///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

//import { Timer } from ;

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class ShortSchedule {
	////////////////////////////////////
	scheduleds: any[] = [];
	cognitiveScheduleds: any[] = [];
	initialState: any[] = [];

	////////////////////////////////////
	isPaused: boolean = false;
	lastPauseTime: number;
	startTime: number;
	interval: number;
	onComplete: any = null;

	////////////////////////////////////
	constructor() {}

	////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////// PUBLIC METHODS

	/////////////////////////
	public add(delay: number, repeat: boolean, callback: Function, params: any = null, initialState: boolean = false): this {
		let scheduled = new Scheduled(delay, repeat, callback, params);
		if (!initialState) this.scheduleds.push(scheduled);
		else this.initialState.push(scheduled);
		this.sortTimers();
		return this;
	}

	/////////////////////////
	public addExisting(scheduled: Scheduled, initialState: boolean = false): this {
		if (!initialState) this.scheduleds.push(scheduled);
		else this.initialState.push(scheduled);
		this.sortTimers();
		return this;
	}

	/////////////////////////
	public addExistingFromJSON(json): this {
		let scheduled = new Scheduled(json.delay, json.repeat, json.callback, json.params);
		if (!json.initialState) this.scheduleds.push(scheduled);
		else this.initialState.push(scheduled);
		this.sortTimers();
		return this;
	}

	/////////////////////////
	public addCognitive(delay: number, repeat: boolean, callback: Function, params: any = null): this {
		let scheduled = new Scheduled(delay, repeat, callback, params);
		this.cognitiveScheduleds.push(scheduled);
		this.sortTimers();
		return this;
	}

	/////////////////////////
	public setToInitialState() {
		this.initialState.forEach((sch) => {
			sch.params.beep = false;
			sch.params.noTransition = true;
			sch.callback(sch, this);
		});
	}

	////////////////////////////////////
	public start(onComplete = null): this {
		if (onComplete) this.onComplete = onComplete;
		if (!this.startTime) this.startTime = new Date().getTime();

		if (this.isPaused) {
			let totalPauseTime = new Date().getTime() - this.lastPauseTime;
			this.scheduleds.forEach((sched) => {
				sched.fireTime = sched.fireTime + totalPauseTime;
			});
			this.cognitiveScheduleds.forEach((sched) => {
				sched.fireTime = sched.fireTime + totalPauseTime;
			});
			this.isPaused = false;
		}
		this.interval = window.setInterval(this.update.bind(this), 20);
		return this;
	}

	////////////////////////////////////
	public pause(): this {
		this.lastPauseTime = new Date().getTime();
		this.isPaused = true;
		return this;
	}

	////////////////////////////////////
	public stop(): this {
		clearInterval(this.interval);
		this.isPaused = false;
		this.lastPauseTime = 0;
		this.scheduleds = [];
		this.cognitiveScheduleds = [];
		return this;
	}

	////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////// PRIVATE METHODS

	/////////////////////////
	private deleteTimer(timer: Scheduled, which: string = 'regular') {
		//console.log("%c - Timer removed", 'color:orange');
		if (which == 'regular') {
			this.scheduleds = this.scheduleds.filter((item) => {
				return !(item == timer);
			});
		} else {
			this.cognitiveScheduleds = this.cognitiveScheduleds.filter((item) => {
				return !(item == timer);
			});
		}
	}

	/////////////////////////
	private update(): void {
		if (this.isPaused) return;
		let schedCheck = this.scheduleds[0];
		if (!schedCheck && !this.isPaused) {
			this.pause();
			if (this.onComplete) this.onComplete();
			return;
		}

		while (schedCheck && schedCheck.fireTime < new Date()) {
			if (schedCheck.callback) schedCheck.callback(schedCheck, this);

			if (schedCheck.repeat == true) {
				let timeDiff = Math.max(0, new Date().getTime() - schedCheck.fireTime);
				let newScheduled = new Scheduled(schedCheck.delay, schedCheck.repeat, schedCheck.callback, schedCheck.params);
				newScheduled.fireTime -= timeDiff;
				this.addExisting(newScheduled);
			}

			// RepEvent
			if (schedCheck && schedCheck.params && schedCheck.params.type == repEventTypeName && !schedCheck.params.complete) {
				schedCheck.params.complete = true;
				const event = new Event(repEventTypeName);
				window.dispatchEvent(event);
			}

			this.deleteTimer(schedCheck);
			schedCheck = this.scheduleds[0];
		}

		let cogSchedCheck = this.cognitiveScheduleds[0];
		while (cogSchedCheck && cogSchedCheck.fireTime < new Date()) {
			if (cogSchedCheck.callback) cogSchedCheck.callback(cogSchedCheck, this);

			this.deleteTimer(cogSchedCheck, 'cognitive');
			cogSchedCheck = this.cognitiveScheduleds[0];
		}
	}

	/////////////////////////
	private sortTimers(): void {
		this.scheduleds.sort((a, b) => {
			if (a.fireTime < b.fireTime) return -1;
			if (a.fireTime > b.fireTime) return 1;
		});
		this.cognitiveScheduleds.sort((a, b) => {
			if (a.fireTime < b.fireTime) return -1;
			if (a.fireTime > b.fireTime) return 1;
		});
	}
}

//////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////  CLASS DEF
export class Scheduled {
	/////////////////////////
	public fireTime: number = 0;
	public delay: number = 0;
	public callback: Function;
	public repeat: boolean = false;
	public params: any = {};

	//////////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////////////////////////////////// CONSTRUCTOR
	constructor(delay: number, repeat: boolean, callback: Function = null, params: any = null) {
		this.fireTime = new Date().getTime() + delay;
		this.delay = delay;
		this.repeat = repeat;
		this.callback = callback;
		this.params = params;
	}

	//////////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////////////////////////////////// PUBLIC FUNCTIONS

	/////////////////////////
	public setTime(ticks: number) {
		let time = new Date().getTime();
		return new Date(time + ticks);
	}
}

//////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////  FLOOR ANIMATIONS
export interface floorAnimation {
	cognitive: any;
	floorSequence: any;
	mode: string;
	modifier: any;
	reps: any[];
	tempo: any[];
}

//////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////  REP COUNTER
export const repEventTypeName = 'rep_update_event';
export class RepEvent {
	type: String = repEventTypeName;
	durationInSecs: number = 0;
	beep: boolean = false;

	constructor() {
		return this;
	}
}
