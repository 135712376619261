///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS
import { Injectable } from '@angular/core';
import { Vasat } from 'vasat';
import { STUser } from '../st-commons/models';
import { StorageService } from './storage.service';
import { FileStream } from '../objects/filestream';
import { DeviceService } from './device.service';
import { userInfo } from 'os';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
@Injectable()
export class UserService {
	////////////////////////////////
	user: STUser = new STUser(this.V);
	birthdayday: number = 1;
	birthdaymonth: number = 1;
	birthdayyear: number = 1;
	dosageLevels = {
		week1_2: [40, 40, 40],
		week3_4: [60, 60, 60],
		week5_6: [60, 80, 90],
		week7_8: [60, 100, 120],
		week9_10: [60, 120, 150],
		week11_: [60, 120, 180],
	};

	////////////////////////////////
	currentGuide: number = 0;
	guideIndex = ['Betty', 'Bill', 'James', 'Janice'];
	userLoaded: boolean = false;
	defaultProfilePhotoUrl: string = 'assets/img/gr.profileplaceholder.svg';
	profilePhotoUrl: string = 'assets/img/gr.profileplaceholder.svg';
	profilePhotoFileUrl: string = '';

	////////////////////////////////
	constructor(private V: Vasat, private sStorage: StorageService, private sDevice: DeviceService) {}

	////////////////////////////////
	setPrimeUser(id = null) {
		let resolver = function (resolve, reject) {
			this.sStorage
				.get('userProfiles')
				.then((res) => {
					let primeUser;

					// Choose the id user, if provided, otherwise the existing primeUser
					if (id)
						primeUser = res.filter((it) => {
							return it.id == id;
						})[0];
					else
						primeUser = res.filter((it) => {
							return it.primeUser == true;
						})[0];

					// If not setting an id, try to get any other user
					if (!primeUser && res.length && !id) {
						primeUser = res[0];
						id = primeUser.id;
					} else if (!primeUser && !res.length) {
						this.repairUser();
						return reject('No users, prime or otherwise');
					}

					// Create the Vasat user
					var newUser = new STUser(this.V);
					newUser.set(primeUser);
					this.user = newUser;
					this.user.primeUser = true;
					this.userLoaded = true;

					// Update relations
					this.user.updateRelations();

					// Get some details
					this.getProfilePhoto();

					// Perform some debugs and repairs
					this.repairUser();
					resolve(this.user);
				})
				.catch((err) => {
					reject(err);
				});
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////
	setPrimeUserByProvidingUser(user) {
		let stUser = new STUser(this.V);
		stUser.set(user);
		this.userLoaded = true;
		this.user = stUser;
		this.getProfilePhoto().catch((err) => {
			if (err.code != 1) {
				console.log('Error during profile photo retrieval');
			}
		});
	}

	////////////////////////////////
	markCurrentUserAsPrime() {
		this.user.primeUser = true;
	}

	////////////////////////////////////
	repairUser() {
		if (this.user && !this.user.weekly_doseage) this.user.weekly_doseage = '60';
	}

	////////////////////////////////////
	clearUser() {
		this.user = new STUser(this.V);
		this.userLoaded = false;
	}

	////////////////////////////////////
	getProfilePhoto() {
		let resolver = function (resolve, reject) {
			if (this.sDevice.isBrowser) {
				this.setDefaultProfilePhoto();
				resolve();
			} else {
				let fs = new FileStream();
				fs.setup()
					.getEntry('/data/user_' + this.user.id + '/profilePhoto.jpg', true)
					.toURL()
					.go()
					.then((res) => {
						if (res.returnData['profilePhoto.jpg']) {
							this.profilePhotoUrl = res.returnData['profilePhoto.jpg'].ionicUrl + '?uncache=' + Math.floor(Math.random() * 1000);
							this.profilePhotoFileUrl = res.returnData['profilePhoto.jpg'].url;
						} else this.setDefaultProfilePhoto();
						resolve(this.profilePhotoUrl);
					})
					.catch((err) => {
						this.profilePhotoUrl = this.defaultProfilePhotoUrl;
						reject(err);
					});
			}
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////////
	setDefaultProfilePhoto() {
		this.profilePhotoUrl = this.defaultProfilePhotoUrl;
	}

	////////////////////////////////
	/*uncacheProfilePhoto() {
		return this.profilePhotoUrl+"?uncache="+(Math.floor(Math.random()*1000));
	}*/

	////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////// GUIDE METHODS

	////////////////////////////////////
	updateGuide(guideIndex: number): void {
		this.user.guide = (guideIndex >= 0 && guideIndex) || 0;
	}

	////////////////////////////////////
	getIMGURL(pose: string): string {
		return 'assets/img/characters/ST.Char.' + this.guideIndex[this.user.guide] + '.' + pose + '.png';
	}

	////////////////////////////////////
	getGuideName() {
		return this.guideIndex[this.user.guide] || this.guideIndex[0];
	}

	////////////////////////////////////
	getGuideIndex() {
		return this.user.guide;
	}

	isUserCog(): boolean {
		if (this.user.program_type != null && this.user.program_type == 'ST+Cogn') {
			return true;
		} else {
			return false;
		}
	}
}
