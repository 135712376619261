///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS
import { Component, OnInit }	from '@angular/core';
import { Router }				from '@angular/router';
import { GlobalPubSub }			from '../../services/global-pub-sub.service';
import { DeviceService }		from '../../services/device.service';


///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
  selector: 'app-page-onboarded2',
  templateUrl: './page-onboarded2.component.html',
  styleUrls: ['./page-onboarded2.component.scss']
})


///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class PageOnboarded2Component implements OnInit {

	calendarText:string = "Don't forget to visit the Calendar section of the app to set yourself some reminders.  Reminders to exercise are an excellent way to build positive habits!";

	////////////////////////////////////
	constructor(private router:Router, private sGlobalPubSub:GlobalPubSub, private sDevice:DeviceService) { }

	////////////////////////////////////
	ngOnInit() {
		if (this.sDevice.isBrowser) this.calendarText = "Don't forget to visit the Calendar section of the app to set yourself a schedule.  A schedule is an excellent way to build positive habits!";
	}

	////////////////////////////////////
	openSettings() {
		let cordova = window['cordova'];
		if (cordova && cordova.plugins && cordova.plugins.settings) {
			cordova.plugins.settings.open("accessibility",
				() => {},
				this.settingsFailure
			);
		}
	}

	////////////////////////////////////
	settingsFailure() {
		this.sGlobalPubSub.fireEvent("toast", [true, "There was a problem opening the settings app.  Please wait a moment and try again, or manually open it.", 2500]);
	}

	////////////////////////////////////
	continue() {
		this.router.navigate(['/onboarded3/add']);
	}
}
