import { Injectable } from '@angular/core';
import { GlobalPubSub } from './global-pub-sub.service';

/**
 * This intention of this service is to log hard-to-track bugs and data incongruities to the localStorage, for use by testers
 * */
@Injectable({
	providedIn: 'root'
})
export class DebugLoggerService {

	////////////////////////////////
	constructor(private sGlobalPubSub:GlobalPubSub) {}

	////////////////////////////////
	saveExerciseSessionLog(session) {
		localStorage.lastExerciseSessionLog = JSON.stringify(session);
	}

	////////////////////////////////
	getExerciseSessionLog() {
		let session = localStorage.lastExerciseSessionLog || '{ "session":"nothing saved" }';
		session = JSON.stringify(session, null, 2);
		this.copyToClipboard(session);
	}

	////////////////////////////////////
	copyToClipboard(content) {

		var textArea = document.createElement("textarea");
		textArea.style.position = 'fixed';
		textArea.style.top = '0';
		textArea.style.left = '0';
		textArea.style.width = '2em';
		textArea.style.height = '2em';
		textArea.style.padding = '0';
		textArea.style.border = 'none';
		textArea.style.outline = 'none';
		textArea.style.boxShadow = 'none';
		textArea.style.background = 'transparent';
		textArea.value = content;

		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();

		try {
			var successful = document.execCommand('copy');
			var msg = successful ? 'successful' : 'unsuccessful';
			this.sGlobalPubSub.fireEvent("toast", [true, "Content copied to clipboard", 2500]);
		} catch (err) {
			this.sGlobalPubSub.fireEvent("toast", [true, "Content coudn't be copied to clipboard", 2500]);
		}

		document.body.removeChild(textArea);
	}
}
