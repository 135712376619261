///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS
import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../services/data.service';
import { LocalCalendar } from '../../objects/localModels';
import { NotificationsService } from '../../services/notifications.service';
import { DeviceService } from '../../services/device.service';
const M = require('moment');

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
	selector: 'app-page-calendar1home',
	templateUrl: './page-calendar1home.component.html',
	styleUrls: ['./page-calendar1home.component.scss'],
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class PageCalendar1homeComponent implements OnInit {
	@ViewChild('bluewaveform') bluewaveform;
	@ViewChild('frm_day_of_week') frm_day_of_week;
	@ViewChild('frm_time') frm_time;

	//allTimes:any[] = Array.apply(null, {length: 24}).map(Number.call, Number);
	calendarItems: any[] = [];
	error: string = null;
	focusItem: any;
	validTime: boolean = true;
	defaultWeeklyReminder: string = "It's time for your StandingTall exercise session!";
	introText: string =
		"You can set yourself reminders to exercise at specific times of the week.  Nifty!  We'll send you a reminder on your device 10 minutes before the nominated time.";

	////////////////////////////////////
	constructor(private sData: DataService, private sNotifications: NotificationsService, private sDevice: DeviceService) {}
	ngOnInit() {
		if (this.sDevice.isBrowser) this.introText = 'You can set yourself a schedule, to exercise at specific times of the week.  Nifty!';
		this.getCalendarItems();

		this.sNotifications
			.getNotifications()
			.then((res) => {})
			.catch((err) => {
				console.log('Error getting notifications', err);
			});
	}

	////////////////////////////////////
	getCalendarItems() {
		this.sData.getCalendarItems().then((res) => {
			this.calendarItems = res.data.calendar;
		});
	}

	////////////////////////////////////
	generateDisplayName(calendarItem) {
		let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
		return days[calendarItem.day_of_week] + 's at ' + this.timeConvert(calendarItem.time);
	}

	////////////////////////////////////
	toAddForm() {
		this.error = '';
		this.bluewaveform.setFormContentState('add');
		this.bluewaveform.open();
		this.frm_day_of_week.nativeElement.value = 1;
		(document.querySelector('#frm_el_time') as any).value = '13:00';
	}

	////////////////////////////////////
	closeForm() {
		this.bluewaveform.close();
	}

	////////////////////////////////////
	addNewCalendarItem() {
		this.error = '';
		let day = this.frm_day_of_week.nativeElement.value;
		let time = this.frm_time.nativeElement.value;
		if (!time) this.error = 'The value given for time is invalid or missing.';
		else {
			// Save :)
			let newCalendarItem = new LocalCalendar({ l_id: null, day_of_week: day, time: time, owner_id: null });
			let hour = parseInt(time.substring(0, time.indexOf(':')));
			let minute = parseInt(time.substring(time.indexOf(':') + 1));
			this.sData
				.saveNewCalendarItemForPrimeUser(newCalendarItem)
				.then((res) => {
					let id = res.data.new_calendar[0].l_id;
					this.sNotifications.setWeeklyNotification(id, this.defaultWeeklyReminder, parseInt(day), hour, minute);
					this.getCalendarItems();
					this.bluewaveform.close();
				})
				.catch((err) => {
					console.log('Error saving calendar item', err);
				});
		}
	}

	////////////////////////////////////
	deleteCalendarItem(id) {
		this.sData.deleteCalendarItemByID(id).then(
			() => {
				this.getCalendarItems();
				this.sNotifications.deleteWeeklyNotification(id);
			},
			(err) => {
				console.log('Error deleting calendar item', err);
			}
		);
	}

	////////////////////////////////////
	toEditForm(id) {
		this.focusItem = this.calendarItems.filter((i) => {
			return i.l_id == id;
		})[0];
		this.bluewaveform.setFormContentState('edit');
		this.bluewaveform.open();
		this.frm_day_of_week.nativeElement.value = parseInt(this.focusItem.day_of_week);
		this.frm_time.nativeElement.value = this.focusItem.time;
	}

	////////////////////////////////////
	editCalendarItem() {
		this.focusItem.day_of_week = this.frm_day_of_week.nativeElement.value;
		this.focusItem.time = this.frm_time.nativeElement.value;

		this.sData
			.replaceDataByTypeAndKey(this.focusItem, LocalCalendar, 'l_id', this.focusItem.l_id)
			.then((res) => {
				let hour = parseInt(this.focusItem.time.substring(0, this.focusItem.time.indexOf(':')));
				let minute = parseInt(this.focusItem.time.substring(this.focusItem.time.indexOf(':') + 1));
				this.sNotifications.editWeeklyNotification(
					this.focusItem.l_id,
					this.defaultWeeklyReminder,
					parseInt(this.focusItem.day_of_week),
					hour,
					minute
				);
				this.getCalendarItems();
				this.bluewaveform.close();
			})
			.catch((err) => {
				console.log('Error saving calendar item', err);
			});
	}

	////////////////////////////////////
	timeConvert(time): string {
		time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

		if (time.length > 1) {
			// If time format correct
			time = time.slice(1); // Remove full string match value
			time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
			time[0] = +time[0] % 12 || 12; // Adjust hours
		}
		return time.join(''); // return adjusted time or original string
	}

	////////////////////////////////////
	focusTimeField() {
		this.validTime = true;
	}

	////////////////////////////////////
	checkTimeValue() {
		let valid = M('2000-01-01 ' + this.frm_time.nativeElement.value)._isValid;
		console.log('VALID', valid, M('2000-01-01 ' + this.frm_time.nativeElement.value));
		if (!valid) {
			this.validTime = false;
		} else {
			this.validTime = true;
		}
	}
}
