///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS
import { Vasat } from 'vasat';
import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { ExerciseService } from '../../services/exercise.service';
import { UserService } from '../../services/user.service';
import { CATEGORY_BREAK_DOWNS } from '../../st-commons/exercise_logic';
import { STExerciseLogic } from '../../st-commons/exercise_logic';
import { ExerciseAction } from 'src/app/st-commons/models';
import { DataService } from 'src/app/services/data.service';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
	selector: 'app-page-debugexgenerator',
	templateUrl: './page-debugexgenerator.component.html',
	styleUrls: ['./page-debugexgenerator.component.scss'],
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class PageDebugExGeneratorComponent implements OnInit {
	categories: string[] = ['floor', 'foam', 'box', 'dart', 'grid'];
	exercisePrint: any[] = [];

	////////////////////////////////
	constructor(private V: Vasat, private zone: NgZone, private sExercise: ExerciseService, private sUser: UserService, private sData: DataService) { }

	////////////////////////////////
	ngOnInit() {

	}

	////////////////////////////////////
	generateExercises(type: string = "floor") {
		this.exercisePrint = [];
		const exerciseLogic = new STExerciseLogic(this.V);
		const user = this.sUser.user;

		let exercises = exerciseLogic.createExercisesFromCategory(user, type);
		exercises.forEach(ex => this.exercisePrint.push(ex));
	}

	////////////////////////////////////
	clearLog() {
		this.exercisePrint = [];
	}
}
