///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS
import { Component, OnInit, ViewChild }	from '@angular/core';
import { DataService }					from '../../services/data.service';


///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
  selector: 'app-page-faqs',
  templateUrl: './page-faqs.component.html',
  styleUrls: ['./page-faqs.component.scss']
})


///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class PageFaqsComponent implements OnInit {

	////////////////////////////////////
	faqs:any = [];
	@ViewChild('scrollContainer') scrollcontainer;

	////////////////////////////////////
	constructor(private sData:DataService) { }
	ngOnInit() {
		this.sData.getRawDataFromStorage('faqs')
		.then((res:any) => {
			let cats = {};
			let arr = [];
			res.forEach(item => {
				if (!cats[item.cat]) cats[item.cat] = [];
				cats[item.cat].push(item);
			});
			Object.keys(cats).forEach(key => {
				arr.push( { cat:key, items:cats[key] } );
			});
			
			// Now reorder alphabetically
			arr = arr.sort((a, b) => a.cat < b.cat && -1 || 1)
			this.faqs = arr;
		});
	}

	////////////////////////////////////
	/*expand(ev) {}*/

	////////////////////////////////////
	backToTop() {
		this.smoothScrollToElement(this.scrollcontainer.nativeElement, 500);
	}

	////////////////////////////////////
	smoothScrollToElement(targetElement: any, scrollTime: number) {

		//Set target position (the elements offset top will change as it scrolls)
		let targetPosition = targetElement.offsetTop - (this.scrollcontainer.nativeElement.offsetHeight/2);

		//Find Distance to target
		let distanceToTarget = targetPosition - this.scrollcontainer.nativeElement.scrollTop;

		//Set interval Length (32 frames per second?)
		let intervalLength = 1000/32;

		//Set number of intervals
		let numberOfIntervals = scrollTime/intervalLength;

		//Set scroll jump
		let scrollJump = distanceToTarget/numberOfIntervals;

		//Set interval count
		let intervalCount = 0;

		//Set interval
		let scrollInterval = setInterval(() => {

			if (intervalCount >= numberOfIntervals) {

				this.scrollcontainer.nativeElement.scrollTop = targetPosition;

				clearInterval(scrollInterval)
			} else {
				intervalCount++;
				this.scrollcontainer.nativeElement.scrollTop = this.scrollcontainer.nativeElement.scrollTop + scrollJump;
			}
		}, intervalLength)
	}
}
