///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
////////// MODULES
import { Component, OnInit, ViewChild, ElementRef, HostBinding, AfterViewInit, Input }	from '@angular/core';
import { DataService }																	from '../../services/data.service';
import { UserService }																	from '../../services/user.service';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
  selector:		'guide-circlehead',
  templateUrl:	'./guide-circlehead.component.html',
  styleUrls:	['./guide-circlehead.component.scss']
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class GuideCircleheadComponent implements OnInit, AfterViewInit {

	////////////////////////////////
	@ViewChild('guideimage') guideimage;
	@ViewChild('guideimagecont') guideimagecont;
	@Input() guideIndex:string;
	@Input() size:number = 120;

	////////////////////////////////
	thisGuide = { name:"Betty" }

	////////////////////////////////
	constructor(private sData:DataService, private elementRef:ElementRef, private sUser:UserService) { }
	ngOnInit() {
		if (parseInt(this.guideIndex) >= 0) {
			this.thisGuide.name = this.sUser.guideIndex[parseInt(this.guideIndex)];
			this.guideimage.nativeElement.src = "assets/img/characters/ST.Char."+this.sUser.guideIndex[parseInt(this.guideIndex)]+".Neutral.png";
		} else {
			this.thisGuide.name = this.sUser.getGuideName();
			this.guideimage.nativeElement.src = "assets/img/characters/ST.Char."+this.sUser.getGuideName()+".Neutral.png";
		}
		this.guideimagecont.nativeElement.style.width = this.size+"px";
		this.guideimagecont.nativeElement.style.height = this.size+"px";
	}

	////////////////////////////////
	ngAfterViewInit() {
		setTimeout(() => { this.elementRef.nativeElement.classList.add('ready'); }, 150);
	}
}
