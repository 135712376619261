///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
import { Component, AfterViewInit, ViewChild, Input } from '@angular/core';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
  selector:		'select-datemonth',
  templateUrl:	'./select-datemonth.component.html',
  styleUrls:	['./select-datemonth.component.scss']
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class SelectDatemonthComponent implements AfterViewInit {

	@ViewChild('fld_birthdaymonth') fld_birthdaymonth;
	@Input() initialValue:number = 1;

	months:any[] = [ { val:1, name:"January" }, { val:2, name:"February" }, { val:3, name:"March" }, { val:4, name:"April" }, { val:5, name:"May" }, { val:6, name:"June" }, { val:7, name:"July" }, { val:8, name:"August" }, { val:9, name:"September" }, { val:10, name:"October" }, { val:11, name:"November" }, { val:12, name:"December" }, ];

	////////////////////////////////
	constructor() {}

	////////////////////////////////
	ngAfterViewInit() {
		this.fld_birthdaymonth.nativeElement.value = this.initialValue;
	}

	////////////////////////////////////
	getValue() {
		return this.fld_birthdaymonth.nativeElement.value;
	}

	////////////////////////////////////
	setValue(value: number) {
		this.fld_birthdaymonth.nativeElement.value = value;
	}

	////////////////////////////////////
	flash() {
		this.fld_birthdaymonth.nativeElement.classList.add("flash");
		setTimeout(_ => {
			this.fld_birthdaymonth.nativeElement.classList.remove("flash");
		}, 2000);
	}
}
