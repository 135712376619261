///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class CognitiveCreator {

	// All: Mode: Audio = on third, do the opposite distractor / real cue
	// All: Mode: Visual = on third, do the opposite / real cue
	// All: Must tell user what they have to do during countdown (perhaps make countdown 5 seconds to let them read), remembering that third goes opposite
	// All: Will need to generate cognitive per iteration of 3 exercises, but keep the specific word to count, or category of words to count, or both to distract

	// Mode: Initial:
	// Simple attention cognitive, which means: take a word category and randomly choose words from it.  The user should have counted the number of words / items
	// Tempo given in initial is always in seconds, not in bpm
	// Choose a random tempo each "beat" so that it is not predictable
	// Show or play only the 1 thing, and user counts them, 1 for 1.
	// Remember how many played / shown, as the user gets the opportunity to see how many, so they can self-reflect.

	// Mode: Audio:
	// Cue to count = "Specific word" means: A word is chosen at random and is played a random number of times and should be counted.  This word must be played at least once
	// Cue to ignore = "Distractor words" means: Choose any number of words at random which don't need to be counted
	// Cue to count = "Word category" means: Count any from good category, Distractor means ignore
	// Cue to count = "Distractor words" means: Count any from 'other' categories, where the 'Word category' acts as bad / wrong / distractor (NOTE: Emma might be removing "Distractor image" in favour of "Specific Image")
	// When tempo is 4b, 3b, 2-5b, 3-8b, I should play on the parent exercise's beat
	// Cue to count = "Tempo beeps and Distractor words" means to count both beeps and words

	// Mode Visual:
	// Same as audio
	// Note for audio AND visual: When we're counting category and there is a distractor, distractor never comes from that category

	// Mode Recall:
	// Always audio.
	// Choose 'recall_forward' random words and play them in a set order.  The user is asked whether they remembered the order of the words.
	// Choose 'recall_backwards' random words and play them in a set order (backwards)
	// Don't repeat pattern audio (play the set just once, even if not long enough for exercise)
	// Note: This means I need a lookup table to translate audio cues to written for the "did you get" page (ie. "cat", "dog" played, "Did you get Cat, Dog?")
	// Tempo: Could get 1.2 (seconds) or 3b (play every 3 beats of parent exercise)
	
	// Mode Pattern:
	// Create a set of a pattern (1:2:3:1:2:3) (and use this for all 3 iterations)
	// Always card suits (visual)
	// Tempo can be 1.2 (seconds) or 3b (per beat of parent exercise)

	// Mode Inhibition:
	// Always 'stop and go' cues (as both visual and audio)
	// Alternate between Stop and Go commands.
	// Randomly select "Stop!" / "Go!" and play both audio and visual at the same time
	// Play these randomly on tempo (always given as seconds)

	// CUES (in Mode: Initial only)
	// 2 type: Audio, visual

	// Audios are words across categories:
	// Cagegories: Fruit and Veg
	// Words starting with C
	// Words starting with P
	// Words starting with L
	// Transport methods
	// Musical instrument

	// Visual are:
	// Card suits
	// Footprints
	// Ship flags


	// PLANNING:
	/*
		List of categorised audio with English labels
		List of categorised visuals

		- Select Mode
		- Select word category(ies) that will be required (and store)
		- Determine the cognitive time (parent exercise time / beat per seconds or parent exercise time / parent tempo / cognitive per-parent-tempo tempo)
		- Pregenerate a list of things you should do in order to fill the time
			- Switch based on mode
			- Store the outcomes (You should have seen x cards, or Did you hear Cat, then Dog?)
		- Provide the sequence to the scheduler, which should run through both options in update (seperately, so they can both run just fine)
	*/

	////////////////////////////////////
	totalExerciseTimeSecs:number;
	chosenPrompt:any;
	chosenPromptCat:any;
	distractorPrompt:any;
	distractorPromptCat:any;
	parentExerciseBeat:number;
	cinstr:any;
	feedbackPrompt:any[] = [];
	helperPrompt:any[] = [];
	feebackAssets:any[] = [];
	feedbackVerb:string = "";
	longestWordReadTimeSecs:number = 1.6;
	longestImageShowTimeSecs:number = 1;
	minInitialWaitSecs:number = 0.42;
	audioItems:any = [];
	preloadableImages:string[];

	////////////////////////////////////
	//visuals = require('./cognitiveVisuals.json');
	//audio = require('./cognitiveAudio.json');
	visuals = {
		"suits":					["suit-club", "suit-diamond", "suit-heart", "suit-spade"],
		"flags":					["flag-alpha", "flag-bravo", "flag-charlie", "flag-delta"],
		"footprints":				["ico-fp-bear", "ico-fp-deer", "ico-fp-dog", "ico-fp-duck"]
	}
	audio = {
		"animals":					["Camel", "Cat", "Cheetah", "Chicken", "Chimpanzee", "Cow", "Crocodile", "Dog", "Elephant", "Horse", "Lemur", "Leopard", "Lion", "Lizard", "Llama", "Lobster", "Lorikeet", "Monkey", "Panda", "Panther", "Pelican", "Penguin", "Pig", "Puppy", "Parrot", "Rabbit", "Sheep", "Tiger"],
		"c":						["Car", "Camel", "Carrot", "Castanets", "Cat", "Catamaran", "Celery", "Cello", "Cheetah", "Cherry", "Chicken", "Chimes", "Chimpanzee", "Chopper", "Clarinet", "Corn", "Cow", "Cranberry", "Crocodile", "Cucumber", "Cymbals"],
		"fruit and vegetables":		["Apple", "Banana", "Broccoli", "Carrot", "Celery", "Cherry", "Corn", "Cranberry", "Cucumber", "Leek", "Lemon", "Lettuce", "Lime", "Lychee", "Onion", "Peach", "Pear", "Peas", "Pineapple", "Plum", "Pomegranate", "Potato", "Pumpkin", "Strawberry", "Tomato", "Watermelon"],
		"l":						["Leek", "Lemon", "Lemur", "Leopard", "Lettuce", "Lime", "Limousine", "Lion", "Lizard", "Llama", "Lobster", "Lorikeet", "Lute", "Lychee"],
		"musical instruments'":		["Bagpipes", "Banjo", "Bongos", "Castanets", "Cello", "Chimes", "Clarinet", "Cymbals", "Drums", "Flute", "Guitar", "Harp", "Lute", "Piccolo", "Saxophone", "Trumpet", "Tuba", "Violin"],
		"p":						["Panda", "Panther", "Parrot", "Peach", "Pear", "Peas", "Pelican", "Penguin", "Percussion", "Piano", "Piccolo", "Pig", "Pineapple", "Plane", "Plum", "Pomegranate", "Potato", "Pumpkin", "Puppy"],
		"transport":				["Bicycle", "Boat", "Bus", "Car", "Catamaran", "Chopper", "Ferry", "Helicopter", "Light Rail", "Limousine", "Monorail", "Motorcycle", "Percussion", "Piano", "Plane", "Scooter", "Ship", "Subway", "Taxi", "Train", "Tram", "Truck"]
	}

	////////////////////////////////////
	createCognitiveComponent(instructions, oppositeIfAble:boolean = false) {
		
		// Setup
		this.cinstr = instructions.cognitive;
		this.cinstr.tempos = this.cleanTempos(this.cinstr.tempos);
		this.parentExerciseBeat = 60 / parseInt(instructions.tempo);
		this.preloadableImages = [];
		this.audioItems = [];
		let proFunc;

		// Setup main things
		if (!this.chosenPrompt) this.setPrompts();
		this.getExerciseTime(instructions);

		// Switch prompts if able, and if the prompts allow it
		if (oppositeIfAble && this.distractorPrompt && this.distractorPrompt.key != "none") {
			let chosenPrompt = JSON.parse(JSON.stringify(this.chosenPrompt));
			let distPrompt = JSON.parse(JSON.stringify(this.distractorPrompt));
			this.chosenPrompt = distPrompt;
			this.distractorPrompt = chosenPrompt;
		}
		
		// Determine the thing
		switch (this.cinstr.mode) {
			case "Initial":		proFunc = this.generateInitial.bind(this);		break;
			case "Audio":		proFunc = this.generateAudio.bind(this);		break;
			case "Visual":		proFunc = this.generateVisual.bind(this);		break;
			case "Recall":		proFunc = this.generateRecall.bind(this);		break;
			case "Pattern":		proFunc = this.generatePattern.bind(this);		break;
			case "Inhibition":	proFunc = this.generateInhibition.bind(this);	break;
		}

		// Act based on the processor Function we've decided on
		let sequence = proFunc(instructions);

		// Now create an audioItems for any unique audio file we need to play
		//if (!this.isBrowser()) {
		sequence.forEach(seq => {
			//let Media = window['Media'];
			//let knownAudioKeys = Object.keys(this.audioItems);
			if (seq.type == "hear"/* && knownAudioKeys.indexOf(seq.what) == -1*/) {
				//let media = new Media(this.findDeviceSpecificPath("assets/animations/audio/"+seq.what+".mp4"), () => { /* success */ }, () => { /* error */ }, () => { /* status */ });
				//this.audioItems[seq.what] = media;
				if (this.audioItems.indexOf(seq.what) == -1) this.audioItems.push(seq.what);
			}
		});
		//}

		return sequence;
	}

	////////////////////////////////////
	findDeviceSpecificPath(relativeUrl: string): string {
		let cordova = window['cordova'];

		if (window['cordova_custom'].device().platform == "Android") {
			return cordova.file.applicationDirectory + "www/" + relativeUrl;
		} else {
			return relativeUrl
		}
	}

	////////////////////////////////////
	generateInitial(instr) {
		let timeTakenSoFar = 0;
		let limiter = 0; let limit = 1000;
		let sequence = [];
		//let target = this.chosenPrompt;
		let target;
		let allTempos = this.cinstr.tempos.sort((a,b) => { return a > b });
		let tempoDiff;
		this.feedbackVerb = this.cinstr.cue == "visuals" && "see" || "hear";
		let maxTimeNeededToPerformPrompt = this.feedbackVerb == "hear" && this.longestWordReadTimeSecs || this.longestImageShowTimeSecs;
		let feedbackPromptVal = 0;

		if (this.feedbackVerb == "hear") {
			let shortestTempo = allTempos[0];
			tempoDiff = Math.max(0, this.longestWordReadTimeSecs - shortestTempo);
		} else {
			tempoDiff = 0;
		}
		
		while (timeTakenSoFar + maxTimeNeededToPerformPrompt <= this.totalExerciseTimeSecs && limiter < limit) {
			limiter++;
			let tempo = this.getMeARandoFromArray(this.cinstr.tempos, true) + tempoDiff;
			if (timeTakenSoFar == 0) tempo += this.minInitialWaitSecs;
			timeTakenSoFar+=tempo;
			target = this.getMeARandoFromArray(this.chosenPrompt.items);
			sequence.push( { type:this.feedbackVerb, what:target, durationInSecs: tempo } );
			feedbackPromptVal++;

			if (this.feedbackVerb == "see") this.addPreloadableImage(target);
		}

		if (this.feedbackVerb == "see") {

			if (feedbackPromptVal == 1) {
				this.feedbackPrompt.push("Did you see "+feedbackPromptVal+" image?");
			} else {
				this.feedbackPrompt.push("Did you see "+feedbackPromptVal+" images?");
			}

			this.helperPrompt.push("Count all the images that appear!")
		} else {

			if (feedbackPromptVal == 1) {
				this.feedbackPrompt.push("Did you hear "+feedbackPromptVal+" word?");
			} else {
				this.feedbackPrompt.push("Did you hear "+feedbackPromptVal+" words?");
			}

			this.helperPrompt.push("Count all the words that you hear!")
		}

		return sequence;
	}

	////////////////////////////////////
	generateAudio() {
		this.feedbackVerb = "hear";
		return this.generateAudioOrVisual();
	}

	////////////////////////////////////
	generateVisual() {
		this.feedbackVerb = "see";
		return this.generateAudioOrVisual();
	}

	//////////////////////////////////// Selective Attention Static/Dynamic Audio/Visual
	generateAudioOrVisual() {
		let timeTakenSoFar = 0;
		let limiter = 0; let limit = 1000;
		let sequence = [];
		let target;
		let allTempos = this.cinstr.tempos.sort((a,b) => { return a > b });
		let tempoDiff;
		let useParentTempo = this.cinstr.tempos.filter(i => { return typeof i == "string" && i.indexOf('b') != -1 }).length > 0;
		let feedbackPromptVal = 0;
		let maxTimeNeededToPerformPrompt = this.feedbackVerb == "hear" && this.longestWordReadTimeSecs || this.longestImageShowTimeSecs;
		let thisMaxTimeNeededToPerformPrompt;

		if (this.feedbackVerb == "hear") {
			let shortestTempo = allTempos[0];
			tempoDiff = Math.max(0, this.longestWordReadTimeSecs - shortestTempo);
		} else {
			tempoDiff = 0;
		}

		for (let x=0; x<1000; x++) {
			limiter ++;
			let tempo;

			// Set the tempo
			tempo = this.getTempoYo(tempoDiff, useParentTempo);
			if (timeTakenSoFar == 0) tempo += this.minInitialWaitSecs;
			timeTakenSoFar+=tempo;

			// On first iteration, we won't count maxTimeNeededToPerformPrompt so that 5 second exercises have a cue // WARNING, this exception may cause issues on longer exercises
			if (!sequence.length) thisMaxTimeNeededToPerformPrompt = 0;
			else thisMaxTimeNeededToPerformPrompt = maxTimeNeededToPerformPrompt;

			if (timeTakenSoFar + thisMaxTimeNeededToPerformPrompt <= this.totalExerciseTimeSecs) {
				let canDistract = (this.distractorPrompt && this.distractorPrompt.key && this.distractorPrompt.key != "none");
				let isDistractor = [true, false][Math.floor(Math.random()*2)];
				if (canDistract && isDistractor) {
					target = this.getAPrompt(this.chosenPrompt.key, this.distractorPrompt.key, true); // Let's make sure we don't get a prompt from the distractor prompts that matches a chosenPrompt
					sequence.push( { type:this.feedbackVerb, what:target, durationInSecs: tempo } );
				} else {
					if (canDistract) target = this.getAPrompt(this.chosenPrompt.key, this.distractorPrompt.key, false); // Let's make sure we don't get a prompt from the distractor prompts that matches a chosenPrompt
					else target = this.getMeARandoFromArray(this.chosenPrompt.items);
					sequence.push( { type:this.feedbackVerb, what:target, durationInSecs: tempo } );
					feedbackPromptVal++;
				}

				if (this.feedbackVerb == "see") this.addPreloadableImage(target);
			} else {
				break;
			}
		}

		/*while (timeTakenSoFar <= this.totalExerciseTimeSecs && limiter < limit) {
			limiter ++;
			let tempo;

			tempo = this.getTempoYo(tempoDiff, useParentTempo);
			if (timeTakenSoFar == 0) tempo += this.minInitialWaitSecs;
			timeTakenSoFar+=tempo;
			
			if (timeTakenSoFar <= this.totalExerciseTimeSecs) {
				let canDistract = (this.distractorPrompt && this.distractorPrompt.key && this.distractorPrompt.key != "none");
				let isDistractor = [true, false][Math.floor(Math.random()*2)];
				if (canDistract && isDistractor) {
					target = this.getAPrompt(this.chosenPrompt.key, this.distractorPrompt.key, true); // Let's make sure we don't get a prompt from the distractor prompts that matches a chosenPrompt
					sequence.push( { type:this.feedbackVerb, what:target, durationInSecs: tempo } );
				} else {
					if (canDistract) target = this.getAPrompt(this.chosenPrompt.key, this.distractorPrompt.key, false); // Let's make sure we don't get a prompt from the distractor prompts that matches a chosenPrompt
					else target = this.getMeARandoFromArray(this.chosenPrompt.items);
					sequence.push( { type:this.feedbackVerb, what:target, durationInSecs: tempo } );
					feedbackPromptVal++;
				}

				if (this.feedbackVerb == "see") this.addPreloadableImage(target);
			}
		}*/

		let noun;
		let helperText;

		//If static
		if (this.chosenPrompt.items.length == 1) {

			//Static Audio/Visual
			noun = this.chosenPrompt.items[0];
			
			//Check if Visual or Audio
			if (this.feedbackVerb == "see") {
				helperText = "Count the number of times you see this image <img class='cognitive-icon-inline' src=assets/animations/svg/" + noun + ".svg>";
			} else {
				helperText = "Count the number of times you hear the word '" + noun +"'";
			}

		} else { //If Dynamic

			//Check if Visual or Audio
			if (this.feedbackVerb == "see") {
				if (feedbackPromptVal == 1) {
					noun = "image from the category " + this.chosenPrompt.key;
				} else {
					noun = "images from the category " + this.chosenPrompt.key;
				}
				helperText = "Count the images you see that are <em>'" + this.chosenPrompt.key + "'</em> <span class='underline'>only</span>";
			} else {

				//If the category is a single letter 
				if (this.chosenPrompt.key.length == 1) {
					if (feedbackPromptVal == 1) {
						noun = "word starting with the letter " + this.chosenPrompt.key.toUpperCase();
					} else {
						noun = "words starting with the letter " + this.chosenPrompt.key.toUpperCase();
					}
					helperText = "Count the number of words you hear that start with the letter <em>'" + this.chosenPrompt.key.toUpperCase() + "'</em> <span class='underline'>only</span>";
				} else {
					if (feedbackPromptVal == 1) {
						noun = "word from the category " + this.chosenPrompt.key;
					} else {
						noun = "words from the category " + this.chosenPrompt.key;
					}
					helperText = "Count the number of words you hear that are <em>'" + this.chosenPrompt.key + "'</em> <span class='underline'>only</span>";
				}
			}
		}

		//Set helper prompts
		this.helperPrompt.push(helperText);

		if (this.feedbackVerb == "see" && this.chosenPrompt.items.length == 1) {
			this.feedbackPrompt.push("Did you count " + feedbackPromptVal + " <img class='cognitive-icon-inline' src=assets/animations/svg/" + noun + ".svg>" + " ?");
		} else {
			this.feedbackPrompt.push("Did you count "+feedbackPromptVal+" "+noun+"?");
		}

		return sequence;
	}

	////////////////////////////////////
	generateRecall(instr) {
		this.feedbackVerb = "hear";
		let sequence = [];
		let target;
		let recallPlaybackTime:number = 0;
		let useParentTempo = this.cinstr.tempos.filter(i => { return typeof i == "string" && i.indexOf('b') != -1 }).length > 0;
		let feedbackItems = [];
		let mode; let recallNum;

		console.log("DEBUG; TOTAL EX TIME:", this.totalExerciseTimeSecs);

		if (instr.cognitive.recall_backwards || instr.cognitive.recall_backward) {
			mode = 'backwards';
			recallNum = parseInt(instr.cognitive.recall_backwards || instr.cognitive.recall_backward);
		} else {
			mode = 'forwards';
			recallNum = parseInt(instr.cognitive.recall_forwards || instr.cognitive.recall_forward || 2);
		}

		let forward = true;
		let tempos = [];
		let targets = [];
		let recalls = [];

		for (let x=0; x<recallNum; x++) {
			if (recallPlaybackTime >= this.totalExerciseTimeSecs) break;
			let tempo = this.getTempoYo(0, useParentTempo);

			if (!targets.length) target = this.getMeARandoFromArray(this.chosenPrompt.items);
			else {
				let gotUnique = false;
				while (!gotUnique) {
					target = this.getMeARandoFromArray(this.chosenPrompt.items);
					if (target != targets[targets.length-1]) gotUnique = true;
				}
			}

			tempos.push(tempo);
			targets.push(target);
			recalls.push(target);
			recallPlaybackTime += tempo;
		}
		if (mode == 'backwards') {
			targets.reverse();
			forward = false;
		}
		for (let x=0; x<targets.length; x++) {
			sequence.push( { type:this.feedbackVerb, what:targets[x], durationInSecs:tempos[x] } );
			feedbackItems.push(recalls[x]);
		}
		this.feedbackPrompt.push("Did you recall the words: "+feedbackItems.join(", ")+"?");

		// Set helper prompts
		if (forward) this.helperPrompt.push("Remember the words in the order you hear them.");
		else this.helperPrompt.push("Remember the words in the reverse order you hear them.");

		return sequence;
	}

	////////////////////////////////////
	generatePattern(instr) {
		/*if (!this.feedbackPrompt) this.feedbackPrompt = [];
		this.feedbackVerb = "see";
		let timeTakenSoFar = 0;
		let firstItem = true;
		let sequence = [];
		let useParentTempo = this.cinstr.tempos.filter(i => { return typeof i == "string" && i.indexOf('b') != -1 }).length > 0;
		let pattern = instr.cognitive.pattern.split(":");
		let uniqueParts:any = []; pattern.forEach(i => { if (uniqueParts.indexOf(i) == -1) uniqueParts.push(i); }); uniqueParts = uniqueParts.length;
		let chosenPromptDupe = JSON.parse(JSON.stringify(this.chosenPrompt));
		let partPrompts = chosenPromptDupe.items.sort(i => { if (Math.random() > 0.5) return -1; return 1; }).splice(0, uniqueParts);
		let indexPartPrompts = {};
		partPrompts.forEach((part, index) => {
			indexPartPrompts["pat"+(index+1)] = part;
		});
		let winningPiece = partPrompts[Math.max(0, parseInt(this.cinstr.next_shape)-1)];

		while (pattern.length && timeTakenSoFar <= this.totalExerciseTimeSecs) {
			let patternPiece = pattern.splice(0, 1);
			let tempo = this.getTempoYo(0, useParentTempo);
			timeTakenSoFar+=tempo;
			if (firstItem) {
				tempo += this.minInitialWaitSecs;
				firstItem = false;
			}
			sequence.push( { type:this.feedbackVerb, what:indexPartPrompts['pat'+patternPiece], durationInSecs:tempo } );

			 this.addPreloadableImage(indexPartPrompts['pat'+patternPiece]);
		}

		if (this.feedbackVerb == "see") {
			//Visual pattern (Im unsure if there are audio patters, if there are fill them in as an else)
			this.feedbackPrompt.push("Did you guess the next item as " + " <img class='cognitive-icon-inline' src=assets/animations/svg/" + winningPiece + ".svg>" + " ?");
		} else {
			this.feedbackPrompt.push("Did you guess the next item as "+winningPiece+"?");
		}

		//Set helper prompts
		this.helperPrompt.push("Watch out for the pattern and try and guess the next shape. ");

		return sequence*/

		if (!this.feedbackPrompt) this.feedbackPrompt = [];
		this.feedbackVerb = "see";
		let timeTakenSoFar = 0;
		let firstItem = true;
		let sequence = [];
		let useParentTempo = this.cinstr.tempos.filter(i => { return typeof i == "string" && i.indexOf('b') != -1 }).length > 0;
		let pattern = instr.cognitive.pattern.split(":");
		let uniqueParts:any = []; pattern.forEach(i => { if (uniqueParts.indexOf(i) == -1) uniqueParts.push(i); }); uniqueParts = uniqueParts.length;
		let chosenPromptDupe = JSON.parse(JSON.stringify(this.chosenPrompt));
		let partPrompts = chosenPromptDupe.items.sort(i => { if (Math.random() > 0.5) return -1; return 1; }).splice(0, uniqueParts);
		let indexPartPrompts = {};
		partPrompts.forEach((part, index) => {
			indexPartPrompts["pat"+(index+1)] = part;
		});
		let winningPiece = partPrompts[Math.max(0, parseInt(this.cinstr.next_shape)-1)];

		/*while (pattern.length && timeTakenSoFar <= this.totalExerciseTimeSecs) {
			let patternPiece = pattern.splice(0, 1);
			let tempo = this.getTempoYo(0, useParentTempo);
			timeTakenSoFar+=tempo;
			if (firstItem) {
				tempo += this.minInitialWaitSecs;
				firstItem = false;
			}
			sequence.push( { type:this.feedbackVerb, what:indexPartPrompts['pat'+patternPiece], durationInSecs:tempo } );

			 this.addPreloadableImage(indexPartPrompts['pat'+patternPiece]);
		}*/

		//let tempo = this.totalExerciseTimeSecs / (pattern.length * 4);
		let tempo = 0;
		//for (let x=0; x<2; x++) {
			pattern.forEach((patItem, index) => {
				sequence.push( { type:"see_additive", what:partPrompts[patItem-1], durationInSecs:tempo } );
			});
		//}

		// Add a final question mark item
		sequence.push( { type:"see_additive", what:"suit-unknown", durationInSecs:tempo } );

		if (this.feedbackVerb == "see") {
			//Visual pattern (Im unsure if there are audio patters, if there are fill them in as an else)
			this.feedbackPrompt.push("Did you guess the next item as " + " <img class='cognitive-icon-inline' src=assets/animations/svg/" + winningPiece + ".svg>" + " ?");
		} else {
			this.feedbackPrompt.push("Did you guess the next item as "+winningPiece+"?");
		}

		//Set helper prompts
		this.helperPrompt.push("Watch out for the pattern and try and guess the next shape. ");

		return sequence
	}

	////////////////////////////////////
	generateInhibition(instr) {
		if (!this.feedbackPrompt) this.feedbackPrompt = null;
		let timeTakenSoFar = 0;
		let limiter = 0; let limit = 1000;
		let sequence = [];
		//let useParentTempo = this.cinstr.tempos.filter(i => { return typeof i == "string" && i.indexOf('b') != -1 }).length > 0;
		//let type = ["audio", "visual"][Math.floor(Math.random()*2)];
		let chosenPromptItems = ["stop", "go", "stop", "go", "stop", "go", "stop", "go", "stop", "go", "stop", "go", "stop", "go", "stop", "go"];
		//if (type == "audio") this.feedbackVerb = "hear";
		//else this.feedbackVerb = "see";

		while (timeTakenSoFar <= this.totalExerciseTimeSecs && limiter < limit) {
			limiter ++;
			let tempo = Math.ceil(Math.random()*5)+3; // Emma confirmed inhibition ALWAYS 3-8 seconds, unless the exercise itself is less than 8 seconds
			if (tempo + this.longestWordReadTimeSecs > this.totalExerciseTimeSecs && !sequence.length) {
				tempo = Math.ceil(Math.random()*(this.totalExerciseTimeSecs - this.longestWordReadTimeSecs))+1; // So create a 1-[totalExerciseTime - this.longestWordReadTimeSecs] tempo
			}
			if (timeTakenSoFar == 0) tempo += this.minInitialWaitSecs;
			timeTakenSoFar+=tempo;
			let chosenPrompt = chosenPromptItems.splice(0, 1)[0];
			sequence.push( { type:"hear", what:chosenPrompt, durationInSecs:tempo } );
			sequence.push( { type:"see", what:chosenPrompt, durationInSecs:0 } );
		}

		 this.addPreloadableImage('stop');
		this.addPreloadableImage('go');

		this.feedbackPrompt.push("Did you manage to stop and start when the prompts told you to?");
		
		//Set helper prompts
		this.helperPrompt.push("When you hear/see STOP, don't move. When you hear/see GO, resume the exercise.");

		return sequence;
	}


	////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////// HELPER AND PROCESSOR FUNCTIONS
	
	////////////////////////////////////
	setPrompts() {
		console.log(" >>>>>>>> >>>>>>>>> >>>>>>> SETTING PROMPTS");
		let source:any;
		let distractorCat;
		switch (this.cinstr.mode) {
			case "Initial":
				if (this.cinstr.cue == "visuals") {
					this.chosenPromptCat = this.chosenPrompt = this.getMeARandoFromObject(this.visuals);
					this.distractorPrompt = null;
				} else {
					this.chosenPromptCat = this.chosenPrompt = this.getMeARandoFromObject(this.audio);
					this.distractorPrompt = null;
				}
			break;

			case "Audio":
			case "Visual":
				if (this.cinstr.mode == "Audio") source = this.audio;
				else source = this.visuals;

				// Chosen Prompt
				if (["Specific word", "Specific image"].indexOf(this.cinstr.cue_to_count) != -1) {
					this.chosenPromptCat = this.getMeARandoFromObject(source);
					this.chosenPrompt = { key:this.chosenPromptCat.key, items:[this.getMeARandoFromArray(this.chosenPromptCat.items)] };
				}
				else if (["Word category", "Image category"].indexOf(this.cinstr.cue_to_count) != -1) {
					this.chosenPromptCat = this.chosenPrompt = this.getMeARandoFromObject(source);
				}

				// Distractor Prompt
				if (!this.cinstr.cue_to_ignore) {
					this.distractorPrompt = { key:'none', items:[] };
				}
				else if (["Specific word", "Specific image"].indexOf(this.cinstr.cue_to_ignore) != -1) {
					distractorCat = this.getMeARandoFromObjectThatIsntThisOne(source, this.chosenPromptCat);
					this.distractorPrompt = { key:distractorCat.key, items:[(distractorCat.items as any).getMeARando()] };
				}
				else if (["Distractor words", "Distrator words", "Word category", "Distractor image", "Image category"].indexOf(this.cinstr.cue_to_ignore) != -1) {
					this.distractorPrompt = this.getMeARandoFromObjectThatIsntThisOne(source, this.chosenPromptCat);
				}

				// If Chosen Prompt type is Specific Word, there is a slight chance that it clashes with a distractor (as we cull the list of items to 1 chosen), so let's check now
				if (this.cinstr.cue_to_count.toLowerCase() == "specific word") {
					let testTarget = this.getAPrompt(this.chosenPrompt.key, this.distractorPrompt.key, false);
					if (!testTarget) this.setPrompts();
				}
			break;

			case "Recall":
				this.chosenPromptCat = this.getMeARandoFromObject(this.audio);
				this.chosenPrompt = { key:this.chosenPromptCat.key, items:this.chosenPromptCat.items };
				this.distractorPrompt = { key:'none', items:[] };
			break;

			case "Pattern":
				this.chosenPromptCat = this.visuals.suits;
				this.chosenPrompt = { key:'suits', items:this.chosenPromptCat };
				this.distractorPrompt = { key:'none', items:[] };
			break;

			case "Inhibition":
				this.chosenPrompt = { key:"stop_and_go", items:null };
				this.distractorPrompt = { key:'none', items:[] };
			break;
		}
	}

	////////////////////////////////////
	unsetPrompts() {
		this.chosenPrompt = null;
		this.distractorPrompt = null;
		this.feedbackPrompt = [];
	}

	////////////////////////////////////
	getAPrompt(chosKey, distKey, isDistractor = false) {
		let limiter = 0; let limit = 80;
		let prompt; let wPrompt;
		let hasLetter = false;
		let hasNonLetter = false;
		let letterKeys = ["c","l","p"];
		let nonletterKeys = ["animals", "fruit and vegetables", "musical instruments'", "transport"];
		let goodKey = isDistractor && chosKey || distKey;

		console.groupCollapsed("%cCalculating "+(isDistractor && "Distractor" || "Chosen")+" prompt", "color:magenta");
		console.log("-- Distractor Group:", distKey);
		console.log("-- Chosen Group:", chosKey);
		while (!prompt && limiter < limit) {
			limiter += 1;
			wPrompt = this.getMeARandoFromArray(isDistractor && this.distractorPrompt.items || this.chosenPrompt.items);
			hasLetter = (letterKeys.indexOf(distKey) != -1 || letterKeys.indexOf(chosKey) != -1) && true || false;
			hasNonLetter = (nonletterKeys.indexOf(distKey) != -1 || nonletterKeys.indexOf(chosKey) != -1) && true || false;

			if (hasLetter && hasNonLetter) {
				switch (goodKey) {
					case "c":
					case "l":
					case "p":
						if (wPrompt[0].toLowerCase() != goodKey) prompt = wPrompt;
						else console.log(">>>>>>> Denied choice:", wPrompt);
					break;
					default:
						//if (!this.audio[goodKey].includes(wPrompt)) prompt = wPrompt;
						let wPromptNotFound = this.audio[goodKey].indexOf(wPrompt) == -1;
						if (wPromptNotFound) prompt = wPrompt;
						else console.log(">>>>>>> Denied choice:", wPrompt);
					break;
				}
			} else {
				prompt = wPrompt;
			}
		}
		console.log("%cChose prompt:", "color:magenta", prompt);
		console.groupEnd();
		
		return prompt;
	}
	
	////////////////////////////////////
	getExerciseTime(instr:any) {
		let beat = 60 / parseInt(instr.tempo);
		let totes:number = 0;
		if (instr.mode == "static_duration") {
			totes = beat * parseInt(instr.reps);
		} else {
			totes = 0;
			instr.floorSequence.forEach(fs => {
				let multiplier = fs.multiplier;
				if (!multiplier) multiplier = 1;
				totes += multiplier * parseInt(instr.reps) * beat;
			});
		}
		this.totalExerciseTimeSecs = totes - (this.longestWordReadTimeSecs + 0.25); // Subtract 'longestWordReadTimeSecs' (and a buffer) to ensure that the cognitive fits despite timer adjustments
		console.log("%c >> Cognitive Calculated total time / totalExerciseTimeSecs", "color:purple", this.totalExerciseTimeSecs);
	}

	////////////////////////////////////
	getTempoYo(tempoDiff:number =0, useParentTempo:boolean = false) {
		let tempo;
		if (useParentTempo) {
			tempo = this.getMeARandoFromArray(this.cinstr.tempos, true);
			tempo = tempo * this.parentExerciseBeat;
		} else {
			tempo = this.getMeARandoFromArray(this.cinstr.tempos, true) + tempoDiff;
		}
		return tempo;
	}

	////////////////////////////////////
	getMeARandoFromObject(object) {
		let keys = Object.keys(object);
		let theKey = keys[Math.floor(Math.random()*keys.length)];
		return { key:theKey, items:object[theKey] };
	}

	////////////////////////////////////
	getMeARandoFromObjectThatIsntThisOne(object, theOneWeDontWant) {
		let keys = Object.keys(object);
		let theKey = keys[Math.floor(Math.random()*keys.length)];
		
		while (theKey == theOneWeDontWant.key) { theKey = keys[Math.floor(Math.random()*keys.length)]; };
		return { key:theKey, items:object[theKey] };
	}

	////////////////////////////////////
	cleanTempos(tempos) {
		let tempTempos = [];
		tempos.forEach(tempo => {
			if (tempo.indexOf("_") != -1) {
				let tempoRange = [];
				tempo = tempo.split("_");
				tempo.sort((a, b) => { if (a < b) return -1; else return 1; })
				let min = tempo[0]; let max = tempo[tempo.length-1];
				let inc = (max - min) / 10;
				for (let x=0; x<10; x++) { tempTempos.push(min+inc); }
			} else if (tempo.indexOf(",") != -1) {
				tempo = tempo.split(",");
				tempo.forEach(tmp => tempTempos.push(tmp));
			} else {
				tempTempos.push(tempo);
			}
		});
		return tempTempos;
	}

	////////////////////////////////////
	addPreloadableImage(item) {
		if (this.preloadableImages.indexOf(item) == -1) this.preloadableImages.push(item);
	}

	////////////////////////////////////
	getMeARandoFromArray(array, numeric:boolean = false) {
		let rando = array[Math.floor(Math.random()*array.length)];
		if (numeric) rando = parseFloat(rando);
		return rando;
	}
	
	
	////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////// HANDY FUNCTIONS

	////////////////////////////////////
	private isBrowser() {
		return window['cordova_custom'].device() == 'browser';
	}
}