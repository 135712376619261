///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
////////// IMPORTS
import { Component, OnInit, AfterViewInit, ViewChild, NgZone, ElementRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBluewaveComponent } from '../../components/form-bluewave/form-bluewave.component';
import { ExerciseService } from '../../services/exercise.service';
import { TimeCircleComponent, TimerCompletionBehaviour, TimerDirection, TimerMode } from '../../components/time-circle/time-circle.component';
import { repEventTypeName, ShortSchedule } from '../../st-commons/shortSchedule';
import { GlobalPubSub } from '../../services/global-pub-sub.service';
import { UserService } from '../../services/user.service';
import { environment } from '../../../environments/environment';
import { FileStream } from 'src/app/objects/filestream';
import { VgPlayerHandlerComponent } from 'src/app/components/vg-player-handler/vg-player-handler.component';
import { Vasat } from 'vasat';
import { DeviceService } from 'src/app/services/device.service';
import { DebugLoggerService } from '../../services/debugLogger.service';
import { DomSanitizer } from '@angular/platform-browser';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
	selector: 'app-page-exercise3main',
	templateUrl: './page-exercise3main.component.html',
	styleUrls: ['./page-exercise3main.component.scss'],
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class PageExercise3mainComponent implements OnInit, OnDestroy, AfterViewInit {
	////////////////////////////////
	@ViewChild('bluewaveform') bluewaveform: FormBluewaveComponent;
	@ViewChild('bluewaveformBalance') bluewaveformBalance: FormBluewaveComponent;
	@ViewChild('timeCircle') timeCircle: TimeCircleComponent;
	@ViewChild('timeCircleRest') timeCircleRest: TimeCircleComponent;
	@ViewChild('timeCirclePause') timeCirclePause: TimeCircleComponent;
	@ViewChild('animationcomponent') animationcomponent: HTMLElement;
	@ViewChild('animation') animation;
	@ViewChild('btCountdown') btCountdown;
	@ViewChild('btStart') btStart;
	@ViewChild('timeoutmenu') timeoutmenu;
	@ViewChild('videoHandler') videoHandler: VgPlayerHandlerComponent;

	////////////////////////////////
	skipExerciseDebugProdCounter: number = 0;

	////////////////////////////////
	exercise: any;
	exerciseIndex: number;
	cognitiveName: string | null = null;
	balanceName: string | null = null;
	balanceNameAddition: string = "";
	exerciseHelpJSON: any;
	scheduledAnims: ShortSchedule;
	cogTips: any[] = [];
	balanceTips: any[] = [];
	showFeet: boolean = true;
	footSource: string = 'assets/img/icons/feet/feet_none.png';
	trialName: string | null = null;
	trialTips: any = [];

	//////////////////// Rating Sets
	restStartTime: Date | null;
	restTimerStarted: boolean = false;
	ratingModalChoice: boolean | null = null;
	ratingSet;
	ratingSetDefault = {
		restWarningRatingValue: 0, // Do not show the rest warning
		modalQuestion: 'Did you need to use a chair to complete the exercise?',
		modalAnswer1: 'No',
		modalAnswer2: 'Yes',
		modalKey: 'usedChair',
		modalIcon1: 'ico_exercise_white',
		modalIcon2: 'ico_chair_white',
		rating1: 'Unstable',
		rating1Desc: this.sanitizer.bypassSecurityTrustHtml(
			'Selecting <strong>1</strong> suggests you "felt unstable" or you could <strong>only</strong> complete the exercise while holding on for support. <strong>This is automatically selected if you needed a chair for this exercise.</strong>'
		),
		rating2: 'Somewhat Stable',
		rating2Desc: this.sanitizer.bypassSecurityTrustHtml(
			'Selecting <strong>2</strong> suggests you "felt somewhat stable" but found the exercise quite challenging or had to hold on for support.'
		),
		rating3: 'Mostly Stable',
		rating3Desc: this.sanitizer.bypassSecurityTrustHtml(
			'Selecting <strong>3</strong> suggests you "felt mostly stable" but the exercise was challenging <strong>without</strong> holding on for support.'
		),
		rating4: 'Stable',
		rating4Desc: this.sanitizer.bypassSecurityTrustHtml(
			'Selecting <strong>4</strong> suggests you "felt stable" and the exercise was fairly easy to complete <strong>without</strong> holding on for support.'
		),
		rating5: 'Very Stable',
		rating5Desc: this.sanitizer.bypassSecurityTrustHtml(
			'Selecting <strong>5</strong> suggests you "felt very stable" and you completed this exercise easily <strong>without</strong> holding on	for support.'
		),
	};
	ratingSetStrength = {
		restWarningRatingValue: 3,
		modalQuestion: 'Did you use weights for this exercise?',
		modalAnswer1: 'No',
		modalAnswer2: 'Yes',
		modalKey: 'usedWeights',
		modalIcon1: 'ico_noweights_white',
		modalIcon2: 'ico_weights_white',
		rating1: 'Very Hard',
		rating1Desc:
			'Selecting 1 suggests that you felt the exercise was very hard. You needed to take breaks to give your muscles a rest. You were able to complete less than half of the exercises in the set time.',
		rating2: 'Hard',
		rating2Desc:
			'Selecting 2 suggests that you felt the exercise was hard. You may have needed to take breaks to give your muscles a rest. You  were able to complete more than half of the exercises in the set time.',
		rating3: 'Somewhat Hard',
		rating3Desc:
			'Selecting 3 suggests that you felt the exercise was somewhat hard. You may have needed to take a break to give your muscles a rest. You may have felt it challenging to complete the exercises in the set time.',
		rating4: 'Fairly Light',
		rating4Desc:
			"Selecting 4 suggests that you felt the exercise was fairly light. You didn't need to take a break. You may have felt you could continue exercising beyond the set time.",
		rating5: 'Very Light',
		rating5Desc:
			"Selecting 5 suggests that you felt the exercise was very light. You didn't need to take a break. You would have been able to continue comfortably beyond the set time.",
	};
	ratingSetCardio = {
		restWarningRatingValue: 0,
		modalQuestion: null,
		modalAnswer1: null,
		modalAnswer2: null,
		modalKey: null,
		modalIcon1: null,
		modalIcon2: null,
		rating1: 'Very Hard',
		rating1Desc: 'Selecting 1 suggests that you felt the exercise was very hard. You may have needed to take rest breaks to get your breath back.',
		rating2: 'Hard',
		rating2Desc:
			'Selecting 2 suggests that you felt the exercise was hard. Your breath quickened and you felt your heartbeat go faster. You would feel unable to say more than a few words without pausing for breath.',
		rating3: 'Somewhat Hard',
		rating3Desc:
			'Selecting 3 suggests that you felt the exercise was somewhat hard. Your breath quickened, and you may have felt your heartbeat go faster. You would be able to talk but not sing during the activity.',
		rating4: 'Fairly Light',
		rating4Desc:
			'Selecting 4 suggests that you felt the exercise was fairly light. Your breath quickened, and you may have felt your heartbeat go slightly faster. You would be able to talk and sing comfortably during the activity.',
		rating5: 'Very Light',
		rating5Desc:
			'Selecting 5 suggests that you felt the exercise was very light. Your breath barely quickened, and you did  not feel your heartbeat go faster. You would be able to talk as if you were resting.',
	};

	////////////////////////////////
	countdownStarted: boolean = false;
	countdownNum: string = '3';
	started: boolean = false;
	midExerciseSets: boolean = false;
	exerciseMarkedComplete: boolean = false;
	baMarkedComplete: boolean = false;
	triedToSaveOnce: boolean = false;
	individualBAExercises: any = {};
	durationScore: any = null;
	exerciseIterationDuration: number = 0;
	exerciseIterations: number = 0;
	exerciseDisplayIterations: number = 1;
	exerciseMaxIterations: number = 3;
	whichState: string = 'exercise';
	quitMessage: string;
	quitButton: string;
	homeRoute: string = '';
	nextRoute: string = '';
	howtoRoute: string | null = '';
	checkExerciseFunc: any;
	completeFunc: any;
	stateSavingExercise: boolean = false;
	maximumSessionTime: number;
	howtoPageState: number = 0;
	videoObjects: any[] = [];
	activeVideoObject: any;
	exerciseHelpArray: any = [];
	resultsNumbers: number[] = Array(31)
		.fill(1)
		.map((i, ind) => (i += ind - 1));

	////////////////////////////////
	constructor(
		private V: Vasat,
		private router: Router,
		private sExercise: ExerciseService,
		private zone: NgZone,
		private elRef: ElementRef,
		private sGlobalPubSub: GlobalPubSub,
		private sUser: UserService,
		private sDevice: DeviceService,
		private sDebugLogger: DebugLoggerService,
		private sanitizer: DomSanitizer
	) { }

	ngOnInit() {
		this.maximumSessionTime = this.sExercise.exerciseTimeoutTime().total.mins;
		this.animation.setExerciseService(this.sExercise);

		// Balance Assessment Session
		if (this.router.url == '/exercises/balance/main') {
			this.whichState = 'balance';
			this.homeRoute = 'exercises/balance/home';
			this.nextRoute = 'exercises/balance/main';
			this.howtoRoute = 'exercises/balance/howto';
			this.completeFunc = this.exerciseCompleteBalance.bind(this);
			this.checkExerciseFunc = this.sExercise.hasExistingBalanceAssessment;
			this.individualBAExercises = {};
			this.quitMessage = 'Your balance assessement will be lost if you quit.';
			this.quitButton = 'I want to quit and return home';

			// Trial Exercise Session
		} else if (this.router.url == '/exercises/trial/main') {
			this.whichState = 'trial';
			this.homeRoute = 'exercises/trial/home';
			this.nextRoute = 'exercises/trial/main';
			this.howtoRoute = null;
			this.completeFunc = this.exerciseCompleteTrial.bind(this);
			this.checkExerciseFunc = () => true;
			this.individualBAExercises = {};
			this.quitMessage = 'You may retry the Trial at any time.';
			this.quitButton = 'I want to quit and return home';

			// Regular Exercise Session
		} else {
			this.whichState = 'exercise';
			this.homeRoute = 'exercises/home';
			this.nextRoute = 'exercises/main';
			this.howtoRoute = 'exercises/howto';
			this.completeFunc = this.exerciseComplete.bind(this);
			this.checkExerciseFunc = this.sExercise.hasExistingSession;
			this.quitMessage = 'Any exercises you have completed so far will be recorded.';
			this.quitButton = 'Save and Quit';
		}

		if (!this.checkExerciseFunc.bind(this.sExercise)()) {
			this.router.navigate([this.homeRoute]);
		} else {
			this.prepareExercise();
		}

		this.sGlobalPubSub.subscribe('cordovapause', this.cordovaPause.bind(this));
		this.sGlobalPubSub.subscribe('sessionInvalidated', this.sessionInvalidated.bind(this));

		// Stop the screen from sleeping
		if (window['plugins'] && window['plugins'].insomnia) window['plugins'].insomnia.keepAwake();
	}

	////////////////////////////////////
	ngOnDestroy() {
		this.exercise = null;

		this.sGlobalPubSub.unsubscribeAllFromEvent('cordovapause');
		this.sGlobalPubSub.unsubscribeAllFromEvent('sessionInvalidated');
		this.clearAnimationAndSchedule();

		// Allow screen sleep again
		if (window['plugins'] && window['plugins'].insomnia) window['plugins'].insomnia.allowSleepAgain();
	}
	onBackButton() {
		this.requestGoHome();
	}
	cordovaPause() {
		this.pauseExercise();
	}

	////////////////////////////////////
	prepareExercise() {
		this.balanceNameAddition = "";
		this.exerciseDisplayIterations = 1;
		this.exerciseMarkedComplete = false;
		this.baMarkedComplete = false;

		this.bluewaveform.close();
		this.bluewaveformBalance.close();
		this.countdownStarted = false;
		this.started = false;
		this.midExerciseSets = false;

		this.videoObjects = [];
		this.exerciseHelpArray = [];

		if (this.animation.cognitiveComponent) this.animation.cognitiveComponent.unsetPrompts();

		setTimeout(() => this.bluewaveform.setFormContentState('pause'), 1000);

		// If a regular exercise
		if (this.whichState == 'exercise') {
			this.exercise = this.sExercise.getCurrentExerciseAction();
			this.exerciseIndex = this.sExercise.getCurrentExerciseActionIndex();
			this.exerciseIterations = 0;
			this.exerciseDisplayIterations = 1;
			this.exerciseHelpJSON = this.sExercise.getHelp(this.sExercise.getCurrentExerciseAction());

			// Loop through exerciseHelpJSON (there should only be 1 or 2 elements) and set names
			for (let i = 0; i < this.exerciseHelpJSON.length; i++) {
				//Set name based on category uid
				if (this.exerciseHelpJSON[i].exercise.category.uid == 'cog') {
					this.cognitiveName = 'Cognitive: ' + this.exerciseHelpJSON[i].name;
				} else {
					this.balanceName = this.exerciseHelpJSON[i].name;
					this.balanceNameAddition = this.sExercise.generateGridAndDartboardName(this.exercise);
				}
			}

			this.setTips();
			this.setAnimation(this.exercise);
			this.setFootIcon(this.exercise.instructions);
			this.exerciseIterationDuration = (this.exercise.duration * 60) / 3;
			this.initHowToExercise();
			this.prepareQuickRefreshHelp();

			// If a Trial Exercise
		} else if (this.whichState == 'trial') {
			this.exercise = this.sExercise.getCurrentExerciseAction();
			this.exerciseIndex = this.sExercise.getCurrentExerciseActionIndex();
			this.exerciseIterations = 0;
			this.exerciseDisplayIterations = 1;
			this.exerciseHelpJSON = this.sExercise.getHelp(this.sExercise.getCurrentExerciseAction());

			// Loop through exerciseHelpJSON (there should only be 1 or 2 elements) and set names
			for (let i = 0; i < this.exerciseHelpJSON.length; i++) {
				if (this.exerciseHelpJSON[i].exercise.category.uid == 'cog') {
					this.cognitiveName = 'Cognitive: ' + this.exerciseHelpJSON[i].name;
				} else {
					this.trialName = this.exerciseHelpJSON[i].name;
				}
			}

			this.setTips();
			this.setAnimation(this.exercise);
			this.setFootIcon(this.exercise.instructions);
			this.exerciseIterationDuration = (this.exercise.duration * 60) / 3;
			this.initHowToExercise();
			this.prepareQuickRefreshHelp();
		}

		// Otherwise it's Balance Assessment
		else {
			this.setBATips();
			this.exercise = this.sExercise.getNextBalanceAssessmentExercise();
			this.balanceName = this.exercise.exerciseName;
			this.setAnimation(this.exercise);
			this.setFootIcon(this.exercise);
			this.exerciseIterationDuration = this.exercise.duration * 60;
			this.initHowToBalance();
		}

		setTimeout(() => this.timeCircle.createTimer(this.getTimeCircleProperties()), 1000);
		this.setRatingLabels();
	}

	ngAfterViewInit() { }

	////////////////////////////////
	getTimeCircleProperties() {
		if (this.whichState == 'balance') {
			return {
				timerMode: TimerMode.time,
				direction: TimerDirection.countup,
				totalValue: 30,
				completionBehaviour: TimerCompletionBehaviour.reset,
				completionCallback: this.completeFunc,
			};
		} else {
			switch (this.exercise.exercise.category.uid) {
				case 'cardio':
					return {
						timerMode: TimerMode.time,
						direction: TimerDirection.countdown,
						totalValue: this.exerciseIterationDuration,
						completionBehaviour: TimerCompletionBehaviour.resetfade,
						completionCallback: this.completeFunc,
					};

				case 'upbodystr':
				case 'lowbodystr':
					let repEvents = this.animation.animSchedule.scheduleds.filter((sched) => sched.params.type == repEventTypeName);
					let repCount = repEvents.length;
					return {
						timerMode: TimerMode.reps,
						direction: TimerDirection.countdown,
						totalValue: repCount,
						completionBehaviour: TimerCompletionBehaviour.resetfade,
						completionCallback: this.completeFunc,
					};

				default:
					return {
						timerMode: TimerMode.percentage,
						direction: TimerDirection.countup,
						totalValue: this.exerciseIterationDuration,
						completionBehaviour: TimerCompletionBehaviour.resetfade,
						completionCallback: this.completeFunc,
					};
			}
		}
	}

	////////////////////////////////
	getDisplayDuration() {
		if (!this.exercise) return 0;
		let duration = this.exerciseIterationDuration / 60;
		if (duration > 1) {
			let mins: any = Math.floor(duration);
			let secs: any = duration * 60 - mins * 60;
			if (mins) mins = mins + ' minutes';
			else mins = '';
			if (secs) secs = Math.round(secs) + ' seconds';
			else secs = '';
			if (mins && secs) mins = mins + ', ';
			return mins + secs;
		} else {
			return Math.round(duration * 60) + ' seconds';
		}
	}

	////////////////////////////////
	requestStartExercise() {
		// Force an exercise timeout check
		if (this.sExercise.exerciseHasTimedOut()) return false;

		if (this.btStart.nativeElement.classList.contains('loading')) return false;
		if (this.started) return false;
		this.countdownStarted = true;
		setTimeout(() => {
			this.countdownNum = '2';
		}, 1000);
		setTimeout(() => {
			this.countdownNum = '1';
		}, 2000);
		setTimeout(() => {
			this.countdownNum = 'GO';
		}, 3000);
		setTimeout(() => {
			this.startExercise();
		}, 3750);
		setTimeout(() => {
			this.countdownNum = '3';
		}, 4000);
	}

	////////////////////////////////
	startExercise() {
		this.countdownStarted = false;
		this.exerciseIterations++;
		this.started = true;
		this.midExerciseSets = true;

		//this.timeCircle.setTimer(this.exerciseIterationDuration, this.completeFunc.bind(this), null);
		this.timeCircle.setRunning();
		if (this.scheduledAnims) this.scheduledAnims.start();
	}

	////////////////////////////////////
	clearAnimationAndSchedule() {
		if (this.timeCircle) this.timeCircle.reset();
		if (this.scheduledAnims) this.scheduledAnims.stop();
		if (this.animation) this.animation.cleanup();
	}

	////////////////////////////////
	exerciseComplete() {
		// Reset the timeout feature and stop the animation
		this.scheduledAnims && this.scheduledAnims.stop();
		this.ratingModalChoice = null;

		// Wait a bit and play the duh-ding
		setTimeout(() => {
			this.animation.audioController.playAudio('duh-ding');

			// Then a second later, get ready for the next exercise
			setTimeout(() => {
				this.timeCircle.reset();
				this.animation.cleanup();
				this.exerciseDisplayIterations = Math.min(3, this.exerciseDisplayIterations + 1);
			}, 1000);

			if (this.exerciseIterations >= this.exerciseMaxIterations) {
				setTimeout(() => {
					this.countdownStarted = false;
					this.started = false;
					this.exercise.usedChair = null;
					this.exercise.usedWeights = null;
					this.bluewaveform.open();
					this.bluewaveform.setFormContentState('complete');
				}, 650);
			} else {
				setTimeout(() => {
					this.setAnimation(this.exercise);
					this.countdownStarted = false;
					this.started = false;
					//this.timeCircle.setStatic(this.exerciseIterationDuration);
				}, 1100);

				if (this.exercise.instructions.cognitive) {
					setTimeout(() => {
						this.bluewaveform.setFormContentState('cognitivefeedback');
						this.bluewaveform.open();
					}, 500);
				}
			}
		}, 500);
	}

	////////////////////////////////////
	exerciseCompleteBalance(timerRes) {
		this.timeCircle.setStopped();
		this.durationScore = timerRes.totalDuration;
		this.bluewaveformBalance.open();
		this.bluewaveformBalance.setFormContentState('complete');

		// Completion sound
		this.animation.audioController.playAudio('duh-ding');
	}

	////////////////////////////////////
	exerciseCompleteTrial(duration, skipCog: boolean = false) {
		// Reset the timeout feature and stop the animation
		this.scheduledAnims.stop();

		// Completion sound
		this.animation.audioController.playAudio('duh-ding');

		// Wait a bit before moving on
		setTimeout(() => {
			if (this.animation.cognitiveComponent && !skipCog) {
				this.bluewaveform.open();
				this.bluewaveform.setFormContentState('trialcognitivefeedback');
				this.animation.cleanup();
			} else {
				this.animation.cleanup();
				this.nextTrialExercise();
			}
		}, 1250);
	}

	////////////////////////////////
	requestGoHome() {
		this.bluewaveform.open();
		this.bluewaveform.setFormContentState('home');
	}

	////////////////////////////////
	pauseExercise(intendedBluewaveformState: string = 'pause') {
		// If countdown has started, or the bluewave is not open
		if (this.countdownStarted) return false;
		if (this.bluewaveform.closingState) return false;

		// If the exercise is already paused (and is opening or already opened)
		if (
			(this.bluewaveform.formContentState == 'pause' || this.bluewaveform.formContentState == 'howto') &&
			(this.bluewaveform.openState == true || this.bluewaveform.openingState == true)
		) {
			return false;
		}

		// If exercise is completed and form is in a completed state
		if (this.bluewaveform.formContentState == 'complete') {
			return false;
		}

		this.bluewaveform.open();
		this.bluewaveform.setFormContentState(intendedBluewaveformState);

		if (this.started) {
			this.timeCircle.setStopped();

			if (this.whichState == 'balance') {
				this.scheduledAnims.setToInitialState();
			}
			if (this.scheduledAnims) this.scheduledAnims.pause();
		}

		if (intendedBluewaveformState == 'howto') this.setHowtoPageState(0);

		//this.timeCirclePause.setRunning();
		setTimeout(() => {
			let times = this.sExercise.exerciseTimeoutTime();
			this.timeCirclePause.createTimer({
				timerMode: TimerMode.time,
				direction: TimerDirection.countdown,
				totalValue: times.remaining.secs,
				completionBehaviour: TimerCompletionBehaviour.resetfade,
				completionCallback: this.sessionInvalidated.bind(this),
			});

			this.timeCirclePause.setRunning();
		}, 1000);
	}

	////////////////////////////////
	unPauseExercise() {
		// Force an exercise timeout check
		if (this.sExercise.exerciseHasTimedOut()) return false;

		if (this.started) {
			if (this.whichState == 'balance' || this.whichState == 'exercise' || this.whichState == 'trial') {
				//this.timeCircle.setTimer(this.exerciseIterationDuration, this.completeFunc.bind(this), null);
				this.timeCircle.setRunning();
			} else {
				//this.timeCircle.resumeTimer();
				this.timeCircle.setStopped();
			}
			if (this.scheduledAnims) this.scheduledAnims.start();
		}
		this.timeCirclePause.setStopped();
		this.bluewaveform.close();

		if (this.videoHandler != null) this.videoHandler.video.nativeElement.pause();
	}

	////////////////////////////////
	hardExit(skipSave: boolean = false) {
		if (skipSave || this.whichState == 'balance' || this.whichState == 'trial') {
			this.clearTimers();
			this.router.navigate(['/home']);
		} else {
			this.saveAndExit('/home');
		}
	}

	////////////////////////////////
	clearTimers() {
		if (this.timeCirclePause && this.timeCirclePause.timerCreated) {
			this.timeCirclePause.reset();
			this.timeCirclePause.setStopped();
		}
		if (this.timeCirclePause && this.timeCircle.timerCreated) {
			this.timeCircle.reset();
			this.timeCircle.setStopped();
		}
		if (this.timeCircleRest && this.timeCircleRest.timerCreated) {
			this.timeCircleRest.reset();
			this.timeCircleRest.setStopped();
		}

		if (this.scheduledAnims) {
			this.scheduledAnims.stop();
			this.scheduledAnims = null;
		}
	}

	////////////////////////////////
	continueSession() {
		this.bluewaveform.close();
	}

	////////////////////////////////
	rateExercise(rating: number) {
		this.exercise.rating = rating;

		// To fix an issue where the scroller becomes unresponsive - forces a redraw.
		setTimeout(
			() =>
			((document.querySelector('form-bluewave.singleScreen .scroller') as HTMLElement).style.transform =
				'translate3d(0, 0, ' + Math.floor(Math.random() * 100) + 'px)'),
			250
		);

		if (this.exercise.rating <= this.ratingSet.restWarningRatingValue) {
			if (!this.restStartTime) this.restStartTime = new Date();
			if (!this.restTimerStarted) {
				this.restTimerStarted = true;

				this.timeCircleRest.createTimer({
					timerMode: TimerMode.time,
					direction: TimerDirection.countdown,
					totalValue: 60,
					completionBehaviour: TimerCompletionBehaviour.pause,
				});
				this.timeCircleRest.setRunning();
			}
		}
	}

	////////////////////////////////
	modalRatingSelect(modalValue: boolean) {
		// Set the visible modal choice (the actual value is saved on submit)
		this.ratingModalChoice = modalValue;

		if (this.ratingSet.modalKey == 'usedChair' && modalValue == true) {
			this.exercise.rating = 1;
		}
	}

	////////////////////////////////
	disabledDueToModal() {
		return this.ratingSet.modalKey == 'usedChair' && this.ratingModalChoice == true;
	}

	////////////////////////////////
	userConfirmComplete() {
		if (this.exercise.rating === null || this.exercise.rating === undefined) {
			this.sGlobalPubSub.fireEvent('toast', [true, 'Please confirm your results before continuing.', 2500]);
			return;
		}

		if (this.exerciseMarkedComplete) return;
		this.exerciseMarkedComplete = true;
		this.exercise.complete = true;
		this.exercise.exerciseEndTime = new Date().getTime();
		if (this.ratingSet.modalKey) this.exercise[this.ratingSet.modalKey] = this.ratingModalChoice;
		this.restStartTime = null;
		this.restTimerStarted = false;
		this.clearTimers();

		// Force an exercise timeout check
		if (this.sExercise.exerciseHasTimedOut()) return false;

		// If there are more exercises
		if (this.sExercise.getCurrentExerciseAction()) {
			let exerciseAction = this.sExercise.getCurrentExerciseAction();
			this.sExercise.prechooseDualIndex(exerciseAction.instructions);
			this.prepareExercise();
		}

		// If this was the last exercise
		else {
			this.saveAndExit('exercises/completed');
		}
	}

	////////////////////////////////
	nextTrialExercise() {
		console.log('User has confirmed Trial Exercise Complete');
		this.exerciseMarkedComplete = true;
		this.exercise.complete = true;

		// If there are more exercises
		if (this.sExercise.getCurrentExerciseAction()) {
			let exerciseAction = this.sExercise.getCurrentExerciseAction();
			this.sExercise.prechooseDualIndex(exerciseAction.instructions);
			this.prepareExercise();
		}

		// If this was the last trial exercise
		else {
			this.router.navigate(['exercises/trial/completed']);
		}
	}

	////////////////////////////////
	saveLocalSession() {
		let resolver = function (resolve, reject) {
			let goSave = function () {
				this.stateSavingExercise = true;
				this.sExercise
					.saveSession()
					.then(() => {
						this.stateSavingExercise = false;
						resolve();
					})
					.catch((err) => {
						this.stateSavingExercise = false;
						reject(err);
					});
			}.bind(this);

			goSave();
		}.bind(this);
		return new Promise(resolver);
	}

	////////////////////////////////
	saveAndExit(route) {
		this.saveLocalSession()
			.then(() => {
				this.clearTimers();
				this.router.navigate([route]);
			})
			.catch((err) => {
				let printableErr = (err && err.message) || err || 'Unknown server issue';
				console.log(err);
				console.log(err.message);
				console.log(printableErr);
				this.sGlobalPubSub.fireEvent('toast', [
					true,
					'Cannot quit right now: there was a problem saving your completed exercises: ' +
					printableErr +
					'.  Please check that you have a stable Internet connection and try again.',
					10000,
				]);
				this.triedToSaveOnce = true;
				this.exerciseMarkedComplete = false;
			});
	}

	////////////////////////////////
	skipExerciseDebug() {
		if (environment.debug) {
			this.finishSkipExerciseDebug();
		} else {
			this.skipExerciseDebugProdCounter += 1;
			setTimeout(() => (this.skipExerciseDebugProdCounter = 0), 5000);

			if (this.skipExerciseDebugProdCounter >= 3) {
				this.finishSkipExerciseDebug();
			}
		}
	}

	////////////////////////////////
	finishSkipExerciseDebug() {
		this.exerciseIterations = 4;
		this.timeCircle.reset();
		this.timeCircle.setStopped();
		this.completeFunc({ totalDuration: 1 });
	}

	////////////////////////////////////
	advanceExerciseDebug() {
		if (environment.debug) {
			this.exerciseIterations = 2;
			this.timeCircle.reset();
			this.timeCircle.setStopped();
			this.completeFunc({ totalDuration: 1 });
		}
	}

	////////////////////////////////////
	copyJSONDebug() {
		if (!environment.debug) return false;

		let json = JSON.stringify(this.exercise.instructions);

		var textArea = document.createElement('textarea');
		textArea.style.position = 'fixed';
		textArea.style.top = '0';
		textArea.style.left = '0';
		textArea.style.width = '2em';
		textArea.style.height = '2em';
		textArea.style.padding = '0';
		textArea.style.border = 'none';
		textArea.style.outline = 'none';
		textArea.style.boxShadow = 'none';
		textArea.style.background = 'transparent';
		textArea.value = json;

		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();

		try {
			var successful = document.execCommand('copy');
			var msg = successful ? 'successful' : 'unsuccessful';
			this.sGlobalPubSub.fireEvent('toast', [true, 'JSON instructions copied to clipboard', 2500]);
		} catch (err) {
			this.sGlobalPubSub.fireEvent('toast', [true, "JSON instructions coudn't be copied to clipboard", 2500]);
		}

		document.body.removeChild(textArea);
	}

	////////////////////////////////////
	sessionInvalidated() {
		this.clearTimers();
		setTimeout(() => {
			this.router.navigate([`/exercises/expired/${this.whichState}`]);
		}, 250);
	}

	////////////////////////////////////
	setTips() {
		let exCogDetails: any = null;
		let exBalanceDetails: any = null;

		//Loop through exerciseHelpJSON (there should only be 1 or 2 elements) and set names
		for (let i = 0; i < this.exerciseHelpJSON.length; i++) {
			//Set name based on category uid
			if (this.exerciseHelpJSON[i].exercise.category.uid == 'cog') {
				exCogDetails = this.exerciseHelpJSON[i];
			} else {
				exBalanceDetails = this.exerciseHelpJSON[i];
			}
		}

		this.cogTips = [];
		this.balanceTips = [];

		if (exCogDetails != null) {
			var cogKeys = Object.keys(exCogDetails);
			cogKeys.forEach((key) => {
				if (key.indexOf('question_title_') != -1) {
					let qid = key.replace('question_title_', '');
					if (exCogDetails['question_body_' + qid]) {
						this.cogTips.push({
							q: exCogDetails['question_title_' + qid],
							a: exCogDetails['question_body_' + qid],
						});
					}
				}
			});
		}

		if (exBalanceDetails != null) {
			var balanceKeys = Object.keys(exBalanceDetails);
			balanceKeys.forEach((key) => {
				if (key.indexOf('question_title_') != -1) {
					let qid = key.replace('question_title_', '');
					if (exBalanceDetails['question_body_' + qid]) {
						this.balanceTips.push({
							q: exBalanceDetails['question_title_' + qid],
							a: exBalanceDetails['question_body_' + qid],
						});
					}
				}
			});
		}
	}

	////////////////////////////////////
	setBATips() {
		this.balanceTips = [
			{
				q: 'Testing your balance',
				a: 'This is your monthly balance assessment. Hold the position for up to 30 seconds, otherwise tap the button at the bottom left to stop the timer.',
			},
		];
	}

	////////////////////////////////////
	setAnimation(exercise) {
		let width = this.animation.animationContainer.nativeElement.offsetWidth;
		let height = this.animation.animationContainer.nativeElement.offsetHeight;

		//this.zone.runOutsideAngular(() => {
		this.animation.setupAnimation
			.bind(this.animation)(exercise.instructions || exercise, width, height, this.exerciseIterations + 1)
			.then((scheduler: any) => {
				this.scheduledAnims = scheduler;
				this.scheduledAnims.pause();
				this.scheduledAnims.setToInitialState();
			});
		//})
	}

	////////////////////////////////////
	setFootIcon(instr) {
		if (instr.feet_icon) {
			this.showFeet = true;
			switch (instr.feet_icon) {
				case 'Feet Hip Width':
					this.footSource = 'assets/img/icons/feet/feet_hip_width.png';
					break;
				case 'Feet Together':
					this.footSource = 'assets/img/icons/feet/feet_together.png';
					break;
				case 'Near Tandem':
					this.footSource = 'assets/img/icons/feet/feet_near_tandem.png';
					break;
				case 'Tandem':
					this.footSource = 'assets/img/icons/feet/feet_tandem.png';
					break;
				case 'Left Leg':
					this.footSource = 'assets/img/icons/feet/feet_left_leg.png';
					break;
				case 'Right Leg':
					this.footSource = 'assets/img/icons/feet/feet_right_leg.png';
					break;
			}
		} else {
			this.showFeet = false;
			this.footSource = 'assets/img/icons/feet/feet_none.png';
		}
	}

	////////////////////////////////////
	closeForm(json) {
		this.bluewaveform.close();
	}

	////////////////////////////////////////////////////////////////////////
	//////////////////////////////////// BA Functions

	////////////////////////////////
	changeDuration() {
		this.bluewaveformBalance.setFormContentState('change');
	}

	////////////////////////////////
	confirmDuration() {
		if (this.baMarkedComplete) return;
		this.baMarkedComplete = true;
		this.sExercise.balanceAssessmentTotalDuration += parseInt(this.durationScore);
		this.individualBAExercises[this.balanceName] = this.durationScore;

		// Save this balance result, then null it out so we don't save it again.
		if (this.durationScore !== null) {
			this.sExercise.saveBalanceResult(this.exercise.exerciseName, this.exercise.type, this.durationScore);
			this.durationScore = null;
		}

		if (this.sExercise.getNextBalanceAssessmentExercise()) this.prepareExercise();
		//this.router.navigate([this.nextRoute]);
		else {
			this.sExercise
				.saveBalanceAssessment(this.individualBAExercises)
				.then(() => {
					this.router.navigate(['home']);
				})
				.catch((err) => {
					console.log('Error saving balance assessment', err);
					this.baMarkedComplete = false;
				});
		}
	}

	////////////////////////////////////
	confirmDurationEdit() {
		if (this.durationScore !== null && this.durationScore >= 0) {
			this.durationScore = parseInt(this.durationScore);
			this.bluewaveformBalance.setFormContentState('complete');
		} else this.sGlobalPubSub.fireEvent('toast', [true, "You must provide a result.  If you didn't balance at all, you can enter 0.", 2500]);
	}

	////////////////////////////////
	stopBalancing() {
		if (this.started) {
			const timerElapsed = this.timeCircle.getValues().elapsedTime || 1;
			const timerElapsedInSeconds = Math.floor(timerElapsed / 1000);
			this.durationScore = Math.round(timerElapsedInSeconds);
			this.timeCircle.setStopped();
			this.bluewaveformBalance.open();
			this.bluewaveformBalance.setFormContentState('complete');
		}
	}

	////////////////////////////////////
	/*toHowTo() {
		this.router.navigate([this.howtoRoute]);
	}*/

	////////////////////////////////////
	setHowtoPageState(pageState: number) {
		this.howtoPageState = pageState;

		switch (pageState) {
			case 0:
				setTimeout(() => {
					this.setActiveVideoObject(this.videoObjects[0]);
				}, 500);
				break;

			case 1:
				setTimeout(() => {
					this.displayExerciseHelp();
				}, 500);
				break;

			default:
				break;
		}

		if (pageState == 0) {
		}
	}

	////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////// HOW TO METHODS

	initHowToExercise() {
		// if (!this.sExercise.hasExistingSession()) this.router.navigate(['exercises/home']);
		// else {

		//Ejectors
		if (this.exerciseHelpJSON == null) return;
		if (this.exerciseHelpJSON.length == 0) return;

		//Loop through exerciseHelp (there should only be 1 or 2 elements) and set names
		for (let i = 0; i < this.exerciseHelpJSON.length; i++) {
			//Define the video object
			let newVideoObject = {
				name: null,
				buttonText: null,
				helpId: null,
				url: null,
			};

			//Set name based on category uid
			if (this.exerciseHelpJSON[i].exercise.category.uid == 'cog') {
				newVideoObject.name = 'Cognitive: ' + this.exerciseHelpJSON[i].name;
				newVideoObject.buttonText = 'Cognitive';
			} else {
				newVideoObject.name = this.exerciseHelpJSON[i].name;
				newVideoObject.buttonText = 'Balance';
			}

			//Set help ID
			newVideoObject.helpId = this.exerciseHelpJSON[i].id;

			// App Get this exercise's video file if it has one
			if (!this.sDevice.isBrowser) {
				//Balance tut video
				if (newVideoObject.helpId) {
					let filename = 'tutorial_video.mp4';
					let fs = new FileStream();
					fs.setup()
						.getEntry('/appData/exerciseData/' + newVideoObject.helpId + '/' + filename, false)
						//.debugData()
						.toURL()
						.go()
						.then((res) => {
							if (res.returnData[filename]) {
								newVideoObject.url = res.returnData[filename].ionicUrl;
							}

							//Push video object
							this.videoObjects.push(newVideoObject);
						})
						.catch((err) => {
							this.videoObjects.push(newVideoObject);
						});
				} else {
				}
			}

			// Browser
			else {
				let video = this.sDevice.getHelps(this.exerciseHelpJSON[i].id).videos();
				if (!video.length) video = ['assets/vid/009.mp4'];
				else {
					video = [this.V.cfg.host + '/api/ExerciseHelp/' + video[0].parentId + '/' + video[0].name + '?access_token=' + this.V.token];
				}

				// //Push video object
				newVideoObject.url = video[0];
				this.videoObjects.push(newVideoObject);
			}
		}
		// }
	}

	////////////////////////////////////
	initHowToBalance() {
		this.videoObjects = [{ url: this.exercise.vidPath }];
	}

	////////////////////////////////////
	prepareQuickRefreshHelp() {
		//Ejectors
		if (this.exerciseHelpJSON == null) return;
		if (this.exerciseHelpJSON.length == 0) return;

		for (let i = 0; i < this.exerciseHelpJSON.length; i++) {
			let newExerciseHelpObject = {
				name: this.exerciseHelpJSON[i].name,
				exerciseHelpSteps: [],
			};

			let help = this.exerciseHelpJSON[i];
			let index = 1;
			while (help['image_' + index] || help['image_' + index + '_text']) {
				newExerciseHelpObject.exerciseHelpSteps.push({
					text: help['image_' + index + '_text'] || null,
				});
				index++;
			}
			if (!newExerciseHelpObject.exerciseHelpSteps.length) {
				newExerciseHelpObject.exerciseHelpSteps = [
					{
						image_1_text: 'No quick refresh help has been provided for this exercise',
					},
				];
			}

			this.exerciseHelpArray.push(newExerciseHelpObject);
		}
	}

	displayExerciseHelp() {
		let exerciseHelpArray = this.exerciseHelpArray;

		for (let i = 0; i < this.exerciseHelpArray.length; i++) {
			let help = this.exerciseHelpJSON[i];
			let index = 1;
			while (help['image_' + index] || help['image_' + index + '_text']) {
				this.prepareExerciseHelpImage(i, help.id, index);
				index++;
			}
			// if (!this.exerciseHelpArray[i].exerciseHelpSteps.length) {
			// 	this.exerciseHelpArray[i].exerciseHelpSteps = [{
			// 		image_1_text:"No quick refresh help has been provided for this exercise"
			// 	}];
			// }
		}
	}

	////////////////////////////////////
	prepareExerciseHelpImage(exerciseHelpID, exID, imID) {
		let imageNumbering = '';
		imageNumbering = imID + '';

		// App
		if (!this.sDevice.isBrowser) {
			let filename = 'image_' + imageNumbering + '.png';
			let fs = new FileStream();
			fs.setup()
				.getEntry('/appData/exerciseData/' + this.exerciseHelpJSON[exerciseHelpID].id + '/' + filename, false)
				.toURL()
				.go()
				.then((res) => {
					if (res.returnData[filename]) {
						let im = document.querySelector('[data-imageindex="' + imageNumbering + '"][data-imagearrayindex="' + (exerciseHelpID + 1) + '"]');
						let imageURL = res.returnData[filename].ionicUrl;
						im.setAttribute('src', imageURL);
					}
				})
				.catch((err) => {
					console.log(
						'Cannot get exercise image with ID:',
						this.exerciseHelpJSON[exerciseHelpID].id,
						'/appData/exerciseData/' + this.exerciseHelpJSON[exerciseHelpID].id + '/' + filename,
						err
					);
				});
		}

		// Browser
		else {
			let image = this.sDevice.getHelps(this.exerciseHelpJSON[exerciseHelpID].id).image(imID);
			if (image.length) {
				setTimeout(() => {
					let im = document.querySelector('[data-imageindex="' + imageNumbering + '"][data-imagearrayindex="' + (exerciseHelpID + 1) + '"]');
					im.setAttribute(
						'src',
						this.V.cfg.host + '/api/ExerciseHelp/' + image[0].parentId + '/' + image[0].name + '?access_token=' + this.V.token
					);
				}, 500);
			}
		}
	}

	////////////////////////////////////
	setRatingLabels() {
		// Balance assessment session
		if (this.isBalanceAssessment()) this.ratingSet = this.ratingSetDefault;
		// Exercise session
		else {
			let category = this.exercise.exercise.category;
			let categoryUID = category.uid;

			switch (categoryUID) {
				case 'lowbodystr':
				case 'upbodystr':
					this.ratingSet = this.ratingSetStrength;
					break;

				case 'cardio':
					this.ratingSet = this.ratingSetCardio;
					break;

				default:
					this.ratingSet = this.ratingSetDefault;
					break;
			}
		}
	}

	setActiveVideoObject(videoObject: any) {
		if (videoObject != null) {
			this.activeVideoObject = videoObject;
			this.videoHandler.setVideoSrc(videoObject.url);
			this.videoHandler.video.nativeElement.pause();
		}
	}

	isStrengthCategory() {
		if (this.isBalanceAssessment()) return false;
		else return ['lowbodystr', 'upbodystr'].includes(this.exercise.exercise.category.uid);
	}

	progressionDifficulty() {
		if (this.exercise && this.exercise.exercise) {
			return 'progression_difficulty_' + this.exercise.exercise.progression_difficulty || '';
		} else {
			return '';
		}
	}

	isBalanceAssessment() {
		return this.exercise.type && this.exercise.type == 'balance_assessment';
	}

	// setHelpImageSources() {
	// let im = document.querySelector('[data-imageindex="'+imageNumbering+'"][data-imagearrayindex="'+(exerciseHelpID+1)+'"]');
	// im.setAttribute('src', this.V.cfg.host + "/api/ExerciseHelp/"+image[0].parentId+"/"+image[0].name+"?access_token="+this.V.token);
	// }
}
