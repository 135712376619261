///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS
import { environment } 							from '../../../environments/environment';

////////////////////////////////
////////// MODULES
import { Component, OnInit, ChangeDetectorRef }	from '@angular/core';
import { Router }								from '@angular/router';
import { Location }								from '@angular/common';
import { CommandForceAlphaService }				from '../../services/commandforcealpha.service';
import { Subscription }							from 'rxjs';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
  selector: 'app-page-onboarding',
  templateUrl: './page-onboarding.component.html',
  styleUrls: ['./page-onboarding.component.scss']
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class PageOnboardingComponent implements OnInit {

	////////////////////////////////
	whichState:string = "";
	disabledDueToStorageAcceptance:boolean = false;
	browserStorageAcceptance$sub:Subscription;

	////////////////////////////////
	appVersion: string = environment.appVersion;

	////////////////////////////////
	constructor(private route:Router, private loc:Location, private sCommandForce:CommandForceAlphaService, private changeDetectorRef:ChangeDetectorRef) { }
	ngOnInit() {
		if (this.route.url == "/onboarding/add") {
			this.whichState = "addtroOn";
		} else {
			this.whichState = "introOn";
		}

		// Test whether the user has accepted cookies or not
		this.browserStorageAcceptance$sub = this.sCommandForce.performBrowserStorageAcceptance(500, true).subscribe(this.storageAcceptanceUpdate.bind(this));
	}
	ngOnDestroy() {
		this.sCommandForce.hideStorageConfirmToast();
		this.browserStorageAcceptance$sub.unsubscribe();
	}

	////////////////////////////////////
	storageAcceptanceUpdate(result) {
		this.disabledDueToStorageAcceptance = !result;
		this.changeDetectorRef.detectChanges();
	}

	////////////////////////////////////
	goLogin() {
		if (this.whichState == "addtroOn") {
			this.route.navigate(["/login/add"]);
		} else {
			this.route.navigate(["/login"]);
		}
	}

	goSetupProfile() {
		if (this.whichState == "addtroOn") {
			this.route.navigate(["/setupprofile/add"]);
		} else {
			this.route.navigate(["/setupprofile"]);
		}
	}
}
