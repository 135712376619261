///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
////////// IMPORTS
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router }						from '@angular/router';
import { ExerciseService }				from '../../services/exercise.service';
import { FormBluewaveComponent }		from '../../components/form-bluewave/form-bluewave.component';

@Component({
  selector: 'app-page-exercise7-trialhome',
  templateUrl: './page-exercise7-trialhome.component.html',
  styleUrls: ['./page-exercise7-trialhome.component.scss']
})
export class PageExercise7TrialhomeComponent implements OnInit {

	////////////////////////////////
	@ViewChild("bluewaveform") bluewaveform: FormBluewaveComponent;

	////////////////////////////////
	constructor(private sExercise:ExerciseService, private router:Router) {}
	ngOnInit() {}

	////////////////////////////////
	readyToStart() {
		this.bluewaveform.open();
	}

	////////////////////////////////
	eventReceived(ev) {
		if (ev.type == "cancel") this.bluewaveform.close();
		if (ev.type == "requestContinue") {
			this.sExercise.createNewTrialSession().then(res2 => {
				this.router.navigate(['/exercises/trial/main']);
			}).catch(err => { console.log("ERROR creating new session", err); });
		}
	}

	onBackButton() {
    	this.router.navigate(['/home']);
	}
}
