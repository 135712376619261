///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

//////////////////////////////// ANGULAR CORE
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceService } from './device.service';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
@Injectable({ providedIn: 'root' })
export class StorageService {
	////////////////////////////////
	writers: any[] = [];
	fileLock: any = {};

	////////////////////////////////
	constructor(private sDevice: DeviceService) {}

	////////////////////////////////////
	get(storageKey: string, raw = false) {
		let resolver = function (resolve, reject) {
			let ok = (res) => {
				this.fileLock[storageKey] = false;
				resolve(res);
			};
			let ko = (err) => {
				this.fileLock[storageKey] = false;
				reject(err);
			};

			////////////////////////////////////
			let tryRead = () => {
				if (!this.fileLock[storageKey]) {
					this.fileLock[storageKey] = true;
					this.getGo(storageKey, raw).then(ok).catch(ko);
				} else {
					setTimeout(tryRead, 450);
				}
			};

			tryRead();
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////
	private getGo(storageKey, raw) {
		let resolver = function (resolve, reject) {
			// Browser
			if (this.sDevice.isBrowser) {
				if (Object.keys(localStorage).indexOf(storageKey) == -1) {
					localStorage.setItem(storageKey, '[]');
					resolve([]);
				} else {
					let data = localStorage.getItem(storageKey);
					data = JSON.parse(data);
					resolve(data);
				}

				// App & Device
			} else {
				let cordova = window['cordova'];
				if (!cordova || !cordova.file) return reject('GET: Cordova or Cordova File not installed');

				let file = cordova.file;
				let resolveLocalFileSystemURL = window['resolveLocalFileSystemURL'];

				this.getFilesystem()
					.then((fs) => {
						resolveLocalFileSystemURL(
							file['dataDirectory'],
							function (dirEntry) {
								dirEntry.getFile(
									storageKey,
									{ create: true, exclusive: false },
									function (fileEntry) {
										// Create a reader and read the file
										this.createFileReader(fileEntry).then((res) => {
											let reader = res.reader;
											reader.onload = (evt: any) => {
												let returnData = evt.target.result;
												if (returnData == '') returnData = [];
												else {
													try {
														returnData = !raw ? JSON.parse(returnData) : returnData;
													} catch (e) {
														// Policy is to delete all local JSON data and take users to the broken data page
														this.set('datapulllog', []); // Reset our content pull requirements
														return reject({
															type: 'content-problem',
															key: storageKey,
															message: 'Data stored on the device was found to be out-of-sorts - Get: ' + storageKey,
														});
													}
												}
												resolve(returnData);
											};
											reader.onerror = (err) => {
												console.log('Error during file read while getting file', err);
												reject(this.prepareError(err, 'writeTo', this.lastEntry.name));
											};
											reader.readAsText(res.file);
										});
									}.bind(this),
									function (err) {
										reject(err);
									}.bind(this)
								);
							}.bind(this)
						);
					})
					.catch((err) => {
						console.log('Error during get go; filesystem error:', err);
					});
			}
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////////
	/*set(storageKey:string, data:any[]) {
		let resolver = function(resolve, reject) {

			let thisID = Math.ceil(Math.random()*10000);
			this.writers.push( { id:thisID, complete:false });

			let ok = (res) => { this.markWriterComplete(thisID); resolve(res); }
			let ko = (err) => { this.markWriterComplete(thisID); reject(err); }

			////////////////////////////////////
			let tryWrite = () => {
				if (this.writers[0].id == thisID) {
					this.setGo(storageKey, data)
					.then(ok).catch(ko);
				} else {
					setTimeout(tryWrite, 450);
				}
			}

			tryWrite();
		}.bind(this);

		return new Promise(resolver);
	}*/

	////////////////////////////////////
	set(storageKey: string, data: any[]) {
		let resolver = function (resolve, reject) {
			let ok = (res) => {
				this.fileLock[storageKey] = false;
				resolve(res);
			};
			let ko = (err) => {
				this.fileLock[storageKey] = false;
				reject(err);
			};

			////////////////////////////////////
			let tryWrite = () => {
				if (!this.fileLock[storageKey]) {
					this.fileLock[storageKey] = true;
					this.setGo(storageKey, data)
						.then((res) => {
							ok(res);
						})
						.catch((err) => {
							ko(err);
						});
				} else {
					setTimeout(tryWrite, 450);
				}
			};
			tryWrite();
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////////
	private setGo(storageKey, data) {
		let resolver = function (resolve, reject) {
			// Browser
			if (this.sDevice.isBrowser) {
				let saveData = (typeof data == 'string' && data) || JSON.stringify(data);
				localStorage.setItem(storageKey, saveData);
				resolve('Update complete');

				// App & Device
			} else {
				// Instantiation
				let cordova = window['cordova'];
				if (!cordova || !cordova.file) return reject('SET: Cordova or Cordova File not installed');

				let file = cordova.file;
				let resolveLocalFileSystemURL = window['resolveLocalFileSystemURL'];

				// Get the filesystem
				this.getFilesystem()
					.then((fs) => {
						// Resolve the local filesystem URL / get the root
						resolveLocalFileSystemURL(
							file['dataDirectory'],
							function (dirEntry) {
								// Get the file we want
								dirEntry.getFile(
									storageKey,
									{ create: true, exclusive: false },
									function (fileEntry) {
										// Create a writer
										this.createFileWriter(fileEntry, false)
											.then((wr) => {
												wr.onwrite = function (res) {
													resolve(res);
												}.bind(this);
												wr.onwriteend = function (res) {}.bind(this);
												wr.onerror = (err) => {
													reject(err);
												};

												let parseData = JSON.stringify(data);
												wr.write(parseData);
											})
											.catch((err) => reject(err));
									}.bind(this),
									function (err) {
										reject(err);
									}.bind(this)
								);
							}.bind(this)
						);
					})
					.catch((err) => {
						console.log('Error during set go filesystem error:', err);
					});
			}
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////
	private getFilesystem() {
		let resolver = function (resolve, reject) {
			let requestFileSystem = window['requestFileSystem'];
			let LocalFileSystem = window['LocalFileSystem'];
			let location = LocalFileSystem.PERSISTENT;
			let size = 0;

			requestFileSystem(
				location,
				size,
				function (fs) {
					this.fileSystem = fs;
					resolve(this.fileSystem);
				}.bind(this),
				function (err) {
					reject(err);
				}.bind(this)
			);
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////
	private createFileReader(entry) {
		let resolver = function (resolve, reject) {
			entry.file(
				(res) => {
					var reader = new FileReader();
					resolve({ reader: reader, file: res });
				},
				(err) => {
					reject(this.prepareError(err, 'createFileReader'));
				}
			);
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////
	private createFileWriter(entry, appendTo) {
		let resolver = function (resolve, reject) {
			entry.createWriter(
				function (writer) {
					if (!appendTo) {
						writer.onwrite = (res) => resolve(writer);
						writer.onerror = (err) => reject(this.prepareError(err, 'createFileWriter - Truncate'));
						writer.truncate(0); // deletes all content
					} else {
						resolve(writer);
					}
				}.bind(this),
				function (err) {
					reject(this.prepareError(err, 'createFileWriter'));
				}.bind(this)
			);
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////
	private markWriterComplete(id: number) {
		this.writers = this.writers.filter((wr) => {
			return wr.id != id;
		});
	}
}
