///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
import { Component, OnInit, HostBinding }	from '@angular/core';
import { GlobalPubSub }						from '../../services/global-pub-sub.service';


///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// COMPONENT
@Component({
  selector:		'toast',
  templateUrl:	'./toast.component.html',
  styleUrls:	['./toast.component.scss']
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class ToastComponent implements OnInit {

	@HostBinding('class.on') on: boolean = false;
	content:string = "";
	dismissTimeDefault:number = 6500;
	hideTimeout: any;

	////////////////////////////////
	constructor(private sGlobalPubSub:GlobalPubSub) { }
	ngOnInit() {
		this.sGlobalPubSub.subscribe('toast', this.eventFired.bind(this));
	}

	////////////////////////////////
	eventFired(show, content, dismissTime) {
		let endTime = this.dismissTimeDefault;
		if (show) this.on = true; else this.on = false;
		if (content) this.content = content;
		if (dismissTime) endTime = dismissTime;
		clearTimeout(this.hideTimeout);
		this.hideTimeout = setTimeout(this.hide.bind(this), endTime);
	}

	////////////////////////////////
	hide() {
		this.on = false;
	}
}
