///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS
import { Component }				from '@angular/core';
import { GlobalPubSub }				from '../../services/global-pub-sub.service';
import { ContentManagerService }	from '../../services/contentmanager.service';
import { CommandForceAlphaService } from '../../services/commandforcealpha.service';
import { Router }					from '@angular/router';


///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
  selector:		'app-page-contentproblem',
  templateUrl:	'./page-contentproblem.component.html',
  styleUrls:	['./page-contentproblem.component.scss']
})


///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class PageContentproblemComponent {

	////////////////////////////////
	error:any;

	////////////////////////////////
	constructor(private sGlobalPubSub:GlobalPubSub, private sContentManager:ContentManagerService, private sCommand:CommandForceAlphaService, private router:Router) {}

	////////////////////////////////
	resumeUpdate() {
		this.sGlobalPubSub.fireEvent("revealScreenHider", []);
		this.sCommand.updateContent();
	}

	////////////////////////////////
	restartUpdate() {
		this.sGlobalPubSub.fireEvent("revealScreenHider", []);
		this.sContentManager.fileManifest = [];
		this.sCommand.updateContent();
	}
}
