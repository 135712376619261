///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
////////// MODULES
import { Component, OnInit, ViewChild, ElementRef, HostBinding, AfterViewInit, Input }	from '@angular/core';
import { DataService }																	from '../../services/data.service';
import { UserService }																	from '../../services/user.service';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
  selector:		'guide-figure',
  templateUrl:	'./guide-figure.component.html',
  styleUrls:	['./guide-figure.component.scss']
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class GuideFigureComponent implements OnInit {

	////////////////////////////////
	//@HostBinding('class.ready') ready: boolean = false;
	@ViewChild('guideimage') guideimage;
	@Input() guideIndex:string;
	@Input() guidePose:string = "Neutral";

	////////////////////////////////
	thisGuide = { name:"Betty" }

	////////////////////////////////
	constructor(private sData:DataService, private elementRef:ElementRef, private sUser:UserService) { }
	ngOnInit() {
		if (parseInt(this.guideIndex) >= 0) {
			this.thisGuide.name = this.sUser.guideIndex[parseInt(this.guideIndex)];
			this.guideimage.nativeElement.src = "assets/img/characters/ST.Char."+this.sUser.guideIndex[parseInt(this.guideIndex)]+"."+this.guidePose+".png";
		} else {
			this.thisGuide.name = this.sUser.getGuideName();
			this.guideimage.nativeElement.src = "assets/img/characters/ST.Char."+this.sUser.getGuideName()+"."+this.guidePose+".png";
		}
	}

	////////////////////////////////
	/*ngAfterViewInit() {
		this.ready = true;
	}*/

}
