///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS
import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { VgPlayerHandlerComponent } from 'src/app/components/vg-player-handler/vg-player-handler.component';
import { ShortSchedule } from '../../st-commons/shortSchedule';
import { ExerciseService } from '../../services/exercise.service';
import { STExerciseLogic } from '../../st-commons/exercise_logic';
import { ExerciseAction, ExerciseLevel } from '../../st-commons/models';
import { UserService } from '../../services/user.service';
import { Vasat } from 'vasat';
import { DataService } from '../../services/data.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { GlobalPubSub } from '../../services/global-pub-sub.service';
import { DeviceService } from '../../services/device.service';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
	selector: 'app-page-debuganimations',
	templateUrl: './page-debuganimations.component.html',
	styleUrls: ['./page-debuganimations.component.scss'],
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class PageDebuganimationsComponent implements OnInit {
	@ViewChild('animations') animations;
	@ViewChild('videoHandler') videoHandler: VgPlayerHandlerComponent;

	lastChosenExercise: ExerciseAction;
	exerciseJSON: string = '';
	showHelpMode: boolean = true;
	exerciseTips: any[] = [];
	exerciseQuickRefresh: any[] = [];

	scheduledAnims: ShortSchedule;
	testThing1: string = JSON.stringify({
		mode: 'static_duration',
		animation_name: 'St_Ey_C_FT',
		start_state_animation: 'St_Ey_C_FT',
		feet_icon: 'Feet Together',
		floorSequence: [{ beats: 1, tempo: 0.1, animation: 'St_Ey_C_FT', multiplier: 1 }],
		tempo: [6],
		reps: [1],
		modifier: null,
		cognitive: {
			mode: 'Audio',
			cue_to_count: 'Specific word',
			cue_to_ignore: 'Word category',
			tempos: ['1.5,3.0'],
			feedback: 'Number',
		},
	});
	testThing2: string = JSON.stringify({
		mode: 'static_duration',
		animation_name: 'HT_HW',
		start_state_animation: 'Si_HW',
		feet_icon: 'Feet Hip Width',
		floorSequence: [{ beats: 1, tempo: 0.1, animation: 'Si_HT', multiplier: 1 }],
		tempo: [6],
		reps: [1],
		modifier: null,
		cognitive: {
			mode: 'Audio',
			cue_to_count: 'Specific word',
			cue_to_ignore: 'Distractor words',
			tempos: ['1.8'],
			feedback: 'Number',
		},
	});

	exerciseSessions = [];
	exercises$: BehaviorSubject<any> = new BehaviorSubject([]);
	exerciseDataOn: boolean = false;

	progression_difficulty_message: string = '';

	////////////////////////////////
	stExerciseLogic: STExerciseLogic;

	////////////////////////////////
	constructor(
		private zone: NgZone,
		private sExercise: ExerciseService,
		private sData: DataService,
		private V: Vasat,
		private sUser: UserService,
		private sGlobalPubSub: GlobalPubSub,
		private sDevice: DeviceService
	) {
		if (!this.stExerciseLogic) this.stExerciseLogic = new STExerciseLogic(this.V);
	}

	////////////////////////////////
	ngOnInit() {
		this.animations.setExerciseService(this.sExercise);
		this.populateExercises();
	}

	////////////////////////////////
	startAnimation(json) {
		let width = this.animations.animationContainer.nativeElement.offsetWidth;
		let height = this.animations.animationContainer.nativeElement.offsetHeight;

		this.zone.runOutsideAngular(() => {
			this.animations.setupAnimation
				.bind(this.animations)(json, width, height)
				.then((scheduler: any) => {
					scheduler.pause();
					scheduler.setToInitialState();

					setTimeout(() => scheduler.start(), 500);
				});
		});
	}

	////////////////////////////////
	testAnimationWithJSON() {
		let jsonEl = document.querySelector('#test_json') as HTMLInputElement;
		let json = jsonEl.value;
		let parse = JSON.parse(json);
		this.startAnimation(parse);
	}

	////////////////////////////////////
	/*plonkInTestJSON(which) {
		let jsonEl = document.querySelector('#test_json') as HTMLInputElement;
		jsonEl.value = this[which];
	}*/

	////////////////////////////////////
	populateExercises() {
		let dataPromises = [];
		dataPromises.push(this.sData.getRawDataFromStorage('exercisecategories'));
		dataPromises.push(this.sData.getRawDataFromStorage('exercises'));
		dataPromises.push(this.sData.getRawDataFromStorage('exerciselevels'));

		console.log('Ignoring cognitive and balance assessment');

		Promise.all(dataPromises).then((data) => {
			let exerciseCategories = data.find((datum) => datum[0].type == 'ExerciseCategory');
			let exercises = data.find((datum) => datum[0].type == 'Exercise');
			let exerciseLevels = data.find((datum) => datum[0].type == 'ExerciseLevel');

			let ignoredCategories = ['init', 'cog', 'dance'];
			exerciseCategories = exerciseCategories.filter((ex) => !ignoredCategories.includes(ex.uid));

			// Add exercies and levels
			exerciseCategories.forEach((excat) => {
				excat.exerciseData = exercises.filter((ex) => ex.category && ex.category.id == excat.id).sort((a, b) => (a.name < b.name && -1) || 1);

				excat.exerciseData.forEach((ex) => {
					ex.levelData = exerciseLevels
						.filter((el) => el.exerciseType && el.exerciseType.id == ex.id)
						.sort((a, b) => (a.seq < b.seq && -1) || 1);
				});
			});

			// Sort by category
			exerciseCategories.sort((a, b) => (a.category < b.category && -1) || 1);

			this.exercises$.next(exerciseCategories);
		});
	}

	/**
	 * Toggles which mode: help or animation
	 * */
	toggleMode() {
		this.animations.resetAnimation();
		this.showHelpMode = !this.showHelpMode;
		this.videoHandler.video.nativeElement.pause();
	}

	////////////////////////////////////
	openExerciseData() {
		this.exerciseDataOn = true;
	}

	////////////////////////////////////
	closeExerciseData() {
		this.exerciseDataOn = false;
	}

	////////////////////////////////////
	toggleCatState(excat) {
		excat.on = !excat.on;
	}

	////////////////////////////////////
	chooseExercise(exercise, exerciseLevel) {
		this.animations.resetAnimation();
		this.videoHandler.video.nativeElement.pause();

		let level = new ExerciseLevel(this.V);
		level.set(exerciseLevel);
		let exerciseAction = this.stExerciseLogic.makeActionForLevel(level, this.sUser.user, true, true);
		this.exerciseJSON = JSON.stringify(exerciseAction.instructions);
		this.closeExerciseData();
		this.lastChosenExercise = exerciseAction;

		// Setup help
		let help = this.sExercise.getHelp(exerciseAction);
		let exerciseHelpDetails;
		let exerciseTips: any[] = [];

		// Loop through exerciseHelpJSON (there should only be 1 or 2 elements) and set names
		for (let i = 0; i < help.length; i++) {
			exerciseHelpDetails = help[i];
		}

		// Get tips
		if (exerciseHelpDetails != null) {
			var keys = Object.keys(exerciseHelpDetails);
			keys.forEach((key) => {
				if (key.indexOf('question_title_') != -1) {
					let qid = key.replace('question_title_', '');
					if (exerciseHelpDetails['question_body_' + qid]) {
						exerciseTips.push({
							q: exerciseHelpDetails['question_title_' + qid],
							a: exerciseHelpDetails['question_body_' + qid],
						});
					}
				}
			});
		}

		if (exerciseTips.length) this.exerciseTips = exerciseTips;

		// Get Video
		let video = this.sDevice.getHelps(exerciseHelpDetails.id).videos();
		if (!video.length) video = ['assets/vid/009.mp4'];
		else {
			video = [this.V.cfg.host + '/api/ExerciseHelp/' + video[0].parentId + '/' + video[0].name + '?access_token=' + this.V.token];
		}

		this.videoHandler.setVideoSrc(video[0]);
		this.videoHandler.video.nativeElement.pause();

		// Get refresh
		let refreshPairs: any[] = [];
		let index = 1;
		while (exerciseHelpDetails['image_' + index] || exerciseHelpDetails['image_' + index + '_text']) {
			let text = exerciseHelpDetails['image_' + index + '_text'];
			let image: any = this.sDevice.getHelps(exerciseHelpDetails.id).image(index);
			if (image[0]) image = this.V.cfg.host + '/api/ExerciseHelp/' + image[0].parentId + '/' + image[0].name + '?access_token=' + this.V.token;
			refreshPairs.push({ image: image, text: text });
			index++;
		}

		this.exerciseQuickRefresh = refreshPairs;

		// Flag progression difficulty
		if (exerciseLevel.hasOwnProperty('progression_difficulty') && exerciseLevel.progression_difficulty > 0) {
			this.progression_difficulty_message = `Progression Difficulty: ${exerciseLevel.progression_difficulty}`;
		} else {
			this.progression_difficulty_message = '';
		}
	}
}
