///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
import { Component } from '@angular/core';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
  selector: 'flexor',
  templateUrl: './form-bluewaveflexor.component.html',
  styleUrls: ['./form-bluewaveflexor.component.scss']
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class FormBluewaveflexorComponent {}
