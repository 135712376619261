///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
import { Component } from '@angular/core';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
  selector: 'nonflexor',
  templateUrl: './form-bluewavenonflexor.component.html',
  styleUrls: ['./form-bluewavenonflexor.component.scss']
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class FormBluewavenonflexorComponent {

	constructor() { }

}
