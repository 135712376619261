import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
	selector: 'vg-player-handler',
	templateUrl: './vg-player-handler.component.html',
	styleUrls: ['./vg-player-handler.component.scss']
})
export class VgPlayerHandlerComponent implements OnInit {

	@ViewChild("media") video;
	private nullSrc: boolean = false;

	constructor() { }

	ngOnInit() {

	}

	public setVideoSrc(src: string) {
		console.log("Setting video Source", src);
		if (src == null || src == undefined || src == '') {
			this.nullSrc = true;
		} else {
			this.nullSrc = false;
			this.video.nativeElement.setAttribute('src', src);
			console.log("Native video source updated", src);
		}
	}
}
