import { Vasat, VasatModel, User, AsClass, ToManyList, AsToManyList } from 'vasat';
import { of, Observable, forkJoin } from 'rxjs';
import { map, flatMap } from 'rxjs/operators';

declare var require;
const _ = require('underscore');
_.templateSettings = {
	interpolate: /\{\{(.+?)\}\}/g,
	evaluate: /\{\%(.+?)\%\}/g,
};

/**
  Base model just incase you want some universal behaviour
*/
class STModel<T extends STModel<T>> extends VasatModel<T> {}

// Progresss on a particular exercise (eg Standing Foam)
export interface ExerciseState {
	exercise: Exercise;
	currentLevel: ExerciseLevel;
	active: boolean;
	finished: boolean;
}

// grouping of progress in a category
export interface ProgressCategory {
	category: ExerciseCategory | ProgressCategoryShort;
	checkpoint: number;
	states: ExerciseState[];
	disabled: boolean;
	max_checkpoint?: number;
}

interface ProgressCategoryShort {
	id: number;
	uid?: string;
	type: string;
	name?: string;
}

export interface FloorAnimationSequence {
	animation: string; // id of the animation to do
	beats: number; // the number of beats to do this animation for
	multiplier?: number; // the number of times to repeat this sequence (1 if notsupplied)
}

export interface FloorModifier {
	animation: string; // id of the animation to do
	loopSpeed: number; // the number of beats to do this animation for
}
export interface CognitiveExercise {
	mode: 'Initial' | 'Audio' | 'Visual' | 'Pattern' | 'Recall' | 'Inhibition';
	cue?: string;
	cue_to_count?: string;
	cue_to_ignore?: string;
	recall_forward?: string;
	recall_backwards?: string;
	pattern?: string;
	next_shape?: string;
	tempos?: number[];
	feedback: 'number' | 'text';
}

export interface ExerciseInstruction {
	// string of one of thses values
	mode?:
		| 'static_duration'
		| 'dynamic_tempo'
		| 'dart'
		| 'grid'
		| 'box'
		//| "dance"
		| 'not_specified'
		| 'cog'
		| 'cardio';

	inherited_mode?: string; // Used when a new category contains exercises from multiple other categories, such as cardio

	animation_name?: string;
	start_state_animation?: string;

	camera_view?: 'Body' | 'Feet';
	feet_icon?: 'Feet Together' | 'Feet Hip Width' | 'Near Tandem' | 'Tandem' | 'Left Leg' | 'Right Leg';

	tempo?: number[]; // always bpm
	reps?: number[]; // number of reps

	// placeholder for cognitive
	cognitive?: CognitiveExercise;
	physicalDuration?: number;

	// floor/foam
	floorSequence?: FloorAnimationSequence[];
	modifier?: FloorAnimationSequence[];

	// dartboard, box, grid
	action?: string;
	step?: string[];
	stepCount?: number;
	cue?: string[];
	direction?: string[];
	// dual exercises
	dual?: any[];
}

interface updateRelationsArgs {
	forceLevelProgressionUpdate?: boolean;
}

export class ExerciseCategory extends STModel<ExerciseCategory> {
	uid: string;

	// sort by seequence
	seq: number;

	constructor(V: Vasat) {
		super(V, 'ExerciseCategory');
	}
}

export class Exercise extends STModel<Exercise> {
	@AsClass(ExerciseCategory)
	category: ExerciseCategory;

	levels: ExerciseLevel[];
	uid: string;
	mode: string; // Added to let cardio exercises use box

	// this type of exercise ends when highest level is reached
	ends: boolean;

	is_extra: boolean;

	// item, dart, grid the rules that govern the different behaviours of the exercise levels for this exercise
	levelSchema: string;

	// March 2022 - A new property for determining calculation of times and duration.  Duplication of code is due to the legacy
	// exercises being reused with different timing.  Trying to work into complex existing code means making changes to that code, which is
	// worse than adding a new timing property
	timing: string;

	floorSequence: FloorAnimationSequence[];

	animation: string;
	starting_animation: string;
	tempo_rocknroll: boolean;

	feet_icon: 'Feet Together' | 'Feet Hip Width' | 'Near Tandem' | 'Tandem' | 'Left Leg' | 'Right Leg';
	modifier_animation: 'None' | 'Hand Movement 1' | 'Hand Movement 2' | 'Hand Movement 3' | 'Hand Movement 4' | 'Directional Gaze';
	camera_view: 'Body' | 'Feet';

	// cognitive
	cog_mode: 'Initial' | 'Audio' | 'Visual' | 'Pattern' | 'Recall' | 'Inhibition';
	cog_target: 'static_duration' | 'dynamic_tempo';

	// underscore expression to align help
	helpExpression: string;

	constructor(V: Vasat) {
		super(V, 'Exercise');
	}

	updateRelations() {
		this.levels = this.V.getCached(ExerciseLevel)
			.filter((e) => e.active && e.exerciseType == this)
			.sort((a, b) => a.seq - b.seq);
	}
	toJSON() {
		var v = super.toJSON();
		delete v.levels;
		return v;
	}
}

export class ExerciseLevel extends STModel<ExerciseLevel> {
	@AsClass(Exercise)
	exerciseType: Exercise;
	@AsClass(ExerciseCategory)
	category: ExerciseCategory;
	seq: number;

	progression_difficulty?: number;

	// provided by floor, but converted to tempo
	duration: number;

	// for time tempo ('item') types
	reps: number;
	tempo: number;

	custom_animation: boolean;
	floorSequence: FloorAnimationSequence[];

	// dartboard:
	is_dual: boolean;
	cue: string;
	action: string;
	step: string;
	direction: string;
	min: number;
	max: number;
	dual: any[];

	// dartboard its a number, dance its a JSON
	steps: any;

	// box
	score: number;

	// cognitive
	col1: string[];
	col2: string[];
	tempos: number[];
	feedback: string[];

	/* Common to all exercise types */

	// skip to this exercise if you did good on balance assesment
	advance: boolean;

	// checkpoint at which is exercise is available
	level: number;
	// the next level to progress if this is done well
	next_level: number;
	nextLevel?: number; // An alternative spelling to the above, as the Vasat system started using this notation on new categories

	constructor(V: Vasat) {
		super(V, 'ExerciseLevel');
	}

	// Calculate the Total Time of an Exercise
	totalDuration(logic?: any, user?: any, byo?: ExerciseAction) {
		// tempo+duration - new type where the duration is the length, and the tempo determines how fast each beat is - March 2022
		if (this.exerciseType.timing == 'tempo+duration') {
			return (this.duration / 60) * 3;
		}

		// tempo+reps - new type where the reps * the tempo is the duration - March 2022
		else if (this.exerciseType.timing == 'tempo+reps') {
			return ((60 / this.tempo) * this.reps * 3) / 60;
		}

		// Static Duration total time
		else if (this.exerciseType.levelSchema == 'static_duration') {
			return (this.duration / 60) * 3;
		}

		// Box, Dart or Grid
		else if (this.exerciseType.levelSchema == 'box' || this.exerciseType.levelSchema == 'dart' || this.exerciseType.levelSchema == 'grid') {
			if (logic && user) {
				var ea: ExerciseAction = byo || logic.makeActionForLevel(this, user, true);

				// Dartboard total time
				if (this.exerciseType.levelSchema == 'dart' && ea.instructions.dual) {
					var d = ea.instructions.dual[0];
					var beat = 60 / ea.instructions.tempo[0];

					if (ea.exercise.action == 'Step & Rock' && d.cog_mode == 'Forward Span')
						console.log('%c' + ea.exercise.action + ', ' + d.cog_mode, 'color: limegreen;');
					else console.log('%cThis is not the exercise youre looking for ' + ea.exercise.action + ', ' + d.cog_mode, 'color: orangered;');

					if (d.cog_mode == 'Forward Span' || d.cog_mode == 'Backward Span') {
						console.log("%cd.cog_mode == 'Forward Span'", 'color: deepskyblue;');
						console.log('%cTD ========== ' + (((d.disp_time * 2 * d.n_cues + 3 * beat) * d.duration) / 60) * 3, 'color:tomato');
						let cueDisplays = d.n_cues * d.disp_time * 2;
						let beepPlays = d.n_cues * beat * 2;
						let beepPauses = beat;
						let total = (cueDisplays + beepPlays + beepPauses) * d.duration; // d.duration is reps
						return (total / 60) * 3; // We divide by 60 and multiply by three to find the value in minutes, and for three full sets of
					} else {
						console.log('%celse', 'color: tomato;');
						console.log('%cTD ========== ' + (d.duration / 60) * 3, 'color:tomato');
						return (d.duration / 60) * 3;
					}

					// Grid total time
				} else if (this.exerciseType.levelSchema == 'grid' && ea.instructions.dual) {
					var d = ea.instructions.dual[0];
					var beat = 60 / ea.instructions.tempo[0];

					var DISP_TIME = 6.65; // hard coded from https://app.asana.com/0/725971722123238/1109627591108847

					if (d.presenation == 'Path all at once' || d.presenation == 'Path chronological') {
						return ((DISP_TIME + beat * d.n_steps) / 60) * 3;
					} else {
						return ((d.reps * beat) / 60) * 3;
					}
				}

				// Box (down and up) total time
				else if (this.exerciseType.uid == 'BOX_DOWN' || this.exerciseType.uid == 'BOX_UP') {
					var beatPerRep = (60 / ea.instructions.tempo[0]) * 4;

					return ((ea.instructions.reps[0] * beatPerRep) / 60) * 3;
				}

				// Box (over forwards) total time
				else if (this.exerciseType.uid == 'BOX_OVER' && byo.instructions.action[0] == 'Forwards') {
					var beatPerRep = (60 / ea.instructions.tempo[0]) * 16.5;

					return ((ea.instructions.reps[0] * beatPerRep) / 60) * 3;
				}

				// Box (over sideways) total time
				else if (this.exerciseType.uid == 'BOX_OVER' && byo.instructions.action[0] == 'Sideways') {
					var beatPerRep = (60 / ea.instructions.tempo[0]) * 8;
					return ((ea.instructions.reps[0] * beatPerRep) / 60) * 3;
				}

				// Box (over default) total time
				else if (this.exerciseType.uid == 'BOX_OVER') {
					// combination
					var beatPerRep = (60 / ea.instructions.tempo[0]) * 24.5;

					return ((ea.instructions.reps[0] * beatPerRep) / 60) * 3;
				}

				// fallback for box (and all the other ones infact!
				else return ((ea.instructions.reps[0] * (60 / ea.instructions.tempo[0])) / 60) * 3;
			}

			// With no other option, we return -1
			return -1;
		}

		// Dynamic Tempo time
		else if (this.exerciseType.levelSchema == 'dynamic_tempo') {
			var beats = 0;
			var animation = this.custom_animation ? this.floorSequence : this.exerciseType.floorSequence;

			// the total duration of all the animation sequence is the beats per seq times multiplers per seq
			animation.forEach((a) => (beats += a.beats * (a.multiplier || 1)));
			return (((60 / this.tempo) * beats * (this.reps || 1)) / 60) * 3;
		}

		// tempo+duration - new type where the duration is the length, and the tempo determines how fast each beat is
		else if (this.exerciseType.timing == 'tempo+duration') {
			return this.duration / 60;
		}

		// tempo+reps - new type where the reps * the tempo is the duration
		else if (this.exerciseType.timing == 'tempo+reps') {
			return ((60 / this.tempo) * this.reps) / 60;
		}

		// Cognitive total time
		else if (this.exerciseType.levelSchema == 'cog' && byo && byo.duration) {
			return byo.duration;
		} else if (this.exerciseType.levelSchema == 'cog' && byo && byo.instructions && byo.instructions.tempo && byo.instructions.reps) {
			return (((60 / byo.instructions.tempo[0]) * (byo.instructions.reps[0] || 1)) / 60) * 3;
		} else {
			return ((this.duration * (this.reps || 1)) / 60) * 3;
		}
	}

	toCog(options?: any): CognitiveExercise {
		var tempos = [];

		if (this.exerciseType.cog_mode == 'Initial') {
			var cr = this._randPickCog();
			return {
				mode: this.exerciseType.cog_mode,
				cue: cr.col1,
				cue_to_ignore: cr.col2,
				tempos: [cr.tempo],
				feedback: <any>cr.feedback,
			};
		} else if (this.exerciseType.cog_mode == 'Audio' || this.exerciseType.cog_mode == 'Visual') {
			var cr = this._randPickCog();

			return {
				mode: this.exerciseType.cog_mode,
				cue_to_count: cr.col1,
				cue_to_ignore: cr.col2,
				tempos: [cr.tempo],
				feedback: <any>cr.feedback,
			};
		} else if (this.exerciseType.cog_mode == 'Recall') {
			var cr = this._randPickCog();
			if (options && options.forceForward) {
				return {
					mode: this.exerciseType.cog_mode,
					recall_forward: cr.col1,
					tempos: [cr.tempo],
					feedback: <any>cr.feedback,
				};
			} else if (options && options.forceForward === false) {
				return {
					mode: this.exerciseType.cog_mode,
					recall_backwards: cr.col2,
					tempos: [cr.tempo],
					feedback: <any>cr.feedback,
				};
			} else if (Math.random() > 0.5)
				return {
					mode: this.exerciseType.cog_mode,
					recall_forward: cr.col1,
					tempos: [cr.tempo],
					feedback: <any>cr.feedback,
				};
			else
				return {
					mode: this.exerciseType.cog_mode,
					recall_backwards: cr.col2,
					tempos: [cr.tempo],
					feedback: <any>cr.feedback,
				};
		} else if (this.exerciseType.cog_mode == 'Pattern') {
			var cr = this._randPickCog();
			return {
				mode: this.exerciseType.cog_mode,
				pattern: cr.col1,
				next_shape: cr.col2,
				tempos: [cr.tempo],
				feedback: <any>cr.feedback,
			};
		} else if (this.exerciseType.cog_mode == 'Inhibition') {
			var cr = this._randPickCog();
			return {
				mode: this.exerciseType.cog_mode,
				cue: cr.col1,
				tempos: [cr.tempo],
				feedback: <any>cr.feedback,
			};
		} else return null;
	}
	private _randPick<T>(arr: T[]): T {
		return arr[Math.floor(Math.random() * arr.length)];
	}
	private _randPickCog() {
		var ai = Math.floor(Math.random() * this.col1.length);

		return {
			col1: this.col1[ai],
			col2: this.col2.length > ai ? this.col2[ai] : null,
			tempo: this.tempos.length > ai ? this.tempos[ai] : null,
			feedback: this.feedback.length > ai ? this.feedback[ai] : null,
		};
	}
}

interface BalanceResults {
	together: number[];
	neartandem: number[];
	tandem: number[];
	left: number[];
	right: number[];
}
export class ExerciseAction extends STModel<ExerciseAction> {
	@AsClass(ExerciseLevel)
	exercise: ExerciseLevel;
	owner: STUser;
	complete: boolean;
	rating: number;
	outcome: string;
	duration: number;
	exerciseTime: number = new Date().getTime();
	weekID: string;

	l_id: number; // localID for Joel's use of syncing etc.
	usedChair: boolean; // property required by business logic that specifies that the user needed a chair to lean on while doing exercise
	usedWeights: boolean; // property required by business logic that specifies that the user used weights while doing exercise
	dartLogic: any;
	instructions: ExerciseInstruction;
	boxLogic: any;
	balanceResults: BalanceResults;
	byoHelp: ExerciseHelp;

	constructor(V: Vasat) {
		super(V, 'ExerciseAction');
	}

	toJSON() {
		// whatever super is doing thats causing huge json
		var json = super.toJSON();

		// make owner a simple id object, as only the local app needs to know about it
		if (json.owner) json.owner = { id: json.owner.id };

		// if its go a ref to the exercise, make it just a shorter representation
		if (json.exercise) json.exercise = { id: json.exercise.id, type: 'ExerciseLevel' };
		// return that instead
		return json;
	}
	_toJSON(the_obj: any, depth: number, refMap: any[]) {
		// compact vasat objects anywhere in the sup references
		if (depth && the_obj instanceof VasatModel) {
			return { id: the_obj.id, type: the_obj.className() };
		} else {
			return super._toJSON(the_obj, depth, refMap);
		}
	}

	/**
	 * Returns 1 help UNLESS its cogntive, in which case it returns the cognitives help and then the physicals help after
	 */
	getHelp(param: any = ''): ExerciseHelp[] {
		var eh = this._getHelp(param);

		return this.byoHelp ? [eh, this.byoHelp] : [eh];
	}
	_getHelp(param: any = ''): ExerciseHelp {
		var helps = this.V.getCached(ExerciseHelp);

		if (!helps) throw 'Need V.registerCached(ExerciseHelp,[all the helps])';

		helps = helps.filter((h) => {
			if (this.exercise.exerciseType.category.uid == 'dart') {
				return h.exercise.id == 17 || h.exercise.id == 165;
			} else return h.exercise.id == this.exercise.exerciseType.id;
		});
		var expr = this.exercise.exerciseType.helpExpression;

		if (expr && expr.length && helps.length > 1) {
			var uidToUse = '';
			try {
				var fixArrays = (obj) => {
					if (obj == null) {
						return null;
					}
					if (Array.isArray(obj) && obj.length && typeof obj[0] != 'object') {
						var s = '';
						if (obj.length) {
							obj.forEach((cur) => (s += '_' + cur));
							s = s.substring(1);
						}
						return s;
					} else if (typeof obj == 'object') {
						Object.keys(obj).forEach((k) => (obj[k] = fixArrays(obj[k])));
						return obj;
					} else {
						return obj;
					}
				};

				var copyOf = fixArrays(Object.assign({}, this.instructions));
				copyOf.param = param;
				uidToUse = _.template(expr)(copyOf);
			} catch (e) {
				console.log(this);
				throw 'template ' + expr + ' is bad ' + e;
			}
			uidToUse = uidToUse.toLowerCase().replace(/ \& /g, '_').replace(/ /g, '_');
			var result = helps.find((h) => h.uid == uidToUse);
			if (!result) {
				console.log('All helps debug:', helps);
				throw 'Unable to find help with uid ' + uidToUse;
			}

			return result;
		} else if (!helps.length) throw `No helps for this type ${this.exercise.exerciseType.name}`;
		else if (helps.length > 1) throw 'Multiple helps and no expression';
		else return helps[0];
	}
}

export class ExerciseHelp extends STModel<ExerciseHelp> {
	@AsClass(Exercise)
	exercise: Exercise;

	constructor(V: Vasat) {
		super(V, 'ExerciseHelp');
	}
	uid: string;
	question_title_1: string;
	question_body_1: string;

	question_title_2: string;
	question_body_2: string;

	question_title_3: string;
	question_body_3: string;

	question_title_4: string;
	question_body_4: string;

	question_title_5: string;
	question_body_5: string;
}

export class STUser extends User {
	first_name: string;
	last_name: string;

	telephone_number: string;
	mobile_number: string;
	country: string;
	dob: number;
	how_did_you_hear: string;

	contact_method: string;

	isTrial: boolean;
	is_patient: boolean;
	is_clinician: boolean;
	is_po: boolean;
	study_id: string;

	startDate: number;
	adherenceLastUseDate: number;
	adherenceFailReason: string;
	adherenceFailTrigDate: number;
	adherenceFailLastDate: number;

	lastUseDate: any;

	// ST OR ST+Cogn
	program_type: string;

	constructor(V: Vasat) {
		super(V, 'User');
	}

	urlPath() {
		return '/api/me';
	}

	// Exercise Data
	levelProgression: ProgressCategory[];
	levelProgressionString: string; // Contains a string containing all max_checkpoint values, so we can trigger a rebuild of cached 'states'
	weekly_doseage: string;
	completedHours: { [weekId: string]: number };
	completedCognitiveHours: { [weekId: string]: number };
	requiredHours: { [weekId: string]: number };
	balancePerMonth: { [monthId: string]: number };
	lastBalanceAssesment: number | null;
	primeUser: boolean;
	lastWorkoutWasCog: boolean;
	cognitiveHoursSinceLastAssess: number;
	cognitiveRatings: number[];

	// UI settings
	guide: number;
	notify_me: boolean;
	showExercise: boolean;
	showSettings: boolean;

	// Advanced personal details
	gender: string;
	is_atsi: string;
	birth_country: string;
	language: string;
	living_situation: string;
	servicesUsed: any;
	services_frequency: string;
	employment: string;
	employment_time: string;
	type_of_practice: string;
	hospital_discharge: string;
	other_falls_prevention: string;
	has_buddy: string;
	falls_frequency: string;
	has_chronic_health_issues: string;
	has_neurological_disorder: string;
	has_cardiovascular_disease: string;
	has_dizziniess: string;
	uses_balance_medication: string;
	has_joint_problems: string;
	can_walk_10_meters: string;
	has_walking_aid: string;
	has_vision_problems: string;
	has_hearing_problems: string;
	study_acronymn: string;

	// Basic personal details
	address: string;
	homephone: string;
	mobile: '00112233';
	owner: { id: 307; type: 'User' };
	postcode: '2010';
	state: 'Nsw';
	suburb: 'Suburbia';

	/*postSet(a){
	  return this.sync()
	}*/

	// Update related data for the user
	updateRelations(args: updateRelationsArgs = {}) {
		this.V.getCached(Exercise).forEach((e) => {
			if (e.updateRelations) e.updateRelations();
		});

		// Determine if we should reset the users' ProgressCategory states based on a change to levelProgressionMax, or if we know there is a new category
		let updateProgressCategory: boolean = args.forceLevelProgressionUpdate;
		let maxCPString: string;
		let atLeastOneLPWithMax = (this.levelProgression && this.levelProgression.filter((lp) => lp.max_checkpoint > 0)) || [];
		if (this.levelProgressionString || atLeastOneLPWithMax.length) {
			maxCPString = this.getLevelProgressionMaxString(this.levelProgression);

			if (maxCPString !== this.levelProgressionString) {
				updateProgressCategory = true;
				this.levelProgressionString = '';
			}
		}

		// If this user has a levelProgression, load the cached instances of exercises and levels
		if (!updateProgressCategory && this.levelProgression && this.levelProgression.length) {
			var finishedInit = false;

			// Get Level Progressions but filter out categories that don't match with cached data
			var lp = this.levelProgression.filter((cur) => {
				if (!cur.category) {
					console.error('No category!');
					console.error(cur);
					return false;
				}
				var category = this.V.findCached(ExerciseCategory, cur.category.id);
				if (!category) {
					console.warn('No cached category ' + cur.category.id);
					return false;
				}
				cur.category = category;
				return true;
			});

			// Handle Balance Assessment / init category
			lp.forEach((cur) => {
				if (cur.category.uid == 'init') {
					cur.states.forEach((s) => {
						s.exercise = this.V.findCached(Exercise, s.exercise.id);
						s.currentLevel = s.exercise.levels[0];
						if (s.exercise.uid == 'init') {
							finishedInit = s.finished;
						}
					});
				}
			});

			// For each ProgressCategory
			lp.forEach((cur) =>
				cur.states.forEach((state) => {
					// Get the exercise
					state.exercise = this.V.findCached(Exercise, state.exercise.id);

					// If this exercise has levels
					if (state.exercise.levels.length && state.exercise.uid != 'init') {
						// Get the current level
						state.currentLevel = state.currentLevel ? this.V.findCached(ExerciseLevel, state.currentLevel.id) : state.exercise.levels[0];

						// Check whether it should be active
						if (!state.active) {
							let currentLevel: boolean = state.currentLevel.level == cur.checkpoint && finishedInit;
							let dartGridHighCheckpoint = cur.checkpoint > 9 && (cur.category.uid == 'dart' || cur.category.uid == 'grid');
							state.active = currentLevel || dartGridHighCheckpoint;
						}

						if (state.active) state.finished = false;
					}
				})
			);
		}

		// Otherwise set new user's levelProgression (either fresh, or based on existing levelProgression)
		else {
			// Get all Exercises from cache
			var sDog = this.V.getCached(Exercise)
				.filter((a) => a.active)
				.sort((a, b) => (a.levels && b.levels && a.levels.length && b.levels.length ? a.levels[0].level - b.levels[0].level : -1));

			// Set levelProgression by filtering the states and setting them active or not based on your checkpoint and your max checkpoint
			this.levelProgression = this.V.getCached(ExerciseCategory)
				.filter((a) => a.active || a.uid == 'box') // Filter only active (cms-driven status) or box categories
				.sort((n1, n2) => n1.seq - n2.seq) // Order them by the category's sequence
				.map((ec) => {
					let lpc = (this.levelProgression && this.levelProgression.find((lp) => lp.category.id == ec.id)) || null;
					let checkpoint = (updateProgressCategory && lpc && lpc.checkpoint) || 1;
					let maxCheckpoint = (lpc && lpc.max_checkpoint) || null;
					let disabled = (lpc && lpc.disabled) || false;
					checkpoint = (maxCheckpoint && Math.min(maxCheckpoint, checkpoint)) || checkpoint;

					return <ProgressCategory>{
						category: ec,
						checkpoint: ec.uid == 'init' ? 0 : checkpoint,
						max_checkpoint: maxCheckpoint || null,
						disabled: disabled,
						states: sDog
							.filter((e) => e.category == ec)
							.map((e) => {
								let highestLevel = e.levels.filter((l) => l.level <= checkpoint).pop() || e.levels[0];
								var l = e.levels && e.levels.length ? highestLevel : null;
								//var l = e.levels && e.levels.length ? e.levels.find(l => l.level == checkpoint) || e.levels[0] : null;
								return {
									exercise: e,
									currentLevel: l,
									finished: false,
									active: l && l.level <= checkpoint,
								};
							}),
					};
				});

			if (updateProgressCategory) {
				console.log('%c+++++++++++ Reset Cached Exercise States', 'color:orange;');
				this.levelProgressionString = maxCPString;
				this.save();
			}
		}

		console.log('%c+++++++++++++++++++++ COMPLETE: Updating Relations', 'color:green;');
	}

	sanityCheck() {
		if (!this.levelProgression) return;
		this.levelProgression.forEach((cur) => {
			if (!cur.category) {
				alert('User level progression error. Category missing');
			}
			cur.states &&
				cur.states.forEach((s) => {
					if (!s.exercise) {
						alert('User level progression error. Exercise missing');
					}
				});
		});
	}

	saveObservable() {
		this.sanityCheck();
		return super.saveObservable();
	}
	_toJSON(the_obj: any, depth: number, refMap: any[]) {
		// compact vasat objects anywhere in the sup references
		if (depth && the_obj instanceof VasatModel) {
			return { id: the_obj.id, type: the_obj.className() };
		} else {
			return super._toJSON(the_obj, depth, refMap);
		}
	}
	/*
	sync(){
	  var ec = this.V.getCached(ExerciseCategory)
	  if (ec && ec.length){
		return of(this)
	  }
	  return this.V.onReady().pipe(
		 // Load the patent and categories
		 flatMap(res =>
		   forkJoin([
			 this.V.searchByObject(ExerciseCategory).useCache(true).queryObservable(),
		   ])
		 ),
		 // Load the exercises (they have a classOf for category, thats why we wait untill cats are loaded first)
		 flatMap(res =>
			this.V.searchByObject(Exercise).useCache(true).queryObservable()
		 ),
		 // Load the rest (they have classOfs for the itmes above, so need to be loaded in one batch)
		 flatMap(res =>
		   forkJoin([
			 this.V.searchByObject(ExerciseLevel).useCache(true).limit(1000).queryObservable(),
			 this.V.searchByObject(ExerciseAction).useCache(true).queryObservable()
		   ])
		 ),
		 map(_ => {
		   this.V.getCached(Exercise).forEach(e => e.updateRelations())
		   return this
		 })
	   ).share()

	}*/

	/*
	// Deprecated
	hashCode(string): string {
		var hash = 0;
		if (string.length == 0) {
			return hash.toString();
		}
		for (var i = 0; i < string.length; i++) {
			var char = string.charCodeAt(i);
			hash = ((hash<<5)-hash)+char;
			hash = hash & hash;
		}
		return hash.toString();
	}*/

	getLevelProgressionMaxString(levelProgression): string {
		let lpMax = '';
		levelProgression
			.sort((lp1, lp2) => (lp1.category.id < lp2.category.id && -1) || 1)
			.forEach((lp) => (lpMax += (lp.max_checkpoint && lp.max_checkpoint.toString()) || ''));

		return lpMax;
	}
}

export class Site extends STModel<Site> {
	uid: string;

	// sort by seequence
	seq: number;

	constructor(V: Vasat) {
		super(V, 'Site');
	}
}

export class FAQ extends STModel<FAQ> {
	category: string;

	constructor(V: Vasat) {
		super(V, 'FAQ');
	}
}

export class TrialExerciseDefinition {
	exerciseID: number;
	levelID: number;
}
