///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE CLASS
export class FileStream {

	////////////////////////////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////////// ABOUT THE FILESTREAM
	/*
		The Filestream allows developers to use the Cordova File plugin more easily, with promise-returning chainable functions.  
		Most of it's functions allow operation on 1 item only - removing, moving, writing, etc, only works on the last known file or directory entry (this.lastEntry)
		It can list all files in a directory (getAllEntries()), but the developer will then need to loop over and modify multiple entries.

		Usage:
		let fs = new FileStream();
		fs.setup()
		.getEntry("/assets/json/settings.json")
		.moveTo("/assets/settings", true, "mysettings.json") // setting true will create a new folder if settings doesn't exist
		.write(myData, true) // setting true appends the data to the end of the file, rather than overwriting
		.read()
		.returnData("mysettings") // returns data under the property 'mysettings'
	*/
	/*
		NOTES:
		- Currently only works well with dataDirectory (see Cordova File docs)
		- If you provide a cdvafile:// path to the system, some functions will try to covnert to regular filesystem, and check that you setup([dir]) with the right dir that matches
	*/

	//cdvfile://localhost/root/var/mobile/Containers/Data/Application/752B1B22-AE91-4D54-8A69-EE0D236F6156/tmp/cdv_photo_001.jpg
	//cdvfile://localhost/library-nosync/cordova_bot.png


	////////////////////////////////////
    todo:any[]			= [];
	rootEntry:any;		// root of filesystem
	lastEntry:any;		// last queried entry
	entryList:any[];	// last queried entry list
	lastData:any;
	dataReturn:any = {
		"_default": {}
	};

	////////////////////////////////
	private fileSystem:any;
	private chosenFileSystem:string; // Cordova file location, e.g. dataDirectory
	private filesystemLocation:any;
	private file:any;

	////////////////////////////////
	cdvaDataDirectory:string = window['cordova_custom'].device().platform == "Android" && window['cordova'].file.dataDirectory || "cdvfile://localhost/library-nosync";

	////////////////////////////////
	error_codes:any = {
		"code1": "NOT_FOUND_ERR",
		"code2": "SECURITY_ERR",
		"code3": "ABORT_ERR",
		"code4": "NOT_READABLE_ERR",
		"code5": "ENCODING_ERR",
		"code6": "NO_MODIFICATION_ALLOWED_ERR",
		"code7": "INVALID_STATE_ERR",
		"code8": "SYNTAX_ERR",
		"code9": "INVALID_MODIFICATION_ERR",
		"code10": "QUOTA_EXCEEDED_ERR",
		"code11": "TYPE_MISMATCH_ERR",
		"code12": "PATH_EXISTS_ERR"
	}

	////////////////////////////////////
	constructor() {}

	////////////////////////////////////
	public setup(location:string="dataDirectory", filesystem:string="permanent"/*||temporary*/): this {
		this.addTodo( { func:this.setupGo, args:[location, filesystem] } );
		return this;
	}

	////////////////////////////////////
	public debugData(): this {
		this.addTodo( { func:this.debugDataGo, args:[] } );
		return this;
	}

	////////////////////////////////////
	public getRoot(): this {
		this.addTodo( { func:this.getRootGo, args:[] } );
		return this;
	}

	////////////////////////////////////
	public getEntry(path:string, createUnknownDirs:boolean = true, createUnknownFile:boolean = false): this {
		this.addTodo( { func:this.getEntryGo, args:[path, createUnknownDirs, createUnknownFile] } );
		return this;
	}

	////////////////////////////////////
	public getAllEntries(): this {
		this.addTodo( { func:this.getAllEntriesGo, args:[] } );
		return this;
	}

	////////////////////////////////////
	public remove(recursivelyIfDirectory:boolean = false): this {
		this.addTodo( { func:this.removeGo, args:[recursivelyIfDirectory] } );
		return this;
	}

	////////////////////////////////////
	public removeByPath(path:string, recursivelyIfDirectory:boolean): this {
		this.addTodo( { func:this.removeByPathGo, args:[path, recursivelyIfDirectory] } );
		return this;
	}

	////////////////////////////////////
	public moveTo(path:string, createPath:boolean = false, newName:string = null): this {
		this.addTodo( { func:this.moveToGo, args:[path, createPath, newName] } );
		return this;
	}

	////////////////////////////////////
	public copyByEntries(entryFrom:any, entryTo:any) {
		this.addTodo( { func:this.copyByEntriesGo, args:[entryFrom, entryTo] } );
		return this;
	}

	////////////////////////////////////
	public copyByPath(pathFrom:string, pathTo:string, newName:string, createPath:boolean = false): this {
		this.addTodo( { func:this.copyByPathGo, args:[pathFrom, pathTo, newName, createPath] } );
		return this;
	}

	////////////////////////////////////
	public writeTo(data:any, appendTo:boolean = false, createPathOrFile:boolean = false): this {
		this.addTodo( { func:this.writeToGo, args:[data, appendTo, createPathOrFile] } );
		return this;
	}

	////////////////////////////////////
	public read(as:string = 'text' /* text, dataURL, binary, arrayBuffer */): this {
		this.addTodo( { func:this.readGo, args:[as] } );
		return this;
	}

	////////////////////////////////////
	public readAtPath(path:string, as:string = 'text' /* text, dataURL, binary, arrayBuffer */): this {
		this.addTodo( { func:this.readAtPathGo, args:[path, as] } );
		return this;
	}

	////////////////////////////////////
	public toURL(): this {
		this.addTodo( { func:this.toURLGo, args:[] } );
		return this;
	}

	////////////////////////////////////
	public returnData(propertyName): this {
		this.addTodo( { func:this.returnDataGo, args:[propertyName] } );
		return this;
	}

	////////////////////////////////////
	public go() {
		return this.goGo();
	}

	///////////////////////////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////// ACTIVITY FUNCTIONS
	////////////////////////////////////
	private async setupGo(location, permOrTemp):Promise<any> {
		
		let resolver = function(resolve, reject) {
			let cordova = window['cordova'];
			if (!cordova || !cordova.file) return reject("FILESTREAM: Cordova or Cordova File not installed");
			this.file = cordova.file;
			this.setFilesystemLocation(location);
			this.getFilesystem(permOrTemp)
			.then(fs => {
				this.getRootGo()
				.then(res => { 
					this.rootEntry = res;
					this.lastEntry = res;
					this.prepReturnData(this.lastEntry);
					resolve();
				});
			});
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////////
	private async debugDataGo():Promise<any> {
		console.log(">> DEBUG: Last Known Entry:", this.lastEntry);
		console.log(">> DEBUG: Last Known Entry Listing:", this.entryList);
		return Promise.resolve();
	}

	////////////////////////////////////
	private async getRootGo():Promise<any> {
		let resolver = function(resolve, reject) {
			let resolveLocalFileSystemURL = window['resolveLocalFileSystemURL'];
			resolveLocalFileSystemURL(this.filesystemLocation, function (dirEntry) {
				this.lastEntry = dirEntry;
				this.prepReturnData(this.lastEntry);
				resolve(dirEntry);
			}.bind(this));
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////////
	private async getEntryGo(path, createPath, createFile):Promise<any> {
		let resolver = async function(resolve, reject) {

			path = this.prepareCordovaPath(path);
			let pathNodes = this.createPathNodes(path);

			let error = false;
			for (let x=0; x<pathNodes.nodes.length; x++) {
				if (error) break;
				if (pathNodes.nodes[x] == "") {
					await this.getRootGo().catch(reject);
				}
				else if (pathNodes.nodes[x].indexOf(".") == -1) {
					await this.getImmediateDirectory(pathNodes.nodes[x], createPath).catch(err => { reject(this.prepareError(err, "getEntry", path)); error = true });
				} else {
					await this.getImmediateFile(pathNodes.nodes[x], createFile).catch(err => { reject(this.prepareError(err, "getEntry", path)); error = true });
				}
			}
			if (!error) {
				// Add our own filepath
				this.lastEntry.filePathUrl = this.filesystemLocation+this.lastEntry.fullPath;
				resolve(this.lastEntry);
			}
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////////
	private async getAllEntriesGo():Promise<any> {
		let resolver = function(resolve, reject) {

			if (!this.lastEntry || !this.lastEntry.isDirectory) return reject( { message:"Not a directory for listing", operation:"getAllEntries" });

			// Create a reader to read through the files
			var reader = this.lastEntry.createReader();
			reader.readEntries(
				function (entries) {
					this.prepReturnData(this.lastEntry);
					let name = this.lastEntry.name && this.lastEntry.name.length && this.lastEntry.name || "root";
					this.dataReturn[name].entries = entries;
					entries.forEach(ent => {
						ent.filePathUrl = this.filesystemLocation+ent.fullPath;
					});
					this.entryList = entries;
					resolve(entries);
				}.bind(this),
				function (err) {
					reject(this.prepareError(err, "getAllEntries"));
				}.bind(this));
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////////
	private async removeGo(recursively): Promise<any> {
		let resolver = function(resolve, reject) {
			if (this.lastEntry == this.rootEntry) return reject( { message:"Cannot remove root", operation:"remove" } );
			if (!this.lastEntry) return reject( { message:"No entry to remove", operation:"remove" } );
			
			let removeFunc;
			if (this.lastEntry.isDirectory && recursively) removeFunc = this.lastEntry.removeRecursively;
			else removeFunc = this.lastEntry.remove;

			removeFunc.bind(this.lastEntry)(
				function () {
					this.lastEntry = this.rootEntry;
					this.prepReturnData(this.lastEntry);
					resolve(this.rootEntry);
				}.bind(this), function(err) {
					if (err.code == 9) err.message = "Cannot remove: Directory not empty";
					reject(this.prepareError(err, "remove"));
				}.bind(this)
			);
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////////
	private async removeByPathGo(path, recursively): Promise<any> {
		let resolver = function(resolve, reject) {

			path = this.prepareCordovaPath(path);

			this.getEntryGo(path, false)
			.then(res => {
				this.removeGo(recursively)
				.then(resolve)
				.catch(err => reject(this.prepareError(err, "removeByPath - removeEntry", path)));
			}).catch(err => reject(this.prepareError(err, "removeByPath - getEntry", path)));
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////////
	private async moveToGo(path, createPath, newName): Promise<any> {
		let resolver = function(resolve, reject) {

			path = this.prepareCordovaPath(path);
			let movableObject = this.lastEntry;
			
			this.getEntryGo(path, createPath)
			.then(res => {
				movableObject.moveTo(this.lastEntry, newName || movableObject.name,
					function() {
						this.lastEntry = movableObject;
						this.prepReturnData(this.lastEntry);
						resolve(this.lastEntry);
					}.bind(this),
					function(err) {
						reject(this.prepareError(err, "moveTo", path));
					}.bind(this)
				);
			}).catch(err => reject(this.prepareError(err, "moveTo - getEntry", path)));
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////////
	private async copyByEntriesGo(entryToCopy, entryToCopyTo): Promise<any> {
		let resolver = function(resolve, reject) {
			entryToCopy.copyTo(entryToCopyTo, null,
				function(copiedObject) {
					this.lastEntry = copiedObject;
					this.prepReturnData(this.lastEntry);
					resolve(this.lastEntry);
				}.bind(this),
				function(err) {
					reject(this.prepareError(err, "copyTo", entryToCopy));
				}.bind(this)
			);
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////////
	private async copyByPathGo(pathFrom, pathTo, newName, createPath): Promise<any> {
		let resolver = function(resolve, reject) {

			pathFrom = this.prepareCordovaPath(pathFrom);
			pathTo = this.prepareCordovaPath(pathTo);
			//let copyableObject = this.lastEntry;
			
			this.getEntryGo(pathFrom, false)
			.then(entryToCopy => {

				this.getEntryGo(pathTo, createPath)
				.then(entryToCopyTo => {
					entryToCopy.copyTo(entryToCopyTo, newName,
						function(copiedObject) {
							this.lastEntry = copiedObject;
							this.prepReturnData(this.lastEntry);
							resolve(this.lastEntry);
						}.bind(this),
						function(err) {
							reject(this.prepareError(err, "copyTo", pathFrom));
						}.bind(this)
					);
				});
			}).catch(err => reject(this.prepareError(err, "copyTo - getEntry", pathFrom)));
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////////
private async writeToGo(data, appendTo, createPath): Promise<any> {
		let resolver = function(resolve, reject) {

			if (!this.lastEntry || !this.lastEntry.isFile) return reject( { message:"Not a file for writing", operation:"writeTo", details:this.lastEntry && this.lastEntry.name });

			this.createFileWriter(this.lastEntry, appendTo)
			.then(wr => {
				wr.onwrite = function() {
					resolve(this.lastEntry);
					data = null; // Clear out data now that it's written
					wr = null; // Clear the writer now that we're done
				}.bind(this)
				//wr.onwriteend = function(res) {}.bind(this);
				wr.onerror = (err) => { reject(this.prepareError(err, 'writeTo', this.lastEntry.name)); };

				wr.write(data);
			}).catch(err => reject(this.prepareError(err, 'writeTo', this.lastEntry.name)))
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////////
	private async readGo(as): Promise<any> {
		let resolver = async function (resolve, reject) {

			if (!this.lastEntry || !this.lastEntry.isFile) return reject( { message:"Not a file for reading", operation:"read", details:this.lastEntry && this.lastEntry.name });
			this.createFileReader(this.lastEntry)
			.then(res => {
				let reader = res.reader;

				reader.onload = (evt:any) => {
					this.lastData = evt.target.result
					resolve(evt.target.result);
				};
				reader.onerror = err => { console.log("READ ERROR HERE", err); reject(this.prepareError(err, 'read', this.lastEntry.name)); };
				switch (as) {
					case "text":		reader.readAsText(res.file);		break;
					case "dataURL":		reader.readAsDataURL(res.file);		break;
					case "binaryString":reader.readAsBinaryString(res.file);break;
					case "arrayBuffer": reader.readAsArrayBuffer(res.file);	break;
				}
			});
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////////
	private async readAtPathGo(path, as): Promise<any> {
		let resolver = async function (resolve, reject) {

			path = this.prepareCordovaPath(path);

			this.getEntryGo(path, false)
			.then(entry => {
				this.createFileReader(entry)
				.then(res => {
					let reader = res.reader;

					reader.onload = (evt:any) => {
						this.lastData = evt.target.result
						resolve(evt.target.result);
					};
					reader.onerror = err => { reject(this.prepareError(err, 'writeTo', this.lastEntry.name)); };
					switch (as) {
						case "text":		reader.readAsText(res.file);		break;
						case "dataURL":		reader.readAsDataURL(res.file);		break;
						case "binary":		reader.readAsBinaryString(res.file);break;
						case "arrayBuffer": reader.readAsArrayBuffer(res.file);	break;
					}
				});
			})
			.catch(err => reject(this.prepareError(err, 'readAtPath', path)))
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////////
	private async toURLGo(): Promise<any> {
		this.prepReturnData(this.lastEntry);
		this.dataReturn[this.lastEntry.name].url = this.lastEntry.toURL();
		this.dataReturn[this.lastEntry.name].internalUrl = this.lastEntry.toInternalURL();
		this.dataReturn[this.lastEntry.name].ionicUrl = (window as any).Ionic && (window as any).Ionic.WebView && (window as any).Ionic.WebView.convertFileSrc(this.lastEntry.toURL()) || "disabled";

		return Promise.resolve();
	}

	////////////////////////////////////
	private async returnDataGo(propertyName): Promise<any> {
		this.dataReturn[propertyName] = this.lastData;
		Promise.resolve(this.lastData);
	}
	

	////////////////////////////////////
	private goGo(): Promise<any> {

		////////////////////////////////////
		let resolver = async function (resolve, reject) {

			for (let x=0; x < this.todo.length; x++) {
				await this.todo[x].func.apply(this, this.todo[x].args)
				.catch(err => {
					this.todo = [];
					reject(err);
				});
			};
			resolve( { lastEntry:this.lastEntry, returnData:this.dataReturn } );
		}.bind(this);

		////////////////////////////////////
		return new Promise(resolver);
	}


	///////////////////////////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////// PRIVATE FUNCTIONS
	////////////////////////////////////
	private addTodo(object:any): void {
		object.id = this.todo.length+1;
		object.resolved = false;
		this.todo.push(object);
	}

	////////////////////////////////
	private setFilesystemLocation(location) {
		this.chosenFileSystem = location;
		this.filesystemLocation = this.file[location];
	}

	////////////////////////////////
	private getFilesystem(which) {

		let resolver = function(resolve, reject) {

			let requestFileSystem = window['requestFileSystem'];
			let LocalFileSystem = window['LocalFileSystem'];
			let location; let size;

			switch (which) {
				case "temporary":	location = LocalFileSystem.TEMPORARY; size = 5 * 1024 * 1024; break;
				case "permanent":
				default:			location = LocalFileSystem.PERSISTENT; size = 0; break;
			}
			
			requestFileSystem(location, size,
				function (fs) {
					this.fileSystem = fs;
					resolve(this.fileSystem);
				}.bind(this),
				function(err) {
					reject(this.prepareError(err, "getFilesystem", which));
				}.bind(this)
			);

		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////
	private async getImmediateDirectory(pathNode:string, createUnknownDirectory:boolean) {

		let resolver = function(resolve, reject) {
			this.lastEntry.getDirectory(pathNode, { create: createUnknownDirectory },
				function (dirEntry) {
					this.lastEntry = dirEntry;
					this.prepReturnData(this.lastEntry);
					resolve(dirEntry);
				}.bind(this),
				function(err) {
					console.log("ERR CAUGHT", err);
					reject(this.prepareError(err, "getImmediateDirectory", pathNode));
				}.bind(this)
			)
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////
	private async getImmediateFile(pathNode:string, createUnknownFile:boolean) {

		let resolver = function(resolve, reject) {

			if (!this.lastEntry || !this.lastEntry.isDirectory) return reject("Not a directory for listing a file");

			this.lastEntry.getFile(pathNode, {create: createUnknownFile, exclusive: false},
			function (fileEntry) {
				this.lastEntry = fileEntry;
				this.prepReturnData(this.lastEntry);
				resolve(fileEntry);
			}.bind(this), function(err) {
				reject(this.prepareError(err, "getImmediateFile", pathNode));
			}.bind(this));
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////
	private createPathNodes(path): any {
		let pathNodes = path.replace("file:///", "").replace("file://", "").split("/");
		let isFile = false; let isDir = false;
		if (pathNodes[pathNodes.length-1] == "") pathNodes.pop();
		if (path.indexOf(".") == -1) isDir = true;
		if (pathNodes[pathNodes.length-1].indexOf(".") != -1) isFile = true;

		return { nodes:pathNodes, isFilePath:isFile, isDirectoryPath:isDir }
	}

	////////////////////////////////
	private prepareError(err, duringOperation, details=null): any {
		if (!err.message && err.code) err.message = this.error_codes["code"+err.code];
		if (!err.operation) err.operation = duringOperation;
		if (!err.details && details) err.details = details;
		return err;
	}

	////////////////////////////////
	private createFileReader(entry) {
		let resolver = function(resolve, reject) {
			entry.file(
				res => {
					var reader = new FileReader();
					resolve( { reader:reader, file:res });
				},
				err => {
					reject(this.prepareError(err, 'createFileReader'));
				}
			);
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////
	private createFileWriter(entry, appendTo) {
		let resolver = function(resolve, reject) {
			entry.createWriter(
				function(writer) {
					if (!appendTo) {
						writer.onwrite = res => resolve(writer);
						writer.onerror = err => reject(this.prepareError(err, 'createFileWriter - Truncate'));
						writer.truncate(0); // deletes all content
					} else {
						resolve(writer);
					}
				}.bind(this),
				function(err) {
					reject(this.prepareError(err, 'createFileWriter'));
				}.bind(this)
			);
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////
	private prepReturnData(parentEntry) {
		let name = parentEntry.name;
		if (name == "") name = "root"
		this.dataReturn[name] = { name:name, entry:parentEntry };
	}

	////////////////////////////////
	private prepareCordovaPath(path) {
		if (path.indexOf(this.cdvaDataDirectory) != -1) {
			if (this.chosenFileSystem == "dataDirectory") return path.replace(this.cdvaDataDirectory, "");
			else throw "Chosen filesystem doesn't match cordova file path";
		}
		else if (path.indexOf("cdvfile:") != -1) {
			throw "Trying to use a currently unsupported cordova file path";
		}
		else return path;
	}
}