/*
In app-routing you need to give each route an acceptedAuth array of strings, and those strings need to match 
*/

import { Injectable } 													from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } 	from '@angular/router';
import { Observable } 													from 'rxjs';
import { UserService } 													from '../services/user.service';
import { NavigationService } 											from '../services/navigation.service';

@Injectable({
	providedIn: 'root'
})
export class CanActivateWithAuthGuard implements CanActivate {

	constructor(private sUser: UserService, private sNavigation: NavigationService) {}
	
	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

		//Translate the passed in auth parameters to fit the Frozen object from the nav service
		let translatedAuthParameters = next.data["acceptedAuth"]
		.filter(x => {
			//Filter out any non-legit auth types
			if (this.sNavigation.authTypes[x] != null)
				return true;
			else
				return false;
		})
		.map(x => {
			//Map the strings to the auth type number instead
			return this.sNavigation.authTypes[x]
		});
		
		//Loop through all auth parameters
		for (let i = 0; i < translatedAuthParameters.length; i++) {
			
			//Switch Case the current passed parameter
			switch (translatedAuthParameters[i]) {

				//All
				case this.sNavigation.authTypes.all:

					//Immediately return true
					return true;
				
				//None
				case this.sNavigation.authTypes.none:

					//Immediately return false
					return false;

				//Logged in
				case this.sNavigation.authTypes.loggedin:

					//If a user is logged in, return true, otherwise try again
					if ((this.sUser.user.id as any) != "User")
						return true;
					else
						break;

				//Anonymous
				case this.sNavigation.authTypes.anonymous:

					//If there is no currently logged in user, return true
					if ((this.sUser.user.id as any)== "User")
						return true;
					else
						break;

				default:
					break;
			}
		}

		//If we got to here, our auth did not fit any of the auth params, and were returning false
		return false;
	}
}
