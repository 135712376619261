import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-exercise6-sessioncompleted',
  templateUrl: './page-exercise6-sessioncompleted.component.html',
  styleUrls: ['./page-exercise6-sessioncompleted.component.scss']
})
export class PageExercise6SessioncompletedComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

	onBackButton() {
    this.router.navigate(['/home']);
	}
}
