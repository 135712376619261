import { Injectable, OnInit } 	from '@angular/core';
import { LocationStrategy } 	from '@angular/common';

@Injectable({
	providedIn: 'root'
})
export class NavigationService {

	public authTypes: any = Object.freeze({"all": 0, "loggedin": 1, "anonymous": 2, "none": 3});

	backButtonPressed: boolean = false;

	constructor(private locationStrategy: LocationStrategy) { 
		this.locationStrategy.onPopState(() => {
			
			this.setBackButtonPressed(true);
			return false;
		});
	}

	////////////////////////////////
	setBackButtonPressed(newBackButtonPressed: boolean) {
		this.backButtonPressed = newBackButtonPressed
	}

	getBackButtonPressed(): boolean {
		return this.backButtonPressed;
	}
}
