///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
////////// MODULES
import { Component, OnInit, Input, ViewChild, ElementRef, HostListener } from '@angular/core';
import { repEventTypeName } from '../../st-commons/shortSchedule';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
	selector: 'time-circle',
	templateUrl: './time-circle.component.html',
	styleUrls: ['./time-circle.component.scss'],
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class TimeCircleComponent implements OnInit {
	////////////////////////////////
	@Input() countDownSelection: string = 'false';
	@Input() colour: string = 'ffffff';
	@Input() id: string = 'na';
	@ViewChild('label') label;
	@ViewChild('svgActive') svgActive;

	////////////////////////////////
	@HostListener('window:' + repEventTypeName, ['$event'])
	handleRepEvent(event: KeyboardEvent) {
		this.addRep();
	}

	////////////////////////////////////
	private timerTick: number = 1000; // If adjusted, CSS transitions may need to be adjusted
	private pathsVisible: boolean = false;
	private labelVisible: boolean = false;
	public timerCreated: boolean = false;
	public timerPaused: boolean;
	private timerLastRecord: Date;
	public timerSettings: TimerSettings;
	private timerElapsed: number = 0;
	private repsRecorded: number = 0;
	private percentageComplete: number;
	private timeValue: number;
	private completionBehaviourDelayDefault: number = 2000;
	private timerInterval: any;
	private lastPauseTime: number;
	private timerPauseTime: number;

	////////////////////////////////////
	constructor(private timeCircleEl: ElementRef) {}

	////////////////////////////////////
	ngOnInit() {}

	////////////////////////////////////
	createTimer(timerSettings: TimerSettings) {
		this.timerCreated = true;
		this.timerPaused = true;
		this.timerSettings = timerSettings;
		this.timerElapsed = this.timerSettings.elapsedValueMS || 0;

		this.setStrokes();
		this.calculateProgress();
		this.checkCompletion();

		setTimeout(() => {
			this.pathsVisible = true;
			this.labelVisible = true;
		}, 1000);
	}

	////////////////////////////////////
	setStrokes() {
		let stroke1: HTMLElement = this.timeCircleEl.nativeElement.querySelector('time-circle svg:first-child path');
		let stroke2: HTMLElement = this.timeCircleEl.nativeElement.querySelector('time-circle svg:nth-child(2) path');
		stroke1.style.stroke = '#' + this.colour;
		stroke2.style.stroke = '#' + this.colour;
	}

	////////////////////////////////////
	setRunning() {
		this.timerPaused = false;
		this.timerLastRecord = new Date();
		this.timerInterval = setInterval(this.updateTimer.bind(this), this.timerTick);
	}

	////////////////////////////////////
	setStopped() {
		clearInterval(this.timerInterval);
	}

	////////////////////////////////////
	pauseTimer() {
		this.lastPauseTime = new Date().getTime();
		this.timerPaused = true;
	}

	////////////////////////////////////
	resumeTimer() {
		this.timerPauseTime = new Date().getTime() - this.lastPauseTime;
		this.timerPaused = false;
	}

	////////////////////////////////////
	reset() {
		this.timerElapsed = 0;
		this.percentageComplete = 0;
		this.repsRecorded = 0;
		this.calculateProgress();
		this.checkCompletion();

		//this.removeRepListener();
	}

	////////////////////////////////////
	resetFade() {
		this.labelVisible = false;
		setTimeout(this.reset.bind(this), 500);
		setTimeout(() => {
			this.labelVisible = true;
		}, 1000);
	}

	////////////////////////////////////
	updateTimer() {
		const updateTime = new Date().getTime() - this.timerLastRecord.getTime();
		this.timerElapsed += updateTime;
		this.timerLastRecord = new Date();

		this.calculateProgress();
		this.checkCompletion();
	}

	////////////////////////////////////
	getLabel() {
		if (!this.timerCreated) return '';

		const dir = this.timerSettings.direction;
		const isCountdown = dir == TimerDirection.countdown;

		let value;
		let unitSuffix;

		switch (this.timerSettings.timerMode) {
			case TimerMode.percentage:
				let perc = Math.round(this.percentageComplete * 100);
				value = isCountdown ? 100 - perc : perc;
				unitSuffix = '%';
				break;

			case TimerMode.reps:
				value = isCountdown ? this.timerSettings.totalValue - this.repsRecorded : this.repsRecorded;
				unitSuffix = '';
				break;

			case TimerMode.time:
			default:
				if (this.timeValue > 60) {
					value = this.timeValue! / 60;
					value = Math.round(value);
					unitSuffix = 'm ';
				} else {
					value = Math.round(this.timeValue);
					unitSuffix = 's';
				}
		}

		return value.toString() + unitSuffix;
	}

	////////////////////////////////////
	getValues() {
		return {
			totalValue: this.timerSettings.totalValue,
			elapsedTime: this.timerElapsed,
			elapsedReps: this.repsRecorded || 0,
		};
	}

	////////////////////////////////////
	calculateProgress() {
		switch (this.timerSettings.timerMode) {
			case TimerMode.reps:
				this.calculateProgressReps();
				break;

			default:
				this.calculateProgressTime();
		}
	}

	////////////////////////////////////
	calculateProgressTime() {
		if (this.timerSettings.totalValue === 0) {
			this.percentageComplete = 0;
			this.timeValue = 0;
			return;
		}

		let perc = this.timerElapsed / 1000 / this.timerSettings.totalValue;
		let time;
		switch (this.timerSettings.direction) {
			case TimerDirection.countup:
				time = this.timerElapsed / 1000;
				break;

			case TimerDirection.countdown:
			default:
				time = this.timerSettings.totalValue - this.timerElapsed / 1000;
				break;
		}

		// Shape the value of percentage
		const min0 = Math.max(0, perc);
		const max100 = Math.min(1, min0);
		this.percentageComplete = max100;

		// Shape the value of time
		const min0T = Math.max(0, time);
		const max100T = Math.min(this.timerSettings.totalValue, min0T);
		this.timeValue = max100T;
	}

	////////////////////////////////////
	calculateProgressReps() {
		this.percentageComplete = this.repsRecorded / this.timerSettings.totalValue;
	}

	////////////////////////////////////
	checkCompletion() {
		switch (this.timerSettings.timerMode) {
			case TimerMode.time:
			case TimerMode.percentage:
				this.checkCompletionTime();
				break;

			case TimerMode.reps:
				this.checkCompletionReps();
				break;
		}
	}

	////////////////////////////////////
	checkCompletionTime() {
		if (this.percentageComplete >= 1 && !this.timerPaused) this.triggerCompletion();
	}

	////////////////////////////////////
	checkCompletionReps() {
		if (this.repsRecorded >= this.timerSettings.totalValue && !this.timerPaused) this.triggerCompletion();
	}

	////////////////////////////////////
	triggerCompletion() {
		this.setStopped();
		this.timerSettings.completionCallback && this.timerSettings.completionCallback({ totalDuration: this.timerSettings.totalValue });
		const behaviourTime = this.timerSettings.completionBehaviourDelay || this.completionBehaviourDelayDefault;
		const reset = this.reset.bind(this);
		const resetFade = this.resetFade.bind(this);

		switch (this.timerSettings.completionBehaviour) {
			case TimerCompletionBehaviour.reset:
				setTimeout(reset, behaviourTime);
				break;
			case TimerCompletionBehaviour.resetfade:
				setTimeout(resetFade, behaviourTime);
				break;
			case TimerCompletionBehaviour.pause:
				// No effect
				break;
		}
	}

	////////////////////////////////////
	addRep() {
		this.repsRecorded += 1;
	}

	////////////////////////////////////
	getDashOffsetValue() {
		if (!this.timerCreated) return 100;
		return 100 - this.percentageComplete * 100;
	}
}

////////////////////////////////////
////////////////////////////////////
export enum TimerMode {
	time = 'time',
	percentage = 'percentage',
	reps = 'reps',
}

export enum TimerCompletionBehaviour {
	reset = 'reset',
	resetfade = 'resetfade',
	pause = 'pause',
}

export enum TimerDirection {
	countup = 'countup',
	countdown = 'countdown',
}

interface TimerSettings {
	timerMode: TimerMode;
	direction: TimerDirection;
	totalValue: number; // Expressed either in seconds, or reps
	elapsedValueMS?: number; // To start a timer at a value other than total
	completionCallback?: Function; // Function to all when the timer has completed
	completionBehaviour: TimerCompletionBehaviour; // What to do to the Timer when it is complete
	completionBehaviourDelay?: number;
}
