///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
////////// MODULES
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { TimeCircleComponent, TimerCompletionBehaviour, TimerDirection, TimerMode } from '../../components/time-circle/time-circle.component';
import { DataService } from '../../services/data.service';
import { ExerciseService } from '../../services/exercise.service';

declare var require; // Otherwise causes an error on jD's comp.  Will resolve correctly later and remove this line.

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
	selector: 'app-page-progress3motivateme',
	templateUrl: './page-progress3motivateme.component.html',
	styleUrls: ['./page-progress3motivateme.component.scss'],
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class PageProgress3motivatemeComponent implements OnInit, AfterViewInit {
	@ViewChild('progressCircle') progressCircle: TimeCircleComponent;

	////////////////////////////////
	private goals: any[] = [];
	private goalIndex: number = 0;
	private quoteIndex: number = 0;
	private dosages: any = {};

	//////////////////////////////
	quotes: any[] = [];

	////////////////////////////////
	constructor(private sData: DataService, private sExercise: ExerciseService) {
		this.sData.getGoals().then((res) => {
			this.goals = res.data.goals;
			if (this.goals.length) this.nextGoal(0);
			this.nextQuote(0);
		});
	}
	ngOnInit() {
		let quotes = require('../../../assets/json/motivquotes.json');
		quotes = this.shuffle(quotes);
		this.quotes = quotes;
		this.nextQuote();
	}

	ngAfterViewInit() {
		this.sExercise.getDoseageForThisWeek().then((res) => {
			this.dosages = res;

			// Setup the progress circle
			this.progressCircle.createTimer({
				timerMode: TimerMode.percentage,
				direction: TimerDirection.countup,
				totalValue: 100,
				elapsedValueMS: this.dosages.percentage * 1000,
				completionBehaviour: TimerCompletionBehaviour.reset,
			});
		});
	}

	////////////////////////////////
	nextGoal(index = null) {
		if (index !== null) {
			this.goalIndex = index;
		} else {
			this.goalIndex += 1;
			if (this.goalIndex > this.goals.length - 1) this.goalIndex = 0;
		}
		this.goals.map((i) => {
			i.state = '';
		});
		this.goals.map((i, index) => {
			if (index == this.goalIndex) i.state = 'on';
			return i;
		});
	}

	////////////////////////////////
	nextQuote(index = null) {
		if (index !== null) {
			this.quoteIndex = index;
		} else {
			this.quoteIndex += 1;
			if (this.quoteIndex > this.quotes.length - 1) this.quoteIndex = 0;
		}
		this.quotes.map((i) => {
			i.on = false;
		});
		this.quotes.map((i, index) => {
			if (index == this.quoteIndex) i.on = true;
			return i;
		});
	}

	//////////////////////////////
	shuffle(array) {
		var currentIndex = array.length,
			temporaryValue,
			randomIndex;

		while (0 !== currentIndex) {
			randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex -= 1;
			temporaryValue = array[currentIndex];
			array[currentIndex] = array[randomIndex];
			array[randomIndex] = temporaryValue;
		}

		return array;
	}
}
