///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
////////// MODULES
import { Component, OnInit, ViewChild } from '@angular/core';
import { ExerciseService } from '../../services/exercise.service';
import { DataService } from '../../services/data.service';
import { UserService } from '../../services/user.service';
import { ExerciseAction } from '../../st-commons/models';
import { TimeCircleComponent, TimerCompletionBehaviour, TimerDirection, TimerMode } from '../../components/time-circle/time-circle.component';

declare var require; // Otherwise causes an error on jD's comp.  Will resolve correctly later and remove this line.
const M = require('moment');

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
	selector: 'app-page-progress1home',
	templateUrl: './page-progress1home.component.html',
	styleUrls: ['./page-progress1home.component.scss'],
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class PageProgress1homeComponent implements OnInit {
	////////////////////////////////
	@ViewChild('journalNub') journalNub;
	@ViewChild('progressCircle') progressCircle: TimeCircleComponent;

	////////////////////////////////
	colour: string = 'F59031';
	journalPerc: number = null;
	daysPerc: number[] = [0, 0, 0, 0, 0, 0, 0];
	daysTime: number[] = [0, 0, 0, 0, 0, 0, 0];

	////////////////////////////////
	private dosages: any = {};

	////////////////////////////////
	constructor(private sExercise: ExerciseService, private sData: DataService, private sUser: UserService) {}
	ngOnInit() {
		// Get dosages
		this.sExercise
			.getDoseageForThisWeek()
			.then((res) => {
				this.dosages = res;

				// Get weekly data
				this.getWeeklyDataAndOptions().then((res: any) => {
					setTimeout(() => {
						this.daysPerc = res.perc;
						this.daysTime = res.time;
					}, 1000);
				});

				// Setup the progress circle
				this.progressCircle.createTimer({
					timerMode: TimerMode.percentage,
					direction: TimerDirection.countup,
					totalValue: 100,
					elapsedValueMS: this.dosages.percentage * 1000,
					completionBehaviour: TimerCompletionBehaviour.reset,
				});
			})
			.catch();

		// Get journal items
		this.sData
			.getRawDataFromStorage('journalItems')
			.then((res: any[]) => {
				let myJournals = res.filter((it) => {
					return it.owner_id == this.sUser.user.id;
				});
				if (myJournals.length) {
					myJournals = myJournals.sort((a, b) => {
						if (a.date > b.date) return 1;
						return -1;
					});
					this.journalPerc = myJournals[0].wellbeing;
					setTimeout(() => {
						this.journalNub.setValue(this.journalPerc);
					}, 250);
				}
			})
			.catch((err) => {
				console.log('Unable to get journals');
			});
	}

	////////////////////////////////////
	getProgressDescription() {
		let label;
		if (this.dosages.percentage < 50) {
			label = 'Pretty good! Maybe try to hit a few more goals this week.';
		} else if (this.dosages.percentage < 70) {
			label = "Keep up the good work, you're on target!";
		} else {
			label = "Well done, you're doing great!";
		}
		return label;
	}

	////////////////////////////////////
	getRoundedCompletage() {
		let roundage = Math.round(this.dosages.completage);
		let diff = this.dosages.completage - roundage;
		let desc: string = '';
		if (diff > 0) desc = 'a little over';
		else if (diff < 0) desc = 'a little under';
		return { val: roundage, desc: desc };
	}

	////////////////////////////////
	getWeeklyDataAndOptions() {
		let resolver = function (resolve, reject) {
			this.sData.getTypedDataFromStorage(ExerciseAction).then((res) => {
				let startOfWeek = M().startOf('isoWeek').toDate().getTime();
				let endOfWeek = M().endOf('isoWeek').toDate().getTime();
				let data = res.data.ExerciseAction;
				let dataDays = [0, 0, 0, 0, 0, 0, 0];
				data = data.filter((it) => {
					let timeOfEx = it.dateMade || it.exerciseTime;
					return (
						it.owner.id == this.sUser.user.id &&
						timeOfEx >= startOfWeek &&
						timeOfEx < endOfWeek &&
						it.name != 'Balance Assesment' &&
						it.name != 'Balance Assessment'
					);
				});
				data.forEach((it) => {
					dataDays[M(it.dateMade || it.exerciseTime).isoWeekday() - 1] += it.duration;
				});
				dataDays = dataDays.map((it) => {
					if (it > 0) return Math.round(it * 100) / 100;
					else return 0;
				});

				// Turn into percentages
				let daysPerc = dataDays.map((dat) => {
					return Math.min(100, (dat / this.dosages.dosage) * 100);
				});

				// Round
				let daysTime = dataDays.map((dat) => {
					return Math.round(dat);
				});

				resolve({ perc: daysPerc, time: daysTime });
			});
		}.bind(this);

		return new Promise(resolver);
	}
}
