///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

//////////////////////////////// ANGULAR CORE
import { Injectable } from '@angular/core';

//////////////////////////////// MODULES
import { VasatObjectService } from './vasatobject.service';
import { Vasat } from 'vasat';
import { ExerciseCategory, Exercise, ExerciseLevel, ExerciseAction, ExerciseHelp, STUser, FAQ } from '../st-commons/models';
import { DataStream } from '../objects/datastream';
import { LocalGoal, LocalJournal, LocalUser, LocalCalendar } from '../objects/localModels';
//import { NativestorageService }			from './nativestorage.service';
import { StorageService } from './storage.service';
import { UserService } from './user.service';
import { NotificationsService } from './notifications.service';
import { FileStream } from '../objects/filestream';
import { FileDownloadService } from './filedownload.service';
import { Push, PushObject, PushOptions } from '@ionic-native/push';
import { GlobalPubSub } from './global-pub-sub.service';
import { DeviceService } from './device.service';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
@Injectable()
export class DataService {
	////////////////////////////////
	sendingServerUpdates: boolean = false;
	previousClientKey: any;

	////////////////////////////////
	constructor(
		private sVasat: VasatObjectService,
		private V: Vasat,
		private sStorage: StorageService,
		private sUser: UserService,
		private sNotification: NotificationsService,
		private sFileDownload: FileDownloadService,
		private push: Push,
		private sGlobalPubSub: GlobalPubSub,
		private router: Router,
		private sDevice: DeviceService
	) {}

	////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////// USER METHODS
	////////////////////////////////////
	reinstateVasatToken(): Promise<any> {
		let resolver = function (resolve, reject) {
			this.getPrimeUser()
				.then((primeUser) => {
					this.getTypedDataFromStorage(LocalUser)
						.then((token) => {
							let theToken = token.data.LocalUser.filter((it) => {
								return it.owner_id == primeUser.data.primeUser[0].id;
							});
							if (!theToken.length) return reject('No tokens to reinstate');
							else {
								let vasatAuth = {
									access_token: theToken[0].access_token,
									user: primeUser.data.primeUser[0],
									device: theToken[0].device,
								};

								localStorage[this.V.clientKey()] = JSON.stringify(vasatAuth);
								this.V.localSession();
								// Provide the STUser to Vasat
								this.V.registerCacheClass(STUser, [primeUser.data.primeUser[0]], true);
								resolve();
							}
						})
						.catch(reject);
				})
				.catch(reject);
		}.bind(this);
		return new Promise(resolver);
	}

	////////////////////////////////////
	clearVasatClientKey() {
		this.previousClientKey = localStorage.getItem(this.V.clientKey()); // Let's take a backup in case a user fails to login
		localStorage.removeItem(this.V.clientKey());
		this.V.token = null;
		this.V.headers = null;
		this.V.localSession();
	}

	////////////////////////////////////
	reinstatePreviousVasatToken() {
		if (!this.previousClientKey) return false;
		localStorage.setItem(this.V.clientKey(), this.previousClientKey);
		this.V.localSession();
		this.previousClientKey = null;
		return true;
	}

	////////////////////////////////////
	getUserProfiles(): Promise<any> {
		return new DataStream(this.V, this.sUser, this.sStorage)
			.setDataType(STUser)
			.getData()
			.returnData('userProfiles')
			.go()
			.catch((err) => console.log('Error during get Prime User'));
	}

	////////////////////////////////////
	getPrimeUser() {
		return new DataStream(this.V, this.sUser, this.sStorage)
			.setDataType(STUser)
			.getData()
			.filter((el) => {
				return el.primeUser === true;
			})
			.convertDataToType(STUser)
			.returnData('primeUser')
			.go()
			.catch((err) => console.log('Error during get Prime User'));
	}

	////////////////////////////////////
	reinstateVasat(): Promise<any> {
		return this.V.onReady().toPromise();
	}

	////////////////////////////////////
	login(email, pass) {
		let resolver = function (resolve, reject) {
			//////// Check that the user doesn't already exist
			this.getUserProfiles().then((profiles) => {
				let profileExists = profiles.data.userProfiles.filter((profile) => {
					return profile.username == email;
				});
				if (profileExists.length > 0) {
					this.sUser.setPrimeUserByProvidingUser(profileExists[0]);
					this.setPrimeUser(profileExists[0].id).then((res) => this.updateUserProfile(this.sUser.user, false, null)); // Save the adherenceLastUseDate to the profile, SHOULD we send it to the server?
					resolve({ message: 'duplicatelogin_reinstated', res: null });
				} else {
					letsLogin.bind(this)();
				}
			});

			////////////////////////////////////
			function letsLogin() {
				// this.V.token = null; // Clear out the existing token so it generates a new one
				this.clearVasatClientKey();

				this.V.loginWithUsernameObservable(email, pass)
					.timeoutWith(20000, Observable.of('Timeout'))
					.toPromise()
					.then(gotUser.bind(this))
					.catch(error.bind(this));
			}

			////////////////////////////////////
			function gotUser(res) {
				// If we got a timeout
				if (res == 'Timeout') return error.bind(this)(res);

				// Check that the user has chosen a guide - otherwise apply a random one
				if (!res.guide) res.guide = Math.floor(Math.random() * 4);
				res.adherenceLastUseDate = new Date().getTime();

				// Save user
				this.saveFullUser(res)
					.then(() => {
						resolve(res);
						this.setPrimeUser(res.id);

						let stUser = new STUser(this.V);
						stUser.set(res);
						stUser.primeUser = true;
						this.sUser.setPrimeUserByProvidingUser(stUser);
						this.registerPushForLoggedInUser();

						// Update the guide
						this.sUser.updateGuide(res.guide);
					})
					.catch(error.bind(this));
			}

			////////////////////////////////////
			function error(err) {
				reject(err);
			}
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////////
	setPrimeUser(id: number) {
		let resolver = function (resolve, reject) {
			this.setPrimeUserGo(id).then((res) => {
				this.reinstateVasatToken()
					.then((res2) => {
						resolve(res);
					})
					.catch(reject);
				let primeUser = res.data.userProfiles.filter((it) => {
					return it.primeUser == true;
				})[0];
				primeUser.adherenceLastUseDate = new Date().getTime();
				this.sUser.setPrimeUserByProvidingUser(primeUser);
			});
		}.bind(this);

		return new Promise(resolver);
	}
	////////////////////////////////////
	setPrimeUserGo(id: number) {
		return new DataStream(this.V, this.sUser, this.sStorage)
			.setDataType(STUser)
			.getData()
			.sort((it) => {
				if (it.id == id) return -1;
				return 1;
			})
			.convertDataToType(STUser)
			.map((item) => {
				if (item.id == id) item.primeUser = true;
				else item.primeUser = false;
				return item;
			})
			.saveDataByOverwrite()
			.returnData('userProfiles')
			.go()
			.catch((err) => console.log('Error during set Prime User'));
	}

	////////////////////////////////////
	checkAllUserDataSynced(allUsersData = false) {
		let resolver = function (resolve, reject) {
			let promises: Promise<number>[] = [];

			promises.push(this.getRawDataFromStorage('datapushlist'));
			promises.push(this.getRawDataFromStorage('userProfiles'));
			promises.push(this.getRawDataFromStorage('userTokens'));

			Promise.all(promises).then((res: any) => {
				if (!allUsersData)
					res[0] = res[0].filter((psh) => {
						return psh.owner == this.sUser.user.id;
					});
				if (res[0].length && res[1].length && res[2].length) {
					reject('Not all data synced');
				} else resolve();
			});
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////////
	logoutAllProfiles() {
		let resolver = (resolve, reject) => {
			new DataStream(this.V, this.sUser, this.sStorage)
				.setDataType(STUser)
				.getData()
				.returnData('users')
				.go()
				.then((result) => {
					result.data.users.forEach((usr) => {
						this.logoutProfile(usr.id);
					});
				});

			resolve();
		};

		return new Promise(resolver);
	}

	////////////////////////////////////
	logoutProfile(id) {
		let resolver = function (resolve, reject) {
			// Need to stop the user if there is no connection
			let connection = (window['navigator'] as any).connection;
			if (connection && connection.type == 'none') {
				this.sGlobalPubSub.fireEvent('toast', [true, 'Unable to logout right now as you must have an Internet connection.']);
				return reject('No connection');
			}

			new DataStream(this.V, this.sUser, this.sStorage)

				.setDataType(LocalUser)
				.getData()
				.filter((it) => {
					return it.owner_id != id;
				})
				.saveDataByOverwrite()

				.setDataType(ExerciseAction)
				.getData()
				.filter((it) => {
					return it.owner.id != id;
				})
				.saveDataByOverwrite()

				.setDataType(LocalGoal)
				.getData()
				.filter((it) => {
					return it.owner_id != id;
				})
				.saveDataByOverwrite()

				.setDataType(LocalJournal)
				.getData()
				.filter((it) => {
					return it.owner_id != id;
				})
				.saveDataByOverwrite()

				.setDataType(STUser)
				.getData()
				.filter((it) => {
					return it.id != id;
				})
				.map((item, index) => {
					if (index == 0) item.primeUser = true;
					else item.primeUser = false;
					return item;
				})
				.saveDataByOverwrite()

				.returnData('remainingUsers')
				.go()

				.then((res) => {
					// Clear any LocalCalendars and any notifications attached to them.
					let cals = new DataStream(this.V, this.sUser, this.sStorage)
						.setDataType(LocalCalendar)
						.getData()
						.filter((it) => {
							return it.owner_id == id;
						})
						.returnData('calendarIDs')
						.getData()
						.filter((it) => {
							return it.owner_id != id;
						})
						.saveDataByOverwrite()
						.go()
						.then((res) => {
							let calendarIDs = res.data.calendarIDs;
							calendarIDs = calendarIDs.map((cid) => {
								return cid.l_id;
							});

							calendarIDs.forEach((id) => this.sNotification.deleteWeeklyNotification(id));
						});

					// Clear all the user's files
					if (window['cordova_custom'].device() != 'browser') {
						let fs = new FileStream();
						fs.setup('dataDirectory')
							.removeByPath('/data/user_' + this.sUser.user.id, true)
							.go()
							.catch((err) => {
								console.log('Error during remove user profile photo: ', err.message);
							});
					}

					// Clear all user's datapushlist items
					this.getRawDataFromStorage('datapushlist').then((res) => {
						let userPush = res.filter((it) => {
							return it.owner == id;
						});
						userPush.forEach((it) => {
							new DataStream(this.V, this.sUser, this.sStorage)
								.unflagAsServerUpdateable(it.id, it.idKey, it.type)
								.go()
								.catch((err) => console.log('Error during lgout profile > get raw data'));
						});
					});

					// End if no users left
					if (!res.data.remainingUsers.length) {
						this.sUser.clearUser();
						this.clearVasatClientKey();
						this.router.navigate(['/onboarding']);
						return resolve(res);
					}

					// Otherwise check for a primeUser, and set one if not
					let primeUser = res.data.remainingUsers.filter((it) => {
						return it.primeUser == true;
					});
					if (primeUser.length) return resolve(res);
					else {
						this.setPrimeUser(res.data.remainingUsers[0].id).then(resolve).catch(reject);
					}
				})
				.catch(reject);
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////////
	requestCreateUserFromVasat(email, pass, options) {
		return this.V.signupWithObservable(email, pass, options).timeoutWith(20000, Observable.of('Timeout')).toPromise();
	}

	////////////////////////////////////
	saveFullUser(loginOrCreateResponse): Promise<any> {
		return new DataStream(this.V, this.sUser, this.sStorage)
			.useProvidedData(loginOrCreateResponse)
			.convertDataToType(STUser)
			.saveDataByAppend()
			.deleteTemporaryStreamData()
			.useProvidedData({
				access_token: this.V.token,
				owner_id: loginOrCreateResponse.id,
				first_name: loginOrCreateResponse.first_name,
				last_name: loginOrCreateResponse.last_name,
			})
			.convertDataToType(LocalUser)
			.saveDataByAppend()
			.returnData('newUserToken')
			.go()
			.catch((err) => console.log('Error during save full user', err));
	}

	////////////////////////////////////
	updateUserProfile(fullUserProfile, flagAsServerUpdateable: boolean = true, properties: any[]) {
		let resolver = function (resolve, reject) {
			if (fullUserProfile instanceof STUser) fullUserProfile = fullUserProfile.toJSON();
			let ds = new DataStream(this.V, this.sUser, this.sStorage);

			if (flagAsServerUpdateable) {
				if (!properties || !properties.length) return Promise.reject('Cannot flag user as updateable with no properties.');
				return ds
					.useProvidedData(fullUserProfile)
					.overwriteLocalRecords('id', 'userProfiles')
					.flagAsServerUpdateable([fullUserProfile.id], 'id', STUser, fullUserProfile.id, properties)
					.go()
					.then(resolve)
					.catch(reject);
			} else {
				return ds.useProvidedData(fullUserProfile).overwriteLocalRecords('id', 'userProfiles').go().then(resolve).catch(reject);
			}
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////////
	/*saveAccessToken(res:any = "no arguments sent") {
		return Promise.resolve(res);
	}*/

	////////////////////////////////////
	setGuide(index: number) {
		this.sUser.updateGuide(index);
		return new DataStream(this.V, this.sUser, this.sStorage)
			.useProvidedData(this.sUser.user)
			.overwriteLocalRecords('id', 'userProfiles')
			.go()
			.catch((err) => console.log('Error during save guide', err));
	}

	////////////////////////////////////
	sendAllServerUpdates() {
		if (this.sendingServerUpdates) return Promise.resolve();
		this.sendingServerUpdates = true;
		return new DataStream(this.V, this.sUser, this.sStorage)
			.updateServerData()
			.go()
			.then((res) => {
				this.sendingServerUpdates = false;
			})
			.catch((err) => {
				console.log('Error during sending all server updates', err);

				if (err.status == 406 && err.message == 'Not Logged in') {
					this.logoutProfile(this.sUser.user.id);
					setTimeout(() => this.router.navigate(['/onboarding']), 1000);
				}
				this.sendingServerUpdates = false;
				this.handleServerError(err);
			});
	}

	////////////////////////////////
	registerPushForLoggedInUser() {
		if (window['cordova_custom'].device() != 'browser') {
			// to check if we have permission
			this.push.hasPermission().then((res: any) => {});

			// Create a channel (Android O and above). You'll need to provide the id, description and importance properties.
			this.push
				.createChannel({
					id: 'stchannel',
					description: 'Standing Tall channel',
					importance: 3, // The importance property goes from 1 = Lowest to 5 = Highest.
				})
				.then(() => console.log('Channel created'));

			// to initialize push notifications
			const options: PushOptions = {
				android: {},
				ios: {
					alert: 'true',
					badge: true,
					sound: 'false',
				},
			};

			const pushObject: PushObject = this.push.init(options);

			pushObject.on('registration').subscribe(
				(registration: any) => {
					// Check special case for ios. We need to convert the string from HEX to base64 as it is the format APNS understands.
					let regId = this.sDevice.isiOS ? this.hexToBase64(registration.registrationId) : registration.registrationId;
					this.V.registerPushRegId(regId).subscribe(
						(res) => {},
						(err) => {}
					);
				},
				(err: any) => {}
				/*this.V.registerPushRegId(registration.registrationId).subscribe(res => {});*/
			);

			pushObject.on('notification').subscribe((notification: any) => {
				// Do extra stuff with the push notification received.
				// This will get called when push notification is received.
				// notification object fields: {title: "The title", message: "Message body", additionalData: Object}
			});

			pushObject.on('error').subscribe((error) => {
				// Handle errors here
			});
		}
	}

	hexToBase64(str) {
		return btoa(
			String.fromCharCode.apply(
				null,
				str
					.replace(/\r|\n/g, '')
					.replace(/([\da-fA-F]{2}) ?/g, '0x$1 ')
					.replace(/ +$/, '')
					.split(' ')
			)
		);
	}

	////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////// GOAL METHODS
	////////////////////////////////////
	getGoals() {
		var exposedData;
		return new DataStream(this.V, this.sUser, this.sStorage)
			.setDataType(STUser)
			.getData()
			.filter((el) => {
				return el.primeUser === true;
			})
			.exposeData((data, rData) => {
				exposedData = data;
			})
			.setDataType(LocalGoal)
			.getData()
			.filter((i) => {
				return i.owner_id == exposedData[0].id;
			})
			.sort((a, b) => {
				if (a.l_id < b.l_id) return -1;
				return 1;
			})
			.returnData('goals')
			.go()
			.catch((err) => console.log('Error during get goals', err));
	}

	////////////////////////////////////
	getJournalItems() {
		var exposedData;
		return new DataStream(this.V, this.sUser, this.sStorage)
			.setDataType(STUser)
			.getData()
			.filter((el) => {
				return el.primeUser === true;
			})
			.exposeData((data, rData) => {
				exposedData = data;
			})
			.setDataType(LocalJournal)
			.getData()
			.filter((i) => {
				return i.owner_id == exposedData[0].id;
			})
			.sort((a, b) => {
				if (a.l_id < b.l_id) return -1;
				return 1;
			})
			.returnData('journal')
			.go()
			.catch((err) => console.log('Error during get journal items', err));
	}

	////////////////////////////////////
	saveNewGoal(goalData: LocalGoal): Promise<any> {
		return new DataStream(this.V, this.sUser, this.sStorage)
			.useProvidedData(goalData)
			.saveDataByAppend(true, 'l_id')
			.go()
			.catch((err) => console.log('Error during save new goal', err));
	}

	////////////////////////////////////
	saveNewJournalItem(journalData: LocalJournal): Promise<any> {
		return new DataStream(this.V, this.sUser, this.sStorage)
			.useProvidedData(journalData)
			.saveDataByAppend(true, 'l_id')
			.returnData('newJournalItem')
			.go()
			.catch((err) => console.log('Error during save new journal', err));
	}

	////////////////////////////////////
	deleteGoalByID(id) {
		return new DataStream(this.V, this.sUser, this.sStorage)
			.setDataType(LocalGoal)
			.getData()
			.filter((i) => {
				return i.l_id != id;
			})
			.saveDataByOverwrite()
			.go()
			.catch((err) => console.log('Error during delete goal', err));
	}

	////////////////////////////////////
	deleteJournalByID(id) {
		return new DataStream(this.V, this.sUser, this.sStorage)
			.setDataType(LocalJournal)
			.getData()
			.filter((i) => {
				return i.l_id != id;
			})
			.saveDataByOverwrite()
			.go();
	}

	////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////// CALENDAR METHODS
	getCalendarItems() {
		var exposedData;
		return new DataStream(this.V, this.sUser, this.sStorage)
			.setDataType(STUser)
			.getData()
			.filter((el) => {
				return el.primeUser === true;
			})
			.exposeData((data, rData) => {
				exposedData = data;
			})
			.setDataType(LocalCalendar)
			.getData()
			.filter((i) => {
				return i.owner_id == exposedData[0].id;
			})
			.sort((a, b) => {
				if (a.day_of_week * 2400 + parseInt(a.time.replace(':', '')) < b.day_of_week * 2400 + parseInt(b.time.replace(':', ''))) return -1;
				return 1;
			})
			.returnData('calendar')
			.go()
			.catch((err) => console.log('Error during get calendar items', err));
	}

	////////////////////////////////
	saveNewCalendarItemForPrimeUser(calendarData) {
		return new DataStream(this.V, this.sUser, this.sStorage)
			.setDataType(STUser)
			.getData()
			.filter((el) => {
				return el.primeUser === true;
			})
			.exposeData((data) => {
				calendarData.owner_id = data[0].id;
			})
			.deleteTemporaryStreamData()
			.setDataType(LocalCalendar)
			.useProvidedData(calendarData)
			.saveDataByAppend(true, 'l_id')
			.returnData('new_calendar')
			.go();
	}

	////////////////////////////////////
	deleteCalendarItemByID(id) {
		return new DataStream(this.V, this.sUser, this.sStorage)
			.setDataType(LocalCalendar)
			.getData()
			.filter((i) => {
				return i.l_id != id;
			})
			.saveDataByOverwrite()
			.go();
	}

	////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////// EXERCISE METHODS
	////////////////////////////////
	getAndCacheExerciseData() {
		let resolver = function (resolve, reject) {
			this.getTypedDataFromStorage(ExerciseCategory)
				.then(this.provideDataToVasatCache.bind(this))
				.then((nill) => this.getTypedDataFromStorage(Exercise))
				.then(this.provideDataToVasatCache.bind(this))
				.then((nill) => this.getTypedDataFromStorage(ExerciseLevel))
				.then(this.provideDataToVasatCache.bind(this))
				.then((nill) => this.getTypedDataFromStorage(ExerciseAction))
				.then(this.provideDataToVasatCache.bind(this))
				.then((nill) => this.getTypedDataFromStorage(ExerciseHelp))
				.then(this.provideDataToVasatCache.bind(this))
				.then(resolve)
				.catch(reject);
		}.bind(this);
		return new Promise(resolver);
	}

	////////////////////////////////////
	private saveCompleteSessionActions(actions: ExerciseAction[]) {
		let jsonActions = actions.map((actn) => {
			return actn.toJSON();
		});

		let resolver = async function (resolve, reject) {
			this.getNextLocalID(ExerciseAction).then(
				function (res) {
					let id1 = parseInt(res.data.id);
					let flaggableIDs = [];
					jsonActions.forEach((action, index) => {
						action.l_id = id1 + index;
						//action.patient = { id: this.sUser.user.id, type: 'Patient' }; // 2023-05-31: Storage Review: Severely reducing storage size by removing users's full data per store // 2023-06-15: Removed altogether as it disrupts relationships in the CMS
						flaggableIDs.push(id1 + index);
					});
					this.saveSessionActions(jsonActions, flaggableIDs)
						.then((res) => {
							resolve(res);
						})
						.catch((err) => {
							console.log('Error during save session actions', err);
							reject(err);
						});
				}.bind(this)
			);
		}.bind(this);
		return new Promise(resolver);
	}
	saveSessionActions(action: ExerciseAction, flaggableIDs) {
		return new DataStream(this.V, this.sUser, this.sStorage)
			.setDataType(ExerciseAction)
			.useProvidedData(action)
			.saveDataByAppend(false, '', 'exerciseactions')
			.flagAsServerUpdateable(flaggableIDs, 'l_id', ExerciseAction, this.sUser.user.id)
			.returnData('saved_actions')
			.go()
			.catch((err) => console.log('Error during save session actions', err));
	}

	////////////////////////////////
	/*getExerciseHelp(id:number) {
		return new DataStream(this.V, this.sUser, this.sStorage)
		.setDataType(ExerciseHelp)
		.getData()
		.filter(i => { return i.exercise.id == id })
		.returnData('ExerciseHelp')
		.go();
	}*/

	////////////////////////////////////
	deleteActionsOlderThanOneWeek() {
		return new DataStream(this.V, this.sUser, this.sStorage)
			.setDataType(ExerciseAction)
			.getData()
			.filter((it) => it.exerciseTime > new Date().getTime() - 1000 * 60 * 60 * 24 * 7)
			.saveDataByOverwrite()
			.go()
			.catch((err) => console.log('Error during delete actions older than 1 week', err));
	}

	////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////// DATA METHODS

	////////////////////////////////////
	getRawDataFromStorage(key, raw = false) {
		return this.sStorage.get(key, raw);
	}

	////////////////////////////////////
	writeRawDataToStorage(key, data) {
		return this.sStorage.set(key, data);
	}

	////////////////////////////////////
	convertProvidedDataToType(data, type) {
		return new DataStream(this.V, this.sUser, this.sStorage)
			.setDataType(type)
			.useProvidedData(data)
			.convertDataToType(STUser)
			.returnData('converted')
			.go()
			.catch((err) => console.log('Error during convert provided data to type', err));
	}

	////////////////////////////////////
	getTypedDataFromStorage(type) {
		return new DataStream(this.V, this.sUser, this.sStorage)
			.setDataType(type)
			.getData()
			.convertDataToType(type)
			.returnData(type.name)
			.go()
			.catch((err) => console.log('Error during get typed data from storage', err));
	}

	////////////////////////////////////
	provideDataToVasatCache(data) {
		let DS = new DataStream(this.V, this.sUser, this.sStorage);
		let key = Object.keys(data.data)[0];
		this.V.registerCacheClass(DS.typeKeys[key].dataType, data.data[key], true);

		if (key == 'ExerciseLevel') {
			this.V.getCached(Exercise).forEach((it) => it.updateRelations());
		}
		return Promise.resolve();
	}

	////////////////////////////////////
	gatherVasatExerciseCategories() {
		return this.gatherVasatDataByType(ExerciseCategory);
	}
	gatherVasatExercises() {
		return this.gatherVasatDataByType(Exercise);
	}
	gatherVasatExerciseLevel() {
		return this.gatherVasatDataByType(ExerciseLevel);
	}
	gatherVasatExerciseAction() {
		return this.gatherVasatDataByType(ExerciseAction);
	}
	gatherVasatExerciseHelp() {
		return this.gatherVasatDataByType(ExerciseHelp);
	}
	gatherVasatFAQs() {
		return this.gatherVasatDataByType(FAQ);
	}

	////////////////////////////////////
	gatherVasatDataByType(type) {
		var exposedData = { value: 0 };
		var limit = 9999;
		if (type == ExerciseAction) limit = 50;
		return new DataStream(this.V, this.sUser, this.sStorage)
			.setDataType(type)
			.getData()
			.sort((a, b) => {
				if (a.dateUpdated > b.dateUpdated) return -1;
				return 1;
			})
			.exposeData((data, rData) => {
				if (data.length) {
					exposedData.value = data[0].dateUpdated;
				} else {
					exposedData.value = 0;
				}
			})
			.getVasatData({ greaterThan: { key: 'dateUpdated', value: exposedData }, limit: limit })
			.saveDataBySmartAdd()
			.returnData('vasatItems')
			.go()
			.catch((err) => {
				console.log('Error during gather vasat data by type', err);
			});
	}

	////////////////////////////////////
	gatherThisWeeksVasatExerciseAction() {
		return new DataStream(this.V, this.sUser, this.sStorage)
			.setDataType(ExerciseAction)
			.getVasatData({ greaterThan: { key: 'dateUpdated', value: { value: new Date().getTime() - 1000 * 60 * 60 * 24 * 7 } }, limit: 500 })
			.saveDataBySmartAdd()
			.returnData('vasatItems')
			.go()
			.catch((err) => console.log("Error during gather this week's vasat exercise action", err));
	}

	////////////////////////////////////
	updateMe() {
		let resolver = function (resolve, reject) {
			if (!this.sUser.userLoaded) {
				return resolve();
			}

			// Check if there are outstanding STUser pushes, as we wouldn't want to overwrite them
			this.getRawDataFromStorage('datapushlist').then((res) => {
				let userPush = res.filter((it) => {
					return it.owner == this.sUser.user.id;
				});
				if (userPush.length == 0) {
					let me = this.sUser.user.fetchObservable().subscribe(
						(res) => {
							// If this was a network issue, reject
							if (res.status == 0 || (res.error && (res.error.status == 0 || res.status == 100001))) {
								console.log("%c>>>> Failed to update 'Me', No Internet Connection", 'color:red;');
								return reject('No connection');
							} else {
								console.log("%c>>>> Updated 'Me'", 'color:green;');

								// Store non-server properties
								let guide = this.sUser.user.guide;
								let profPhoto = this.sUser.user.profilePhotoUrl;
								let prime = this.sUser.user.primeUser;
								let adherenceLastUseDate = this.sUser.user.adherenceLastUseDate;

								// Update the user with server data and re-add non-server properties
								this.sUser.user = res;
								this.sUser.user.guide = guide;
								this.sUser.user.profilePhotoUrl = profPhoto;
								this.sUser.user.primeUser = prime;
								this.sUser.user.adherenceLastUseDate = adherenceLastUseDate;
								this.updateUserProfile(this.sUser.user, false, null).then(() => this.sUser.user.updateRelations());

								// And resolve
								resolve();
							}
						},
						(err) => {
							console.log("%c>>>> Get an error while updating 'Me'", 'color:red;', err);
							if (err.message == 'Not Logged in') {
								this.logoutProfile(this.sUser.user.id);
								setTimeout(() => this.router.navigate(['/onboarding']), 1000);
								reject();
							} else {
								reject('Unable to update me just yet');
							}
						}
					);
				} else {
					reject('Unable to update me just yet');
				}
			});
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////////
	getNextLocalID(type): Promise<any> {
		return new DataStream(this.V, this.sUser, this.sStorage).setDataType(type).getNextLocalID().returnData('id').go();
	}

	////////////////////////////////////
	replaceDataByTypeAndKey(data: any, type, compareKey: string, compareVal: any) {
		return new DataStream(this.V, this.sUser, this.sStorage)
			.setDataType(type)
			.getData()
			.map((it) => {
				if (it[compareKey] == compareVal) it = data;
				return it;
			})
			.saveDataByOverwrite()
			.returnData(type.name)
			.go();
	}

	////////////////////////////////
	waitAMoment(waitTime: number) {
		// This waits for a moment before attempting the next operation - it ensures that other resources have loaded before starting the manifest check and timeout
		let resolver = function (resolve, reject) {
			setTimeout(resolve, waitTime || 2500);
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////// RESPONSE AND ERROR METHODS

	////////////////////////////////////
	handleServerError(err) {
		if (err.message.toLowerCase() == 'not logged in') {
			this.logoutProfile(this.sUser.user.id);
			setTimeout(() => {
				this.sGlobalPubSub.fireEvent('toast', [
					true,
					'The current user was logged out on a different device.  Please login again to continue using Standing Tall.',
					12500,
				]);
			}, 1000);
		}
	}
}
