///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { TimeCircleComponent, TimerMode, TimerDirection, TimerCompletionBehaviour } from '../../components/time-circle/time-circle.component';
import { repEventTypeName } from '../../st-commons/shortSchedule';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
	selector: 'app-page-debugtimers',
	templateUrl: './page-debugtimers.component.html',
	styleUrls: ['./page-debugtimers.component.scss'],
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class PageDebugTimersComponent implements OnInit, AfterViewInit {
	@ViewChild('timeCircle') timeCircle: TimeCircleComponent;
	@ViewChild('percentageCircle') percentageCircle: TimeCircleComponent;
	@ViewChild('repsCircle') repsCircle: TimeCircleComponent;

	@ViewChild('timeCircleCD') timeCircleCD: TimeCircleComponent;
	@ViewChild('percentageCircleCD') percentageCircleCD: TimeCircleComponent;
	@ViewChild('repsCircleCD') repsCircleCD: TimeCircleComponent;

	////////////////////////////////
	ngOnInit() {}

	ngAfterViewInit() {
		this.timeCircle.createTimer({
			timerMode: TimerMode.time,
			direction: TimerDirection.countup,
			totalValue: 10,
			elapsedValueMS: 3000,
			completionCallback: () => console.log('DONE Time'),
			completionBehaviour: TimerCompletionBehaviour.reset,
		});

		this.percentageCircle.createTimer({
			timerMode: TimerMode.percentage,
			direction: TimerDirection.countup,
			totalValue: 10,
			elapsedValueMS: 500,
			completionCallback: () => console.log('DONE Perc 1'),
			completionBehaviour: TimerCompletionBehaviour.resetfade,
			completionBehaviourDelay: 1000,
		});

		this.repsCircle.createTimer({
			timerMode: TimerMode.reps,
			direction: TimerDirection.countup,
			totalValue: 10,
			elapsedValueMS: 0,
			completionCallback: () => console.log('DONE Reps'),
			completionBehaviour: TimerCompletionBehaviour.reset,
		});

		////////////////////////////////////
		this.timeCircleCD.createTimer({
			timerMode: TimerMode.time,
			direction: TimerDirection.countdown,
			totalValue: 10,
			elapsedValueMS: 3000,
			completionCallback: () => console.log('DONE Time CD'),
			completionBehaviour: TimerCompletionBehaviour.reset,
		});

		this.percentageCircleCD.createTimer({
			timerMode: TimerMode.percentage,
			direction: TimerDirection.countdown,
			totalValue: 10,
			completionCallback: () => console.log('DONE Perc CD'),
			completionBehaviour: TimerCompletionBehaviour.reset,
		});

		this.repsCircleCD.createTimer({
			timerMode: TimerMode.reps,
			direction: TimerDirection.countdown,
			totalValue: 10,
			completionCallback: () => console.log('DONE Reps CD'),
			completionBehaviour: TimerCompletionBehaviour.reset,
		});
	}

	////////////////////////////////////
	prep() {
		console.log('PREP');
	}

	////////////////////////////////////
	start() {
		this.timeCircle.setRunning();
		this.percentageCircle.setRunning();
		this.repsCircle.setRunning();

		this.timeCircleCD.setRunning();
		this.percentageCircleCD.setRunning();
		this.repsCircleCD.setRunning();
	}

	incrementReps() {
		const event = new Event(repEventTypeName);
		window.dispatchEvent(event);
		console.log(event);
	}
}
