declare var require;

export const environment = {
	debug: true,
	production: true,
	save_settings_as_cookie: false,
	save_settings_as_localStorage: false,
	save_settings_as_cordovaFile: false,
	save_settings_as_dbEntry: false,
	save_settings_as_inMem: false,
	appVersion: require('../../package.json').version,

	apiHost: 'https://standing-tall-stage.theprojectfactory.com',
	clientId: 'st_customer',
	clientSecret: 'H50LRDDYUGX0QJO',
};
