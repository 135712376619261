///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS
import { VgCoreModule } from 'videogular2/core';
import { VgControlsModule } from 'videogular2/controls';
import { VgOverlayPlayModule } from 'videogular2/overlay-play';
import { VgBufferingModule } from 'videogular2/buffering';

//////////////////////////////// ANGULAR CORE
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http'; // HTTP used in conjunction with HttpClient due to legacy reasons and to not shake the boat unnecessarily
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//////////////////////////////// VASAT & IONIC
import { Vasat, VasatConfig } from 'vasat';
import { VasatObjectService } from './services/vasatobject.service';
import { Push } from '@ionic-native/push';
import { CustomHttpInterceptorService } from '../app/custominterceptor';

//////////////////////////////// APP AND SERVICES
import { AppComponent } from './app.component';
import { AppRoutingModule } from './pages/app-routing.module';
import { DeviceService } from './services/device.service';
import { DataService } from './services/data.service';
import { StorageService } from './services/storage.service';
import { CommandForceAlphaService } from './services/commandforcealpha.service';
import { GlobalPubSub } from './services/global-pub-sub.service';
import { NotificationsService } from './services/notifications.service';
import { UserService } from './services/user.service';
import { PhotoHandlingService } from './services/photohandling.service';
import { FileDownloadService } from './services/filedownload.service';
import { ContentManagerService } from './services/contentmanager.service';
import { AbsExerciseService } from './st-commons/service';
import { ExerciseService } from './services/exercise.service';
import { NavigationService } from './services/navigation.service';

//////////////////////////////// PAGES AND SUBPAGES
import { PageSplashComponent } from './pages/page-splash/page-splash.component';
import { PageLoginComponent } from './pages/page-login/page-login.component';
import { PageHomeComponent } from './pages/page-home/page-home.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PageOnboardingComponent } from './pages/page-onboarding/page-onboarding.component';
import { PageOnboardedComponent } from './pages/page-onboarded/page-onboarded.component';
import { PageOnboarded2Component } from './pages/page-onboarded2/page-onboarded2.component';
import { PageOnboarded3TutorialComponent } from './pages/page-onboarded3-tutorial/page-onboarded3-tutorial.component';
import { PageSetupprofileComponent } from './pages/page-setupprofile/page-setupprofile.component';
import { PageExercise1homeComponent } from './pages/page-exercise1-home/page-exercise1home.component';
import { PageExercise1DigestComponent } from './pages/page-exercise1-digest/page-exercise1-digest.component';
import { PageExercise3mainComponent } from './pages/page-exercise3-main/page-exercise3main.component';
import { PageExercise4SessionexpiredComponent } from './pages/page-exercise4-sessionexpired/page-exercise4-sessionexpired.component';
import { PageGoalsAndJournal1homeComponent } from './pages/page-goalsandjournal1home/page-goalsandjournal1home.component';
import { PageCalendar1homeComponent } from './pages/page-calendar1home/page-calendar1home.component';
import { PageSettingsComponent } from './pages/page-settings/page-settings.component';
import { PageSettingsprofileComponent } from './pages/page-settingsprofile/page-settingsprofile.component';
import { PageSettingsexercisesComponent } from './pages/page-settingsexercises/page-settingsexercises.component';
import { PageProgress1homeComponent } from './pages/page-progress1home/page-progress1home.component';
import { PageProgress2graphComponent } from './pages/page-progress2graph/page-progress2graph.component';
import { PageProgress3motivatemeComponent } from './pages/page-progress3motivateme/page-progress3motivateme.component';
import { SubpageSecuritychecklistComponent } from './pages/subpage-securitychecklist/subpage-securitychecklist.component';
import { PageTermsConditionsComponent } from './pages/page-termsconditions/page-termsconditions.component';
import { PagePrivacyPolicyComponent } from './pages/page-privacypolicy/page-privacypolicy.component';
import { PageExercise5BalancehomeComponent } from './pages/page-exercise5-balancehome/page-exercise5-balancehome.component';
import { PageExercise6SessioncompletedComponent } from './pages/page-exercise6-sessioncompleted/page-exercise6-sessioncompleted.component';
import { PageExercise7TrialhomeComponent } from './pages/page-exercise7-trialhome/page-exercise7-trialhome.component';
import { PageExercise8TrialSessioncompletedComponent } from './pages/page-exercise8-trialsessioncompleted/page-exercise8-trialsessioncompleted.component';
import { PageDebugmodifiersComponent } from './pages/page-debugmodifiers/page-debugmodifiers.component';
import { PageDebugExSessionsComponent } from './pages/page-debugsessions/page-debugsessions.component';
import { PageDebugExSessionsBAComponent } from './pages/page-debugsessionsba/page-debugsessionsba.component';
import { PageDebugTimersComponent } from './pages/page-debugtimers/page-debugtimers.component';
import { PageDebugExGeneratorComponent } from './pages/page-debugexgenerator/page-debugexgenerator.component';
import { PageContentproblemComponent } from './pages/page-contentproblem/page-contentproblem.component';
import { PageDebuganimationsComponent } from './pages/page-debuganimations/page-debuganimations.component';
import { PageDebugTutorialAssetsComponent } from './pages/page-debugtutorialassets/page-debugtutorialassets.component';
import { PageAdherenceComponent } from './pages/page-adherence/page-adherence.component';
import { PageHelpComponent } from './pages/page-help/page-help.component';
import { PageFaqsComponent } from './pages/page-faqs/page-faqs.component';
import { SubpageTermsandconditionsComponent } from './pages/subpage-termsandconditions/subpage-termsandconditions.component';
import { SubpagePrivacypolicyComponent } from './pages/subpage-privacypolicy/subpage-privacypolicy.component';

//////////////////////////////// COMPONENTS
import { TimeCircleComponent } from './components/time-circle/time-circle.component';
import { ControlToggleComponent } from './components/control-toggle/control-toggle.component';
import { ProgressNubComponent } from './components/progress-nub/progress-nub.component';
import { FormBluewaveComponent } from './components/form-bluewave/form-bluewave.component';
import { FormBluewaveflexorComponent } from './components/form-bluewaveflexor/form-bluewaveflexor.component';
import { FormBluewavenonflexorComponent } from './components/form-bluewavenonflexor/form-bluewavenonflexor.component';
import { GuideCircleheadComponent } from './components/guide-circlehead/guide-circlehead.component';
import { GuideFigureComponent } from './components/guide-figure/guide-figure.component';
import { ValidfieldComponent } from './components/validfield/validfield.component';
import { SelectDatedayComponent } from './components/select-dateday/select-dateday.component';
import { SelectDatemonthComponent } from './components/select-datemonth/select-datemonth.component';
import { SelectDateyearComponent } from './components/select-dateyear/select-dateyear.component';
import { ConfirmbuttonComponent } from './components/confirmbutton/confirmbutton.component';
import { GraphComponent } from './st-commons/graph/graph.component';
import { ToastComponent } from './components/toast/toast.component';
import { ToastConfirmComponent } from './components/toast-confirm/toast-confirm.component';
import { StAnimationsComponent } from './st-commons/st-animations/st-animations.component';
import { VgPlayerHandlerComponent } from './components/vg-player-handler/vg-player-handler.component';
import { SavingIndicatorComponent } from './components/saving-indicator/saving-indicator.component';

import { environment } from '../environments/environment';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// SETUP MODULE
@NgModule({
	declarations: [
		AppComponent,
		PageSplashComponent,
		PageHomeComponent,
		PageNotFoundComponent,
		TimeCircleComponent,
		PageOnboardingComponent,
		PageSetupprofileComponent,
		PageExercise1homeComponent,
		PageGoalsAndJournal1homeComponent,
		PageCalendar1homeComponent,
		PageSettingsComponent,
		ControlToggleComponent,
		PageSettingsprofileComponent,
		PageSettingsexercisesComponent,
		ProgressNubComponent,
		SubpageSecuritychecklistComponent,
		PageExercise3mainComponent,
		FormBluewaveComponent,
		FormBluewaveflexorComponent,
		FormBluewavenonflexorComponent,
		PageProgress1homeComponent,
		PageProgress2graphComponent,
		PageTermsConditionsComponent,
		PagePrivacyPolicyComponent,
		GuideCircleheadComponent,
		PageLoginComponent,
		ValidfieldComponent,
		PageOnboardedComponent,
		SelectDatedayComponent,
		SelectDatemonthComponent,
		SelectDateyearComponent,
		ConfirmbuttonComponent,
		GraphComponent,
		PageExercise1DigestComponent,
		GuideFigureComponent,
		ToastComponent,
		ToastConfirmComponent,
		PageProgress3motivatemeComponent,
		PageExercise4SessionexpiredComponent,
		PageExercise5BalancehomeComponent,
		PageDebugmodifiersComponent,
		PageDebugExSessionsComponent,
		PageDebugExSessionsBAComponent,
		PageDebugTimersComponent,
		PageDebugExGeneratorComponent,
		PageContentproblemComponent,
		PageDebuganimationsComponent,
		PageDebugTutorialAssetsComponent,
		StAnimationsComponent,
		PageAdherenceComponent,
		PageOnboarded2Component,
		PageOnboarded3TutorialComponent,
		PageHelpComponent,
		PageFaqsComponent,
		SubpageTermsandconditionsComponent,
		SubpagePrivacypolicyComponent,
		VgPlayerHandlerComponent,
		PageExercise6SessioncompletedComponent,
		PageExercise7TrialhomeComponent,
		PageExercise8TrialSessioncompletedComponent,
		SavingIndicatorComponent,
	],
	imports: [
		HttpModule,
		HttpClientModule,
		AppRoutingModule,
		BrowserModule,
		FormsModule,
		BrowserAnimationsModule,
		VgCoreModule,
		VgControlsModule,
		VgOverlayPlayModule,
		VgBufferingModule,
	],
	providers: [
		DeviceService,
		DataService,
		StorageService,
		CommandForceAlphaService,
		GlobalPubSub,
		NotificationsService,
		UserService,
		PhotoHandlingService,
		FileDownloadService,
		ContentManagerService,
		NavigationService,
		{ provide: AbsExerciseService, useClass: ExerciseService },
		Push,
		{
			provide: VasatConfig,
			useValue: {
				host: environment.apiHost + '/vasat',
				clientId: environment.clientId,
				clientSecret: environment.clientSecret,
				useCookies: false,
			},
		},
		Vasat,
		VasatObjectService,
		{ provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptorService, multi: true },
	],
	bootstrap: [AppComponent],
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT MODULE
export class AppModule {
	constructor() {
		if (environment.debug) document.body.classList.add('debugMode');
	}
}
