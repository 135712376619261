///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
////////// MODULES
import { Component, OnInit, ElementRef, HostListener, ContentChild }	from '@angular/core';


///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
  selector:		'validfield',
  templateUrl:	'./validfield.component.html',
  styleUrls:	['./validfield.component.scss']
})


///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class ValidfieldComponent implements OnInit {

	////////////////////////////////
	constructor(public el:ElementRef) {}
	ngOnInit() {
		this.el.nativeElement.addEventListener('keyup', this.validate.bind(this));
	}

	////////////////////////////////
	validate() {
		let parent = this.el.nativeElement;
		let field = this.el.nativeElement.querySelector('input, select, textarea');
		if (parent.hasAttribute('data-readytovalidate') && parent.getAttribute('data-readytovalidate') == "false") return true;
		
		this.el.nativeElement.classList.remove('validatrix-invalid');
		this.el.nativeElement.classList.remove('validatrix-invalid-required');
		this.el.nativeElement.classList.remove('validatrix-invalid-minlength');
		this.el.nativeElement.classList.remove('validatrix-invalid-maxlength');
		this.el.nativeElement.classList.remove('validatrix-invalid-needsnumber');
		this.el.nativeElement.classList.remove('validatrix-invalid-needscapital');
		this.el.nativeElement.classList.remove('validatrix-invalid-needstomatch');
		
		if (field.hasAttribute('required') && field.value == "") {
			this.el.nativeElement.classList.add('validatrix-invalid');
			this.el.nativeElement.classList.add('validatrix-invalid-required');
			return false;
		}
		if (field.hasAttribute('minlength') && field.value.length < parseInt(field.getAttribute('minlength'))) {
			this.el.nativeElement.classList.add('validatrix-invalid');
			this.el.nativeElement.classList.add('validatrix-invalid-minlength');
			return false;
		}
		if (field.hasAttribute('data-needsnumber') && field.value.search("([0-9])") == -1) {
			this.el.nativeElement.classList.add('validatrix-invalid');
			this.el.nativeElement.classList.add('validatrix-invalid-needsnumber');
			return false;
		}
		if (field.hasAttribute('data-needscapital') && field.value.search("([A-Z])") == -1) {
			this.el.nativeElement.classList.add('validatrix-invalid');
			this.el.nativeElement.classList.add('validatrix-invalid-needscapital');
			return false;
		}
		if (field.hasAttribute('data-needstomatchfieldwithid')) {
			let thisVal = field.value;
			let thatVal = (document.querySelector("#"+field.getAttribute('data-needstomatchfieldwithid')) as HTMLInputElement).value;
			if (thisVal != thatVal) {
				this.el.nativeElement.classList.add('validatrix-invalid');
				this.el.nativeElement.classList.add('validatrix-invalid-needstomatch');
				return false;
			}
		}

		// If all good, let's return true
		return true;
	}
}
