(function (global, factory) {
	typeof exports === 'object' && typeof module !== 'undefined' ? factory(exports, require('@angular/core'), require('@angular/common/http'), require('rxjs'), require('rxjs/operators')) :
	typeof define === 'function' && define.amd ? define(['exports', '@angular/core', '@angular/common/http', 'rxjs', 'rxjs/operators'], factory) :
	(factory((global.vasat = {}),global.core,global.http,global.rxjs,global.operators));
}(this, (function (exports,core,http,rxjs,operators) { 'use strict';

var __extends = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (undefined && undefined.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * Emulates the backbone style listenTo type event manager
 * @record
 */

/**
 * @record
 */

/**
 * @record
 */

var VasatError = /** @class */ (function () {
    function VasatError(resp, e) {
        this.status = resp.status;
        this.message = resp.message;
        this.payload = resp.payload;
        this.error = e;
    }
    return VasatError;
}());
var VasatStorage = /** @class */ (function () {
    function VasatStorage() {
    }
    /**
     * @param {?} key
     * @param {?} v
     * @return {?}
     */
    VasatStorage.prototype.set = /**
     * @param {?} key
     * @param {?} v
     * @return {?}
     */
    function (key, v) {
        //localStorage[key] = JSON.stringify(v)
        localStorage.setItem(key, JSON.stringify(v));
    };
    /**
     * @param {?} key
     * @return {?}
     */
    VasatStorage.prototype.get = /**
     * @param {?} key
     * @return {?}
     */
    function (key) {
        //return localStorage[key]?JSON.parse(localStorage[key]):null
        return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : null;
    };
    /**
     * @return {?}
     */
    VasatStorage.prototype.getWindow = /**
     * @return {?}
     */
    function () {
        return window;
    };
    /**
     * @param {?} key
     * @return {?}
     */
    VasatStorage.prototype.del = /**
     * @param {?} key
     * @return {?}
     */
    function (key) {
        //delete localStorage[key]
        localStorage.removeItem(key);
    };
    /**
     * @return {?}
     */
    VasatStorage.prototype.getDeviceName = /**
     * @return {?}
     */
    function () {
        /** @type {?} */
        var bo = this.browserV();
        return bo.browserName + " - " + bo.fullVersion;
    };
    /**
     * @return {?}
     */
    VasatStorage.prototype.getDeviceType = /**
     * @return {?}
     */
    function () {
        /** @type {?} */
        var bo = this.browserV();
        return {
            deviceModel: bo.browserName + ' ' + bo.majorVersion,
            deviceMake: 'Browser',
            deviceOSName: navigator.platform,
            deviceOSVersion: navigator['oscpu'] || '?'
        };
    };
    /**
     * @return {?}
     */
    VasatStorage.prototype.getDeviceUid = /**
     * @return {?}
     */
    function () {
        /** @type {?} */
        var nav = this.getWindow().navigator;
        /** @type {?} */
        var screen = this.getWindow().screen;
        /** @type {?} */
        var guid = '' + nav.mimeTypes.length;
        guid += nav.userAgent.replace(/\D+/g, '');
        guid += nav.plugins.length;
        guid += ('' + screen.height) || '';
        guid += ('' + screen.width) || '';
        guid += ('' + screen.pixelDepth) || '';
        guid += ('' + Math.random());
        return guid;
    };
    /**
     * @return {?}
     */
    VasatStorage.prototype.browserV = /**
     * @return {?}
     */
    function () {
        /** @type {?} */
        var nVer = navigator.appVersion;
        /** @type {?} */
        var nAgt = navigator.userAgent;
        /** @type {?} */
        var browserName = navigator.appName;
        /** @type {?} */
        var fullVersion = '' + parseFloat(navigator.appVersion);
        /** @type {?} */
        var majorVersion = parseInt(navigator.appVersion, 10);
        /** @type {?} */
        var nameOffset;
        /** @type {?} */
        var verOffset;
        /** @type {?} */
        var ix;
        // In Opera, the true version is after "Opera" or after "Version"
        if ((verOffset = nAgt.indexOf("Opera")) != -1) {
            browserName = "Opera";
            fullVersion = nAgt.substring(verOffset + 6);
            if ((verOffset = nAgt.indexOf("Version")) != -1)
                fullVersion = nAgt.substring(verOffset + 8);
        }
        // In MSIE, the true version is after "MSIE" in userAgent
        else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
            browserName = "Microsoft Internet Explorer";
            fullVersion = nAgt.substring(verOffset + 5);
        }
        // In Chrome, the true version is after "Chrome" 
        else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
            browserName = "Chrome";
            fullVersion = nAgt.substring(verOffset + 7);
        }
        // In Safari, the true version is after "Safari" or after "Version" 
        else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
            browserName = "Safari";
            fullVersion = nAgt.substring(verOffset + 7);
            if ((verOffset = nAgt.indexOf("Version")) != -1)
                fullVersion = nAgt.substring(verOffset + 8);
        }
        // In Firefox, the true version is after "Firefox" 
        else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
            browserName = "Firefox";
            fullVersion = nAgt.substring(verOffset + 8);
        }
        // In most other browsers, "name/version" is at the end of userAgent 
        else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
            (verOffset = nAgt.lastIndexOf('/'))) {
            browserName = nAgt.substring(nameOffset, verOffset);
            fullVersion = nAgt.substring(verOffset + 1);
            if (browserName.toLowerCase() == browserName.toUpperCase()) {
                browserName = navigator.appName;
            }
        }
        // trim the fullVersion string at semicolon/space if present
        if ((ix = fullVersion.indexOf(";")) != -1)
            fullVersion = fullVersion.substring(0, ix);
        if ((ix = fullVersion.indexOf(" ")) != -1)
            fullVersion = fullVersion.substring(0, ix);
        majorVersion = parseInt('' + fullVersion, 10);
        if (isNaN(majorVersion)) {
            fullVersion = '' + parseFloat(navigator.appVersion);
            majorVersion = parseInt(navigator.appVersion, 10);
        }
        return {
            browserName: browserName,
            fullVersion: fullVersion,
            majorVersion: majorVersion
        };
    };
    return VasatStorage;
}());
var VasatConfig = /** @class */ (function () {
    function VasatConfig(host, clientId, clientSecret, useCookies) {
        this.host = host;
        this.clientId = clientId;
        this.clientSecret = clientSecret;
        this.useCookies = useCookies;
    }
    return VasatConfig;
}());
/**
 * @abstract
 */
var Triggerable = /** @class */ (function () {
    function Triggerable() {
        this._eventBus = new rxjs.Subject();
        this._listenTos = [];
    }
    /**
     * @param {?} key
     * @param {?} fn
     * @return {?}
     */
    Triggerable.prototype.on = /**
     * @param {?} key
     * @param {?} fn
     * @return {?}
     */
    function (key, fn) {
        return this._eventBus
            .asObservable()
            .pipe(operators.filter(function (event) { return event.key === key; }), operators.map(function (e) { return e.data; }))
            .subscribe(fn);
    };
    /**
    
    listen to another triggerable for the specified event, when fired run the following function
    
    */
    /**
     * listen to another triggerable for the specified event, when fired run the following function
     * @param {?} obj
     * @param {?} key
     * @param {?} fn
     * @return {?}
     */
    Triggerable.prototype.listenTo = /**
     * listen to another triggerable for the specified event, when fired run the following function
     * @param {?} obj
     * @param {?} key
     * @param {?} fn
     * @return {?}
     */
    function (obj, key, fn) {
        /** @type {?} */
        var oo = obj._eventBus
            .asObservable()
            .pipe(operators.filter(function (event) { return event.key === key; }), operators.map(function (e) { return e.data; }))
            .subscribe(fn);
        this._listenTos.push(oo);
        return oo;
    };
    /**
    
    Fire event for everyone who is listening
    
    */
    /**
     * Fire event for everyone who is listening
     * @param {?} e
     * @param {?} obj
     * @return {?}
     */
    Triggerable.prototype.trigger = /**
     * Fire event for everyone who is listening
     * @param {?} e
     * @param {?} obj
     * @return {?}
     */
    function (e, obj) {
        //console.log("event triggered " + e + " for " + (typeof this))
        this._eventBus.next({ key: e, data: obj });
    };
    // when this class is extended by an ngComponent will assure cleanup
    // when this class is extended by an ngComponent will assure cleanup
    /**
     * @return {?}
     */
    Triggerable.prototype.ngOnDestroy = 
    // when this class is extended by an ngComponent will assure cleanup
    /**
     * @return {?}
     */
    function () {
        this._listenTos.forEach(function (o) { return o.unsubscribe(); });
    };
    /**
     * @return {?}
     */
    Triggerable.prototype.clearAllListeners = /**
     * @return {?}
     */
    function () {
        this._listenTos.forEach(function (o) { return o.unsubscribe(); });
        this._listenTos = [];
    };
    return Triggerable;
}());
var Vasat = /** @class */ (function (_super) {
    __extends(Vasat, _super);
    function Vasat(http$$1, cfg, storez) {
        var _this = _super.call(this) || this;
        _this.http = http$$1;
        _this.cfg = cfg;
        _this.pathPrefix = "/api";
        _this.LOGOUT_OK = 'vasat:logout_ok';
        _this.LOGOUT_ERROR = 'vasat:logout_error';
        _this.LOGIN_OK = 'vasat:login_ok';
        _this.LOGIN_ERROR = 'vasat:login_error';
        _this.TOKEN_OK = 'vasat:token_ok';
        _this.TOKEN_ERROR = 'vasat:token_error';
        _this.SYNC_ERROR = 'vasat:sync_error';
        _this.SYNC_OK = 'vasat:sync_ok';
        _this.SYNC_FINISHED = 'vasat:sy6nc_finished';
        _this.SAVE_OK = 'vasat:save_ok';
        _this.FETCH_OK = 'vasat:fetch_ok';
        _this.API_START = 'vasat:api_started';
        _this.API_FINISH = 'vasat:api_finished';
        _this.GLOBAL_ERROR = 'vasat:global_error';
        _this._modelCache = {};
        _this._cacheClass = {};
        _this._modelCacheHash = {};
        _this.store = storez || new VasatStorage();
        _this._sessionSubject = new rxjs.Subject();
        _this._sessionObservable = _this._sessionSubject.asObservable().pipe(operators.map(function (a) {
            if (a instanceof VasatError) {
                throw a;
            }
            return a;
        }));
        /** @type {?} */
        var s = _this.localSession();
        /** @type {?} */
        var w = _this.store.getWindow();
        if (w['process'] && w['process'].env.NODE_ENV != 'production') {
            /** @type {?} */
            var session = "\n\nsession\t\t= Nil\n";
            if (s) {
                /** @type {?} */
                var expr = "Nil";
                /** @type {?} */
                var exp = s.expires_by;
                if (exp) {
                    exp = (s.expires_by - new Date().getTime()) / 6000;
                    /** @type {?} */
                    if (exp > 100) {
                        exp = exp / 60;
                        
                    }
                }
                session = '\n\nsession \t= ' + s.access_token +
                    '\nexpires \t= ' + expr +
                    '\nuser \t\t= ' + (s.user ? s.user.name + ' [' + s.user.id + ']' : 'Nil') + '\n';
            }
            console.log('#### Vasat Instance ####\n' +
                '\nhost \t\t= ' + _this.cfg.host +
                '\nclient \t\t= ' + _this.cfg.clientId + session);
        }
        return _this;
    }
    /**
     * @return {?}
     */
    Vasat.prototype.clientKey = /**
     * @return {?}
     */
    function () {
        return 'v_' + this.cfg.clientId + "_" + this.cfg.host.replace(/[\/\:\.\-]+/g, '_');
    };
    /**
     * @template T
     * @param {?} obj
     * @param {?} items
     * @param {?=} overrideExisting
     * @return {?}
     */
    Vasat.prototype.registerCacheClass = /**
     * @template T
     * @param {?} obj
     * @param {?} items
     * @param {?=} overrideExisting
     * @return {?}
     */
    function (obj, items, overrideExisting) {
        /** @type {?} */
        var key = null;
        if ((typeof obj) == 'string')
            key = (/** @type {?} */ (obj));
        else {
            key = this.classNameForType((/** @type {?} */ (obj)));
        }
        if (!this._cacheClass[key] && (typeof obj) != 'string')
            this.registerClass((/** @type {?} */ (obj)));
        if (!this._modelCache[key] || overrideExisting) {
            this._modelCache[key] = [];
            this._modelCacheHash[key] = {};
        }
        /** @type {?} */
        var curCache = this._modelCache[key];
        /** @type {?} */
        var curCacheHash = this._modelCacheHash[key];
        items.forEach(function (i) {
            // just blindly push
            if (overrideExisting) {
                curCache.push(i);
                if (i.id)
                    curCacheHash['_' + i.id] = i;
            }
            else {
                if (i.id && curCacheHash['_' + i.id])
                    curCacheHash['_' + i.id].set(i.toJSON());
                else {
                    curCache.push(i);
                    if (i.id)
                        curCacheHash['_' + i.id] = i;
                }
            }
        });
    };
    /**
     * @param {?} obj
     * @return {?}
     */
    Vasat.prototype.classNameForType = /**
     * @param {?} obj
     * @return {?}
     */
    function (obj) {
        /** @type {?} */
        var t;
        if (obj)
            t = new obj(this).className();
        return t;
    };
    /**
     * @template T
     * @param {?} obj
     * @return {?}
     */
    Vasat.prototype.registerClass = /**
     * @template T
     * @param {?} obj
     * @return {?}
     */
    function (obj) {
        /** @type {?} */
        var key = this.classNameForType(obj);
        if (!this._cacheClass[key])
            this._cacheClass[key] = (/** @type {?} */ (obj));
    };
    /**
     * @template T
     * @param {?} obj
     * @param {?} id
     * @return {?}
     */
    Vasat.prototype.findCached = /**
     * @template T
     * @param {?} obj
     * @param {?} id
     * @return {?}
     */
    function (obj, id) {
        /** @type {?} */
        var key = (typeof obj) == 'string' ? (/** @type {?} */ (obj)) : this.classNameForType((/** @type {?} */ (obj)));
        if (this._modelCache[key] && typeof id == 'number') {
            return (/** @type {?} */ (this._modelCacheHash[key]['_' + id]));
        }
        else if (this._modelCache[key] && typeof id == 'function') {
            return (/** @type {?} */ (this._modelCache[key].find(function (o) { return id((/** @type {?} */ (o))); })));
        }
        return null;
    };
    /**
     * @template T
     * @param {?} obj
     * @return {?}
     */
    Vasat.prototype.getCached = /**
     * @template T
     * @param {?} obj
     * @return {?}
     */
    function (obj) {
        /** @type {?} */
        var key = (typeof obj) == 'string' ? (/** @type {?} */ (obj)) : this.classNameForType((/** @type {?} */ (obj)));
        return (/** @type {?} */ (this._modelCache[key]));
    };
    /**
     * @template T
     * @param {?} obj
     * @param {?} id
     * @return {?}
     */
    Vasat.prototype.filterCached = /**
     * @template T
     * @param {?} obj
     * @param {?} id
     * @return {?}
     */
    function (obj, id) {
        /** @type {?} */
        var key = (typeof obj) == 'string' ? (/** @type {?} */ (obj)) : this.classNameForType((/** @type {?} */ (obj)));
        if (this._modelCache[key] && typeof id == 'number')
            if (this._modelCacheHash[key]['_' + id])
                return (/** @type {?} */ ([this._modelCacheHash[key]['_' + id]]));
            else
                return [];
        else if (this._modelCache[key] && typeof id == 'function')
            return (/** @type {?} */ (this._modelCache[key].filter(function (o) { return id((/** @type {?} */ (o))); })));
        return null;
    };
    /**
     * @param {?} key
     * @return {?}
     */
    Vasat.prototype.registeredClassForString = /**
     * @param {?} key
     * @return {?}
     */
    function (key) {
        return this._cacheClass[key];
    };
    /**
     * @param {?=} fire
     * @return {?}
     */
    Vasat.prototype.sessionObservable = /**
     * @param {?=} fire
     * @return {?}
     */
    function (fire) {
        var _this = this;
        if (fire === void 0) { fire = false; }
        if (fire) {
            setTimeout(function () { return _this._sessionSubject.next(_this.localSession()); }, 10);
        }
        return this._sessionObservable;
    };
    /**
     * @return {?}
     */
    Vasat.prototype.onReady = /**
     * @return {?}
     */
    function () {
        /** @type {?} */
        var ls = this.localSession();
        if (ls) {
            console.log('existsing');
            return rxjs.of(ls);
        }
        else if (this._loadingSession) {
            console.log('waitload');
            return this._loadingSession;
        }
        else {
            console.log('startload');
            return this.loginWithClientCredentialsObservable();
        }
    };
    /**
     * @return {?}
     */
    Vasat.prototype.localSession = /**
     * @return {?}
     */
    function () {
        /** @type {?} */
        var key = this.clientKey();
        /** @type {?} */
        var sess = this.store.get(key);
        if (!sess || (sess['expires_by'] && sess['expires_by'] < new Date().getTime())) {
            this.token = null;
            this.store.del(key);
            this.trigger(this.LOGOUT_OK, "timeout");
            return null;
        }
        else {
            this.token = sess['access_token'];
            this.headers = new http.HttpHeaders({ 'Authorization': 'bearer ' + sess['access_token'] });
        }
        //console.log(sess)
        if (sess['user'])
            sess['user'] = new User(this, sess['user']);
        if (sess['device'])
            sess['device'] = new Device(this, sess['device']);
        return sess;
    };
    /**
     * @return {?}
     */
    Vasat.prototype.invalidateSession = /**
     * @return {?}
     */
    function () {
        /** @type {?} */
        var key = this.clientKey();
        this._sessionSubject.next(null);
        this.store.del(key);
        this.invalidateCache();
    };
    /**
     * @return {?}
     */
    Vasat.prototype.invalidateCache = /**
     * @return {?}
     */
    function () {
        this._modelCache = {};
        this._cacheClass = {};
        this._modelCacheHash = {};
    };
    /**
     * @return {?}
     */
    Vasat.prototype.authToken = /**
     * @return {?}
     */
    function () {
        /** @type {?} */
        var key = this.clientKey();
        /** @type {?} */
        var sess = this.store.get(key);
        if (!sess || (sess['expires_by'] && sess['expires_by'] < new Date().getTime())) {
            return null;
        }
        else {
            return sess['access_token'];
        }
    };
    /**
     * @param {?} obj
     * @return {?}
     */
    Vasat.prototype.saveLocalSession = /**
     * @param {?} obj
     * @return {?}
     */
    function (obj) {
        this.token = obj['access_token'];
        /** @type {?} */
        var ut = typeof obj['user'];
        /** @type {?} */
        var dt = typeof obj['device'];
        if (ut == 'User')
            obj['user'] = obj['user'].toJSON();
        if (dt == 'Device')
            obj['device'] = obj['device'].toJSON();
        this.store.set(this.clientKey(), obj);
        this.localSession();
    };
    /**
     * @return {?}
     */
    Vasat.prototype.hasSession = /**
     * @return {?}
     */
    function () {
        return !!this.localSession();
    };
    /**
     * @param {?} user
     * @param {?} pass
     * @param {?=} other
     * @return {?}
     */
    Vasat.prototype.signupWithObservable = /**
     * @param {?} user
     * @param {?} pass
     * @param {?=} other
     * @return {?}
     */
    function (user, pass, other) {
        var _this = this;
        /** @type {?} */
        var obj = {
            client_id: this.cfg.clientId,
            grant_type: 'password',
            username: user,
            password: pass
        };
        if (other)
            Object.keys(other).forEach(function (k) { return obj[k] = other[k]; });
        return this.post('/access/signup', obj, { withCredentials: true }).pipe(operators.map(function (r) {
            /** @type {?} */
            var j = r;
            _this.processResp(j);
        }), operators.flatMap(function (res) {
            return new Device(_this, {}).saveObservable();
        }), operators.map(function (dev) {
            /** @type {?} */
            var ls = _this.localSession();
            ls.device = dev;
            _this.saveLocalSession(ls);
            return dev;
        }), operators.flatMap(function (res) {
            return new MeUser(_this, { id: 'me' }).fetchObservable();
        }), operators.map(function (user) {
            /** @type {?} */
            var ls = _this.localSession();
            ls.user = user;
            _this.saveLocalSession(ls);
            _this._sessionSubject.next(ls);
            return user;
        }));
    };
    /**
     * @param {?} user
     * @param {?} pass
     * @param {?=} other
     * @return {?}
     */
    Vasat.prototype.signup = /**
     * @param {?} user
     * @param {?} pass
     * @param {?=} other
     * @return {?}
     */
    function (user, pass, other) {
        var _this = this;
        return this.signupWithObservable(user, pass, other).subscribe(function (r) { return _this.trigger(_this.LOGIN_OK, r); }, function (r) { return _this.trigger(_this.LOGIN_ERROR, r); });
    };
    /**
     * @param {?} user
     * @param {?} pass
     * @return {?}
     */
    Vasat.prototype.loginWithUsernameObservable = /**
     * @param {?} user
     * @param {?} pass
     * @return {?}
     */
    function (user, pass) {
        var _this = this;
        return this._loadingSession = this.post(this.cfg.useCookies ? '/access/login' : '/access/token', { client_id: this.cfg.clientId, grant_type: 'password', username: user, password: pass }, { withCredentials: true }).pipe(operators.map(function (r) {
            _this.processResp(r);
        }), operators.flatMap(function (res) {
            return new Device(_this, {}).saveObservable();
        }), operators.map(function (dev) {
            /** @type {?} */
            var ls = _this.localSession();
            ls.device = dev;
            _this.saveLocalSession(ls);
            return dev;
        }), operators.flatMap(function (res) {
            return new MeUser(_this, { id: 'me' }).fetchObservable();
        }), operators.map(function (user) {
            /** @type {?} */
            var ls = _this.localSession();
            ls.user = user;
            _this.saveLocalSession(ls);
            _this._sessionSubject.next(ls);
            _this._loadingSession = null;
            return user;
        }), operators.share());
    };
    /**
     * @param {?} user
     * @param {?} pass
     * @return {?}
     */
    Vasat.prototype.loginWithUsername = /**
     * @param {?} user
     * @param {?} pass
     * @return {?}
     */
    function (user, pass) {
        var _this = this;
        return this.loginWithUsernameObservable(user, pass).subscribe(function (r) { return _this.trigger(_this.LOGIN_OK, r); }, function (r) { return _this.trigger(_this.LOGIN_ERROR, r); });
    };
    /**
     * @return {?}
     */
    Vasat.prototype.loginWithClientCredentialsObservable = /**
     * @return {?}
     */
    function () {
        var _this = this;
        /** @type {?} */
        var curSess = this.localSession();
        if (curSess) {
            return rxjs.of(curSess).pipe(operators.map(function (o) {
                _this._sessionSubject.next(o);
                return o;
            }));
        }
        if (!this._loadingSession) {
            this._loadingSession = this.post(this.cfg.useCookies ? '/access/login' : '/access/token', { client_id: this.cfg.clientId, grant_type: 'client_credentials', client_secret: this.cfg.clientSecret || '' }).pipe(operators.map(function (r) {
                return _this.processResp(r);
            }), operators.flatMap(function (res) {
                return new Device(_this, {}).saveObservable();
            }), operators.map(function (dev) {
                /** @type {?} */
                var ls = _this.localSession();
                ls.device = dev;
                _this.saveLocalSession(ls);
                _this._sessionSubject.next(ls);
                _this._loadingSession = null;
                return ls;
            }), operators.share());
        }
        return this._loadingSession;
    };
    /**
     * @return {?}
     */
    Vasat.prototype.loginWithClientCredentials = /**
     * @return {?}
     */
    function () {
        var _this = this;
        return this.loginWithClientCredentialsObservable().subscribe(function (r) { return _this.trigger(_this.LOGIN_OK, r); }, function (r) { return _this.trigger(_this.LOGIN_ERROR, r); });
    };
    /**
     * Login using remote FacebookOauth
     * @param remoteClientId Facebook App ID
     * @param remoteClientToken Facebook access token given by the Facebook SDK.
     */
    /**
     * Login using remote FacebookOauth
     * @param {?} remoteClientId Facebook App ID
     * @param {?} remoteClientToken Facebook access token given by the Facebook SDK.
     * @return {?}
     */
    Vasat.prototype.loginWithClientFacebook = /**
     * Login using remote FacebookOauth
     * @param {?} remoteClientId Facebook App ID
     * @param {?} remoteClientToken Facebook access token given by the Facebook SDK.
     * @return {?}
     */
    function (remoteClientId, remoteClientToken) {
        var _this = this;
        return this._loadingSession = this.post('/access/token', { client_id: this.cfg.clientId, grant_type: 'remote_client', remote_client_id: remoteClientId, remote_token: remoteClientToken }, { withCredentials: true }).pipe(operators.map(function (r) {
            _this.processResp(r);
        }), operators.flatMap(function (res) {
            return new Device(_this, {}).saveObservable();
        }), operators.map(function (dev) {
            /** @type {?} */
            var ls = _this.localSession();
            ls.device = dev;
            _this.saveLocalSession(ls);
            return dev;
        }), operators.flatMap(function (res) {
            return new MeUser(_this, { id: 'me' }).fetchObservable();
        }), operators.map(function (user) {
            /** @type {?} */
            var ls = _this.localSession();
            ls.user = user;
            _this.saveLocalSession(ls);
            _this._sessionSubject.next(ls);
            _this._loadingSession = null;
            return user;
        }), operators.share());
    };
    /**
     * @param {?} remoteClientId
     * @param {?} remoteUserId
     * @param {?} remoteToken
     * @param {?} remoteSecret
     * @return {?}
     */
    Vasat.prototype.loginWithClientTwitter = /**
     * @param {?} remoteClientId
     * @param {?} remoteUserId
     * @param {?} remoteToken
     * @param {?} remoteSecret
     * @return {?}
     */
    function (remoteClientId, remoteUserId, remoteToken, remoteSecret) {
        var _this = this;
        return this._loadingSession = this.post('/access/token', { client_id: this.cfg.clientId, grant_type: 'remote_client', remote_client_id: remoteClientId, remote_token: remoteUserId + "&" + remoteToken + "&" + remoteSecret }, { withCredentials: true }).pipe(operators.map(function (r) {
            _this.processResp(r);
        }), operators.flatMap(function (res) {
            return new Device(_this, {}).saveObservable();
        }), operators.map(function (dev) {
            /** @type {?} */
            var ls = _this.localSession();
            ls.device = dev;
            _this.saveLocalSession(ls);
            return dev;
        }), operators.flatMap(function (res) {
            return new MeUser(_this, { id: 'me' }).fetchObservable();
        }), operators.map(function (user) {
            /** @type {?} */
            var ls = _this.localSession();
            ls.user = user;
            _this.saveLocalSession(ls);
            _this._sessionSubject.next(ls);
            _this._loadingSession = null;
            return user;
        }), operators.share());
    };
    /**
     * @param {?} regId
     * @return {?}
     */
    Vasat.prototype.registerPushRegId = /**
     * @param {?} regId
     * @return {?}
     */
    function (regId) {
        var _this = this;
        /** @type {?} */
        var ls = this.localSession();
        //console.log("pushRegIdSent: " + ls.device.pushRegIdSent)
        // Only register push reg Id with server if we have a session with a device
        if (ls && ls.device && !ls.device.pushRegIdSent) {
            //console.log("Sending push reg id to server")
            ls.device.pushRegId = regId;
            ls.device.allowPush = true;
            return ls.device.saveObservable().pipe(operators.map(function (res) {
                //console.log(res)
                ls.device.pushRegIdSent = true;
                _this.saveLocalSession(ls);
            }));
        }
        else {
            //console.log("Device not found or Push reg Id already sent")
            return rxjs.empty();
        }
    };
    /**
     * @param {?} email
     * @return {?}
     */
    Vasat.prototype.forgetPasswordObservable = /**
     * @param {?} email
     * @return {?}
     */
    function (email) {
        var _this = this;
        return this.post('/access/forget', { email: email }).pipe(operators.map(function (r) {
            _this.processResp(r);
        }));
    };
    /**
     * @return {?}
     */
    Vasat.prototype.logoutObservable = /**
     * @return {?}
     */
    function () {
        var _this = this;
        return this.get('/access/logout').pipe(operators.map(function (r) {
            _this.trigger(_this.LOGOUT_OK, r);
            /** @type {?} */
            var key = _this.clientKey();
            _this.token = null;
            _this.invalidateSession();
            _this.store.del(key);
        }));
    };
    /**
     * @return {?}
     */
    Vasat.prototype.logout = /**
     * @return {?}
     */
    function () {
        var _this = this;
        this.logoutObservable().subscribe(function (r) { return _this.trigger(_this.LOGOUT_OK, r); }, function (r) { return _this.trigger(_this.LOGOUT_ERROR, r); });
    };
    /**
     * @return {?}
     */
    Vasat.prototype.refreshMeUser = /**
     * @return {?}
     */
    function () {
        var _this = this;
        return new MeUser(this, { id: 'me' })
            .fetchObservable()
            .pipe(operators.map(function (user) {
            /** @type {?} */
            var ls = _this.localSession();
            ls.user = user;
            _this.saveLocalSession(ls);
            return user;
        }));
    };
    /**
     * @private
     * @param {?} resp
     * @return {?}
     */
    Vasat.prototype.processResp = /**
     * @private
     * @param {?} resp
     * @return {?}
     */
    function (resp) {
        if (!this.cfg.useCookies && !resp.status) {
            resp = {
                status: resp['error'] ? 409 : 200,
                message: resp['error'] || 'ok',
                payload: resp
            };
        }
        if (resp.payload && resp.payload.access_token) {
            /** @type {?} */
            var obj = {
                access_token: resp.payload.access_token
            };
            if (resp.payload.expires_in && parseInt(resp.payload.expires_in)) {
                obj['expires_by'] = new Date(new Date().getTime() + 1000 * resp.payload.expires_in).getTime();
            }
            this.saveLocalSession(obj);
            this.localSession();
        }
        else if (resp.status < 400) {
            return resp;
        }
        else {
            this._sessionSubject.next(new VasatError(resp, null));
            throw resp;
        }
    };
    /**
     * @param {?} url
     * @param {?=} meth
     * @param {?=} args
     * @return {?}
     */
    Vasat.prototype.api = /**
     * @param {?} url
     * @param {?=} meth
     * @param {?=} args
     * @return {?}
     */
    function (url, meth, args) {
        /** @type {?} */
        var a = new VasatAPICall();
        return a.apiObservable(this, meth || 'get', url, args);
    };
    /**
     * @param {?} url
     * @param {?=} args
     * @return {?}
     */
    Vasat.prototype.get = /**
     * @param {?} url
     * @param {?=} args
     * @return {?}
     */
    function (url, args) {
        return this._http('get', url, null, args);
    };
    /**
     * @param {?} url
     * @param {?=} args
     * @return {?}
     */
    Vasat.prototype.delete = /**
     * @param {?} url
     * @param {?=} args
     * @return {?}
     */
    function (url, args) {
        return this._http('delete', url, null, args);
    };
    /**
     * @param {?} url
     * @param {?} obj
     * @param {?=} args
     * @return {?}
     */
    Vasat.prototype.put = /**
     * @param {?} url
     * @param {?} obj
     * @param {?=} args
     * @return {?}
     */
    function (url, obj, args) {
        return this._http('put', url, obj, args);
    };
    /**
     * @param {?} url
     * @param {?} obj
     * @param {?=} args
     * @return {?}
     */
    Vasat.prototype.post = /**
     * @param {?} url
     * @param {?} obj
     * @param {?=} args
     * @return {?}
     */
    function (url, obj, args) {
        return this._http('post', url, obj, args);
    };
    /**
     * @param {?} meth
     * @param {?} url
     * @param {?} obj
     * @param {?=} args
     * @return {?}
     */
    Vasat.prototype._http = /**
     * @param {?} meth
     * @param {?} url
     * @param {?} obj
     * @param {?=} args
     * @return {?}
     */
    function (meth, url, obj, args) {
        /** @type {?} */
        var a = args || {};
        /** @type {?} */
        var headers = new http.HttpHeaders();
        if (this.headers) {
            headers = headers.set("Authorization", this.headers.get("Authorization"));
        }
        if (obj)
            headers = headers.set('Content-Type', 'application/json');
        a.headers = headers;
        //console.log(a)
        if (obj) {
            return this.http[meth](this.cfg.host + url, JSON.stringify(obj), a);
        }
        else {
            return this.http[meth](this.cfg.host + url, a);
        }
    };
    /**
     * @template T
     * @param {?} v
     * @param {?=} overloadUrl
     * @return {?}
     */
    Vasat.prototype.searchByObject = /**
     * @template T
     * @param {?} v
     * @param {?=} overloadUrl
     * @return {?}
     */
    function (v, overloadUrl) {
        return new VasatSearchQuery(v, this, overloadUrl);
    };
    /**
     * @template T
     * @param {?} obj
     * @param {?=} overloadUrl
     * @return {?}
     */
    Vasat.prototype.searchByObjectString = /**
     * @template T
     * @param {?} obj
     * @param {?=} overloadUrl
     * @return {?}
     */
    function (obj, overloadUrl) {
        var VasatObj = /** @class */ (function (_super) {
            __extends(VasatObj, _super);
            function VasatObj(V) {
                return _super.call(this, V, obj, overloadUrl) || this;
            }
            return VasatObj;
        }(VasatModel));
        return this.searchByObject((/** @type {?} */ (VasatObj)), overloadUrl);
    };
    /**
     * Generate a url to send the browser to so it can authenticate.
     * @client is the remote client uid
     * @redirectUrl is the url on your server to be sent to once the user has logged in (or not)
     *
     * returns observale of the url to be sent to
     */
    /**
     * Generate a url to send the browser to so it can authenticate.
     * \@client is the remote client uid
     * \@redirectUrl is the url on your server to be sent to once the user has logged in (or not)
     *
     * returns observale of the url to be sent to
     * @param {?} client
     * @param {?} redirectUrl
     * @param {?=} state
     * @return {?}
     */
    Vasat.prototype.loginViaOAuthUrl = /**
     * Generate a url to send the browser to so it can authenticate.
     * \@client is the remote client uid
     * \@redirectUrl is the url on your server to be sent to once the user has logged in (or not)
     *
     * returns observale of the url to be sent to
     * @param {?} client
     * @param {?} redirectUrl
     * @param {?=} state
     * @return {?}
     */
    function (client, redirectUrl, state) {
        /** @type {?} */
        var post = {
            client_id: this.cfg.clientId,
            remote_client_id: client,
            target_url: redirectUrl
        };
        return this.api('/access/remote' + (state ? '?state=' + state : ''), 'post', post).pipe(operators.map(function (r) { return r.url; }));
    };
    /**
     * When an oauth site returns you back to this site
     * @client is the remote client uid
     * @redirectUrl is the url on your server to be sent to once the user has logged in (or not)
     * @code is the code returned by the oauth server
     * returns observale of the url to be sent to
     */
    /**
     * When an oauth site returns you back to this site
     * \@client is the remote client uid
     * \@redirectUrl is the url on your server to be sent to once the user has logged in (or not)
     * \@code is the code returned by the oauth server
     * returns observale of the url to be sent to
     * @param {?} client
     * @param {?} code
     * @param {?} redirectUrl
     * @param {?=} state
     * @return {?}
     */
    Vasat.prototype.verifyOAuthUrl = /**
     * When an oauth site returns you back to this site
     * \@client is the remote client uid
     * \@redirectUrl is the url on your server to be sent to once the user has logged in (or not)
     * \@code is the code returned by the oauth server
     * returns observale of the url to be sent to
     * @param {?} client
     * @param {?} code
     * @param {?} redirectUrl
     * @param {?=} state
     * @return {?}
     */
    function (client, code, redirectUrl, state) {
        var _this = this;
        /** @type {?} */
        var post = {
            client_id: this.cfg.clientId,
            remote_client_id: client,
            target_url: redirectUrl,
            code: code
        };
        if (state)
            post['state'] = state;
        return this.post('/access/remoteAuth', post).pipe(operators.map(function (r) {
            return _this.processResp(r);
        }), operators.flatMap(function (res) {
            return new Device(_this, {}).saveObservable();
        }), operators.map(function (dev) {
            /** @type {?} */
            var ls = _this.localSession();
            ls.device = dev;
            _this.saveLocalSession(ls);
            return dev;
        }), operators.flatMap(function (res) {
            return new MeUser(_this, { id: 'me' }).fetchObservable();
        }), operators.map(function (user) {
            /** @type {?} */
            var ls = _this.localSession();
            ls.user = user;
            _this.saveLocalSession(ls);
            _this._sessionSubject.next(ls);
            _this._loadingSession = null;
            return user;
        }));
    };
    Vasat.decorators = [
        { type: core.Injectable },
    ];
    /** @nocollapse */
    Vasat.ctorParameters = function () { return [
        { type: http.HttpClient },
        { type: VasatConfig },
        { type: VasatStorage, decorators: [{ type: core.Optional }] }
    ]; };
    return Vasat;
}(Triggerable));
var VasatAPICall = /** @class */ (function (_super) {
    __extends(VasatAPICall, _super);
    function VasatAPICall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * @param {?} V
     * @param {?} meth
     * @param {?} url
     * @param {?=} obj
     * @param {?=} args
     * @return {?}
     */
    VasatAPICall.prototype.api = /**
     * @param {?} V
     * @param {?} meth
     * @param {?} url
     * @param {?=} obj
     * @param {?=} args
     * @return {?}
     */
    function (V, meth, url, obj, args) {
        var _this = this;
        this.trigger(V.API_START, this);
        this.apiObservable(V, meth, url, obj, args).subscribe(function (ok) {
            _this.trigger(V.SYNC_OK, ok);
            
        }, function (error) { return _this.trigger(V.SYNC_ERROR, error); }, function () { return _this.trigger(V.SYNC_FINISHED, _this); });
    };
    /**
     * @param {?} V
     * @param {?} meth
     * @param {?} url
     * @param {?=} obj
     * @param {?=} args
     * @return {?}
     */
    VasatAPICall.prototype.apiObservable = /**
     * @param {?} V
     * @param {?} meth
     * @param {?} url
     * @param {?=} obj
     * @param {?=} args
     * @return {?}
     */
    function (V, meth, url, obj, args) {
        var _this = this;
        this.trigger(V.API_START, this);
        this._loading = true;
        /** @type {?} */
        var o = V._http(meth, url, obj).pipe(operators.flatMap(function (r) {
            /** @type {?} */
            var resp = r;
            _this.trigger(V.API_FINISH, _this);
            return _this.processResp(V, meth, url, resp, obj, args);
        }), operators.catchError(function (e) {
            /** @type {?} */
            var ve = null;
            if (e instanceof VasatError)
                ve = e;
            else {
                if (e.error)
                    e = e.error;
                ve = e['status'] && e['message'] && e['version'] ? new VasatError((/** @type {?} */ (e)), null) : new VasatError({ status: 100001, message: 'Vasat network connection or response process issue', version: "", time: new Date().getTime() }, e.toString());
            }
            if (ve instanceof VasatError) {
                throw ve;
            }
            else {
                _this.trigger(V.API_FINISH, _this);
                if (!args || args['pass_error'])
                    throw ve;
                return rxjs.of(ve);
            }
        }));
        return o;
    };
    /**
     * @protected
     * @param {?} V
     * @param {?} meth
     * @param {?} url
     * @param {?} resp
     * @param {?=} obj
     * @param {?=} args
     * @return {?}
     */
    VasatAPICall.prototype.processResp = /**
     * @protected
     * @param {?} V
     * @param {?} meth
     * @param {?} url
     * @param {?} resp
     * @param {?=} obj
     * @param {?=} args
     * @return {?}
     */
    function (V, meth, url, resp, obj, args) {
        this._loading = false;
        if (!resp.status || resp.status >= 400) {
            this._error = resp;
            if (resp.status == 405) {
                if (V.localSession()) {
                    V.invalidateSession();
                    V.trigger(V.LOGOUT_OK, resp);
                }
            }
            else {
                V.trigger(V.GLOBAL_ERROR, resp);
            }
            //console.log("Enter API error: " + JSON.stringify(resp))
            if (args && args['pass_error'])
                return rxjs.of(new VasatError(resp, null));
            else
                throw resp;
        }
        else {
            this._error = null;
            return this.postProcess(meth, url, resp, obj, args);
        }
    };
    /**
     * @param {?} meth
     * @param {?} url
     * @param {?} resp
     * @param {?=} obj
     * @param {?=} args
     * @return {?}
     */
    VasatAPICall.prototype.postProcess = /**
     * @param {?} meth
     * @param {?} url
     * @param {?} resp
     * @param {?=} obj
     * @param {?=} args
     * @return {?}
     */
    function (meth, url, resp, obj, args) {
        return rxjs.of(resp.payload);
    };
    return VasatAPICall;
}(Triggerable));
/**
 * Search
 * @record
 */

/**
 * @template T
 */
var VasatSearchQuery = /** @class */ (function (_super) {
    __extends(VasatSearchQuery, _super);
    function VasatSearchQuery(clazz, V, searchUrl) {
        var _this = _super.call(this) || this;
        _this.clazz = clazz;
        _this.V = V;
        _this.searchUrl = searchUrl;
        _this.queryParams = { limit: 100 };
        _this._resSubject = new rxjs.Subject();
        _this.items = _this._resSubject.asObservable();
        _this.resultCache = [];
        return _this;
    }
    /**
     * @param {?} v
     * @return {?}
     */
    VasatSearchQuery.prototype._castToString = /**
     * @param {?} v
     * @return {?}
     */
    function (v) {
        if (v == null) {
            return "null";
        }
        else if (v instanceof VasatModel) {
            return "" + v.id;
        }
        else if (v instanceof Date) {
            return "" + v.getTime();
        }
        else {
            return "" + v;
        }
    };
    /**
     * @template THIS
     * @this {THIS}
     * @param {?} column
     * @return {THIS}
     */
    VasatSearchQuery.prototype.isNull = /**
     * @template THIS
     * @this {THIS}
     * @param {?} column
     * @return {THIS}
     */
    function (column) {
        (/** @type {?} */ (this)).queryParams[column] = "null";
        return (/** @type {?} */ (this));
    };
    /**
     * @template THIS
     * @this {THIS}
     * @param {?} column
     * @return {THIS}
     */
    VasatSearchQuery.prototype.isNotNull = /**
     * @template THIS
     * @this {THIS}
     * @param {?} column
     * @return {THIS}
     */
    function (column) {
        (/** @type {?} */ (this)).queryParams[column] = "!null";
        return (/** @type {?} */ (this));
    };
    /**
     * @template THIS
     * @this {THIS}
     * @param {?} value
     * @return {THIS}
     */
    VasatSearchQuery.prototype.complexQuery = /**
     * @template THIS
     * @this {THIS}
     * @param {?} value
     * @return {THIS}
     */
    function (value) {
        (/** @type {?} */ (this)).queryParams['query'] = JSON.stringify(value);
        return (/** @type {?} */ (this));
    };
    /**
     * @template THIS
     * @this {THIS}
     * @param {?} column
     * @param {?} value
     * @return {THIS}
     */
    VasatSearchQuery.prototype.equals = /**
     * @template THIS
     * @this {THIS}
     * @param {?} column
     * @param {?} value
     * @return {THIS}
     */
    function (column, value) {
        (/** @type {?} */ (this)).queryParams[column] = (/** @type {?} */ (this))._castToString(value);
        return (/** @type {?} */ (this));
    };
    /**
     * @template THIS
     * @this {THIS}
     * @param {?} column
     * @param {?} value
     * @return {THIS}
     */
    VasatSearchQuery.prototype.equalsAny = /**
     * @template THIS
     * @this {THIS}
     * @param {?} column
     * @param {?} value
     * @return {THIS}
     */
    function (column, value) {
        var _this = this;
        /** @type {?} */
        var s = "";
        value.forEach(function (v) { return s += (/** @type {?} */ (_this))._castToString(value) + "|"; });
        if (s.length)
            s = s.substring(0, s.length - 1);
        (/** @type {?} */ (this)).equals(column, s);
        return (/** @type {?} */ (this));
    };
    /**
     * @template THIS
     * @this {THIS}
     * @param {?} column
     * @param {?} value
     * @return {THIS}
     */
    VasatSearchQuery.prototype.contains = /**
     * @template THIS
     * @this {THIS}
     * @param {?} column
     * @param {?} value
     * @return {THIS}
     */
    function (column, value) {
        (/** @type {?} */ (this)).queryParams[column] = "~" + (/** @type {?} */ (this))._castToString(value);
        return (/** @type {?} */ (this));
    };
    /**
     * @template THIS
     * @this {THIS}
     * @param {?} column
     * @param {?} value
     * @return {THIS}
     */
    VasatSearchQuery.prototype.greaterThan = /**
     * @template THIS
     * @this {THIS}
     * @param {?} column
     * @param {?} value
     * @return {THIS}
     */
    function (column, value) {
        (/** @type {?} */ (this)).queryParams[column] = ">" + (/** @type {?} */ (this))._castToString(value);
        return (/** @type {?} */ (this));
    };
    /**
     * @template THIS
     * @this {THIS}
     * @param {?} column
     * @param {?} value
     * @return {THIS}
     */
    VasatSearchQuery.prototype.lessThan = /**
     * @template THIS
     * @this {THIS}
     * @param {?} column
     * @param {?} value
     * @return {THIS}
     */
    function (column, value) {
        (/** @type {?} */ (this)).queryParams[column] = "<" + (/** @type {?} */ (this))._castToString(value);
        return (/** @type {?} */ (this));
    };
    /**
     * @template THIS
     * @this {THIS}
     * @param {?} column
     * @param {?} value
     * @return {THIS}
     */
    VasatSearchQuery.prototype.notEquals = /**
     * @template THIS
     * @this {THIS}
     * @param {?} column
     * @param {?} value
     * @return {THIS}
     */
    function (column, value) {
        (/** @type {?} */ (this)).queryParams[column] = "!" + (/** @type {?} */ (this))._castToString(value);
        return (/** @type {?} */ (this));
    };
    /**
     * @template THIS
     * @this {THIS}
     * @param {?} column
     * @param {?} value
     * @return {THIS}
     */
    VasatSearchQuery.prototype.notContains = /**
     * @template THIS
     * @this {THIS}
     * @param {?} column
     * @param {?} value
     * @return {THIS}
     */
    function (column, value) {
        (/** @type {?} */ (this)).queryParams[column] = "!~" + (/** @type {?} */ (this))._castToString(value);
        return (/** @type {?} */ (this));
    };
    /**
     * @template THIS
     * @this {THIS}
     * @param {?} column
     * @param {?} value
     * @return {THIS}
     */
    VasatSearchQuery.prototype.notGreaterThan = /**
     * @template THIS
     * @this {THIS}
     * @param {?} column
     * @param {?} value
     * @return {THIS}
     */
    function (column, value) {
        (/** @type {?} */ (this)).queryParams[column] = "!>" + (/** @type {?} */ (this))._castToString(value);
        return (/** @type {?} */ (this));
    };
    /**
     * @template THIS
     * @this {THIS}
     * @param {?} column
     * @param {?} value
     * @return {THIS}
     */
    VasatSearchQuery.prototype.notLessThan = /**
     * @template THIS
     * @this {THIS}
     * @param {?} column
     * @param {?} value
     * @return {THIS}
     */
    function (column, value) {
        (/** @type {?} */ (this)).queryParams[column] = "!<" + (/** @type {?} */ (this))._castToString(value);
        return (/** @type {?} */ (this));
    };
    /**
     * @template THIS
     * @this {THIS}
     * @param {?} column
     * @param {?=} descend
     * @return {THIS}
     */
    VasatSearchQuery.prototype.sortBy = /**
     * @template THIS
     * @this {THIS}
     * @param {?} column
     * @param {?=} descend
     * @return {THIS}
     */
    function (column, descend) {
        (/** @type {?} */ (this)).queryParams['sortby'] = column;
        (/** @type {?} */ (this)).queryParams['sortbyorder'] = descend ? 'down' : 'up';
        return (/** @type {?} */ (this));
    };
    /**
     * @template THIS
     * @this {THIS}
     * @param {?} limit
     * @return {THIS}
     */
    VasatSearchQuery.prototype.limit = /**
     * @template THIS
     * @this {THIS}
     * @param {?} limit
     * @return {THIS}
     */
    function (limit) {
        (/** @type {?} */ (this)).queryParams['limit'] = limit;
        return (/** @type {?} */ (this));
    };
    /**
     * @template THIS
     * @this {THIS}
     * @param {?} offset
     * @return {THIS}
     */
    VasatSearchQuery.prototype.offset = /**
     * @template THIS
     * @this {THIS}
     * @param {?} offset
     * @return {THIS}
     */
    function (offset) {
        (/** @type {?} */ (this)).queryParams['offset'] = offset;
        return (/** @type {?} */ (this));
    };
    /**
     * @return {?}
     */
    VasatSearchQuery.prototype.query = /**
     * @return {?}
     */
    function () {
        return this._query(false);
    };
    /**
     * @return {?}
     */
    VasatSearchQuery.prototype.queryObservable = /**
     * @return {?}
     */
    function () {
        return this._query(true);
    };
    /**
     * @param {?} searchRes
     * @return {?}
     */
    VasatSearchQuery.prototype.queryObservableBYO = /**
     * @param {?} searchRes
     * @return {?}
     */
    function (searchRes) {
        return this._query(true, searchRes);
    };
    /**
     * @template THIS
     * @this {THIS}
     * @param {?} cache
     * @return {THIS}
     */
    VasatSearchQuery.prototype.useCache = /**
     * @template THIS
     * @this {THIS}
     * @param {?} cache
     * @return {THIS}
     */
    function (cache) {
        (/** @type {?} */ (this))._useCache = cache;
        return (/** @type {?} */ (this));
    };
    /**
     * @protected
     * @param {?} ob
     * @param {?=} searchRes
     * @return {?}
     */
    VasatSearchQuery.prototype._query = /**
     * @protected
     * @param {?} ob
     * @param {?=} searchRes
     * @return {?}
     */
    function (ob, searchRes) {
        var _this = this;
        /** @type {?} */
        var fn = this.clazz;
        /** @type {?} */
        var bm = new fn(this.V);
        //var enc = new HttpParams()    // For some reason HttpParams doesn't behaive as expected in Nativescript
        /** @type {?} */
        var enc = "";
        /** @type {?} */
        var hasQ = false;
        Object.keys(this.queryParams).forEach(function (k) {
            //enc.append(k,this.queryParams[k])
            enc += k + "=" + _this.queryParams[k] + "&";
            hasQ = true;
        });
        /** @type {?} */
        var url = this.searchUrl ? this.V.pathPrefix + '/' + this.searchUrl : bm.urlRoot();
        if (ob) {
            return this.apiObservable(this.V, 'get', url + (hasQ ? ("?" + encodeURI(enc)) : ''), null, { res: searchRes }).pipe(operators.map(function (r) {
                _this._resSubject.next(r.items);
                _this.resultCache = r.items;
                return r;
            }));
        }
        else {
            return this.api(this.V, 'get', url + (hasQ ? ("?" + enc.toString()) : ''), null, { res: searchRes });
        }
    };
    /**
     * @param {?} meth
     * @param {?} url
     * @param {?} resp
     * @param {?=} postObj
     * @param {?=} args
     * @return {?}
     */
    VasatSearchQuery.prototype.postProcess = /**
     * @param {?} meth
     * @param {?} url
     * @param {?} resp
     * @param {?=} postObj
     * @param {?=} args
     * @return {?}
     */
    function (meth, url, resp, postObj, args) {
        var _this = this;
        // cast items into the object type
        /** @type {?} */
        var items = resp.payload.map(function (cur) {
            if (_this._useCache) {
                /** @type {?} */
                var cached = _this.V.findCached(_this.clazz, cur.id);
                cached = (cached || new _this.clazz(_this.V, ''));
                return cached.set(cur);
            }
            else {
                return new _this.clazz(_this.V, '').set(cur);
            }
        });
        if (this._useCache) {
            this.V.registerCacheClass(this.clazz, items);
        }
        /** @type {?} */
        var scope = {};
        return (items.length ? rxjs.forkJoin(items.map(function (i) { return i.postSet(i, scope); })) : rxjs.of(items)).pipe(operators.map(function (_) {
            // if there was a BYO search results update it
            if (args && args['res']) {
                /** @type {?} */
                var sr = args['res'];
                sr.items = items;
                sr.setFromResponse(resp, _this.queryParams.limit);
                return sr;
            }
            else
                return new SearchResults(_this.clazz, resp, _this.queryParams, _this, _this.queryParams.limit, items, _this.V);
        }));
    };
    return VasatSearchQuery;
}(VasatAPICall));
/**
 * @template T
 */
var SearchResults = /** @class */ (function (_super) {
    __extends(SearchResults, _super);
    function SearchResults(clazz, resp, query, queryObj, limit, items, V) {
        var _this = _super.call(this) || this;
        _this.clazz = clazz;
        _this.resp = resp;
        _this.query = query;
        _this.queryObj = queryObj;
        _this.items = items;
        _this.V = V;
        _this.setFromResponse(resp, limit);
        _this.pageUpdate = new rxjs.Subject();
        _this.pageUpdate
            .asObservable().pipe(operators.debounceTime(300), operators.distinctUntilChanged())
            .subscribe(function (n) {
            return _this.currentPage = n;
        });
        return _this;
    }
    /**
     * @param {?} resp
     * @param {?} limit
     * @return {?}
     */
    SearchResults.prototype.setFromResponse = /**
     * @param {?} resp
     * @param {?} limit
     * @return {?}
     */
    function (resp, limit) {
        this._currentPage = Math.floor(resp.offset / limit);
        this.pages = Math.ceil(resp.total / limit);
        this.total = resp.total;
        this.offset = resp.offset;
        this.time = resp.time;
        this.trigger(this.V.SYNC_OK, this.items);
    };
    Object.defineProperty(SearchResults.prototype, "limit", {
        get: /**
         * @return {?}
         */
        function () {
            return this.query['limit'];
        },
        set: /**
         * @param {?} s
         * @return {?}
         */
        function (s) {
            if (s != this.limit) {
                this.queryObj
                    .limit(s)
                    .queryObservableBYO(this).subscribe(function (r) {
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchResults.prototype, "sortBy", {
        get: /**
         * @return {?}
         */
        function () {
            return this.query['sortby'];
        },
        set: /**
         * @param {?} s
         * @return {?}
         */
        function (s) {
            if (s != this.sortBy) {
                this.queryObj
                    .sortBy(s, this.sortDown)
                    .queryObservableBYO(this).subscribe(function (r) {
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchResults.prototype, "sortDown", {
        get: /**
         * @return {?}
         */
        function () {
            return this.query['sortbyorder'] == 'down';
        },
        set: /**
         * @param {?} s
         * @return {?}
         */
        function (s) {
            if (this.sortDown != s) {
                this.queryObj
                    .sortBy(this.sortBy, s)
                    .queryObservableBYO(this).subscribe(function (r) {
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchResults.prototype, "currentPageP1", {
        get: /**
         * @return {?}
         */
        function () {
            return this._currentPage + 1;
        },
        set: /**
         * @param {?} p
         * @return {?}
         */
        function (p) {
            this.pageUpdate.next(!p ? 0 : p - 1);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchResults.prototype, "currentPage", {
        get: /**
         * @return {?}
         */
        function () {
            return this._currentPage;
        },
        set: /**
         * @param {?} p
         * @return {?}
         */
        function (p) {
            if (p != this._currentPage) {
                this.queryObj
                    .offset(p * this.limit)
                    .queryObservableBYO(this).subscribe(function (r) {
                });
            }
            this._currentPage = p;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * @return {?}
     */
    SearchResults.prototype.refresh = /**
     * @return {?}
     */
    function () {
        this.queryObj
            .queryObservableBYO(this).subscribe(function (r) {
        });
    };
    return SearchResults;
}(Triggerable));
/**
 * MODELS
 * @template T
 */
var VasatModel = /** @class */ (function (_super) {
    __extends(VasatModel, _super);
    function VasatModel(V, _objType, vars) {
        var _this = _super.call(this) || this;
        _this.V = V;
        _this._objType = _objType;
        _this.props = {}; // Default empty prompts rather than null
        _this.setVals(vars);
        return _this;
    }
    /**
     * @param {?} vars
     * @return {?}
     */
    VasatModel.prototype.setVals = /**
     * @param {?} vars
     * @return {?}
     */
    function (vars) {
        if (vars) {
            /** @type {?} */
            var t = typeof vars;
            if (t == 'object') {
                this.set(vars);
            }
            else {
                this.id = vars;
            }
        }
    };
    /**
     * @return {?}
     */
    VasatModel.prototype.onReady = /**
     * @return {?}
     */
    function () {
        //console.log('onReady ' + this.loading)
        if (!this.loading)
            return rxjs.of(this);
        else
            return this.loading;
    };
    /**
     * @template THIS
     * @this {THIS}
     * @param {?} args
     * @return {THIS}
     */
    VasatModel.prototype.set = /**
     * @template THIS
     * @this {THIS}
     * @param {?} args
     * @return {THIS}
     */
    function (args) {
        var _this = this;
        Object.keys(args).forEach(function (k) {
            // dont override toManyLists
            if (!((/** @type {?} */ (_this))[k] instanceof ToManyList))
                (/** @type {?} */ (_this))[k] = args[k];
        });
        return (/** @type {?} */ (this));
    };
    /**
     * @return {?}
     */
    VasatModel.prototype.urlRoot = /**
     * @return {?}
     */
    function () {
        return this.V.pathPrefix + '/' + this._objType;
    };
    /**
     * @return {?}
     */
    VasatModel.prototype.urlPath = /**
     * @return {?}
     */
    function () {
        if (this.id)
            return this.urlRoot() + "/" + this.id;
        else
            return this.urlRoot();
    };
    /**
     * @return {?}
     */
    VasatModel.prototype.fetchObservable = /**
     * @return {?}
     */
    function () {
        var _this = this;
        if (!this.id) {
            return (/** @type {?} */ (rxjs.of(this)));
        }
        else {
            this.loading = this.apiObservable(this.V, 'get', this.urlPath(), null, { pass_error: true }).pipe(operators.map(function (o) {
                _this.trigger(_this.V.FETCH_OK, _this);
                return o;
            }), operators.share());
            return this.loading;
        }
    };
    /**
     * @return {?}
     */
    VasatModel.prototype.saveObservable = /**
     * @return {?}
     */
    function () {
        var _this = this;
        /** @type {?} */
        var json = this.toJSON();
        //console.log("JSON: " + JSON.stringify(json))
        this.loading = this.apiObservable(this.V, (this.id ? 'put' : 'post'), this.urlPath(), json, { pass_error: true }).pipe(operators.map(function (o) {
            _this.trigger(_this.V.SAVE_OK, _this);
            return o;
        }), operators.share());
        return this.loading;
    };
    /**
     * @param {?=} params
     * @return {?}
     */
    VasatModel.prototype.destroyObservable = /**
     * @param {?=} params
     * @return {?}
     */
    function (params) {
        if (!this.id) {
            return (/** @type {?} */ (rxjs.of(this)));
        }
        else if (!this.loading) {
            this.loading = this.apiObservable(this.V, 'delete', this.urlPath() + ((params) ? '?' + params : ''), null, { pass_error: true }).pipe(operators.share());
        }
        return this.loading;
    };
    /**
     * @template THIS
     * @this {THIS}
     * @return {THIS}
     */
    VasatModel.prototype.fetch = /**
     * @template THIS
     * @this {THIS}
     * @return {THIS}
     */
    function () {
        (/** @type {?} */ (this)).mkTrigger((/** @type {?} */ (this)).fetchObservable());
        return (/** @type {?} */ (this));
    };
    /**
     * @template THIS
     * @this {THIS}
     * @return {THIS}
     */
    VasatModel.prototype.save = /**
     * @template THIS
     * @this {THIS}
     * @return {THIS}
     */
    function () {
        (/** @type {?} */ (this)).mkTrigger((/** @type {?} */ (this)).saveObservable());
        return (/** @type {?} */ (this));
    };
    /**
     * @template THIS
     * @this {THIS}
     * @return {THIS}
     */
    VasatModel.prototype.destroy = /**
     * @template THIS
     * @this {THIS}
     * @return {THIS}
     */
    function () {
        (/** @type {?} */ (this)).mkTrigger((/** @type {?} */ (this)).destroyObservable());
        return (/** @type {?} */ (this));
    };
    /**
     * @protected
     * @param {?} o
     * @return {?}
     */
    VasatModel.prototype.mkTrigger = /**
     * @protected
     * @param {?} o
     * @return {?}
     */
    function (o) {
        var _this = this;
        o.subscribe(function (ok) { return _this.trigger(_this.V.SYNC_OK, ok); }, function (error) { return _this.trigger(_this.V.SYNC_ERROR, error); }, function () { return _this.trigger(_this.V.SYNC_FINISHED, _this); });
    };
    /**
     * @param {?} V
     * @param {?} meth
     * @param {?} url
     * @param {?} resp
     * @param {?=} obj
     * @param {?=} args
     * @return {?}
     */
    VasatModel.prototype.processResp = /**
     * @param {?} V
     * @param {?} meth
     * @param {?} url
     * @param {?} resp
     * @param {?=} obj
     * @param {?=} args
     * @return {?}
     */
    function (V, meth, url, resp, obj, args) {
        var _this = this;
        return _super.prototype.processResp.call(this, V, meth, url, resp, obj, args).pipe(operators.flatMap(function (s) {
            _this.loading = null;
            if (s instanceof VasatError) {
                args.pass_error = false;
                //console.log('throwing!' + s)
                throw s;
            }
            if (meth == 'delete') {
                _this._deleted = true;
                return rxjs.of(null);
            }
            else if (resp.payload && meth != 'put') {
                _this.set(resp.payload);
                return _this.postSet(resp.payload);
            }
            else {
                return rxjs.of(_this);
            }
        }));
    };
    /**
     * @param {?} objs
     * @param {?=} scope
     * @return {?}
     */
    VasatModel.prototype.postSet = /**
     * @param {?} objs
     * @param {?=} scope
     * @return {?}
     */
    function (objs, scope) {
        return rxjs.of((/** @type {?} */ (this)));
    };
    /**
     * @param {?=} shallow
     * @return {?}
     */
    VasatModel.prototype.toJSON = /**
     * @param {?=} shallow
     * @return {?}
     */
    function (shallow) {
        /** @type {?} */
        var refed = [];
        return this._toJSON(this, 0, refed);
    };
    /**
     * @param {?} the_obj
     * @param {?} depth
     * @param {?} refMap
     * @return {?}
     */
    VasatModel.prototype._toJSON = /**
     * @param {?} the_obj
     * @param {?} depth
     * @param {?} refMap
     * @return {?}
     */
    function (the_obj, depth, refMap) {
        var _this = this;
        if (the_obj == null) {
            return null;
        }
        else if (Array.isArray(the_obj)) {
            /** @type {?} */
            return the_obj.map(function (a) { return _this._toJSON(a, depth + 1, refMap); });
        }
        else if (the_obj instanceof Date) {
            return the_obj.getTime();
        }
        else if (typeof the_obj == 'object') {
            // if this object has already been serialized, you cant serialize it again
            if (refMap.find(function (r) { return r == the_obj; }) && depth) {
                if (the_obj instanceof VasatModel) {
                    return { id: the_obj['id'], type: the_obj['_objType'] };
                }
                else {
                    console.warn("Warning recursive object in JSON ");
                    console.log(the_obj);
                    return null;
                }
            }
            refMap.push(the_obj);
            /** @type {?} */
            var json = {};
            Object.keys(the_obj).filter(function (k) { return k != 'loading' && k != '_listenTos' && k != 'V' && k != '_eventBus' && k != '_error' && k != '_loading' && k != '_deleted' && k != '_objType'; }).forEach(function (k) {
                if (!(the_obj[k] instanceof ToManyList)) {
                    json[k] = _this._toJSON(the_obj[k], depth + 1, refMap);
                }
            });
            if (the_obj instanceof VasatModel && !the_obj['type']) {
                /** @type {?} */
                var vm = the_obj;
                json['type'] = vm._objType;
            }
            return json;
        }
        else {
            return the_obj;
        }
    };
    /**
     * @param {?} fn
     * @param {?=} cache
     * @return {?}
     */
    VasatModel.prototype.file = /**
     * @param {?} fn
     * @param {?=} cache
     * @return {?}
     */
    function (fn, cache) {
        if (cache === void 0) { cache = true; }
        return this.rootPath() + '/' + fn +
            (!this.V.cfg.useCookies || !cache ? '?' : '') +
            (this.V.cfg.useCookies ? '' : 'access_token=' + this.V.token) +
            (!this.V.cfg.useCookies ? '&' : '') +
            (cache ? '' : '__rand=' + Math.random());
    };
    /**
     * @return {?}
     */
    VasatModel.prototype.rootPath = /**
     * @return {?}
     */
    function () {
        return this.V.cfg.host + this.urlPath();
    };
    /**
     * @param {?} fn
     * @return {?}
     */
    VasatModel.prototype.findFile = /**
     * @param {?} fn
     * @return {?}
     */
    function (fn) {
        if (!this.files)
            return null;
        if (Array.isArray(this.files)) {
            return this.files.find(function (f) { return f.name == fn; });
        }
        else if (typeof this.files == 'object') {
            return this.files[fn];
        }
    };
    /**
     * @return {?}
     */
    VasatModel.prototype.className = /**
     * @return {?}
     */
    function () {
        return this._objType;
    };
    return VasatModel;
}(VasatAPICall));
var User = /** @class */ (function (_super) {
    __extends(User, _super);
    function User(V, id) {
        var _this = _super.call(this, V, 'User', id) || this;
        _this.json = {}; // Default emtpy json field for user.
        return _this;
    }
    /**
     * @param {?} old
     * @param {?} newPass
     * @return {?}
     */
    User.prototype.setPassword = /**
     * @param {?} old
     * @param {?} newPass
     * @return {?}
     */
    function (old, newPass) {
        return this.V.api(this.urlPath(), 'put', { oldp: old, newp: newPass, newp2: newPass });
    };
    /**
     * @param {?} old
     * @param {?} newPass
     * @param {?} confirmNewPass
     * @return {?}
     */
    User.prototype.changePassword = /**
     * @param {?} old
     * @param {?} newPass
     * @param {?} confirmNewPass
     * @return {?}
     */
    function (old, newPass, confirmNewPass) {
        return this.V.api(this.urlPath(), 'put', { oldp: old, newp: newPass, newp2: confirmNewPass });
    };
    return User;
}(VasatModel));
var MeUser = /** @class */ (function (_super) {
    __extends(MeUser, _super);
    function MeUser(V, id) {
        return _super.call(this, V, id) || this;
    }
    /**
     * @return {?}
     */
    MeUser.prototype.urlPath = /**
     * @return {?}
     */
    function () {
        return '/api/me';
    };
    return MeUser;
}(User));
var Device = /** @class */ (function (_super) {
    __extends(Device, _super);
    function Device(V, id) {
        return _super.call(this, V, 'Device', id) || this;
    }
    /**
     * @return {?}
     */
    Device.prototype.toJSON = /**
     * @return {?}
     */
    function () {
        /*
        var n:any = navigator;
        var bo = this.browserV()
        return {
            id:this.id||null,
            name:bo.browserName  + " - " + bo.fullVersion,
            remoteID:this.guid(),
            pushRegId:this.pushRegId,
            allowPush:this.allowPush,
            pushRegIdSent:this.pushRegIdSent,
            deviceType:{
                deviceModel:bo.browserName + ' ' + bo.majorVersion,
                deviceMake:'Browser',
                deviceOSName:navigator.platform,
                deviceOSVersion:n['oscpu'] || '?'

            }
        }
        */
        return {
            id: this.id || null,
            name: this.V.store.getDeviceName(),
            remoteID: this.V.store.getDeviceUid(),
            pushRegId: this.pushRegId,
            allowPush: this.allowPush,
            pushRegIdSent: this.pushRegIdSent,
            deviceType: this.V.store.getDeviceType()
        };
    };
    return Device;
}(VasatModel));
/**
 * @template T
 */
var ToManyList = /** @class */ (function (_super) {
    __extends(ToManyList, _super);
    function ToManyList(V, parent, name) {
        var _this = _super.call(this, V, parent.V, parent.urlRoot()) || this;
        _this.parent = parent;
        _this.name = name;
        return _this;
    }
    /**
     * @return {?}
     */
    ToManyList.prototype._updateURL = /**
     * @return {?}
     */
    function () {
        this.searchUrl = ((/** @type {?} */ (this.parent)))._objType + '/' + this.parent.id + '.' + this.name;
    };
    /**
     * @param {?} ob
     * @param {?} sr
     * @return {?}
     */
    ToManyList.prototype._query = /**
     * @param {?} ob
     * @param {?} sr
     * @return {?}
     */
    function (ob, sr) {
        this._updateURL();
        return _super.prototype._query.call(this, ob, sr);
    };
    /**
     * @param {?} obj
     * @return {?}
     */
    ToManyList.prototype.add = /**
     * @param {?} obj
     * @return {?}
     */
    function (obj) {
        this.addObserveable(obj).subscribe();
    };
    /**
     * @param {?} obj
     * @return {?}
     */
    ToManyList.prototype.addObserveable = /**
     * @param {?} obj
     * @return {?}
     */
    function (obj) {
        var _this = this;
        this._updateURL();
        return this.V.api(this.V.pathPrefix + '/' + this.searchUrl + '/' + obj.id, 'post', { id: obj.id }).pipe(operators.map(function (r) {
            if (!_this.resultCache.find(function (a) { return a == obj; })) {
                _this.resultCache.push(obj);
                _this._resSubject.next(_this.resultCache);
            }
            return r;
        }));
    };
    /**
     * @param {?} obj
     * @return {?}
     */
    ToManyList.prototype.remove = /**
     * @param {?} obj
     * @return {?}
     */
    function (obj) {
        this.removeObservable(obj).subscribe();
    };
    /**
     * @param {?} obj
     * @return {?}
     */
    ToManyList.prototype.removeObservable = /**
     * @param {?} obj
     * @return {?}
     */
    function (obj) {
        var _this = this;
        this._updateURL();
        return this.V.api(this.V.pathPrefix + '/' + this.searchUrl + '/' + obj.id, 'delete').pipe(operators.map(function (r) {
            if (_this.resultCache.find(function (a) { return a == obj; })) {
                _this.resultCache = _this.resultCache.filter(function (a) { return a != obj; });
                _this._resSubject.next(_this.resultCache);
            }
            return r;
        }));
    };
    return ToManyList;
}(VasatSearchQuery));
/**
 * @param {?} _target
 * @return {?}
 */
function AsToManyList(_target) {
    /** @type {?} */
    var f = function (proto, fieldName, descriptor) {
        if (!proto.__manyToManys) {
            // create list of fields to cast
            proto.__manyToManys = {};
            // preserve the methods actual setter
            proto.__actualInit = proto.setVals;
            proto.setVals = function (a) {
                var _this = this;
                /** @type {?} */
                var v = this.__actualInit.bind(this)(a);
                Object.keys(this.__manyToManys).forEach(function (m) {
                    return _this[m] = new ToManyList(proto.__manyToManys[m], ((/** @type {?} */ (_this))), m);
                });
            };
        }
        proto.__manyToManys[fieldName] = _target;
    };
    return f;
}
/**
 * @param {?} _target
 * @return {?}
 */
function AsClass(_target) {
    // this is the decorator
    /** @type {?} */
    var f = function (proto, fieldName, descriptor) {
        if (!proto.__postCast) {
            // create list of fields to cast
            proto.__postCast = {};
            // preserve the methods actual setter
            proto.__actualSet = proto.set;
            // give class new setter that casts
            proto.set = function (a) {
                var _this = this;
                // do old setting
                /** @type {?} */
                var v = this.__actualSet.bind(this)(a);
                // then go through registered castTypes
                Object.keys(this.__postCast).filter(function (i) { return a[i]; }).forEach(function (i) {
                    /** @type {?} */
                    var suppliedObj = a[i];
                    /** @type {?} */
                    var clazz = _this.__postCast[i];
                    /** @type {?} */
                    var className = _this.V.classNameForType((/** @type {?} */ (clazz)));
                    if (clazz && suppliedObj && suppliedObj instanceof clazz) {
                        _this[i] = suppliedObj;
                    }
                    else {
                        _this[i] = _this.V.findCached(className, suppliedObj.id) || (clazz ? new clazz(_this.V).set(suppliedObj) : suppliedObj);
                    }
                });
                return v;
            };
        }
        // add this type to cast list
        proto.__postCast[fieldName] = _target;
        return descriptor;
    };
    return f;
}
var AccessGroup = /** @class */ (function (_super) {
    __extends(AccessGroup, _super);
    function AccessGroup(V) {
        return _super.call(this, V, 'AccessGroup') || this;
    }
    __decorate([
        AsToManyList(User),
        __metadata("design:type", ToManyList)
    ], AccessGroup.prototype, "users", void 0);
    return AccessGroup;
}(VasatModel));
var Permission = /** @class */ (function (_super) {
    __extends(Permission, _super);
    function Permission(V) {
        return _super.call(this, V, 'Permission') || this;
    }
    return Permission;
}(VasatModel));
var Role = /** @class */ (function (_super) {
    __extends(Role, _super);
    function Role(V) {
        return _super.call(this, V, 'Role') || this;
    }
    __decorate([
        AsToManyList(Permission),
        __metadata("design:type", ToManyList)
    ], Role.prototype, "permissions", void 0);
    return Role;
}(VasatModel));

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
var ImageURLDirective = /** @class */ (function () {
    function ImageURLDirective(el, V) {
        this.el = el;
        this.V = V;
        this.cache = true;
    }
    /**
     * @return {?}
     */
    ImageURLDirective.prototype.ngOnInit = /**
     * @return {?}
     */
    function () {
        this.updateLink();
    };
    /**
     * @param {?} changes
     * @return {?}
     */
    ImageURLDirective.prototype.ngOnChanges = /**
     * @param {?} changes
     * @return {?}
     */
    function (changes) {
        this.updateLink();
    };
    /**
     * @return {?}
     */
    ImageURLDirective.prototype.updateLink = /**
     * @return {?}
     */
    function () {
        /** @type {?} */
        var attr = null;
        if (this.el.nativeElement.localName == 'a')
            attr = 'href';
        else if (this.el.nativeElement.localName == 'img')
            attr = 'src';
        /** @type {?} */
        var link = null;
        if (this.model && this.model.file)
            link = this.model.file(this.link, this.cache);
        else if (this.model && this.obj_type) {
            /** @type {?} */
            var v = new VasatModel(this.V, this.obj_type, this.model);
            link = v.file(this.link, this.cache);
        }
        if (attr) {
            if (this.model && this.model.file)
                this.el.nativeElement.setAttribute(attr, link);
            else if (this.model && this.obj_type)
                this.el.nativeElement.setAttribute(attr, link);
        }
        else {
            this.el.nativeElement.style['background-size'] = 'cover';
            this.el.nativeElement.style['background-image'] = 'url(\'' + link + '\')';
        }
    };
    ImageURLDirective.decorators = [
        { type: core.Directive, args: [{
                    selector: '[vlink]'
                },] },
    ];
    /** @nocollapse */
    ImageURLDirective.ctorParameters = function () { return [
        { type: core.ElementRef },
        { type: Vasat }
    ]; };
    ImageURLDirective.propDecorators = {
        model: [{ type: core.Input }],
        link: [{ type: core.Input, args: ['vlink',] }],
        obj_type: [{ type: core.Input, args: ['obj_type',] }],
        cache: [{ type: core.Input }]
    };
    return ImageURLDirective;
}());

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
var EntityListComponent = /** @class */ (function () {
    function EntityListComponent() {
    }
    /**
     * @return {?}
     */
    EntityListComponent.prototype.ngOnInit = /**
     * @return {?}
     */
    function () {
        var _this = this;
        this.isToMany = this.query instanceof ToManyList;
        this.query.queryObservable().subscribe(function (s) {
            _this.results = s;
        });
    };
    EntityListComponent.decorators = [
        { type: core.Component, args: [{
                    selector: '[vlist]',
                    styles: [],
                    template: "<ng-container *ngIf=\"template\" [ngTemplateOutlet]=\"template\" [ngTemplateOutletContext]=\"{self:this,query:query,results:results}\">\n  \n</ng-container>\n<div *ngIf=\"!template\">\n  <table class=\"full-width\">\n    <tr *ngFor=\"let cur of query.items | async\">\n      <td>{{ cur.name }}</td><td width=\"5%\" *ngIf=\"isToMany\"><button (click)=\"query.remove(cur)\">del</button></td>\n    </tr>\n  </table>\n  <div class=\"box box-default text-center\" *ngIf=\"results && results.pages > 1\">\n              \n    <div class=\"box-body\">\n       \n      <span>\n         \n        <select placeholder=\"Rows Per Page\" [(ngModel)]=\"results.limit\" >\n          <option *ngFor=\"let i of [5,10,15,25,50]\" [value]=\"i\">\n            {{i}} Rows\n          </option>\n        </select>\n      \n      </span>\n     \n      <button (click)=\"results.currentPage = results.currentPage - 1\" [disabled]=\"!results.currentPage\">\n        <i class=\"fa fa-arrow-left\"></i>\n      </button>\n      \n      <span>\n        <input  maxlength=\"5\" type=\"number\" [(ngModel)]=\"results.currentPageP1\">\n      </span>\n\n      <button (click)=\"results.currentPage = results.currentPage + 1\"  [disabled]=\"results.currentPage >= results.pages - 1\">\n        <i class=\"fa fa-arrow-right\"></i>\n      </button>\n\n      <span>Page {{ results.currentPageP1 }}  of {{ this.results.pages }} </span>\n    </div>\n\n  </div>\n</div>"
                },] },
    ];
    /** @nocollapse */
    EntityListComponent.ctorParameters = function () { return []; };
    EntityListComponent.propDecorators = {
        query: [{ type: core.Input, args: ['vlist',] }],
        template: [{ type: core.ContentChild, args: [core.TemplateRef,] }]
    };
    return EntityListComponent;
}());

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */

exports.Vasat = Vasat;
exports.VasatConfig = VasatConfig;
exports.VasatStorage = VasatStorage;
exports.VasatAPICall = VasatAPICall;
exports.VasatError = VasatError;
exports.VasatModel = VasatModel;
exports.VasatSearchQuery = VasatSearchQuery;
exports.SearchResults = SearchResults;
exports.ToManyList = ToManyList;
exports.AsClass = AsClass;
exports.AccessGroup = AccessGroup;
exports.AsToManyList = AsToManyList;
exports.User = User;
exports.Device = Device;
exports.Triggerable = Triggerable;
exports.Role = Role;
exports.Permission = Permission;
exports.ImageURLDirective = ImageURLDirective;
exports.EntityListComponent = EntityListComponent;

Object.defineProperty(exports, '__esModule', { value: true });

})));
