///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class FeetFootFeatFortFart {

	///////////////////////////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////// FUNCTION FOR ANIMATION
	createFootSequence(instr, beatDuration): {} {
		// Please repeat each instruction by the multiplier before returning
		// type: Always floor_foot
		// foot: left or right
		// pos: relative (adjust foot by given values) or absolute (adjust foot to absolute value)
		// rotate: in degrees
		// x: in width of foot (Value of 1 would be "move the foot exactly 1 foot's width right)
		// y: in height of foot (Value of 0.5 would be "move the foot exactly half a foot's height up)
		// The feet are 1/7 of the total height of the container. 3 up, 3 down.
		// for (let a = 0; a < instr.multiplier; a++) {

		// x == right
		// y == down

		// create the sequence
		let seq = [];
		let init = [];

		// check animation type
		switch (instr.animation_name) {

			case ("Turn_Sp"):
				{
					this.Turn_Sp(instr, seq, init, beatDuration);
					break;
				}
			case ("W_Turn_Sp_HW"):
				{
					this.W_Turn_Sp_HW(instr, seq, init, beatDuration);
					break;
				}
			case ("Walk_Si_S"):
				{
					this.Walk_Si_S(instr, seq, init, beatDuration);
					break;
				}
			case ("Walk_Si_L"):
				{
					this.Walk_Si_L(instr, seq, init, beatDuration);
					break;
				}
			case ("W_Turn_Sp_NT"):
				{
					this.W_Turn_Sp_NT(instr, seq, init, beatDuration);
					break;
				}
			case ("W_U_Turn_HW"):
				{
					this.W_U_Turn_HW(instr, seq, init, beatDuration);
					break;
				}
			case ("W_Fig_8_HW"):
				{
					this.W_Fig_8_HW(instr, seq, init, beatDuration);
					break;
				}
			case ("Mar_Turn"):
				{
					this.Mar_Turn(instr, seq, init, beatDuration);
					break;
				}
			case ("Mar_F_On_Sp_Turn"):
				{
					this.Mar_F_On_Sp_Turn(instr, seq, init, beatDuration);
					break;
				}
			case ("W_U_Turn_NT"):
				{
					this.W_U_Turn_NT(instr, seq, init, beatDuration);
					break;
				}
			case ("W_Fig_8_NT"):
				{
					this.W_Fig_8_NT(instr, seq, init, beatDuration);
					break;
				}
			case ("W_Fig_8_Ch_Dir_HW"):
				{
					this.W_Fig_8_Ch_Dir_HW(instr, seq, init, beatDuration);
					break;
				}
			case ("Mar_U_Turn"):
				{
					this.Mar_U_Turn(instr, seq, init, beatDuration);
					break;
				}
			case ("W_Turn_Sp_T"):
				{
					this.W_Turn_Sp_T(instr, seq, init, beatDuration);
					break;
				}
			case ("Pivot_Sh"):
				{
					this.Pivot_Sh(instr, seq, init, beatDuration);
					break;
				}
			case ("W_U_Turn_T"):
				{
					this.W_U_Turn_T(instr, seq, init, beatDuration);
					break;
				}
			case ("W_Fig_8_Ch_Dir_NT"):
				{
					this.W_Fig_8_Ch_Dir_NT(instr, seq, init, beatDuration);
					break;
				}
			case ("Mar_Fig_8"):
				{
					this.Mar_Fig_8(instr, seq, init, beatDuration);
					break;
				}
			case ("Mar_Fig_8_Ch_Dir"):
				{
					this.Mar_Fig_8_Ch_Dir(instr, seq, init, beatDuration);
					break;
				}
			case ("W_Fig_8_T"):
				{
					this.W_Fig_8_T(instr, seq, init, beatDuration);
					break;
				}
			case ("Pivot_Lo"):
				{
					this.Pivot_Lo(instr, seq, init, beatDuration);
					break;
				}
			case ("W_Fig_8_Ch_Dir_T"):
				{
					this.W_Fig_8_Ch_Dir_T(instr, seq, init, beatDuration);
					break;
				}
			case ("W_B_NT"):
				{
					this.W_B_NT(instr, seq, init, beatDuration);
					break;
				}
			case ("W_B_T"):
				{
					this.W_B_T(instr, seq, init, beatDuration);
					break;
				}
			case ("si_to_si_step_cardio"):
				{
					this.si_to_si_step_cardio(instr, seq, init, beatDuration);
					break;
				}
		}

		return { seq: seq, init: init };
	}



	//------------------------------------------------------------------------------
	// Marches
	private Mar_F_On_Sp_Turn(instr: any, seq: any[], init: any[], beatDuration: any) {
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'right', pos: 'absolute', rotate: -0.036, x: 0.8643112, y: 2.951959, durationInSecs: beatDuration, beep: true });
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'left', pos: 'absolute', rotate: -0.036, x: 0.5085702, y: 2.960495, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -1.934, x: 0.8565585, y: 2.004766, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -1.934, x: 0.4033285, y: 0.936048, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 0.236, x: 0.724132, y: -0.09212169, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 0.236, x: 0.3662635, y: -1.18408, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -81.401, x: -0.07344628, y: -2.188656, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -112.78, x: 0.7598869, y: -1.607891, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -153.103, x: -0.8644068, y: -2.05672, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -179.019, x: 1.706215, y: -1.632552, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -181.268, x: -0.6704524, y: -1.062027, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -181.268, x: 1.760848, y: -0.07854043, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -184.228, x: -0.7265884, y: 1.149572, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -184.228, x: 1.816984, y: 2.099503, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -181.098, x: -0.7429204, y: 3.004198, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -181.098, x: 1.918062, y: 3.093212, durationInSecs: beatDuration, beep: true });
	}

	private Mar_Fig_8(instr: any, seq: any[], init: any[], beatDuration: any) {
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'right', pos: 'absolute', rotate: 718.879, x: 0.7514125, y: 0.01233046, durationInSecs: beatDuration, beep: true });
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'left', pos: 'absolute', rotate: 717.117, x: 0.3954801, y: 0.03082614, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 637.636, x: -2.553672, y: -1.257707, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 606.205, x: -2.457627, y: -0.215783, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 539.81, x: -6.649717, y: 0.5178791, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 492.447, x: -2.887005, y: 0.7669544, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 430.653, x: -1.112994, y: 1.812577, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 399.702, x: 0.2542372, y: 0.487053, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 413.462, x: 2.305085, y: 0.1726264, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 415.139, x: 3.079096, y: -0.918619, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 504.056, x: 4.875706, y: 0.3822441, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 494.802, x: 7.344632, y: -0.4377312, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 574.376, x: 5.355931, y: 1.07275, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 582.369, x: 7.655366, y: 1.929716, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 658.599, x: 2.305085, y: 1.08508, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 679.226, x: 0.7310683, y: 1.323801, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 720.382, x: 0.5254238, y: 0.02466091, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 721.221, x: -0.1694916, y: 0.01849568, durationInSecs: beatDuration, beep: true });
	}


	private Mar_Fig_8_Ch_Dir(instr: any, seq: any[], init: any[], beatDuration: any) {
		init.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -361.4, x: 0.7514125, y: 0.01233046, durationInSecs: beatDuration, beep: true });
		init.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -361.6, x: 0.3954802, y: 0.03082614, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -442, x: -2.248167, y: -0.8970174, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -474.3, x: -4.067797, y: -0.4747226, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -542.602, x: -6.536723, y: 0.1602959, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -655.4, x: -3.361582, y: 0.9432799, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -651.8, x: -1.451977, y: 1.183724, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -689.8, x: 0.5287946, y: 0.01877188, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -683.6, x: 1.516969, y: 0.1812157, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -657.8, x: 3.079096, y: -0.918619, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -604.3, x: 3.947864, y: -0.4715602, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -584.2, x: 7.570621, y: -0.4377312, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -507.81, x: 5.666667, y: 0.3699137, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -454.8, x: 5.139995, y: 1.067045, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -408.344, x: 3.152542, y: 0.4562269, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -454.8, x: 2.175141, y: 0.487053, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -391.972, x: 1.683616, y: 0.09864365, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -310.886, x: 1.192285, y: -0.5136752, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -223.157, x: -0.1525424, y: -0.135635, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -213.856, x: 3.19209, y: 0.05548705, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -244.64, x: 1.462243, y: 0.8761821, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -236.718, x: 5.222032, y: 0.7358304, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -302.844, x: 4.17907, y: 1.400222, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -338.043, x: 5.860568, y: 0.3546922, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -378.418, x: 6.184605, y: -0.0451773, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -413.616, x: 4.993602, y: -0.5798506, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -447.299, x: 3.945711, y: -1.077996, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -482.497, x: 2.974107, y: -0.3666787, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -506.427, x: 0.2692589, y: -0.4540948, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -488.674, x: 0.8313186, y: 0.7150154, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -458.551, x: -2.094725, y: 0.4519123, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -440.797, x: -3.34359, y: 1.100381, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -404.117, x: -3.663483, y: 0.636353, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -366.677, x: -4.581377, y: 0.2361695, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -301.28, x: -4.537743, y: -0.4257323, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -263.84, x: -2.176427, y: -1.130448, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -248.846, x: -1.844946, y: -0.3732014, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -211.407, x: 1.29832, y: -0.3246374, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -180.61, x: -0.3784328, y: -0.06835636, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -180.676, x: 2.316286, y: -0.06112245, durationInSecs: beatDuration, beep: true });
	}




	private Mar_Turn(instr: any, seq: any[], init: any[], beatDuration: any) {
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'right', pos: 'absolute', rotate: 0, x: 0.7514125, y: 0.01233046, durationInSecs: beatDuration, beep: true });
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'left', pos: 'absolute', rotate: -360, x: 0.3954802, y: 0.03082614, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 41.609, x: 0.5724208, y: 0.2126759, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -318.391, x: 0.5744718, y: -0.1695193, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 82.505, x: 0.1501317, y: 0.306363, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -277.495, x: 0.996761, y: -0.2632064, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 110.048, x: -0.1700486, y: 0.2885168, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -249.952, x: 1.316941, y: -0.2453602, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 136.382, x: -0.4254592, y: 0.2127079, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -223.618, x: 1.572352, y: -0.1695513, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 172.377, x: -0.5999964, y: 0.04646311, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -187.623, x: 1.746889, y: -0.00330651, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 146.622, x: -0.4991246, y: 0.1707995, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -213.378, x: 1.646017, y: -0.1276429, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 117.512, x: -0.2501814, y: 0.2723696, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -242.488, x: 1.397074, y: -0.229213, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 88.106, x: 0.08402496, y: 0.3082257, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -271.894, x: 1.062868, y: -0.2650691, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 63.615, x: 0.3640858, y: 0.2796904, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -296.385, x: 0.7828069, y: -0.2365338, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 32.282, x: 0.6402156, y: 0.1747239, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -327.718, x: 0.5066771, y: -0.1315673, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -3.767, x: 0.750622, y: -0.001954856, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -363.767, x: 0.3962707, y: 0.04511145, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -23.788, x: 0.6984912, y: -0.1022911, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -383.788, x: 0.4484015, y: 0.1454477, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -60.886, x: 0.4135915, y: -0.243659, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -420.886, x: 0.7333012, y: 0.2868156, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -100.84, x: -0.04241437, y: -0.2792464, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -460.84, x: 1.189307, y: 0.3224029, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -125.571, x: -0.3112484, y: -0.2313454, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -485.571, x: 1.458141, y: 0.274502, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -140.473, x: -0.4418792, y: -0.1799649, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -500.473, x: 1.588772, y: 0.2231215, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -182.494, x: -0.604287, y: 0.02004527, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -542.494, x: 1.75118, y: 0.02311133, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -136.909, x: -0.4134909, y: -0.1935782, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -496.909, x: 1.560384, y: 0.2367347, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -99.72101, x: -0.02935216, y: -0.2801785, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -459.721, x: 1.176245, y: 0.3233351, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -71.264, x: 0.3023869, y: -0.2662183, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -431.264, x: 0.8445058, y: 0.3093749, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -44.592, x: 0.5644725, y: -0.1917226, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -404.592, x: 0.5824202, y: 0.2348792, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -19.278, x: 0.717207, y: -0.08048513, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -379.278, x: 0.4296857, y: 0.1236417, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -0.504, x: 0.7513927, y: 0.01489002, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -360.504, x: 0.3955, y: 0.02826658, durationInSecs: beatDuration, beep: true });
	}


	private Mar_U_Turn(instr: any, seq: any[], init: any[], beatDuration: any) {
		init.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 0, x: 0.8644068, y: 2.540074, durationInSecs: beatDuration, beep: true });
		init.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 0, x: 0.5084745, y: 2.55857, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 0, x: 0.8361583, y: 1.553638, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 0, x: 0.4237288, y: 0.4993835, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 0, x: 0.7514125, y: -0.5425401, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 0, x: 0.338983, y: -1.596794, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 0, x: 0.6949153, y: -2.009864, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -51.09, x: -0.2542374, y: -2.31196, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -99.8, x: -2.508971, y: -2.836878, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -149.7, x: -1.949152, y: -1.929716, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -178.2, x: -3.99435, y: -1.787916, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -179.8, x: -1.538588, y: -1.115906, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -178.2, x: -4.150678, y: -0.3822441, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -179.8, x: -1.694915, y: 0.2897657, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -178.2, x: -4.333333, y: 1.34402, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -179.8, x: -1.80791, y: 1.374846, durationInSecs: beatDuration, beep: true });
	}


	//------------------------------------------------------------------------------
	// Pivots
	private Pivot_Lo(instr: any, seq: any[], init: any[], beatDuration: any) {
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'right', pos: 'absolute', rotate: -1.38, x: 0.7514125, y: 0.01233046, durationInSecs: beatDuration, beep: true });
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'left', pos: 'absolute', rotate: -1.38, x: 0.3954802, y: 0.03082614, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -6, x: 3.661017, y: -0.02342787, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -1.09, x: 0.3954802, y: 0.03082614, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -88.7, x: -0.5282485, y: -1.87053, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -87.9, x: 0.2824858, y: 0.0431566, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -182.7, x: -5.00565, y: 0.07274969, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -182.5, x: 0.3954802, y: 0.03082614, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -269, x: -0.7259887, y: 1.779285, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -270.1, x: 0.3954802, y: 0.01849568, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -363.7, x: 3.432203, y: -0.03945746, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -363.9, x: 0.2259886, y: 0.05548705, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -363.9, x: 1.20339, y: 0, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -363.9, x: 0.3954802, y: 0.05548705, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -272, x: 1.158467, y: 0.06184438, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -272.9, x: 2.245763, y: -1.738594, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -182.6, x: 1.061594, y: 0.04803188, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -182.1, x: 6.471752, y: 0.07891492, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -90.8, x: 1.168658, y: 0.03808996, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -90.9, x: 1.977401, y: 1.743526, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -6.8, x: 1.161395, y: -0.03080695, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -6.11, x: -1.579096, y: 0.07274969, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -5.94, x: 1.161395, y: -0.03080695, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -6.11, x: 0.3672316, y: 0.03082614, durationInSecs: beatDuration, beep: true });
	}


	private Pivot_Sh(instr: any, seq: any[], init: any[], beatDuration: any) {
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'right', pos: 'absolute', rotate: -1.33, x: 2.022599, y: 0, durationInSecs: beatDuration, beep: true });
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'left', pos: 'absolute', rotate: -1.33, x: 0.3954802, y: 0.03082614, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -93.57201, x: -0.7072675, y: -1.096056, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -93.57201, x: 0.4237288, y: 0.06781751, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -167.617, x: -3.361198, y: -0.1242257, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -167.617, x: 0.1977401, y: 0.1541307, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -266.385, x: -1.198311, y: 1.16819, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -266.385, x: -5.38799E-08, y: 0.0431566, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -357.14, x: 1.367932, y: 0.1084722, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -357.14, x: -0.2542374, y: 0.05548705, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -357.14, x: 0.3841808, y: 0.07398274, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -357.14, x: -0.2542374, y: 0.05548705, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -357.14, x: 0.3841808, y: 0.07398274, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -357.14, x: -2.00565, y: 0.01849568, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -261.463, x: 0.586365, y: 0.1210582, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -261.463, x: 2.090395, y: -1.325524, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -183.602, x: 0.4996356, y: -0.005632394, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -183.602, x: 4.887006, y: -0.08014797, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -97.72501, x: 1.397051, y: 0.0485792, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -97.72501, x: 2.853107, y: 1.535142, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -3.996, x: 1.849099, y: 0.06946907, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -3.996, x: -0.5367233, y: 0.1911221, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -2.884, x: 1.316384, y: 0.0863132, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 3.207, x: 0.1412429, y: 0.08014797, durationInSecs: beatDuration, beep: true });
	}


	//------------------------------------------------------------------------------
	// Turn

	private Turn_Sp(instr: any, seq: any[], init: any[], beatDuration: any) {

		if (instr.floorSequence[0].multiplier == 4) {
			init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'right', pos: 'absolute', rotate: 362.475, x: 1.033905, y: 0.0244532, durationInSecs: beatDuration, beep: true });
			init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'left', pos: 'absolute', rotate: 357.005, x: 0.2172661, y: 0.03005549, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 404.491, x: 0.8154321, y: 0.2760001, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 399.021, x: 0.4855156, y: -0.2458452, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 449.946, x: 0.1440334, y: 0.4157578, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 444.477, x: 1.093396, y: -0.3585036, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 496.97, x: -0.6040404, y: 0.2818025, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 491.5, x: 1.690224, y: -0.2562875, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 542.82, x: -0.8650915, y: -0.004715737, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 537.35, x: 1.951339, y: 0.03144552, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 499.58, x: -0.6285769, y: 0.2703278, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 494.111, x: 1.722412, y: -0.2414614, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 453.142, x: 0.01680094, y: 0.399486, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 447.672, x: 1.06747, y: -0.3747754, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 412.656, x: 0.5908589, y: 0.3181655, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 407.187, x: 0.4502394, y: -0.2806458, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 361.945, x: 0.9393748, y: 0.00805047, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 356.475, x: 0.1225427, y: 0.02099203, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 313.272, x: 0.6682098, y: -0.3000424, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 307.802, x: 0.4588912, y: 0.3103235, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 269.629, x: -0.00933383, y: -0.4005607, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 264.16, x: 1.051358, y: 0.4105574, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 231.178, x: -0.6184061, y: -0.2684471, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 225.709, x: 1.558312, y: 0.354311, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 184.177, x: -0.9203784, y: 0.008021871, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 178.707, x: 1.894588, y: 0.06295276, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 229.389, x: -0.6782757, y: -0.2558246, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 223.92, x: 1.541081, y: 0.350603, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 271.301, x: -0.05469812, y: -0.3626399, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 265.831, x: 0.9529949, y: 0.4489147, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 302.818, x: 0.4359917, y: -0.3040935, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 297.349, x: 0.4927554, y: 0.3922243, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 364.229, x: 0.8886381, y: 0.06365794, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 358.759, x: 0.07375248, y: 0.04499141, durationInSecs: beatDuration, beep: true });
		} else if (instr.floorSequence[0].multiplier == 6) {
			init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'right', pos: 'absolute', rotate: 0, x: 0.933905, y: 0.0214532, durationInSecs: beatDuration, beep: true });
			init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'left', pos: 'absolute', rotate: 355.466, x: 0.1320979, y: 0.02718209, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 28.62, x: 0.8550498, y: 0.2005287, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 383.151, x: 0.2373094, y: -0.1419688, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 51.049, x: 0.6251697, y: 0.3193222, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 405.58, x: 0.4453427, y: -0.2652862, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 76.049, x: 0.2335878, y: 0.3862712, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 430.579, x: 0.7482202, y: -0.359467, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 96.302, x: -0.06483099, y: 0.4009679, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 450.833, x: 1.085903, y: -0.3708604, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 148.452, x: -0.7204607, y: 0.2189454, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 502.982, x: 1.801702, y: -0.1955556, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 181.564, x: -0.9286699, y: 0.002363137, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 536.095, x: 1.888202, y: 0.02115007, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 144.813, x: -0.7439592, y: 0.2456563, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 499.344, x: 1.712198, y: -0.210131, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 94.49901, x: -0.06380684, y: 0.4164065, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 449.029, x: 1.029884, y: -0.3571038, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 57.941, x: 0.4625541, y: 0.3500739, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 412.471, x: 0.4570446, y: -0.2919655, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 31.329, x: 0.7634557, y: 0.2176058, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 385.859, x: 0.1866175, y: -0.1578553, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -0.259, x: 0.8911674, y: 0.01618147, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 354.271, x: 0.07520077, y: 0.05961803, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -30.449, x: 0.7358657, y: -0.191898, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 324.081, x: 0.1949486, y: 0.2467657, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -49.993, x: 0.5319186, y: -0.2973195, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 304.538, x: 0.4017888, y: 0.34214, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -83.741, x: 0.05529791, y: -0.387583, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 270.79, x: 0.9059185, y: 0.4212848, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -125.529, x: -0.5264378, y: -0.3087212, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 229.001, x: 1.56881, y: 0.3425299, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -133.808, x: -0.6330355, y: -0.2700787, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 220.723, x: 1.659509, y: 0.3057432, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -166.684, x: -0.8908073, y: -0.07641286, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 187.846, x: 1.887817, y: 0.103887, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -178.82, x: -0.9081524, y: 0.002344322, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 175.711, x: 1.90859, y: 0.01581495, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -154.776, x: -0.8315395, y: -0.1649763, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 199.755, x: 1.832523, y: 0.1719528, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -123.592, x: -0.5291792, y: -0.3207145, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 230.938, x: 1.51703, y: 0.3462417, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -97.151, x: -0.1318507, y: -0.3845463, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 257.379, x: 1.143564, y: 0.4179125, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -62.94, x: 0.4271054, y: -0.3502553, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 291.59, x: 0.6453915, y: 0.3842001, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -35.591, x: 0.7416054, y: -0.2336916, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 318.94, x: 0.294017, y: 0.2640467, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 0.969, x: 0.9116211, y: 0.007610956, durationInSecs: beatDuration, beep: true });
			seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 355.5, x: 0.09487685, y: 0.03474187, durationInSecs: beatDuration, beep: true });

		}
	}

	//------------------------------------------------------------------------------
	//------------------------------------------------------------------------------

	//------------------------------------------------------------------------------
	// Walks - Backward

	private W_B_NT(instr: any, seq: any[], init: any[], beatDuration: any) {
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'right', pos: 'absolute', rotate: -1, x: 0.581921, y: -2.022195, durationInSecs: beatDuration, beep: true });
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'left', pos: 'absolute', rotate: -1, x: 0.3107344, y: -1.387176, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -1, x: 0.6384181, y: -0.9247842, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -1, x: 0.3672316, y: -0.2897657, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -1, x: 0.6666667, y: -0.01233046, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -1, x: 0.3954802, y: 0.6226881, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -1, x: 0.7514125, y: 0.998767, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -1, x: 0.4802259, y: 1.633785, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -1, x: 0.7796611, y: 2.342787, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -1, x: 0.4802259, y: 2.336622, durationInSecs: beatDuration, beep: true });
	}

	private W_B_T(instr: any, seq: any[], init: any[], beatDuration: any) {
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'right', pos: 'absolute', rotate: 719, x: 0.4124294, y: -2.811344, durationInSecs: beatDuration, beep: true });
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'left', pos: 'absolute', rotate: 719, x: 0.3107344, y: -1.732429, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 719.254, x: 0.5254238, y: -0.974106, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 719.254, x: 0.398305, y: 0.1060419, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 719.319, x: 0.5819209, y: 1.023428, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 719.254, x: 0.477401, y: 2.097411, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 718.707, x: 0.8079096, y: 2.078915, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 719.254, x: 0.477401, y: 2.097411, durationInSecs: beatDuration, beep: true });
	}


	//------------------------------------------------------------------------------
	// Walks - Fig 8
	private W_Fig_8_Ch_Dir_HW(instr: any, seq: any[], init: any[], beatDuration: any) {
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'right', pos: 'absolute', rotate: 1.013, x: 1.909481, y: -0.0006092227, durationInSecs: beatDuration, beep: true });
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'left', pos: 'absolute', rotate: -2.378, x: 0.3960836, y: 0.04428435, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 1.013, x: 2.376091, y: -0.4306787, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 54.354, x: 1.638418, y: -1.066584, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 90.401, x: 3.548022, y: -0.4932182, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 105.525, x: 5.706214, y: -1.510481, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 146.21, x: 4.872732, y: -0.2975633, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 169.674, x: 8.531074, y: -0.1418002, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 215.443, x: 4.635205, y: 0.3661677, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 238.907, x: 6.195029, y: 1.525942, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 259.275, x: 3.202115, y: 0.5814511, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 282.739, x: 2.610129, y: 1.46765, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 304.172, x: 1.914041, y: 0.2368352, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 327.636, x: 0.2186127, y: 0.5560551, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 318.993, x: 0.1690595, y: -1.092154, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 302.277, x: -1.390266, y: -0.5310307, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 232.458, x: -5.691805, y: -1.424111, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 207.444, x: -3.306798, y: -0.4217913, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 169.105, x: -7.306076, y: 0.4277443, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 144.091, x: -3.073143, y: 0.5472836, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 83.774, x: -2.060632, y: 1.569928, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 58.761, x: -0.9186853, y: 0.3985671, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -1.445, x: 1.254228, y: 0.04157051, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -1.77, x: -0.3109518, y: 0.07410127, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -55.325, x: 0.8147063, y: -0.3154934, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -55.65, x: 0.2081807, y: 0.6819294, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -121.37, x: -0.2873594, y: -0.3288305, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -121.695, x: 2.051526, y: 0.666344, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -181.187, x: -0.7749441, y: 0.05904079, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -181.512, x: 3.051739, y: 0.06243008, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -133.505, x: -2.180541, y: 0.4962277, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -105.162, x: -1.626289, y: 1.705682, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -65.29301, x: -4.138357, y: 0.6876124, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -36.95, x: -5.857183, y: 0.9722674, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 21.726, x: -5.065529, y: -0.004789867, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 50.07, x: -5.019357, y: -1.25805, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 82.07101, x: -3.529561, y: -0.5565902, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 110.414, x: -0.5062083, y: -1.472902, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 118.741, x: -1.405088, y: -0.1646756, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 131.297, x: 2.718716, y: -0.4850707, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 116.377, x: 1.043503, y: 1.1832, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 84.65, x: 4.728114, y: 0.6103081, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 53.319, x: 4.939601, y: 1.503784, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 21.592, x: 5.819014, y: 0.1422721, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -5.842, x: 7.218763, y: -0.1795337, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -37.569, x: 5.229249, y: -0.674239, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -65.79, x: 5.290309, y: -1.316442, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -97.51701, x: 3.505558, y: -0.5132458, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -151.353, x: -1.019749, y: -0.7838258, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -145.35, x: 1.856988, y: 0.2117521, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -183.656, x: -1.791002, y: 0.08268834, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -177.653, x: 1.778926, y: 0.072353, durationInSecs: beatDuration, beep: true });
	}

	private W_Fig_8_Ch_Dir_NT(instr: any, seq: any[], init: any[], beatDuration: any) {
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'right', pos: 'absolute', rotate: 358.711, x: 0.7514116, y: 0.01178445, durationInSecs: beatDuration, beep: true });
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'left', pos: 'absolute', rotate: 358.711, x: 0.395481, y: 0.03137214, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 350.701, x: 0.5254238, y: 0.01233046, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 329.583, x: 0.07627115, y: -0.3945746, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 318.187, x: -0.1807909, y: -0.7891492, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 282.096, x: -0.8333334, y: -0.6387176, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 258.468, x: -3.005649, y: -1.159063, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 242.558, x: -3.081921, y: -0.4796548, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 200.286, x: -5.548022, y: -0.8138101, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 182.716, x: -3.765537, y: 0.001233044, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 148.202, x: -6.203751, y: 0.4685573, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 132.9, x: -3.384181, y: 0.6942047, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 111.831, x: -4.740113, y: 1.229346, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 91.261, x: -1.875038, y: 0.8166419, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 63.952, x: -2.855932, y: 1.276202, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 40.442, x: -1.20904, y: 0.4673243, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 50.468, x: -1.358757, y: 0.6769421, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 58.544, x: 0.1397635, y: -0.08055098, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 49.699, x: 0.01694922, y: 0.135635, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 49.699, x: 1.275422, y: -0.5281204, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 67.589, x: 1.99435, y: -0.5055487, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 67.589, x: 3.705797, y: -1.143615, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 91.72401, x: 3.502825, y: -0.6399507, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 114.177, x: 6.368606, y: -0.8625171, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 151.553, x: 4.892655, y: -0.2416769, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 151.109, x: 7.51482, y: 0.05531772, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 210.234, x: 5.101694, y: 0.1233046, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 210.234, x: 6.317107, y: 0.9593007, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 266.971, x: 4.112994, y: 0.6781751, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 266.971, x: 3.766619, y: 1.226531, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 317.293, x: 2.502877, y: 0.7151664, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 317.293, x: 1.823974, y: 0.6234083, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 311.687, x: 1.457627, y: 0.2219482, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 311.687, x: 0.6131506, y: 0.1104853, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 362.789, x: 1.258394, y: 0.02419092, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 362.789, x: 0.7359529, y: -0.005695235, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 417.02, x: 0.9167384, y: 0.2782977, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 417.02, x: 1.077611, y: -0.2598028, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 483.389, x: 0.06088763, y: 0.2733547, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 483.389, x: 1.933461, y: -0.2548598, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 536.481, x: -0.2645099, y: 0.02123436, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 536.481, x: 2.258857, y: -0.002739809, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 504.616, x: 0.2765521, y: 0.3822441, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 504.616, x: 2.880501, y: 0.6258096, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 460.035, x: 2.161017, y: 1.183724, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 435.018, x: 4.528248, y: 0.6189889, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 407.666, x: 4.562146, y: 0.9099877, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 407.666, x: 5.890335, y: 0.2111624, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 343.795, x: 6.259886, y: -0.2466091, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 343.795, x: 5.8261, y: -0.6760347, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 247.356, x: 3.949152, y: -1.304562, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 247.356, x: 3.960436, y: -0.5644236, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 208.615, x: 1.514124, y: -0.4969174, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 222.379, x: 2.523403, y: 0.3631874, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 245.526, x: 0.5536723, y: 0.07398274, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 245.526, x: 0.4934881, y: 0.7851155, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 251.364, x: -1.960452, y: 0.4181529, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 251.364, x: -1.903621, y: 1.107751, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 267.104, x: -3.118644, y: 0.5807645, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 283.385, x: -3.749601, y: 1.055498, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 303.839, x: -4.37288, y: 0.5992602, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 351.901, x: -5.122662, y: 0.2699571, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 384.185, x: -5.121469, y: 0.1430333, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 384.185, x: -4.401482, y: -0.6988932, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 404.431, x: -4.330508, y: -0.3822441, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 421.422, x: -2.550485, y: -1.13458, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 458.234, x: -3.203389, y: -0.5795314, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 466.128, x: -0.5780116, y: -0.8687997, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 491.369, x: -1.423729, y: -0.2589396, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 490.557, x: 1.260467, y: -0.1824146, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 475.989, x: -0.3192381, y: 0.2472176, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 412.641, x: 0.5586587, y: -0.5497134, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 360.941, x: 0.751059, y: 0.02186654, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 360.941, x: 0.3958336, y: 0.02129006, durationInSecs: beatDuration, beep: true });
	}




	private W_Fig_8_Ch_Dir_T(instr: any, seq: any[], init: any[], beatDuration: any) {
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'right', pos: 'absolute', rotate: -5.872, x: 0.7514125, y: 0.01233046, durationInSecs: beatDuration, beep: true });
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'left', pos: 'absolute', rotate: -5.872, x: 0.3954802, y: 0.03082614, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -22.901, x: -0.01129937, y: 0.04932182, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -43.156, x: -0.7627119, y: -0.7090012, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -115.954, x: -3.5534, y: -1.026714, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -165.436, x: -3.813559, y: -0.1541307, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -221.398, x: -4.295056, y: 0.7576771, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -270.881, x: -1.073446, y: 1.078915, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -327.052, x: 0.2711865, y: 0.6041924, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -336.918, x: 2.344633, y: -0.2774353, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -282.325, x: 3.424575, y: -0.9109445, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -225.63, x: 6.497175, y: -0.6966708, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -168.917, x: 6.223716, y: 0.2216603, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -112.222, x: 5.9887, y: 0.9926017, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -78.28, x: 3.221462, y: 1.029869, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -22.8, x: 2.514124, y: 0.4993835, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -52.1, x: 2.220339, y: -0.07891484, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -144.3, x: 0.960452, y: 0.4623921, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -209.3, x: 0.4124295, y: 1.22688, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -257, x: 3.672316, y: 1.59926, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -309.676, x: 4.809047, y: 0.9568038, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -335.034, x: 6.779661, y: 0.03082614, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -410.203, x: 5.118221, y: -0.7547835, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -472.062, x: 3.926554, y: -0.6843403, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -467.157, x: 1.281848, y: -0.3123468, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -477.702, x: 0.2542372, y: 0.351418, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -467.157, x: -3.350921, y: 0.7973942, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -389.47, x: -4.265537, y: 0.5363749, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -349.446, x: -5.39508, y: -0.4505215, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -271.76, x: -2.937853, y: -1.041924, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -258.08, x: -1.373415, y: -0.7367485, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -202.928, x: 1.440678, y: -0.1294698, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -174.339, x: -0.6610169, y: 0.04932182, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -182.271, x: 2.344633, y: 0.09247842, durationInSecs: beatDuration, beep: true });
	}


	private W_Fig_8_HW(instr: any, seq: any[], init: any[], beatDuration: any) {
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'right', pos: 'absolute', rotate: 719.034, x: 1.542373, y: 0.01233046, durationInSecs: beatDuration, beep: true });
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'left', pos: 'absolute', rotate: 719.034, x: -0.5649718, y: 0.03082614, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 674.906, x: 0.4971752, y: -0.7768188, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 667.629, x: -1.186441, y: -0.4007398, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 644.599, x: -2.299435, y: -1.516646, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 640.398, x: -2.655367, y: -0.4993835, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 584.36, x: -6.19774, y: -0.9494451, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 547.492, x: -3.785311, y: 0.1294698, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 480.947, x: -5.322034, y: 1.097411, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 448.66, x: -1.779661, y: 0.4377312, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 423.511, x: 0.1581921, y: 0.8138101, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 403.282, x: 0.7062145, y: -0.3144266, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 422.327, x: 2.079096, y: -0.03699137, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 439.243, x: 3.926553, y: -1.189889, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 469.695, x: 4.169491, y: -0.3945746, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 496.411, x: 8.079095, y: -0.8076448, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 526.572, x: 5.158191, y: -0.135635, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 558.245, x: 8.38983, y: 0.8569667, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 573.173, x: 4.508474, y: 0.5178791, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 601.887, x: 5.480226, y: 1.76942, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 649.207, x: 2.474576, y: 0.4685573, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 669.308, x: 0.7344632, y: 1.103576, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 717.135, x: 1.231638, y: 0.01233046, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 717.135, x: -0.05649722, y: 0.03082614, durationInSecs: beatDuration, beep: true });
	}


	private W_Fig_8_NT(instr: any, seq: any[], init: any[], beatDuration: any) {
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'left', pos: 'absolute', rotate: -0.675, x: 0.2543252, y: -0.2343261, durationInSecs: beatDuration });
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'right', pos: 'absolute', rotate: 359.325, x: 0.6102436, y: -0.2494652, durationInSecs: beatDuration });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -28.073, x: -0.8228781, y: -0.6235211, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 331.927, x: -1.773893, y: -1.758552, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -62.468, x: -2.272535, y: -1.825262, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 272.381, x: -4.428881, y: -2.460962, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -117.851, x: -4.323117, y: -1.976292, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 209.312, x: -7.155232, y: -1.929253, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -177.994, x: -5.674794, y: -1.275372, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 158.312, x: -7.872634, y: -0.6558065, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -192.231, x: -5.737288, y: -0.113207, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 142.664, x: -7.369585, y: 0.600093, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -264.047, x: -4.643636, y: 0.4741837, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 92.79501, x: -3.95926, y: 1.089247, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -305.564, x: -2.291651, y: 0.4359822, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 54.436, x: -0.7491868, y: 0.2931268, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -316.098, x: 0.2416767, y: -0.351412, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 43.902, x: 1.689235, y: -0.6950647, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -322.366, x: 2.181188, y: -1.281394, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 65.47, x: 3.677054, y: -1.649941, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -291.008, x: 4.000816, y: -2.309442, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 94.79101, x: 4.792822, y: -1.821382, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -231.103, x: 7.150646, y: -2.440943, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 131.891, x: 6.575462, y: -1.469988, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -169.993, x: 9.263721, y: -0.5945246, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 193.858, x: 6.625652, y: 0.02073615, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -132.618, x: 8.668905, y: 0.9681485, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 239.945, x: 5.290919, y: 1.009474, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -84.72, x: 6.400517, y: 1.640251, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 286.917, x: 4.268451, y: 0.9076552, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -64.342, x: 2.72413, y: 1.448349, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 328.467, x: 1.446008, y: 0.4203343, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -3.855, x: 0.2551579, y: -0.2179098, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 356.145, x: 0.6094109, y: -0.2658815, durationInSecs: beatDuration, beep: true });
	}



	private W_Fig_8_T(instr: any, seq: any[], init: any[], beatDuration: any) {
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'left', pos: 'absolute', rotate: 355, x: 0.3446327, y: 0, durationInSecs: beatDuration, beep: true });
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'right', pos: 'absolute', rotate: 356.5, x: 1.112994, y: 0, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 302.8, x: -0.5564972, y: -0.5832306, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 274.2, x: -2.06554, y: -0.9683483, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 232.9, x: -4.757062, y: -0.8754624, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 206.5, x: -5.560076, y: 0.004988175, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 147.6, x: -5.80226, y: 0.5425401, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 118.1, x: -2.935711, y: 1.396826, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 68.1, x: -1.9887, y: 1.183724, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 39.5, x: 0.7344632, y: 0.4623921, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 39, x: 0.8079097, y: -0.135635, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 78.3, x: 3.757062, y: -0.7706535, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 130.3, x: 5.045197, y: -0.6041924, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 172.5, x: 7.542373, y: 0.2774353, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 246.7, x: 5.949152, y: 1.023428, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 281.1, x: 4.689266, y: 1.263872, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 306.1, x: 1.457627, y: 0.9371147, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 331, x: 0.6336018, y: 0.4318937, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 354.8, x: 1.271187, y: 0.09617756, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 356.4, x: 0.4293785, y: 0.05918619, durationInSecs: beatDuration, beep: true });
	}



	//------------------------------------------------------------------------------
	//  Walks - Turn_SP

	private W_Turn_Sp_HW(instr: any, seq: any[], init: any[], beatDuration: any) {
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'right', pos: 'absolute', rotate: 0.09, x: 0.7514125, y: 3.020962, durationInSecs: beatDuration, beep: true });
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'left', pos: 'absolute', rotate: -0.06, x: 0.3954802, y: 3.039457, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -0.06, x: 0.7514125, y: 1.89889, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -0.24, x: 0.3954802, y: 0.8323058, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -0.12, x: 0.7514125, y: -0.09864365, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -1.5, x: 0.3954802, y: -1.165228, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -22.306, x: 0.7796611, y: -3.144266, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -2.245, x: 0.3954802, y: -2.916153, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -139.4, x: -2.384181, y: -3.082614, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -148.1, x: 1.779661, y: -2.509248, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -182.2, x: -0.6327683, y: -2.909988, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -177.5, x: 2.288136, y: -2.866831, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -181.371, x: -0.5762712, y: -1.689273, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -176.746, x: 2.033898, y: -0.6843403, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -181.371, x: -0.6327683, y: 0.4685574, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -176.746, x: 1.977401, y: 1.47349, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -181.371, x: -0.4350282, y: 3.045623, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -176.746, x: 2.175141, y: 3.088779, durationInSecs: beatDuration, beep: true });
	}




	private W_Turn_Sp_NT(instr: any, seq: any[], init: any[], beatDuration: any) {
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'left', pos: 'absolute', rotate: -361.034, x: 0.3446201, y: 0.0002306692, durationInSecs: beatDuration, beep: true });
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'right', pos: 'absolute', rotate: -361.034, x: 1.113007, y: -0.0002306692, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -358.196, x: 0.3466072, y: -0.8220484, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -358.196, x: 0.7155397, y: -1.193981, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -357.913, x: 0.3163681, y: -1.772972, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -357.913, x: 0.6892816, y: -2.141948, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -305.971, x: 0.6045197, y: -2.24291, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -288.554, x: 0.799435, y: -1.909988, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -199.489, x: 0.1390057, y: -1.882215, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -241.066, x: 1.236265, y: -2.463382, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -176.549, x: -0.1421612, y: -2.045074, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -176.363, x: 2.193043, y: -1.561619, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -180.927, x: -0.1809263, y: -1.035655, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -180.741, x: 2.23169, y: -0.5980456, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -181.458, x: -0.1006004, y: -0.0833476, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -181.273, x: 2.320872, y: 0.3485205, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -179.219, x: -0.1243903, y: 0.3359489, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -179.033, x: 2.316493, y: 0.3607336, durationInSecs: beatDuration, beep: true });
	}



	private W_Turn_Sp_T(instr: any, seq: any[], init: any[], beatDuration: any) {
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'right', pos: 'absolute', rotate: 718.268, x: 0.7514125, y: 2.367448, durationInSecs: beatDuration, beep: true });
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'left', pos: 'absolute', rotate: 718.268, x: 0.3954801, y: 2.385943, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 717.165, x: 0.7514125, y: 2.367448, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 717.165, x: 1.553672, y: 1.325524, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 719.282, x: 0.6666667, y: 0.3452527, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 719.282, x: 1.468926, y: -0.6966708, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 718.35, x: 0.5819209, y: -1.701603, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 665.817, x: -0.1694916, y: -2.114673, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 644.201, x: -1.141243, y: -2.577065, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 576.614, x: 0.2259886, y: -1.510481, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 539.841, x: -1.79096, y: -1.528977, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 539.19, x: 0.2259886, y: -0.4377312, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 542.401, x: -1.169491, y: 0.5302097, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 541.749, x: 0.1412429, y: 1.572133, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 540.569, x: -2.327683, y: 2.367448, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 539.917, x: 0.1129943, y: 2.410604, durationInSecs: beatDuration, beep: true });
	}

	//------------------------------------------------------------------------------
	// Walks - Uturn
	private W_U_Turn_HW(instr: any, seq: any[], init: any[], beatDuration: any) {
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'right', pos: 'absolute', rotate: -0.397, x: 1.372814, y: 2.977216, durationInSecs: beatDuration, beep: true });
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'left', pos: 'absolute', rotate: -0.397, x: -0.05642999, y: 2.984559, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 0.182, x: 1.317283, y: 2.008599, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 0.182, x: -0.1138936, y: 0.8582322, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 0.545, x: 1.224018, y: -0.2815117, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 0.545, x: -0.1901203, y: -1.438587, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -62.981, x: 0.8813559, y: -2.902589, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -52.113, x: 0.151818, y: -2.381165, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -119.844, x: -1.022599, y: -3.050555, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -113.454, x: 2.254237, y: -2.069051, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -186.046, x: 2.945119, y: -2.579154, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -180.78, x: -0.2448968, y: -1.878705, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -184.455, x: 3.151142, y: -1.235689, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -179.188, x: 0.02829848, y: -0.2147854, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -182.507, x: 3.444666, y: 1.02373, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -177.241, x: 0.242125, y: 2.012542, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -184.056, x: 3.501833, y: 2.085124, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -178.79, x: 0.3287716, y: 2.02514, durationInSecs: beatDuration, beep: true });
	}



	private W_U_Turn_NT(instr: any, seq: any[], init: any[], beatDuration: any) {
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'right', pos: 'absolute', rotate: -0.77, x: 2.531073, y: 1.270037, durationInSecs: beatDuration });
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'left', pos: 'absolute', rotate: 359.23, x: 2.175141, y: 1.288533, durationInSecs: beatDuration });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -1.451, x: 2.535942, y: 1.267652, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 358.549, x: 2.142024, y: 0.7483776, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -0.848, x: 2.501171, y: 0.3953753, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 359.152, x: 2.120297, y: -0.1302705, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -1.001, x: 2.474586, y: -0.431572, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 358.999, x: 2.090385, y: -0.9556043, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -91.658, x: 0.5217461, y: -1.382402, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 268.342, x: 0.2948014, y: -0.7535215, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -180.834, x: -1.824324, y: -0.6767361, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 179.166, x: 0.5562405, y: -0.1139544, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -179.07, x: -1.833056, y: 0.004624674, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 180.93, x: 0.5084745, y: 0.5856966, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -178.717, x: -1.857325, y: 0.496045, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 181.283, x: 0.4762465, y: 1.080713, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -178.823, x: -1.762584, y: 1.429187, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 181.177, x: 0.4518497, y: 1.474635, durationInSecs: beatDuration, beep: true });
	}

	private W_U_Turn_T(instr: any, seq: any[], init: any[], beatDuration: any) {
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'right', pos: 'absolute', rotate: -1.2, x: 1.429379, y: 2.059186, durationInSecs: beatDuration, beep: true });
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'left', pos: 'absolute', rotate: -1.2, x: 0.9322034, y: 2.02836, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 0.243, x: 1.429379, y: 2.059186, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 0.243, x: 2.288136, y: 1.054254, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 1.071, x: 1.336158, y: 0.01972873, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 1.071, x: 2.194916, y: -0.9852034, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -5.721, x: 1.217514, y: -1.975339, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -79.575, x: 0.717514, y: -2.702836, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -110.297, x: -2.50602, y: -2.488202, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -153.964, x: -2.936942, y: -1.580172, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -183.525, x: -4.317008, y: -0.5671739, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -171.513, x: -2.621717, y: 0.4550152, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -182.632, x: -4.124294, y: 1.468557, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -177.907, x: -2.603061, y: 2.4878, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -182.966, x: -4.819209, y: 2.466091, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -178.241, x: -2.491526, y: 2.496917, durationInSecs: beatDuration, beep: true });
	}



	//------------------------------------------------------------------------------
	// Walks - side

	private Walk_Si_S(instr: any, seq: any[], init: any[], beatDuration: any) {
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'right', pos: 'absolute', rotate: 0.034, x: -4.163841, y: 0.01233046, durationInSecs: beatDuration });
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'left', pos: 'absolute', rotate: 360.604, x: -4.519773, y: 0.03082614, durationInSecs: beatDuration });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -0.087, x: -2.129943, y: 0.01233046, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 359.913, x: -2.485875, y: 0.03082614, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 0.07700001, x: -0.06779654, y: 0.01233046, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 360.077, x: -0.4237289, y: 0.03082614, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 0.161, x: 2.220339, y: 0.01233046, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 360.161, x: 1.864407, y: 0.03082614, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -0.102, x: 4.480226, y: 0.01233046, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 359.898, x: 4.124293, y: 0.03082614, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -0.171, x: 6.485875, y: 0.01233046, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 359.829, x: 6.129943, y: 0.03082614, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 360, x: 3.785311, y: 0.03082614, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 0, x: 4.141243, y: 0.01233046, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 358.343, x: 1.299435, y: 0.03082614, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -1.657, x: 1.655367, y: 0.01233046, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 360, x: -1.129943, y: 0.03082614, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 0, x: -0.7740112, y: 0.01233046, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 360.643, x: -3.531073, y: 0.03082614, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 0.643, x: -3.175141, y: 0.01233046, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: 360.356, x: -4.943502, y: 0.03082614, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: 0.356, x: -4.58757, y: 0.01233046, durationInSecs: beatDuration, beep: true });
	}


	private Walk_Si_L(instr: any, seq: any[], init: any[], beatDuration: any) {
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'right', pos: 'absolute', rotate: -1, x: -5.576272, y: 0.06165228, durationInSecs: beatDuration, beep: true });
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'left', pos: 'absolute', rotate: -1, x: -5.932203, y: 0.08014797, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -1, x: -1.79096, y: 0.03699137, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -1, x: -2.146893, y: 0.05548705, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -1, x: 1.711864, y: 0.01233046, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -1, x: 1.355932, y: 0.03082614, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -1, x: 5.242938, y: -0.01233046, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -1, x: 4.887006, y: 0.006165228, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -1, x: 7.841808, y: -0.03699137, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -1, x: 7.485876, y: -0.01849568, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -1, x: 7.485876, y: -0.01849568, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -1, x: 7.841808, y: -0.03699137, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -1, x: 4.322034, y: 0.006165228, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -1, x: 4.677966, y: -0.01233046, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -1, x: 0.960452, y: 0.03082614, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -1, x: 1.316384, y: 0.01233046, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -1, x: -1.977401, y: 0.05548705, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -1, x: -1.621469, y: 0.03699137, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -1, x: -5.706214, y: 0.08014797, durationInSecs: beatDuration, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -1, x: -5.350283, y: 0.06165228, durationInSecs: beatDuration, beep: true });
	}

	private si_to_si_step_cardio(instr: any, seq: any[], init: any[], beatDuration: any) {
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'right', pos: 'absolute', rotate: -1, x: -2, y: 0, durationInSecs: 0, beep: true });
		init.push({ type: 'floor_foot', subtype: 'start_pos', foot: 'left', pos: 'absolute', rotate: -1, x: -2, y: 0, durationInSecs: 0, beep: false });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -1, x: 2, y: 0, durationInSecs: 0, beep: true });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -1, x: 1.5, y: 0, durationInSecs: 0, beep: false });
		seq.push({ type: 'floor_foot', foot: 'left', pos: 'absolute', rotate: -1, x: -2, y: 0, durationInSecs: 0, beep: true });
		seq.push({ type: 'floor_foot', foot: 'right', pos: 'absolute', rotate: -1, x: -2, y: 0, durationInSecs: 0, beep: false });
	}
}
