///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS
import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { GlobalPubSub } from './global-pub-sub.service';
import { Router } from '@angular/router';
import { Vasat } from 'vasat';
import { UserService } from './user.service';
import { ContentManagerService } from './contentmanager.service';
import { DeviceService } from './device.service';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Exercise, ExerciseCategory } from '../st-commons/models';
import { DataPullLog } from '../objects/localModels';

// Debug only
//import { FileStream }				from '../objects/filestream';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE INJECTABLE
@Injectable({ providedIn: 'root' })

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class CommandForceAlphaService {
	////////////////////////////////
	public cordovaLoadReady: boolean = false;
	public userExists: boolean = null;
	public redirectPage: string;
	public firstRun: boolean = true;
	private readyForUserCheck: boolean = false;
	public adherenceRedirectedFrom: string = '';
	lastAdherenceCheck: number = 0;
	updateAdherenceEvery: number = 1000 * 60 * 5; // 5 mins
	minimumAdherenceTime: number = 1000 * 60 * 60 * 24 * 14; // 14 days
	storageAcceptance$obs: BehaviorSubject<boolean> = new BehaviorSubject(false);
	//minimumAdherenceTime:number = 1000*60*5; // 5 minutes
	dataIsTooOldSoLogoutWhenMS: number = 1000 * 60 * 60 * 24 * 365; // A year

	////////////////////////////////
	constructor(
		private sDevice: DeviceService,
		private sData: DataService,
		private sGlobalPubSub: GlobalPubSub,
		private router: Router,
		private sUser: UserService,
		private V: Vasat,
		private sContentManager: ContentManagerService
	) {}

	////////////////////////////////
	initiateBootupSequence(): void {
		this.sGlobalPubSub.subscribe('cordovaready', this.cordovaReady.bind(this));
		this.sGlobalPubSub.subscribe('cordovaresume', this.cordovaResume.bind(this));
		window.addEventListener('focus', this.cordovaResume.bind(this));

		// Mark this as a first run
		this.firstRun = true;

		// If window.cordova is not found, it is likely that this is browser and no cordova has been found, so we'll fire cordovaReady anyway.
		let cordova = window['cordova'];
		if (!cordova) this.cordovaReady();
	}

	////////////////////////////////
	cordovaReady() {
		let navigator: any = window['navigator'];
		if (navigator && navigator.splashscreen) navigator.splashscreen.hide();
		let statusBar = window['StatusBar'];
		if (statusBar) statusBar.hide();
		this.cordovaLoadReady = true;
		this.sDevice.checkDevice();
		this.addKeyboardListeners();
		this.reinstateUserIfExists().then(this.userExistsAllGood.bind(this)).catch(this.userDoesntExistNotAllGood.bind(this));
	}

	////////////////////////////////
	reinstateUserIfExists() {
		let resolver = async function (resolve, reject) {
			await this.logoutUsersWhenOldData(); // Checks and logs users out if data is too old

			this.sData
				.getAndCacheExerciseData()
				.then(this.sUser.setPrimeUser.bind(this.sUser))
				.then((res) => this.sData.updateUserProfile.bind(this.sData, false, null)(this.sUser.user))
				.then(this.sData.reinstateVasatToken.bind(this.sData))
				.then(resolve)
				.catch(reject);
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////
	cordovaResume() {
		if (['/splash', '/content-problem'].indexOf(this.router.url) == -1 && this.router.url.indexOf('/exercises') == -1) {
			this.firstRun = false;
			this.updateContent();
			if (environment.debug) console.log('Resume Event');
		}
	}

	////////////////////////////////
	userExistsAllGood() {
		this.userExists = true;
		this.redirectPage = 'home';
		this.readyForUserCheck = true;
		this.performBrowserStorageAcceptance();
		this.updateContent();
	}

	////////////////////////////////
	userDoesntExistNotAllGood() {
		this.userExists = false;
		this.redirectPage = 'onboarding';
		this.readyForUserCheck = true;
		this.performBrowserStorageAcceptance();
		this.updateContent();
	}

	////////////////////////////////
	updateContent() {
		this.sContentManager
			.startContentUpdate()
			.then(this.goodToGo.bind(this))
			.then(() => {
				if (this.userExists) this.sData.gatherThisWeeksVasatExerciseAction();
			})
			.catch((err) => {
				console.log('Error updating content in command force', err);
				this.sGlobalPubSub.fireEvent('dismissScreenHider', []);
				this.router.navigate(['content-problem']);
			});
	}

	////////////////////////////////
	goodToGo() {
		//throw "This error will simulate the broken page";
		if (this.firstRun) this.router.navigate([this.redirectPage]);
		this.sGlobalPubSub.fireEvent('dismissScreenHider', []);
	}

	////////////////////////////////
	addKeyboardListeners() {
		if (this.sDevice.isAndroid) {
			window.addEventListener('native.keyboardshow', function (e) {
				document.querySelector('body').style.height = 'calc(100% - ' + (e as any).keyboardHeight + 'px)';
				let aForm = document.querySelector("form-bluewave[data-style='unfixed']") as HTMLElement;
				if (aForm) aForm.style.bottom = (e as any).keyboardHeight + 'px';
			});
			window.addEventListener('native.keyboardhide', function (e) {
				document.querySelector('body').style.height = '100%';
				let aForm = document.querySelector("form-bluewave[data-style='unfixed']") as HTMLElement;
				if (aForm) aForm.style.bottom = '0px';
			});
		}
	}

	////////////////////////////////////
	// Logs all users out if we detect data is more than a year old
	logoutUsersWhenOldData() {
		let resolver = async function(resolve, reject) {
			const now = new Date().getTime();
			const dataPullLog = await JSON.parse(localStorage.getItem('datapulllog'));

			let exerciseCategoryTooOld = false;
			let exerciseTooOld = false;
			if (dataPullLog) {
				exerciseCategoryTooOld = dataPullLog.ExerciseCategory && dataPullLog.ExerciseCategory.lastUpdate < (now - this.dataIsTooOldSoLogoutWhenMS);
				exerciseTooOld = dataPullLog.Exercise && dataPullLog.Exercise.lastUpdate < (now - this.dataIsTooOldSoLogoutWhenMS);
			}

			if (exerciseTooOld || exerciseCategoryTooOld) {
				this.sData.logoutAllProfiles()
				.then(() => {
					console.log("LOGGED OUT ALL USERS");
					reject("Data outdated"); // Tells the promise chain that users were not validated
				});
			} else {
				return resolve("Data fresh enough");
			}

			resolve();
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////////
	userAdherenceCheck() {
		if (this.router.url.indexOf('exercises/') != -1) return; // Don't check adherence if we're trying to exercise / do a balance assessment.
		if (this.router.url.indexOf('splash') != -1) return; // Don't check adherence if we're on the splash page.
		if (!this.sUser.userLoaded) return;
		if (!this.sUser.user || !this.readyForUserCheck) return;
		if (new Date().getTime() < this.lastAdherenceCheck + this.updateAdherenceEvery) return;
		this.lastAdherenceCheck = new Date().getTime();

		if (this.sUser.user.adherenceLastUseDate + this.minimumAdherenceTime < new Date().getTime()) {
			this.adherenceRedirectedFrom = this.router.url;
			setTimeout((_) => this.router.navigate(['/adherence']), 2000);
		} else {
			this.sUser.user.adherenceLastUseDate = new Date().getTime();
			this.sData.updateUserProfile(this.sUser.user, false, null);
		}
	}

	////////////////////////////////////
	performBrowserStorageAcceptance(delay = 3000, forced: boolean = false): BehaviorSubject<boolean> {
		let userHasToAndHasAcceptedStorage: boolean = true;

		if (this.sDevice.isBrowser) {
			let hasAcceptedCookies = localStorage.getItem('cookieconfirm');
			if (hasAcceptedCookies != 'has_accepted' || forced) {
				setTimeout(() => {
					this.sGlobalPubSub.fireEvent('toast-confirm', [
						true,
						'This site uses cookies to process your personal data. These cookies allow the website to remember choices you make to give you better functionality and personal features.',
						'OK',
						() => {
							this.storageAcceptance$obs.next(true);
							localStorage.setItem('cookieconfirm', 'has_accepted');
						},
						'Cancel',
						() => {
							this.storageAcceptance$obs.next(false);
							localStorage.setItem('cookieconfirm', 'has_declined');
							this.sData.logoutAllProfiles();
						},
					]);
				}, delay);
				userHasToAndHasAcceptedStorage = false;
			}
		}

		this.storageAcceptance$obs.next(userHasToAndHasAcceptedStorage);
		return this.storageAcceptance$obs;
	}

	////////////////////////////////////
	hideStorageConfirmToast() {
		this.sGlobalPubSub.fireEvent('toast-confirm-hide', []);
	}
}
