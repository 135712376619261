import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-exercise6-sessioncompleted',
  templateUrl: './page-exercise8-trialsessioncompleted.component.html',
  styleUrls: ['./page-exercise8-trialsessioncompleted.component.scss']
})
export class PageExercise8TrialSessioncompletedComponent implements OnInit {

	constructor(private router: Router) { }

	ngOnInit() {}

	onBackButton() {
		this.router.navigate(['/home']);
	}
}
