///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
////////// MODULES
import { Component, OnInit, ViewChild, ViewChildren, QueryList, AfterViewInit } 	from '@angular/core';
import { Router }																	from '@angular/router';
import { DataService }																from '../../services/data.service';
import { ValidfieldComponent }														from '../../components/validfield/validfield.component';
import { Vasat }																	from 'vasat';
import { FormBluewaveComponent }													from '../../components/form-bluewave/form-bluewave.component';
import { UserService }																from '../../services/user.service';

declare var require;

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
	selector: 'app-page-setupprofile',
	templateUrl: './page-setupprofile.component.html',
	styleUrls: ['./page-setupprofile.component.scss']
})


///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class PageSetupprofileComponent implements OnInit, AfterViewInit {

	////////////////////////////////
	profileQuestions = require('../../../assets/json/profileQuestions.json');

	////////////////////////////////
	signupForm:any = {
		first_name:"",
		last_name:"",
		state:"",
		country:"",
		dob:"",
		how_did_you_hear:this.profileQuestions.howHearOptions[0].val,
		falls_frequency:this.profileQuestions.fallsOptions[0].val,
		has_chronic_health_issues:"",
		email:"",
		password:"",
		accept_terms_privacy:false,
		code:""
	}

	////////////////////////////////
	@ViewChild('fld_password') fld_password;
	@ViewChild('fld_birthdayday') fld_birthdayday;
	@ViewChild('fld_birthdaymonth') fld_birthdaymonth;
	@ViewChild('fld_birthdayyear') fld_birthdayyear;
	@ViewChild('btnext') btnext;
	@ViewChild('scrollContainer') scrollcontainer;
	@ViewChild('tgl_accepttermsprivacy') tgl_accepttermsprivacy;
	@ViewChild('tgl_showpassword') tgl_showpassword;

	////////////////////////////////
	@ViewChildren(ValidfieldComponent) validfields !: QueryList<ValidfieldComponent>;
	@ViewChild("bluewaveform") bluewaveform: FormBluewaveComponent;

	////////////////////////////////
	currentPageIndex:number = 0;
	errorMessage:string = "";
	whichState:string;
	longContentState:boolean = false;
	invalidcodeattempts:number = 0;

	////////////////////////////////
	constructor(private router: Router, private sData:DataService, private V:Vasat, private sUser:UserService) { }

	////////////////////////////////
	ngOnInit() {
		setTimeout(() => {
			this.bluewaveform.setFormContentState("step-0");
			this.bluewaveform.open();
		}, 1000);

		if (this.router.url == "/setupprofile/add") {
			this.whichState = "fromProfile";
		} else {
			this.whichState = "notFromProfile";
		}
	}

	////////////////////////////////
	ngAfterViewInit() {
		this.fld_password.nativeElement.addEventListener('keyup', ev => {
			let minlength = parseInt(ev.target.getAttribute('minlength')) || 0;
			if (ev.target.value.length >= minlength) {
				document.querySelector("#minlengthnote").classList.add('met');
			} else {
				document.querySelector("#minlengthnote").classList.remove('met');
			}
			if (ev.target.value.search(/[0-9]/g) != -1) {
				document.querySelector("#numbernote").classList.add('met');
			} else {
				document.querySelector("#numbernote").classList.remove('met');
			}
			if (ev.target.value.search(/[A-Z]/g) != -1) {
				document.querySelector("#capitalnote").classList.add('met');
			} else {
				document.querySelector("#capitalnote").classList.remove('met');
			}
		});
	}

	////////////////////////////////
	prev() {
		this.longContentState = false;
		if (this.btnext.nativeElement.classList.contains('loading')) return;
		switch (this.currentPageIndex) {
			case 0:
			case 98: /* Invalid trial code 3 attempts */
				if (this.whichState == "fromProfile") this.router.navigate(['/onboarding/add']);
				else this.router.navigate(['/onboarding']);
			break;
			case 1: this.bluewaveform.setFormContentState("step-0"); this.currentPageIndex--; break;
			case 2: this.bluewaveform.setFormContentState("step-1"); this.currentPageIndex--; break;
			case 3: this.bluewaveform.setFormContentState("step-2"); this.currentPageIndex--; break;
			case 4: this.bluewaveform.setFormContentState("step-3"); this.currentPageIndex--; break;
			case 5: this.bluewaveform.setFormContentState("step-4"); this.currentPageIndex--; break;
			case 99: this.bluewaveform.setFormContentState("step-0"); this.currentPageIndex = 0; break; /* Invalid code */
		}

		//this.scrollcontainer.nativeElement.scrollTop = 0;
	}

	////////////////////////////////
	next() {
		if (this.currentPageIndex >= 3) this.setReadyToValidate();
		if (this.btnext.nativeElement.classList.contains('loading')) return;
		if (!this.validate()) return;
		switch (this.currentPageIndex) {
			case 0: this.validateTrialCode().then(() => { this.bluewaveform.setFormContentState("step-1"); this.currentPageIndex++; }).catch(err => { this.bluewaveform.setFormContentState("step-invalidtrialcode"); this.currentPageIndex = 99; this.checkTooManyCodeAttempts(); }); break;
			case 1: this.bluewaveform.setFormContentState("step-2"); this.currentPageIndex++; break;
			case 2: this.bluewaveform.setFormContentState("step-3"); this.currentPageIndex++; this.alignAllToggles(); break;
			case 3: this.bluewaveform.setFormContentState("step-4"); this.currentPageIndex++; this.alignAllToggles(); break;
			case 4: this.submitForm(); break;
		}

		//this.scrollcontainer.nativeElement.scrollTop = 0;
	}

	////////////////////////////////
	validate(): boolean {
		
		let valid = true;
		let fields = this.validfields.toArray();
		let foundInvalidField = false;
		fields.forEach( field => {
			if (!field.el.nativeElement.parentElement.hasAttribute('hidden') && !field.validate()) {
				valid = false;
				if (!foundInvalidField) {
					foundInvalidField = true;
					//this.smoothScrollToElement(field.el.nativeElement.parentElement, 250);
				}
			}
		});

		if (!this.tgl_accepttermsprivacy.el.nativeElement.parentElement.parentElement.parentElement.hasAttribute('hidden') && !this.signupForm.accept_terms_privacy) {
			valid = false;
			this.tgl_accepttermsprivacy.el.nativeElement.classList.add("ng-invalid");
		}

		return valid;
	}

	////////////////////////////////////
	validateTrialCode() {

		this.signupForm.code = this.signupForm.code.trim();
		this.signupForm.code = this.signupForm.code.replace(/ /g, "");
		this.signupForm.code = this.signupForm.code.toUpperCase();

		let resolver = (resolve, reject) => {
			this.V.api("/api/CheckCode/"+this.signupForm.code).subscribe(resolve, reject);
		}

		return new Promise(resolver);
	}

	////////////////////////////////////
	setReadyToValidate() {
		Array.prototype.forEach.call(document.querySelectorAll('[data-readytovalidate="false"]'), (el) => {
			el.setAttribute('data-readytovalidate', 'true');
		});
	}

	////////////////////////////////
	checkRequired(validForm) {
		let el = validForm.querySelector('input, select, textarea');
		if (el.hasAttribute('required') && el.value == "") {
			validForm.classList.add("validatrix-invalid");
			validForm.classList.add("validatrix-invalid-required");
			return false;
		}
		return true;
	}

	////////////////////////////////
	checkMinLength(validForm) {
		let el = validForm.querySelector('input, select, textarea');
		let minlength = parseInt(el.getAttribute('minlength'));
		if (minlength && el.value.length < minlength) {
			validForm.classList.add("validatrix-invalid");
			validForm.classList.add("validatrix-invalid-minlength");
			return false;
		}
		return true;
	}

	////////////////////////////////
	checkMaxLength(validForm) {
		let el = validForm.querySelector('input, select, textarea');
		let maxlength = parseInt(el.getAttribute('maxlength'));
		if (maxlength && el.value.length > maxlength) {
			validForm.classList.add("validatrix-invalid");
			validForm.classList.add("validatrix-invalid-maxlength");
			return false;
		}
		return true;
	}

	////////////////////////////////
	submitForm() {
		let options = JSON.parse(JSON.stringify(this.signupForm));
		delete options['password'];
		//options.dob = Date.UTC(this.fld_birthdayyear.getValue(), this.fld_birthdaymonth.getValue()-1, this.fld_birthdayday.getValue(), 0, 0, 0);

		this.btnext.nativeElement.classList.add('loading');
		this.sData.clearVasatClientKey();
		
		this.sData.requestCreateUserFromVasat(this.signupForm.email, this.signupForm.password, options)
		.then( res => {

			// If we got a timeout
			if (res == "Timeout") {
				this.btnext.nativeElement.classList.remove('loading');
				this.errorMessage = "No response from the accounts department, please check your Internet connection and try again.";
				this.bluewaveform.setFormContentState("step-5");
				this.currentPageIndex = 5;
				this.sData.reinstateVasatToken();
				return res;
			}

			// Otherwise save the User in our data and move on
			this.sData.saveFullUser(res)
			.then(res2 => {
				this.sUser.user = res;
				this.sData.setPrimeUser(res2.data.newUserToken[0].owner_id)
				.then(res3 => {
					this.btnext.nativeElement.classList.remove('loading');
					this.router.navigate(["/onboarded"]);
				}).catch(err => { console.log("Error during setPrimeUser", err); });
			})
			.catch( (err) => { console.log("Error saving new user", err); this.sData.reinstateVasatToken(); });
		})
		.catch( err => {
			setTimeout( () => {
				this.btnext.nativeElement.classList.remove('loading');
				switch (err.message) {
					case "User already exists": this.errorMessage = "This email has already been registered for Standing Tall, please proceed to login"; break;
					case "Valid email address required": this.errorMessage = "The email you provided is not in the correct format.  Please check your email and try again."; break;
				}
				this.errorMessage = err.message;
				this.bluewaveform.setFormContentState("step-5");
				this.currentPageIndex = 5;

				// Reinstate the Vasat token of the current logged in user
				this.sData.reinstateVasatToken()
				
				// If no logged in user, we'll reinstate the previous backup (generic vasat login)
				.catch(() => { this.sData.reinstatePreviousVasatToken(); });
			}, 1000);
		})
	}

	////////////////////////////////////
	checkTooManyCodeAttempts() {
		this.invalidcodeattempts++;
		if (this.invalidcodeattempts >= 3) {
			this.bluewaveform.setFormContentState("step-invalidtrialcodeend"); this.currentPageIndex = 98;
		}
	}

	////////////////////////////////////
	/*updateBirthday() {
		let date = new Date(this.fld_birthdayyear.getValue()+"/"+this.fld_birthdaymonth.getValue()+"/"+this.fld_birthdayday.getValue());
		if (date.getMonth() != parseInt(this.fld_birthdaymonth.getValue()) - 1) {
			this.fld_birthdayday.setValue(1);
			this.fld_birthdayday.invalidDate();
			return false;
		}
	}*/

	////////////////////////////////
	updatePasswordRequirements() {
	}

	////////////////////////////////
	toggleValueUpdated(state) {
		if (state.id == "passwordformat") {
			if (state.state) this.fld_password.nativeElement.setAttribute('type', 'text');
			else  this.fld_password.nativeElement.setAttribute('type', 'password');
		}
	}

	////////////////////////////////
	toggleAcceptTermsPrivacy(state) {
		this.signupForm.accept_terms_privacy = state.state;
		this.tgl_accepttermsprivacy.el.nativeElement.classList.add("ng-dirty", "ng-touched");
		if (state.state == false) {
			this.tgl_accepttermsprivacy.el.nativeElement.classList.add("ng-invalid");
		} else {
			this.tgl_accepttermsprivacy.el.nativeElement.classList.remove("ng-invalid");
		}
	}

	////////////////////////////////
	toTerms($event) {
		this.longContentState = true;
		this.bluewaveform.setFormContentState("step-terms");
		this.currentPageIndex++;
		//this.scrollcontainer.nativeElement.scrollTop = 0;
	}

	////////////////////////////////
	toPrivacy($event) {
		this.longContentState = true;
		this.bluewaveform.setFormContentState("step-privacy");
		this.currentPageIndex++;
		//this.scrollcontainer.nativeElement.scrollTop = 0;
	}

	////////////////////////////////
	/*smoothScrollToElement(targetElement: any, scrollTime: number) {

		//Set target position (the elements offset top will change as it scrolls)
		let targetPosition = targetElement.offsetTop - (this.scrollcontainer.nativeElement.offsetHeight/2);

		//Find Distance to target
		let distanceToTarget = targetPosition - this.scrollcontainer.nativeElement.scrollTop;

		//Set interval Length (32 frames per second?)
		let intervalLength = 1000/32;

		//Set number of intervals
		let numberOfIntervals = scrollTime/intervalLength;

		//Set scroll jump
		let scrollJump = distanceToTarget/numberOfIntervals;

		//Set interval count
		let intervalCount = 0;

		//Set interval
		let scrollInterval = setInterval(() => {

			if (intervalCount >= numberOfIntervals) {

				this.scrollcontainer.nativeElement.scrollTop = targetPosition;

				clearInterval(scrollInterval)
			} else {
				intervalCount++;
				this.scrollcontainer.nativeElement.scrollTop = this.scrollcontainer.nativeElement.scrollTop + scrollJump;
			}
		}, intervalLength)
	}*/

	////////////////////////////////
	alignAllToggles() {
		setTimeout(() => {
			this.tgl_accepttermsprivacy.alignToggle();
			this.tgl_showpassword.alignToggle();
		}, 200);
	}
}
