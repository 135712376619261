///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
////////// ANGULAR CORE
import { Component, HostListener, OnInit, AfterViewInit, ViewChild }	from '@angular/core';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute }		from '@angular/router';

////////////////////////////////
////////// MODULES
import { pageRouterTransition }											from './router.animations';
import { CommandForceAlphaService }										from './services/commandforcealpha.service';
import { GlobalPubSub }													from './services/global-pub-sub.service';
import { ContentManagerService }										from './services/contentmanager.service';
import { DataService }													from './services/data.service';
import { DeviceService }												from './services/device.service';
import { NavigationService } 											from './services/navigation.service';

////////////////////////////////
////////// SETUP COMPONENT
@Component({
    selector:       'app-root',
    templateUrl:    './app.component.html',
    styleUrls:      ['./app.component.scss'],
    animations:     [ pageRouterTransition ]
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class AppComponent implements AfterViewInit {

	////////////////////////////////
	hider:HTMLElement = document.querySelector("#hider");

	////////////////////////////////
	contentReady:boolean = false;

	////////////////////////////////
    constructor (private router: Router,
				 private activatedRoute: ActivatedRoute,
				 private sCommand: CommandForceAlphaService,
				 private sContentManager:ContentManagerService,
				 private sGlobalPubSub:GlobalPubSub,
				 private sData:DataService,
				 private sNavigation:NavigationService,
				 private sDevice:DeviceService) {

        router.events.subscribe(this.routerChange.bind(this));
    }

	////////////////////////////////
	ngAfterViewInit() {
		this.sGlobalPubSub.subscribe('dismissScreenHider', this.appReady.bind(this));
		this.sGlobalPubSub.subscribe('revealScreenHider', this.needToHideForABit.bind(this));
		this.sGlobalPubSub.subscribe('dataProgressUpdate', this.dataProgressUpdate.bind(this));
		this.sCommand.initiateBootupSequence();
	}

	////////////////////////////////////
	appReady() {
		setTimeout(() => this.hider.classList.add('off'), 500);
		this.hider.classList.remove('messageReceived');
		this.contentReady = true;
	}

	////////////////////////////////////
	needToHideForABit() {
		setTimeout(() => this.hider.classList.remove('off'), 500);
	}

	////////////////////////////////////
	dataProgressUpdate(event, filename, fileIndex, numFiles, parentId) {
		let progress = event.position || event.loaded;
		let totes = event.totalSize || event.total;
		let percent;
		if (event.lengthComputable) {
			percent = progress / totes;
		} else {
			percent = null;
		}

		// Don't show if there isn't any real data to display
		if (!fileIndex || !numFiles) return;

		this.hider.classList.add('messageReceived');

		// Update percent circles
		let circ1 = document.querySelector(".circ1");
		let circ2 = document.querySelector(".circ2");
		let circ3 = document.querySelector(".circ3");
		let circ4 = document.querySelector(".circ4");
		let circ5 = document.querySelector(".circ5");
		if (percent > 0.95) circ5.classList.add("on"); else circ5.classList.remove("on");
		if (percent > 0.75) circ4.classList.add("on"); else circ4.classList.remove("on");
		if (percent > 0.55) circ3.classList.add("on"); else circ3.classList.remove("on");
		if (percent > 0.35) circ2.classList.add("on"); else circ2.classList.remove("on");
		if (percent > 0.15) circ1.classList.add("on"); else circ1.classList.remove("on");

		(this.hider.querySelector(".message .index") as HTMLElement).innerText = fileIndex + " / " + numFiles;
		(this.hider.querySelector(".message .file") as HTMLElement).innerText = filename;
		//(this.hider.querySelector(".message .exerciseDeets") as HTMLElement).innerText = "PID: " + parentId;
	}

	////////////////////////////////////
	routerChange(event): void {

		if (event instanceof NavigationEnd) {

			let data:any = this.activatedRoute.firstChild.data;
			if (this.activatedRoute && this.activatedRoute.firstChild && this.activatedRoute.firstChild.data && data._value) {
				let main: HTMLElement = document.querySelector("main");

				// If the route has a logoVisible property
				if (typeof data._value.logoVisible == "boolean") main.setAttribute('data-logoon', data._value.logoVisible);
				else main.setAttribute('data-logoon', "true");

				// If the route has a non-empty array of decorators
				var decorators = document.querySelectorAll(".decorators img");
				Array.prototype.forEach.call(decorators, (dec:HTMLElement) => { dec.style.opacity = "0"; });
				if (typeof data._value.decorators == "object" && data._value.decorators.length) {
					var decos = data._value.decorators;
					decos.forEach((i) => {
						var deco:HTMLElement = document.querySelector(".decorators #deco-"+i);
						if (deco) deco.style.opacity = "1";
					});
				}
			}

			// Check adherence
			this.sCommand.userAdherenceCheck();

			// Update zoom level zoomlevel
			let zoom = document.querySelector('main').getAttribute('data-zoomlevel');
			let children = document.querySelectorAll("main > *");
			for (let x=0; x<children.length; x++) {
				children[x].setAttribute('data-zoomlevel', zoom);
			}
		}
	}

    // Queries the activatedRouteData for the current state - fired on router transition
	getState(outlet) {
		return outlet.activatedRouteData.state;
	}

	////////////////////////////////////
	genericRouterChange() {
		if (this.contentReady && (["/content-problem", "/splash"].indexOf(this.router.url) == -1 && this.router.url.indexOf("exercises/") == -1)) {
			this.sContentManager.pingDataSyncLog()
			.then(_ => console.log('%c  >>> Completed update pulls', 'color:lightblue;'))
			.catch(err => {
				console.log(">>>>>>>>>> DATA SYNC ERROR:", err);
			});
		} else {
			//console.log("%cNot downloading app data as we're visiting a locked url.", 'color:lightgray');
		}
	}

	////////////////////////////////////
	getDevice() {
		return this.sDevice.platform;
	}
}
