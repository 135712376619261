import { Injectable } from '@angular/core'
import { Observable,forkJoin,of } from 'rxjs'
import { map,flatMap,tap ,share} from 'rxjs/operators'
import { Vasat, VasatSearchQuery,User } from 'vasat';
import { ExerciseCategory,Exercise,ExerciseAction,ExerciseLevel,STUser } from './models'
import { DOSEAGE_TIMES } from './exercise_logic';

declare var require; // Otherwise causes an error on jD's comp.  Will resolve correctly later and remove this line.
const M = require('moment')


export interface ChartData{
    data:Array<any>
    labels:Array<any>
    options:any
}

@Injectable()
export class STService{

    loaded:boolean
    
    activeUser:STUser

    lastSync:number

    private _loading:Observable<STService>

    constructor(public V:Vasat){
    
    }



    onReady():Observable<STService>{
    
        if(this.loaded)
        return of(this)
        else if(this._loading)
        return this._loading
        else
        return this._loading = this.V.onReady().pipe(
        flatMap(s => {
            return new STUser(this.V).fetchObservable().pipe(map(ss => {
            this.activeUser = <STUser>ss;
            //  this.activeUser.updateRelations()
            //  this.V.registerCacheClass(User,[this.activeUser]);
            this.loaded = true;
            this._loading = null;
            return this
            }))
        }),
         share()
        )

    }
    /**
     * Returns the user progress data formated for using with chartjs
     */
    getChartDataForProgress(patient:STUser):ChartData {
        // Custom object containing the values to pass onto the chartjs object
        let res:ChartData = {
            data:[],
            labels:[],
            options:{
                responsive: true,
                scales: {
                    yAxes: [{
                          scaleLabel: {
                            display: true,
                            labelString: 'Minutes'
                          },
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        }

        let rawData         = patient.completedHours
        //let rawData         = {"2018_43":15,"2018_44":20,"2018_45":20,"2018_46":20,"2018_47":30,"2018_48":40,"2018_49":40,"2018_50":50,"2018_51":50,"2018_52":50,"2019_1":60,"2019_2":50,"2019_3":50,"2019_4":60}
        if (!rawData) return res    // If no data just return empty object

        let rawDataArray    = Object.keys(rawData)
        let startDate       = M(patient.startDate)  // Get patient's progress start date
        let lastProgDate    = M(rawDataArray[rawDataArray.length - 1], "YYYY_W")   // Last progress date
        let weeksDiff        = lastProgDate.diff(startDate, 'weeks') as number
        
        // Dosage series. This si of type line chart
        let doseageSerie = {data:[],type: 'line', label: "Weekly Dosage", fill:false, borderDash:[5]}
        res.data.push(doseageSerie)
        // User data series. This should be bar but can be overriden by setting type as undefined .
        let dataSerie = {data:[], label: "Progress", type:'bar', fill:true}
        res.data.push(dataSerie)
        // Get weekly doseages times
        let doseageTimes = DOSEAGE_TIMES["_" + patient.weekly_doseage] as number[]
        // Calculate the number of weeks to display data for.
        let idx = (doseageTimes.length > weeksDiff) ? doseageTimes.length : weeksDiff
        for (var i=0; i < idx; i++) {
            // Set the doseage serie
            if (doseageTimes[i]) {
                doseageSerie.data.push(doseageTimes[i])
            } else {
                // Set doseage serie to last doseage if user has done more weeks than the doseage times
                doseageSerie.data.push(doseageTimes[doseageTimes.length - 1])
            }
            // Set actual progress serie
            let curWeek = M(startDate).clone().add(i, 'weeks');
            let val = rawData[curWeek.format('YYYY_W')]
            dataSerie.data.push( val ? val : 0 )
            res.labels.push( 'Week ' + (i+1) )
        }
        return res
    }
    /**
     * Returns the user balance data formated for using with chartjs
     */
    getChartDataForBalance(patient:STUser):ChartData {
        // Custom object containing the values to pass onto the chartjs object
        let res:ChartData = {
            data:[],
            labels:[],
            options:{
                responsive: true,
                scales: {
                    yAxes: [{
                        scaleLabel: {
                            display: true,
                            labelString: 'Seconds'
                          },
                        ticks: {
                            beginAtZero: true,
                            max: 300
                        }
                    }]
                }
            }
        }
        
        let rawData         = patient.balancePerMonth
        //let rawData         = {"2018_7":20,"2018_8":50,"2018_9":60,"2018_10":80,"2018_11":100}
        if (!rawData) return res    // If no data just return empty object
        let startDate       = M(patient.startDate)  // Get patient's progress start date
        //let startDate       = M(1530367200000)

        let todaysDate      = M()
        let startDateDayOne = M(startDate).clone().date(1)  // Set start date to 1st of month to do some calculation
        let monthsDiff      = todaysDate.diff(startDateDayOne, 'months') as number
        monthsDiff++        // Add one month to dsiplay current month even if it has no data
        
        // User data series. This should be bar but can be overriden by setting type as undefined .
        let dataSerie = {data:[], label: "Balance assessment", type:'line', fill:true}
        res.data.push(dataSerie)
        for (var i=0; i < monthsDiff; i++) {
            // Set balance serie
            let curDate = M(startDate).clone().add(i, 'months');
            //console.log("curDate: " + curDate)
            //console.log("curDate formatted: " + curDate.format('YYYY_M'))
            let val = rawData[curDate.format('YYYY_MM')]
            //console.log("val: " + val)
            dataSerie.data.push( val ? val : 0 )
            res.labels.push( 'Month ' + (i+1) + " (" + curDate.format('MMM YY') + ")" )
        }
        return res
    }

}


////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
// Parent service for Exercise Service in main package
@Injectable()
export class AbsExerciseService{
   prechooseDualIndexMethod():any {
       return '';
   }
   getPrechosenDualIndex():any {
	   return '';
   }
}