///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
////////// ANGULAR
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { Vasat } from 'vasat';
import { STExerciseLogic } from '../../st-commons/exercise_logic';
import { UserService } from '../../services/user.service';
import { Push } from '@ionic-native/push';
import { GlobalPubSub } from '../../services/global-pub-sub.service';
import { ExerciseService } from '../../services/exercise.service';
import { DeviceService } from '../../services/device.service';
import { ContentManagerService } from '../../services/contentmanager.service';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
	selector: 'app-page-settings',
	templateUrl: './page-settings.component.html',
	styleUrls: ['./page-settings.component.scss'],
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class PageSettingsComponent implements OnInit {
	////////////////////////////////
	@ViewChild('toggleNotifications') toggleNotifications;

	////////////////////////////////
	stExerciseLogic = new STExerciseLogic(this.V);
	tier: string = '1';
	dosages: any = [0, 0, 0];
	showPermissionInfo: boolean = false;

	////////////////////////////////
	constructor(
		private sUser: UserService,
		private router: Router,
		private sData: DataService,
		private V: Vasat,
		private push: Push,
		private sGlobalPubSub: GlobalPubSub,
		private sExercise: ExerciseService,
		private sDevice: DeviceService,
		private sContentManager: ContentManagerService
	) {}
	ngOnInit() {
		// If platform is cordova
		if (!this.sDevice.isBrowser) {
			this.push.hasPermission().then((res) => {
				this.toggleNotifications.setState(res.isEnabled);
			});
		}

		// this.toggleNotifications.setState(this.sUser.user.notify_me);
		this.updateTier();

		this.getWeeklyDosage();
	}

	////////////////////////////////
	toggleNotificationsUpdated(val) {
		// this.sUser.user.notify_me = val.state;
		// this.sData.updateUserProfile(this.sUser.user, false, null);
		this.showPermissionInfo = true;
	}

	////////////////////////////////
	updateDosageHours($event, val) {
		this.sUser.user.weekly_doseage = val + '';
		this.sData.updateUserProfile(this.sUser.user, true, ['weekly_doseage']);
		this.getWeeklyDosage();
		this.updateTier();
	}

	////////////////////////////////////
	updateTier() {
		switch (this.sUser.user.weekly_doseage) {
			case '60':
				this.tier = '1';
				break;
			case '120':
				this.tier = '2';
				break;
			case '180':
				this.tier = '3';
				break;
		}
	}

	////////////////////////////////
	btTerms() {
		this.router.navigate(['termsconditions']);
	}

	////////////////////////////////
	btPrivacy() {
		this.router.navigate(['privacy']);
	}

	////////////////////////////////
	getWeeklyDosage() {
		let minutes;
		let hours;
		let week = this.stExerciseLogic.weekNumber(this.sUser.user);

		let week1: string = convertToString(
			this.sUser.isUserCog()
				? this.stExerciseLogic.doseageForGivenWeek(this.sUser.user, week) + this.sExercise.getCogDosageForThisWeek()
				: this.stExerciseLogic.doseageForGivenWeek(this.sUser.user, week)
		);
		let week2: string = convertToString(
			this.sUser.isUserCog()
				? this.stExerciseLogic.doseageForGivenWeek(this.sUser.user, week + 1) + this.sExercise.getCogDosageForThisWeek()
				: this.stExerciseLogic.doseageForGivenWeek(this.sUser.user, week + 1)
		);
		let week3: string = convertToString(
			this.sUser.isUserCog()
				? this.stExerciseLogic.doseageForGivenWeek(this.sUser.user, week + 2) + this.sExercise.getCogDosageForThisWeek()
				: this.stExerciseLogic.doseageForGivenWeek(this.sUser.user, week + 2)
		);
		this.dosages = [week1, week2, week3];

		////////////////////////////////
		function convertToString(value) {
			let string;
			let hourAddon = '';
			minutes = Math.ceil(value % 60);
			hours = Math.round((value - minutes) / 60);
			if (hours > 1) hourAddon = 's';
			if (minutes) string = hours.toString() + ' hour' + hourAddon + ', ' + minutes + ' minutes';
			else string = hours.toString() + ' hour' + hourAddon;
			return string;
		}
	}

	////////////////////////////////////
	openSettings() {
		if (!this.sDevice.isBrowser) {
			let cordova = window['cordova'];

			if (cordova && cordova.plugins && cordova.plugins.settings) {
				//iOS
				if (this.sDevice.isiOS) {
					cordova.plugins.settings.open('notification_id', () => {}, this.settingsFailure);

					return;
				}

				//Android
				if (this.sDevice.isAndroid) {
					cordova.plugins.settings.open('application', () => {}, this.settingsFailure);

					return;
				}
			}
		}
	}

	////////////////////////////////////
	settingsFailure() {
		this.sGlobalPubSub.fireEvent('toast', [
			true,
			'There was a problem opening the settings app.  Please wait a moment and try again, or manually open it.',
			2500,
		]);
	}
}
