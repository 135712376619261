///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////// ANGULAR CORE
import { Injectable }                           from '@angular/core';

////////////////////////////// VASAT
import { Vasat, VasatSession, VasatModel,
		 VasatModelClass, VasatSearchQuery }	from 'vasat';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
@Injectable()
export class VasatObjectService {

	//////////////////////////////
	constructor(private V:Vasat) {}
    
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////// PUBLIC FUNCTIONS

	//////////////////////////////
	public get<T extends VasatModel<T>>(type: VasatModelClass<T>) {
        return this.V.searchByObject(type)
		.queryObservable()
		.subscribe();
    }

    /*public postObjectToVasat<T extends VasatModel<T>>(object: VasatModel<T>) { //Promise<{}>

        return object.saveObservable();
    }

    public postGenericObjectByType<T extends VasatModel<T>>(type: VasatModelClass<T>) {

        //Create object
        var object = new type(this.V);

        //Post object
        this.postObjectToVasat(object).subscribe(
            res => console.log(res),
            err => console.error(err)
        );
	}*/
    
	/*
    public customSearchQuery<T extends VasatModel<T>>(type: VasatModelClass<T>) {
        
        //Return a custom fetch query
        return this.V.searchByObject(type);
    }

    public customFetchActionQuery() {

        return this.customSearchQuery(Action)
            .useCache(true)
    }

    public customFetchUserActionQuery() {

        return this.customSearchQuery(UserAction)
            .useCache(true)
            .equals("completed", "false")
            .equals("cancelled", false)
    }

    public customFetchCommentQuery(action: Action) {

        return this.customSearchQuery(Comment)
            .useCache(true)
            .equals("action", action)
            .sortBy("dateMade", true)
        ;
    }

    public customFetchOnboardingQuestionQuery() {

        return this.customSearchQuery(OnboardingQuestion)
            .useCache(true)
            // .sortBy("orderId", false)
    }

    public customFetchOnboardingQuestionResponseQuery() {

        return this.customSearchQuery(OnboardingQuestion)
            .useCache(true)
    }

    public customFetchCategoryQuery() {

        return this.customSearchQuery(Category)
            .useCache(true)
    }


    public createObjectFromJson<T extends VasatModel<T>>(type: VasatModelClass<T>, jsonObject: any): T {

        //Create new Object
        var newObject = new type(this.V);

        //Set the object from jsonObject
        newObject.set(jsonObject);

        return newObject;
    }

	//  Convert JSON to vasat object
    public createObjectArrayFromJsonArray<T extends VasatModel<T>>(type: VasatModelClass<T>, jsonArray: any[]): any[] {

        //Declare array
        var objectArray = [];

        //Loop through json array
        for (var i = 0; i < jsonArray.length; i++) {

            //Create new Object
            var newObject = this.createObjectFromJson(type, jsonArray[i]);

            //Add new object to object array
            objectArray.push(newObject);
        }

        return objectArray;
    }

	//  Convert JSON to vasat object
    public createObjectArrayFromLocalStorage<T extends VasatModel<T>>(type: VasatModelClass<T>, localStorageTarget: any): any[] {

        //If actions are available in Storage
        var localStorageObjects = localStorageTarget;

        //Declare array
        var objectArray = [];
            
        //Ensure local storage data is valid
        if (localStorageObjects != null) {
            
            //Get the non-typed array
            var genericArray = JSON.parse(localStorageObjects);

            //Create and set Action Array
            objectArray = this.createObjectArrayFromJsonArray(type, genericArray);

            //Register Cache class (fixes the json attribute bug)
            this.V.registerCacheClass(type,objectArray)
        }

        return objectArray;
    }*/
}