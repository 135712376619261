///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

//////////////////////////////// ANGULAR CORE
import { Injectable } from '@angular/core';
import { GlobalPubSub } from './global-pub-sub.service';
import { DeviceService } from "./device.service";


///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
@Injectable({ providedIn: 'root' })
export class NotificationsService {

	////////////////////////////////
	constructor(private sDevice:DeviceService) { }

	////////////////////////////////
	getNotifications() {

		let resolver = function(resolve, reject) {
			if (this.sDevice.isBrowser) return resolve();
			let cordova = window["cordova"];
			if (!cordova.plugins || !cordova.plugins.notification) return reject("Notifications not available");
			cordova.plugins.notification.local.getScheduled(gotNotifications, reject);

			function gotNotifications(notifications) {
				resolve(notifications);
			}
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////
	setSingleNotification(id, title, text, date) {

		let resolver = function(resolve, reject) {
			if (this.sDevice.isBrowser) return resolve();
			let cordova = window["cordova"];
			if (!cordova.plugins || !cordova.plugins.notification) return reject("Notifications not available");
			cordova.plugins.notification.local.schedule({
				id: id,
				title: title,
				text: text,
				foreground: true,
				trigger: { at: date },
				wakeup: true,
				lockscreen: true
			});

		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////
	setWeeklyNotification(id, text, dayOfWeek/*Monday = 1*/, hour, minute) {

		let resolver = function(resolve, reject) {
			if (this.sDevice.isBrowser) return resolve();
			let cordova = window["cordova"];
			if (!cordova.plugins || !cordova.plugins.notification) return reject("Notifications not available");
			cordova.plugins.notification.local.schedule({
				id: id,
				title: text,
				trigger: { every: { weekday: dayOfWeek, hour: hour, minute: minute } }
			});
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////
	editWeeklyNotification(id, text, dayOfWeek/*Monday = 1*/, hour, minute) {

		let resolver = function(resolve, reject) {
			if (this.sDevice.isBrowser) return resolve();
			let cordova = window["cordova"];
			if (!cordova.plugins || !cordova.plugins.notification) return reject("Notifications not available");
			cordova.plugins.notification.local.cancel(id, function() {
				cordova.plugins.notification.local.schedule({
					id: id,
					title: text,
					trigger: { every: { weekday: dayOfWeek, hour: hour, minute: minute } }
				});
			}, reject);
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////
	deleteWeeklyNotification(id) {

		let resolver = function(resolve, reject) {
			if (this.sDevice.isBrowser) return resolve();
			let cordova = window["cordova"];
			if (!cordova.plugins || !cordova.plugins.notification) return resolve("Notifications not available - so nothing to delete");
			cordova.plugins.notification.local.cancel(id, function() {
				resolve(id);
			}, (err) => { console.log("Error during deleting notifications", err); reject(); });
		}.bind(this);

		return new Promise(resolver);
	}
}
