///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
////////// HANDY BITS
import { sequence,
         trigger,
         stagger,
         animate,
         style,
         group,
         query as q,
         transition,
         keyframes,
         animateChild } from '@angular/animations';
//import { VgControlsHidden } from 'videogular2/core';

////////////////////////////////
////////// CONSTANTS
const query = (s,a,o={optional:true})=>q(s,a,o);

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CONSTANT
let width = Math.min(1024, window.innerWidth); // Assuming 1024px is still the maximum width in styles.scss
let isIE11 = !!(window as any).MSInputMethodContext && !!(document as any).documentMode;
let transitionDef;
if (!isIE11) {
	transitionDef = [
	// Transition
	transition('* => *', [
		query(':enter, :leave', style({ position: 'absolute', width:width+"px" })),
		query(':enter', style({ transform: 'translateX(105%)' })),

		// Sequence
		sequence([
			query(':leave', animateChild()), 
			group([
				query(':leave', [
				style({ transform: 'translateX(0%)', opacity:1 }),
				animate('750ms cubic-bezier(.75,0,.26,1.32)',
					style({ transform: 'translateX(-12%)', opacity:0 }))
				]),
				query(':enter', [
				style({ transform: 'translateX(12%)', opacity:0 }),
				animate('750ms 300ms cubic-bezier(.75,-0.32,.26,1.32)',
					style({ transform: 'translateX(0%)', opacity:1 })),
				]),
			]),
			query(':enter', animateChild()),
		])
	])];
} else {
	transitionDef = [];
}

// Let's export;
export const pageRouterTransition = trigger('pageRouterTransition', transitionDef);