///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
////////// IMPORTS
import { Component, ElementRef,
		 Input, Output, ViewChild,
		 OnInit, AfterViewInit, EventEmitter } from '@angular/core';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
  selector:		'toggle',
  templateUrl:	'./control-toggle.component.html',
  styleUrls:	['./control-toggle.component.scss']
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class ControlToggleComponent implements OnInit, AfterViewInit {

	////////////////////////////////
	@Output() notify: EventEmitter<any> = new EventEmitter<any>();
	@Input() initialState:boolean = false;
	@Input() toggleID:string;
	@Input() disableToggle = false;
	@ViewChild('toggle') el:ElementRef;
	public stateOn:boolean = true;

	constructor() { }

	////////////////////////////////
	ngOnInit() { this.stateOn = this.initialState; }

	////////////////////////////////
	ngAfterViewInit() {
		this.alignToggle();
	}

	////////////////////////////////
	alignToggle() {
		let el				= this.el.nativeElement.querySelector(".nub");
		let desiredWidth	= el.offsetHeight;
		el.style.width		= el.offsetHeight+"px";

		let remainingWidth	= this.el.nativeElement.offsetWidth - desiredWidth;
		let widthPerc		= (remainingWidth / desiredWidth * 100) - 16;
		el.style.transform	= "translateX(-"+widthPerc+"%)";
	}

	////////////////////////////////
	toggleToggle() {
		if (!this.disableToggle)
			this.stateOn = !this.stateOn;
		this.notify.emit( { state:this.stateOn, id:this.toggleID } );
	}

	////////////////////////////////
	setState(state:boolean) {
		this.stateOn = state;
	}
}
