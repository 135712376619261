///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
////////// MODULES
import { Component, NgZone }				from '@angular/core';
import { Router }							from '@angular/router';
import { DataService }						from '../../services/data.service';

////////////////////////////////
////////// ANIMATION
const query = (s,a,o={optional:true})=>q(s,a,o);
import 'hammerjs';
import { trigger,
         stagger,
         animate,
         style,
         group,
         query as q,
         transition,
         keyframes} from '@angular/animations';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// ANIMATIONS
export const splashTransition = trigger('splashTransition',
[
	// TRANSITION: Enter
	transition(':enter', [
		query('img', style({ opacity: 0 })),
		query('img', stagger(300, [
			style({ transform: 'scale(0.8)' }),
			animate('2s 1.25s ease-out', style({transform: 'scale(1)', opacity: 1})),
		])),
	]),
	// TRANSITION: Leave
	transition(':leave', [
		query('body', [
			style({ opacity: '1' }),
			animate('2s ease-out', style({opacity: 0})),
		]),
	])
]);

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
  selector:		'app-splash',
  templateUrl:	'./page-splash.component.html',
  styleUrls:	['./page-splash.component.scss'],
  animations:	[ splashTransition ],
  host:			{ '[@splashTransition]': '' }
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class PageSplashComponent {

	////////////////////////////////
	constructor(private router: Router,	private ngZone: NgZone, private sData:DataService) {}
}
