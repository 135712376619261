////////////////////////////////
////////// MODULES
import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { ExerciseService } from '../../services/exercise.service';
import { UserService } from '../../services/user.service';
import { Push, PushObject, PushOptions } from '@ionic-native/push';

// TEMP VASAT
import { Vasat } from 'vasat';
import { TimeCircleComponent, TimerCompletionBehaviour, TimerDirection, TimerMode } from '../../components/time-circle/time-circle.component';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
	selector: 'app-home',
	templateUrl: './page-home.component.html',
	styleUrls: ['./page-home.component.scss'],
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class PageHomeComponent implements OnInit {
	@ViewChild('progressCircle') progressCircle: TimeCircleComponent;

	private goals: any[] = [];
	private goalIndex: number = 0;
	private dosages: any = {};
	private needsBalanceAssessment: boolean = null;
	private profilePhotoUrl: string;
	private secretDebugCounter: number = 0;

	////////////////////////////////
	constructor(
		private router: Router,
		private sData: DataService,
		private sExercise: ExerciseService,
		private sUser: UserService,
		private push: Push
	) { }
	ngOnInit() {
		this.secretDebugCounter = 0;

		this.sData.getGoals().then((res) => {
			this.goals = res.data.goals;
			if (this.goals.length) this.nextGoal(0);
		});

		if (this.sUser.userLoaded) {
			this.sExercise.getDoseageForThisWeek().then((res) => {
				this.dosages = res;

				// Setup the progress circle
				this.progressCircle.createTimer({
					timerMode: TimerMode.percentage,
					direction: TimerDirection.countup,
					totalValue: 100,
					elapsedValueMS: this.dosages.percentage * 1000,
					completionBehaviour: TimerCompletionBehaviour.reset,
				});
			});

			this.sExercise
				.needsBalanceAssessment()
				.then((res) => {
					this.needsBalanceAssessment = (res && res == true) || false;
				})
				.catch((err) => {
					console.log('Error getting goals', err);
				});
		}
	}

	////////////////////////////////
	nextGoal(index = null) {
		if (index !== null) {
			this.goalIndex = index;
		} else {
			this.goalIndex += 1;
			if (this.goalIndex > this.goals.length - 1) this.goalIndex = 0;
		}
		this.goals.map((i) => {
			i.state = '';
		});
		this.goals.map((i, index) => {
			if (index == this.goalIndex) i.state = 'on';
			return i;
		});
	}

	////////////////////////////////////
	secretDebug() {
		this.secretDebugCounter += 1;

		if (this.secretDebugCounter > 4) {
			this.router.navigate(['debugmod']);
		}

		setTimeout(() => (this.secretDebugCounter = 0), 5000);
	}
}
