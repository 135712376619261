///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
////////// MODULES
import { Component, OnInit }	from '@angular/core';
import { Router, Route }		from '@angular/router';


///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
  selector: 'app-page-onboarded3-tutorial',
  templateUrl: './page-onboarded3-tutorial.component.html',
  styleUrls: ['./page-onboarded3-tutorial.component.scss']
})


///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class PageOnboarded3TutorialComponent implements OnInit {

	////////////////////////////////////
	whichState:string = "";
	introText:string = "We're almost ready!  Let's quickly see what you can do in the app.";
	doneButtonText:string = "I'm ready to get started";
	nextButtonText:string = "Next Tutorial";
	toot_state:string = "off"
	toot_index:number = 0;
	toots:any[] = [
		{
			title: "Balance Assessment",
			desc: "Every month, we will ask you to complete a balance assessment.",
			images:[
				{ url:'toot_1-1_1.jpg', xP:0, yP:0, wP:100 },
				{ url:'toot_1-1_2.png', xP:5, yP:27, wP:50 },
				{ url:'toot_arrow_up_left.png', xP:50, yP:50, wP:11 }
			]
		},
		{
			title: "Balance Assessment",
			desc: "The Balance Assessment will help keep track of your balance. See if you can hold each position for up to 30 seconds.",
			images:[
				{ url:'toot_1-2_1.jpg', xP:0, yP:0, wP:100 },
				{ url:'toot_1-2_2.png', xP:33, yP:10, wP:37 },
				{ url:'toot_1-2_3.png', xP:10, yP:10, wP:20 }
			]
		},
		{
			title: "Exercising",
			desc: "It's important to complete your weekly dosage of StandingTall balance exercises.",
			images:[
				{ url:'toot_2-1_1.jpg', xP:0, yP:0, wP:100 },
				{ url:'toot_2-1_2.png', xP:50, yP:36, wP:50 },
			]
		},
		{
			title: "Exercising",
			desc: "Start a session by selecting the 'Exercise' button and choose your exercise duration.",
			images:[
				{ url:'toot_2-2_1.jpg', xP:0, yP:0, wP:100 },
				{ url:'toot_2-2_2.png', xP:9.25, yP:30, wP:40 },
				{ url:'toot_2-2_3.png', xP:38, yP:40, wP:20 },
			]
		},
		{
			title: "Progress",
			desc: "You can view your exercise adherence here as well as the results of your Balance Assessments.",
			images:[
				{ url:'toot_3-1_1.jpg', xP:0, yP:0, wP:100 }
			]
		},
		{
			title: "Calendar",
			desc: "We can remind you to exercise! Select a day and time to be reminded each week. Make sure you allow Notifications to be sent under Settings.",
			images:[
				{ url:'toot_4-1_1.jpg', xP:0, yP:0, wP:100 },
				{ url:'toot_4-1_2.png', xP:50, yP:0, wP:50 },
				{ url:'toot_4-1_3.png', xP:43, yP:48, wP:35 },
				{ url:'toot_4-1_4.png', xP:48, yP:88, wP:30 },
			]
		},
		{
			title: "Goals & Journal",
			desc: "If you need some encouragement, head to the goals and journal section. Jot down some goals to help you or log how you feel you have been progressing on your StandingTall journey.",
			images:[
				{ url:'toot_5-1_1.jpg', xP:0, yP:0, wP:100 }
			]
		},
		{
			title: "Help",
			desc: "Don't forget, if you have any questions or are unsure about anything, just head to the Help section and view the FAQs. You can also re-watch the tutorials.",
			images:[
				{ url:'toot_6-1_1.jpg', xP:0, yP:0, wP:100 },
				{ url:'toot_6-1_2.png', xP:30, yP:72, wP:25 },
				{ url:'toot_6-1_3.png', xP:52, yP:72, wP:12 }
			]
		},
		{
			title: "Settings",
			desc: "You can amend your preferences by visiting the Settings area of the app. If you have the option to change your dosage and exercise settings, you can do so here.",
			images:[
				{ url:'toot_7-1_1.jpg', xP:0, yP:0, wP:100 },
				{ url:'toot_7-1_2.png', xP:69, yP:4, wP:12 },
				{ url:'toot_7-1_3.png', xP:65, yP:20, wP:10 }
			]
		},
		{
			title: "Profile",
			desc: "You can log out or add new users by visiting the Profile section of the app.",
			images:[
				{ url:'toot_8-1_1.jpg', xP:0, yP:0, wP:100 },
				{ url:'toot_8-1_2.png', xP:82, yP:4, wP:15 },
				{ url:'toot_8-1_3.png', xP:80, yP:22, wP:10 }
			]
		}
	];

	////////////////////////////////////
	constructor(private router:Router) { }
	ngOnInit() {
		if (this.router.url == "/onboarded3/add") {
			this.whichState = "new";
		} else {
			this.whichState = "rewatch";
			this.introText = "How about a little refresher?  Let's take a look at what you can do.";
		}
		setTimeout(() => {
			this.goLive();
		}, 2000);
	}

	////////////////////////////////////
	goLive() { this.toot_state = "live"; }
	goOff() { this.toot_state = "off"; }

	////////////////////////////////////
	next() {
		if (this.toot_state == "off") return;
		let numTootImages = this.toots[this.toot_index].images.length;
		let cssTransTime = 500;
		let cssDelayTime = 200;
		let nextTime = (numTootImages * (cssTransTime - cssDelayTime)) + cssDelayTime;

		if (this.toot_index < this.toots.length-1) {
			this.goOff();
			
			setTimeout(() => { this.updateTootIndex(); }, nextTime);
			setTimeout(() => { this.goLive(); }, nextTime + 25);
		} else {
			this.skip();
		}
	}

	////////////////////////////////////
	updateTootIndex() {
		this.toot_index++;
		if (this.toot_index == this.toots.length-1) {
			this.nextButtonText = "Done";
		}
	}

	////////////////////////////////////
	skip() {
		if (this.whichState == "new") this.router.navigate(['/home']);
		else						  this.router.navigate(['/help']);
	}
}
