///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

////////////////////////////////
import { PageSplashComponent } from '../pages/page-splash/page-splash.component';
import { PageLoginComponent } from '../pages/page-login/page-login.component';
import { PageHomeComponent } from '../pages/page-home/page-home.component';
import { PageOnboardingComponent } from '../pages/page-onboarding/page-onboarding.component';
import { PageOnboardedComponent } from '../pages/page-onboarded/page-onboarded.component';
import { PageOnboarded2Component } from '../pages/page-onboarded2/page-onboarded2.component';
import { PageOnboarded3TutorialComponent } from '../pages/page-onboarded3-tutorial/page-onboarded3-tutorial.component';
import { PageNotFoundComponent } from '../pages/page-not-found/page-not-found.component';
import { PageSetupprofileComponent } from '../pages/page-setupprofile/page-setupprofile.component';
import { PageExercise1homeComponent } from '../pages/page-exercise1-home/page-exercise1home.component';
import { PageExercise3mainComponent } from '../pages/page-exercise3-main/page-exercise3main.component';
import { PageExercise4SessionexpiredComponent } from '../pages/page-exercise4-sessionexpired/page-exercise4-sessionexpired.component';
import { PageExercise5BalancehomeComponent } from '../pages/page-exercise5-balancehome/page-exercise5-balancehome.component';
import { PageExercise7TrialhomeComponent } from '../pages/page-exercise7-trialhome/page-exercise7-trialhome.component';
import { PageExercise8TrialSessioncompletedComponent } from '../pages/page-exercise8-trialsessioncompleted/page-exercise8-trialsessioncompleted.component';
import { PageGoalsAndJournal1homeComponent } from '../pages/page-goalsandjournal1home/page-goalsandjournal1home.component';
import { PageCalendar1homeComponent } from '../pages/page-calendar1home/page-calendar1home.component';
import { PageSettingsComponent } from '../pages/page-settings/page-settings.component';
import { PageSettingsprofileComponent } from '../pages/page-settingsprofile/page-settingsprofile.component';
import { PageSettingsexercisesComponent } from '../pages/page-settingsexercises/page-settingsexercises.component';
import { PageProgress1homeComponent } from '../pages/page-progress1home/page-progress1home.component';
import { PageProgress2graphComponent } from '../pages/page-progress2graph/page-progress2graph.component';
import { PageProgress3motivatemeComponent } from '../pages/page-progress3motivateme/page-progress3motivateme.component';
import { PageTermsConditionsComponent } from '../pages/page-termsconditions/page-termsconditions.component';
import { PagePrivacyPolicyComponent } from '../pages/page-privacypolicy/page-privacypolicy.component';
import { PageContentproblemComponent } from '../pages/page-contentproblem/page-contentproblem.component';
import { PageAdherenceComponent } from '../pages/page-adherence/page-adherence.component';
import { PageHelpComponent } from '../pages/page-help/page-help.component';
import { PageFaqsComponent } from '../pages/page-faqs/page-faqs.component';

import { CannotDeactivateWithBackButtonGuard } from '../guards/cannotdeactivatewithbackbutton.guard';

// DEBUG PAGE
import { PageDebugmodifiersComponent } from '../pages/page-debugmodifiers/page-debugmodifiers.component';
import { PageDebuganimationsComponent } from '../pages/page-debuganimations/page-debuganimations.component';
import { PageDebugExSessionsComponent } from '../pages/page-debugsessions/page-debugsessions.component';
import { PageDebugExSessionsBAComponent } from '../pages/page-debugsessionsba/page-debugsessionsba.component';
import { PageDebugTutorialAssetsComponent } from '../pages/page-debugtutorialassets/page-debugtutorialassets.component';
import { PageDebugTimersComponent } from '../pages/page-debugtimers/page-debugtimers.component';
import { PageDebugExGeneratorComponent } from '../pages/page-debugexgenerator/page-debugexgenerator.component';
import { PageExercise6SessioncompletedComponent } from './page-exercise6-sessioncompleted/page-exercise6-sessioncompleted.component';
import { CanActivateWithAuthGuard } from '../guards/canactivatewithauth.guard';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// SETUP ROUTES
const routes: Routes = [
	{ path: '', redirectTo: '/splash', pathMatch: 'full' },
	{
		path: 'splash',
		component: PageSplashComponent,
		data: { state: 'splash', acceptedAuth: ['all'], logoVisible: false, decorators: [] },
		canActivate: [CanActivateWithAuthGuard],
	},
	{
		path: 'content-problem',
		component: PageContentproblemComponent,
		data: { state: 'content-update', acceptedAuth: ['all'], logoVisible: true, decorators: [] },
		canActivate: [CanActivateWithAuthGuard],
	},
	{
		path: 'onboarding',
		component: PageOnboardingComponent,
		data: { state: 'onboarding', acceptedAuth: ['anonymous'], logoVisible: true, decorators: ['br-orange', 'bl-blue'] },
		canActivate: [CanActivateWithAuthGuard],
	},
	{
		path: 'onboarding/add',
		component: PageOnboardingComponent,
		data: { state: 'onboarding', acceptedAuth: ['loggedin'], logoVisible: false, decorators: ['br-orange', 'bl-blue'] },
		canActivate: [CanActivateWithAuthGuard],
	},
	{
		path: 'onboarded',
		component: PageOnboardedComponent,
		data: { state: 'onboarded', acceptedAuth: ['loggedin'], logoVisible: true, decorators: ['bl-orange', 'br-blue'] },
		canActivate: [CanActivateWithAuthGuard],
	},
	{
		path: 'onboarded2',
		component: PageOnboarded2Component,
		data: { state: 'onboarded2', acceptedAuth: ['loggedin'], logoVisible: true, decorators: ['bl-orange', 'br-blue'] },
		canActivate: [CanActivateWithAuthGuard],
	},
	{
		path: 'onboarded3/add',
		component: PageOnboarded3TutorialComponent,
		data: { state: 'onboarded3', acceptedAuth: ['loggedin'], logoVisible: true, decorators: ['bl-orange', 'br-blue'] },
		canActivate: [CanActivateWithAuthGuard],
	},
	{
		path: 'onboarded3/rewatch',
		component: PageOnboarded3TutorialComponent,
		data: { state: 'onboarded3', acceptedAuth: ['loggedin'], logoVisible: false, decorators: ['tr-orange', 'bl-blue'] },
		canActivate: [CanActivateWithAuthGuard],
	},
	//{ path: 'onboarded/add',				component: PageOnboardedComponent,						data: { state:'onboarded',	 		acceptedAuth:['all'],			logoVisible:false,	decorators:['bl-orange', 'br-blue'] }				canActivate: [CanActivateWithAuthGuard] },
	{
		path: 'setupprofile',
		component: PageSetupprofileComponent,
		data: { state: 'setupprofile', acceptedAuth: ['anonymous'], logoVisible: true, decorators: [] },
		canActivate: [CanActivateWithAuthGuard],
	},
	{
		path: 'setupprofile/add',
		component: PageSetupprofileComponent,
		data: { state: 'setupprofile', acceptedAuth: ['loggedin'], logoVisible: true, decorators: [] },
		canActivate: [CanActivateWithAuthGuard],
	},
	{
		path: 'login',
		component: PageLoginComponent,
		data: { state: 'login', acceptedAuth: ['anonymous'], logoVisible: true, decorators: ['tr-orange'] },
		canActivate: [CanActivateWithAuthGuard],
	},
	{
		path: 'login/add',
		component: PageLoginComponent,
		data: { state: 'loginadd', acceptedAuth: ['loggedin'], logoVisible: true, decorators: ['tr-orange'] },
		canActivate: [CanActivateWithAuthGuard],
	},
	{
		path: 'home',
		component: PageHomeComponent,
		data: { state: 'home', acceptedAuth: ['loggedin'], logoVisible: true, decorators: ['tr-blue', 'br-orange'] },
		canActivate: [CanActivateWithAuthGuard],
	},

	{ path: 'exercises', redirectTo: 'exercises/home', pathMatch: 'full' },
	{
		path: 'exercises/home',
		component: PageExercise1homeComponent,
		data: { state: 'exerises1home', acceptedAuth: ['loggedin'], logoVisible: false, decorators: ['bl-orange', 'br-blue'] },
		canActivate: [CanActivateWithAuthGuard],
	},
	{
		path: 'exercises/main',
		component: PageExercise3mainComponent,
		data: { state: 'exerises3main', acceptedAuth: ['loggedin'], logoVisible: false, decorators: ['bl-orange', 'br-blue'] },
		canActivate: [CanActivateWithAuthGuard],
		canDeactivate: [CannotDeactivateWithBackButtonGuard],
	},
	{
		path: 'exercises/expired/exercise',
		component: PageExercise4SessionexpiredComponent,
		data: { state: 'exerises4exp', acceptedAuth: ['loggedin'], logoVisible: true, decorators: ['bl-orange', 'br-blue'] },
		canActivate: [CanActivateWithAuthGuard],
		canDeactivate: [CannotDeactivateWithBackButtonGuard],
	},
	{
		path: 'exercises/expired/balance',
		component: PageExercise4SessionexpiredComponent,
		data: { state: 'exerises4exp', acceptedAuth: ['loggedin'], logoVisible: true, decorators: ['bl-orange', 'br-blue'] },
		canActivate: [CanActivateWithAuthGuard],
		canDeactivate: [CannotDeactivateWithBackButtonGuard],
	},
	{
		path: 'exercises/balance/home',
		component: PageExercise5BalancehomeComponent,
		data: { state: 'exerises5balhome', acceptedAuth: ['loggedin'], logoVisible: false, decorators: ['bl-orange', 'br-blue'] },
		canActivate: [CanActivateWithAuthGuard],
		canDeactivate: [CannotDeactivateWithBackButtonGuard],
	},
	{
		path: 'exercises/balance/main',
		component: PageExercise3mainComponent,
		data: { state: 'exerises5balmain', acceptedAuth: ['loggedin'], logoVisible: false, decorators: ['bl-orange', 'br-blue'] },
		canActivate: [CanActivateWithAuthGuard],
		canDeactivate: [CannotDeactivateWithBackButtonGuard],
	},
	{
		path: 'exercises/completed',
		component: PageExercise6SessioncompletedComponent,
		data: { state: 'exerises6completed', acceptedAuth: ['loggedin'], logoVisible: false, decorators: ['bl-orange', 'br-blue'] },
		canActivate: [CanActivateWithAuthGuard],
		canDeactivate: [CannotDeactivateWithBackButtonGuard],
	},
	{
		path: 'exercises/trial/home',
		component: PageExercise7TrialhomeComponent,
		data: { state: 'exerises1trial', acceptedAuth: ['loggedin'], logoVisible: false, decorators: ['bl-orange', 'br-blue'] },
		canActivate: [CanActivateWithAuthGuard],
	},
	{
		path: 'exercises/trial/main',
		component: PageExercise3mainComponent,
		data: { state: 'exerises3trial', acceptedAuth: ['loggedin'], logoVisible: false, decorators: ['bl-orange', 'br-blue'] },
		canActivate: [CanActivateWithAuthGuard],
		canDeactivate: [CannotDeactivateWithBackButtonGuard],
	},
	{
		path: 'exercises/trial/completed',
		component: PageExercise8TrialSessioncompletedComponent,
		data: { state: 'exerises6TrialDone', acceptedAuth: ['loggedin'], logoVisible: false, decorators: ['bl-orange', 'br-blue'] },
		canActivate: [CanActivateWithAuthGuard],
		canDeactivate: [CannotDeactivateWithBackButtonGuard],
	},

	{
		path: 'journal1home',
		component: PageGoalsAndJournal1homeComponent,
		data: { state: 'journal1home', acceptedAuth: ['loggedin'], logoVisible: false, decorators: ['tr-orange', 'bl-orange'] },
		canActivate: [CanActivateWithAuthGuard],
	},
	{
		path: 'calendar1home',
		component: PageCalendar1homeComponent,
		data: { state: 'calendar1home', acceptedAuth: ['loggedin'], logoVisible: false, decorators: ['tr-orange', 'bl-blue'] },
		canActivate: [CanActivateWithAuthGuard],
	},
	{
		path: 'settings',
		component: PageSettingsComponent,
		data: { state: 'settings', acceptedAuth: ['loggedin'], logoVisible: false, decorators: ['tr-blue', 'bl-blue', 'br-orange'] },
		canActivate: [CanActivateWithAuthGuard],
	},
	{
		path: 'settings/profile',
		component: PageSettingsprofileComponent,
		data: { state: 'settingsprofile', acceptedAuth: ['loggedin'], logoVisible: false, decorators: ['tr-blue', 'bl-blue', 'br-orange'] },
		canActivate: [CanActivateWithAuthGuard],
	},
	{
		path: 'settings/exercises',
		component: PageSettingsexercisesComponent,
		data: { state: 'settingsexers', acceptedAuth: ['loggedin'], logoVisible: false, decorators: ['tr-blue', 'bl-blue', 'br-orange'] },
		canActivate: [CanActivateWithAuthGuard],
	},

	{
		path: 'termsconditions',
		component: PageTermsConditionsComponent,
		data: { state: 'terms', acceptedAuth: ['all'], logoVisible: false, decorators: ['tr-blue', 'bl-blue', 'br-orange'] },
		canActivate: [CanActivateWithAuthGuard],
	},
	{
		path: 'privacy',
		component: PagePrivacyPolicyComponent,
		data: { state: 'privacy', acceptedAuth: ['all'], logoVisible: false, decorators: ['tr-blue', 'bl-blue', 'br-orange'] },
		canActivate: [CanActivateWithAuthGuard],
	},

	{ path: 'progress', redirectTo: 'progress/home', pathMatch: 'full' },
	{
		path: 'progress/home',
		component: PageProgress1homeComponent,
		data: { state: 'progresshome', acceptedAuth: ['loggedin'], logoVisible: false, decorators: ['tr-orange', 'bl-blue'] },
		canActivate: [CanActivateWithAuthGuard],
	},
	{
		path: 'progress/graph/:name',
		component: PageProgress2graphComponent,
		data: { state: 'progressgraph', acceptedAuth: ['loggedin'], logoVisible: false, decorators: ['tr-orange', 'bl-blue'] },
		canActivate: [CanActivateWithAuthGuard],
	},
	{
		path: 'progress/motivate',
		component: PageProgress3motivatemeComponent,
		data: { state: 'progressmotivate', acceptedAuth: ['loggedin'], logoVisible: false, decorators: ['tr-orange', 'bl-blue'] },
		canActivate: [CanActivateWithAuthGuard],
	},

	{
		path: 'adherence',
		component: PageAdherenceComponent,
		data: { state: 'adherence', acceptedAuth: ['loggedin'], logoVisible: true, decorators: ['tr-blue', 'bl-blue'] },
		canActivate: [CanActivateWithAuthGuard],
	},
	{
		path: 'help',
		component: PageHelpComponent,
		data: { state: 'help', acceptedAuth: ['loggedin'], logoVisible: false, decorators: ['tr-orange', 'bl-blue', 'br-orange'] },
		canActivate: [CanActivateWithAuthGuard],
	},
	{
		path: 'faqs',
		component: PageFaqsComponent,
		data: { state: 'faqs', acceptedAuth: ['loggedin'], logoVisible: false, decorators: ['tr-blue', 'bl-orange'] },
		canActivate: [CanActivateWithAuthGuard],
	},

	// DEBUG PAGES
	{
		path: 'debugmod',
		component: PageDebugmodifiersComponent,
		data: { state: 'debugmod', acceptedAuth: ['all'], logoVisible: false, decorators: ['tr-blue', 'bl-blue', 'br-orange'] },
		canActivate: [CanActivateWithAuthGuard],
	},
	{
		path: 'debugs',
		component: PageDebuganimationsComponent,
		data: { state: 'debuganims', acceptedAuth: ['all'], logoVisible: false, decorators: ['tr-blue', 'bl-blue', 'br-orange'] },
		canActivate: [CanActivateWithAuthGuard],
	},
	{
		path: 'debugsessions',
		component: PageDebugExSessionsComponent,
		data: { state: 'debugsessions', acceptedAuth: ['all'], logoVisible: false, decorators: ['tr-blue', 'bl-blue', 'br-orange'] },
		canActivate: [CanActivateWithAuthGuard],
	},
	{
		path: 'debugCategoryGenerator',
		component: PageDebugExGeneratorComponent,
		data: { state: 'debugCategoryGenerator', acceptedAuth: ['all'], logoVisible: false, decorators: ['tr-blue', 'bl-blue', 'br-orange'] },
		canActivate: [CanActivateWithAuthGuard],
	},
	{
		path: 'debugsessionsba',
		component: PageDebugExSessionsBAComponent,
		data: { state: 'debugsessions', acceptedAuth: ['all'], logoVisible: false, decorators: ['tr-blue', 'bl-blue', 'br-orange'] },
		canActivate: [CanActivateWithAuthGuard],
	},
	{
		path: 'debugtutorialassets',
		component: PageDebugTutorialAssetsComponent,
		data: { state: 'debugtutorialassets', acceptedAuth: ['all'], logoVisible: false, decorators: ['tr-blue', 'bl-blue', 'br-orange'] },
		canActivate: [CanActivateWithAuthGuard],
	},
	{
		path: 'debugtimers',
		component: PageDebugTimersComponent,
		data: { state: 'debugtimers', acceptedAuth: ['all'], logoVisible: false, decorators: ['tr-blue', 'bl-blue', 'br-orange'] },
		canActivate: [CanActivateWithAuthGuard],
	},

	// Catch-all
	{
		path: '**',
		component: PageNotFoundComponent,
		data: { state: 'notfound', acceptedAuth: ['all'], logoVisible: true, decorators: [] },
		canActivate: [CanActivateWithAuthGuard],
	},
];

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// SETUP MODULE
@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true })],
	exports: [RouterModule],
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class AppRoutingModule { }
