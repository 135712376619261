///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class AudioController {
	////////////////////////////////////
	lastBeepWasPlayer1: boolean = false;
	audioPlayerBeep1: any = null;
	audioPlayerBeep2: any = null;
	audioPlayerDuhDing: any = null;
	audioPlayersCognitive: any[] = [];
	platform: string;

	audioPlugin: any;

	////////////////////////////////////
	constructor(platform) {
		this.platform = platform;

		// Setup the audio player depending on your platform
		if (this.platform == 'Android') {
			if (window['plugins'] && window['plugins'].NativeAudio) this.audioPlugin = this.usePluginNativeAudio;
			else this.audioPlugin = this.useCoreMedia;
		} else if (this.platform == 'iOS') {
			if (window['plugins'] && window['plugins'].NativeAudio) this.audioPlugin = this.usePluginNativeAudio;
			else this.audioPlugin = this.useCoreMedia;
		} else {
			this.audioPlugin = this.useCoreAudio;
		}

		// A SO poster fixed a Safari audio delay bug with this one weird trick (https://stackoverflow.com/questions/9811429/html5-audio-tag-on-safari-has-a-delay)
		const AudioContext = (window as any).AudioContext || (window as any).webkitAudioContext;
		const audioCtx = new AudioContext();
	}

	////////////////////////////////////
	usePluginNativeAudio(file) {
		console.log('%c >> Setting up Native() Audio: ' + file, 'color:purple;');
		let id = this.simplifyFilepath(file);
		return {
			id: id,
			file: file,
			play: function () {
				console.log('%c >> Playing Native() Audio:', 'color:purple', this.id);
				window['plugins'].NativeAudio.play(this.id);
			},
			stop: function () {
				window['plugins'].NativeAudio.stop(this.id);
			},
			preload: function () {
				console.log('%c >> Preloading Native() Audio:', 'color:purple', this.id);
				window['plugins'].NativeAudio.preloadComplex(
					this.id,
					this.file,
					1,
					1,
					0,
					function (msg) {
						console.log('Preloaded:', this.id);
					}.bind(this),
					function (msg) {
						console.log('Error during preload:', msg);
					}.bind(this)
				);
			},
			release: function () {
				console.log('%c >> Releasing Native() Audio:', 'color:purple', this.id);
				window['plugins'].NativeAudio.unload(this.id);
			},
		};
	}

	////////////////////////////////////
	useCoreMedia(file) {
		console.log('%c >> Setting up Media() Audio: ' + file, 'color:purple;');
		let Media = window['Media'];
		let id = this.simplifyFilepath(file);
		return {
			id: id,
			media: new Media(file, this.audioSuccess, this.audioError),
			file: file,
			play: function () {
				console.log('%c >> Playing Media() Audio:', 'color:purple', this.id);
				this.media.play();
			},
			stop: function () {
				this.media.stop();
			},
			preload: function () {
				console.log('%c >> Preloading Media() Audio:', 'color:purple', this.id);
				this.media.player.setVolume('0.0');
				setTimeout(() => this.media.setVolume('1.0'), 4000);
				this.media.play();
			},
			release: function () {
				console.log('%c >> Releasing Media() Audio:', 'color:purple', this.id);
				this.media.release();
			},
		};
	}

	////////////////////////////////////
	useCoreAudio(file) {
		console.log('%c >> Setting up Core() Audio: ' + file, 'color:purple;');
		let id = this.simplifyFilepath(file);
		let player = new Audio();
		player.src = file;
		player.addEventListener('ended', (event) => {
			player.volume = 1;
		}); // Add an event to reset volume to 1 (for browser preload)

		return {
			id: id,
			media: player,
			file: file,
			play: function () {
				console.log('%c >> Playing Core() Audio:', 'color:purple', this.id);
				this.media.play();
			},
			stop: function () {
				this.media.stop();
			},
			preload: function () {
				console.log('%c >> Preloading Core() Audio:', 'color:purple', this.id);
				this.media.volume = 0.0;
				this.media.play();
			},
			release: function () {
				console.log('%c >> Releasing Core() Audio:', 'color:purple', this.id);
				this.media.src = '';
			},
		};
	}

	////////////////////////////////////
	setupBeepPlayer() {
		let beepURL = 'assets/animations/audio/beep.wav';
		if (this.audioPlayerBeep1 === null) {
			this.audioPlayerBeep1 = { key: 'beep', player: this.audioPlugin(beepURL) };
		}
		if (this.audioPlayerBeep2 === null) {
			this.audioPlayerBeep2 = { key: 'beep', player: this.audioPlugin(beepURL) };
		}
	}

	////////////////////////////////////
	setupDuhDingPlayer() {
		if (this.audioPlayerDuhDing === null) {
			let duhDingURL = 'assets/animations/audio/duh-ding.mp3';
			this.audioPlayerDuhDing = { key: 'duhding', player: this.audioPlugin(duhDingURL) };
		}
	}

	////////////////////////////////////
	setupCognitivePlayers(cognitiveKeys: any[]) {
		let pathBase = 'assets/animations/audio/';

		cognitiveKeys.forEach((cp) => {
			let player = { key: cp, player: this.audioPlugin(pathBase + cp + '.mp4') };
			this.audioPlayersCognitive.push(player);
		});
	}

	////////////////////////////////////
	findDeviceSpecificPath(relativeUrl: string): string {
		let cordova = window['cordova'];

		if (this.platform == 'Android') {
			return cordova.file.applicationDirectory + 'www/' + relativeUrl;
		} else {
			return relativeUrl;
		}
	}

	////////////////////////////////////
	preloadAudio() {
		if (this.audioPlayerBeep1) this.audioPlayerBeep1.player.preload();
		if (this.audioPlayerBeep2) this.audioPlayerBeep2.player.preload();
		if (this.audioPlayerDuhDing) this.audioPlayerDuhDing.player.preload();
		this.audioPlayersCognitive.forEach((plr) => {
			plr.player.preload();
		});
	}

	////////////////////////////////////
	playAudio(key) {
		if (key == 'beep') {
			if (this.lastBeepWasPlayer1) {
				this.audioPlayerBeep2.player.play();
				this.lastBeepWasPlayer1 = false;
			} else {
				this.audioPlayerBeep1.player.play();
				this.lastBeepWasPlayer1 = true;
			}
		} else if (key == 'duh-ding') {
			this.audioPlayerDuhDing.player.play();
		} else {
			let player = this.audioPlayersCognitive.find((it) => it.key == key).player;
			player.play();
		}
	}

	////////////////////////////////////
	resetAudio(which = 'all') {
		console.log('%c>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', 'color:purple');
		console.log('%c >> Reset audio: ' + which, 'color:purple');
		if (this.platform == 'Android' || this.platform == 'iOS') {
			if (which == 'all' || which == 'beep') {
				if (this.audioPlayerBeep1 && this.audioPlayerBeep1.player) {
					this.audioPlayerBeep1.player.release();
					this.audioPlayerBeep1 = null;
				}
				if (this.audioPlayerBeep2 && this.audioPlayerBeep2.player) {
					this.audioPlayerBeep2.player.release();
					this.audioPlayerBeep2 = null;
				}
			}

			if (which == 'all' || which == 'duhding') {
				if (this.audioPlayerDuhDing && this.audioPlayerDuhDing.player) {
					this.audioPlayerDuhDing.player.release();
					this.audioPlayerDuhDing = null;
				}
			}

			if (which == 'all' || which == 'cognitive') {
				this.audioPlayersCognitive.forEach((plr) => plr.player.release());
				this.audioPlayersCognitive = [];
			}
		} else {
			if (which == 'all' || which == 'cognitive') {
				this.audioPlayersCognitive.forEach((plr) => plr.player.release());
				this.audioPlayersCognitive = [];
			}
		}
	}

	////////////////////////////////////
	/*() { // Used to try to fool iOS Safari to play these within a user click event
		this.audioPlayerBeep.player.play(); this.audioPlayerBeep.player.pause(); this.audioPlayerBeep.player.currentTime = 0;
		this.audioPlayerDuhDing.player.play(); this.audioPlayerDuhDing.player.pause(); this.audioPlayerDuhDing.player.currentTime = 0;
		this.audioPlayersCognitive.forEach(player => {
			player.player.play(); player.player.pause(); player.player.currentTime = 0;
		});
	}*/

	////////////////////////////////////
	simplifyFilepath(file) {
		let returnFile = file.substring(0, file.indexOf('.'));
		returnFile = returnFile.replace(/\//g, '');

		console.log('Simplified audio ID to:', returnFile);
		return returnFile;
	}

	////////////////////////////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////////////////////////////////////////////// ANDROID STATUS CALLBACKS
	audioSuccess() {}
	audioError() {}
	audioStatus() {}
}
