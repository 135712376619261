///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS
import { Component, OnInit, HostBinding, Input }	from '@angular/core';
import { GlobalPubSub }								from '../../services/global-pub-sub.service';


///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// COMPONENT
@Component({
	selector:		'toast-confirm',
	templateUrl:	'./toast-confirm.component.html',
	styleUrls:		['./toast-confirm.component.scss']
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class ToastConfirmComponent implements OnInit {

	// Core
	content:string = "";
	dismissTimeDefault:number = 6500;
	positiveButtonText:string = "";
	positiveButtonTextDefault:string = "OK";
	positiveResponseCallback:Function;
	negativeButtonText:string = "";
	negativeButtonTextDefault:string = "Cancel";
	negativeResponseCallback:Function;

	// Decorators
	@HostBinding('class.on') on: boolean = false;

	////////////////////////////////////
	constructor(private sGlobalPubSub:GlobalPubSub) { }
	ngOnInit() {
		this.sGlobalPubSub.subscribe('toast-confirm', this.eventFired.bind(this));
		this.sGlobalPubSub.subscribe('toast-confirm-hide', this.hide.bind(this));
	}

	////////////////////////////////////
	eventFired(show, content:string = "No content set", positiveButtonText:string = this.positiveButtonTextDefault, positiveResponseCallback:Function, negativeButtonText:string = this.negativeButtonTextDefault, negativeResponseCallback:Function = null) {

		// Set the state and content
		if (show) this.on = true; else this.on = false;
		this.content = content;

		// Positive
		this.positiveButtonText = positiveButtonText;
		this.positiveResponseCallback = () => { positiveResponseCallback(); this.hide(); };

		// Negative
		this.negativeButtonText = negativeButtonText;
		if (negativeResponseCallback) this.negativeResponseCallback = () => { negativeResponseCallback(); this.hide(); }
		else this.negativeResponseCallback = this.negativeResponseCallbackDefault;
	}

	////////////////////////////////////
	negativeResponseCallbackDefault() {	this.hide(); }

	////////////////////////////////////
	hide() {
		this.on = false;
	}
}
