///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
////////// MODULES
import { Component, OnInit, Input, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GraphComponent } from '../../st-commons/graph/graph.component';
import { DataService } from '../../services/data.service';
import { UserService } from '../../services/user.service';

declare var require; // Otherwise causes an error on jD's comp.  Will resolve correctly later and remove this line.
const M = require('moment');

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
	selector: 'app-page-progress2graph',
	templateUrl: './page-progress2graph.component.html',
	styleUrls: ['./page-progress2graph.component.scss']
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class PageProgress2graphComponent implements OnInit, AfterViewInit {

	@ViewChild('graph') graph: GraphComponent;
	name: string = "name";
	displayName: string = "";
	monthly1On: string = "";
	monthly3On: string = "";
	monthly6On: string = "";
	balanceOn: string = "";
	maxBalanceResultsDisplay: number = 14;

	graphData: any;
	graphOptions: any;

	minPlots: number = 6;
	totalValues: number;
	noData: boolean = false;

	////////////////////////////////
	constructor(private route: ActivatedRoute, private sData: DataService, private sUser: UserService, private router: Router) { }
	ngOnInit() {
		this.route.params.subscribe(params => {
			this.name = params['name'];
		});
		this.totalValues = 0;
		this.preparePage();
	}
	ngAfterViewInit() {
		//this.preparePage(); // jD Removed to stop expressionchange error
	}

	////////////////////////////////
	preparePage(): void {
		switch (this.name) {
			case "1month":
				this.displayName = "1 Month";
				this.getMonthlyDataAndOptions(1).then(this.createGraph.bind(this));
				break;
			case "3month":
				this.displayName = "3 Month";
				this.getMonthlyDataAndOptions(3).then(this.createGraph.bind(this));
				break;
			case "6month":
				this.displayName = "6 Month";
				this.getMonthlyDataAndOptions(6).then(this.createGraph.bind(this));
				break;
			case "balance":
				this.displayName = "Balance Assessments";
				this.getBalanceDataAndOptions().then(this.createGraph.bind(this));
				break;
		}
		this.updateButtons(this.name);
	}

	////////////////////////////////////
	updateButtons(which) {
		this.monthly1On = ""; this.monthly3On = ""; this.monthly6On = ""; this.balanceOn = "";
		if (which == "1month") this.monthly1On = "disabled";
		if (which == "3month") this.monthly3On = "disabled";
		if (which == "6month") this.monthly6On = "disabled";
		if (which == "balance") this.balanceOn = "disabled";
	}

	////////////////////////////////////
	createGraph() {
		if (!this.totalValues) this.noData = true;
		this.graph.createGraph(this.graphData);
	}

	////////////////////////////////////
	getMonthlyDataAndOptions(numMonths) {
		let resolver = function (resolve, reject) {

			// Instantiation
			let weeks = [];
			let data = [];

			// Current week and year
			let currentWeek = M().week();
			let currentYear = M().year();

			// Determine our first and last week
			let numWeeks = Math.round(numMonths * 4.333);

			for (let x = 0; x < numWeeks; x++) {
				let week = currentWeek - x;
				let year = currentYear;
				if (week < 0) {

					// week = 51; // Changed to 51 because the weeks start at 0, not 1
					week = 52 + week; //Fixes issue where everything previous to this year was being set to 51 (in this case week is always negative, so we have to add it)
					year = currentYear - 1;
				}

				let digitExtender = "";

				if (week >= 0 && week <= 9) {
					digitExtender = "0"
				}
				weeks.splice(0, 0, (year + " " + digitExtender + week));
			}

			// Create week entries for all weeks
			weeks = weeks.map(week => {
				// let completedHours = JSON.stringify(this.sUser.user.completedHours) || {};
				let completedHours = this.sUser.user.completedHours || {};
				let completedCognitiveHours = this.sUser.user.completedCognitiveHours || {};

				let valueBase = Math.round(completedHours[this.prepareWeekCode(week)]) || Math.round(completedHours[this.prepareWeekCode(week, true)]) || 0;
				let valueCog = Math.round(completedCognitiveHours[this.prepareWeekCode(week)]) || Math.round(completedCognitiveHours[this.prepareWeekCode(week, true)]) || 0;

				let value = (valueBase > 0 && valueBase || 0) + (valueCog > 0 && valueCog || 0);
				if (value == 0) value = NaN;
				data.push(value);
				if (value >= 0) this.totalValues++;
				let pieces = week.split(" ");

				return M().year(pieces[0]).week(pieces[1]).startOf('isoWeek').format('MMM DD');
			});

			this.graphData = {
				labels: weeks,
				datasets: [{
					label: 'Weekly exercise',
					data: data,
					spanGaps: true,
				}]
			}

			resolve();
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////
	prepareWeekCode(week: string, justASingleDigitPlease: boolean = false) {
		const date = week.split(' ');
		const year = date[0];

		let weekNumber;
		if (justASingleDigitPlease) weekNumber = ("00" + date[1]).slice(-2);
		else weekNumber = ("00" + date[1]).slice(-1);
		return `${year}_${weekNumber}`;
	}

	////////////////////////////////
	getBalanceDataAndOptions() {
		let resolver = function (resolve, reject) {

			// Instantiation
			let months = [];
			let data = [];

			// Get and sort keys
			let balancePerMonth = this.sUser.user.balancePerMonth || {};
			let keys = Object.keys(balancePerMonth).sort((a, b) => { if (a < b) return -1; else return 1; });
			if (keys.length > this.maxBalanceResultsDisplay) keys.splice(0, keys.length - this.maxBalanceResultsDisplay);

			// Create week entries for all weeks
			keys.forEach(ba => {
				let pieces = ba.split("_");
				months.push(M().year(pieces[0]).month(parseInt(pieces[1]) - 1).format('MMM'));
				data.push(balancePerMonth[ba]);
				this.totalValues++;
			});

			this.graphData = {
				labels: months,
				datasets: [{
					label: 'Balance Assessment time',
					data: data,
					spanGaps: true,
				}]
			}

			resolve();
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////////
	switch(which) {
		this.noData = false;
		this.totalValues = 0;
		if (which == "1month") this.name = "1month";
		if (which == "3month") this.name = "3month";
		if (which == "6month") this.name = "6month";
		if (which == "balance") this.name = "balance";
		this.preparePage();
	}
}
