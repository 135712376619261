interface BoxPropertiesInterface {
	beat?: number;
	position?: string;
	positionR?: string;
	isLeft?: boolean;
	leftNeutral?: string;
	rightNeutral?: string;
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class Box_Sequences {
	// Cardio Box: Tapping
	static CardioTappingUp(beat: number) {
		return [
			{
				type: 'box',
				animation: 'Step_Left',
				durationInSecs: beat,
				rotation: 0,
				footLeft: 'box-left-inner-bot',
				stance: 'forward',
				beep: false,
			},
			{
				type: 'box',
				animation: 'Step_Left',
				durationInSecs: beat,
				rotation: 0,
				footLeft: 'box-bottom-outer',
				stance: 'flat',
				beep: true,
			},
			{
				type: 'box',
				animation: 'Step_Right',
				durationInSecs: beat,
				rotation: 0,
				footRight: 'box-right-inner-bot',
				stance: 'forward',
				beep: false,
			},
			{
				type: 'box',
				animation: 'Step_Right',
				durationInSecs: beat,
				rotation: 0,
				footRight: 'box-bottom-outer',
				stance: 'flat',
				beep: true,
			},
		];
	}

	// Cardio Box: Tapping Down
	static CardioTappingDown(beat: number) {
		return [
			{
				type: 'box',
				animation: 'Step_Left',
				durationInSecs: beat,
				rotation: 0,
				footLeft: 'box-bottom-outer',
				stance: 'forward',
				beep: true,
			},
			{
				type: 'box',
				animation: 'Step_Left',
				durationInSecs: beat,
				rotation: 0,
				footLeft: 'box-left-inner-bot',
				stance: 'flat',
				beep: true,
			},
			{
				type: 'box',
				animation: 'Step_Right',
				durationInSecs: beat,
				rotation: 0,
				footRight: 'box-bottom-outer',
				stance: 'forward',
				beep: true,
			},
			{
				type: 'box',
				animation: 'Step_Right',
				durationInSecs: beat,
				rotation: 0,
				footRight: 'box-right-inner-bot',
				stance: 'flat',
				beep: true,
			},
		];
	}

	// Cardio Box: Tapping
	static CardioSteppingUp(beat: number) {
		return [
			{
				type: 'box',
				animation: 'Step_Left',
				durationInSecs: beat,
				rotation: 0,
				footLeft: 'box-middle',
				stance: 'flat',
				beep: true,
			},
			{
				type: 'box',
				animation: 'Step_Right',
				durationInSecs: beat,
				rotation: 0,
				footRight: 'box-middle',
				stance: 'flat',
				beep: true,
			},
			{
				type: 'box',
				animation: 'Step_Left',
				durationInSecs: beat,
				rotation: 0,
				footLeft: 'box-bottom-outer',
				stance: 'flat',
				beep: false,
			},
			{
				type: 'box',
				animation: 'Step_Right',
				durationInSecs: beat,
				rotation: 0,
				footRight: 'box-bottom-outer',
				stance: 'flat',
				beep: false,
			},
		];
	}
}
