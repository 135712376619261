///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
////////// IMPORTS
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ExerciseService } from '../../services/exercise.service';
import { GlobalPubSub } from '../../services/global-pub-sub.service';
import { DebugLoggerService } from '../../services/debugLogger.service';


///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
	selector: 'app-page-exercise4-sessionexpired',
	templateUrl: './page-exercise4-sessionexpired.component.html',
	styleUrls: ['./page-exercise4-sessionexpired.component.scss']
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class PageExercise4SessionexpiredComponent implements OnInit {

	////////////////////////////////
	maximumSessionTime:any;
	created:any;
	elapsed:any;
	triedToSaveOnce:boolean = false;
	whichState:string = "exercise";

	////////////////////////////////
	constructor(private router: Router,
				private sExercise:ExerciseService,
				private sGlobalPubSub:GlobalPubSub,
				private sDebugLogger:DebugLoggerService) {
	}

	ngOnInit() {
		let times = this.sExercise.exerciseTimeoutTime();
		var created = this.sExercise.exercise_session && this.sExercise.exercise_session.created || this.sExercise.balance_assessment_session && this.sExercise.balance_assessment_session.created || 0;
		this.maximumSessionTime = times.total.mins;
		this.elapsed = times.elapsed.mins + " minutes";
		this.created = new Date(created).toLocaleTimeString('en-US');

		if (this.router.url == "/exercises/expired/balance") {
			this.whichState = "balance";
		}
	}

	////////////////////////////////
	onBackButton(skipSave:boolean = false) {

		if (skipSave || this.whichState == "balance") {
			this.sExercise.clearSession();
			this.router.navigate(['/home']);
		}

		else {
			this.sExercise.saveSession()
			.then(res => {
				this.sExercise.clearSession();
				this.router.navigate(['/home']);
			})
			.catch(err => {
				let printableErr = err && err.message || err || "Unknown server issue";
				this.sGlobalPubSub.fireEvent("toast", [true, "Cannot quit right now: there was a problem saving your completed exercises: "+printableErr+".  Please check that you have a stable Internet connection and try again.", 10000]);
				this.triedToSaveOnce = true;
			})
		}
	}
}
