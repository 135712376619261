///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS
import { HttpHandler,
    HttpProgressEvent,
    HttpInterceptor,
    HttpSentEvent,
    HttpHeaderResponse,
    HttpUserEvent,
    HttpRequest,
    HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

export class CustomHttpInterceptorService implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
		// Only turn off caching for API calls to the server.
		if (req.url.indexOf('/api/') >= 0) {
			const nextReq = req.clone({
				headers: req.headers.set('Cache-Control', 'no-cache')
					.set('Pragma', 'no-cache')
					.set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
					.set('If-Modified-Since', '0')
			});

			return next.handle(nextReq);
		} else {
			// Pass the request through unchanged.
			return next.handle(req);
		}
	}
}