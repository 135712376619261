///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS
import { Component, OnInit } from '@angular/core';
import { Vasat } from 'vasat';
import { DataService } from '../../services/data.service';
import { STExerciseLogic } from '../../st-commons/exercise_logic';
import { ExerciseService } from '../../services/exercise.service';
import { GlobalPubSub } from '../../services/global-pub-sub.service';
import { UserService } from '../../services/user.service';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
	selector: 'app-page-debugsessionsba',
	templateUrl: './page-debugsessionsba.component.html',
	styleUrls: ['./page-debugsessionsba.component.scss'],
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class PageDebugExSessionsBAComponent implements OnInit {
	needsBalanceAssessment: boolean | null = null;
	stExerciseLogic = new STExerciseLogic(this.V);
	testResults: string = 'Test Results';

	////////////////////////////////
	constructor(
		private sUser: UserService,
		private sGlobalPubSub: GlobalPubSub,
		private sExercise: ExerciseService,
		private sData: DataService,
		private V: Vasat
	) {}

	////////////////////////////////
	ngOnInit() {
		this.checkBalanceAssessment();
	}

	/**
	 * Checks if the user needs a BA
	 * */
	checkBalanceAssessment() {
		this.sExercise.needsBalanceAssessment().then((needsIt) => {
			this.needsBalanceAssessment = needsIt as boolean;
		});
	}

	/**
	 * Sets BA to required for this user
	 * */
	toggleBA() {
		if (this.needsBalanceAssessment) {
			this.sUser.user.lastBalanceAssesment = new Date().getTime();
		} else {
			this.sUser.user.lastBalanceAssesment = 0;
		}

		// Update the user profile
		this.sData.updateUserProfile(this.sUser.user, true, ['lastBalanceAssesment']).then((res) => {
			this.sData.sendAllServerUpdates();
		});

		// Check the balance assessment formally
		this.checkBalanceAssessment();
	}

	/**
	 * If you have an outstanding BA, marks it as complete with high or low scores
	 * */
	performBA(setScoresHigh: boolean = false) {
		let duration = (setScoresHigh && 30) || 1;

		if (this.needsBalanceAssessment) {
			this.sExercise.createNewBalanceAssessment().then((session) => {
				// Update the exercise actions
				this.sExercise.balance_assessment_session.exercise_actions = {
					...this.sExercise.balance_assessment_session.exercise_actions.map((ea) => {
						ea.duration = duration;
						ea.complete = true;
						ea.exerciseEndTime = new Date().getTime();
						return ea;
					}),
				};

				// Update the session
				this.sExercise.balanceAssessmentTotalDuration = duration * Object.keys(this.sExercise.balance_assessment_session.exercise_actions).length;

				this.sExercise.saveBalanceAssessment(this.sExercise.balance_assessment_session.exercise_actions).then((result) => {
					this.checkBalanceAssessment();
					this.sGlobalPubSub.fireEvent('toast', [true, 'Balance Assessment updated.', 5000]);
				});
			});
			this.sGlobalPubSub.fireEvent('toast', [
				true,
				'Note: performing BAs in the debug will add your time to the current month, on top of whatever time you have already done BA exercises for.',
				7500,
			]);
		} else {
			this.sGlobalPubSub.fireEvent('toast', [true, 'You do not have an outstanding Balance Assessment.', 5000]);
		}
	}

	/**
	 * Forces the user to perform an initial BA again
	 * Useful for testing the initial BA set
	 * */
	resetInitialBA() {
		this.sUser.user.lastBalanceAssesment = null;
		this.sUser.user.balancePerMonth[Object.keys(this.sUser.user.balancePerMonth).sort((a, b) => (a < b && -1) || 1)[0]] = 0;

		// Update the user profile
		this.sData.updateUserProfile(this.sUser.user, true, ['lastBalanceAssesment', 'balancePerMonth']).then((res) => {
			this.sData.sendAllServerUpdates();
		});

		// Check the balance assessment formally
		this.checkBalanceAssessment();

		this.sGlobalPubSub.fireEvent('toast', [
			true,
			"User's initial BA has been reset. Note: this is used to check that 'new' users (or simulated new users) are progressed to the correct checkpoints.  Actual BA time will be applied to the current month.",
			10000,
		]);
	}

	////////////////////////////////
	testBALevels() {
		let randoSets = [];
		let randoHighSets = [];
		let resultSets = [
			{
				expected: 'A',
				FTFl: 30,
				NTFl: 30,
				TFl: 30,
				LLFl: 30,
				RLFl: 30,
				FTFo: 30,
				NTFo: 30,
				TFo: 30,
				LLFo: 30,
				RLFo: 30,
			},
			{
				expected: 'A (leg averaged)',
				FTFl: 30,
				NTFl: 30,
				TFl: 30,
				LLFl: 30,
				RLFl: 0,
				FTFo: 30,
				NTFo: 30,
				TFo: 30,
				LLFo: 30,
				RLFo: 0,
			},
			{
				expected: 'A (leg averaged)',
				FTFl: 30,
				NTFl: 30,
				TFl: 30,
				LLFl: 0,
				RLFl: 30,
				FTFo: 30,
				NTFo: 30,
				TFo: 30,
				LLFo: 0,
				RLFo: 30,
			},
			{
				expected: 'C (leg average just under 15)',
				FTFl: 30,
				NTFl: 30,
				TFl: 30,
				LLFl: 29,
				RLFl: 0,
				FTFo: 30,
				NTFo: 30,
				TFo: 30,
				LLFo: 29,
				RLFo: 0,
			},
			{
				expected: 'C (leg average just under 15)',
				FTFl: 30,
				NTFl: 30,
				TFl: 30,
				LLFl: 0,
				RLFl: 29,
				FTFo: 30,
				NTFo: 30,
				TFo: 30,
				LLFo: 0,
				RLFo: 29,
			},
			{
				expected: 'B',
				FTFl: 30,
				NTFl: 30,
				TFl: 30,
				LLFl: 30,
				RLFl: 30,
				FTFo: 0,
				NTFo: 0,
				TFo: 0,
				LLFo: 0,
				RLFo: 0,
			},
			{
				expected: 'C',
				FTFl: 30,
				NTFl: 30,
				TFl: 30,
				LLFl: 0,
				RLFl: 0,
				FTFo: 30,
				NTFo: 30,
				TFo: 30,
				LLFo: 30,
				RLFo: 30,
			},
			{
				expected: 'D',
				FTFl: 30,
				NTFl: 30,
				TFl: 0,
				LLFl: 0,
				RLFl: 0,
				FTFo: 0,
				NTFo: 0,
				TFo: 0,
				LLFo: 0,
				RLFo: 0,
			},
			{
				expected: 'E',
				FTFl: 30,
				NTFl: 0,
				TFl: 0,
				LLFl: 0,
				RLFl: 0,
				FTFo: 0,
				NTFo: 0,
				TFo: 0,
				LLFo: 0,
				RLFo: 0,
			},
			{
				expected: 'A',
				FTFl: 20,
				NTFl: 20,
				TFl: 20,
				LLFl: 20,
				RLFl: 20,
				FTFo: 20,
				NTFo: 20,
				TFo: 20,
				LLFo: 20,
				RLFo: 20,
			},
			{
				expected: 'D',
				FTFl: 30,
				NTFl: 30,
				TFl: 10,
				LLFl: 10,
				RLFl: 10,
				FTFo: 30,
				NTFo: 30,
				TFo: 0,
				LLFo: 0,
				RLFo: 0,
			},
			{
				expected: 'D',
				FTFl: 30,
				NTFl: 20,
				TFl: 5,
				LLFl: 0,
				RLFl: 30,
				FTFo: 20,
				NTFo: 15,
				TFo: 0,
				LLFo: 0,
				RLFo: 15,
			},
			{
				expected: 'D',
				FTFl: 30,
				NTFl: 20,
				TFl: 5,
				LLFl: 30,
				RLFl: 0,
				FTFo: 20,
				NTFo: 15,
				TFo: 0,
				LLFo: 4,
				RLFo: 0,
			},
			{
				expected: 'D',
				FTFl: 30,
				NTFl: 30,
				TFl: 10,
				LLFl: 25,
				RLFl: 13,
				FTFo: 27,
				NTFo: 20,
				TFo: 0,
				LLFo: 15,
				RLFo: 0,
			},
			{
				expected: 'C',
				FTFl: 30,
				NTFl: 30,
				TFl: 19,
				LLFl: 1,
				RLFl: 18,
				FTFo: 27,
				NTFo: 20,
				TFo: 15,
				LLFo: 0,
				RLFo: 15,
			},
			{
				expected: "B (though Jess' spreadsheet suggested 'C')",
				FTFl: 30,
				NTFl: 30,
				TFl: 19,
				LLFl: 30,
				RLFl: 1,
				FTFo: 27,
				NTFo: 20,
				TFo: 15,
				LLFo: 15,
				RLFo: 4,
			},
			{
				expected: 'D (Tandem override)',
				FTFl: 30,
				NTFl: 30,
				TFl: 14,
				LLFl: 30,
				RLFl: 30,
				FTFo: 30,
				NTFo: 30,
				TFo: 0,
				LLFo: 30,
				RLFo: 30,
			},
		];

		// Now add some randoms:
		for (let x = 0; x < 10; x++) {
			let val1 = val();
			let val2 = (val1 >= 15 && val()) || 0;
			let val3 = val();
			let val4 = (val3 >= 15 && val()) || 0;
			let val5 = val();
			let val6 = (val5 >= 15 && val()) || 0;
			let val7 = val();
			let val8 = (val7 >= 15 && val()) || 0;
			let val9 = val();
			let val10 = (val9 >= 15 && val()) || 0;

			randoSets.push({ FTFl: val1, FTFo: val2, NTFl: val3, NTFo: val4, TFl: val5, TFo: val6, LLFl: val7, LLFo: val8, RLFl: val9, RLFo: val10 });
		}

		// Now add some randoms:
		for (let x = 0; x < 10; x++) {
			let val1 = highVal();
			let val2 = (val1 >= 15 && highVal()) || 0;
			let val3 = highVal();
			let val4 = (val3 >= 15 && highVal()) || 0;
			let val5 = highVal();
			let val6 = (val5 >= 15 && highVal()) || 0;
			let val7 = highVal();
			let val8 = (val7 >= 15 && highVal()) || 0;
			let val9 = highVal();
			let val10 = (val9 >= 15 && highVal()) || 0;

			randoHighSets.push({
				FTFl: val1,
				FTFo: val2,
				NTFl: val3,
				NTFo: val4,
				TFl: val5,
				TFo: val6,
				LLFl: val7,
				LLFo: val8,
				RLFl: val9,
				RLFo: val10,
			});
		}

		this.testResults = '<h1>Curated Sets</h1>';
		resultSets.forEach((tr) => {
			let results = this.stExerciseLogic.calculateCheckpointScores(
				tr.FTFl,
				tr.FTFo,
				tr.NTFl,
				tr.NTFo,
				tr.TFl,
				tr.TFo,
				tr.LLFl,
				tr.LLFo,
				tr.RLFl,
				tr.RLFo
			);
			this.testResults += `
			<div class="result">
				<table cellspacing="5">
					<tr><td>Feet Together</td><td>Floor: ${tr.FTFl}</td><td>Foam: ${tr.FTFo}</td></row>
					<tr><td>Near Tandem</td><td>Floor: ${tr.NTFl}</td><td>Foam: ${tr.NTFo}</td></row>
					<tr><td>Tandem</td><td>Floor: ${tr.TFl}</td><td>Foam: ${tr.TFo}</td></row>
					<tr><td>Left Leg</td><td>Floor: ${tr.LLFl}</td><td>Foam: ${tr.LLFo}</td></row>
					<tr><td>Right leg</td><td>Floor: ${tr.RLFl}</td><td>Foam: ${tr.RLFo}</td></row>
					${(tr.expected && `<tr><td><strong>Expected:</strong></td><td>${tr.expected}</td></tr>`) || ''}
					<tr><td><strong>Results:</strong></td><td colspan="2"><strong>${results}</strong></td></tr>
				</table>
			</div>
			<br>`;
		});

		this.testResults += `<h1>Random sets</h1>`;
		randoSets.forEach((tr) => {
			let results = this.stExerciseLogic.calculateCheckpointScores(
				tr.FTFl,
				tr.FTFo,
				tr.NTFl,
				tr.NTFo,
				tr.TFl,
				tr.TFo,
				tr.LLFl,
				tr.LLFo,
				tr.RLFl,
				tr.RLFo
			);
			this.testResults += `
			<div class="result">
				<table cellspacing="5">
					<tr><td>Feet Together</td><td>Floor: ${tr.FTFl}</td><td>Foam: ${tr.FTFo}</td></row>
					<tr><td>Near Tandem</td><td>Floor: ${tr.NTFl}</td><td>Foam: ${tr.NTFo}</td></row>
					<tr><td>Tandem</td><td>Floor: ${tr.TFl}</td><td>Foam: ${tr.TFo}</td></row>
					<tr><td>Left Leg</td><td>Floor: ${tr.LLFl}</td><td>Foam: ${tr.LLFo}</td></row>
					<tr><td>Right leg</td><td>Floor: ${tr.RLFl}</td><td>Foam: ${tr.RLFo}</td></row>
					<tr><td><strong>Results:</strong></td><td colspan="2"><strong>${results}</strong></td></tr>
				</table>
			</div>
			<br>`;
		});

		this.testResults += `<h1>Random sets (High weighting)</h1>`;
		randoHighSets.forEach((tr) => {
			let results = this.stExerciseLogic.calculateCheckpointScores(
				tr.FTFl,
				tr.FTFo,
				tr.NTFl,
				tr.NTFo,
				tr.TFl,
				tr.TFo,
				tr.LLFl,
				tr.LLFo,
				tr.RLFl,
				tr.RLFo
			);
			this.testResults += `
			<div class="result">
				<table cellspacing="5">
					<tr><td>Feet Together</td><td>Floor: ${tr.FTFl}</td><td>Foam: ${tr.FTFo}</td></row>
					<tr><td>Near Tandem</td><td>Floor: ${tr.NTFl}</td><td>Foam: ${tr.NTFo}</td></row>
					<tr><td>Tandem</td><td>Floor: ${tr.TFl}</td><td>Foam: ${tr.TFo}</td></row>
					<tr><td>Left Leg</td><td>Floor: ${tr.LLFl}</td><td>Foam: ${tr.LLFo}</td></row>
					<tr><td>Right leg</td><td>Floor: ${tr.RLFl}</td><td>Foam: ${tr.RLFo}</td></row>
					<tr><td><strong>Results:</strong></td><td colspan="2"><strong>${results}</strong></td></tr>
				</table>
			</div>
			<br><br>`;
		});
	}
}

function val() {
	return Math.ceil(Math.random() * 30);
}
function highVal() {
	return Math.min(30, Math.ceil(Math.random() * 20) + 10);
}
