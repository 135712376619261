///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS
import { Injectable } from '@angular/core';
import { FileStream } from '../objects/filestream';
import { GlobalPubSub } from './global-pub-sub.service';
import { environment } from '../../environments/environment';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE CLASS
@Injectable({ providedIn: 'root' })
export class FileDownloadService {
	////////////////////////////////
	//oReq:XMLHttpRequest;
	fileTransfer: any;
	blob: any;

	////////////////////////////////
	constructor(private sGlobalPubSub: GlobalPubSub) {}

	////////////////////////////////
	downloadAFile(getURL, saveURL, manifest, fileIndex, numFiles) {
		let resolver = function (resolve, reject) {
			console.log('Getting file:', getURL);

			////////////////////////////////
			/*let timeoutifier;
			let outtime = 30000; // 30 seconds

			////////////////////////////////
			var ontimeout = function(ev) {
				this.oReq.abort();
				reject(ev);
			}.bind(this);

			////////////////////////////////
			let fs				= new FileStream();
			timeoutifier		= setTimeout(ontimeout, outtime);

			////////////////////////////////
			if (!this.oReq) {
				this.oReq = new XMLHttpRequest();
				this.oReq.responseType = "blob";
			}
			this.oReq.open("GET", getURL, true);

			////////////////////////////////
			this.oReq.onload = function (event:any) {
				console.log("File Download ONLoad Event:", event.position || event.loaded, event.totalSize);
				clearTimeout(timeoutifier);
				if (this.oReq.status == 404) return reject("File not found: "+getURL);
				this.blob = this.oReq.response; // Note: not oReq.responseText
				if (this.blob) {
					fs.setup('dataDirectory')
					.getEntry(saveURL)
					.writeTo(this.blob, false, false)
					//.debugData()
					.go()
					.then(res => { this.blob = null; fs = null; this.oReq.onload = null; this.oReq.onprogress = null; this.oReq.onerror = null; resolve(res); })
					.catch(reject);
				} else reject("Unable to get BLOB");
			}.bind(this);

			////////////////////////////////
			this.oReq.onprogress = function (ev:any) {
				// TEST ONLY TO REMOVE //if (window['failDownload'] == true) { oReq.abort(); reject( { message:"User prompted test failure" }); }
				clearTimeout(timeoutifier);
				timeoutifier = setTimeout(ontimeout, outtime);
				this.sGlobalPubSub.fireEvent("dataProgressUpdate", [ev, manifest.name, fileIndex, numFiles, manifest.parentId]);
			}.bind(this);

			////////////////////////////////
			this.oReq.onerror = function(ev:any) {
				console.log("FAILED TO DOWNLOAD FILE: Type 1");
				clearTimeout(timeoutifier);
				reject(ev);
			}.bind(this);

			////////////////////////////////
			this.oReq.send(null);*/

			////////////////////////////////
			let timeoutifier;
			let outtime = 30000; // 30 seconds

			////////////////////////////////
			var ontimeout = function (ev) {
				this.fileTransfer.abort();
				reject(ev);
			}.bind(this);

			////////////////////////////////////
			let fs = new FileStream();
			this.fileTransfer = new window['FileTransfer']();
			var uri = encodeURI(getURL);
			timeoutifier = setTimeout(ontimeout, outtime);

			////////////////////////////////////
			fs.setup('dataDirectory')
				.getEntry(saveURL, true, true)
				.go()
				.then((res) => {
					if (environment.debug) {
						console.log('Got download target location:', res);
						console.log('Downloading File:', uri);
					}

					////////////////////////////////////
					this.fileTransfer.onprogress = function (ev: any) {
						clearTimeout(timeoutifier);
						timeoutifier = setTimeout(ontimeout, outtime);
						this.sGlobalPubSub.fireEvent('dataProgressUpdate', [ev, manifest.name, fileIndex, numFiles, manifest.parentId]);
					}.bind(this);

					////////////////////////////////////
					this.fileTransfer.download(
						uri,
						res.lastEntry.filePathUrl,
						function () {
							clearTimeout(timeoutifier);
							resolve();
						},
						function (error) {
							console.log('Error during file download', error);
							reject(error);
						},
						false
					);
				});
		}.bind(this);

		return new Promise(resolver);
	}
}
