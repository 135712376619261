///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
////////// MODULES
import { Component, OnInit, Input, ViewChild }	from '@angular/core';
import { Chart }								from 'chart.js';


///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
  selector:		'graph-report',
  templateUrl:	'./graph.component.html',
  styleUrls:	['./graph.component.scss']
})


///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class GraphComponent implements OnInit {

	////////////////////////////////////
	@Input() type:string = "line";
	@ViewChild('canvas') canvas;

	////////////////////////////////////
	renderedChart:Chart;

	////////////////////////////////////
	private data:any;
	private options:any = {
		responsive: true,
    	maintainAspectRatio: false,
		scales: {
			yAxes: [{
				ticks: { beginAtZero:true, padding:20 },
				gridLines: { display:false, drawBorder:false }
			}],
			xAxes: [{
				ticks: { beginAtZero:false, padding:6 },
				gridLines: { display:false, drawBorder:false }
			}]
		},
		legend: {
			position:'bottom',
			display: true, fullWidth:true,
			labels: {
				fontColor: 'rgb(255, 99, 132)'
			},
			onClick: (e) => e.stopPropagation()
		},
		elements: {
			line: {
				borderWidth:	6,
				borderColor:	'rgba(245, 144, 49, 1)',
				backgroundColor:'rgba(0, 0, 0, 0)',
				tension:		0, // disables bezier curves
			},
			point: {
				radius:8,
				borderColor:'rgba(153, 38, 73, 1)',
				backgroundColor:'rgba(153, 38, 73, 1)'
			}
		}
	}

	////////////////////////////////////
	constructor() {}
	ngOnInit() {}

	////////////////////////////////
	createGraph(data, opts=null, type=null) {
		Chart.defaults.global.defaultFontSize = 18;
		Chart.defaults.global.defaultFontFamily = 'Montserrat';
		this.renderedChart = new Chart(this.canvas.nativeElement.getContext('2d'), {
			type: type || this.type,
			data: data,
			options: opts || this.options
		});

		let width = document.querySelector(".chart").clientWidth - 10;
		this.renderedChart.canvas.parentNode.style.width = width+'px';
	}
}
