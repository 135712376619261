///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
import { Component, AfterViewInit, ViewChild, Input } from '@angular/core';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
  selector:		'select-dateday',
  templateUrl:	'./select-dateday.component.html',
  styleUrls:	['./select-dateday.component.scss']
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class SelectDatedayComponent implements AfterViewInit {

	@ViewChild('fld_birthdayday') fld_birthdayday;
	@ViewChild('msg_date') msg_date;
	@Input() initialValue:number = 1;

	days:number[] = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31];

	////////////////////////////////
	constructor() {}

	////////////////////////////////
	ngAfterViewInit() {
		this.fld_birthdayday.nativeElement.value = this.initialValue;
	}

	////////////////////////////////
	getValue() {
		return this.fld_birthdayday.nativeElement.value;
	}

	////////////////////////////////////
	setValue(value: number) {
		this.fld_birthdayday.nativeElement.value = value;
	}

	////////////////////////////////////
	invalidDate() {
		this.msg_date.nativeElement.classList.add("invalid-date");
		this.fld_birthdayday.nativeElement.classList.add("invalid-date");
		setTimeout(_ => {
			this.msg_date.nativeElement.classList.remove("invalid-date");
			this.fld_birthdayday.nativeElement.classList.remove("invalid-date");
		}, 2000);
	}
}
