///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
////////// ANGULAR CORE
import { Component } from '@angular/core';
import { UserService } from '../../services/user.service';
import { DataService } from '../../services/data.service';
import { GlobalPubSub } from '../../services/global-pub-sub.service';
import { Vasat } from 'vasat';
import { STExerciseLogic } from '../../st-commons/exercise_logic';
import { ExerciseService } from '../../services/exercise.service';
import { DeviceService } from '../../services/device.service';
import { DebugLoggerService } from '../../services/debugLogger.service';
import { ContentManagerService } from '../../services/contentmanager.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// SETUP COMPONENT
@Component({
	selector: 'app-page-debugmodifiers',
	templateUrl: './page-debugmodifiers.component.html',
	styleUrls: ['./page-debugmodifiers.component.scss'],
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class PageDebugmodifiersComponent {
	stExerciseLogic = new STExerciseLogic(this.V);
	testResults: string = 'Test Results';
	downloadableFilesFound: boolean = false;
	foundFiles: any[] = [];

	renameCode: string = `
   for i in *
   do

     # Prepare strings
     file=\${i#*_}  # Remove everything up to and including first _
     number=\${i%%_*} # Remove the first _ and everything following it

     echo "F" $number " - " \${file//bin/mp4}
     mkdir -p $number

	  cp $i "$number/\${file//bin/mp4}"
   done`;

	constructor(
		private sUser: UserService,
		private sData: DataService,
		private sGlobalPubSub: GlobalPubSub,
		private V: Vasat,
		private sExercise: ExerciseService,
		private sContentManager: ContentManagerService,
		private sDebugLogger: DebugLoggerService,
		private sDevice: DeviceService,
		private http: HttpClient
	) {}

	////////////////////////////////
	changeToEarlierWeek() {
		this.sUser.user.startDate = 1518614488094;
	}

	////////////////////////////////
	queryExerciseData() {
		let exercises;
		let exerciseActions;
		let exerciseCategories;
		let exerciseLevels;
		let promises: any[] = [];

		promises.push(this.sData.getRawDataFromStorage('exerciselevels'));
		promises.push(this.sData.getRawDataFromStorage('exerciseactions'));
		promises.push(this.sData.getRawDataFromStorage('exercises'));
		promises.push(this.sData.getRawDataFromStorage('exercisecategories'));

		Promise.all(promises).then((res) => {
			this.testResults =
				'<h2>Exercise data stored for this user</h2>Levels: ' +
				res[0].length +
				' (' +
				JSON.stringify(res[0]).length +
				' chars).  <br>Actions: ' +
				res[1].length +
				' (' +
				JSON.stringify(res[1]).length +
				' chars).  <br>Exercises: ' +
				res[2].length +
				' (' +
				JSON.stringify(res[2]).length +
				' chars).  <br>Cats: ' +
				res[3].length +
				' (' +
				JSON.stringify(res[3]).length +
				' chars).  ';
		});
	}

	////////////////////////////////
	/*logAllData() {
		this.sData.getRawDataFromStorage('exercises').then(res => console.log("EXERCISES:", res));
		this.sData.getRawDataFromStorage('exerciseactions').then(res => console.log("EXERCISE ACTIONS:", res));
		this.sData.getRawDataFromStorage('exercisecategories').then(res => console.log("EXERCISE CATEGORIES:", res));
		this.sData.getRawDataFromStorage('exerciselevels').then(res => console.log("EXERCISE LEVELS:", res));
		this.sData.getRawDataFromStorage('userProfiles').then(res => console.log("USER PROFILES:", res));
		this.sData.getRawDataFromStorage('userTokens').then(res => console.log("USER TOKENS:", res));
		this.sData.getRawDataFromStorage('v_st_customer_https_standing_tall_correllink_com_vasat').then(res => console.log("VASAT TOKEN AUTH THING:", res));
		this.sData.getRawDataFromStorage('datapulllog').then(res => console.log("DATA PULL LOG:", res));
		this.sData.getRawDataFromStorage('datapushlist').then(res => console.log("DATA PUSH LIST:", res));
	}*/

	////////////////////////////////////
	exerciseSessionLength(which) {
		switch (which) {
			case 0:
				this.sExercise.inactivityTimeoutLengthMs = 1000 * 60 * 120; // 120 mins
				break;

			case 1:
				this.sExercise.inactivityTimeoutLengthMs = 1000 * 60 * 5; // 5 mins
				break;

			case 2:
				this.sExercise.inactivityTimeoutLengthMs = 1000 * 60 * 2; // 2 mins
				break;

			case 3:
				this.sExercise.inactivityTimeoutLengthMs = 1000 * 60 * 0.5; // 30 secs
				break;
		}
	}

	////////////////////////////////////
	showCurrentCheckpoints() {
		let string = '<div><span>Current: </span>';
		this.sUser.user.levelProgression.forEach(
			(lp) => lp.category.uid !== 'init' && (string += `${lp.category.uid} = ${('00' + lp.checkpoint).substr(-2)} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; `)
		);
		string += '</div><div><span>Max: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>';
		this.sUser.user.levelProgression.forEach(
			(lp) =>
				lp.category.uid !== 'init' &&
				(string += `${lp.category.uid} = ${(lp.max_checkpoint && ('00' + lp.max_checkpoint).substr(-2)) || 'na'} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; `)
		);
		string += '</div>';
		this.sGlobalPubSub.fireEvent('toast', [true, string, 7500]);
	}

	////////////////////////////////////
	collectUserDataFromServer() {
		this.sContentManager.dataPullLog.markDataAsOutdated('Me');
		this.sContentManager.dataPullLog.checkForUpdates().then(() => {});
	}

	////////////////////////////////////
	collectExerciseAndLevelsFromServer() {
		this.sData.writeRawDataToStorage('exercisecategories', []);
		this.sContentManager.dataPullLog.markDataAsOutdated('ExerciseCategory');

		let scope = this;
		scope.sGlobalPubSub.fireEvent('toast', [true, 'Please wait while data clears.', 7500]);

		// Ugly timeout as this is not created with a promise or timed response
		setTimeout(() => {
			this.sContentManager.dataPullLog.checkForUpdates();

			// And repeat finally for categories, as otherwise the timing / ordering is off (cats need to go last to marry with exercises)
			this.sData.writeRawDataToStorage('exercises', []);
			this.sData.writeRawDataToStorage('exerciselevels', []);
			this.sData.writeRawDataToStorage('exercisehelp', []);
			this.sContentManager.dataPullLog.markDataAsOutdated('Exercise');
			this.sContentManager.dataPullLog.markDataAsOutdated('ExerciseLevel');
			this.sContentManager.dataPullLog.markDataAsOutdated('ExerciseHelp');
			setTimeout(() => {
				this.sContentManager.dataPullLog.checkForUpdates();

				setTimeout(() => {
					this.sUser.user.updateRelations();

					scope.sGlobalPubSub.fireEvent('toast', [true, 'Data has been cleared.  Please refresh your browser', 7500]);
				}, 6000);
			}, 1500);
		}, 750);
	}

	////////////////////////////////////
	calculateLocalStorage() {
		if (!this.sDevice.isBrowser) {
			return this.sGlobalPubSub.fireEvent('toast', [true, 'Calc only works on browser', 6500]);
		}

		var _lsTotal = 0,
			_xLen,
			_x;
		for (_x in localStorage) {
			if (!localStorage.hasOwnProperty(_x)) {
				continue;
			}
			_xLen = (localStorage[_x].length + _x.length) * 2;
			_lsTotal += _xLen;
			console.log(_x.substr(0, 50) + ' = ' + (_xLen / 1024).toFixed(2) + ' KB');
		}
		console.log('Total = ' + (_lsTotal / 1024).toFixed(2) + ' KB');

		this.sGlobalPubSub.fireEvent('toast', [true, 'Results printed to console', 6500]);
	}

	////////////////////////////////////
	setCognitive() {
		let weekID = this.stExerciseLogic.weekID(new Date());
		this.sUser.user.lastWorkoutWasCog = false;

		if (this.sUser.user.completedCognitiveHours) this.sUser.user.completedCognitiveHours[weekID] = 0;

		this.sGlobalPubSub.fireEvent('toast', [
			true,
			"User's 'lastWorkoutWasCog' has been set so that they will perform cognitive on their next session, if this is a cognitive user.",
			6500,
		]);
	}

	////////////////////////////////////
	viewLastSession() {
		let log = (localStorage.lastExerciseSessionLog && JSON.parse(localStorage.lastExerciseSessionLog)) || '{ "session": "No session found" }';
		let string = JSON.stringify(log, null, 2);
		this.testResults = `<h2>Last exercise session</h2><pre><code>${string}</code></pre>`;
		this.sDebugLogger.copyToClipboard(string);
	}

	////////////////////////////////////
	expireFiles() {
		this.sData.getRawDataFromStorage('datapulllog').then((res: any) => {
			res.ExerciseFiles.lastUpdate = 1577797200000; // Jan 1, 2020.  Should trigger a few updates, without all.  Set to 0 for all.
			this.sData.writeRawDataToStorage('datapulllog', res);
		});
	}

	////////////////////////////////////
	deleteCardio() {
		let cardioID = 10;
		this.sUser.user.levelProgression = this.sUser.user.levelProgression.filter((lp) => lp.category.id != cardioID);
		this.sData.updateUserProfile(this.sUser.user, false, []);
	}

	////////////////////////////////////
	// Expires anything newer than Feb 2022
	// Has unintended bug to reduce CPs to 1, so removing
	/*expireRecentData() {
		// Instantiation
		let scope = this;
		let dateToDeleteFrom = 1643634000000; // February 1, 2022;
		let cardioID = 10;

		// Update the user
		this.sUser.user.levelProgression = this.sUser.user.levelProgression.filter((lp) => lp.category.id != cardioID);
		this.sData.updateUserProfile(this.sUser.user, false, ['levelProgression']);

		// Mark items as outdated, then delete data newer than dateToDeleteFrom
		this.sContentManager.dataPullLog.markDataAsOutdated(['ExerciseLevel', 'ExerciseCategory', 'Exercise']).then((_) => {
			this.sData.getRawDataFromStorage('exercises').then((res: any) => {
				this.sData.writeRawDataToStorage(
					'exercises',
					res.filter((ex) => ex.dateUpdated < dateToDeleteFrom)
				);
			});
			this.sData.getRawDataFromStorage('exercisecategories').then((res: any) => {
				this.sData.writeRawDataToStorage(
					'exercisecategories',
					res.filter((ex) => ex.dateUpdated < dateToDeleteFrom)
				);
			});
			this.sData.getRawDataFromStorage('exerciselevels').then((res: any) => {
				this.sData.writeRawDataToStorage(
					'exerciselevels',
					res.filter((ex) => ex.dateUpdated < dateToDeleteFrom)
				);
			});
			this.sData.getRawDataFromStorage('exercisehelp').then((res: any) => {
				this.sData.writeRawDataToStorage(
					'exercisehelp',
					res.filter((ex) => ex.dateUpdated < dateToDeleteFrom)
				);
			});

			scope.sGlobalPubSub.fireEvent('toast', [
				true,
				'Your data has been expired.  Please refresh your browser to download the latest data.',
				7500,
			]);
		});
	}*/

	////////////////////////////////////
	lookupDownloadableFiles() {
		if (!this.sDevice.isBrowser) {
			this.sGlobalPubSub.fireEvent('toast', [true, 'Only available for browser.', 7500]);
		}

		this.V.api('/api/ExerciseHelp.files?limit=2000&zip=true').subscribe((res) => {
			this.downloadableFilesFound = true;
			this.foundFiles = res.sort((a, b) => a.parentId < b.parentId);
		});
	}

	////////////////////////////////////
	downloadFileToDisk(file) {
		this.downloadFileGo(file);
	}

	////////////////////////////////////
	downloadAllFilesToDisk() {
		let timeoutAdditive = 2300;
		let timeout = 0;
		this.foundFiles.forEach((file) => {
			timeout += timeoutAdditive;
			setTimeout(this.downloadFileGo.bind(this, file), timeout);
		});
	}

	////////////////////////////////////
	downloadFileGo(file) {
		fetch(`${this.V.cfg.host}/api/ExerciseHelp/${file.parentId}/${file.name}?access_token=${this.V.token}`)
			.then((resp) => resp.blob())
			.then((blob) => {
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.style.display = 'none';
				a.href = url;
				// the filename you want
				a.download = `${file.parentId}_${file.name.replace('bin', 'mp4')}`;
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
				file.downloaded = true;
				console.log('File downloaded');
			})
			.catch(() => alert('oh no!'));
	}
}
