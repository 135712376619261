///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
////////// MODULES
import { Component, OnInit, ViewChild }	from '@angular/core';
import { Router }				from '@angular/router';


///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
  selector:		'app-page-termsconditions',
  templateUrl:	'./page-termsconditions.component.html',
  styleUrls:	['./page-termsconditions.component.scss']
})


///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class PageTermsConditionsComponent implements OnInit {

	@ViewChild('scrollContainer') scrollcontainer;

	////////////////////////////////////
	constructor(private router:Router) { }
	ngOnInit() {}

	////////////////////////////////////
	toSettings() {
		this.router.navigate(['/settings']);
	}

	backToTop() {
		this.smoothScrollToElement(this.scrollcontainer.nativeElement, 500);
	}

	////////////////////////////////
	smoothScrollToElement(targetElement: any, scrollTime: number) {

		//Set target position (the elements offset top will change as it scrolls)
		let targetPosition = targetElement.offsetTop - (this.scrollcontainer.nativeElement.offsetHeight/2);

		//Find Distance to target
		let distanceToTarget = targetPosition - this.scrollcontainer.nativeElement.scrollTop;

		//Set interval Length (32 frames per second?)
		let intervalLength = 1000/32;

		//Set number of intervals
		let numberOfIntervals = scrollTime/intervalLength;

		//Set scroll jump
		let scrollJump = distanceToTarget/numberOfIntervals;

		//Set interval count
		let intervalCount = 0;

		//Set interval
		let scrollInterval = setInterval(() => {

			if (intervalCount >= numberOfIntervals) {

				this.scrollcontainer.nativeElement.scrollTop = targetPosition;

				clearInterval(scrollInterval)
			} else {
				intervalCount++;
				this.scrollcontainer.nativeElement.scrollTop = this.scrollcontainer.nativeElement.scrollTop + scrollJump;
			}
		}, intervalLength)
	}
}
