///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS
import { DataService } from '../services/data.service';
import { Vasat } from 'vasat';
import { GlobalPubSub } from '../services/global-pub-sub.service';
import { ExerciseAction } from '../st-commons/models';
import { reject } from 'q';
import { UserService } from '../services/user.service';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// GOAL EXPORT
export class LocalGoal {
	////////////////////////////////////
	l_id: number;
	title: string;
	description: string;
	image_path: string;
	owner_id: string;
	goal_preset: number;

	////////////////////////////////////
	constructor(params) {
		this.l_id = params.l_id;
		this.title = params.title;
		this.description = params.description;
		this.image_path = params.image_path;
		this.owner_id = params.owner_id;
		this.goal_preset = params.goal_preset;
	}
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// JOURNAL EXPORT
export class LocalJournal {
	////////////////////////////////////
	l_id: number;
	date: Date;
	description: string;
	image_path: string; // Ionic path for reading file
	image_file: string; // File path for deleting file
	owner_id: string;
	wellbeing: number;

	////////////////////////////////////
	constructor(params) {
		this.l_id = params.l_id;
		this.date = params.date;
		this.description = params.description;
		this.image_path = params.image_path;
		this.owner_id = params.owner_id;
		this.wellbeing = params.wellbeing;
	}
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// CALENDAR EXPORT
export class LocalCalendar {
	////////////////////////////////////
	l_id: number;
	owner_id: number;
	day_of_week: number;
	time: number;

	////////////////////////////////////
	constructor(params) {
		this.l_id = params.l_id;
		this.owner_id = params.owner_id;
		this.day_of_week = params.day_of_week;
		this.time = params.time;
	}
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// JOURNAL EXPORT
export class LocalUser {
	////////////////////////////////////
	access_token: string;
	owner_id: number;
	first_name: string;
	last_name: string;

	////////////////////////////////////
	constructor() {}

	////////////////////////////////////
	set(data) {
		this.access_token = data.access_token;
		this.owner_id = data.owner_id;
		this.first_name = data.first_name;
		this.last_name = data.last_name;
	}
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DATAPULSHOG EXPORT
export class DataPushLog {
	sData: DataService = null;
	V: Vasat = null;

	////////////////////////////////////
	pushUpdates: any = {
		DataPushLog: {
			lastUpdate: null,
			updateEvery: null,
			updating: false,
		},
	};

	////////////////////////////////////
	constructor(params) {
		this.sData = params.sData;
		this.V = params.V;

		if (!params['DataPushLog']) params['DataPushLog'] = {};
		this.pushUpdates['DataPushLog'].lastUpdate = params['DataPushLog'].lastUpdate || 0;
		this.pushUpdates['DataPushLog'].updateEvery = params['DataPushLog'].updateEvery || 1000 * 4; // 4 seconds
		this.pushUpdates['DataPushLog'].updating = params['DataPushLog'].updating || false;
	}

	////////////////////////////////////
	readyForUpdatePush() {
		let updates = this.pushUpdates['DataPushLog'];
		return !updates.updating && new Date().getTime() > updates.lastUpdate + updates.updateEvery;
	}

	////////////////////////////////////
	checkForDataPushes() {
		let resolver = function (resolve, reject) {
			if (!this.V.hasSession()) return resolve(false);
			if (!this.readyForUpdatePush()) return resolve(false);

			this.sData
				.getRawDataFromStorage('datapushlist')
				.then((res: any[]) => {
					if (res.length) {
						this.pushUpdates['DataPushLog'].updating = true;
						this.sData
							.sendAllServerUpdates()
							.then((res) => {
								console.log('%c >>> Completed update pushes', 'color:lightblue;');
								this.pushUpdates['DataPushLog'].updating = false;
								this.pushUpdates['DataPushLog'].lastUpdate = new Date().getTime();
								this.sData.sendingServerUpdates = false;
								this.sData.deleteActionsOlderThanOneWeek();

								resolve(res);
							})
							.catch((err) => {
								console.log('Error during update push', err);
								this.pushUpdates['DataPushLog'].updating = false;
								this.sData.sendingServerUpdates = false;

								reject(err);
							});
					} else {
						resolve();
					}
				})
				.catch((err) => {
					console.log('UNABLE TO CHECK FOR UPDATABLE DATA', err);
					reject(err);
				});
		}.bind(this);

		return new Promise(resolver);
	}
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DATAPULLLOG EXPORT
export class DataPullLog {
	sData: DataService = null;
	V: Vasat = null;
	sGlobalPubSub: GlobalPubSub = null;
	sUser: UserService = null;

	////////////////////////////////////
	serverDataUpdates: any = {
		Exercise: {
			lastUpdate: null,
			updateEvery: null,
			updating: false,
		},
		ExerciseCategory: {
			lastUpdate: null,
			updateEvery: null,
			updating: false,
		},
		ExerciseLevel: {
			lastUpdate: null,
			updateEvery: null,
			updating: false,
		},
		/*"ExerciseAction": {
			lastUpdate:		null,
			updateEvery:	null,
			updating:		false
		},*/
		ExerciseHelp: {
			lastUpdate: null,
			updateEvery: null,
			updating: false,
		},
		ExerciseFiles: {
			lastUpdate: null,
			updateEvery: null,
			updating: false,
		},
		FAQ: {
			lastUpdate: null,
			updateEvery: null,
			updating: false,
		},
		Me: {
			lastUpdate: null,
			updateEvery: null,
			updating: false,
		},
	};

	////////////////////////////////////
	constructor(params) {
		this.sData = params.sData;
		this.V = params.V;
		this.sGlobalPubSub = params.sGlobalPubSub;
		this.sUser = params.sUser;

		if (!params['Exercise']) params['Exercise'] = {};
		this.serverDataUpdates['Exercise'].lastUpdate = params['Exercise'].lastUpdate || 0;
		this.serverDataUpdates['Exercise'].updateEvery = params['Exercise'].updateEvery || 1000 * 60 * 60 * 8; // 8 hours
		this.serverDataUpdates['Exercise'].updating = params['Exercise'].updating || false;

		if (!params['ExerciseCategory']) params['ExerciseCategory'] = {};
		this.serverDataUpdates['ExerciseCategory'].lastUpdate = params['ExerciseCategory'].lastUpdate || 0;
		this.serverDataUpdates['ExerciseCategory'].updateEvery = params['ExerciseCategory'].updateEvery || 1000 * 60 * 60 * 8; // 8 hours
		this.serverDataUpdates['ExerciseCategory'].updating = params['ExerciseCategory'].updating || false;

		if (!params['ExerciseLevel']) params['ExerciseLevel'] = {};
		this.serverDataUpdates['ExerciseLevel'].lastUpdate = params['ExerciseLevel'].lastUpdate || 0;
		this.serverDataUpdates['ExerciseLevel'].updateEvery = params['ExerciseLevel'].updateEvery || 1000 * 60 * 60 * 8; // 8 hours
		this.serverDataUpdates['ExerciseLevel'].updating = params['ExerciseLevel'].updating || false;

		// No longer pulling these here - instead using gatherThisWeeksVasatExerciseAction()
		/*if (!params["ExerciseAction"]) params["ExerciseAction"] = {};
		this.serverDataUpdates["ExerciseAction"].lastUpdate		= params["ExerciseAction"].lastUpdate			|| 0;
		this.serverDataUpdates["ExerciseAction"].updateEvery		= params["ExerciseAction"].updateEvery		|| 1000*60*60*1; // 1 Hour
		this.serverDataUpdates["ExerciseAction"].updating			= params["ExerciseAction"].updating			|| false;*/

		if (!params['ExerciseHelp']) params['ExerciseHelp'] = {};
		this.serverDataUpdates['ExerciseHelp'].lastUpdate = params['ExerciseHelp'].lastUpdate || 0;
		this.serverDataUpdates['ExerciseHelp'].updateEvery = params['ExerciseHelp'].updateEvery || 1000 * 60 * 60 * 24; // 24 hours
		this.serverDataUpdates['ExerciseHelp'].updating = params['ExerciseHelp'].updating || false;

		if (!params['ExerciseFiles']) params['ExerciseFiles'] = {};
		this.serverDataUpdates['ExerciseFiles'].lastUpdate = params['ExerciseFiles'].lastUpdate || 0;
		this.serverDataUpdates['ExerciseFiles'].updateEvery = params['ExerciseFiles'].updateEvery || 1000 * 60 * 60 * 12; // 12 hours
		this.serverDataUpdates['ExerciseFiles'].updating = params['ExerciseFiles'].updating || false;

		if (!params['FAQ']) params['FAQ'] = {};
		this.serverDataUpdates['FAQ'].lastUpdate = params['FAQ'].lastUpdate || 0;
		this.serverDataUpdates['FAQ'].updateEvery = params['FAQ'].updateEvery || 1000 * 60 * 60 * 24 * 10; // 10 days
		this.serverDataUpdates['FAQ'].updating = params['FAQ'].updating || false;

		if (!params['Me']) params['Me'] = {};
		this.serverDataUpdates['Me'].lastUpdate = params['Me'].lastUpdate || 0;
		this.serverDataUpdates['Me'].updateEvery = params['Me'].updateEvery || 1000 * 60 * 4; // 4 minutes
		this.serverDataUpdates['Me'].updating = params['Me'].updating || false;

		this.saveDataUpdatesToStorage();
	}

	////////////////////////////////////
	saveDataUpdatesToStorage() {
		return this.sData.writeRawDataToStorage('datapulllog', this.serverDataUpdates);
	}

	////////////////////////////////////
	checkForUpdates() {
		let resolver = function (resolve, reject) {
			this.checkForServerUpdates()
				.then(resolve)
				.catch((err) => {
					switch (err) {
						case 'No connection':
							resolve();
							break;
						case 'Unable to update me just yet':
							resolve();
							break;
						default:
							reject(err);
							break;
					}
				});
		}.bind(this);
		return new Promise(resolver);
	}

	////////////////////////////////////
	checkForServerUpdates() {
		////////////////////////////////////
		if (this.sData.sendingServerUpdates) return Promise.all([Promise.resolve("Can't download while pushes are taking place.")]); // Return a resolved promise as this isn't a blocking action (not checked all instances of this)

		////////////////////////////////////
		if (!this.V.hasSession()) return Promise.all([Promise.reject('No session')]);

		////////////////////////////////////
		let promises: Promise<any>[] = [];
		let readyForUpdates = this.findReadyForServerUpdates();

		////////////////////////////////////
		if (!readyForUpdates.length) return Promise.all([Promise.resolve()]);

		readyForUpdates.forEach((upd) => {
			switch (upd.key) {
				case 'Exercise':
					promises.push(
						this.sData.gatherVasatExercises().then((res) => {
							this.fireEvent('Exercise', res.data.vasatItems);
							this.markDataAsServerUpdated('Exercise');
							this.sUser.user.updateRelations({ forceLevelProgressionUpdate: true });
							return res.data.vasatItems;
						})
					);
					break;
				//case "ExerciseAction":	promises.push(this.sData.gatherVasatExerciseAction().then(res => 			{ this.fireEvent("ExerciseAction", res.data.vasatItems);	this.markDataAsServerUpdated("ExerciseAction"); })		);		break;
				case 'ExerciseLevel':
					promises.push(
						this.sData.gatherVasatExerciseLevel().then((res) => {
							this.fireEvent('ExerciseLevel', res.data.vasatItems);
							this.markDataAsServerUpdated('ExerciseLevel');
							this.sUser.user.updateRelations({ forceLevelProgressionUpdate: true });
							return res.data.vasatItems;
						})
					);
					break;
				case 'ExerciseCategory':
					promises.push(
						this.sData.gatherVasatExerciseCategories().then((res) => {
							this.fireEvent('ExerciseCategory', res.data.vasatItems);
							this.markDataAsServerUpdated('ExerciseCategory');
							this.sUser.user.updateRelations({ forceLevelProgressionUpdate: true });
							return res.data.vasatItems;
						})
					);
					break;
				case 'ExerciseHelp':
					promises.push(
						this.sData.gatherVasatExerciseHelp().then((res) => {
							this.fireEvent('ExerciseHelp', res.data.vasatItems);
							this.markDataAsServerUpdated('ExerciseHelp');
							return res.data.vasatItems;
						})
					);
					break;
				case 'FAQ':
					promises.push(
						this.sData.gatherVasatFAQs().then((res) => {
							this.fireEvent('FAQ', res.data.vasatItems);
							this.markDataAsServerUpdated('FAQ');
							return res.data.vasatItems;
						})
					);
					break;
				case 'Me':
					promises.push(
						this.sData.updateMe().then((res) => {
							this.markDataAsServerUpdated('Me');
							return [];
						})
					);
					break;
			}
		});

		return Promise.all(promises);
	}

	////////////////////////////////////
	findReadyForServerUpdates() {
		let readyForUpdates: any[] = [];

		Object.keys(this.serverDataUpdates).forEach((key) => {
			if (
				!this.serverDataUpdates[key].updating &&
				new Date().getTime() > this.serverDataUpdates[key].lastUpdate + this.serverDataUpdates[key].updateEvery
			)
				readyForUpdates.push({ key: key, lastUpdate: this.serverDataUpdates[key].lastUpdate });
		});

		return readyForUpdates;
	}

	////////////////////////////////////
	markDataAsServerUpdated(key: string, updateTime: number = null) {
		if (!updateTime) updateTime = new Date().getTime();
		this.serverDataUpdates[key].lastUpdate = updateTime;
		this.saveDataUpdatesToStorage();
	}

	////////////////////////////////////
	markAllDataAsOutdated() {
		Object.keys(this.serverDataUpdates).forEach((key) => {
			this.serverDataUpdates[key].lastUpdate = 0;
			this.saveDataUpdatesToStorage();
		});
	}

	////////////////////////////////////
	markDataAsOutdated(key: string | string[]) {
		if (Array.isArray(key)) {
			key.forEach((k) => {
				this.serverDataUpdates[k].lastUpdate = 0;
			});
		} else {
			this.serverDataUpdates[key].lastUpdate = 0;
		}

		return this.saveDataUpdatesToStorage();
	}

	////////////////////////////////////
	forceServerUpdates(keys = []) {
		if (!keys.length) keys = Object.keys(this.serverDataUpdates);
		keys.forEach((key) => {
			this.serverDataUpdates[key].lastUpdate = 0;
		});

		return this.checkForServerUpdates();
	}

	////////////////////////////////////
	fireEvent(message, itemsUpdated) {
		if (itemsUpdated.length) {
			//message = message + " data updated with "+itemsUpdated.length+ " items.";
			message = 'Exercise data updated';
			this.sGlobalPubSub.fireEvent('dataProgressUpdate', [message]);
		}
	}
}
