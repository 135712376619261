///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
////////// MODULES
import { Component, OnInit, AfterViewInit, 
		 ViewChild, ViewChildren,
		 QueryList, ElementRef }						from '@angular/core';
import { DomSanitizer }									from '@angular/platform-browser';
import { DataService }									from '../../services/data.service';
import { LocalGoal, LocalJournal }						from '../../objects/localModels';
import { GlobalPubSub }									from '../../services/global-pub-sub.service';
import { FormBluewaveComponent }						from '../../components/form-bluewave/form-bluewave.component';
import { UserService }									from '../../services/user.service';
import { PhotoHandlingService }							from '../../services/photohandling.service';
import { DeviceService }								from '../../services/device.service';


///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
	selector:	'app-page-journal1home',
	templateUrl:'./page-goalsandjournal1home.component.html',
	styleUrls:	['./page-goalsandjournal1home.component.scss']
})


///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class PageGoalsAndJournal1homeComponent implements OnInit, AfterViewInit {

	////////////////////////////////
	@ViewChild('customgoaltext') customgoaltext;
	@ViewChild('customjournaltext') customjournaltext;
	@ViewChild('journalwellbeing') journalwellbeing;
	@ViewChild('bluewaveform') bluewaveform: FormBluewaveComponent;
	@ViewChildren('journalLI') journalLI: QueryList<ElementRef>;
	@ViewChildren('goalLI') goalLI: QueryList<ElementRef>;

	////////////////////////////////
	selectedGoalItem:LocalGoal = null;
	selectedJournalItem:LocalJournal;
	formMode:string = "none";
	readyState = "not_ready";
	priorGoalMode:string = "";
	journalError:string;
	newJournalItem:LocalJournal;
	temporaryImagePath:string;
	temporaryIonicImagePath:string;
	loadedJournalsAtLeastOnce:string = "no";

	////////////////////////////////
	goalPresets: any[] = [ { id:1, title:"Goal", text:"Play with my grandkids" }, { id:2, title:"Goal", text:"Be able to do more gardening" }, { id:3, title:"Goal", text:"Be more mobile" }, { id:4, title:"Goal", text:"Walk more for my upcoming trip" }, { id:0, title:"Custom Goal", text:"Custom Goal" } ];
	newGoalToSave: number = null;
	goals:LocalGoal[] = [];
	journalItems:LocalJournal[] = [];

	////////////////////////////////
	constructor(private sData:DataService, private sGlobalPubSub:GlobalPubSub, private sUser:UserService, private sPhotoHandler:PhotoHandlingService, private sanitizer:DomSanitizer, private sDevice:DeviceService) { }
	ngOnInit() {}

	////////////////////////////////
	ngAfterViewInit() {
		setTimeout( ()=>{
			this.readyState = "ready";
			this.getGoalsAndJournalData();
		}, 1500);

		this.journalLI.changes.subscribe(() => {
			const jernals = this.journalLI.toArray().map(i => i.nativeElement);
			jernals.forEach((j, index) => setTimeout(() => { j.classList.add('in'); }, index*250));
			if (this.loadedJournalsAtLeastOnce == "no") this.loadedJournalsAtLeastOnce = "loadedJournals";
		});

		this.goalLI.changes.subscribe(() => {
			const goals = this.goalLI.toArray().map(i => i.nativeElement);
			goals.forEach((g, index) => setTimeout(() => { g.classList.add('in'); }, index*250));
		});
	}

	////////////////////////////////
	desanitiseURL(url) {
    	return this.sanitizer.bypassSecurityTrustUrl(url);
	}

	////////////////////////////////
	getGoalsAndJournalData() {
		this.sData.getGoals()
		.then( (res:any) => { this.goals = res.data.goals; } );

		this.sData.getJournalItems()
		.then( (res:any) => { this.journalItems = res.data.journal;  } );
	}

	////////////////////////////////
	openForm(type:string, id:number) {

		// Show the form
		this.formMode = type;
		this.bluewaveform.open();
		this.bluewaveform.setFormContentState(type);
		this.priorGoalMode = type;

		// Modify the data
		switch (type) {
			case "add-goal":
				this.selectedGoalItem = null;
			break;
			case "edit-goal":
				this.selectedGoalItem = this.goals.filter( i=> { return (i.l_id == id) })[0];
				
				if (!this.selectedGoalItem) this.closeForm();
				else {
					Array.prototype.forEach.call(document.querySelectorAll('.goal_option'), (el) => { el.classList.remove('selected'); });

					this.newGoalToSave = this.selectedGoalItem.goal_preset;
					
					// Test if this is a custom goal (id 0) by comparing the goal ID to preset goal ids
					let premadeGoals = this.goalPresets.map(i => { console.log(i); return i !== 0 && i.id || null });
					if (premadeGoals.indexOf(this.selectedGoalItem.goal_preset) == -1) {
						this.priorGoalMode = "edit-goal";
						this.bluewaveform.setFormContentState("edit-custom-goal");
					}
					setTimeout(() => {
						document.querySelector('.goal_option[data-presetid="'+this.selectedGoalItem.goal_preset+'"]').classList.add('selected');
						if (this.customgoaltext) this.customgoaltext.nativeElement.value = this.selectedGoalItem.description;
					}, 100);
				}
			break;
			case "add-journal":
				this.selectedJournalItem = new LocalJournal( { date:new Date(), description:"", image_path:"", owner_id:null, wellbeing:50 });
			break;
			case "edit-journal":
				this.selectedJournalItem = this.journalItems.filter( i=> { return (i.l_id == id) })[0];
				if (!this.selectedJournalItem) this.closeForm();
				else {
					setTimeout(() => {
						this.customjournaltext.nativeElement.value = this.selectedJournalItem.description;
						this.journalwellbeing.setValue(this.selectedJournalItem.wellbeing);
					}, 100);
				}
			break;
		}

		// Just in case cleanup
		this.temporaryImagePath = "";
	}

	////////////////////////////////
	closeForm() {
		this.temporaryImagePath = "";
		this.sPhotoHandler.cleanup();
		setTimeout(this.resetForm.bind(this), 1000);
		this.bluewaveform.close();
		Array.prototype.forEach.call(document.querySelectorAll('.goal_option'), (el) => { el.classList.remove('selected'); });
	}

	////////////////////////////////
	resetForm() {
		this.journalError = "";
		if (this.customjournaltext) this.customjournaltext.nativeElement.value = "";
		if (this.customgoaltext) this.customgoaltext.nativeElement.value = "";
		if (this.journalwellbeing) this.journalwellbeing.reset();
	}

	////////////////////////////////
	selectGoalOption($event, idSelected) {
		Array.prototype.forEach.call($event.target.parentNode.children, (el) => { el.classList.remove('selected'); });
		$event.target.classList.add('selected');

		this.newGoalToSave = idSelected;
		
		if (idSelected === 0) {
			if (this.bluewaveform.formContentState.indexOf("add-") != -1) this.bluewaveform.setFormContentState('add-custom-goal');
			else this.bluewaveform.setFormContentState('edit-custom-goal');
		} else {
			this.bluewaveform.setFormContentState(this.priorGoalMode);
		}
	}

	////////////////////////////////
	saveSelectedGoal() {
		if (this.bluewaveform.formContentState == "edit-goal") this.updateSelectedGoal();
		else this.saveNewGoal();
	}

	////////////////////////////////
	prepareGoalToSave() {
		let newGoal;
		if (this.newGoalToSave > 0) {
			let goalDetails = this.goalPresets.filter( it => { return it.id == this.newGoalToSave })[0];
			newGoal = new LocalGoal( { l_id:null, title:"Goal", description:goalDetails.text, owner_id:null, goal_preset:this.newGoalToSave });
		} else if (this.newGoalToSave == 0 && this.customgoaltext.nativeElement.value) {
			newGoal = new LocalGoal( { l_id:null, title:"Custom Goal", description:this.customgoaltext.nativeElement.value, owner_id:null, goal_preset:this.newGoalToSave });
		} else {
			newGoal = new LocalGoal( { l_id:null, title:"Custom Goal", description:this.customgoaltext.nativeElement.value, owner_id:null, goal_preset:this.newGoalToSave });
		}
		return newGoal;
	}

	////////////////////////////////
	saveNewGoal() {
		let newGoal = this.prepareGoalToSave();

		if (newGoal) {
			newGoal.owner_id = this.sUser.user.id;
			this.sData.saveNewGoal(newGoal)
			.then( res => {
				this.getGoalsAndJournalData();
				this.closeForm();
				setTimeout(() => {
					this.sGlobalPubSub.fireEvent("toast", [true, "Thank you for adding your goal. Don't forget to check in on your goals from time to time!", 4000 ]);
				}, 750);
			})
			.catch( err => { console.log("Error saving new goal", err); });
		}
	}

	////////////////////////////////
	updateSelectedGoal() {
		let theGoal = this.prepareGoalToSave();
		theGoal.l_id = this.selectedGoalItem.l_id;
		theGoal.owner_id = this.selectedGoalItem.owner_id;
		this.sData.replaceDataByTypeAndKey(theGoal, LocalGoal, "l_id", this.selectedGoalItem.l_id)
		.then( res => {
			this.getGoalsAndJournalData();
			this.closeForm();
		})
		.catch( err => { console.log("Error updating goal", err); });
	}

	////////////////////////////////
	deleteSelectedGoal() {
		this.sData.deleteGoalByID(this.selectedGoalItem.l_id)
		.then( res => {
			this.getGoalsAndJournalData();
			this.closeForm();
		})
		.catch( err => { console.log("Error deleting goal", err); });
	}

	////////////////////////////////
	deleteSelectedJournal() {
		this.sData.deleteJournalByID(this.selectedJournalItem.l_id)
		.then( res => {
			if (this.selectedJournalItem.image_file) this.sPhotoHandler.deletePhoto(this.selectedJournalItem.image_file);
			this.getGoalsAndJournalData();
			this.closeForm();
		})
		.catch( err => { console.log("Error deleting journal", err); });
	}

	////////////////////////////////
	addNewJournalItem() {
		this.newJournalItem = new LocalJournal( { date:new Date(), description:"", image_path:"", owner_id:this.sUser.user.id, wellbeing:"" });
		this.openForm('add-journal', 0);
	}

	////////////////////////////////
	createNewJournalItem() {

		if (this.customjournaltext.nativeElement.value == "") {
			this.journalError = "Description unfortunately cannot be empty.  Please review the form and try again.";
			return;
		}
		
		this.newJournalItem.description = this.customjournaltext.nativeElement.value;
		this.newJournalItem.wellbeing = this.journalwellbeing.intValue;

		this.sData.saveNewJournalItem(this.newJournalItem)
		.then(res => {
			if (this.temporaryImagePath) {
				this.sPhotoHandler.saveTempPhoto(this.temporaryImagePath, "/data/user_"+this.sUser.user.id+"/journal_"+res.data.newJournalItem[0].l_id+".jpg")
				.then((res2:any) => {
					console.log("K, so we going to save the yernal:", res2);
					this.newJournalItem.l_id = res.data.newJournalItem[0].l_id;
					this.newJournalItem.image_path = res2.returnData["journal_"+res.data.newJournalItem[0].l_id+".jpg"].ionicUrl;
					this.sData.replaceDataByTypeAndKey(this.newJournalItem, LocalJournal, "l_id", this.newJournalItem.l_id)
					.then(res => { this.completeSaveNewJournalItem(); })
					.catch(err => {})
				}).catch(err => { console.log("Error creating new journal / save photo", err); });
			} else {
				this.completeSaveNewJournalItem();
			}
			setTimeout(() => {
				this.sGlobalPubSub.fireEvent("toast", [true, "Thank you for adding your journal entry. ", 3000 ]);
			}, 750);
		})
		.catch( (err) => { console.log("Error creating new journal", err); });
	}

	////////////////////////////////
	completeSaveNewJournalItem() {
		this.getGoalsAndJournalData();
		this.closeForm();
	}

	////////////////////////////////
	takeJournalPhoto() {
		this.sPhotoHandler.takePhoto()
		.then((res:string) => {
			this.temporaryImagePath = res;
			this.temporaryIonicImagePath = (window as any).Ionic && (window as any).Ionic.WebView && (window as any).Ionic.WebView.convertFileSrc(res) || "";
		})
		.catch(err => { console.log("Error taking journal photo", err); });
	}

	////////////////////////////////
	updateSelectedJournalItem() {
		if (this.customjournaltext.nativeElement.value == "") {
			this.journalError = "Description unfortunately cannot be empty.  Please review the form and try again.";
			return;
		}

		if (this.temporaryImagePath) {
			this.sPhotoHandler.saveTempPhoto(this.temporaryImagePath, "/data/user_"+this.sUser.user.id+"/journal_"+this.selectedJournalItem.l_id+".jpg")
			.then((res2:any) => {
				console.log("K, so we going to update the yernal:", res2);
				let image_file = res2.returnData["journal_"+this.selectedJournalItem.l_id+".jpg"].url;
				let image_path = res2.returnData["journal_"+this.selectedJournalItem.l_id+".jpg"].ionicUrl;
				this.finaliseUpdateSelectedJournal(image_path, image_file);
			}).catch(err => { console.log("Error updating image", err); });
		} else {
			this.finaliseUpdateSelectedJournal();
		}
	}

	////////////////////////////////////
	finaliseUpdateSelectedJournal(image_path = null, image_file = null) {
		if (image_path) this.selectedJournalItem.image_path = image_path;
		if (image_file) this.selectedJournalItem.image_file = image_file;
		this.selectedJournalItem.description = this.customjournaltext.nativeElement.value;
		this.selectedJournalItem.wellbeing = this.journalwellbeing.intValue;
		
		this.sData.replaceDataByTypeAndKey(this.selectedJournalItem, LocalJournal, "l_id", this.selectedJournalItem.l_id)
		.then(this.completeSaveNewJournalItem.bind(this))
		.catch( err => { console.log("Error updating journal", err); });
	}
}
