///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
import { Component, AfterViewInit, ViewChild, Input } from '@angular/core';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
  selector:		'select-dateyear',
  templateUrl:	'./select-dateyear.component.html',
  styleUrls:	['./select-dateyear.component.scss']
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class SelectDateyearComponent implements AfterViewInit {

	////////////////////////////////
	@ViewChild('fld_birthdayyear') fld_birthdayyear;
	@Input() initialValue:number = 1;

	////////////////////////////////
	years:number[] = Array.apply(null, {length: 120}).map(Number.call, Number).map( el => { return new Date().getFullYear() - el } );

	////////////////////////////////
	constructor() {}

	////////////////////////////////
	ngAfterViewInit() {
		if (this.initialValue != 1) {
			this.fld_birthdayyear.nativeElement.value = this.initialValue;
		}
	}

	////////////////////////////////////
	getValue() {
		return this.fld_birthdayyear.nativeElement.value;
	}

	////////////////////////////////////
	setValue(value: number) {
		this.fld_birthdayyear.nativeElement.value = value;
	}

	////////////////////////////////////
	flash() {
		this.fld_birthdayyear.nativeElement.classList.add("flash");
		setTimeout(_ => {
			this.fld_birthdayyear.nativeElement.classList.remove("flash");
		}, 2000);
	}
}
