///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
////////// MODULES
import { Component, OnInit, Output, EventEmitter, Renderer, ElementRef } from '@angular/core';


///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
	selector:		'confirmbutton',
	templateUrl:	'./confirmbutton.component.html',
	styleUrls:		['./confirmbutton.component.scss']
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class ConfirmbuttonComponent implements OnInit {

	@Output() onConfirm: EventEmitter<any> = new EventEmitter();

	clickNum:number = 0;
	timeout:any = 0;

	////////////////////////////////////
	constructor(private renderer:Renderer, private elementRef:ElementRef) { }
	ngOnInit() {
		this.renderer.listen(this.elementRef.nativeElement, 'click', (event) => {
			this.clicked();
		})
	}

	////////////////////////////////////
	clicked() {
		if (this.clickNum === 0) {
			this.clickNum += 1;
			this.elementRef.nativeElement.classList.add('pleaseconfirm');
			this.timeout = setTimeout(this.revertToNormal.bind(this), 2750);
		} else {
			this.revertToNormal();
			this.onConfirm.emit();
		}
	}

	////////////////////////////////////
	revertToNormal() {
		clearTimeout(this.timeout);
		this.clickNum = 0;
		this.elementRef.nativeElement.classList.remove('pleaseconfirm');
	}

}
