///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS
import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { ExerciseService } from '../../services/exercise.service';
import { UserService } from '../../services/user.service';
import { CATEGORY_BREAK_DOWNS } from '../../st-commons/exercise_logic';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
	selector: 'app-page-debugsessions',
	templateUrl: './page-debugsessions.component.html',
	styleUrls: ['./page-debugsessions.component.scss'],
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class PageDebugExSessionsComponent implements OnInit {
	exerciseRating: number = 5;
	exerciseSessions = [];
	expectedBreakdowns: Breakdown[] = [];
	generatedBreakdowns: Breakdown[] = [];

	////////////////////////////////
	constructor(private zone: NgZone, private sExercise: ExerciseService, private sUser: UserService) { }

	////////////////////////////////
	ngOnInit() {
		this.sExercise.determineSessionScenarioCapabilities();
		this.setExpectedBreakdowns();
	}

	////////////////////////////////////
	generateSessionAndSave(rating: number) {
		this.exerciseRating = rating;
		this.generateSessionGo(this.resolveSessionSaved);
		this.setExpectedBreakdowns();
	}

	////////////////////////////////////
	generateSession() {
		this.generateSessionGo(this.resolveSessionUnsaved);
		this.setExpectedBreakdowns();
	}

	////////////////////////////////////
	generateSessionGo(callback) {
		// We may offer additional time options here
		let time = { val: 20, on: true };

		this.sExercise.clearSession().then((res) => {
			this.sExercise
				.createNewSession(time, this.sExercise.selectedSessionScenario)
				.then((session: any) => {
					this.calculateGeneratedBreakdowns(session);
					callback.bind(this)();
				})
				.catch((err) => console.log('ERR', err));
		});
	}

	////////////////////////////////////
	clearLog() {
		this.exerciseSessions = [];
	}

	////////////////////////////////////
	setExpectedBreakdowns() {
		this.generatedBreakdowns = [];
		const breakdowns = CATEGORY_BREAK_DOWNS[this.sExercise.selectedSessionScenario];
		const floorProgression = this.sUser.user.levelProgression.find((lp) => lp.category.uid == 'floor');
		const fpCheckpoint = (floorProgression && floorProgression.checkpoint) || 0;

		const progressionBreakdowns = breakdowns.find((bd) => bd.checkpointMax >= fpCheckpoint);
		this.expectedBreakdowns = Object.keys(progressionBreakdowns)
			.filter((key) => key != 'checkpointMax')
			.map((key) => {
				return { category: this.translateKey(key), value: progressionBreakdowns[key] };
			});
	}

	////////////////////////////////////
	calculateGeneratedBreakdowns(session) {
		const generatedBreakdowns = {};
		Object.keys(CATEGORY_BREAK_DOWNS.balance[0])
			.filter((key) => key != 'checkpointMax')
			.forEach((key) => (generatedBreakdowns[key] = 0));

		session.forEach((action) => {
			let key = action.exercise.category.uid;
			generatedBreakdowns[key] += Math.round(action.duration * 100) / 100;
		});

		let totalDuration = 0;
		Object.keys(generatedBreakdowns).forEach((key) => (totalDuration += generatedBreakdowns[key]));

		this.generatedBreakdowns = Object.keys(generatedBreakdowns)
			.filter((key) => key != 'checkpointMax')
			.map((key) => {
				return {
					category: this.translateKey(key),
					value: Math.round((generatedBreakdowns[key] / totalDuration) * 100),
					userDisabled: (this.sUser.user.levelProgression.find((lp) => lp.category.uid == key).disabled && 'Yes') || '',
				};
			});
	}

	////////////////////////////////////
	translateKey(key: string): string {
		switch (key) {
			case 'floor':
				return 'Floor';
			case 'foam':
				return 'Foam';
			case 'box':
				return 'Box';
			case 'cardio':
				return 'Cardio';
			case 'upbodystr':
				return 'Upper Body Strength';
			case 'lowbodystr':
				return 'Lower Body Strength';
			case 'dart':
				return 'Dartboard';
			case 'grid':
				return 'Grid';
			default:
				return key;
		}
	}

	////////////////////////////////////
	roundBinding(number: number) {
		return Math.round(number);
	}

	////////////////////////////////////
	applySessionActions(newSession) {
		this.sExercise.exercise_session.exercise_actions.forEach((action) => {
			let details = { name: action.name, duration: action.duration, json: JSON.stringify(action.instructions) };

			action.rating = this.exerciseRating;
			action.usedChair = false;
			action.complete = true;
			action.exerciseEndTime = new Date().getTime();
			action.owner = { id: this.sUser.user.id };

			newSession.exercises.push(details);
		});

		return newSession;
	}

	////////////////////////////////////
	resolveSessionUnsaved(res) {
		let newSession = { exercises: [], saved: false };
		newSession = this.applySessionActions(newSession);
		this.exerciseSessions.push(newSession);

		let sessionString = "";
		newSession.exercises.forEach(ex => sessionString += ex.name + "\n" + ex.json + "\n");
		(navigator as any).clipboard.writeText(sessionString);
	}

	////////////////////////////////////
	resolveSessionSaved() {
		let newSession = { exercises: [], saved: false };
		newSession = this.applySessionActions(newSession);

		this.exerciseSessions.push(newSession);
		this.sExercise.saveSession();

		this.sExercise.determineSessionScenarioCapabilities();

		let sessionString = "";
		newSession.exercises.forEach(ex => sessionString += ex.name + "\n" + ex.json + "\n");
		(navigator as any).clipboard.writeText(sessionString);
	}
}

interface Breakdown {
	category: string;
	value: number;
}
