///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
////////// IMPORTS
import { Component, OnInit, ViewChild } from '@angular/core';
import { ExerciseService }				from '../../services/exercise.service';
import { Router }						from '@angular/router';
import { FormBluewaveComponent }		from '../../components/form-bluewave/form-bluewave.component';
import { TimeCircleComponent }			from '../../components/time-circle/time-circle.component';


///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
  selector:		'app-page-exercise5-balancehome',
  templateUrl:	'./page-exercise5-balancehome.component.html',
  styleUrls:	['./page-exercise5-balancehome.component.scss']
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class PageExercise5BalancehomeComponent implements OnInit {

	////////////////////////////////
	@ViewChild("bluewaveform") bluewaveform: FormBluewaveComponent;

	////////////////////////////////
	constructor(private sExercise:ExerciseService, private router:Router) {}
	ngOnInit() {}

	////////////////////////////////
	readyToStart() {
		this.bluewaveform.open();
	}

	////////////////////////////////
	eventReceived(ev) {
		if (ev.type == "cancel") this.bluewaveform.close();
		if (ev.type == "requestContinue") {
			this.sExercise.createNewBalanceAssessment().then(res2 => {
				this.router.navigate(['/exercises/balance/main']);
			}).catch(err => { console.log("ERROR creating new session", err); });
		}
	}
}
