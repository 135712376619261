import { Injectable } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { map, flatMap, tap } from 'rxjs/operators';

import { Vasat, VasatSearchQuery, User } from 'vasat';
import {
	ExerciseCategory,
	Exercise,
	ExerciseAction,
	ExerciseLevel,
	STUser,
	ExerciseState,
	CognitiveExercise,
	ProgressCategory,
	ExerciseInstruction,
	TrialExerciseDefinition,
} from './models';
import { STService } from './service';
import 'rxjs-compat';
import { EACCES } from 'constants';

declare var require; // Otherwise causes an error on jD's comp.  Will resolve correctly later and remove this line.
const M = require('moment');

(<any>Date.prototype).getMonday = function () {
	let d = new Date(this.getTime());
	let day = d.getDay();
	let diff = d.getDate() - day + (day == 0 ? -6 : 1);
	d.setDate(diff);
	d.setHours(0);
	d.setMinutes(0);
	d.setSeconds(0);
	d.setMilliseconds(0);
	return d;
};

export const DOSEAGE_TIMES = {
	_60: [40, 60, 60, 60, 60, 60],
	_120: [40, 60, 80, 100, 120, 120],
	_180: [40, 60, 90, 120, 150, 180],
};

// export const COG_DOSEAGE_TIMES = [0, 0, 0, 0, 20, 20, 40, 40, 60, 60, 60]
export const COG_DOSEAGE_TIMES = [0, 0, 20, 40, 60, 60];

const A_DAY = 3600000 * 24;
const A_WEEK = A_DAY * 7;

const DOSAGE_WEEKS = [2, 4, 6, 8, 10, -1];

interface SessionArguments {
	sessionScenario?: string;
	user: STUser;
	duration: number;
	debug: boolean;
	pad: boolean;
}

interface SessionScenarios {
	balance: CategoryLogic[];
	balance_cardio_strength: CategoryLogic[];
	cardio_strength: CategoryLogic[];
}
interface CategoryLogic {
	checkpointMax: number;
	floor: number;
	foam: number;
	box: number;
	cardio: number;
	upbodystr: number;
	lowbodystr: number;
	dart: number;
	grid: number;
}

// Used to determine percentages of exercise sessions
export const CATEGORY_BREAK_DOWNS: SessionScenarios = {
	// Balance Scenario
	balance: [
		{
			checkpointMax: 2,
			floor: 40,
			foam: 10,
			box: 0,
			cardio: 0,
			upbodystr: 0,
			lowbodystr: 0,
			dart: 25,
			grid: 25,
		},
		{
			checkpointMax: 3,
			floor: 20,
			foam: 10,
			box: 30,
			cardio: 0,
			upbodystr: 0,
			lowbodystr: 0,
			dart: 20,
			grid: 20,
		},
		{
			checkpointMax: Infinity,
			floor: 20,
			foam: 10,
			box: 30,
			cardio: 0,
			upbodystr: 0,
			lowbodystr: 0,
			dart: 20,
			grid: 20,
		},
	],

	// Balance + Cardio + Strength Scenario
	balance_cardio_strength: [
		{
			checkpointMax: 5,
			floor: 20,
			foam: 8,
			box: 0,
			cardio: 20,
			upbodystr: 10,
			lowbodystr: 10,
			dart: 16,
			grid: 16,
		},
		{
			checkpointMax: Infinity,
			floor: 10,
			foam: 10,
			box: 0,
			cardio: 30,
			upbodystr: 15,
			lowbodystr: 15,
			dart: 10,
			grid: 10,
		},
	],

	// Cardio + Strength Scenario (incidentally contains a few balance that are relevant to Cardio + Strength)
	cardio_strength: [
		{
			checkpointMax: 5,
			floor: 20,
			foam: 0,
			box: 0,
			cardio: 30,
			upbodystr: 20,
			lowbodystr: 20,
			dart: 10,
			grid: 0,
		},
		{
			checkpointMax: Infinity,
			floor: 10,
			foam: 0,
			box: 0,
			cardio: 40,
			upbodystr: 20,
			lowbodystr: 20,
			dart: 10,
			grid: 0,
		},
	],
};

var MODIFIERS = {
	'Hand Movement 1': [
		{ animation: 'arms_by_side', beats: 1, multiplier: 1 },
		{ animation: 'l_arm_reach_forward', beats: 1, multiplier: 1 },
		{ animation: 'l_arm_reach_left', beats: 1, multiplier: 1 },
		{ animation: 'l_arm_reach_right', beats: 1, multiplier: 1 },
		{ animation: 'r_arm_reach_forward', beats: 1, multiplier: 1 },
		{ animation: 'r_arm_reach_left', beats: 1, multiplier: 1 },
		{ animation: 'r_arm_reach_right', beats: 1, multiplier: 1 },
	],
	'Hand Movement 2': [
		{ animation: 'hands_on_knees', beats: 1, multiplier: 1 },
		{ animation: 'arms_by_side', beats: 1, multiplier: 1 },
	],
	'Hand Movement 3': [
		{ animation: 'arms_by_side', beats: 1, multiplier: 1 },
		{ animation: 'r_arm_reach_right', beats: 1, multiplier: 1 },
		{ animation: 'arms_by_side', beats: 1, multiplier: 1 },
		{ animation: 'l_arm_reach_left', beats: 1, multiplier: 1 },
	],
	'Hand Movement 4': [
		{ animation: 'arms_by_side', beats: 1, multiplier: 1 },
		{ animation: 'r_arm_reach_right', beats: 1, multiplier: 1 },
		{ animation: 'arms_by_side', beats: 1, multiplier: 1 },
		{ animation: 'l_arm_reach_left', beats: 1, multiplier: 1 },
	],
	'Directional Gaze': [
		{ animation: 'Forward_eyes_open', beats: 1, multiplier: 1 },
		{ animation: 'Look_left_eyes_open', beats: 1, multiplier: 1 },
		{ animation: 'Forward_eyes_open', beats: 1, multiplier: 1 },
		{ animation: 'Look_right_eyes_open', beats: 1, multiplier: 1 },
		{ animation: 'Forward_eyes_open', beats: 1, multiplier: 1 },
		{ animation: 'Look_up_eyes_open', beats: 1, multiplier: 1 },
		{ animation: 'Forward_eyes_open', beats: 1, multiplier: 1 },
		{ animation: 'Look_down_eyes_open', beats: 1, multiplier: 1 },
	],
};

@Injectable()
export class STExerciseLogic {
	constructor(private V: Vasat) {}

	// Do I need to do a balance assesment first?
	needsBalanceAssesment(user: STUser): boolean {
		// if they never had one then yes they need one!
		if (!user.lastBalanceAssesment) return true;

		var lastBalTime = new Date(user.lastBalanceAssesment);
		var now = new Date();

		// if they did it less than half  month ago, long as next months kicked over, then yep do it again
		if (lastBalTime.getDate() <= 15) {
			return now.getMonth() != lastBalTime.getMonth();
		}
		// otherwise they have to wait another month
		else {
			var newTime = new Date(user.lastBalanceAssesment);
			newTime.setMonth(newTime.getMonth() + 2);
			newTime.setDate(1);

			// the months match, or the time now is after the new time
			return now.getMonth() == newTime.getMonth() || now.getTime() > newTime.getTime();
		}
	}

	userShouldDoCog(user) {
		var doCog: boolean = false;
		var wID = this.weekID(new Date());
		if (user.program_type == 'ST+Cogn') {
			var cogDoneThisWeek = user.completedCognitiveHours ? user.completedCognitiveHours[wID] || 0 : 0;
			var daysIn = (new Date().getTime() - (<any>new Date()).getMonday().getTime()) / (3600000 * 24);

			// if they done over their cog dosage mins, stop!
			if (cogDoneThisWeek >= this.cogDoseageForThisWeek(user))
				//TODO @Jayden Magic number here
				doCog = false;
			// if its 4 days of the week, theyre doing it regardless
			else if (daysIn >= 4) doCog = true;
			// otherwsie, its alternativing
			else {
				// do cog if last floor workout wasnt cog
				doCog = !user.lastWorkoutWasCog;
			}
		}

		return doCog;
	}

	// make a balance assesment (If I dont need one will be null)
	makeBalanceAssesment(user: STUser): ExerciseAction {
		if (!this.needsBalanceAssesment(user)) return null;

		var exercises = this.V.getCached(Exercise);
		var balance = exercises.find((e) => e.uid == 'init');
		return new ExerciseAction(this.V).set({
			name: 'Balance Assesment',
			exercise: balance.levels[0],
		});
	}

	makeTrialSession(user: STUser, exerciseIDList: Array<TrialExerciseDefinition>) {
		var exercises = this.V.getCached(Exercise);
		var chosenExercises = [];
		exerciseIDList.forEach((def) => {
			let exercise = exercises.find((ex) => ex.id == def.exerciseID);
			chosenExercises.push(
				this.makeActionForLevel(
					exercise.levels.find((level) => level.id == def.levelID),
					user,
					false,
					true
				)
			);
		});

		return chosenExercises;
	}

	weekID(d: Date) {
		let year = M(d).isoWeekYear();
		let month = Math.min(52, M(d).isoWeek());
		return `${year}_${month}`;
	}

	currentWeekExercises(inCompleteOnly: boolean = false): ExerciseAction[] {
		var week = this.weekID(new Date());
		var exercises = this.V.getCached(ExerciseAction);
		return exercises.filter((e) => (inCompleteOnly ? !e.complete : true) && e.weekID == week);
	}

	weekNumber(user: STUser): number {
		var timeSinceStart: number = new Date().getTime() - user.startDate;
		return Math.floor(timeSinceStart / A_WEEK);
	}

	doseageForThisWeek(user: STUser): number {
		var week = this.weekID(new Date());

		var timeSinceStart = this.weekNumber(user);

		return this.doseageForGivenWeek(user, timeSinceStart);
	}

	cogDoseageForThisWeek(user: STUser): number {
		var week = this.weekID(new Date());

		var timeSinceStart = this.weekNumber(user);

		return this.cogDoseageForGivenWeek(user, timeSinceStart);
	}

	// Get the doseage for a given week
	doseageForGivenWeek(user: STUser, week): number {
		//TODO: @Jayden this is where we actually find the times!

		var hoursMatrix = DOSEAGE_TIMES['_' + user.weekly_doseage];
		//console.log("MATRIX", user, user.weekly_doseage, hoursMatrix);

		var i = 0;

		for (i = 0; i < DOSAGE_WEEKS.length; i++) {
			//console.log(i, DOSAGE_WEEKS.length);
			if (week < DOSAGE_WEEKS[i] || DOSAGE_WEEKS[i] == -1) break;
		}

		return hoursMatrix[i];
	}

	cogDoseageForGivenWeek(user: STUser, week): number {
		var hoursMatrix = COG_DOSEAGE_TIMES;

		var i = 0;

		for (i = 0; i < DOSAGE_WEEKS.length; i++) {
			if (week < DOSAGE_WEEKS[i] || DOSAGE_WEEKS[i] == -1) break;
		}

		return hoursMatrix[i];
	}

	getTheoreticalMax(user: STUser) {
		var v = this.createExerciseSession({ user: user, duration: 1000, debug: false, pad: false });
		var total: any = {
			total: 0,
		};
		v.forEach((ea) => {
			if (!total[ea.exercise.exerciseType.category.uid]) total[ea.exercise.exerciseType.category.uid] = 0;
			total[ea.exercise.exerciseType.category.uid] += ea.duration;
			total.total += ea.duration;
		});

		return total;
	}

	// Give me my exercise Actions for this session
	createExerciseSession(sessionArgs: SessionArguments): ExerciseAction[] {
		// Add debug and pad default values
		if (!Object.keys(sessionArgs).includes('debug')) sessionArgs.debug = false;
		if (!Object.keys(sessionArgs).includes('pad')) sessionArgs.pad = true;

		// Set the category breakdowns based on the sessionArgs
		var categoryBreakDowns = (sessionArgs.sessionScenario && CATEGORY_BREAK_DOWNS[sessionArgs.sessionScenario]) || CATEGORY_BREAK_DOWNS.balance;

		// Check the week id and existing session
		if (this.currentWeekExercises(true).length) throw 'Theres incomplete exercises from another session, you cant start a new one';

		sessionArgs.user.updateRelations();
		var doCog = this.userShouldDoCog(sessionArgs.user);

		// assuming your randomisation rules are on the checkpoint for floor exercises
		var floorState = sessionArgs.user.levelProgression.find((lp) => lp.category.uid == 'floor');

		if (doCog) {
			if (sessionArgs.debug) console.log('Start Ex user.lastWorkoutWasCog not cog therefore do cog');
			// For cognitive, its floor only
			var exercisesAllFloor: ExerciseAction[] = [];
			var timeLeft = sessionArgs.duration;

			floorState.states
				.filter((s) => s.active)
				.forEach((cur) => {
					//   var dur =  cur.currentLevel.totalDuration(this,user)
					//   if((timeLeft - dur) > 0){
					var ea = this.makeActionForLevel(cur.currentLevel, sessionArgs.user);
					exercisesAllFloor.push(ea);
					//     timeLeft -= dur
					//   }
				});

			var cogEA = this.makeCognitiveActions(exercisesAllFloor, sessionArgs.user, sessionArgs.duration, null);
			//exercises.push(cogEA)

			return cogEA;
		} else {
			if (sessionArgs.debug) console.groupCollapsed(`+ Exercise Session Details ${'Cognitive: ' + doCog}`);
			if (sessionArgs.debug) console.log(`Generating Exercise Session ${sessionArgs.sessionScenario}`);
			if (!floorState) throw 'Users has no setting for floor state';

			var exercises: ExerciseAction[] = [];
			var breakdown = categoryBreakDowns.find((bd) => bd.checkpointMax > floorState.checkpoint);
			if (!breakdown) throw 'Could not find valid breakdown logic';

			// figure out if items are disabled, if they are, redistribute weight amoungst others
			var enabled = sessionArgs.user.levelProgression.filter((lp) => !lp.disabled && lp.category.uid != 'init');

			var timeSlots = {};
			enabled.forEach((lp) => {
				timeSlots[lp.category.uid] = breakdown[lp.category.uid] || 0;
			});

			// Get the initial sum of all the enables time slots
			var enabledSum = this.getValueFromTimeslots(timeSlots);

			// Distribute the disabled ex time into the enabled exs with the ratio
			Object.keys(breakdown)
				.filter((k) => !timeSlots[k] && k != 'checkpointMax')
				.forEach((k) => {
					var time = breakdown[k];
					Object.keys(timeSlots).forEach((ek) => {
						timeSlots[ek] += (time / enabledSum) * (breakdown[ek] || 0);
					});
				});

			if (sessionArgs.debug) console.log('Session Breakdown:', timeSlots);

			// Recalculate enabledSum to ensure we are hitting 100%
			enabledSum = 0;
			var enabledSum = this.getValueFromTimeslots(timeSlots);

			// Let's exit if we didn't calculate 100%
			if (Math.round(enabledSum) != 100) throw 'Time total doesnt add to 100%';

			var totalTimeLeft = sessionArgs.duration;
			var floorLevel = -1;

			// now go through and generate exercises
			var exercises: ExerciseAction[] = [];

			var order = ['floor', 'foam', 'box', 'cardio', 'upbodystr', 'lowbodystr', 'dart', 'grid'];
			// var order = ['dart'] // JD Debug - force dartboard only to test helps and issues

			// breakdown of the percent of time per exercise
			order
				.filter((k) => timeSlots[k] > 0)
				.forEach((category) => {
					var perc = timeSlots[category] / 100;

					var cat = sessionArgs.user.levelProgression.find((lp) => lp.category.uid == category);
					if (cat.category.uid == 'floor') {
						floorLevel = cat.checkpoint;
					}

					var timeTally = 0;
					var timeOnCat = Math.round(sessionArgs.duration * perc);

					if (sessionArgs.debug) console.log('## Category ' + category + ' time allowed: ' + timeOnCat + ' mins');

					var timeLeft = timeOnCat;

					while (timeLeft) {
						var states = cat.states
							.filter((s) => s.active)
							.sort((a, b) =>
								// static durations first, commented out for totally random as per request
								// https://app.asana.com/0/725971722123238/991608537876463/f
								//            ((a.exercise.levelSchema == 'static_duration')?'1111':a.exercise.levelSchema)
								//              .localeCompare((b.exercise.levelSchema == 'static_duration')?'1111':b.exercise.levelSchema)
								Math.random() > 0.5 ? -1 : 1
							);
						if (!states.length) break;

						states.forEach((cur) => {
							var ea = this.makeActionForLevel(cur.currentLevel, sessionArgs.user);

							if (timeLeft - ea.duration > 0) {
								exercises.push(ea);
								timeTally += ea.duration;
								if (sessionArgs.debug)
									console.log(
										`> ${cur.exercise.name}, levelId: ${cur.currentLevel.id}, duration: ${ea.duration}, Time left: ${timeLeft} :GOOD`
									);

								timeLeft -= ea.duration;
								totalTimeLeft -= ea.duration;
							} else if (timeLeft) {
								if (sessionArgs.debug) console.log('Time left after minusing the duration will be BELOW 0 so we are BAD');

								exercises.push(ea);
								timeTally += ea.duration;

								if (sessionArgs.debug)
									console.log(
										`>> ${cur.exercise.name}, levelId: ${cur.currentLevel.id}, duration: ${ea.duration}, Time left: ${timeLeft} :SQUISHING INTO TIME`
									);

								totalTimeLeft -= timeLeft;
								timeLeft = 0;
							} else {
								if (sessionArgs.debug)
									console.log(
										'\t' +
											cur.exercise.name +
											' levelId:' +
											cur.currentLevel.id +
											' duration ' +
											timeLeft +
											' time left ' +
											timeLeft +
											' :No TIME left'
									);
							}
						});

						if (!sessionArgs.pad) timeLeft = 0;
					}

					if (sessionArgs.debug) console.log('## End Category ' + category + ' time alloted: ' + timeTally + ' vs ' + timeOnCat);
				});

			/*
			if(floorLevel >= 3){
			  if(totalTimeLeft < 3){
				totalTimeLeft = 3
			  }
			  var cat = user.levelProgression.find(lp => lp.category.uid == 'dance')
			  var items = cat.states.filter(cur => cur.active)
			  var dance = items[Math.floor(Math.random()*items.length)]

			  var ea = new ExerciseAction(this.V)
			  ea.name = cat.category.name + ' : ' + dance.currentLevel.exerciseType.name + ' - ' + dance.currentLevel.level
			  ea.exercise = dance.currentLevel
			  ea.duration = Math.round(totalTimeLeft*100)/100
			  exercises.push(ea)
			}*/

			// If we have both Upper Body Strength and Lower Body Strength, alternate them, to provide appropriate rest
			let upperBodyStrength = 'upbodystr';
			let lowerBodyStrength = 'lowbodystr';
			let hasUpperBodyStrength = exercises.find((ex) => ex.exercise.category.uid == upperBodyStrength);
			let hasLowerBodyStrength = exercises.find((ex) => ex.exercise.category.uid == lowerBodyStrength);
			if (hasUpperBodyStrength && hasLowerBodyStrength) {
				if (sessionArgs.debug) console.log('>>>>> Interleaving Upper and Lower Body Strength Exercises');
				let categories = order;
				categories.splice(order.indexOf(upperBodyStrength), 1);
				categories[categories.indexOf(lowerBodyStrength)] = 'strength_combined';

				let newExerciseOrder = [];
				categories.forEach((category) => {
					let exercisesForThisCategory = [];
					if (category == 'strength_combined') {
						let upBody = exercises.filter((ex) => ex.exercise.category.uid == upperBodyStrength);
						let lowBody = exercises.filter((ex) => ex.exercise.category.uid == lowerBodyStrength);
						while (upBody.length || lowBody.length) {
							if (upBody.length) exercisesForThisCategory.push(upBody.splice(0, 1)[0]);
							if (lowBody.length) exercisesForThisCategory.push(lowBody.splice(0, 1)[0]);
						}
					} else {
						exercisesForThisCategory = exercises.filter((ex) => ex.exercise.category.uid == category);
					}
					newExerciseOrder = newExerciseOrder.concat(exercisesForThisCategory);
				});

				exercises = newExerciseOrder;
			}

			if (sessionArgs.debug) {
				console.log('------------------------------------------');
				console.log('>> Final Exercises');
				console.log(exercises);
				console.groupEnd();
			}

			return exercises;
		}
	}

	// Used in the debugger
	createExercisesFromCategory(user: STUser, category: string) {
		let exerciseActions = [];

		switch (category) {
			case "floor":
			default:
				var cat = user.levelProgression.find((lp) => lp.category.uid == category);
				let states = cat.states
				.filter((s) => s.active);

				states.forEach((cur) => {
					var ea = this.makeActionForLevel(cur.currentLevel, user);
					exerciseActions.push(ea);
				});
			break;
		}
		console.log(exerciseActions);
		return exerciseActions;
	}

	makeActionForLevel(el: ExerciseLevel, user: STUser, noDuration: boolean = false, isForTrialSession: boolean = false) {
		var cat = el.exerciseType.category;
		var ea = new ExerciseAction(this.V);

		ea.name = cat.name + ' : ' + el.exerciseType.name + ' - ' + el.level;
		ea.exercise = el;
		ea.complete = false;
		ea.weekID = this.weekID(new Date());

		// Make actions based on the type
		if (cat.uid == 'dart') {
			this.getDartboardInstructions(user, ea);
		} else if (cat.uid == 'box') {
			this.getBoxInstructions(user, ea);
		} else if (cat.uid == 'grid') {
			this.getGridInstructions(user, ea);
		} else if (cat.uid == 'floor' || cat.uid == 'foam') {
			this.getFloorInstructions(user, ea);
		} else if (cat.uid == 'cardio' || cat.uid == 'upbodystr' || cat.uid == 'lowbodystr') {
			this.getCardioOrUpperOrLowerBodyStrengthInstructions(user, ea);
		} else if (cat.uid == 'cog') {
			var floorState = user.levelProgression.find((lp) => lp.category.uid == 'floor');
			var exercisesAllFloor: ExerciseAction[] = [];

			floorState.states
				.filter((s) => s.active)
				.forEach((cur) => {
					var ea = this.makeActionForLevel(cur.currentLevel, user);
					exercisesAllFloor.push(ea);
				});

			ea = this.makeCognitiveActions(exercisesAllFloor, user, 10, el.level, isForTrialSession).find((ee) => ee.exercise == el);
			ea.duration = ea.duration || 1;
		}
		// March 2022 - Dance never made it in, so removing
		/* else if (cat.uid == 'dance') {
		this.getDanceInstructions(user, ea);*/

		// If no duration was given, calculate it
		if (!noDuration) {
			var d = el.totalDuration(this, user, ea);
			ea.duration = Math.round(d * 100) / 100;
		}

		return ea;
	}

	getValueFromTimeslots(timeSlots: any): number {
		return Object.values(timeSlots).reduce((x: any, y: any) => x + y) as number;
	}

	getCurrentProgression(user: STUser, e: Exercise): ExerciseState {
		var cat = user.levelProgression.find((ec) => ec.category == e.category);
		return cat.states.find((s) => s.exercise == e);
	}

	jumpCheckpoint(currentLevel: number, progressionCat: ProgressCategory, user: STUser) {
		progressionCat.max_checkpoint && (currentLevel = Math.min(currentLevel, progressionCat.max_checkpoint)); // Also cap to max_checkpoint if it exists
		progressionCat.checkpoint = currentLevel;
		progressionCat.states.forEach((s) => {
			s.currentLevel = s.exercise.levels.find((e) => e.level == currentLevel);
			var olderLevels = s.exercise.levels.filter((e) => e.level < currentLevel);
			var harderLevels = s.exercise.levels.filter((e) => e.level > currentLevel);

			// if theres an exerciselevel for this checkpoint (first one, then good)
			if (s.currentLevel) s.active = true;
			// if theres easier levels than this checkpoint and exercise never dies, keep last one of those alive
			else if (!s.currentLevel && !s.exercise.ends && olderLevels.length) {
				s.active = !!olderLevels;
				s.currentLevel = olderLevels[olderLevels.length - 1];
			}
			// otherwise
			else if (harderLevels.length) {
				s.active = false;
				s.currentLevel = harderLevels[0];
			} else {
				s.active = false;
				s.finished = true;
			}
		});
	}

	goUpAtCheckpoint(currentLevel: number, progressionCat: ProgressCategory, forceEx: boolean) {
		console.log('INITIAL', currentLevel);
		currentLevel = Math.max(progressionCat.checkpoint, currentLevel) || 1;
		progressionCat.max_checkpoint && (currentLevel = Math.min(currentLevel, progressionCat.max_checkpoint)); // Also cap to max_checkpoint if it exists
		progressionCat.checkpoint = currentLevel;
		progressionCat.states.filter((s) => !s.active && s.currentLevel && s.currentLevel.level == currentLevel).forEach((s) => (s.active = true));

		console.log('>> Go up', currentLevel, progressionCat, progressionCat.category.uid);

		// if we want to force all exercises to the current level
		if (forceEx)
			progressionCat.states
				.filter((s) => s.currentLevel && s.currentLevel.level < currentLevel)
				.forEach((s) => {
					s.active = s.exercise.ends!;
					s.currentLevel =
						s.exercise.levels.find((e) => e.level == currentLevel) ||
						(!s.exercise.ends ? s.exercise.levels[s.exercise.levels.length - 1] : null);
				});
	}

	saveExercise(user: STUser, exerciseAction: ExerciseAction, retry: number = 0): Observable<ExerciseAction> {
		var el = exerciseAction.exercise;

		var statusChange = 'No Change';
		var progressionCat = user.levelProgression.find((ec) => ec.category == el.exerciseType.category);

		if (!progressionCat && retry < 2) {
			user.updateRelations();
			return this.saveExercise(user, exerciseAction, retry + 1);
		}
		if (!progressionCat) {
			throw 'Detecting that updateRelations() on user was not called!';
		}

		var progression = progressionCat.states.find((s) => s.exercise == el.exerciseType);
		var index = el.exerciseType.levels.indexOf(el);
		var isCog = exerciseAction.exercise.exerciseType.category.uid == 'cog';

		var processLevelUp = true;
		if (isCog) {
			user.lastWorkoutWasCog = true;
			user.cognitiveHoursSinceLastAssess = user.cognitiveHoursSinceLastAssess == undefined ? 0 : user.cognitiveHoursSinceLastAssess;
			user.cognitiveRatings = user.cognitiveRatings || [];

			user.cognitiveHoursSinceLastAssess += exerciseAction.duration;
			user.cognitiveRatings.push(exerciseAction.rating);
			var cognitiveAvgRating = 0;
			user.cognitiveRatings.forEach((c) => (cognitiveAvgRating += c));
			cognitiveAvgRating /= user.cognitiveRatings.length;
			// dont process level like a phycical ex
			processLevelUp = false;
			// process levels for cognitive only after 2 hours of exercise, not at end of each session
			if (user.cognitiveHoursSinceLastAssess >= 120) {
				//TODO @Jayden Magic number here (unsure about this one, but just marking it for later anyway)
				if (cognitiveAvgRating >= 4) {
					this.goUpAtCheckpoint(progressionCat.checkpoint + 1, progressionCat, true);
				}
				// clear their tallys
				user.cognitiveHoursSinceLastAssess = 0;
				user.cognitiveRatings = [];
			}
		} else {
			user.lastWorkoutWasCog = false;
		}
		if (processLevelUp) {
			// going up a level
			if (exerciseAction.rating > 3) {
				var currentLevel = progressionCat.checkpoint;
				var categoryMaxCheckpoint = progressionCat.max_checkpoint || Infinity;
				var requestedLevel = progressionCat.checkpoint;

				// Repair some next_level data (Vasat started recording next_level as nextLevel
				if (!el.next_level && Number.isInteger(el.nextLevel)) el.next_level = el.nextLevel as number;

				// if its a level progression
				if (el.next_level) {
					console.log('next_level: ' + el.next_level + ` in category ${progressionCat.category.uid}`);
					requestedLevel = Math.min(categoryMaxCheckpoint, el.next_level);
					console.log('requestedLevel: ' + requestedLevel);

					// update Level Category
					if (requestedLevel > currentLevel) {
						this.goUpAtCheckpoint(requestedLevel, progressionCat, false);

						// find any exercises that are flagged to start at this level and activate them
						statusChange = 'Progressed to Level ' + requestedLevel;

						if (el.exerciseType.category.uid == 'floor') {
							// special case to trigger the Box exercises to start if the floor exercise ticks to 3
							if (requestedLevel >= 3) {
								var boxCat = user.levelProgression.find((ec) => ec.category.uid == 'box');
								if (boxCat.checkpoint < 3) {
									this.goUpAtCheckpoint(3, boxCat, true);
								}
							}

							// special case to trigger the Foam exercises to start if the floor exercise ticks to 2
							else if (requestedLevel >= 2) {
								var foamCat = user.levelProgression.find((ec) => ec.category.uid == 'foam');
								if (foamCat.checkpoint < 2) {
									this.goUpAtCheckpoint(requestedLevel, foamCat, true);
								}
							}
						}
					} else {
						statusChange = 'No progression: Capped by Max Checkpoint';
					}
				}

				// if this is the last ex for this type, deactivate the chain
				if (el.exerciseType.levels.length - 1 == index) {
					progression.active = !el.exerciseType.ends;
					progression.finished = true;
				} else {
					// otherwise if the next item is ok to be used at this level, activate it (you can have ex for l 1,2,3 and then nothing till 6,7,8)
					var nextItem = el.exerciseType.levels[index + 1];
					if (nextItem.level <= categoryMaxCheckpoint) {
						//progression.active = nextItem.level <= requestedLevel
						// ^ Removed by jD 2021-10-11 because by reading through the logic it could only be that exercises become deactivated forever, when you add max checkpoints to the mix.
						// It was thought better to keep progressions active but not move the currentLevel forward until the maxCP raised.
						progression.currentLevel = nextItem;
					}
				}
			}

			// go down
			else if (exerciseAction.rating == 1) {
				statusChange = 'Went down';
				if (index) {
					if (progressionCat.category.uid != 'floor' && progressionCat.category.uid != 'foam') {
						progression.currentLevel = el.exerciseType.levels[index - 1];

						// drop levels
						this.jumpCheckpoint(Math.max(progressionCat.category.uid == 'box' ? 3 : 1, progression.currentLevel.level), progressionCat, user);
					} else {
						// just going to blindly go down, no regard for if the preivious ex was an older level
						progression.currentLevel = el.exerciseType.levels[index - 1];
					}
				}

				// if theres no exercises left at this level, go down
				/* var maxLevel = 0
				 progressionCat.states.forEach(cur=> maxLevel = Math.max(cur.currentLevel.level,maxLevel))
				 if(progressionCat.checkpoint > maxLevel)
					 progressionCat.checkpoint = maxLevel
				   */
			}
			// if its the first balance assesment ever and you did well, go up accross the board
			// jD Advancing from initial BA is here - but IT MAY NEVER BE CALLED BECAUSE exerciseAction doesn't contain a rating for BA
			else if (el.exerciseType.category.uid == 'init' && user.lastBalanceAssesment == null) {
				// else if (el.exerciseType.category.uid == 'init') { //TEST ONLY
				//Define category dictionary to make code more readable
				let categoryDictionary = {
					floor: 1,
					foam: 2,
					box: 3,
					dart: 4,
					grid: 5,
					balanceAssessment: 6,
					cardio: 14,
					upbodystr: 15,
					lowbodystr: 16,
					cognitive: 8,
				};

				//Determine checkpoint scores based on invidivual exercise scores
				let checkpointScores: CheckpointScores = this.determineCheckpointsAfterFirstBA((exerciseAction as any).individualExercises);

				// Adjust checkpoint scores to max Checkpoint
				checkpointScores = this.adjustCheckpointScoresToMaxCP(checkpointScores, user);

				// Set each level by above checkpoint scores
				user.levelProgression.forEach((levelProgression) => {
					switch (levelProgression.category.id) {
						case categoryDictionary.floor:
							this.goUpAtCheckpoint(checkpointScores.floor, levelProgression, true);
							break;
						case categoryDictionary.foam:
							this.goUpAtCheckpoint(checkpointScores.foam, levelProgression, true);
							break;
						case categoryDictionary.box:
							this.goUpAtCheckpoint(checkpointScores.box, levelProgression, true);
							break;
						case categoryDictionary.dart:
							this.goUpAtCheckpoint(checkpointScores.dart, levelProgression, true);
							break;
						case categoryDictionary.grid:
							this.goUpAtCheckpoint(checkpointScores.grid, levelProgression, true);
							break;
						case categoryDictionary.cardio:
							this.goUpAtCheckpoint(checkpointScores.cardio, levelProgression, true);
							break;
						case categoryDictionary.upbodystr:
							this.goUpAtCheckpoint(checkpointScores.upbodystr, levelProgression, true);
							break;
						case categoryDictionary.lowbodystr:
							this.goUpAtCheckpoint(checkpointScores.lowbodystr, levelProgression, true);
							break;
						// case categoryDictionary.balanceAssessment: 	this.goUpAtCheckpoint(2, levelProgression, true); break;
						// case categoryDictionary.cognitive: 			this.goUpAtCheckpoint(2, levelProgression, true); break;
						default:
							break;
					}
				});

				// if this is the last ex for this type, deactivate the chain
				if (el.exerciseType.levels.length - 1 == index) {
					progression.active = !el.exerciseType.ends;
					progression.finished = true;
				} else {
					// otherwise if the next item is ok to be used at this level, activate it (you can have ex for l 1,2,3 and then nothing till 6,7,8)
					var nextItem = el.exerciseType.levels[index + 1];
					progression.active = nextItem.level <= currentLevel;
					progression.currentLevel = nextItem;
				}
			}
			// stay same
			else {
			}
		}

		if (exerciseAction.exercise.exerciseType.uid == 'init') {
			user.lastBalanceAssesment = new Date().getTime();

			var mID = M(new Date()).format('YYYY_MM');

			if (!user.balancePerMonth) user.balancePerMonth = {};

			if (!user.balancePerMonth[mID]) user.balancePerMonth[mID] = 0;

			user.balancePerMonth[mID] += exerciseAction.duration;
		} else if (isCog) {
			var wID = this.weekID(new Date(exerciseAction.exerciseTime));

			if (!user.completedCognitiveHours) user.completedCognitiveHours = {};

			if (!user.completedCognitiveHours[wID]) user.completedCognitiveHours[wID] = 0;

			user.completedCognitiveHours[wID] += exerciseAction.duration;
		} else {
			var wID = this.weekID(new Date(exerciseAction.exerciseTime));

			if (!user.completedHours) user.completedHours = {};

			if (!user.completedHours[wID]) user.completedHours[wID] = 0;

			user.completedHours[wID] += exerciseAction.duration;

			if (!user.requiredHours) user.requiredHours = {};

			if (!user.requiredHours[wID]) user.requiredHours[wID] = this.doseageForThisWeek(user);
		}

		exerciseAction.outcome = statusChange;
		exerciseAction.complete = true;

		// write current state to user object
		user.updateRelations();

		user.sanityCheck();

		var obs = forkJoin([user.saveObservable(), exerciseAction.saveObservable()]).pipe(map((_) => exerciseAction));

		return obs;
	}

	determineCheckpointsAfterFirstBA(individualExercises: any): CheckpointScores {
		//Get exercise info
		let standingFeetNearTandemFloor: number = individualExercises['Standing Feet Near Tandem - Floor'];
		let standingFeetTandemFloor: number = individualExercises['Standing Feet Tandem - Floor'];
		let standingFeetTogetherFloor: number = individualExercises['Standing Feet Together - Floor'];
		let standingOnLeftLegFloor: number = individualExercises['Standing on Left Leg - Floor'];
		let standingOnRightLegFloor: number = individualExercises['Standing on Right Leg - Floor'];
		let standingFeetNearTandemFoam: number = individualExercises['Standing Feet Near Tandem - Foam'];
		let standingFeetTandemFoam: number = individualExercises['Standing Feet Tandem - Foam'];
		let standingFeetTogetherFoam: number = individualExercises['Standing Feet Together - Foam'];
		let standingOnLeftLegFoam: number = individualExercises['Standing on Left Leg - Foam'];
		let standingOnRightLegFoam: number = individualExercises['Standing on Right Leg - Foam'];

		//Define a function to create a checkpoint scores object
		function generateCheckpointScores(scores: number[]): CheckpointScores {
			let floorValue = scores[0];
			let foamValue = scores[1];
			let boxValue = scores[2];
			let dartValue = scores[3];
			let gridValue = scores[4];
			let cardioValue = scores[5];
			let upBodyStrValue = scores[6];
			let lowBodyStrValue = scores[7];
			return {
				floor: floorValue,
				foam: foamValue,
				box: boxValue,
				dart: dartValue,
				grid: gridValue,
				cardio: cardioValue,
				upbodystr: upBodyStrValue,
				lowbodystr: lowBodyStrValue,
			} as CheckpointScores;
		}

		// Convert values to 0 if null
		if (standingFeetTogetherFloor === null) standingFeetTogetherFloor = 0;
		if (standingFeetTogetherFoam === null) standingFeetTogetherFoam = 0;
		if (standingFeetNearTandemFloor === null) standingFeetNearTandemFloor = 0;
		if (standingFeetNearTandemFoam === null) standingFeetNearTandemFoam = 0;
		if (standingFeetTandemFloor === null) standingFeetTandemFloor = 0;
		if (standingFeetTandemFoam === null) standingFeetTandemFoam = 0;
		if (standingOnLeftLegFloor === null) standingOnLeftLegFloor = 0;
		if (standingOnLeftLegFoam === null) standingOnLeftLegFoam = 0;
		if (standingOnRightLegFloor === null) standingOnRightLegFloor = 0;
		if (standingOnRightLegFoam === null) standingOnRightLegFoam = 0;

		//Check scores and return approriate levels
		//values are arranged as they are to match the table of values provided by the client (StandingTall suggested checkpoint (CP) progression based on balance assessment)

		let checkpointScores = this.calculateCheckpointScores(
			standingFeetTogetherFloor,
			standingFeetTogetherFoam,
			standingFeetNearTandemFloor,
			standingFeetNearTandemFoam,
			standingFeetTandemFloor,
			standingFeetTandemFoam,
			standingOnLeftLegFloor,
			standingOnLeftLegFoam,
			standingOnRightLegFloor,
			standingOnRightLegFoam
		);
		return generateCheckpointScores(checkpointScores);
	}

	calculateCheckpointScores(
		standingFeetTogetherFloor: number,
		standingFeetTogetherFoam: number,
		standingFeetNearTandemFloor: number,
		standingFeetNearTandemFoam: number,
		standingFeetTandemFloor: number,
		standingFeetTandemFoam: number,
		standingOnLeftLegFloor: number,
		standingOnLeftLegFoam: number,
		standingOnRightLegFloor: number,
		standingOnRightLegFoam: number
	) {
		// Prep
		let response;

		// Responses
		// Note: Cardio, and the 2 strength categories were not set for early advancement, and so are set to 1
		let checkpointArrays = {
			A: [6, 5, 7, 8, 8, 1, 1, 1],
			B: [6, 5, 5, 7, 7, 1, 1, 1],
			C: [5, 5, 4, 6, 6, 1, 1, 1],
			D: [4, 5, 3, 4, 4, 1, 1, 1],
			E: [2, 4, 3, 3, 3, 1, 1, 1],
			F: [1, 1, 1, 1, 1, 1, 1, 1],
		};

		let achievements = {
			FT_Fl: standingFeetTogetherFloor >= 15,
			FT_Fo: standingFeetTogetherFoam >= 15,
			NT_Fl: standingFeetNearTandemFloor >= 15,
			NT_Fo: standingFeetNearTandemFoam >= 15,
			T_Fl: standingFeetTandemFloor >= 15,
			T_Fo: standingFeetTandemFoam >= 15,
			Le_Fl: (standingOnLeftLegFloor + standingOnRightLegFloor) / 2 >= 15,
			Le_Fo: (standingOnLeftLegFoam + standingOnRightLegFoam) / 2 >= 15,
		};

		// 'A' Level
		if (achievements.FT_Fl && achievements.NT_Fl && achievements.T_Fl && achievements.Le_Fl && achievements.Le_Fo) response = checkpointArrays.A;
		// 'B' Level
		else if (achievements.FT_Fl && achievements.NT_Fl && achievements.T_Fl && achievements.Le_Fl) response = checkpointArrays.B;
		// 'C' Level
		else if (achievements.FT_Fl && achievements.NT_Fl && achievements.T_Fl) response = checkpointArrays.C;
		// 'D' Level
		else if (achievements.FT_Fl && achievements.NT_Fl) response = checkpointArrays.D;
		// 'E' Level
		else if (achievements.FT_Fl) response = checkpointArrays.E;
		// 'F' Level
		else response = checkpointArrays.F;

		return response;
	}

	/*****************************************************************
	 * Cognitive Logic
	 ****************************************************************/

	makeCognitiveActions(
		allFloor: ExerciseAction[],
		user: STUser,
		duration: number,
		byoLevel: number,
		isForTrialSession: boolean = false
	): ExerciseAction[] {
		var wID = this.weekID(new Date());

		// figure out how much cognitive was done this week
		if (!user.completedCognitiveHours) user.completedCognitiveHours = {};

		if (!user.completedCognitiveHours[wID]) user.completedCognitiveHours[wID] = 0;

		var minsDone = user.completedCognitiveHours[wID];

		let cogDosage: number = this.cogDoseageForThisWeek(user);

		// only 60 mins per week
		if (!isForTrialSession && minsDone >= cogDosage)
			//TODO @Jayden Magic number here
			throw 'Cant do more than ' + cogDosage + ' mins cognitive (calling method should have checked for this)'; //TODO @Jayden Magic number here

		var staticDurations = allFloor.filter((a) => a.exercise.exerciseType.levelSchema == 'static_duration');
		var dynamicTempos = allFloor.filter((a) => a.exercise.exerciseType.levelSchema == 'dynamic_tempo');

		// For trial sessions, we only want standing exercises (in order to bring the difficulty down).
		// The requires a hacky filter of the possible exercises by matching against the animation name.
		if (isForTrialSession) {
			staticDurations = staticDurations.filter((sd) => sd.exercise.exerciseType.animation.toLowerCase().indexOf('st') === 0); // Match "Standing" exercises static
			dynamicTempos = dynamicTempos.filter((dt) => dt.exercise.exerciseType.animation.toLowerCase().indexOf('hr') === 0); // Match  "Heel Raise" exercises for dynamic, if used in Trial Exercises
		}

		// get level progression just like normal
		var progess = user.levelProgression.find((up) => up.category.uid == 'cog');

		var exercises: ExerciseAction[] = [];

		// inital simple cog (10%) no levels jsut pick one
		this._findCog('Initial', 'static_duration', progess, staticDurations, 0.1 * duration, byoLevel).forEach((ea) => exercises.push(ea));

		// 15% audio
		this._findCog('Audio', 'static_duration', progess, staticDurations, 0.075 * duration, byoLevel).forEach((ea) => exercises.push(ea));
		this._findCog('Audio', 'dynamic_tempo', progess, dynamicTempos, 0.075 * duration, byoLevel).forEach((ea) => exercises.push(ea));

		// 15% visual
		this._findCog('Visual', 'static_duration', progess, staticDurations, 0.075 * duration, byoLevel).forEach((ea) => exercises.push(ea));
		this._findCog('Visual', 'dynamic_tempo', progess, dynamicTempos, 0.075 * duration, byoLevel).forEach((ea) => exercises.push(ea));

		if (progess.checkpoint < 2) {
			// 30% Recall
			this._findCog('Recall', 'static_duration', progess, staticDurations, 0.3 * duration, byoLevel).forEach((ea) => exercises.push(ea));

			// 10% pattern
			this._findCog('Pattern', 'static_duration', progess, staticDurations, 0.1 * duration, byoLevel).forEach((ea) => exercises.push(ea));
		} else {
			// 30% Recall
			this._findCog('Recall', 'static_duration', progess, staticDurations, 0.15 * duration, byoLevel).forEach((ea) => exercises.push(ea));
			this._findCog('Recall', 'dynamic_tempo', progess, dynamicTempos, 0.15 * duration, byoLevel).forEach((ea) => exercises.push(ea));

			// 10% pattern
			this._findCog('Pattern', 'static_duration', progess, staticDurations, 0.05 * duration, byoLevel).forEach((ea) => exercises.push(ea));
			this._findCog('Pattern', 'dynamic_tempo', progess, dynamicTempos, 0.05 * duration, byoLevel).forEach((ea) => exercises.push(ea));
		}
		// 20% inhibition
		this._findCog('Inhibition', 'dynamic_tempo', progess, dynamicTempos, 0.1 * duration, byoLevel).forEach((ea) => exercises.push(ea));

		return exercises;
	}
	_findCog(cogType: string, exType: string, lp: ProgressCategory, floors: ExerciseAction[], duration: number, byoLevel: number): ExerciseAction[] {
		var level = lp.states.find((s) => s.exercise.cog_mode == cogType && s.exercise.cog_target == exType);
		var ex = (byoLevel ? level.exercise.levels.find((e) => e.level == byoLevel) : level.currentLevel) || level.currentLevel;

		var suitedExercises = floors
			.filter((ea) => {
				if (exType == 'static_duration') {
					// visual and pattern only when theres no modifierers (ie eyes closed)
					// audio always
					// recall allways

					var hasMods = ea.exercise.exerciseType.is_extra;
					return !hasMods || !(cogType == 'Visual' || cogType == 'Initial' || cogType == 'Pattern');
				} else if (ea.exercise.exerciseType.levelSchema == 'dynamic_tempo') {
					// vis only if leaning rock
					// pattern  only if leaning rock
					// inhibition, dynamic only
					// audio always

					var isLeaning_Rocking = ea.exercise.exerciseType.tempo_rocknroll;
					return !(cogType == 'Visual' || cogType == 'Pattern') || !isLeaning_Rocking;
				}
			})
			.sort((a, b) => a.duration - b.duration);

		if (!suitedExercises.length) console.error('Couldnt find a single exercise for ' + cogType + ' ' + exType);

		var timeLeft = duration;

		var exA: ExerciseAction[] = [];
		while (timeLeft > 0) {
			var head = suitedExercises[Math.floor((suitedExercises.length - 1) * Math.random())];
			// if this ex is the last one taht will push total over, get shortest possible one that will still go over
			if (timeLeft - head.duration < 0) {
				head = suitedExercises.find((cur) => timeLeft - cur.duration <= 0);
			}
			var cog_ea = new ExerciseAction(this.V);
			cog_ea.exercise = ex;
			cog_ea.name = ex.exerciseType.category.name + ' : ' + ex.exerciseType.name + ' - ' + ex.level;
			cog_ea.complete = false;
			cog_ea.duration = head.duration;
			cog_ea.weekID = this.weekID(new Date());
			cog_ea.exerciseTime = new Date().getTime();
			cog_ea.instructions = JSON.parse(JSON.stringify(head.instructions));
			cog_ea.instructions.cognitive = ex.toCog();
			cog_ea.byoHelp = head.getHelp()[0];
			exA.push(cog_ea);
			timeLeft -= head.duration;
		}
		if (cogType == 'Recall') {
			var len = exA.length;
			var fuftyPercent = len / 2;
			for (var i = 0; i < exA.length; i++) {
				var cog_ea = exA[i];
				cog_ea.instructions.cognitive = cog_ea.exercise.toCog({ forceForward: i < fuftyPercent });
			}
		}

		return exA;
	}

	saveCognitive(ea: ExerciseAction, user: STUser, rate: number) {
		// TODO Calc Levels
		ea.complete = true;
		ea.rating = rate;

		// add completed hours to list
		user.completedCognitiveHours[ea.weekID] += ea.duration;
	}

	/*****************************************************************
	 * DartBoard exercise logic
	 *****************************************************************/
	lastDartboardExerciseCreated: ExerciseInstruction;
	action = ['Step & Rock', 'Step & Lift', 'Step & Bend'];
	step = [['Short'], ['Long'], ['Short', 'Long']];
	tempo = [[30], [40], [30, 40]];
	//reps        = [[24],[32],[40]]
	// as per https://app.asana.com/0/725971722123238/958572107046001
	reps = [[12], [16], [20]];
	//cue         = [ ["Shade"],["Symbol"],["Shade","Symbol"] ]
	// changed as per https://app.asana.com/0/725971722123238/991608537876468
	cue = [['Shade'], ['Shade'], ['Shade']];
	direction = ['W/NW/NE/E', 'W/NW/N/NE/E', 'W/NW/N/NE/E/SE/S/SW'];
	// Array with the list of above metrics used for the random generation of instructios based on the points given.
	metrics = [
		{ metric: 'action', values: this.action },
		{ metric: 'step', values: this.step },
		{ metric: 'tempo', values: this.tempo },
		{ metric: 'reps', values: this.reps },
		{ metric: 'cue', values: this.cue },
		{ metric: 'direction', values: this.direction },
	];

	adv_cue = [['Arrows'], ['Compass'], ['Shade'], ['Footprints'], ['Arrows', 'Compass', 'Shade', 'Footprints']];
	/**
	 * Returns the set of instructions based on the provided checkpoint level
	 */
	getDartboardInstructions(user: STUser, exerciseAction: ExerciseAction) {
		console.log("EA DART", exerciseAction);
		var exeLevelz = exerciseAction.exercise;

		// 2023-05-30: lowRange Dartboard used to return only one option of exercise, causing repeats.
		// Now we at least randomise between a few options
		var lowRange = (lev: ExerciseLevel): ExerciseInstruction => {
			const lowRangeOptionsLevel1: ExerciseInstruction[] = [
				// Instruction provided at launch / original
				{
					mode: 'dart',
					animation_name: exerciseAction.exercise.exerciseType.animation,

					action: 'Step & Rock',
					step: [lev.step],
					tempo: [30],
					reps: [12],
					cue: [lev.cue],
					direction: ['W/NW/NE/E'],
				},

				// New Instructions
				{
					mode: 'dart',
					animation_name: exerciseAction.exercise.exerciseType.animation,

					action: 'Step & Rock',
					step: ["Short"],
					tempo: [15],
					reps: [16],
					cue: [lev.cue],
					direction: ['NW/N/NE'],
				},
				{
					mode: 'dart',
					animation_name: exerciseAction.exercise.exerciseType.animation,

					action: 'Step & Bend',
					step: ["Short"],
					tempo: [15],
					reps: [16],
					cue: [lev.cue],
					direction: ['NW/N/NE'],
				},
				{
					mode: 'dart',
					animation_name: exerciseAction.exercise.exerciseType.animation,

					action: 'Step & Lift',
					step: ["Short"],
					tempo: [15],
					reps: [16],
					cue: [lev.cue],
					direction: ['NW/N/NE'],
				},
			];

			const lowRangeOptionsLevel2: ExerciseInstruction[] = [
				// Instruction provided at launch / original
				{
					mode: 'dart',
					animation_name: exerciseAction.exercise.exerciseType.animation,

					action: 'Step & Rock',
					step: [lev.step],
					tempo: [30],
					reps: [12],
					cue: [lev.cue],
					direction: ['W/NW/NE/E'],
				},

				// New Instructions
				{
					mode: 'dart',
					animation_name: exerciseAction.exercise.exerciseType.animation,

					action: 'Step & Rock',
					step: ["Short"],
					tempo: [20],
					reps: [14],
					cue: [lev.cue],
					direction: ['W/NW/N/NE/E'],
				},
				{
					mode: 'dart',
					animation_name: exerciseAction.exercise.exerciseType.animation,

					action: 'Step & Bend',
					step: ["Short"],
					tempo: [20],
					reps: [14],
					cue: [lev.cue],
					direction: ['W/NW/N/NE/E'],
				},
				{
					mode: 'dart',
					animation_name: exerciseAction.exercise.exerciseType.animation,

					action: 'Step & Lift',
					step: ["Short"],
					tempo: [20],
					reps: [14],
					cue: [lev.cue],
					direction: ['W/NW/N/NE/E'],
				},
			];

			const levelArray = lev.level == 1 && lowRangeOptionsLevel1 || lowRangeOptionsLevel2;
			const rand = Math.floor(Math.random() * levelArray.length)
			return levelArray[rand];

		}

		var midRange = (lev: ExerciseLevel) => {
			var points = [];
			// From checkpoint 3 until 9, the presentation is random based on min and max point range
			// form 10 onward, its same but max points
			let minP = lev.level > 9 ? 10 : lev.min;
			let maxP = lev.level > 9 ? 12 : lev.max;
			// Shuffle the metric in random order and then loop through the array of metrics calculating points
			this.shuffleArray(this.metrics);
			// All metrics have 3 options hence max score for all is 2. We define a maxRandom range number based on the checkpoint max score.
			// Note that the only different will be checkpoint 3 which has max point = 1 so we cannot select metrics with 2 points
			// The rest have max point greather than 2 so we use 2 as the maxRandom.
			let maxRandom = maxP < 2 ? maxP : 2;
			var minRandom = 0;
			var curPoints = 0;

			for (let i = 0; i < this.metrics.length; i++) {
				// total current points
				curPoints = this.getSum(points);
				// Pending number of iterations left to do
				var pendingIter = this.metrics.length - i;
				// Maximum number of points possible with the left pending iterations
				var maxPossible = pendingIter * 2;
				// Residual points that can be awarded on next iterations
				var residualP = curPoints + maxPossible - minP;
				// Calculate the minRandom limit depending on how many points left can be granted depending on the minPoints of this checkpoint
				if (residualP == 1) {
					// This means we need to ensure at least 1 point in the next run to meet the minPonits criteria
					minRandom = 1;
				} else if (residualP == 0) {
					// This means me deed to ensure 2 points from now on to meet the minPonits criteria
					minRandom = 2;
				} else {
					// this means we have enough points left so it doesnt matter how many points we award to the next run
					minRandom = 0;
				}

				// Check the current total points.
				if (curPoints >= maxP) {
					// If we have reach the maxP then simply chose 0 point metrics from now on.
					//points.push(0)
					minRandom = 0;
					maxRandom = 0;
				} else if (((curPoints >= minP && curPoints < maxP) || minP == maxP) && maxP - curPoints == 1) {
					// If current points already within the range of min and max points for this checkpoint and also current points is only 1 point from the max
					// then alow to get random from 0 to 1
					maxRandom = 1;
				}
				let rand = this.getRandomInt(minRandom, maxRandom);
				points.push(rand);
			}
			// Build instructions JSON respose
			res = {
				animation_name: exerciseAction.exercise.exerciseType.animation,

				mode: 'dart',
				tempo: [],
				reps: [],
			};
			for (let i = 0; i < points.length; i++) {
				res[this.metrics[i].metric] = this.metrics[i].values[points[i]];
			}
			res['points'] = this.getSum(points);

			return res;
		};
		var res: ExerciseInstruction;
		// Checkpoinnt 1 and 2 are fixed and the instructions are driven by the CMS so we simple pass some values from the CMS.
		if (exeLevelz.level == 1 || exeLevelz.level == 2) {
			// Pick an exercise that is not the same as the last one
			do {
				res = lowRange(exeLevelz);
			} while (JSON.stringify(res) == JSON.stringify(this.lastDartboardExerciseCreated));

			this.lastDartboardExerciseCreated = res;
		} else if (exeLevelz.level >= 3 && exeLevelz.level < 10) {
			res = midRange(exeLevelz);
		}
		//console.log(res)
		else if (exeLevelz.level >= 10) {
			var allDB = [];
			user.levelProgression
				.filter((cur) => cur.category.uid == 'dart')
				.forEach((c) => c.states.forEach((s) => s.exercise.levels.forEach((l) => allDB.push(l))));

			//https://app.asana.com/0/725971722123238/1108967139406567 says logic for this
			var levels = allDB.filter((el) => el.level > 8 && el.level <= exerciseAction.exercise.level);
			var rnd = Math.random();
			var pickOne: ExerciseLevel = levels[Math.max(0, Math.floor(rnd * levels.length - 0.1))];
			if (pickOne.level == 9) {
				res = midRange(pickOne);
				//  delete res.dual
				//  exerciseAction.exercise = pickOne
				exerciseAction.name = pickOne.exerciseType.name + ' ' + pickOne.level;
			} else {
				res = midRange(pickOne);
				//  exerciseAction.exercise = pickOne
				exerciseAction.name = pickOne.exerciseType.name + ' ' + pickOne.level;
				var pickADual = pickOne.dual[Math.max(0, Math.floor(Math.random() * (pickOne.dual.length - 1)))];
				// From checkpoint 10, The step length is now fixed as a long step with a 35 bpm tempo, with each exercise lasting for 20 seconds.
				// The cues will now vary between Arrows, Compass, Shapes, Footprints and Mixed
				res.action = 'Step & Rock';
				res.cue = this.adv_cue[this.getRandomInt(0, this.adv_cue.length - 1)];
				res.dual = [pickADual];
				res.tempo = [35];
				res.step = ['Long'];
			}
		}
		// res action always tep and rock!

		exerciseAction.instructions = res;
		return res;
	}

	// March 2022 - Removed due to removing Simulator from Little Vasat CMS
	/*getDartInstructionsAll(user: STUser, ex: Exercise) {
		var res: ExerciseAction[] = [];
		var typeId = ex.uid;

		var curLogic = !ex.levels[0].is_dual
			? {
					action: this.action,
					step: this.step,
					tempo: this.tempo,
					reps: this.reps,
					cue: this.cue,
					direction: this.direction,
			  }
			: {
					action: ['Step & Rock'],
					cue: this.adv_cue,
					dual: ex.levels.map((el) => el.dual),
					tempo: [35],
					step: ['Long'],
			  };

		var rcur = (array: ExerciseAction[], stack: string[], obj) => {
			if (!stack.length) {
				var ea = new ExerciseAction(user.V);
				ea.instructions = Object.assign({}, obj);
				ea.instructions.mode = 'dart';
				ea.exercise = ex.levels[0];
				ea.instructions.animation_name = ex.animation;
				array.push(ea);
			} else {
				var key = stack[0];
				var subStack = [];

				for (var x = 1; x < stack.length; x++) subStack.push(stack[x]);

				var a: any[] = curLogic[key];
				a.forEach((i) => {
					obj[key] = i;
					rcur(array, subStack, obj);
				});
			}
		};

		rcur(res, Object.keys(curLogic), {});

		return res;
	}*/

	/*****************************************************************
	 * Grid 4x4 exercise logic
	 *****************************************************************/
	lastGridExerciseCreated: ExerciseInstruction;
	grid_action = ['Both feet', null, 'One foot'];
	grid_step = [['Short'], ['Short'], ['Short', 'Long']];
	grid_tempo = [[35], [55], [35, 55]];
	grid_reps = [[8], [10], [12]];
	grid_cue = [['Arrow'], ['Compass'], ['Arrow', 'Compass']];
	grid_direction = ['F/L/R', 'F/L/R/FR/FL', 'F/L/R/FR/FL/B/BR/BL'];

	grid_metrics = [
		{ metric: 'action', values: this.grid_action },
		{ metric: 'step', values: this.grid_step },
		{ metric: 'tempo', values: this.grid_tempo },
		{ metric: 'reps', values: this.grid_reps },
		{ metric: 'cue', values: this.grid_cue },
		{ metric: 'direction', values: this.grid_direction },
	];

	grid_adv_action = ['Both feet', 'One foot'];
	grid_adv_cue = [['Arrow'], ['Shades'], ['Target']];
	grid_adv_steps = [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

	getGridInstructions(user: STUser, exerciseAction: ExerciseAction) {
		var exeLevelz = exerciseAction.exercise;

		var res: ExerciseInstruction;

		// 2023-05-30: lowLevel Grid used to return only one option of exercise, causing repeats.
		// Now we at least randomise between a few options
		var lowLevels = (levelObj: ExerciseLevel): ExerciseInstruction => {
			const lowRangeOptionsLevel1: ExerciseInstruction[] = [

				// The original instruction
				{
					mode: 'grid',
					action: exeLevelz.action,
					step: ['Short'],
					tempo: [35],
					reps: [8],
					cue: ['Arrows'],
					direction: ['F/L/R'],
				},

				// New instructions
				{
					mode: 'grid',
					action: exeLevelz.action,
					step: ['Short'],
					tempo: [15],
					reps: [6],
					cue: ['Arrows'],
					direction: ['L/R'],
				},
				{
					mode: 'grid',
					action: "One foot",
					step: ['Short'],
					tempo: [15],
					reps: [6],
					cue: ['Arrows'],
					direction: ['L/R'],
				}
			];

			const lowRangeOptionsLevel2: ExerciseInstruction[] = [

				// The original instruction
				{
					mode: 'grid',
					action: exeLevelz.action,
					step: ['Short'],
					tempo: [35],
					reps: [8],
					cue: ['Arrows'],
					direction: ['F/L/R'],
				},

				// New instructions
				{
					mode: 'grid',
					action: exeLevelz.action,
					step: ['Short'],
					tempo: [30],
					reps: [10],
					cue: ['Arrows'],
					direction: ['F/L/R'],
				},
				{
					mode: 'grid',
					action: "One foot",
					step: ['Short'],
					tempo: [30],
					reps: [10],
					cue: ['Arrows'],
					direction: ['F/L/R'],
				}
			];

			const levelArray = levelObj.level == 1 && lowRangeOptionsLevel1 || lowRangeOptionsLevel2;
			const rand = Math.floor(Math.random() * levelArray.length)
			return levelArray[rand];
		}

		var midLevels = (levelObj: ExerciseLevel) => {
			var points = [];
			// From checkpoint 3 until 9, the presentation is random based on min and max point range
			let minP = levelObj.min;
			let maxP = levelObj.max;
			// Shuffle the metric in random order and then loop through the array of metrics calculating points
			// Special case for Grid. We leave first item (Action) first as is a sepcail case with 2 options only so we treat it differently.
			var metricsArray = this.grid_metrics.slice(1);
			this.shuffleArray(metricsArray);
			metricsArray.unshift(this.grid_metrics[0]);
			// All metrics have 3 options hence max score for all is 2. We define a maxRandom range number based on the checkpoint max score.
			// Note that the only different will be checkpoint 3 which has max point = 1 so we cannot select metrics with 2 points
			// The rest have max point greather than 2 so we use 2 as the maxRandom.
			let maxRandom = maxP < 2 ? maxP : 2;
			var minRandom = 0;
			var curPoints = 0;

			for (let i = 0; i < metricsArray.length; i++) {
				// total current points
				curPoints = this.getSum(points);
				// Pending number of iterations left to do
				var pendingIter = metricsArray.length - i;
				// Maximum number of points possible with the left pending iterations
				var maxPossible = pendingIter * 2;
				// Residual points that can be awarded on next iterations
				var residualP = curPoints + maxPossible - minP;
				// Calculate the minRandom limit depending on how many points left can be granted depending on the minPoints of this checkpoint
				if (residualP == 1) {
					// This means we need to ensure at least 1 point in the next run to meet the minPonits criteria
					minRandom = 1;
				} else if (residualP == 0) {
					// This means me deed to ensure 2 points from now on to meet the minPonits criteria
					minRandom = 2;
				} else {
					// this means we have enough points left so it doesnt matter how many points we award to the next run
					minRandom = 0;
				}

				// Check the current total points.
				if (curPoints >= maxP) {
					// If we have reach the maxP then simply chose 0 point metrics from now on.
					minRandom = 0;
					maxRandom = 0;
				} else if (((curPoints >= minP && curPoints < maxP) || minP == maxP) && maxP - curPoints == 1) {
					// If current points already within the range of min and max points for this checkpoint and also current points is only 1 point from the max
					// then alow to get random from 0 to 1
					maxRandom = 1;
				}

				// Special case for Grid. If first time and max points is 1 then then can only push 0 as action do not have an action with 1 points.
				if (i == 0 && maxP == 1) {
					points.push(0);
				} else if (i == 0) {
					// Special case for Grid. If first time then only choose between 0 or 2 as there are only two options for the action metric.
					let rand = this.getRandomFrom(0, 2);
					points.push(rand);
				} else {
					let rand = this.getRandomInt(minRandom, maxRandom);
					points.push(rand);
				}
			}
			// Build instructions JSON respose
			var loc_res: ExerciseInstruction = {
				animation_name: exerciseAction.exercise.exerciseType.animation,

				mode: 'grid',
				tempo: [],
				reps: [],
			};
			for (let i = 0; i < points.length; i++) {
				loc_res[metricsArray[i].metric] = metricsArray[i].values[points[i]];
			}
			loc_res['points'] = this.getSum(points);
			return loc_res;
		};
		// Checkpoint 1 and 2 are fixed
		if (exerciseAction.exercise.level == 1 || exerciseAction.exercise.level == 2) {
			do {
				res = lowLevels(exeLevelz);
			} while (JSON.stringify(res) == JSON.stringify(this.lastGridExerciseCreated));

			this.lastGridExerciseCreated = res;
		} else if (exerciseAction.exercise.level >= 3 && exerciseAction.exercise.level < 10) {
			res = midLevels(exeLevelz);
		} else {
			var levels = exerciseAction.exercise.exerciseType.levels.filter((el) => el.level > 8 && el.level <= exerciseAction.exercise.level);
			var pickOne = levels[Math.max(0, Math.floor(Math.random() * levels.length - 0.1))];
			// exerciseAction.exercise = pickOne
			exerciseAction.name = pickOne.exerciseType.name + ' ' + pickOne.level;
			if (pickOne.level == 9) {
				res = midLevels(pickOne);
			} else {
				var dualPick = pickOne.dual[Math.max(0, Math.floor(Math.random() * pickOne.dual.length - 0.1))];
				// From checkpoint 10, The step length is now fixed as a long step with a 35 bpm tempo, with each exercise lasting for 20 seconds.
				// The cues will now vary between Arrows, Compass, Shapes, Footprints and Mixed
				res = {
					animation_name: exerciseAction.exercise.exerciseType.animation,

					mode: 'grid',
					action: this.grid_adv_action[this.getRandomInt(0, this.grid_adv_action.length - 1)], // Fixed, always same value
					step: [pickOne.step], // Fixed, always same value
					dual: [dualPick],
					reps: [8],
					tempo: pickOne.tempo ? [pickOne.tempo] : null, // Fixed, always same value
					stepCount: this.grid_adv_steps[this.getRandomInt(0, this.grid_adv_steps.length - 1)], // Fixed, always same value
					cue: this.grid_adv_cue[this.getRandomInt(0, this.grid_adv_cue.length - 1)], // Random
					direction: ['F/L/R/FR/FL/B/BR/BL'], // Random
				};
			}
		}
		res.mode = 'grid';
		exerciseAction.instructions = res;
		return res;
	}

	// March 2022 - Removed due to removing Simulator from Little Vasat CMS
	/*getGridInstructionsAll(user: STUser, exLevel: Exercise) {
		var res: ExerciseAction[] = [];
		var typeId = exLevel.uid;

		var curLogic = {
			action: this.grid_action,
			step: this.grid_step,
			tempo: this.grid_tempo,
			reps: this.grid_reps,
			cue: this.grid_cue,
			direction: this.grid_direction,
		};
		var rcur = (array: ExerciseAction[], stack: string[], obj) => {
			if (!stack.length) {
				var ea = new ExerciseAction(user.V);
				ea.instructions = Object.assign({}, obj);
				ea.instructions.mode = 'grid';
				ea.exercise = exLevel.levels[0];
				ea.instructions.animation_name = exLevel.animation;
				array.push(ea);
			} else {
				var key = stack[0];
				var subStack = [];

				for (var x = 1; x < stack.length; x++) subStack.push(stack[x]);

				var a: any[] = curLogic[key];
				a.forEach((i) => {
					obj[key] = i;
					rcur(array, subStack, obj);
				});
			}
		};

		rcur(res, Object.keys(curLogic), {});

		return res;
	}*/

	/*****************************************************************
	 * Box exercise logic
	 *****************************************************************/
	BOX_COMBINATION = ['combination'];

	BOX_LOGIC = {
		BOX_TAP: {
			tempo: [45, 55, 65],
			reps: [6, 8, 10], //https://app.asana.com/0/725971722123238/991608537876461/f
			cue: ['Closest side of box', 'Furthest side of box', 'Mixed'],
			action: ['Forwards', 'Sideways'],
		},
		BOX_OVER: {
			tempo: [55, 70, 85],
			reps: [1, 2, 3],
			cue: ['Simple steps', 'Simple steps'],
			action: ['Sideways', 'Forwards', this.BOX_COMBINATION],
		},
		BOX_UP: {
			tempo: [55, 70, 85],
			reps: [4, 6, 8],
			cue: ['Simple steps', 'High knees'],
			action: ['Forwards', 'Sideways'],
		},
		BOX_DOWN: {
			tempo: [45, 55, 65],
			reps: [4, 6, 8],
			cue: ['Step down', 'Tap down'],
			action: ['Backwards', 'Sideways', 'Mixed_Backwards_Sideways'],
		},
	};
	getBoxInstructions(user: STUser, exerciseAction: ExerciseAction) {
		const logic = this.BOX_LOGIC;
		var exLevel = exerciseAction.exercise;
		var scoreNeeded = exLevel.score;
		var typeId = exLevel.exerciseType.uid;

		if (logic[typeId]) {
			var obj = logic[typeId];

			if (!exerciseAction.instructions) exerciseAction.instructions = {};
			exerciseAction.instructions.mode = 'box';
			exerciseAction.instructions.animation_name = exerciseAction.exercise.exerciseType.animation;

			var cols = Object.keys(obj);
			if (typeId == 'BOX_TAP') cols = cols.filter((k) => k != 'action');

			var distro = {};
			var sum = -1;
			var x = 0;
			while (sum != scoreNeeded && x < 10) {
				if (sum == -1) {
					sum = 0;
					cols.forEach((c) => (distro[c] = Math.round((obj[c].length - 1) * Math.random())));
				} else {
					cols.forEach((c) => {
						if (sum > scoreNeeded && distro[c]) {
							distro[c]--;
							sum--;
						} else if (sum < scoreNeeded && distro[c] < obj[c].length - 1) {
							distro[c]++;
							sum++;
						}
					});
				}
				sum = 0;
				cols.forEach((c) => (sum += distro[c]));
				x++;
			}
			cols.forEach((curKey) => {
				var curArray = obj[curKey];

				var offset = distro[curKey];
				var cp = curArray[offset];
				//if (curKey == "cue" || curKey == "action") cp = curArray[Math.floor(Math.random() * (offset+1))]; // TEMP overwriter, to trial anything at scoreNeeded or below
				if (cp == 'Combination') exerciseAction.instructions[curKey] = curArray.filter((a) => a != cp);
				else exerciseAction.instructions[curKey] = [cp];
			});

			if (typeId == 'BOX_TAP') exerciseAction.instructions['action'] = Math.random() > 0.5 ? 'Forwards' : 'Sideways';
		} else {
			console.error('BOX TYPE ' + typeId + ' Unknown!');
		}
	}

	// March 2022 - Removed due to removing Simulator from Little Vasat CMS
	/*getBoxInstructionsAll(user: STUser, ex: Exercise) {
		var res: ExerciseAction[] = [];
		var typeId = ex.uid;

		if (this.BOX_LOGIC[typeId]) {
			var curLogic = this.BOX_LOGIC[typeId];

			var rcur = (array: ExerciseAction[], stack: string[], obj) => {
				if (!stack.length) {
					var ea = new ExerciseAction(user.V);
					ea.instructions = Object.assign({}, obj);
					ea.instructions.mode = 'box';
					ea.exercise = ex.levels[0];
					ea.instructions.animation_name = ex.animation;
					array.push(ea);
				} else {
					var key = stack[0];
					var subStack = [];

					for (var x = 1; x < stack.length; x++) subStack.push(stack[x]);

					var a: any[] = curLogic[key];
					a.forEach((i) => {
						obj[key] = i;
						rcur(array, subStack, obj);
					});
				}
			};

			rcur(res, Object.keys(curLogic), {});
		}
		return res;
	}*/

	/*****************************************************************
	 * Dance exercise logic
	 *****************************************************************/
	// March 2022 - Dance never made it in to the project, so removing
	/*getDanceInstructions(user: STUser, exerciseAction: ExerciseAction) {
		exerciseAction.instructions = {
			mode: <any>(exerciseAction.exercise.exerciseType.levelSchema || 'not_specified'),
			animation_name: exerciseAction.exercise.exerciseType.animation,
			start_state_animation: exerciseAction.exercise.exerciseType.starting_animation,
			camera_view: exerciseAction.exercise.exerciseType.camera_view,
			feet_icon: exerciseAction.exercise.exerciseType.feet_icon,
			floorSequence: exerciseAction.exercise.steps,
			//duration:[exerciseAction.exercise.duration],
			tempo: [exerciseAction.exercise.tempo],
			reps: [exerciseAction.exercise.reps || 1],
		};
	}*/

	/*****************************************************************
	 * Floor exercise logic
	 *****************************************************************/
	getFloorInstructions(user: STUser, exerciseAction: ExerciseAction) {
		var mod = exerciseAction.exercise.exerciseType.modifier_animation ? MODIFIERS[exerciseAction.exercise.exerciseType.modifier_animation] : null;

		var fs = exerciseAction.exercise.custom_animation ? exerciseAction.exercise.floorSequence : exerciseAction.exercise.exerciseType.floorSequence;
		var tempo = [exerciseAction.exercise.tempo];
		var isStatic = exerciseAction.exercise.exerciseType.levelSchema == 'static_duration';
		if (isStatic) {
			/*fs = [{
			  animation:exerciseAction.exercise.exerciseType.name,
			  beats:1,
			  multiplier:1
			}]*/
			// floor sequence now supplied by CMS
			tempo = [60 / exerciseAction.exercise.duration];
		} else if (fs)
			fs.forEach((a) =>
				Object.keys(a)
					.filter((k) => k != 'animation' && k != 'beats' && k != 'multiplier')
					.forEach((k) => delete a[k])
			);

		exerciseAction.instructions = {
			mode: <any>(exerciseAction.exercise.exerciseType.levelSchema || 'not_specified'),
			animation_name: exerciseAction.exercise.exerciseType.animation,
			start_state_animation: exerciseAction.exercise.exerciseType.starting_animation,
			camera_view: exerciseAction.exercise.exerciseType.camera_view,
			feet_icon: exerciseAction.exercise.exerciseType.feet_icon,
			floorSequence: fs,
			//duration:[exerciseAction.exercise.duration],
			tempo: tempo,
			reps: [isStatic ? 1 : exerciseAction.exercise.reps],
			modifier: mod,
		};
	}

	/*****************************************************************
	 * Cardio exercise logic
	 *****************************************************************/
	getCardioOrUpperOrLowerBodyStrengthInstructions(user: STUser, exerciseAction: ExerciseAction) {
		let timingProperties;
		let exerciseLevel: ExerciseLevel = exerciseAction.exercise;
		let exercise: Exercise = exerciseLevel.exerciseType;

		switch (exerciseAction.exercise.exerciseType.timing) {
			case 'tempo+duration':
				timingProperties = {
					timing: 'tempo+duration',
					tempo: exerciseLevel.tempo,
					duration: exerciseLevel.duration,
					mode: exercise.mode || '',
				};
				break;
			case 'tempo+reps':
				timingProperties = {
					timing: 'tempo+reps',
					tempo: exerciseLevel.tempo,
					reps: exerciseLevel.reps,
					duration: (60 / exerciseLevel.tempo) * exerciseLevel.reps,
					mode: exercise.mode || '',
				};
				break;
			default:
				timingProperties = {};
		}

		exerciseAction.instructions = {
			animation_name: exercise.animation,
			start_state_animation: exercise.starting_animation,
			camera_view: exercise.camera_view,
			feet_icon: exercise.feet_icon,
			...timingProperties,
		};

		// And add a floor sequence if needs be
		var fs = exerciseAction.exercise.exerciseType.floorSequence;
		if (fs) exerciseAction.instructions.floorSequence = fs;

		// And add box instructions if needs be
		if (
			exerciseAction.exercise.exerciseType.levelSchema == 'box' ||
			exerciseAction.exercise.exerciseType.levelSchema == 'cardio_box' ||
			exerciseAction.exercise.exerciseType.levelSchema == 'lowbodystr_box'
		) {
			exerciseAction.instructions.mode = 'box';
			exerciseAction.instructions.animation_name = exerciseAction.exercise.exerciseType.animation;
		}
	}

	adjustCheckpointScoresToMaxCP(checkpointScores: CheckpointScores, user: STUser): CheckpointScores {
		Object.keys(checkpointScores).forEach((key) => {
			let state = user.levelProgression.find((lp) => lp.category.uid == key);
			let maxCP = state.max_checkpoint || null;
			maxCP && (checkpointScores[key] = Math.min(checkpointScores[key], maxCP));
		});
		return checkpointScores;
	}

	/**************************** Helper functions ****************************/
	/**
	 * Returns a random integer between min (inclusive) and max (inclusive)
	 * Using Math.round() will give you a non-uniform distribution!
	 */
	getRandomInt(min, max) {
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}
	/**
	 * Returns random value between the 2 values passed as parameters.
	 * @param value1
	 * @param value2
	 */
	getRandomFrom(value1, value2) {
		return Math.random() < 0.5 ? value1 : value2;
	}

	/**
	 * Randomize array element order in-place.
	 * Using Durstenfeld shuffle algorithm.
	 * Taken from here: https://stackoverflow.com/a/12646864/3792636
	 */
	shuffleArray(array) {
		for (var i = array.length - 1; i > 0; i--) {
			var j = Math.floor(Math.random() * (i + 1));
			var temp = array[i];
			array[i] = array[j];
			array[j] = temp;
		}
	}
	/**
	 * Returns the sum of all the number items from the give array
	 */
	getSum(array: number[]) {
		var res = 0;
		array.forEach((item) => (res += item));
		return res;
	}
}

interface CheckpointScores {
	floor: number;
	foam: number;
	box: number;
	dart: number;
	grid: number;
	cardio: number;
	upbodystr: number;
	lowbodystr: number;
}
