///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
import { Component, OnInit, ViewChild,
		 ViewChildren, QueryList, OnDestroy,
		 ChangeDetectorRef, ElementRef }	from '@angular/core';
import { ActivatedRoute, Router }			from '@angular/router';
import { DataService } 						from '../../services/data.service';
import { DeviceService }					from '../../services/device.service';
import { FormBluewaveComponent }			from '../../components/form-bluewave/form-bluewave.component';
import { GlobalPubSub }						from '../../services/global-pub-sub.service';
import { UserService }						from '../../services/user.service';
import { PhotoHandlingService }				from '../../services/photohandling.service';
import { FileStream }						from '../../objects/filestream';
import { ControlToggleComponent }			from '../../components/control-toggle/control-toggle.component';
import { ContentManagerService }			from '../../services/contentmanager.service';
import { CommandForceAlphaService }			from '../../services/commandforcealpha.service';

declare var require;

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
  selector: 'app-page-settingsprofile',
  templateUrl: './page-settingsprofile.component.html',
  styleUrls: ['./page-settingsprofile.component.scss']
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class PageSettingsprofileComponent implements OnInit, OnDestroy {

	////////////////////////////////
	//@ViewChild(SubpageLoginformComponent) loginForm: SubpageLoginformComponent;
	@ViewChild("bluewaveform") bluewaveform: FormBluewaveComponent;
	@ViewChild("fld_birthdayday") fld_birthdayday;
	@ViewChild("fld_birthdaymonth") fld_birthdaymonth;
	@ViewChild("fld_birthdayyear") fld_birthdayyear;
	@ViewChild("profImage") profImage;
	@ViewChildren('toggles') toggles: QueryList<ControlToggleComponent>;

	////////////////////////////////
	uid:any = null;
	userProfiles:any[] = [];
	formstate:string = "edit";
	defaultProfilePhotoUrl:string = "assets/img/gr.profileplaceholder.png";
	focusProfilePhotoURL:string = this.defaultProfilePhotoUrl;
	active:boolean = true;

	////////////////////////////////
	profileQuestions = require('../../../assets/json/profileQuestions.json');

	////////////////////////////////
	constructor(private route: ActivatedRoute, private sData:DataService, private router:Router, private sGlobalPubSub:GlobalPubSub, private sUser:UserService,
				private sPhotoHandler:PhotoHandlingService, private changeDetectorRef:ChangeDetectorRef, private hostRef:ElementRef,
				private sContentManager:ContentManagerService, private sCommand:CommandForceAlphaService,
				private sDevice:DeviceService) {}

	////////////////////////////////
	ngOnInit() {

		////////////////////////////////////
		this.collectUserProfiles()
		.then((res:any[]) => {
			let primeUser = res.filter( it => { return it.primeUser == true; })[0];
			setTimeout (() => { if (primeUser) this.selectProfileGo(primeUser.id); }, 1100);
		});
	}

	////////////////////////////////
	ngOnDestroy() {
		this.active = false;
	}

	////////////////////////////////
	collectUserProfiles() {
		let resolver = function(resolve, reject) {
			this.sData.getUserProfiles()
			.then( res => {
				this.userProfiles = res.data.userProfiles;
				this.linkUserProfilePhotos();
				resolve(this.userProfiles);
			}).catch(reject);
		}.bind(this);
		return new Promise(resolver);
	}

	////////////////////////////////
	selectProfile(id) {
		this.sData.checkAllUserDataSynced()
		.then(res => this.selectProfileGo.bind(this)(id))
		.catch(err => {
			this.sData.sendAllServerUpdates();
			this.sGlobalPubSub.fireEvent("toast", [true, "Please go online to switch profiles. Your data must be synced before switching."]);
		});
	}

	////////////////////////////////
	selectProfileGo(id) {

		// Define prime users and other users
		let thePrimeUser:any;
		let userPrePrimes:any = {};

		// Set Prime and sort
		this.userProfiles.forEach(it => {
			userPrePrimes['user_'+it.id] = it.primeUser;
			it.primeUser = (it.id == id);
			if (it.id == id) thePrimeUser = it;
		});
		
		//If no prime user was found but we have at least 1 user profile
		if (!thePrimeUser && this.userProfiles[0]) {

			//Set prime user
			this.userProfiles[0].primeUser = true;
			thePrimeUser = this.userProfiles[0];
		}

		// Save all users that changed prime
		let updateProfiles = [];
		this.userProfiles.forEach(it => {
			let primeChanged = (userPrePrimes['user_'+it.id] != it.primeUser);
			if (primeChanged) updateProfiles.push(it);
		});
		this.userProfiles.forEach(prof => {
			this.sData.updateUserProfile(prof, false, null); // Don't send a server update, it's just primeUser
		});
		this.completeSelectProfile(thePrimeUser);

		/*if (updateProfiles.length) {
			var sendUpdate = function(profile) {
				this.sData.updateUserProfile(profile, false, null).then(res => { // Or SHOULD we send the user's profile here?  I don't think so as it's just 'primeUser' property
					updateProfiles.splice(0, 1);
					if (updateProfiles.length) sendUpdate(updateProfiles[0]);
					else this.completeSelectProfile(thePrimeUser);
				})
			}.bind(this);
			sendUpdate(updateProfiles[0]);
		} else this.completeSelectProfile(thePrimeUser);*/

		//this.fld_birthdayday.setValue(this.sUser.birthdayday);
		//this.fld_birthdaymonth.setValue(this.sUser.birthdaymonth);
		//this.fld_birthdayyear.setValue(this.sUser.birthdayyear);
	}

	////////////////////////////////
	completeSelectProfile(thePrimeUser) {
		// Tell sUser to set a new prime User;
		this.sUser.setPrimeUserByProvidingUser(thePrimeUser);
		if (this.active) this.changeDetectorRef.detectChanges();
		this.setFocusProfileDefaults();

		// Reinstate Vasat token for this user
		this.sData.reinstateVasatToken()
		.then(() => {
			// Request an update of the user from the server
			this.sContentManager.dataPullLog.markDataAsOutdated('Me');
			this.sContentManager.dataPullLog.checkForUpdates()
			.then(() => {
				if (this.active) this.changeDetectorRef.detectChanges();
			});
		});

		// Open the form
		this.bluewaveform.setFormContentState("step-1");
		this.bluewaveform.open();

		// Reset the user adherence check information and prompt a check
		this.sCommand.lastAdherenceCheck = 0;
		this.sCommand.userAdherenceCheck();
	}

	////////////////////////////////
	linkUserProfilePhotos() {
		this.userProfiles.forEach(prof => {
			let fs = new FileStream();
			fs.setup('dataDirectory')
			.getEntry("/data/user_"+prof.id+"/profilePhoto.jpg", false)
			.toURL()
			.go()
			.then(res => {
				if (res.returnData['profilePhoto.jpg']) {
					prof.profilePhotoUrl = res.returnData['profilePhoto.jpg'].ionicUrl+"?cache_break="+Math.floor(Math.random()*1000);
				}
				else this.setDefaultProfilePhoto(prof);
			}).catch(err => this.setDefaultProfilePhoto(prof));
		});
	}

	////////////////////////////////////
	setDefaultProfilePhoto(profile) {
		profile.profilePhotoUrl = this.defaultProfilePhotoUrl;
	}

	////////////////////////////////
	logout(id) {
		this.sData.checkAllUserDataSynced()
		.then((res) => { return this.sData.logoutProfile(id); })
		.then((res:any) => {
			if (res.data.remainingUsers.length) {
				this.bluewaveform.close();
				this.userProfiles = res.data.remainingUsers;
				
				// Auto-select the next primeUser
				let primeUser = this.userProfiles.find(it => it.primeUser == true);
				setTimeout(() => document.querySelector("#bt-top").scrollIntoView(), 825);
				if (primeUser) this.selectProfile(primeUser.id);
			} else {
				this.router.navigate(['/onboarding']);
			}
		}).catch(err => {
			this.sData.sendAllServerUpdates();
			this.sGlobalPubSub.fireEvent("toast", [true, "Unable to logout right now as you have unsynced data. Please try again in a few moments."]);
		});
	}

	////////////////////////////////
	toAdd() {
		this.bluewaveform.open();
		this.bluewaveform.setFormContentState("add");
	}

	////////////////////////////////
	closePanel() {
		this.bluewaveform.close();
	}

	////////////////////////////////
	updateProfile(property) {
		if (!Array.isArray(property)) property = [property];
		this.sData.updateUserProfile(this.sUser.user, true, property);
	}

	////////////////////////////////
	updateProfileButDontUpdateProfile() {
		this.sData.updateUserProfile(this.sUser.user, false, []);
	}

	////////////////////////////////
	updateProfileName(userID, property) {
		this.updateProfile(property);
		setTimeout(() => {
			this.userProfiles.forEach(prof => {
				if (prof.id == userID) {
					prof.first_name = this.sUser.user.first_name;
					prof.last_name = this.sUser.user.last_name;
				}
			});
		}, 250);
	}

	////////////////////////////////
	updateProfileBirthday() {
		// >> Removed as we're now just using birthday year
		/*let date = new Date(this.fld_birthdayyear.getValue()+"/"+this.fld_birthdaymonth.getValue()+"/"+this.fld_birthdayday.getValue());

		// Check that the date is appropriate, otherwise reset day to 1
		if (date.getMonth() != parseInt(this.fld_birthdaymonth.getValue()) - 1) {
			setTimeout(_ => { this.fld_birthdayday.setValue(1); }, 750);
			this.fld_birthdayday.invalidDate();
			return false;
		}

		// Otherwise continue
		let time = date.getTime();
		this.sUser.user.dob = time;
		this.userProfiles.find(el => el.primeUser == true).dob = time;
		this.sData.updateUserProfile(this.sUser.user, true, ['dob']).then(_ => {}).catch(err => {console.log("Unable to update profile birthday")});*/
	}

	////////////////////////////////
	setFocusProfileDefaults() {
		if (!this.sUser.user.gender) this.sUser.user.gender = "not_specified";
		//if (!this.sUser.user.is_cald) this.sUser.user.is_cald = "";
		if (!this.sUser.user.is_atsi) this.sUser.user.is_atsi = "";

		if (!this.sUser.user.birth_country) this.sUser.user.birth_country = "";
		if (!this.sUser.user.language) this.sUser.user.language = "";
		if (!this.sUser.user.living_situation) this.sUser.user.living_situation = "";
		if (!this.sUser.user.servicesUsed) this.sUser.user.servicesUsed = {};
		if (!this.sUser.user.services_frequency) this.sUser.user.services_frequency = "";
		if (!this.sUser.user.employment) this.sUser.user.employment = "";
		if (!this.sUser.user.employment_time) this.sUser.user.employment_time = "";
		if (!this.sUser.user.type_of_practice) this.sUser.user.type_of_practice = "";
		if (!this.sUser.user.hospital_discharge) this.sUser.user.hospital_discharge = "";
		if (!this.sUser.user.other_falls_prevention) this.sUser.user.other_falls_prevention = "";
		if (!this.sUser.user.has_buddy) this.sUser.user.has_buddy = "";
		if (!this.sUser.user.falls_frequency) this.sUser.user.falls_frequency = "";
		if (!this.sUser.user.has_chronic_health_issues) this.sUser.user.has_chronic_health_issues = "";
		if (!this.sUser.user.has_neurological_disorder) this.sUser.user.has_neurological_disorder = "";
		if (!this.sUser.user.has_cardiovascular_disease) this.sUser.user.has_cardiovascular_disease = "";
		if (!this.sUser.user.has_dizziniess) this.sUser.user.has_dizziniess = "";
		if (!this.sUser.user.uses_balance_medication) this.sUser.user.uses_balance_medication = "";
		if (!this.sUser.user.has_joint_problems) this.sUser.user.has_joint_problems = "";
		if (!this.sUser.user.can_walk_10_meters) this.sUser.user.can_walk_10_meters = "";
		if (!this.sUser.user.has_walking_aid) this.sUser.user.has_walking_aid = "";
		if (!this.sUser.user.has_vision_problems) this.sUser.user.has_vision_problems = "";
		if (!this.sUser.user.has_hearing_problems) this.sUser.user.has_hearing_problems = "";		
	}

	////////////////////////////////
	selectGuide(index) {
		this.sData.setGuide(index);
		this.updateProfileButDontUpdateProfile();
	}

	////////////////////////////////
	requestNewUser() {
		this.sData.checkAllUserDataSynced()
		.then(res => {
			this.router.navigate(['/onboarding/add']);
		}).catch(err => {
			this.sData.sendAllServerUpdates();
			this.sGlobalPubSub.fireEvent("toast", [true, "Unable to add another user right now because you have unsynced data.  Please wait a moment and try again, or check that you have a stable Internet connection."]);
		});
	}

	////////////////////////////////
	toggleValueUpdated(state) {
		if (!this.sUser.user.servicesUsed) this.sUser.user.servicesUsed = {};
		this.sUser.user.servicesUsed[state.id] = state.state;
		this.updateProfile('servicesUsed');
	}

	////////////////////////////////
	takeProfilePhoto() {
		this.sPhotoHandler.takePhoto()
		.then((res:string) => {
			this.sPhotoHandler.saveTempPhoto(res, "/data/user_"+this.sUser.user.id+"/profilePhoto.jpg")
			.then(res => {
				this.sUser.getProfilePhoto()
				.then(res => {
					this.linkUserProfilePhotos();
				});
			})
			.catch(err => { console.log("Error taking profile photo", err); })
		})
		.catch(err => { console.log("Error saving taken profile photo", err); });
	}

	////////////////////////////////
	alignAllToggles() {
		setTimeout(_ => this.toggles.toArray().forEach(i => i.alignToggle()), 200);
	}
}
