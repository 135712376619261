///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
////////// MODULES
import { Component, OnInit, AfterViewInit, ViewChild, Input } from '@angular/core';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
  selector: 'progress-nub',
  templateUrl: './progress-nub.component.html',
  styleUrls: ['./progress-nub.component.scss']
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class ProgressNubComponent implements OnInit, AfterViewInit {

	@Input() progressPerc:number = 50;
	@Input() colour:string = "ffffff";
	@Input() colour2:string = "ffffff";
	@Input() interactive:string = "false";
	@Input() value:string = "50";

	@ViewChild('bar') bar;
	@ViewChild('nub') nub;
	@ViewChild('val') val;

	dragging:boolean = false;
	dragStart:number = 0;
	nubStart:number = 0;
	intValue:number = 50;

	////////////////////////////////////
	constructor() { }
	ngOnInit() {
		this.bar.nativeElement.style.backgroundColor = "#"+this.colour2;
		this.nub.nativeElement.style.backgroundColor = "#"+this.colour;
		this.val.nativeElement.style.color = "#"+this.colour2;
	}
	ngAfterViewInit() {
		this.intValue = parseInt(this.value);
		this.moveNub();
	}

	////////////////////////////////////
	setValue(intVal) {
		this.intValue = intVal;
		this.moveNub();
	}

	////////////////////////////////////
	moveNub() {
		let perc = this.bar.nativeElement.offsetWidth * (this.intValue/100) - this.nub.nativeElement.offsetWidth / 2;
		this.nub.nativeElement.style.transform = "translateX("+perc+"px)";
		this.val.nativeElement.innerText = this.intValue + "%";
	}

	////////////////////////////////////
	dragMouse($event) {
		if (this.dragging) {
			this.drag(this.nubStart + ($event.pageX - this.dragStart));
		}
	}

	////////////////////////////////////
	dragTouch($event) {
		if (this.dragging) {
			this.drag(this.nubStart + $event.touches[0].screenX - this.dragStart);
		}
	}

	////////////////////////////////////
	drag(x) {
		let perc = Math.round(x / (this.bar.nativeElement.offsetWidth - this.nub.nativeElement.offsetWidth) * 100);
		if (perc < 0) {
			x = 0;
			perc = 0;
		}
		if (perc > 100) {
			x = this.bar.nativeElement.offsetWidth - this.nub.nativeElement.offsetWidth;
			perc = 100;
		}
		this.nub.nativeElement.style.transform = "translateX("+x+"px)";
		this.val.nativeElement.innerText = perc + "%";
		this.intValue = perc;
	}

	////////////////////////////////////
	touchstart($event) {
		if (this.interactive == "false" ) return;
		this.dragging = true;
		this.dragStart = $event.touches[0].screenX;
		this.nubStart = parseInt(this.nub.nativeElement.style.transform.replace("translateX(", "").replace("px)",""));
	}

	////////////////////////////////////
	mouseDown($event) {
		if (this.interactive == "false" ) return;
		this.dragging = true;
		this.dragStart = $event.pageX;
		this.nubStart = parseInt(this.nub.nativeElement.style.transform.replace("translateX(", "").replace("px)",""));
	}

	////////////////////////////////////
	interactEnd($event) {
		this.dragging = false;
		if (window.getSelection) {window.getSelection().removeAllRanges();}
 		else if ((document as any).selection) { (document as any).selection.empty();}
	}

	////////////////////////////////////
	reset() {
		this.intValue = parseInt(this.value);
		let perc = this.bar.nativeElement.offsetWidth * (this.intValue/100) - this.nub.nativeElement.offsetWidth / 2;
		this.nub.nativeElement.style.transform = "translateX("+perc+"px)";
		this.val.nativeElement.innerText = this.intValue + "%";
	}
}
