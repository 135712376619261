///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
////////// IMPORTS
import { Injectable }						from '@angular/core';
import { Http, RequestOptions, Headers }	from '@angular/http';
import { Vasat }							from 'vasat';
import { Observable }						from 'rxjs';
import { FileStream }						from '../objects/filestream';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE CLASS
@Injectable({ providedIn: 'root' })
export class PhotoHandlingService {

	////////////////////////////////
	constructor(private http:Http, private V:Vasat) { }

	////////////////////////////////
	takePhoto() {

		let resolver = function(resolve, reject) {
			let navigator:any = window['navigator'];
			if (!navigator || !navigator.camera) return reject("No camera plugins available");

			let Camera = navigator.camera;

			function pictureError(err) {
				console.log("Error during photo capture", err);
				Camera.cleanup();
				reject(err);
			}

			// Set capture options
			let options = {
				quality:			50,
				targetWidth:		600,
				targetHeight:		600,
				destinationType:	Camera.DestinationType.FILE_URI,
				sourceType:			Camera.PictureSourceType.CAMERA, // || Camera.PictureSourceType.PHOTOLIBRARY || Camera.PictureSourceType.SAVEDPHOTOALBUM
				mediaType:			Camera.MediaType.PICTURE,
				correctOrientation: true
			}

			Camera.getPicture(resolve, pictureError, options);
		}.bind(this);

		return new Promise(resolver);
	}


	////////////////////////////////
	saveTempPhoto(from, to) {

		console.log("Looking to write from / to", from, to);

		let resolver = function(resolve, reject) {
			let cordova = window['cordova'];
			let fileContents:any;
			let fs = new FileStream();

			// Determine the file location, based on the 'from' property
			let fileLoc:string = "applicationStorageDirectory";
			if (from.indexOf(cordova.file.externalApplicationStorageDirectory) != -1) fileLoc = "externalApplicationStorageDirectory";

			fs.setup(fileLoc)
			.getEntry(from.replace(cordova.file[fileLoc], ""))
			.toURL()
			.read('arrayBuffer')
			.returnData("arrayBuffer")
			.go()
			.then(res => {
				console.log("Get res storage area", res);
				fileContents = res.returnData.arrayBuffer;
				let fs2 = new FileStream()
				fs2.setup('dataDirectory')
				.getEntry(to, true, true)
				.writeTo(fileContents)
				.toURL()
				.go()
				.then(res2 => {
					this.checkPhoto(to)
					.then(res => { resolve(res2); })
					.catch(err => { resolve(err); });
				})
				.catch(err => { console.log("Error during write photo:", err); reject(err); });
			})
			.catch(err => { console.log("Error during saveTempPhoto:", err); reject(err); });
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////
	checkPhoto(location) {
		let navigator:any = window['navigator'];
		let Camera = navigator.camera;

		let resolver = function(resolve, reject) {
			let fs = new FileStream()
			fs.setup('dataDirectory')
			.getEntry(location)
			.toURL()
			.returnData('checkedPhoto')
			.go()
			.then(res=> { Camera.cleanup(); resolve(res); })
			.catch(err => { console.log("Error during check photo:", err); reject(err); });
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////
	cleanup() {
		let navigator:any = window['navigator'];
		let Camera = navigator.camera;
		if (Camera) Camera.cleanup();
	}

	////////////////////////////////
	/*uploadFile(fileUrl) {

		// CODE COPIED FROM SHIB, UNTESTED, UNUSED, NOT CUSTOMISED FOR ME
		let cordova = window['cordova'];
		let file = cordova.plugins.file;
		
		var vasatModel =  this.V.localSession().user;// TODO  whatever model the image is being uploaded to (eg user, action, comment)
		var FILENAME = 'image.png'

		let formData: FormData = new FormData();
		// Resolve the File given the file URI
		file.resolveLocalFilesystemUrl(fileUrl).then( (fileEntry) => {
			// Get the parent FileEntry
			fileEntry.getParent(parent => {
				// Read file and return binary. Need to pass the file path (taken from parent) and file name.
				file.readAsArrayBuffer(parent.nativeURL, fileEntry.name).then(buffer => {
					// Generate the binary Blob and append to the FormData
					var blob = new Blob([buffer], { type: "image/png" });
					formData.append('file', blob);
					// Generate Vasat Post request
					let headers = new Headers();
					headers.append('Authorization', 'bearer ' + this.V.authToken());
					let options = new RequestOptions({ headers: headers });
					this.http.post(this.V.cfg.host + vasatModel.urlRoot() + "/" + vasatModel.id + '/' + FILENAME, formData, options)
						.map(res => res.json())
						.catch(error => Observable.throw(error))
						.subscribe(
							data => {
								//this.imageDirective.updateLink(); // probs something Joel doesn't need...
							},
							error => {

								console.log(error)
							}
						)
				}).catch(err => console.log(err) )
			})
		})
	}*/

	////////////////////////////////
	deletePhoto(location) {
		let resolver = function(resolve, reject) {
			let fs = new FileStream()
			fs.setup('dataDirectory')
			.removeByPath(location, false)
			.go()
			.then(res=> { resolve(res); })
			.catch(err => { console.log("Error during delete photo:", err); reject(err); });
		}.bind(this);

		return new Promise(resolver);
	}
}
