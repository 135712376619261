import { Injectable } 			from '@angular/core';
import { CanDeactivate } 		from '@angular/router';
import { NavigationService } 	from '../services/navigation.service';

@Injectable({
	providedIn: 'root'
})
export class CannotDeactivateWithBackButtonGuard implements CanDeactivate<any> {

	constructor(private sNavigation: NavigationService) { }

	canDeactivate(component: any) {

		//If back button was logged as pressed
		if (this.sNavigation.getBackButtonPressed()) {
			//Reset back button and return false
			this.sNavigation.setBackButtonPressed(false);
			history.pushState(null, null, location.href);
			return false;
		}
		
		//No back button was pressed, Return true
		return true;
	}
}
