///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS
import { Component, OnInit } from '@angular/core';
import { ExerciseService } from '../../services/exercise.service';
import { DataService } from '../../services/data.service';
import { DeviceService } from '../../services/device.service';
import { Exercise, ExerciseHelp } from '../../st-commons/models';
import { FileStream } from '../../objects/filestream';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
	selector: 'app-page-debugtutorialassets',
	templateUrl: './page-debugtutorialassets.component.html',
	styleUrls: ['./page-debugtutorialassets.component.scss'],
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class PageDebugTutorialAssetsComponent implements OnInit {
	// Responses
	results: String = '';
	error: String = '';
	tested_exerciseIDs: number[] = [];
	untested_exerciseIDs: number[] = [];
	files_missing: String[] = [];
	files_missing_response: String = '';

	// Data
	exerciseHelps: ExerciseHelp[] = [];
	exercises: Exercise[] = [];

	// Working
	exerciseIDsFound: number[] = [];

	////////////////////////////////
	constructor(private sExercise: ExerciseService, private sData: DataService, private sDevice: DeviceService) {}

	////////////////////////////////
	ngOnInit() {}

	////////////////////////////////
	testAssets() {
		if (this.sDevice.isBrowser) {
			return (this.results = 'Test not designed to work in browser');
		}

		this.error = '';
		this.results = 'Testing';
		this.tested_exerciseIDs = [];
		this.untested_exerciseIDs = [];
		this.files_missing = [];

		this.sData
			.getTypedDataFromStorage(ExerciseHelp)
			.then((results) => {
				this.exerciseHelps = results.data.ExerciseHelp;

				this.sData
					.getTypedDataFromStorage(Exercise)
					.then((results) => {
						this.exercises = results.data.Exercise;

						this.continueTest();
					})
					.catch((_) => (this.error = 'Error getting exercises'));
			})
			.catch((_) => (this.error = 'Error getting helps'));
	}

	////////////////////////////////
	continueTest() {
		let promises = [];

		this.exerciseHelps.forEach((help) => {
			let image1 = (help['image_1'] == 'uploaded' && 'image_1.png') || null;
			let image2 = (help['image_2'] == 'uploaded' && 'image_2.png') || null;
			let image3 = (help['image_3'] == 'uploaded' && 'image_3.png') || null;
			let video = (help['tutorial_video'] == 'uploaded' && 'tutorial_video.mp4') || null;

			if (image1) promises.push(this.fileChecker.bind(this, help, image1)()); // Upgrade: Also check if files downloaded but "uploaded" not set?
			if (image2) promises.push(this.fileChecker.bind(this, help, image2)()); // Upgrade: Also check if files downloaded but "uploaded" not set?
			if (image3) promises.push(this.fileChecker.bind(this, help, image3)()); // Upgrade: Also check if files downloaded but "uploaded" not set?
			if (video) promises.push(this.fileChecker.bind(this, help, video)()); // Upgrade: Also check if files downloaded but "uploaded" not set?
		});

		Promise.all(promises).then(this.completeSuccess.bind(this)).catch(this.completeError.bind(this));
	}

	////////////////////////////////
	fileChecker(help, filename) {
		let resolver = function (resolve, reject) {
			let fs = new FileStream();
			fs.setup()
				.getEntry('/appData/exerciseData/' + help.id + '/' + filename, false)
				.toURL()
				.go()
				.then(() => {
					this.tested_exerciseIDs.push(help.exercise.id);
					resolve();
				})
				.catch((error) => {
					this.files_missing.push({ help_id: help.id, file: filename });
					reject(error);
				});
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////
	completeSuccess() {
		this.exercises.forEach((exercise) => {
			if (!this.tested_exerciseIDs.includes(exercise.id)) this.untested_exerciseIDs.push(exercise.id);
		});

		this.results = 'Checked all files - No expected files missing';
	}

	////////////////////////////////
	completeError(error) {
		this.results = ''; // Clear results screen

		let errorResponse;
		if (error.message && error.details) errorResponse = `Error: ${error.message} - ${error.details}`;
		else error = errorResponse;
		this.files_missing_response = this.files_missing.map((i) => `Help ID: ${i['help_id']}, File: ${i['file']} `).join('<br> ');
		this.error = errorResponse;
	}
}
