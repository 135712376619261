///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
////////// ANGULAR CORE
import { Component, OnInit, Input, Output, EventEmitter }	from '@angular/core';
import { Router }											from '@angular/router';
import { GlobalPubSub }										from '../../services/global-pub-sub.service';


///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// SETUP COMPONENT
@Component({
  selector: 'securitychecklist',
  templateUrl: './subpage-securitychecklist.component.html',
  styleUrls: ['./subpage-securitychecklist.component.scss']
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class SubpageSecuritychecklistComponent implements OnInit {

	////////////////////////////////
	@Output() notify: EventEmitter<any> = new EventEmitter<any>();
	@Input() bluewaveFormState:string = "off";

	////////////////////////////////
	disabled:string = "disabled";
	loadingState:string;
	accepted = {
		trip: false,
		call: false,
		vid: false
	}

	////////////////////////////////
	constructor(private router: Router, private sGlobalPubSub:GlobalPubSub) {}
	ngOnInit() {
		this.loadingState = "";
	}

	////////////////////////////////
	toggleValueUpdated(event) {
		this.accepted[event.id] = (event.state == true) ? true : false;
		if (this.accepted.trip == true && this.accepted.call == true && this.accepted.vid == true) {
			this.disabled = "";
		} else {
			this.disabled = "disabled";
		}
	}

	////////////////////////////////
	closeForm() {
		this.notify.emit( { type:'cancel' } );
	}

	////////////////////////////////
	continue() {
		if (this.disabled != "disabled") {
			this.loadingState = "loading";
			this.bluewaveFormState = "off";
			setTimeout( () => {
				this.notify.emit( { type:'requestContinue' } );
			}, 550);
		} else {
			this.sGlobalPubSub.fireEvent("toast", [true, "Please confirm that you've checked all safety requirements before continuing.", 2500]);
		}
	}

}
