///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
////////// ANGULAR CORE
import { Component, OnInit }	from '@angular/core';
import { ExerciseService }		from '../../services/exercise.service';
import { Router }				from '@angular/router';

////////////////////////////////
////////// ANIMATION
const query = (s,a,o={optional:true})=>q(s,a,o);
import 'hammerjs';
import { trigger, stagger, animate, style, group, query as q, transition, keyframes} from '@angular/animations';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT ANIMATIONS
export const digestTransition = trigger('digestTransition',
[
	// TRANSITION: Enter
	transition(':enter', [
		query('.logo', style({ opacity: 0, transform:'translateY(25%)' })),
		query('.logo', stagger(100, [
			style({ transform: 'translateY(25%)' }),
			animate('0.65s ease-out', style({transform: 'translateY(0%)', opacity: 1})),
		])),
		query('guide-figure, .count', style({ opacity: 0, transform:'translateY(-38%)' })),
		query('guide-figure, .count', stagger(250, [
			style({ transform: 'translateY(-38%)' }),
			animate('0.65s ease-out', style({transform: 'translateY(-50%)', opacity: 1})),
		])),
	]), transition(':leave', [])
	// TRANSITION: Leave (no properties, but kept in so that the leave routerAnimation fires)
]);

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// SETUP COMPONENT
@Component({
  selector:		'app-page-exercise1-digest',
  animations:	[ digestTransition ],
  host:			{ '[@digestTransition]': ''},
  templateUrl:	'./page-exercise1-digest.component.html',
  styleUrls:	['./page-exercise1-digest.component.scss']
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class PageExercise1DigestComponent implements OnInit {

	continueTO:any;

	////////////////////////////////
	constructor(private sExercise:ExerciseService, private router:Router) {}
	ngOnInit() {
		if (!this.sExercise.hasExistingSession()) this.router.navigate(['exercises/home']);
		else {
			this.continueTO = setTimeout(() => { this.router.navigate(['exercises/howto']); }, 7500);
		}
	}

	////////////////////////////////
	getCurrentSessionIndex() {
		var numIncomplete = this.sExercise.exercise_session.exercise_actions.filter(act => { return act.complete == false; }).length;
		var total = this.sExercise.exercise_session.exercise_actions.length;
		return total - numIncomplete + 1;
	}

	////////////////////////////////
	getTotalSessionExercises() {
		return this.sExercise.exercise_session.exercise_actions.length;
	}

	////////////////////////////////
	skip() {
		clearTimeout(this.continueTO);
		this.router.navigate(['exercises/howto']);
	}

}
