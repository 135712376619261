import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-saving-indicator',
	templateUrl: './saving-indicator.component.html',
	styleUrls: ['./saving-indicator.component.scss']
})
export class SavingIndicatorComponent implements OnInit {

	////////////////////////////////////
	activity:string = "idle";
	activity_message:string = "Saving Data";

	////////////////////////////////////
	@Input('quitFunction') quitFunction:Function;
	@Input('mainState') mainState:string;

	////////////////////////////////////
	constructor() {}
	ngOnInit() {}

	////////////////////////////////////
	getActivityMessage() {
		var message = "";
		switch (this.mainState) {
			case "idle":	message = ""; break;
			case "saving":	message = "Saving Data"; break;
			case "error":	message = "Session Save Failed"; break;
			default:		message = "";
		}

		return message;
	}

	////////////////////////////////////
	runQuitFunction() {
		this.quitFunction();
	}
}
