//////////////////////////////// IMPORTS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////
////////// IMPORTS
import { Component, ViewChild,
		 Output, EventEmitter }					from '@angular/core';
import { DataService }							from '../../services/data.service';
import { Router }			        			from '@angular/router';
import { FormBluewaveComponent } 				from '../../components/form-bluewave/form-bluewave.component';
import { Location }								from '@angular/common';
import { Vasat }								from 'vasat';
import { UserService }							from '../../services/user.service';
import { GlobalPubSub }							from '../../services/global-pub-sub.service';
import { NotificationsService } 				from '../../services/notifications.service';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// COMPONENT
@Component({
	selector:		'app-page-login',
	templateUrl:	'./page-login.component.html',
	styleUrls:		['./page-login.component.scss']
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class PageLoginComponent {

	////////////////////////////////////
	@ViewChild("btlogin") btlogin;
	@ViewChild("btforgot") btforgot;
	@ViewChild("password") password;
	@ViewChild("emaillogin") emaillogin;
	@ViewChild("emailforgot") emailforgot;
	@ViewChild("bluewaveform") bluewaveform: FormBluewaveComponent;
	@Output() notify: EventEmitter<any> = new EventEmitter<any>();

	////////////////////////////////
	whichState:string = "";
	loginError:string = "";
	fpError:string = "";

	////////////////////////////////////
	constructor(private sData:DataService, private router:Router, private loc:Location, private V:Vasat, private sUser:UserService, private sGlobalPubSub:GlobalPubSub, private sNotifications:NotificationsService) {}
	ngOnInit() {
		setTimeout(() => {
			this.bluewaveform.setFormContentState("login");
			this.bluewaveform.open();
		}, 1000);

		if (this.router.url == "/login/add") {
			this.whichState = "fromProfile";
		} else {
			this.whichState = "notFromProfile";
		}
	}

	////////////////////////////////////
	toLogin() {
		this.bluewaveform.setFormContentState("login");
	}

	////////////////////////////////////
	toForgotPassword() {
		this.bluewaveform.setFormContentState("forgot-password");
	}

	////////////////////////////////
	back():void {
		if (this.whichState == "fromProfile") {
			this.router.navigate(["/onboarding/add"]);
		} else {
			this.router.navigate(["/onboarding"]);
		}
	}

	////////////////////////////////////
	login():void {
		this.loginError = "";
		let email = this.emaillogin.nativeElement.value;
		let password = this.password.nativeElement.value;
		let essData = this.sData;

		this.btlogin.nativeElement.classList.add('loading');
		this.sData.login(email, password)
		.then(success.bind(this))
		.catch(error.bind(this));
		
		////////////////////////////////////
		function success(res) {
			setTimeout(() => {
				// Get ExerciseAction data for this user and save
				this.sData.gatherThisWeeksVasatExerciseAction();
				this.btlogin.nativeElement.classList.remove('loading');

				//Find date a week from now
				let weekDate = new Date();
				weekDate.setDate(weekDate.getDate() + 7);
				weekDate.setHours(12, 0, 0, 0);

				let titleCopyString = "Thanks for signing up to StandingTall";
				let bodyCopyString = "If you haven't already done so, please complete your profile details in the settings area.";

				//Set reminder to complete profile information
				this.sNotifications.setSingleNotification(weekDate.valueOf(), titleCopyString, bodyCopyString, weekDate);

				// Then route back home or to profiles, based on where you are
				if (this.whichState == "fromProfile") {
					this.router.navigate(['/settings/profile']);
				} else {
					this.router.navigate(['/home']);
				}
			}, 500);
		}

		////////////////////////////////////
		function error(err) {
			this.sData.reinstatePreviousVasatToken();
			setTimeout(() => {
				this.btlogin.nativeElement.classList.remove('loading');
			}, 750);
			if (err.status == 0) {
				this.loginError = "You don't have an Internet connection currently available.  Please check your connection and try again.";
			}
			else if (err.message && err.message == "duplicatelogin") {
				this.loginError = "This user has already logged in";
				this.notify.emit( { message:"loginduplicate", loginID:err.res } );
			} else {
				setTimeout(() => {
					this.loginError = err;
				}, 750);
				
				// Remove the user as the login failed for some reason
				essData.getUserProfiles()
				.then(res => {
					let user = res.data.userProfiles.filter( prof => { return prof.username == email });
					if (user.length) {
						let id = user[0].id;
						essData.logoutProfile(id);
					}
				});
				
			}
		}
	}

	////////////////////////////////////
	toggleValueUpdated(state) {
		if (state.id == "passwordvisibility") {
			if (state.state == true) this.password.nativeElement.setAttribute('type', 'text');
			else  this.password.nativeElement.setAttribute('type', 'password');
		}
	}

	////////////////////////////////////
	initiateForgotPassword() {
		if (this.btforgot.nativeElement.classList.contains("loading")) return;

		this.fpError = "";
		this.btforgot.nativeElement.classList.add('loading');
		let email = this.emailforgot.nativeElement.value;
		let start = new Date().getTime();
		let wait = 0;

		this.V.forgetPasswordObservable(email).toPromise()
		.then(res => {
			if (new Date().getTime() - start < 1000) wait = 1000;
			setTimeout(_ => {
				this.btforgot.nativeElement.classList.remove('loading');
				this.sGlobalPubSub.fireEvent("toast", [true, "Thanks!  An email has been sent to this address with instructions on how to change your password.", 6000]);
			}, wait);
		})
		.catch(err => {
			if (new Date().getTime() - start < 1000) wait = 1000;
			setTimeout(_ => {
				this.btforgot.nativeElement.classList.remove('loading');
				this.fpError = err;
			}, wait);
		});
	}
}
