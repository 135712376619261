///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DataPushLog, DataPullLog } from '../objects/localModels';
import { DataService } from './data.service';
import { Vasat } from 'vasat';
import { UserService } from './user.service';
import { GlobalPubSub } from './global-pub-sub.service';
import { FileDownloadService } from './filedownload.service';
import { DeviceService } from './device.service';
import { FileStream } from '../objects/filestream';
import { environment } from '../../environments/environment';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
@Injectable({ providedIn: 'root' })
export class ContentManagerService {
	////////////////////////////////
	dataPushLog: DataPushLog;
	dataPullLog: DataPullLog;

	////////////////////////////////
	maxRetries: number = 3;
	curJSONRetries: number = 0;
	curFileRestries: number = 0;
	fileManifest: any[] = [];
	lastKnownError: string = '';
	multipleResourceWaitTime: number = 2500;
	manifestTimeoutTime: number = 17500;

	////////////////////////////////
	constructor(
		private sDevice: DeviceService,
		private sData: DataService,
		private V: Vasat,
		private sUser: UserService,
		private sGlobalPubSub: GlobalPubSub,
		private sFileDownload: FileDownloadService,
		private router: Router
	) {}

	////////////////////////////////////
	startContentUpdate() {
		//this.V.api('https://standingtall.neura.edu.au/vasat/api/ExerciseHelp.files?limit=1500&updated=0').subscribe(res => console.log("1", res));
		//this.V.api('https://standingtall.neura.edu.au/vasat/api/ExerciseHelp.files?limit=1500&updated=1000').subscribe(res => console.log("2", res));
		//this.V.api('https://standingtall.neura.edu.au/vasat/api/ExerciseHelp.files?limit=1500&updated=%3E0').subscribe(res => console.log("3", res));
		//this.V.api('/api/ExerciseHelp.files?limit=1500&updated=%3E1000').subscribe(res => console.log("3", res));

		let vasatUserExists;
		let resolver = function (resolve, reject) {
			// First test if we have a user or not
			if (this.V.hasSession()) vasatUserExists = true;

			// If no user, jump in as an unknown user and get exercise stuff
			if (!vasatUserExists) {
				this.V.loginWithClientCredentialsObservable()
					.toPromise()
					.then(this.pingDataPull.bind(this))
					.then(this.sData.waitAMoment.bind(this, this.multipleResourceWaitTime))
					.then(this.downloadAllFiles.bind(this))
					.then(resolve)
					.catch(reject);
			} else {
				this.pingDataPull()
					.then(this.sData.waitAMoment.bind(this, this.multipleResourceWaitTime))
					.then(this.downloadAllFiles.bind(this))
					.then((res) => {
						resolve(res);
						this.updateUserCategories();
					})
					.catch(reject);
			}
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////
	getFileManifest(omitUpdatedParam: boolean = false) {
		let resolver = function (resolve, reject) {
			let xhr;

			// Throw a timeout - if the user has already downloaded content, then perhaps we let them off and scoot through.  However if first run, we need them to download content
			let timeoutifier = setTimeout(() => {
				if (xhr) xhr.unsubscribe();

				if (!this.fileManifest.length) this.handleContentGetError({ message: 'Could not get content manifest.' }, resolve, reject);
				else this.handleContentGetError({ message: 'Unable to download content manifest.' }, resolve, reject);
			}, this.manifestTimeoutTime);

			// Start a connection to get the files we might need
			if (this.fileManifest.length) {
				resolve(this.fileManifest);
			} else {
				// Prepare the endpoint
				let url = '/api/ExerciseHelp.files?limit=1500';
				let updated = (!omitUpdatedParam && '&updated=>' + this.dataPullLog.serverDataUpdates.ExerciseFiles.lastUpdate) || '';

				// Create an XHR request
				xhr = this.V.api(url + updated).subscribe(
					(manifest) => {
						this.fileManifest = manifest;
						clearTimeout(timeoutifier);
						resolve(manifest);
					},
					(err) => {
						if (environment.debug) console.log('Error getting files manifest', err);
						reject(err);
					}
				);
			}
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////
	downloadAllFiles() {
		let resolver = function (resolve, reject) {
			if (window['cordova_custom'].device() != 'browser') {
				this.sData.getRawDataFromStorage('datapulllog').then((res: any) => {
					this.dataPullLog.markDataAsServerUpdated('ExerciseFiles', res.ExerciseFiles.lastUpdate);

					// Don't download on first app open (instead copy existing files), otherwise Apple will reject it (signified by lastUpdate being 0)
					let isIOS = this.sDevice.isiOS;
					let files = (res.ExerciseFiles && true) || false;
					let noLastUpdated = (files && parseInt(res.ExerciseFiles.lastUpdate) == 0) || false;

					if (environment.debug) console.log('Files: Last Updated is:', parseInt(res.ExerciseFiles.lastUpdate));

					// If we don't have files, or if we do but not concept of the lastUpdated, let's just copy files across
					if ((isIOS && files && noLastUpdated) || (isIOS && !files)) {
						if (environment.debug) console.log('Copying embedded assets and setting lastUpdated on iOS.');

						// We insert a preloadedFilesDate into the package.json, and we update that date when we provide a build with new files
						let updateTime = require('../../../package.json').preloadedFilesDate;

						let entryFrom;
						let entryTo;
						let fs = new FileStream();
						fs.setup('applicationDirectory')
							.getEntry('www/assets/exerciseData')
							//.debugData()
							.go()
							.then((res) => {
								entryFrom = res.returnData.exerciseData;
								let fs2 = new FileStream();
								fs2.setup('dataDirectory')
									.getEntry('appData')
									.go()
									.then((res2) => {
										entryTo = res2.returnData.appData;
										let fs3 = new FileStream();
										fs3.setup('dataDirectory')
											.copyByEntries(entryFrom.entry, entryTo.entry)
											.getEntry('/appData/exerciseData')
											.getAllEntries()
											.go()
											.then((res) => {
												this.dataPullLog.markDataAsServerUpdated('ExerciseFiles', updateTime);
												resolve();
											})
											.catch((err) => {
												if (err.code == 12) {
													this.dataPullLog.markDataAsServerUpdated('ExerciseFiles', updateTime);
													resolve(); // Path exists error, so we good
												} else reject(err);
											});
									});
							});
					} else {
						window['plugins'].insomnia.keepAwake();

						////////////////////////////////
						this.getFileManifest()
							.then((man) => this.getFilesInManifest.bind(this)(man))

							////////////////////////////////
							.then((res) => {
								//this.sFileDownload.oReq = null;
								this.fileManifest = [];
								this.dataPullLog.markDataAsServerUpdated('ExerciseFiles');
								window['plugins'].insomnia.allowSleepAgain();
								resolve();
							})

							////////////////////////////////
							.catch((err) => {
								window['plugins'].insomnia.allowSleepAgain();
								this.handleContentGetError(err, resolve, reject);
							});
					}
				});

				// Is Browser
			} else {
				// We need to get and store the manifest as we will use this to get help urls
				this.getFileManifest(true)
					.then((man) => {
						this.sDevice.manifest = man;
						this.dataPullLog.markDataAsServerUpdated('ExerciseFiles');
						resolve();
					})
					.catch((err) => reject(err));
			}
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////
	handleContentGetError(err, resolve, reject) {
		this.sData.getRawDataFromStorage('datapulllog').then((res: any) => {
			if (res.ExerciseFiles && res.ExerciseFiles.lastUpdate && res.ExerciseFiles.lastUpdate != 0)
				resolve([]); // We can let the user through as they already have a full download of the content (even if outdated)
			else {
				err = (err && err.message && err) || { message: 'Critical files and content was not downloaded.' };
				this.lastKnownError = err.message;

				if (!this.lastKnownError && (window['navigator'] as any).connection.type == 'none') this.lastKnownError = 'No Internet Connection';

				reject(err);
			}
		});
	}

	////////////////////////////////
	pingDataPush() {
		if (!this.dataPushLog) {
			let dataPushLog = new DataPushLog({ sData: this.sData, V: this.V });
			this.dataPushLog = dataPushLog;
			return this.dataPushLog.checkForDataPushes();
		} else {
			return this.dataPushLog.checkForDataPushes();
		}
	}

	////////////////////////////////
	pingDataSyncLog(): Promise<any> {
		let resolver = function (resolve, reject) {
			let pull = function () {
				this.pingDataPull().then(resolve).catch(reject);
			}.bind(this);
			let push = function () {
				this.pingDataPush()
					.then(pull)
					.catch((err) => {
						pull();
					});
			}.bind(this);

			push(); // Push first to make sure local changes are updated as priority
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////
	pingDataPull() {
		console.log('Ping data pull');
		let resolver = function (resolve, reject) {
			if (!this.dataPullLog) {
				this.sData
					.getRawDataFromStorage('datapulllog')
					.then((res: any) => {
						res.sData = this.sData;
						res.V = this.V;
						res.sGlobalPubSub = this.sGlobalPubSub;
						res.sUser = this.sUser;
						let dataPullLog = new DataPullLog(res);
						this.dataPullLog = dataPullLog;
						this.dataPullLog
							.checkForUpdates()
							.then((res) => {
								resolve(res);
							})
							.catch((err) => {
								reject(err);
							}); //It catches, pretty sure in the checkForUpdates()
					})
					.catch((err) => {
						if (environment.debug) console.log('Error during data pull', err);
						reject(err);
					});
			} else {
				this.dataPullLog.checkForUpdates().then(resolve).catch(reject);
			}
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////////
	updateUserCategories() {
		if (!this.sUser.user.levelProgression) return;

		let catTemplate = {
			category: { id: 0, type: 'ExerciseCategory' },
			disabled: false,
			checkpoint: 1,
			states: [],
		};

		this.sData.getRawDataFromStorage('exercisecategories').then((cats: any) => {
			cats.forEach((cat) => {
				let catExists = this.sUser.user.levelProgression.find((lp) => lp.category.id == cat.id);

				// They don't have this category yet
				if (!catExists) {
					catTemplate.category.id = cat.id;
					this.sUser.user.levelProgression.push(catTemplate);
					this.sUser.user.updateRelations({ forceLevelProgressionUpdate: true });
					this.sData.updateUserProfile(this.sUser.user, true, ['levelProgression']);
					if (environment.debug) console.log('%c  >>> Updating user with new category.', 'color:lightblue;');
				}
			});
		});
	}

	////////////////////////////////////
	getFilesInManifest(manifest) {
		let resolver = async function (resolve, reject) {
			let failure = false;
			for (let x = 0; x < manifest.length; x++) {
				if (failure) break;
				let getURL = this.V.cfg.host + '/api/ExerciseHelp/' + manifest[x].parentId + '/' + manifest[x].name + '?access_token=' + this.V.token;
				let fileName: string;
				switch (manifest[x].mimeType) {
					case 'image/png':
					case 'image/jpg':
					case 'image/jpeg':
					case 'image/gif':
						fileName = manifest[x].name;
						break;
					case 'video/mp4':
					default:
						fileName = manifest[x].name.replace('bin', 'mp4');
						break;
				}
				let saveURL = '/appData/exerciseData/' + manifest[x].parentId + '/' + fileName;
				//saveURL = "/appData/exerciseData/"+manifest[x].parentId+"/video.mp4";

				//await this.sFileDownload.downloadAFile.apply(this, [getURL, saveURL, manifest[x], x+1, manifest.length])
				await this.sFileDownload
					.downloadAFile(getURL, saveURL, manifest[x], x + 1, manifest.length)
					.then((_) => {
						if (environment.debug) console.log('COMPLETED FILE SUCCESSFULLY:', manifest[x]);
						this.removeItemFromManifest(manifest[x]);
					})
					.catch((err) => {
						if (environment.debug) console.log('ERRORED FILE', err);
						failure = true;
						reject(err);
					});
				await sleep(500);
			}

			// We good, got all files
			resolve();
		}.bind(this);

		return new Promise(resolver);

		////////////////////////////////////
		function sleep(ms) {
			let resolver = function (resolve, reject) {
				setTimeout(() => {
					resolve();
				}, ms);
			}.bind(this);
			return new Promise(resolver);
		}
	}

	////////////////////////////////////
	removeItemFromManifest(manItem) {
		this.fileManifest = this.fileManifest.filter((i) => {
			return i.name != manItem.name || i.parentId != manItem.parentId || i.size != manItem.size;
		});
	}
}
