///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS
import { Vasat, VasatSession, User, VasatError } from 'vasat';
import { STUser, ExerciseCategory, Exercise, ExerciseAction, ExerciseLevel, ExerciseHelp, FAQ } from '../st-commons/models';
import { LocalGoal, LocalJournal, LocalCalendar, LocalUser } from '../objects/localModels';
import { StorageService } from '../services/storage.service';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class DataStream {
	////////////////////////////////////
	//sData:DataService;
	sStorage: StorageService;

	////////////////////////////////////
	todo: any[] = [];
	log: any[] = [];
	dataReturn: any = {};
	dataType: any;
	data: any[] = [];
	attempt: number = 1;
	attempts: number = 1;
	currentDevice: any = {};
	//sNativeStorage:any;

	////////////////////////////////////
	public typeKeys = {
		Exercise: { storageKey: 'exercises', updatesKey: 'Exercise', dataType: Exercise },
		ExerciseCategory: { storageKey: 'exercisecategories', updatesKey: 'ExerciseCategory', dataType: ExerciseCategory },
		ExerciseAction: { storageKey: 'exerciseactions', updatesKey: 'ExerciseAction', dataType: ExerciseAction },
		ExerciseLevel: { storageKey: 'exerciselevels', updatesKey: 'ExerciseLevel', dataType: ExerciseLevel },
		ExerciseHelp: { storageKey: 'exercisehelp', updatesKey: 'ExerciseHelp', dataType: ExerciseHelp },
		FAQ: { storageKey: 'faqs', updatesKey: 'FAQ', dataType: FAQ },
		STUser: { storageKey: 'userProfiles', updatesKey: 'STUser', dataType: STUser },
		LocalUser: { storageKey: 'userTokens', updatesKey: 'LocalUser', dataType: LocalUser },
		LocalGoal: { storageKey: 'goals', updatesKey: 'Goal', dataType: LocalGoal },
		LocalJournal: { storageKey: 'journalItems', updatesKey: 'Journal', dataType: LocalJournal },
		LocalCalendar: { storageKey: 'calendarItems', updatesKey: 'Calendar', dataType: LocalCalendar },
	};

	////////////////////////////////////
	constructor(public V: Vasat, private sUser, sStorage) {
		this.sStorage = sStorage;
	}

	///////////////////////////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////// PREPARATION FUNCTIONS

	////////////////////////////////////
	public setDataType(type): this {
		this.addTodo({ func: this.setDataTypeGo, args: [type] });
		return this;
	}

	////////////////////////////////////
	public getData(): this {
		this.addTodo({ func: this.getDataGo, args: [] });
		return this;
	}

	////////////////////////////////////
	public getVasatData(params): this {
		this.addTodo({ func: this.getVasatDataGo, args: [params] });
		return this;
	}

	////////////////////////////////////
	public useProvidedData(data): this {
		this.addTodo({ func: this.useProvidedDataGo, args: [data] });
		return this;
	}

	////////////////////////////////////
	public addPropertyToData(which, data): this {
		this.addTodo({ func: this.addPropertyToDataGo, args: [which, data] });
		return this;
	}

	////////////////////////////////////
	public convertDataToType(type): this {
		this.addTodo({ func: this.convertDataToTypeGo, args: [type] });
		return this;
	}

	////////////////////////////////////
	public deleteExistingData(): this {
		this.addTodo({ func: this.deleteExistingDataGo, args: [] });
		return this;
	}

	////////////////////////////////////
	public saveDataByAppend(incrementID = false, IDKey = '', specificStorageKey = null): this {
		this.addTodo({ func: this.saveDataByAppendGo, args: [incrementID, IDKey, specificStorageKey] });
		return this;
	}

	////////////////////////////////////
	public saveDataByOverwrite(): this {
		this.addTodo({ func: this.saveDataByOverwriteGo, args: [] });
		return this;
	}

	////////////////////////////////////
	public saveDataBySmartAdd(compareKey = 'id'): this {
		this.addTodo({ func: this.saveDataBySmartAddGo, args: [compareKey] });
		return this;
	}

	////////////////////////////////////
	public overwriteLocalRecords(compareKey, specificStorageKey = null): this {
		this.addTodo({ func: this.overwriteLocalRecordsGo, args: [compareKey, specificStorageKey] });
		return this;
	}

	////////////////////////////////////
	public getNextLocalID(): this {
		this.addTodo({ func: this.getNextLocalIDGo, args: [] });
		return this;
	}

	////////////////////////////////////
	public returnData(propertyName): this {
		this.addTodo({ func: this.returnDataGo, args: [propertyName] });
		return this;
	}

	////////////////////////////////////
	public exposeData(func: Function): this {
		this.addTodo({ func: this.exposeDataGo, args: [func] });
		return this;
	}

	////////////////////////////////////
	public flagAsServerUpdateable(id, key, type, owner, properties: any[] = []) {
		this.addTodo({ func: this.flagAsServerUpdateableGo, args: [id, key, type, owner, properties] });
		return this;
	}

	////////////////////////////////////
	public unflagAsServerUpdateable(id, key, type) {
		this.addTodo({ func: this.unflagAsServerUpdateableGo, args: [id, key, type] });
		return this;
	}

	////////////////////////////////////
	public updateServerData(allUsersData = false): this {
		this.addTodo({ func: this.updateServerDataGo, args: [allUsersData] });
		return this;
	}

	////////////////////////////////////
	public deleteTemporaryStreamData(): this {
		this.addTodo({ func: this.deleteTemporaryStreamDataGo, args: [] });
		return this;
	}

	////////////////////////////////////
	public retries(retryNumber): this {
		this.attempts += retryNumber;
		return this;
	}

	////////////////////////////////////
	public filter(func) {
		this.addTodo({ func: this.filterGo, args: [func] });
		return this;
	}

	////////////////////////////////////
	public sort(func) {
		this.addTodo({ func: this.sortGo, args: [func] });
		return this;
	}

	////////////////////////////////////
	public map(func) {
		this.addTodo({ func: this.mapGo, args: [func] });
		return this;
	}

	////////////////////////////////////
	public go() {
		return this.goGo();
	}

	///////////////////////////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////// ACTIVITY FUNCTIONS
	////////////////////////////////////
	private async setDataTypeGo(type): Promise<any> {
		this.dataType = type;
		this.log.push('setDataTypeGo: Success');
		return Promise.resolve();
	}

	////////////////////////////////////
	private async useProvidedDataGo(data): Promise<any> {
		if (data === null || data === undefined || (Array.isArray(data) && data.length == 0)) return Promise.reject('No data provided');
		if (data instanceof Array) this.data = data;
		else this.data.push(data);
		return Promise.resolve();
	}

	////////////////////////////////////
	private async addPropertyToDataGo(which, data): Promise<any> {
		let frst = 0;
		let last = this.data.length - 1;
		switch (which) {
			case 'first':
				if (this.data[frst] === Object(this.data[frst]) && data === Object(data)) Object.assign(this.data[frst], data);
				break;
			case 'last':
				if (this.data[last] === Object(this.data[last]) && data === Object(data)) Object.assign(this.data[last], data);
				break;
			case 'all':
				this.data.forEach((it) => {
					if (it === Object(it)) Object.assign(it, data);
				});
				break;
			default:
				return Promise.reject("Add Property To Data: 'which' method not supported");
		}
		return Promise.resolve();
	}

	////////////////////////////////////
	private async getDataGo() {
		let resolver = function (resolve, reject) {
			// Prepare
			let keys: any = this.getKeysByDataType(this.dataType);
			if (!keys) return reject("Can't find appropriate keys for data type: " + this.dataType.name);

			this.sStorage
				.get(keys.storageKey)
				.then((res) => {
					this.data = res;
					resolve(res);
				})
				.catch(reject);
		}.bind(this);
		return new Promise(resolver);
	}

	////////////////////////////////////
	private async getVasatDataGo(params): Promise<any> {
		let resolver = function (resolve, reject) {
			var getter = this.V.searchByObject(this.dataType);
			if (params) {
				if (params.greaterThan) {
					getter.greaterThan(params.greaterThan.key, params.greaterThan.value.value);
				}
			}

			getter
				.useCache(false)
				.limit(params.limit || 9999)
				.queryObservable()
				.subscribe(
					(res) => {
						this.log.push('getVasatDataGo: Success');
						this.data = res.items;
						resolve();
					},
					(err) => {
						this.log.push('getVasatDataGo: Failure');

						// If this was a network issue, reject
						if (err.status == 0 || (err.error && (err.error.status == 0 || err.status == 100001))) {
							reject('No connection');
						} else {
							reject(err);
						}
					}
				);
		}.bind(this);
		return new Promise(resolver);
	}

	////////////////////////////////////
	private async convertDataToTypeGo(type): Promise<any> {
		this.dataType = type;
		let promises: Promise<any>[] = [];
		this.data.forEach((item, index) => {
			promises.push(this.convertDataToTypeVasatObject(item, this.dataType, index));
		});
		return Promise.all(promises);
	}

	////////////////////////////////////
	private async deleteExistingDataGo(): Promise<any> {
		let resolver = function (resolve, reject) {
			// Prepare based on type
			let keys = this.getKeysForDataType(this.dataType);

			this.sStorage.set(keys.storageKey, '').then(resolve).catch(reject);
		};
		return new Promise(resolver);
	}

	////////////////////////////////////
	private async saveDataByAppendGo(incrementID, IDKey, specificStorageKey): Promise<any> {
		let resolver = function (resolve, reject) {
			// Note that a deficiency of this is that all data will be saved to the type of the first item
			let keys;
			if (typeof specificStorageKey == 'string' && specificStorageKey.length) {
				keys = { storageKey: specificStorageKey };
			} else {
				keys = this.getKeysForItem(this.data[0]);
				if (!keys) return reject('Data has type with no keys, for item: ' + JSON.stringify(this.data[0]));
			}

			this.sStorage.get(keys.storageKey).then((res) => {
				let data;
				try {
					data = JSON.parse(res);
				} catch (e) {
					data = res;
				}

				if (incrementID) {
					let nextID = null;
					if (data.length) {
						data = data.sort((a, b) => {
							if (a[IDKey] < b[IDKey]) return -1;
							return 1;
						});
						nextID = parseInt(data[data.length - 1][IDKey]) + 1;
					} else {
						nextID = 1;
					}
					this.data.forEach((data, index) => {
						data[IDKey] = nextID + index;
					});
				}
				data = data.concat(this.data);
				this.sStorage
					.set(keys.storageKey, data)
					.then((res) => {
						resolve(res);
					})
					.catch((err) => {
						reject(err);
					});
			});
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////////
	private async saveDataByOverwriteGo(): Promise<any> {
		let resolver = function (resolve, reject) {
			let keys = this.getKeysByDataType(this.dataType);
			if (!keys) return reject('Data has type with no keys: ' + this.dataType.name);

			this.sStorage.set(keys.storageKey, this.data).then(resolve).catch(reject);
		}.bind(this);
		return new Promise(resolver);
	}

	////////////////////////////////////
	private async saveDataBySmartAddGo(compareKey): Promise<any> {
		////////////////////////////////////
		let resolver = function (resolve, reject) {
			//////////////// Get storage keys
			let keys = this.getKeysByDataType(this.dataType);
			if (!keys) return reject('Data has type with no keys: ' + this.dataType.name);

			//////////////// Get existing master data
			this.sStorage
				.get(keys.storageKey)
				.then((masterData) => {
					//////////////// Iterate over saveable data
					this.data.forEach((newDataItem) => {
						//////////////// See if an item with the compareKey exists and overwrite
						let foundData = false;
						masterData.forEach((masterDataItem, index) => {
							if (masterDataItem[compareKey] == newDataItem[compareKey]) {
								masterData[index] = newDataItem;
								foundData = true;
							}
						});

						if (!foundData) masterData.push(newDataItem);
					});

					//////////////// Write data back
					this.sStorage.set(keys.storageKey, masterData).then(resolve).catch(reject);
				})
				.catch(reject);
		}.bind(this);

		////////////////////////////////////
		return new Promise(resolver);
	}

	////////////////////////////////////
	private async overwriteLocalRecordsGo(compareKey, specificStorageKey) {
		////////////////////////////////////
		var promises: Promise<any>[] = [];

		this.data.forEach((item) => {
			////////////////////////////////////
			let resolver = function (resolve, reject) {
				let keys;
				if (typeof specificStorageKey == 'string' && specificStorageKey.length) {
					keys = { storageKey: specificStorageKey }; // <-- executed this one
				} else {
					keys = this.getKeysForItem(item);
					if (!keys) return reject("Can't find appropriate keys for data type during overwriteLocalRecords");
				}

				this.sStorage
					.get(keys.storageKey)
					.then((res) => {
						let parseData;
						try {
							parseData = JSON.parse(res);
						} catch (e) {
							parseData = res;
						}

						parseData.forEach((i, index) => {
							if (i[compareKey] == item[compareKey]) parseData[index] = item;
						});

						this.sStorage.set(keys.storageKey, parseData).then(resolve).catch(reject);
					})
					.catch(reject);
			}.bind(this);

			promises.push(new Promise(resolver));
		});

		return Promise.all(promises);
	}

	////////////////////////////////////
	private async getNextLocalIDGo() {
		let resolver = function (resolve, reject) {
			/////////////////////////
			var done = function (res) {
				if (res.length) {
					res = res.filter((it) => {
						if (!it.l_id && it.l_id !== 0) return false;
						return true;
					});
					res = res.sort((a, b) => {
						if (a.l_id < b.l_id) return -1;
						return 1;
					});
					if (res.length) this.data = res[res.length - 1].l_id + 1;
					else this.data = 1;
					resolve();
				} else {
					this.data = 1;
					resolve();
				}
			}.bind(this);

			/////////////////////////
			var error = function () {
				reject('Unable to retrieve data for getting local ID');
			};

			this.getDataGo().then(done).catch(error);
		}.bind(this);
		return new Promise(resolver);
	}

	////////////////////////////////////
	private async returnDataGo(returnProperty) {
		this.log.push('returnDataGo: Success');
		this.dataReturn[returnProperty] = this.data;
		return Promise.resolve();
	}

	////////////////////////////////////
	private async exposeDataGo(func: Function) {
		func(this.data, this.dataReturn);
		return Promise.resolve();
	}

	////////////////////////////////////
	private async flagAsServerUpdateableGo(ids: any[], idKey, type, owner, properties) {
		////////////////////////////////////
		let resolver = function (resolve, reject) {
			this.sStorage
				.get('datapushlist')
				.then((res) => {
					var parseData;
					try {
						parseData = JSON.parse(res);
					} catch (e) {
						parseData = res;
					}

					ids.forEach((id) => {
						let tempData = processMeSomeData(id, parseData, properties);
					});

					this.sStorage.set('datapushlist', parseData).then(resolve).catch(reject);
				})
				.catch(reject);
		}.bind(this);

		////////////////////////////////////
		function processMeSomeData(id, parseData, properties) {
			let existingRecord = parseData.filter((i) => {
				if (i.id == id && i.idKey == idKey && i.type == type.name && i.owner == owner) return true;
				return false;
			});

			if (existingRecord.length) {
				if (properties && properties.length) {
					existingRecord.forEach((eR) => {
						properties.forEach((prop) => {
							if (eR.properties.indexOf(prop) == -1) eR.properties.push(prop);
						});
					});
					return { result: 'success', data: parseData };
				} else {
					return { result: 'already_synced', data: parseData };
				}
			} else {
				parseData.push({ id: id, idKey: idKey, type: type.name, owner: owner, properties: properties });
				return { result: 'success', data: parseData };
			}
		}

		return new Promise(resolver);
	}

	////////////////////////////////////
	private async unflagAsServerUpdateableGo(id, idKey, typeString) {
		////////////////////////////////////
		let resolver = function (resolve, reject) {
			this.sStorage
				.get('datapushlist')
				.then((res) => {
					var parseData;
					try {
						parseData = JSON.parse(res);
					} catch (e) {
						parseData = res;
					}
					parseData = parseData.filter((i) => {
						if (i.id == id && i.idKey == idKey && i.type == typeString) return false;
						return true;
					});
					this.sStorage.set('datapushlist', parseData).then(resolve).catch(reject);
				})
				.catch(reject);
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////////
	private async updateServerDataGo(allUsersData) {
		////////////////////////////////////
		let oResolver = function (oResolve, oReject) {
			//////////// Get our push updates
			this.sStorage
				.get('datapushlist')
				.then((res: any) => {
					//////////// Prepare
					var parseUpdates;
					try {
						parseUpdates = JSON.parse(res);
					} catch (e) {
						parseUpdates = res;
					}

					//////////// Filter out other users if required
					if (!allUsersData)
						parseUpdates = parseUpdates.filter((upd) => {
							return upd.owner == this.sUser.user.id;
						});

					//////////// Prepare
					let promises: Promise<any>[] = [];

					//////////// Iterate over each pushUpdate
					parseUpdates.forEach((pushUpdate) => {
						let keys = this.typeKeys[pushUpdate.type];

						////////////////////////////////////
						let iResolver = function (iResolve, iReject) {
							this.sStorage
								.get(keys.storageKey)
								.then((res: any) => {
									//////////// Get our push update from storage
									let parseData;
									try {
										parseData = JSON.parse(res);
									} catch (e) {
										parseData = res;
									}
									parseData = parseData.filter((i) => {
										return i[pushUpdate.idKey] == pushUpdate['id'];
									});

									//////////// If we don't have data, unflag it, otherwise push it
									if (!parseData.length) {
										this.unflagAsServerUpdateableGo(pushUpdate.id, pushUpdate.idKey, pushUpdate.type);
										promises.push(iResolve);
									} else {
										this.saveObjectToServer(parseData[0], keys.dataType, pushUpdate.properties)
											.then((savedObject) => {
												//console.log("SAVED RECORD TO SERVER:", savedObject, pushUpdate);

												// Now let's assign an id (server id) to the local object if it doesn't already have one
												new DataStream(this.V, this.sUser, this.sStorage)
													.setDataType(keys.dataType)
													.getData()
													.exposeData((actions) => {
														let serverID = savedObject.id;
														let localID = pushUpdate.id;
														actions.forEach((action, index) => {
															if (!action.id && action.l_id && action.l_id == localID) actions[index].id = serverID;
														});
													})
													.saveDataByOverwrite()
													.go();

												this.unflagAsServerUpdateableGo(pushUpdate.id, pushUpdate.idKey, pushUpdate.type);
												iResolve(savedObject);
											})
											.catch(iReject);
									}
								})
								.catch(iReject);
						}.bind(this);

						promises.push(new Promise(iResolver));
					});

					// Respond based on the inner working looped promises
					Promise.all(promises).then(oResolve).catch(oReject);
				})
				.catch(oReject);
		}.bind(this);

		return new Promise(oResolver);
	}

	////////////////////////////////////
	deleteTemporaryStreamDataGo() {
		this.data = [];
		this.dataReturn = {};
		this.log.push('deleteTemporaryStreamDataGo: Success');
		return Promise.resolve();
	}

	////////////////////////////////////
	private async filterGo(func): Promise<any> {
		this.data = this.data.filter(func);
		this.log.push('filterGo: Success');
		return Promise.resolve();
	}

	////////////////////////////////////
	private async sortGo(func): Promise<any> {
		this.data = this.data.sort(func);
		this.log.push('sortGo: Success');
		return Promise.resolve();
	}

	////////////////////////////////////
	private async mapGo(func): Promise<any> {
		this.data = this.data.map(func);
		this.log.push('mapGo: Success');
		return Promise.resolve();
	}

	////////////////////////////////////
	private goGo(): Promise<any> {
		////////////////////////////////////
		this.dataType = null;
		var failure = false;
		this.attempt = 1;
		this.currentDevice = window['cordova_custom'].device();

		////////////////////////////////////
		let resolver = async function (resolve, reject) {
			for (let x = 0; x < this.todo.length; x++) {
				if (failure) break;
				await this.todo[x].func.apply(this, this.todo[x].args).catch((err) => {
					if (this.attempt < this.attempts) {
						this.attempt++;
						this.todo.map((it) => {
							it.resolved = false;
						});
						x = -1;
					} else {
						failure = true;
						console.log('FAILING');
						reject(err);
					}
				});
			}
			resolve({ data: this.dataReturn, log: this.log });
		}.bind(this);

		////////////////////////////////////
		return new Promise(resolver);
	}

	///////////////////////////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////// PRIVATE FUNCTIONS
	////////////////////////////////////
	private addTodo(object: any): void {
		object.id = this.todo.length + 1;
		object.resolved = false;
		this.todo.push(object);
	}

	////////////////////////////////////
	private getKeysForItem(item: any): any {
		if (item instanceof Exercise) return this.getKeysByDataType(Exercise);
		else if (item instanceof ExerciseCategory) return this.getKeysByDataType(ExerciseCategory);
		else if (item instanceof ExerciseAction) return this.getKeysByDataType(ExerciseAction);
		else if (item instanceof ExerciseLevel) return this.getKeysByDataType(ExerciseLevel);
		else if (item instanceof ExerciseHelp) return this.getKeysByDataType(ExerciseHelp);
		else if (item instanceof FAQ) return this.getKeysByDataType(FAQ);
		else if (item instanceof STUser) return this.getKeysByDataType(STUser);
		else if (item instanceof LocalUser) return this.getKeysByDataType(LocalUser);
		else if (item instanceof LocalGoal) return this.getKeysByDataType(LocalGoal);
		else if (item instanceof LocalJournal) return this.getKeysByDataType(LocalJournal);
		else if (item instanceof LocalCalendar) return this.getKeysByDataType(LocalCalendar);
		else return null;
	}

	////////////////////////////////////
	private getKeysByDataType(type: any) {
		if (type == Exercise) return this.typeKeys['Exercise'];
		else if (type == ExerciseCategory) return this.typeKeys['ExerciseCategory'];
		else if (type == ExerciseAction) return this.typeKeys['ExerciseAction'];
		else if (type == ExerciseLevel) return this.typeKeys['ExerciseLevel'];
		else if (type == ExerciseHelp) return this.typeKeys['ExerciseHelp'];
		else if (type == FAQ) return this.typeKeys['FAQ'];
		else if (type == STUser) return this.typeKeys['STUser'];
		else if (type == LocalUser) return this.typeKeys['LocalUser'];
		else if (type == LocalGoal) return this.typeKeys['LocalGoal'];
		else if (type == LocalJournal) return this.typeKeys['LocalJournal'];
		else if (type == LocalCalendar) return this.typeKeys['LocalCalendar'];
	}

	////////////////////////////////////
	private saveObjectToServer(object, dataType, properties = null) {
		let resolver = function (resolve, reject) {
			var newObject;
			newObject = new dataType(this.V);
			if (properties && properties.length) {
				properties.forEach((prop) => {
					if (object[prop] != undefined) newObject[prop] = object[prop];
				});
			} else {
				newObject.set(object);
			}
			newObject
				.saveObservable()
				.toPromise()
				.then((res) => {
					if (res instanceof VasatError) reject(res);
					else resolve(res);
				})
				.catch(reject);
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////////
	prepareLocalStorage(key, value = '[]'): Promise<any> {
		if (localStorage.getItem(key) == null) {
			localStorage.setItem(key, value);
		}
		return Promise.resolve();
	}

	////////////////////////////////////
	convertDataToTypeVasatObject(data, type, index) {
		var newObject = new type(this.V);
		newObject.set(data);
		this.data[index] = newObject;
		return Promise.resolve();
	}
}
