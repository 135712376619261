///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
////////// IMPORTS
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataService }								from '../../services/data.service';
import { STUser, ProgressCategory }					from '../../st-commons/models';
import { Vasat }									from 'vasat';
import { UserService }								from '../../services/user.service';
import { ExerciseService }							from '../../services/exercise.service';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
  selector:		'app-page-settingsexercises',
  templateUrl:	'./page-settingsexercises.component.html',
  styleUrls:	['./page-settingsexercises.component.scss']
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class PageSettingsexercisesComponent implements OnInit {

	////////////////////////////////
	@ViewChild('togCog') togCog;
	@ViewChild('togBal') togBal;
	@ViewChild('togFloor') togFloor;
	@ViewChild('togFoam') togFoam;
	@ViewChild('togMatBox') togMatBox;
	@ViewChild('togDart') togDart;
	@ViewChild('tog4Grid') tog4Grid;
	@ViewChild('togDancing') togDancing;
	////////////////////////////////
	@ViewChild('dropCog') dropCog;
	@ViewChild('dropBal') dropBal;
	@ViewChild('dropFloor') dropFloor;
	@ViewChild('dropFoam') dropFoam;
	@ViewChild('dropMatBox') dropMatBox;
	@ViewChild('dropDart') dropDart;
	@ViewChild('drop4Grid') drop4Grid;
	@ViewChild('dropDancing') dropDancing;

	////////////////////////////////
	levelProgression:ProgressCategory[] = [];

	////////////////////////////////
	constructor(private sData:DataService, private V:Vasat, private sUser:UserService, private sExercise:ExerciseService) {}
	ngOnInit() {
		this.sData.getAndCacheExerciseData()
		.then( res => {
			this.sUser.user.updateRelations();
			this.levelProgression = this.sUser.user.levelProgression;
		});
	}

	////////////////////////////////
	toggleValueUpdated(property, value) {
		this.setExerciseDisabled(property, value.state);
	}

	////////////////////////////////
	setExerciseDisabled(name, value) {
		let setting = this.sUser.user.levelProgression.filter( it => { return it.category.name == name; });
		if (setting.length) {
			setting[0].disabled = !value;
			this.sData.updateUserProfile(this.sUser.user, true, ['levelProgression']);
		}
	}

	////////////////////////////////
	createOptionsArray(lp) {
		let maxForThisCat = lp.category.max_checkpoints;
		let maxForThisUser = lp.max_checkpoint || 100;
		let num = Math.min(maxForThisCat, maxForThisUser);
		num = Math.max(1, num);
		
		let array = Array.apply(null, {length: num}).map(Number.call, Number);
		return array;
	}

	////////////////////////////////
	checkpointOptionChange($event, uid) {
		this.setCheckpointValue(uid, $event);
	}

	////////////////////////////////
	setCheckpointValue(uid, value) {
		/*let setting = this.sUser.user.levelProgression.filter( it => { return it.category.name == name; });
		if (setting.length) {
			setting[0].checkpoint = parseInt(value);
			this.sData.updateUserProfile(this.sUser.user, true, ['levelProgression']);
		}*/
		let setting = this.sUser.user.levelProgression.find( it => { return it.category.uid == uid; });
		if (setting) {
			this.sExercise.stExerciseLogic.jumpCheckpoint(parseInt(value), setting, this.sUser.user);
			this.sData.updateUserProfile(this.sUser.user, true, ['levelProgression']);
		}
	}
}
