///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS
import { Injectable } from '@angular/core';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS
@Injectable({
	providedIn:'root'
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS
export class DeviceService {

	// States
	public platform: string;
	public isBrowser: boolean;
	public isiOS: boolean;
	public isAndroid: boolean;

	// Data
	manifest: any[];
	
	////////////////////////////////////
	constructor() { this.checkDevice(); }

	////////////////////////////////////
	checkDevice() {
		this.platform	= window['cordova_custom'].device().platform;
		this.isiOS		= (this.platform == "iOS");
		this.isAndroid	= (this.platform == "Android");
		this.isBrowser	= (window['cordova_custom'].device() == "browser");
	}

	////////////////////////////////////
	getHelps(parentID) {
		let files = this.manifest && this.manifest.filter(mn => mn.parentId == parentID) || [];
		
		return {
			all:	() => 		{ return files; },
			videos: () => 		{ return files.filter(fl => fl.name == "tutorial_video.mp4"); },
			images: () => 		{ return files.filter(fl => fl.name.indexOf("image") != -1); },
			image:	(imID) =>	{ return files.filter(fl => fl.name.indexOf("image_"+imID) != -1); }
		}
	}
}
