///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS
import { environment } 					from '../../../environments/environment';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS
import { Component, OnInit } from '@angular/core';


///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
  selector:		'app-page-help',
  templateUrl:	'./page-help.component.html',
  styleUrls:	['./page-help.component.scss']
})


///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class PageHelpComponent implements OnInit {

	////////////////////////////////
	appVersion: string = environment.appVersion;

	////////////////////////////////////
	constructor() {}
	ngOnInit() {}



}
