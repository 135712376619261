///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
////////// ANGULAR CORE
import { Component, ViewChild } 	from '@angular/core';
import { Router }					from '@angular/router';
import { UserService }				from '../../services/user.service';
import { DataService }				from '../../services/data.service';
import { CommandForceAlphaService }	from '../../services/commandforcealpha.service';


///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// SETUP COMPONENT
@Component({
  selector: 'app-page-adherence',
  templateUrl: './page-adherence.component.html',
  styleUrls: ['./page-adherence.component.scss']
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class PageAdherenceComponent {

	@ViewChild('reason') reason;

	////////////////////////////////////
	constructor(private router:Router, private sUser:UserService, private sData:DataService, private sCommand:CommandForceAlphaService) {}

	////////////////////////////////////
	submitResponse() {
		let reason = this.reason.nativeElement.value;
		this.sUser.user.adherenceFailReason = reason;
		this.sUser.user.adherenceFailLastDate = this.sUser.user.adherenceLastUseDate;
		this.sUser.user.adherenceFailTrigDate = new Date().getTime();
		this.sUser.user.adherenceLastUseDate = new Date().getTime();
		this.sData.updateUserProfile(this.sUser.user, true, ['adherenceFailReason', 'adherenceFailLastDate', 'adherenceFailTrigDate', 'adherenceLastUseDate']);
		this.sData.sendAllServerUpdates();
		this.router.navigate([this.sCommand.adherenceRedirectedFrom]);
	}
}
