///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
////////// ANGULAR CORE
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBluewaveComponent } from '../../components/form-bluewave/form-bluewave.component';
import { ExerciseService } from '../../services/exercise.service';
import { Router } from '@angular/router';
import { GlobalPubSub } from '../../services/global-pub-sub.service';
import { UserService } from '../../services/user.service';
import { TimeCircleComponent, TimerCompletionBehaviour, TimerDirection, TimerMode } from '../../components/time-circle/time-circle.component';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// SETUP COMPONENT
@Component({
	selector: 'app-page-exercise1home',
	templateUrl: './page-exercise1home.component.html',
	styleUrls: ['./page-exercise1home.component.scss'],
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class PageExercise1homeComponent implements OnInit {
	////////////////////////////////
	@ViewChild('bluewaveform') bluewaveform: FormBluewaveComponent;
	@ViewChild('progressCircle') progressCircle: TimeCircleComponent;

	////////////////////////////////
	secCheckOpen: string = 'off';
	exerciseTimesWeeks1_3: any[] = [10, 15];
	exerciseTimesFull: any[] = [10, 15, 20, 25, 30];
	exerciseTimesReduced: any[] = [20, 25, 30];
	exerciseTimes = [];
	selectedTime: any;
	dosages: any = {};

	////////////////////////////////
	constructor(private sExercise: ExerciseService, private router: Router, private sGlobalPubSub: GlobalPubSub, private sUser: UserService) { }
	ngOnInit() {
		let week = this.sExercise.stExerciseLogic.weekNumber(this.sUser.user);
		this.sExercise.determineSessionScenarioCapabilities();

		// Set exerciseTimes
		if (week <= 2) {
			this.exerciseTimes = this.exerciseTimesWeeks1_3.map((i) => (i = { val: i, on: true }));
		} else {
			this.exerciseTimes = this.exerciseTimesFull.map((i) => (i = { val: i, on: true }));
		}

		// Just in case the user gets here without a balance assessment
		this.sExercise.needsBalanceAssessment().then((res) => {
			if (res) {
				this.sGlobalPubSub.fireEvent('toast', [
					true,
					'You cannot exercise right now because you have an outstanding Balance Assessment.  We will return you to the homepage.',
				]);
				setTimeout(() => {
					this.router.navigate(['/home']);
				}, 1500);
			}
		});

		// Get dosages
		this.sExercise.getDoseageForThisWeek().then((res) => {
			this.dosages = res;

			// Setup the progress circle
			this.progressCircle.createTimer({
				timerMode: TimerMode.percentage,
				direction: TimerDirection.countup,
				totalValue: 100,
				elapsedValueMS: this.dosages.percentage * 1000,
				completionBehaviour: TimerCompletionBehaviour.reset,
			});
		});
	}

	////////////////////////////////
	selectExerciseTime(time, event) {
		if (event.target.classList.contains('disabled')) {
			this.sGlobalPubSub.fireEvent('toast', [true, 'Not enough exercises at your current level to exercise for that long.', 3000]);
		} else {
			this.selectedTime = time;
			this.bluewaveform.open();
		}
	}

	////////////////////////////////
	eventReceived(ev) {
		if (ev.type == 'cancel') this.bluewaveform.close();
		if (ev.type == 'requestContinue') {
			this.createNewExerciseSession();
		}
	}

	////////////////////////////////
	createNewExerciseSession() {
		this.sExercise.clearSession().then((res) => {
			this.sExercise
				.createNewSession(this.selectedTime, this.sExercise.selectedSessionScenario)
				.then((res2) => {
					this.router.navigate(['/exercises/main']);
				})
				.catch((err) => {
					this.sGlobalPubSub.fireEvent('toast', [true, 'Unable to create an exercise session: ' + err, 3000]);
					console.log('ERROR creating new session', err);
				});
		});
	}
}
