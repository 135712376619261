///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
////////// MODULES
import { Component, OnInit }	from '@angular/core';
import { Router }				from '@angular/router';
import { DataService }			from '../../services/data.service';
import { UserService }			from '../../services/user.service';


///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
  selector:		'app-page-onboarded',
  templateUrl:	'./page-onboarded.component.html',
  styleUrls:	['./page-onboarded.component.scss']
})


///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class PageOnboardedComponent implements OnInit {

	////////////////////////////////
	selectionStarted:boolean = false;
	charSelected:number = null;

	////////////////////////////////
	constructor(private router:Router, private sData:DataService, private sUser:UserService) { }
	ngOnInit() {}

	////////////////////////////////
	selectCharacter(which:number) {
		this.selectionStarted = true;
		this.charSelected = which;
	}

	////////////////////////////////
	confirmSelection() {
		this.sData.setGuide(this.charSelected);
		this.sUser.user.updateRelations();
		console.log("ABout to save after update", this.sUser.user);
		this.sData.updateUserProfile(this.sUser.user, false, null);
		this.router.navigate(['/onboarded2']);
	}
}
