///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
import { Component, ElementRef, HostBinding, Input } from '@angular/core';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
  selector: 'form-bluewave',
  templateUrl: './form-bluewave.component.html',
  styleUrls: ['./form-bluewave.component.scss']
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class FormBluewaveComponent {

	@HostBinding('class.openState')				openState: boolean			= false;
	@HostBinding('class.openingState')			openingState: boolean		= false;
	@HostBinding('class.closingState')			closingState: boolean		= false;
	@HostBinding('attr.data-formcontentstate')	formContentState: string	= "";
	@Input() size:string = "";

	////////////////////////////////
	constructor(public el:ElementRef) {}

	////////////////////////////////
	close() {
		this.openState = false;
		this.openingState = false;
		this.closingState = true;
		setTimeout( () => {
			this.closingState = false;
		}, 1100);
	}

	////////////////////////////////
	open() {
		this.openingState = true;
		this.closingState = false;
		setTimeout( () => {
			this.openState = true;
			this.openingState = false;
		}, 1000);
	}

	////////////////////////////////
	setFormContentState(state:string): void {
		this.formContentState = state;
	}

	////////////////////////////////
	hasState(states:any): boolean {
		if (Array.isArray(states)) {
			if (states.indexOf(this.formContentState) > -1) return true;
			return false;
		} else {
			if (states == this.formContentState) return true;
			return false;
		}
	}

}
