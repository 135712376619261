///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS
import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { ShortSchedule, RepEvent } from '../shortSchedule';
import { FeetFootFeatFortFart } from './feet-foot-feat-fort-fart';
import { CognitiveCreator } from './cognitive';
import { AbsExerciseService } from '../service';
import { AudioController } from './audiocontroller';
import { Box_Sequences } from './box_definitions/box_definitions';

declare const require: any;

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
	selector: 'st-animations',
	template: require('./st-animations.component.html'),
	styles: [require('./st-animations.component.scss').toString()],
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class StAnimationsComponent implements OnInit, OnDestroy {
	@ViewChild('animationContainer') animationContainer;
	@ViewChild('dartboard') dartboard;
	@ViewChild('grid') grid;
	@ViewChild('box') box;
	@ViewChild('character') character;
	@ViewChild('feetbounds') feetbounds;

	@ViewChild('footLeft') footLeft;
	@ViewChild('footLeftFlat') footLeftFlat;
	@ViewChild('footLeftForward') footLeftForward;
	@ViewChild('footRight') footRight;
	@ViewChild('footRightFlat') footRightFlat;
	@ViewChild('footRightForward') footRightForward;
	@ViewChild('floorFootLeft') floorFootLeft;
	@ViewChild('floorFootRight') floorFootRight;

	@ViewChild('gridIcoArrow') gridIcoArrow;
	@ViewChild('gridIcoCompass') gridIcoCompass;
	@ViewChild('gridIcoTargets') gridIcoTargets;
	@ViewChild('gridIcoTarget1') gridIcoTarget1;
	@ViewChild('gridIcoTarget2') gridIcoTarget2;
	@ViewChild('gridIcoTarget3') gridIcoTarget3;

	@ViewChild('charFloor') charFloor;
	@ViewChild('charFoam') charFoam;
	@ViewChild('charShadow') charShadow;
	@ViewChild('charPose') charPose;

	//@ViewChild('audioEl') audioEl;
	audioBeepMedia: any;

	@ViewChild('cogimage') cogimage;
	@ViewChild('cogcont') cogcont;
	//@ViewChild('cogaudioEl') cogaudioEl;
	@ViewChild('cogpatterncont') cogpatterncont;

	@ViewChild('error') error;

	////////////////////////////////////
	animSchedule: ShortSchedule;
	cognitiveComponent: any;
	cognitiveImageOnScreen: number = 875;
	backupScheduled: any[] = [];
	limiter: number = 0;
	limiter2: number = 0;
	gridMoveLeftFootThisTime: boolean = true;
	sExercise: AbsExerciseService;
	audioController: AudioController;
	preloadableImages: string[] = [];
	preloadCharacterPoseTimeout: any;

	////////////////////////////////////
	iconsOnSegments: any[] = [];
	segmentLookup = {
		'N-Long': 'dartseg1',
		'NE-Long': 'dartseg2',
		'E-Long': 'dartseg3',
		'SE-Long': 'dartseg4',
		'S-Long': 'dartseg5',
		'SW-Long': 'dartseg6',
		'W-Long': 'dartseg7',
		'NW-Long': 'dartseg8',

		'N-Short': 'dartseg9',
		'NE-Short': 'dartseg10',
		'E-Short': 'dartseg11',
		'SE-Short': 'dartseg12',
		'S-Short': 'dartseg13',
		'SW-Short': 'dartseg14',
		'W-Short': 'dartseg15',
		'NW-Short': 'dartseg16',

		inner: 'dartcircle',
	};
	segmentPositionVariances = {
		dartseg1: { y: 0.33, x: 0.63 },
		dartseg2: { y: 0.47, x: 0.72 },
		dartseg3: { y: 0.64, x: 0.69 },
		dartseg4: { y: 0.7, x: 0.55 },
		dartseg5: { y: 0.68, x: 0.36 },
		dartseg6: { y: 0.56, x: 0.31 },
		dartseg7: { y: 0.38, x: 0.33 },
		dartseg8: { y: 0.31, x: 0.44 },

		dartseg9: { y: 0.43, x: 0.63 },
		dartseg10: { y: 0.54, x: 0.65 },
		dartseg11: { y: 0.64, x: 0.56 },
		dartseg12: { y: 0.68, x: 0.52 },
		dartseg13: { y: 0.59, x: 0.37 },
		dartseg14: { y: 0.51, x: 0.36 },
		dartseg15: { y: 0.39, x: 0.41 },
		dartseg16: { y: 0.34, x: 0.5 },

		dartcircle: { y: 0.41, x: 0.45 },
		dartcircle_icon: { y: 0.5, x: 0.5 },
	};
	outerSegments = ['N-Long', 'NE-Long', 'E-Long', 'SE-Long', 'S-Long', 'SW-Long', 'W-Long', 'NW-Long'];

	dartboardIcons = [
		'clock',
		'coolS',
		'copyright',
		'face',
		'power',
		'question',
		'plane',
		'dog',
		'hand',
		'star',
		'circle',
		'feather',
		'bell',
		'camera',
		'bird',
		'celebrity',
	];
	dartboardFootprints = ['fp-bear', 'fp-deer', 'fp-dog', 'fp-duck', 'fp-elephant', 'fp-frog', 'fp-horse', 'fp-wolf'];

	////////////////////////////////////
	poseDeets: any = {
		blank: 'F000_blank', // Used when in development / asset not created

		neutral: 'F001',

		St_HW: 'F001',
		St_HW_MoveR: { pose: 'F001', horz: -8 },
		St_HW_MoveHR: { pose: 'F001', horz: -4 },
		St_HW_MoveL: { pose: 'F001', horz: 8 },
		St_HW_MoveHL: { pose: 'F001', horz: 4 },
		St_FT: 'F002',
		'Standing - Feet Together': 'F002',

		St_T: 'F026',
		'Standing - Tandem': 'F026',
		St_NT: 'F014',
		'Standing - Near Tandem': 'F014',

		St_Ey_C_FT: 'F007',
		'Standing with Eyes Closed: Feet Together': 'F007',
		St_Ey_C_NT: 'F019',
		'Standing with Eyes Closed: Near Tandem': 'F019',
		St_Ey_C_T: 'F031',
		'Standing with Eyes Closed: Tandem': 'F031',

		Lean_R: 'F126',
		Lean_L: 'F129',
		Lean_R_LFo_Li: 'F139',
		Lean_L_RFo_Li: 'F138',

		Si_St: 'F142',
		Si_HW: 'F142',
		Si_Heels_D: 'F142',
		Si_St_NT: 'F145',
		Si_Heels_D_NT: 'F145',
		Si_NT: 'F145',
		Si_St_HW: 'F142',
		Si_St_T: 'F144',
		Si_Heels_D_T: 'F144',
		Si_FT: 'F142',
		St_FT_Arms: 'F142',
		Si_HH: 'F150',
		'Hold on Heels - Feet Together': 'F150',
		'Hold on Heels - Feet Hip Width': 'F150',
		Si_Heels_U: 'F146',
		Si_HT: 'F146',
		Si_Heels_U_NT: 'F149',
		Si_Heels_U_T: 'F148',

		Si_Lean_F: 'F152',
		Si_Lean_B: 'F154',
		Si_Lean_F_NT: 'F153',
		Si_Lean_B_NT: 'F155',

		St_Neutral_Left: 'F156',

		Si_T: 'F145',
		Si_HT_FT: 'F146',
		'Hold on Toes - Feet Together': 'F146',
		Si_HT_HW: 'F149',
		'Hold on Toes - Feet Hip Width': 'F149',
		Si_HT_NT: 'F148',
		'Hold on Toes - Near Tandem': 'F148',
		Si_HT_T: 'F148',
		'Hold on Toes - Tandem': 'F148',
		Si_HT_RLeg_LLeg90B: 'F167',
		'Hold on Toes - Right Leg': 'F167',
		Si_HT_LLeg_RLeg90B: 'F169',
		'Hold on Toes - Left Leg': 'F169',

		Si_Toes_U: 'F150',

		LKn_Ra: 'F125',
		RKn_Ra: 'F124',

		St_RLeg_LLeg90B: 'F110',
		St_LLeg_RLeg90B: 'F102',
		St_Ey_C_LLeg_RLeg90B: 'F103',
		'Standing with Eyes Closed - Left Leg': 'F103',
		St_Ey_C_RLeg_LLeg90B: 'F095',
		'Standing with Eyes Closed: Right Leg': 'F095',
		Si_LLeg_RLeg90B: 'F168',
		Si_LLeg_Heel_D: 'F168',
		Si_HR_LLeg_RLeg90B: 'F168',
		Si_RLeg_LLeg90B: 'F166',
		Si_RLeg_Heel_D: 'F166',
		Si_HR_RLeg_LLeg90B: 'F166',

		Si_LLeg_Heel_U: 'F169',
		Si_RLeg_Heel_U: 'F167',

		Si_Seat_Hands_K: { pose: 'F162', vert: 5 },
		Si_Seat_ArmsX: { pose: 'F164', vert: 5 },
		St_ArmsX: { pose: 'F165', vert: 5 },

		Si_Seat_Punch_Neutral: { pose: 'F185', vert: 5 },
		Si_Seat_Punch_Forward_Left: { pose: 'F186', vert: 5 },
		Si_Seat_Punch_Forward_Right: { pose: 'F187', vert: 5 },
		Si_Seat_Punch_Upwards_Left: { pose: 'F188', vert: 5 },
		Si_Seat_Punch_Upwards_Right: { pose: 'F189', vert: 5 },

		Si_Seat_Marching_Left_Early: { pose: 'F190', vert: 5 },
		Si_Seat_Marching_Left_Mid: { pose: 'F191', vert: 5 },
		Si_Seat_Marching_Left_Full: { pose: 'F192', vert: 5 },
		Si_Seat_Marching_Mid: { pose: 'F193', vert: 5 },
		Si_Seat_Marching_Right_Early: { pose: 'F194', vert: 5 },
		Si_Seat_Marching_Right_Full: { pose: 'F195', vert: 5 },

		Si_Should_Roll_Neutral: { pose: 'F196', vert: 5 },
		Si_Should_Roll_1: { pose: 'F197', vert: 5 },
		Si_Should_Roll_2: { pose: 'F198', vert: 5 },
		Si_Should_Roll_3: { pose: 'F199', vert: 5 },

		Si_St_Chair: { pose: 'F163', vert: 5 },

		RLeg_Steps_Si_R_S_KL: 'F124',
		RLeg_Steps_Si_HKs: 'F124',
		RLeg_Steps_Si_HKs_MoveHL: { pose: 'F124', horz: 4 },
		LLeg_Steps_Si_L_S_KL: 'F125',
		LLeg_Steps_Si_HKs: 'F125',
		LLeg_Steps__Si_HKs: 'F125',
		LLeg_Steps_Si_HKs_MoveHR: { pose: 'F125', horz: -4 },

		St_LLeg: 'F102',
		St_LLeg_LArm_F_RArm_Si: 'F104',
		St_LLeg_LArm_L_RArm_Si: 'F105',
		St_LLeg_LArm_R_RArm_Si: 'F106',
		St_LLeg_RArm_F_LArm_Si: 'F107',
		St_LLeg_RArm_R_LArm_Si: 'F108',
		St_LLeg_RArm_L_LArm_Si: 'F109',

		St_RLeg: 'F094',
		St_RLeg_LArm_F_RArm_Si: 'F111',
		St_RLeg_LArm_L_RArm_Si: 'F112',
		St_RLeg_LArm_R_RArm_Si: 'F113',
		St_RLeg_RArm_F_LArm_Si: 'F114',
		St_RLeg_RArm_R_LArm_Si: 'F115',
		St_RLeg_RArm_L_LArm_Si: 'F116',

		St_FT_LArm_F_RArm_Si: 'F008',
		St_FT_LArm_L_RArm_Si: 'F009',
		St_FT_LArm_R_RArm_Si: 'F010',
		St_FT_RArm_F_LArm_Si: 'F011',
		St_FT_RArm_R_LArm_Si: 'F012',
		St_FT_RArm_L_LArm_Si: 'F013',

		St_NT_LArm_F_RArm_Si: 'F053',
		St_NT_LArm_L_RArm_Si: 'F054',
		St_NT_LArm_R_RArm_Si: 'F055',
		St_NT_RArm_F_LArm_Si: 'F056',
		St_NT_RArm_R_LArm_Si: 'F057',
		St_NT_RArm_L_LArm_Si: 'F058',

		St_HW_LArm_F_RArm_Si: 'F039',
		St_HW_LArm_L_RArm_Si: 'F040',
		St_HW_LArm_R_RArm_Si: 'F041',
		St_HW_RArm_F_LArm_Si: 'F042',
		St_HW_RArm_R_LArm_Si: 'F043',
		St_HW_RArm_L_LArm_Si: 'F044',

		HT_FT: 'F073',
		HT_FT_LArm_F_RArm_Si: 'F074',
		HT_FT_LArm_L_RArm_Si: 'F075',
		HT_FT_LArm_R_RArm_Si: 'F076',
		HT_FT_RArm_F_LArm_Si: 'F077',
		HT_FT_RArm_R_LArm_Si: 'F078',
		HT_FT_RArm_L_LArm_Si: 'F079',

		HT_NT: 'F080',
		HT_NT_LArm_F_RArm_Si: 'F081',
		HT_NT_LArm_L_RArm_Si: 'F082',
		HT_NT_LArm_R_RArm_Si: 'F083',
		HT_NT_RArm_F_LArm_Si: 'F084',
		HT_NT_RArm_R_LArm_Si: 'F085',
		HT_NT_RArm_L_LArm_Si: 'F086',

		HT_HW: 'F066',
		HT_HW_LArm_F_RArm_Si: 'F067',
		HT_HW_LArm_L_RArm_Si: 'F068',
		HT_HW_LArm_R_RArm_Si: 'F069',
		HT_HW_RArm_F_LArm_Si: 'F070',
		HT_HW_RArm_R_LArm_Si: 'F071',
		HT_HW_RArm_L_LArm_Si: 'F072',

		St_T_LArm_F_RArm_Si: 'F060',
		St_T_LArm_L_RArm_Si: 'F061',
		St_T_LArm_R_RArm_Si: 'F062',
		St_T_RArm_F_LArm_Si: 'F063',
		St_T_RArm_R_LArm_Si: 'F064',
		St_T_RArm_L_LArm_Si: 'F065',

		St_FT_Head_L: 'F003',
		St_FT_Head_R: 'F004',
		St_FT_Head_U: 'F005',
		St_FT_Head_D: 'F006',

		St_NT_Head_L: 'F015',
		St_NT_Head_R: 'F016',
		St_NT_Head_U: 'F017',
		St_NT_Head_D: 'F018',

		HT_T: 'F087',
		HT_T_LArm_F_RArm_Si: 'F088',
		HT_T_LArm_L_RArm_Si: 'F089',
		HT_T_LArm_R_RArm_Si: 'F090',
		HT_T_RArm_F_LArm_Si: 'F091',
		HT_T_RArm_R_LArm_Si: 'F092',
		HT_T_RArm_L_LArm_Si: 'F093',

		HT_RLeg_LLeg90B: 'F110',
		HT_RLeg_LArm_F_RArm_Si: 'F111',
		HT_RLeg_LArm_L_RArm_Si: 'F112',
		HT_RLeg_LArm_R_RArm_Si: 'F113',
		HT_RLeg_RArm_F_LArm_Si: 'F114',
		HT_RLeg_RArm_R_LArm_Si: 'F115',
		HT_RLeg_RArm_L_LArm_Si: 'F116',

		St_T_Head_L: 'F027',
		St_T_Head_R: 'F028',
		St_T_Head_U: 'F029',
		St_T_Head_D: 'F030',

		HT_LLeg_RLeg90B: 'F117',
		HT_LLeg_LArm_F_RArm_Si: 'F118',
		HT_LLeg_LArm_L_RArm_Si: 'F119',
		HT_LLeg_LArm_R_RArm_Si: 'F120',
		HT_LLeg_RArm_F_LArm_Si: 'F121',
		HT_LLeg_RArm_R_LArm_Si: 'F122',
		HT_LLeg_RArm_L_LArm_Si: 'F123',

		Lean_R_RArm_Reach_LArm_Side: 'F131',
		Lean_R_RArm_Reach_LArm_Si: 'F131',
		Lean_L_LArm_Reach_RArm_Side: 'F127',
		Lean_L_LArm_Reach_RArm_Si: 'F127',

		St_PushUpWall_Neutral: 'F200',
		St_PushUpWall_Mid: 'F201',

		Si_ForwardRow_Back: 'F202',
		Si_ForwardRow_Forward: 'F203',

		Si_BicepCurlUp_Neutral: 'F204',
		Si_BicepCurlUp_LeftUp: 'F205',
		Si_BicepCurlUp_RightUp: 'F206',

		Si_ShoulderPress_Neutral: 'F207',
		Si_ShoulderPress_Up: 'F208',

		Si_ShoulderLift_Neutral: 'F209',
		Si_ShoulderLift_Up: 'F210',

		Si_SideLift_Neutral: 'F211',
		Si_SideLift_Up: 'F212',

		Si_ForwardLift_Neutral: 'F213',
		Si_ForwardLift_Up: 'F214',

		St_KneeFlex_Neutral: 'F215',
		St_KneeFlex_LeftUp: 'F216',
		St_KneeFlex_RightUp: 'F217',

		St_HipAbduction_Neutral: 'F218',
		St_HipAbduction_LeftUp: 'F219',
		St_HipAbduction_RightUp: 'F220',

		St_Squat_Neutral: 'F221',
		//St_Squat_Mid: 'F222',
		St_Squat_Down: 'F223',

		Si_KneeExtensions_Neutral: 'F224',
		Si_KneeExtensions_Left: 'F225',
		Si_KneeExtensions_Right: 'F226',

		Si_RomanianDeadlifts_Neutral: 'F227',
		Si_RomanianDeadlifts_Down: 'F228',
	};

	////////////////////////////////////
	renderedIcons: Element[] = [];
	highlightedElements: Element[] = [];

	////////////////////////////////////
	debugLastFireTime: number;

	////////////////////////////////////
	constructor() {
		this.audioController = window['cordova_custom'] ? new AudioController(window['cordova_custom'].device().platform) : null;
	}

	////////////////////////////////////
	ngOnInit() {
		if (document.body.getAttribute('data-isie') == 'true') {
			this.segmentPositionVariances['dartcircle'].y = 0.48;
		}
	}

	////////////////////////////////////
	ngOnDestroy() {
		this.audioController.resetAudio();
	}

	//////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////  GENERAL METHODS

	////////////////////////////////
	setExerciseService(sExercise) {
		this.sExercise = sExercise;
	}

	////////////////////////////////
	getContainerPos() {
		let rect = this.animationContainer.nativeElement.getBoundingClientRect();
		return { left: rect.left, top: rect.top };
	}

	////////////////////////////////
	setFootStance(foot: string, stance: string) {
		if (foot == 'both') {
			document.querySelector('.foot-left').setAttribute('data-stance', stance);
			document.querySelector('.foot-right').setAttribute('data-stance', stance);
		} else {
			document.querySelector('.foot-' + foot).setAttribute('data-stance', stance);
		}
	}

	////////////////////////////////
	removeRenderedIcons() {
		this.renderedIcons.forEach((ico) => {
			ico.parentElement.removeChild(ico);
		});
		this.renderedIcons = [];
	}

	////////////////////////////////
	unhighlightAll() {
		this.highlightedElements.forEach((el) => {
			this.removeClass(el, 'on');
			this.removeClass(el, 'numbered');

			let gridNumber = el.querySelector('.number');
			if (gridNumber) (gridNumber as HTMLElement).innerText = '';
		});
		this.highlightedElements = [];
	}

	//////////////////////////////// // Cleans and hides all animation elements
	resetAnimation() {
		if (this.animSchedule) this.animSchedule.stop();
		//this.error.nativeElement.classList.remove('on');
		this.removeClass(this.error.nativeElement, 'on');
		this.removeRenderedIcons();
		this.unhighlightAll();

		this.removeClass(this.footLeft.nativeElement, 'on');
		this.removeClass(this.footRight.nativeElement, 'on');
		this.removeClass(this.floorFootLeft.nativeElement, 'on');
		this.removeClass(this.floorFootRight.nativeElement, 'on');
		this.footLeftForward.nativeElement.style.transform = '';
		this.footLeftFlat.nativeElement.style.transform = '';
		this.footRightForward.nativeElement.style.transform = '';
		this.footRightFlat.nativeElement.style.transform = '';

		this.removeClass(this.dartboard.nativeElement, 'on');
		this.removeClass(this.character.nativeElement, 'on');
		this.removeClass(this.box.nativeElement, 'on');
		this.removeClass(this.grid.nativeElement, 'on');

		if (this.gridIcoTargets && this.gridIcoTargets.nativeElement != undefined) this.removeClass(this.gridIcoTargets.nativeElement, 'on');
		if (this.gridIcoTarget1 && this.gridIcoTarget1.nativeElement != undefined)
			this.gridIcoTarget1.nativeElement.setAttribute('class', 'grid-ico-target');
		if (this.gridIcoTarget2 && this.gridIcoTarget2.nativeElement != undefined)
			this.gridIcoTarget2.nativeElement.setAttribute('class', 'grid-ico-target');
		if (this.gridIcoTarget3 && this.gridIcoTarget3.nativeElement != undefined)
			this.gridIcoTarget3.nativeElement.setAttribute('class', 'grid-ico-target');

		this.removeClass(this.feetbounds.nativeElement, 'on');

		if (this.charFloor && this.charFloor.nativeElement != undefined) this.removeClass(this.charFloor.nativeElement, 'on');

		if (this.charFoam && this.charFoam.nativeElement != undefined) this.removeClass(this.charFoam.nativeElement, 'on');

		this.cogpatterncont.nativeElement.innerHTML = '';
		this.removeClass(this.cogpatterncont.nativeElement, 'on');

		// Reset foot to flat
		this.footLeft.nativeElement.setAttribute('data-stance', 'flat');
		this.footRight.nativeElement.setAttribute('data-stance', 'flat');

		this.resetDartboardIcons();
		this.audioController.resetAudio();

		// Reset locked dartboard height
		let dartboard = document.querySelector('.dartboard') as HTMLElement;
		if (dartboard) dartboard.style.height = '100%';
	}

	//////////////////////////////////// // Just hides items no longer needed, such as arrows and prompts
	cleanup() {
		console.log('%cCLEANING UP ANIMATION', 'color:magenta');

		if (this.gridIcoCompass && this.gridIcoCompass.nativeElement != undefined)
			this.gridIcoCompass.nativeElement.setAttribute('class', 'grid-icon-compass');

		if (this.gridIcoArrow && this.gridIcoArrow.nativeElement != undefined) this.removeClass(this.gridIcoArrow.nativeElement, 'on');

		if (this.gridIcoTargets && this.gridIcoTargets.nativeElement != undefined) this.removeClass(this.gridIcoTargets.nativeElement, 'on');

		if (this.gridIcoTarget1 && this.gridIcoTarget1.nativeElement != undefined)
			this.gridIcoTarget1.nativeElement.setAttribute('class', 'grid-ico-target');

		if (this.gridIcoTarget2 && this.gridIcoTarget2.nativeElement != undefined)
			this.gridIcoTarget2.nativeElement.setAttribute('class', 'grid-ico-target');

		if (this.gridIcoTarget3 && this.gridIcoTarget3.nativeElement != undefined)
			this.gridIcoTarget3.nativeElement.setAttribute('class', 'grid-ico-target');

		if (this.footLeft && this.footLeft.nativeElement != undefined) this.removeClass(this.footLeft.nativeElement, 'on');

		if (this.footRight && this.footRight.nativeElement != undefined) this.removeClass(this.footRight.nativeElement, 'on');

		if (this.floorFootLeft && this.floorFootLeft.nativeElement != undefined) this.removeClass(this.floorFootLeft.nativeElement, 'on');

		if (this.floorFootRight && this.floorFootRight.nativeElement != undefined) this.removeClass(this.floorFootRight.nativeElement, 'on');

		if (this.cogpatterncont && this.cogpatterncont.nativeElement != undefined) this.removeClass(this.cogpatterncont.nativeElement, 'on');

		this.audioController.resetAudio();
	}

	////////////////////////////////////
	determineCardinalDifference(lastMove, thisMove) {
		let comp1 = '';
		let comp2 = '';
		let isLeft = thisMove.x < lastMove.x;
		let isRight = thisMove.x > lastMove.x;
		let isDown = thisMove.y > lastMove.y;
		let isUp = thisMove.y < lastMove.y;

		if (isUp) comp1 = 'F';
		else if (isDown) comp1 = 'B';

		if (isLeft) comp2 = 'L';
		else if (isRight) comp2 = 'R';

		return comp1 + comp2;
	}

	////////////////////////////////////
	translateCardinalToRotation(dir, opposite = false) {
		let rot = 0;
		switch (dir) {
			case 'L':
			case 'W':
				rot = opposite ? 90 : 270;
				break;
			case 'F':
			case 'N':
				rot = opposite ? 180 : 0;
				break;
			case 'R':
			case 'E':
				rot = opposite ? 270 : 90;
				break;
			case 'B':
			case 'S':
				rot = opposite ? 0 : 180;
				break;
			case 'FL':
			case 'NW':
				rot = opposite ? 135 : -45;
				break;
			case 'FR':
			case 'NE':
				rot = opposite ? 225 : 45;
				break;
			case 'BR':
			case 'SW':
				rot = opposite ? -45 : 135;
				break;
			case 'BL':
			case 'SE':
				rot = opposite ? 45 : 225;
				break;
		}
		return rot;
	}

	////////////////////////////////
	shuffleArray(array) {
		var currentIndex = array.length,
			temporaryValue,
			randomIndex;
		while (0 !== currentIndex) {
			randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex -= 1;
			temporaryValue = array[currentIndex];
			array[currentIndex] = array[randomIndex];
			array[randomIndex] = temporaryValue;
		}

		return array;
	}

	//////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////  CHARACTER METHODS

	////////////////////////////////
	characterLoad(instructions) {
		this.resetAnimation();
		this.addClass(this.character.nativeElement, 'on');

		setTimeout(() => {
			let charPoseWidth = this.charPose.nativeElement.offsetWidth;
			this.charFloor.nativeElement.style.width = Math.max(520, charPoseWidth) + 'px';
			this.charFoam.nativeElement.style.width = Math.max(260, charPoseWidth / 2) + 'px';
			this.charShadow.nativeElement.style.width = Math.max(520, charPoseWidth) + 'px';

			this.addClass(this.charFloor.nativeElement, 'on');

			let nameToInspect =
				(instructions && instructions.animation_name && instructions.animation_name.toLowerCase()) ||
				(instructions && instructions.exerciseName && instructions.exerciseName.toLowerCase()) ||
				'instructions-missing'; // exerciseName if Balance
			if (nameToInspect.indexOf('foam') == -1) this.removeClass(this.charFoam.nativeElement, 'on');
			//this.charFoam.nativeElement.classList.remove('on');
			else this.addClass(this.charFoam.nativeElement, 'on'); //this.charFoam.nativeElement.classList.add('on');
		}, 2000);
	}

	////////////////////////////////
	characterSetPose(poseID: string, cumulative: boolean) {
		if (!this.poseDeets[poseID]) console.log('%cPose details missing for:', 'color:red', poseID);

		let character = document.querySelector('#character-pose') as HTMLImageElement;

		if (cumulative) character.setAttribute('data-vert', (parseInt(character.getAttribute('data-vert')) || 0) + (this.poseDeets[poseID].vert || 0));
		else character.setAttribute('data-vert', this.poseDeets[poseID].vert || 0);

		if (cumulative) character.setAttribute('data-horz', (parseInt(character.getAttribute('data-horz')) || 0) + (this.poseDeets[poseID].horz || 0));
		else character.setAttribute('data-horz', this.poseDeets[poseID].horz || 0);

		//character.style.transform = "translateX(calc(-50% + " + (character.getAttribute('data-horz') || 0) + "%)) translateY(calc(0% + " + (character.getAttribute('data-vert') || 0) + "%))";
		character.style.transform =
			'translateX(-50%) translateX(' +
			(character.getAttribute('data-horz') || 0) +
			'%) translateY(0%) translateY(' +
			(character.getAttribute('data-vert') || 0) +
			'%)';
		//this.charShadow.nativeElement.style.transform = "translateX(calc(-50% + " + (character.getAttribute('data-horz') || 0) + "%)) translateY(0%)";
		this.charShadow.nativeElement.style.transform =
			'translateX(-50%) translateX(' + (character.getAttribute('data-horz') || 0) + '%) translateY(0%)';

		character.src = this.getImageSourceFromCharacterPose(poseID);
	}

	////////////////////////////////////
	getImageSourceFromCharacterPose(poseID) {
		let pose = (typeof this.poseDeets[poseID] == 'string' && this.poseDeets[poseID]) || this.poseDeets[poseID].pose;
		return 'assets/animations/images/characters/' + pose + '.png';
	}

	//////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////  CHARACTER FEET METHODS

	////////////////////////////////
	footLoad() {
		this.resetAnimation();
		this.addClass(this.feetbounds.nativeElement, 'on');
		this.floorFootLeft.nativeElement.style.height = this.dartboard.nativeElement.offsetHeight / 7 + 'px';
		this.floorFootRight.nativeElement.style.height = this.dartboard.nativeElement.offsetHeight / 7 + 'px';
		this.addClass(this.floorFootLeft.nativeElement, 'on');
		this.addClass(this.floorFootRight.nativeElement, 'on');
		this.floorFootLeft.nativeElement.setAttribute('data-transform', '{ "x":0, "y":0, "rot":0 }');
		this.floorFootRight.nativeElement.setAttribute('data-transform', '{ "x":0, "y":0, "rot":0 }');
	}

	////////////////////////////////
	floorFootMoveFoot(instr) {
		let w = this.floorFootLeft.nativeElement.offsetWidth;
		let h = this.floorFootLeft.nativeElement.offsetHeight;
		let newX = w * instr.x;
		let newY = h * instr.y;
		let newRot = instr.rotate;
		let foot = (instr.foot == 'left' && this.floorFootLeft) || this.floorFootRight;
		if (instr.pos == 'absolute') {
			let transX: string;
			if (instr.foot == 'left') transX = -1 * w + newX + 'px';
			else transX = newX + 'px';
			let transform = 'translate(' + transX + ', ' + (-0.5 * h + newY) + 'px) rotate(' + instr.rotate + 'deg)';
			foot.nativeElement.style.transform = transform;
			foot.nativeElement.setAttribute('data-transform', '{ "x":' + newX + ', "y":' + newY + ', "rot":' + newRot + ' }');
		} else {
			let trans = JSON.parse(foot.nativeElement.getAttribute('data-transform'));
			let curX = trans.x;
			let curY = trans.y;
			let curRot = trans.rot;
			let transform;
			if (instr.foot == 'left')
				transform = 'translate(' + (-1 * w + curX + newX) + 'px, ' + (-0.5 * h + curY + newY) + 'px) rotate(' + (curRot + newRot) + 'deg)';
			else transform = 'translate(' + (curX + newX) + 'px, ' + (-0.5 * h + curY + newY) + 'px) rotate(' + (curRot + newRot) + 'deg)';
			foot.nativeElement.style.transform = transform;
			foot.nativeElement.setAttribute(
				'data-transform',
				'{ "x":' + (curX + newX) + ', "y":' + (curY + newY) + ', "rot":' + (curRot + newRot) + ' }'
			);
		}
	}

	//////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////  DARTBOARD METHODS

	////////////////////////////////
	dartboardLoad() {
		this.resetAnimation();
		this.addClass(this.dartboard.nativeElement, 'on');

		this.footLeft.nativeElement.style.height = this.dartboard.nativeElement.offsetHeight / 5.5 + 'px';
		this.footRight.nativeElement.style.height = this.dartboard.nativeElement.offsetHeight / 5.5 + 'px';

		// Lock the dartboard height programatically to stop height change issues, plus make it no bigger than the width
		let DBHeight = this.dartboard.nativeElement.offsetHeight;
		let DBWidth = this.dartboard.nativeElement.offsetWidth;
		if (DBHeight > DBWidth * 0.9) DBHeight = DBWidth * 0.9;
		this.dartboard.nativeElement.style.height = DBHeight + 'px';

		// Prepare all the segments by manually setting their width
		let height = this.dartboard.nativeElement.offsetHeight;

		Array.prototype.forEach.call(document.querySelectorAll('.dartseg-inner'), (segment) => {
			segment.style.width = height * 0.25 * 1.39692744 + 'px';
		});
		Array.prototype.forEach.call(document.querySelectorAll('.dartseg-outer'), (segment) => {
			segment.style.width = height * 0.4 * 1.39692744 + 'px';
		});

		// Setup icons if necessary
		if (this.animationSequence.original.cue.indexOf('Symbol') != -1 || this.animationSequence.original.cue.indexOf('Shapes') != -1) {
			let justOuterRing = this.animationSequence.original.step.indexOf('Short') == -1;
			this.dartboardSetupIcons('symbols', justOuterRing);
		}
		if (this.animationSequence.original.cue.indexOf('Footprints') != -1) {
			let justOuterRing = this.animationSequence.original.step.indexOf('Short') == -1;
			this.dartboardSetupIcons('fp', justOuterRing);
		}
	}

	////////////////////////////////
	getDartSegmentElement(segmentID: string) {
		return document.querySelector('#' + segmentID);
	}

	////////////////////////////////
	getDartboardSegmentCenter(segmentID: string, isForIcon: boolean = false) {
		let segment = document.querySelector('#' + segmentID);
		let rect = segment.getBoundingClientRect();

		if (isForIcon && segmentID == 'dartcircle') segmentID = 'dartcircle_icon';
		let varnc = this.segmentPositionVariances[segmentID];

		let left = rect.left + (rect.right - rect.left) * varnc.x;
		let top = rect.top + (rect.bottom - rect.top) * varnc.y;

		let containerPos = this.getContainerPos();
		left = left - containerPos.left;
		top = top - containerPos.top;

		return { left: left, top: top };
	}

	////////////////////////////////
	placeIconOnDartboard(segmentName: string, icon: string, rotation = 0) {
		let segmentID = this.segmentLookup[segmentName];
		let icoEl;

		let icoTemplate = document.querySelector('#ico-' + icon);
		icoEl = icoTemplate.cloneNode(true) as HTMLElement;
		let iconCont = document.querySelector('.icon_vis');
		iconCont.appendChild(icoEl);

		let pos = this.getDartboardSegmentCenter(segmentID, true);
		icoEl.style.left = pos.left + 'px';
		icoEl.style.top = pos.top + 'px';
		icoEl.style.transform = 'translate(-50%, -50%) rotate(' + rotation + 'deg)';
		this.addClass(icoEl, 'on');
		icoEl.setAttribute('data-pos', segmentName);
		return true;
	}

	////////////////////////////////
	highlightDartSegment(segmentName: string) {
		let element = this.getDartSegmentElement(this.segmentLookup[segmentName]);
		this.addClass(element, 'on');
		this.highlightedElements.push(element);
	}

	////////////////////////////////
	dartboardMoveFoot(foot: string, segmentName: string, highlight: boolean = false, playSound = false) {
		this.unhighlightAll();
		let segmentID = this.segmentLookup[segmentName];

		let pos = this.getDartboardSegmentCenter(segmentID);
		if (foot == 'left') pos.left = pos.left;
		else pos.left = pos.left + 10;

		if (foot == 'left' || foot == 'both') {
			this.addClass(this.footLeft.nativeElement, 'on');
			this.footLeft.nativeElement.style.left = pos.left + 'px';
			this.footLeft.nativeElement.style.top = pos.top + 10 + 'px';
		}
		if (foot == 'right' || foot == 'both') {
			this.addClass(this.footRight.nativeElement, 'on');
			this.footRight.nativeElement.style.left = pos.left + 'px';
			this.footRight.nativeElement.style.top = pos.top + 10 + 'px';
		}

		if (highlight) this.highlightDartSegment(segmentName);
		if (playSound) this.playBeep();
	}

	////////////////////////////////
	dartboardAdjustFoot(params: any) {
		let foot = (params.isLeftFoot && this.footLeft) || this.footRight;
		foot.nativeElement.setAttribute('data-stance', params.stance || 'flat');
		foot.nativeElement.style.opacity = params.opacity || '1';
	}

	//////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////  GRID METHODS

	////////////////////////////////
	gridLoad() {
		this.resetAnimation();
		this.addClass(this.grid.nativeElement, 'on');

		// Resize grid squares
		let height = this.animationContainer.nativeElement.offsetHeight;
		if (this.animationContainer.nativeElement.offsetWidth < height) height = this.animationContainer.nativeElement.offsetWidth;
		let heightString = height / 5 + 'px';
		Array.prototype.forEach.call(this.grid.nativeElement.querySelectorAll('.gridsquare'), (el) => {
			el.style.height = heightString;
			el.style.width = heightString;
		});

		this.footLeft.nativeElement.style.opacity = '';
		this.footRight.nativeElement.style.opacity = '';

		this.gridIcoTarget1.nativeElement.style.height = height / 7 + 'px';
		this.gridIcoTarget2.nativeElement.style.height = height / 7 + 'px';
		this.gridIcoTarget3.nativeElement.style.height = height / 7 + 'px';
	}

	////////////////////////////////
	getGridElement(gridIndex: string) {
		return document.querySelector('#grid_' + gridIndex);
	}

	////////////////////////////////
	getGridCenter(gridIndex: string) {
		let segment = this.getGridElement(gridIndex);
		let rect = segment.getBoundingClientRect();

		let left = rect.right - (rect.right - rect.left) / 2;
		let top = rect.bottom - (rect.bottom - rect.top) / 2;

		let containerPos = this.getContainerPos();
		left = left - containerPos.left;
		top = top - containerPos.top;

		return { left: left, top: top };
	}

	////////////////////////////////
	highlightGrid(gridIndex: string, number: number = 0) {
		let element = this.getGridElement(gridIndex);
		this.addClass(element, 'on');

		if (number) {
			this.addClass(element, 'numbered');
			(element.querySelector('.number') as HTMLElement).innerText = number > 0 ? number + '' : '';
		}

		this.highlightedElements.push(element);
	}

	////////////////////////////////
	gridMoveFoot(foot: string, gridIndex: string, highlight: boolean = false, playSound = false) {
		this.unhighlightAll();
		let pos = this.getGridCenter(gridIndex);

		if (foot == 'left' || foot == 'both') {
			this.addClass(this.footLeft.nativeElement, 'on');
			this.footLeft.nativeElement.style.left = pos.left + 'px';
			this.footLeft.nativeElement.style.top = pos.top + 'px';
		}
		if (foot == 'right' || foot == 'both') {
			this.addClass(this.footRight.nativeElement, 'on');
			this.footRight.nativeElement.style.left = pos.left + 'px';
			this.footRight.nativeElement.style.top = pos.top + 'px';
		}

		if (highlight) this.highlightGrid(gridIndex);
		if (playSound) this.playBeep();
	}

	//////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////  BOX METHODS

	////////////////////////////////
	boxLoad() {
		this.resetAnimation();
		this.addClass(this.box.nativeElement, 'on');

		let height = this.animationContainer.nativeElement.offsetHeight;
		let boxHeight = this.box.nativeElement.offsetHeight;
		if (boxHeight > height / 2) this.box.nativeElement.style.width = height / 2 + 'px';
	}

	////////////////////////////////
	getBoxElement(boxIndex: string) {
		return document.querySelector('#' + boxIndex);
	}

	////////////////////////////////
	getBoxElementCenter(boxIndex: string) {
		let boxEl = this.getBoxElement(boxIndex);
		let rect = boxEl.getBoundingClientRect();

		let left = rect.right - (rect.right - rect.left) / 2;
		let top = rect.bottom - (rect.bottom - rect.top) / 2;

		let containerPos = this.getContainerPos();
		left = left - containerPos.left;
		top = top - containerPos.top;

		return { left: left, top: top };
	}

	////////////////////////////////
	highlightBoxEl(boxIndex: string) {
		let element = this.getBoxElement(boxIndex);
		this.addClass(element, 'on');
		this.highlightedElements.push(element);
	}

	////////////////////////////////
	boxMoveFoot(foot: string, stance: string, boxIndex: string, rotate: number = null, highlight: boolean = false, playSound = false) {
		this.unhighlightAll();
		let pos = this.getBoxElementCenter(boxIndex);

		if (foot == 'left' || foot == 'both') {
			this.addClass(this.footLeft.nativeElement, 'on');
			this.footLeft.nativeElement.style.left = pos.left + 'px';
			this.footLeft.nativeElement.style.top = pos.top + 'px';
			this.footLeft.nativeElement.setAttribute('data-stance', stance);
			if (typeof rotate == 'number') {
				if (rotate != 0) this.footLeft.nativeElement.setAttribute('data-rotate', 'degree' + rotate);
				else this.footLeft.nativeElement.removeAttribute('data-rotate');
				this.footLeftForward.nativeElement.style.transform = 'rotate(' + rotate + 'deg)';
				this.footLeftFlat.nativeElement.style.transform = 'rotate(' + rotate + 'deg)';
			}
		}
		if (foot == 'right' || foot == 'both') {
			this.addClass(this.footRight.nativeElement, 'on');
			this.footRight.nativeElement.style.left = pos.left + 'px';
			this.footRight.nativeElement.style.top = pos.top + 'px';
			this.footRight.nativeElement.setAttribute('data-stance', stance);
			if (typeof rotate == 'number') {
				if (rotate != 0) this.footRight.nativeElement.setAttribute('data-rotate', 'degree' + rotate);
				else this.footRight.nativeElement.removeAttribute('data-rotate');
			}
			this.footRightForward.nativeElement.style.transform = 'rotate(' + rotate + 'deg)';
			this.footRightFlat.nativeElement.style.transform = 'rotate(' + rotate + 'deg)';
		}

		if (highlight) this.highlightBoxEl(boxIndex);
		if (playSound) this.playBeep();
	}

	////////////////////////////////////
	preloadCognitiveIfRequired() {
		console.log('%c >> Preloading cognitive images:', 'color:purple;', this.cognitiveComponent.preloadableImages);
		if (this.cognitiveComponent && this.cognitiveComponent.preloadableImages.length) {
			this.cognitiveComponent.preloadableImages.forEach((i) => {
				var img = new Image();
				img.src = 'assets/animations/svg/' + i + '.svg';
			});
		}
	}

	////////////////////////////////////
	playBeep() {
		/*if (this.isBrowser()) {
			this.audioEl.nativeElement.src = "/assets/animations/audio/beep.wav";
			this.audioEl.nativeElement.play()
			.then(res => console.log("%c ---- Played beep", "color:deeppink;"))
			.catch(err => console.log("Failed beep", err))
		} else {
			this.audioBeepMedia.play(); //@Jayden this gets called for beep on android on Start button press
			console.log("%c ---- Played beep", "color:deeppink;")
		}*/
		this.audioController.playAudio('beep');
	}

	////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////// ANIMATION DEFINITION METHODS
	animationSequence: any;

	////////////////////////////////
	setupAnimation(instructions, width, height, exerciseIterations) {
		this.preloadableImages = [];
		let resolver = function (resolve, reject) {
			// Setup a console group
			console.groupCollapsed('+ Animation Instructions');

			this.getSequence(instructions).then((seq: any) => {
				let createdCognitive = null;

				// Load the correct board type
				switch (seq.type) {
					case 'floor':
					case 'balance_assessment':
						this.characterLoad(instructions);
						break;
					case 'floor_feet':
						this.footLoad();
						break;
					case 'dart':
						this.dartboardLoad();
						break;
					case 'grid':
						this.gridLoad();
						break;
					case 'box':
					case 'box_newtiming':
						this.boxLoad();
						break;
					case 'cardio':
						console.log('setupAnimation() sequence:', seq);
						break;
				}

				// If there is a cognitive component, do that thing
				if (instructions.cognitive) {
					if (!this.cognitiveComponent) this.cognitiveComponent = new CognitiveCreator();
					createdCognitive = this.cognitiveComponent.createCognitiveComponent(instructions, exerciseIterations == 3);
				}

				// Setup the scheduler;
				let scheduler = this.createScheduledAnims(seq, createdCognitive);

				// Set some debugs
				console.log('%c>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', 'color:purple');
				console.log('%c>>>>>>>>>>>> Animation details:', 'color:purple');
				console.log('%c >> Original instructions', 'color:purple', instructions);
				console.log('%c >> Original instructions (text)', 'color:purple', JSON.stringify(instructions));
				console.log('%c >> Animation Sequence', 'color:purple', this.animationSequence);
				if (instructions.cognitive) {
					console.log('%c >> Cognitive Sequence', 'color:purple', createdCognitive);
					console.log('%c >> Cognitive Feedback Prompt(s)', 'color:purple', this.cognitiveComponent.feedbackPrompt);
					console.log(
						'%c >> Cognitive Chosen & Distractor Prompt(s)',
						'color:purple',
						this.cognitiveComponent.chosenPrompt,
						this.cognitiveComponent.distractorPrompt
					);
					this.preloadCognitiveIfRequired();
					this.audioController.setupCognitivePlayers(this.cognitiveComponent.audioItems);
				}
				console.log('%c>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', 'color:purple');
				this.audioController.setupBeepPlayer();
				this.audioController.setupDuhDingPlayer();
				this.audioController.preloadAudio();
				this.preloadCharacterPoseImagesIfNecessary();
				console.log('%c>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', 'color:purple');

				console.log('Got the animation sequence', this.animationSequence);
				console.log('And the preloadable images', this.preloadableImages);

				resolve(scheduler);

				// Finalise the group
				console.groupEnd();
			});
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////
	clearAnimation() {
		if (this.animSchedule) this.animSchedule.stop();
	}

	////////////////////////////////
	public getSequence(instructions) {
		let resolver = function (resolve, reject) {
			this.limiter2 = 0;
			this.animationSequence = { sequence: [], initialState: [] };
			this.defineType(instructions)
				.then(this.defineCoreCharacteristics.bind(this))
				.then(this.createSequenceBasedOnType.bind(this)(instructions))
				.then(resolve)
				.catch((err) => {
					console.log('Error:', err);
					this.error.nativeElement.innerText = err;
					this.addClass(this.error.nativeElement, 'on'); //this.error.nativeElement.classList.add('on')
				});
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////
	defineCoreCharacteristics(instructions) {
		this.animationSequence.reps = parseInt(instructions.reps);

		if (Array.isArray(instructions.tempo) && instructions.tempo.length > 1) {
			instructions.tempo.map((tmp) => {
				return parseInt(tmp);
			});
		} else {
			instructions.tempo = parseInt(instructions.tempo);
		}
		this.animationSequence.tempo = instructions.tempo;

		return Promise.resolve(instructions);
	}

	////////////////////////////////
	private defineType(instructions): Promise<any> {
		let isFloor = () =>
			((instructions['mode'] == 'static_duration' || instructions['mode'] == 'dynamic_tempo') && instructions.camera_view != 'Feet') ||
			instructions.camera_view == 'Body';
		let isFloorFeet = () =>
			((instructions['mode'] == 'static_duration' || instructions['mode'] == 'dynamic_tempo') && instructions.camera_view == 'Feet') ||
			instructions.camera_view == 'Feet';

		switch (true) {
			case instructions.type == 'balance_assessment':
				this.animationSequence.type = 'balance_assessment';
				break;

			case instructions.timing == 'tempo+duration' && isFloor():
			case instructions.timing == 'tempo+reps' && isFloor():
				this.animationSequence.type = 'floor';
				break;

			case instructions.timing == 'tempo+duration' && isFloorFeet():
			case instructions.timing == 'tempo+reps' && isFloorFeet():
				this.animationSequence.type = 'floor_feet';
				break;

			case instructions.timing == 'tempo+duration' && instructions.mode == 'box':
			case instructions.timing == 'tempo+reps' && instructions.mode == 'box':
				this.animationSequence.type = 'box_newtiming';
				break;

			case isFloor():
				this.animationSequence.type = 'floor';
				break;

			case isFloorFeet():
				this.animationSequence.type = 'floor_feet';
				break;

			default:
				this.animationSequence.type = instructions.mode;
		}

		return Promise.resolve(instructions);
	}

	////////////////////////////////
	createSequenceBasedOnType(instructions) {
		let handler;
		switch (this.animationSequence.type) {
			case 'floor':
				handler = this.createFloorSequence.bind(this);
				break;
			case 'floor_feet':
				handler = this.createFloorFeetSequence.bind(this);
				break;
			case 'dart':
				handler = this.createDartboardSequence.bind(this);
				break;
			case 'grid':
				handler = this.createGridSequence.bind(this);
				break;
			case 'box':
				handler = this.createBoxSequence.bind(this);
				break;
			case 'box_newtiming':
				handler = this.createBoxNewTimingSequence.bind(this);
				break;
			case 'balance_assessment':
				handler = this.createBalanceAssessementSequence.bind(this);
				this.animationSequence.reps = 1; // Setup reps and tempo to mimic regular instructions
				this.animationSequence.tempo = [1];
				break;
		}

		return handler;
	}

	////////////////////////////////
	private createFloorSequence(instructions) {
		console.log('%c >>>>>>>>>>>>> CREATING NEW FLOOR SEQUENCE', 'color:red');
		this.animationSequence.sequence = [];
		this.animationSequence.initialState.push({ type: 'floor', animation: instructions.start_state_animation, durationInSecs: 0 });

		// Instantiation
		let sequences: any[] = [];
		let tempSequences: any[] = [];
		let cumulativeAdjustments = this.isFloorCumulativeAdjustments(instructions);

		// Static Duration Mode
		if (instructions.mode == 'static_duration') {
			let beat = 60 / this.animationSequence.tempo;
			let statDur = 60 / this.animationSequence.tempo;
			let animTime = 0;
			let seqIndex = 0;
			let firstSequence = true;
			while (animTime < statDur) {
				let seq = instructions.floorSequence[seqIndex];
				let durationInSecs;

				// static_duration exercises have a (usually) single frame floorSequence set to a ridiculous tempo (creates a huge duration so that it is the only frame).  However we need to move to it so I set and use firstSequence
				if (firstSequence) {
					firstSequence = false;
					durationInSecs = 1;
				} else {
					durationInSecs = 60 / seq.tempo;
				}
				let sequence = {
					type: this.animationSequence.type,
					animation: seq.animation,
					durationInSecs: durationInSecs * seq.beats,
					cumulative: cumulativeAdjustments,
					beep: seq.suppress_beep ? false : true,
				};
				let addition = this.getAnimationDetailsFromAnimationName(seq);
				sequence = Object.assign(sequence, addition);
				tempSequences.push(sequence);
				animTime += durationInSecs * seq.beats;
				seqIndex = seqIndex + 1;
				if (seqIndex > instructions.floorSequence.length - 1) seqIndex = 0;

				if (this.preloadableImages.indexOf(seq.animation) == -1) this.preloadableImages.push(seq.animation);
			}
		}

		// Dynamic Tempo mode
		else if (instructions.mode == 'dynamic_tempo') {
			let beat = 60 / this.animationSequence.tempo;
			instructions.floorSequence.forEach((seq: any) => {
				for (let x = 0; x < seq.multiplier; x++) {
					let sequence = {
						type: this.animationSequence.type,
						animation: seq.animation,
						durationInSecs: beat * seq.beats,
						cumulative: cumulativeAdjustments,
						beep: seq.suppress_beep ? false : true,
					};
					let addition = this.getAnimationDetailsFromAnimationName(seq);
					sequence = Object.assign(sequence, addition);
					tempSequences.push(sequence);

					if (this.preloadableImages.indexOf(seq.animation) == -1) this.preloadableImages.push(seq.animation);
				}
			});
		}

		// tempo+duration - March 2022, new mode for more generic timing based on tempo and duration
		// In this mode, we do the entire sequence in floorSequence to the speed of the tempo
		else if (instructions.timing == 'tempo+duration') {
			let duration = instructions.duration;
			let beat = 60 / this.animationSequence.tempo / instructions.floorSequence.length;
			while (duration > 0) {
				instructions.floorSequence.forEach((seq: any) => {
					let sequence = {
						type: this.animationSequence.type,
						animation: seq.animation,
						durationInSecs: beat * seq.beats,
						beep: seq.suppress_beep ? false : true,
					};
					let addition = this.getAnimationDetailsFromAnimationName(seq);
					sequence = Object.assign(sequence, addition);
					tempSequences.push(sequence);

					if (this.preloadableImages.indexOf(seq.animation) == -1) this.preloadableImages.push(seq.animation);
					duration -= beat;
				});
			}
		}

		// tempo+duration - March 2022, new mode for more generic timing based on tempo and reps
		// In this mode, we do the sequence in floorSequence [reps] times at the speed of the tempo
		else if (instructions.timing == 'tempo+reps') {
			let beat = 60 / this.animationSequence.tempo / instructions.floorSequence.length;

			instructions.floorSequence.forEach((seq: any) => {
				let sequence = {
					type: this.animationSequence.type,
					animation: seq.animation,
					durationInSecs: beat * seq.beats,
					beep: seq.suppress_beep ? false : true,
				};
				let addition = this.getAnimationDetailsFromAnimationName(seq);
				sequence = Object.assign(sequence, addition);
				tempSequences.push(sequence);

				if (this.preloadableImages.indexOf(seq.animation) == -1) this.preloadableImages.push(seq.animation);
			});
		}

		// Repeat these for "reps" times
		let reps = parseInt(instructions.reps);
		if (isNaN(reps)) reps = 1;
		for (let x = 0; x < reps; x++) {
			tempSequences.forEach((seq) => sequences.push(seq));
			sequences.push(new RepEvent());
		}
		this.animationSequence.sequence = this.animationSequence.sequence.concat(sequences);

		// Return resolved data
		return Promise.resolve(this.animationSequence);
	}

	////////////////////////////////
	createFloorFeetSequence(instructions) {
		console.log('%c >>>>>>>>>>>>> CREATING NEW FLOOR FEET SEQUENCE', 'color:red');
		this.animationSequence.sequence = [];

		let sequences: any[] = [];

		// Switch based on the type
		// tempo+duration - March 2022, new mode for more generic timing based on tempo and duration
		// In this mode, we do the entire sequence in floorSequence to the speed of the tempo
		if (instructions.timing == 'tempo+duration') {
			let duration = instructions.duration;
			let feetFart = new FeetFootFeatFortFart();
			let tempSequences: any = feetFart.createFootSequence(instructions, 0); // 0 given as we will rewrite these times
			let beat = 60 / this.animationSequence.tempo / tempSequences.seq.length;

			// Repeat these for the duration
			let x = 0;
			while (duration > 0 && x < 100) {
				tempSequences.seq.forEach((seq) => {
					seq.durationInSecs = beat;
					sequences.push(seq);
					duration -= beat;
				});
				sequences.push(new RepEvent());
				x = x + 1;
			}
			this.animationSequence.sequence = this.animationSequence.sequence.concat(sequences);

			// Setup the initialState
			this.animationSequence.initialState = tempSequences.init;
		}

		// Regular static_duratoin | dynamic_tempo
		else {
			let beat = 60 / this.animationSequence.tempo;

			// Create a temp sequence
			let feetFart = new FeetFootFeatFortFart();
			let tempSequences: any = feetFart.createFootSequence(instructions, beat);

			// Repeat these for "reps" times
			for (let x = 0; x < parseInt(instructions.reps); x++) {
				tempSequences.seq.forEach((seq) => sequences.push(seq));
				sequences.push(new RepEvent());
			}
			this.animationSequence.sequence = this.animationSequence.sequence.concat(sequences);

			// Setup the initialState
			this.animationSequence.initialState = tempSequences.init;
		}

		return Promise.resolve(this.animationSequence);
	}

	////////////////////////////////
	private createDartboardSequence(origInstructions) {
		console.log('%c >>>>>>>>>>>>> CREATING NEW DARTBOARD SEQUENCE', 'color:red');
		// Emma confirmed - dartboard is always randomised
		// Emma confirmed that EVERY movement on EVERY dartboard goes out, then also back to center, each with their own beep / beat.  Each out-and-in is one rep.

		// NEW UPDATES
		// Mixed cue in single task is Shade or Symbol randomised.  Simply show the symbol or the shade, on the segment that the foot should appear in
		// Mixed cue in dual task is Arrows, Compass.
		// When Dual task, don't render feet.
		// When Dual task, use instructions from Dual, not base instructions (where dual contains property).  Choose one (randomly) for the entire exercise.
		// Display_Time; show arrow / icon / compass whatever for that time.  Beep on beep.
		// # of cues means how many to preload the user with before animating
		// When I see # cues, 1 rep = 1 step (and return) as normal, not all cue numbers combined -- jD new note: this was never implemented
		// Arrows -> Breaking pattern -- WILL BE RENAMED TO Change Colour and Different Colour. use Grid rules for this.

		// NEW UPDATES / Clarifications v2
		// WHENEVER DUAL, LOOK UP cog_mode:
		// Simple:: cue: Arrows, Compass, Mixed (randomly choose 1 each set) -- IN ORDER: show cue, remove cue after disp_time, beep after beat, beep after beat, wait a beat, repeat
		// Visual Search:: cue: Shapes, Footprints, Mixed (randomly choose 1 each set) -- Present unique icons on outside (only) segments -- IN ORDER: show cue, remove cue after disp_time, beep after beat, beep after beat, wait a beat, repeat
		// Forward Span:: cue: Arrows, Compass, Mixed (random choose 1 each set) -- ((show cue for disp_time, then hide for disp_time) * n_cues (random cue each time)), then (beep on beat, beep on beat, wait one beat) -- repeat .duration times
		// Backward Span:: EXACTLY AS ABOVE
		// Breaking Pattern - Different Colour:: Arrows (1 green, 1 purple) -- pick a cue: (green or purple), show cue, beep after beat, remove cue after disp_time, beep after beat, repeat
		// Breaking Pattern - Change Colour:: Arrows (1 green, 1 purple) -- choose if going to fakeout, show cue, change cue if fakeout at half beat time, beep after beat, remove cue after disp_time, beep after beat, repeat

		////////////////////////////////
		let instructions = JSON.parse(JSON.stringify(origInstructions));

		////////////////////////////////
		this.animationSequence.start_position = { type: 'dart_together' };
		this.animationSequence.original = instructions;
		this.animationSequence.sequence = [];
		this.animationSequence.initialState.push({
			type: 'dart',
			animation: 'Step',
			durationInSecs: 0,
			rotation: 0,
			isLeftFoot: true,
			dir: 'inner',
			cue: 'none',
			stance: 'flat',
			opacity: '0',
		});
		this.animationSequence.initialState.push({
			type: 'dart',
			animation: 'Step',
			durationInSecs: 0,
			rotation: 0,
			isLeftFoot: false,
			dir: 'inner',
			cue: 'none',
			stance: 'flat',
			opacity: '0',
		});

		////////////////////////////////
		let dirs = (Array.isArray(instructions.direction) && instructions.direction[0].split('/')) || instructions.direction.split('/');
		let dirAndFoot: any = { dir: null, isLeftFoot: null };
		let dirsAndFoots: any[] = [];
		let stepLength: string;
		let tempSequence: any[] = [];
		let icon;

		////////////////////////////////
		if (instructions.dual) {
			let dualProperty;
			let dualIndex = this.sExercise.getPrechosenDualIndex();
			if (dualIndex || dualIndex == 0) dualProperty = instructions.dual[dualIndex];
			else dualProperty = instructions.dual[Math.floor(Math.random() * instructions.dual.length)];

			// Now copy all the instructions over
			instructions = Object.assign(instructions, dualProperty);
			instructions.dual = true;

			//console.log(">>>>> UPDATED INSTRUCTIONS POST DUAL:", instructions, "WITH INDEX", dualIndex, "FROM DUAL PROPERTY", dualProperty);
		}

		////////////////////////////////
		if (!Array.isArray(instructions.cue)) instructions.cue = [instructions.cue];
		if (instructions.cue.indexOf('Mixed') != -1) {
			let index = instructions.cue.indexOf('Mixed');
			if (instructions.dual) instructions.cue.splice(index, 1, 'Arrows', 'Compass');
			else instructions.cue.splice(index, 1, 'Shade', 'Symbol');
		}

		// Reveal the feet if not dual
		if (!instructions.dual) {
			this.animationSequence.sequence.push({
				type: 'dart',
				animation: 'Step',
				durationInSecs: 0,
				rotation: 0,
				isLeftFoot: true,
				dir: 'inner',
				cue: 'none',
				stance: 'flat',
				opacity: '1',
			});
			this.animationSequence.sequence.push({
				type: 'dart',
				animation: 'Step',
				durationInSecs: 0,
				rotation: 0,
				isLeftFoot: false,
				dir: 'inner',
				cue: 'none',
				stance: 'flat',
				opacity: '1',
			});
		}

		// If this is a cog_mode of "Forward Span" or "Backward Span", we want to use "duration" as reps instead
		if (['Forward Span', 'Backward Span'].indexOf(instructions.cog_mode) != -1) {
			instructions.reps = instructions.duration;
		}

		//Create random colour cards to help enforce psuedo random
		let colorCards = new Array(instructions.reps).fill('green');
		let greenPercentage = 0.75;
		let purplePercentage = 1 - greenPercentage;
		let intendedNumberOfPurples = Math.round(instructions.reps * purplePercentage);
		let chunkSize = Math.round(instructions.reps / intendedNumberOfPurples);

		//Helper functions for random below
		function clamp(min: number, max: number, value: number): number {
			return Math.max(min, Math.min(value, max));
		}
		function randomInt(minInclusive: number, maxInclusive: number) {
			return Math.floor(Math.random() * (maxInclusive + 1 - minInclusive) + minInclusive);
		}

		//If were looking at a selective exercise, loop through reps
		if (instructions.dual && instructions.cog_mode == 'Selective') {
			for (let i = 0; i < instructions.reps; i += chunkSize) {
				//Find and cap min/mex
				let minValue = i;
				let maxValue = minValue + chunkSize - 1;
				if (maxValue > instructions.reps) maxValue = instructions.reps - 1;

				//Find random index between min/max and make it purple
				let randomIndex = clamp(minValue, maxValue, randomInt(minValue, maxValue));
				colorCards[randomIndex] = 'purple';
			}
		}

		////////////////////////////////////
		for (let x = 0; x < instructions.reps; x++) {
			let colour;
			let rando;
			let cue = instructions.cue[Math.floor(Math.random() * instructions.cue.length)];
			let cogMode = instructions.cog_mode;
			let beat = this.getBeatFromTempo(this.animationSequence.tempo);
			stepLength =
				(Array.isArray(instructions.step) && instructions.step[Math.floor(Math.random() * instructions.step.length)]) ||
				instructions.step ||
				'Short';

			// Move prime foot (and adjust subprime foot if needs be)
			// EMMA CONFIRMED (Slack call 28 Feb) THAT A SINGLE REP IS ONE FOOT OUT AND IN, NOT TWO FEET
			if (!instructions.dual) {
				dirAndFoot = this.getDirAndFootForDartboard(dirs, dirAndFoot.dir, dirsAndFoots);
				dirsAndFoots.push(dirAndFoot);

				// The outie movement
				tempSequence.push({
					type: 'dart',
					animation: 'Step',
					durationInSecs: beat,
					rotation: 0,
					isLeftFoot: dirAndFoot.isLeftFoot,
					dir: dirAndFoot.dir + '-' + stepLength,
					cue: cue,
					stance: 'flat',
					opacity: '1',
				});
				if (instructions.action == 'Step & Lift')
					tempSequence.push({ type: 'dart', animation: 'AdjustFoot', durationInSecs: 0, isLeftFoot: !dirAndFoot.isLeftFoot, opacity: '0' });
				if (instructions.action == 'Step & Rock')
					tempSequence.push({ type: 'dart', animation: 'AdjustFoot', durationInSecs: 0, isLeftFoot: !dirAndFoot.isLeftFoot, stance: 'forward' });

				// And the return movement
				tempSequence.push({
					type: 'dart',
					animation: 'Step',
					durationInSecs: beat,
					rotation: 0,
					isLeftFoot: dirAndFoot.isLeftFoot,
					dir: 'inner',
					cue: cue,
					stance: 'flat',
					opacity: '1',
				});
				if (instructions.action == 'Step & Lift')
					tempSequence.push({ type: 'dart', animation: 'AdjustFoot', durationInSecs: 0, isLeftFoot: !dirAndFoot.isLeftFoot, opacity: '1' });
				if (instructions.action == 'Step & Rock')
					tempSequence.push({ type: 'dart', animation: 'AdjustFoot', durationInSecs: 0, isLeftFoot: !dirAndFoot.isLeftFoot, stance: 'flat' });
			}

			// Dual mode - act based on cog_mode
			else {
				switch (cogMode) {
					// Simple
					case 'Simple':
						icon = cue == 'Arrows' ? 'arrow' : 'compass';
						dirAndFoot = this.getDirAndFootForDartboard(dirs, dirAndFoot.dir, dirsAndFoots);
						dirsAndFoots.push(dirAndFoot);
						tempSequence.push({ type: 'dart', animation: 'icon_middle', durationInSecs: 0, dir: dirAndFoot.dir, opacity: 1, icon: icon });
						tempSequence.push({
							type: 'dart',
							animation: 'icon_middle',
							durationInSecs: instructions.disp_time,
							dir: dirAndFoot.dir,
							opacity: 0,
							icon: icon,
						});
						tempSequence.push({ type: 'general', animation: 'beep', durationInSecs: beat });
						tempSequence.push({ type: 'general', animation: 'beep', durationInSecs: beat });
						tempSequence.push({ type: 'general', animation: 'nothing', durationInSecs: beat });
						break;

					// Visual Search
					case 'Visual Search':
						let distance = instructions.step[Math.floor(Math.random() * instructions.step.length)];
						dirs = instructions.direction.split('/');
						let dir = dirs[Math.floor(Math.random() * dirs.length)];
						tempSequence.push({
							type: 'dart',
							animation: 'icon_middle',
							durationInSecs: 0,
							iconFromDir: dir + '-' + distance,
							opacity: 1,
							dir: 'N',
						});
						tempSequence.push({
							type: 'dart',
							animation: 'icon_middle',
							durationInSecs: instructions.disp_time,
							iconFromDir: dir + '-' + distance,
							opacity: 0,
							dir: 'N',
						});
						tempSequence.push({ type: 'general', animation: 'beep', durationInSecs: beat });
						tempSequence.push({ type: 'general', animation: 'beep', durationInSecs: beat });
						tempSequence.push({ type: 'general', animation: 'nothing', durationInSecs: beat });
						break;

					// Forward and Backward Span
					case 'Forward Span':
					case 'Backward Span':
						icon = cue == 'Arrows' ? 'arrow' : 'compass';
						for (let x = 0; x < instructions.n_cues; x++) {
							dirAndFoot = this.getDirAndFootForDartboard(dirs, dirAndFoot.dir, dirsAndFoots);
							dirsAndFoots.push(dirAndFoot);
							tempSequence.push({ type: 'dart', animation: 'icon_middle', durationInSecs: 0, dir: dirAndFoot.dir, opacity: 1, icon: icon });
							tempSequence.push({
								type: 'dart',
								animation: 'icon_middle',
								durationInSecs: instructions.disp_time,
								dir: dirAndFoot.dir,
								opacity: 0,
								icon: icon,
							});
							tempSequence.push({ type: 'general', animation: 'nothing', durationInSecs: instructions.disp_time });
						}
						for (let x = 0; x < instructions.n_cues; x++) {
							tempSequence.push({ type: 'general', animation: 'beep', durationInSecs: beat });
							tempSequence.push({ type: 'general', animation: 'beep', durationInSecs: beat });
						}
						tempSequence.push({ type: 'general', animation: 'nothing', durationInSecs: beat });
						break;

					// Breaking Pattern: Different Colour
					case 'Selective':
						if (colorCards[x] != null) colour = colorCards[x];
						else colour = (Math.random() > greenPercentage && 'purple') || 'green';

						dirAndFoot = this.getDirAndFootForDartboard(dirs, dirAndFoot.dir, dirsAndFoots);
						dirsAndFoots.push(dirAndFoot);
						if (instructions.disp_time > beat) {
							tempSequence.push({
								type: 'dart',
								animation: 'icon_middle',
								durationInSecs: 0,
								dir: dirAndFoot.dir,
								opacity: 1,
								icon: 'arrow',
								colour: colour,
							});
							tempSequence.push({ type: 'general', animation: 'beep', durationInSecs: beat });
							tempSequence.push({
								type: 'dart',
								animation: 'icon_middle',
								durationInSecs: instructions.disp_time - beat,
								dir: dirAndFoot.dir,
								opacity: 0,
								icon: 'arrow',
							});
							tempSequence.push({ type: 'general', animation: 'beep', durationInSecs: beat * 2 - instructions.disp_time });
						} else {
							tempSequence.push({
								type: 'dart',
								animation: 'icon_middle',
								durationInSecs: 0,
								dir: dirAndFoot.dir,
								opacity: 1,
								icon: 'arrow',
								colour: colour,
							});
							tempSequence.push({
								type: 'dart',
								animation: 'icon_middle',
								durationInSecs: instructions.disp_time,
								dir: dirAndFoot.dir,
								opacity: 0,
								icon: 'arrow',
							});
							tempSequence.push({ type: 'general', animation: 'beep', durationInSecs: beat - instructions.disp_time });
							tempSequence.push({ type: 'general', animation: 'beep', durationInSecs: beat * 2 - instructions.disp_time });
						}
						tempSequence.push({ type: 'general', animation: 'nothing', durationInSecs: beat / 4 });
						break;

					// Breaking Pattern: Change Colour
					case 'Inhibition':
						rando = Math.random();
						let fakeout = rando > 0.75;
						dirAndFoot = this.getDirAndFootForDartboard(dirs, dirAndFoot.dir, dirsAndFoots);
						dirsAndFoots.push(dirAndFoot);
						if (instructions.disp_time > beat) {
							if (fakeout) {
								tempSequence.push({
									type: 'dart',
									animation: 'icon_middle',
									durationInSecs: 0,
									dir: dirAndFoot.dir,
									opacity: 1,
									icon: 'arrow',
									colour: 'green',
								});
								tempSequence.push({
									type: 'dart',
									animation: 'icon_middle',
									durationInSecs: beat / 3,
									dir: dirAndFoot.dir,
									opacity: 1,
									icon: 'arrow',
									colour: 'purple',
								});
								tempSequence.push({ type: 'general', animation: 'beep', durationInSecs: beat - beat / 3 });
								tempSequence.push({
									type: 'dart',
									animation: 'icon_middle',
									durationInSecs: instructions.disp_time - beat - beat / 3,
									dir: dirAndFoot.dir,
									opacity: 0,
									icon: 'arrow',
								});
								tempSequence.push({ type: 'general', animation: 'beep', durationInSecs: beat * 2 - instructions.disp_time - beat / 3 });
							} else {
								tempSequence.push({
									type: 'dart',
									animation: 'icon_middle',
									durationInSecs: 0,
									dir: dirAndFoot.dir,
									opacity: 1,
									icon: 'arrow',
									colour: 'green',
								});
								tempSequence.push({ type: 'general', animation: 'beep', durationInSecs: beat });
								tempSequence.push({
									type: 'dart',
									animation: 'icon_middle',
									durationInSecs: instructions.disp_time - beat,
									dir: dirAndFoot.dir,
									opacity: 0,
									icon: 'arrow',
								});
								tempSequence.push({ type: 'general', animation: 'beep', durationInSecs: beat * 2 - instructions.disp_time });
							}
						} else {
							if (fakeout) {
								tempSequence.push({
									type: 'dart',
									animation: 'icon_middle',
									durationInSecs: 0,
									dir: dirAndFoot.dir,
									opacity: 1,
									icon: 'arrow',
									colour: 'green',
								});
								tempSequence.push({
									type: 'dart',
									animation: 'icon_middle',
									durationInSecs: instructions.disp_time / 1.5,
									dir: dirAndFoot.dir,
									opacity: 1,
									icon: 'arrow',
									colour: 'purple',
								});
								tempSequence.push({
									type: 'dart',
									animation: 'icon_middle',
									durationInSecs: instructions.disp_time - instructions.disp_time / 1.5,
									dir: dirAndFoot.dir,
									opacity: 0,
									icon: 'arrow',
								});
								tempSequence.push({
									type: 'general',
									animation: 'beep',
									durationInSecs: beat - instructions.disp_time - instructions.disp_time / 1.5,
								});
								tempSequence.push({
									type: 'general',
									animation: 'beep',
									durationInSecs: beat * 2 - instructions.disp_time - instructions.disp_time / 1.5,
								});
							} else {
								tempSequence.push({
									type: 'dart',
									animation: 'icon_middle',
									durationInSecs: 0,
									dir: dirAndFoot.dir,
									opacity: 1,
									icon: 'arrow',
									colour: 'green',
								});
								tempSequence.push({
									type: 'dart',
									animation: 'icon_middle',
									durationInSecs: instructions.disp_time,
									dir: dirAndFoot.dir,
									opacity: 0,
									icon: 'arrow',
								});
								tempSequence.push({ type: 'general', animation: 'beep', durationInSecs: beat - instructions.disp_time });
								tempSequence.push({ type: 'general', animation: 'beep', durationInSecs: beat * 2 - instructions.disp_time });
							}
						}
						tempSequence.push({ type: 'general', animation: 'nothing', durationInSecs: beat / 4 });
						break;
				}
				/*switch (cue) {
					case "Arrows":
					case "Compass":
						let icon = (cue == "Arrows") ? "arrow" : "compass";
						tempSequence.push({ type: "dart", animation: "icon_middle", durationInSecs: 0, dir: dirAndFoot.dir, opacity:1, icon:icon });
						tempSequence.push({ type: "dart", animation: "icon_middle", durationInSecs: instructions.disp_time, dir: dirAndFoot.dir, opacity:0, icon:icon });
						tempSequence.push({ type: "general", animation: "beep", durationInSecs: beat - instructions.disp_time });
						tempSequence.push({ type: "general", animation: "beep", durationInSecs: beat });
					break;
					case "Shapes":
					case "Symbol":
					case "Footprints":
						let distance = instructions.step[ Math.floor(Math.random()*instructions.step.length) ];
						let dirs = instructions.direction.split("/");
						let dir = dirs[ Math.floor(Math.random()*dirs.length) ];
						//let icon = (cue == "Shapes" || cue == "Symbol") && this.dartboardIcons[Math.floor(Math.random()*this.dartboardIcons.length)] || this.dartboardFootprints[Math.floor(Math.random()*this.dartboardFootprints.length)];
						tempSequence.push({ type: "dart", animation: "icon_middle", durationInSecs: 0, iconFromDir: dir+"-"+distance, opacity:1, dir:"N" });
						tempSequence.push({ type: "dart", animation: "icon_middle", durationInSecs: instructions.disp_time, iconFromDir: dir+"-"+distance, opacity:0, dir:"N" });
						tempSequence.push({ type: "general", animation: "beep", durationInSecs: beat - instructions.disp_time });
						tempSequence.push({ type: "general", animation: "beep", durationInSecs: beat });
					break;
				}*/
			}

			// Finally, add a rep finished counter
			tempSequence.push(new RepEvent());
		}

		////////////////////////////////
		/*if (!instructions.dual) {
			for (let x = 0; x < parseInt(instructions.reps); x++) {
				tempSequence.forEach(seq => this.animationSequence.sequence.push(seq));
			}
		} else {
			tempSequence.forEach(seq => this.animationSequence.sequence.push(seq));
		}*/
		tempSequence.forEach((seq) => this.animationSequence.sequence.push(seq));

		return Promise.resolve(this.animationSequence);
	}

	////////////////////////////////
	dartboardSetupIcons(type, justOuterRing = true) {
		let icons = (type == 'fp' && JSON.parse(JSON.stringify(this.dartboardFootprints))) || JSON.parse(JSON.stringify(this.dartboardIcons));
		let segmentKeys = (justOuterRing && this.outerSegments) || Object.keys(this.segmentLookup);
		segmentKeys.forEach((seg) => {
			if (seg != 'inner') {
				let iconIndex = Math.floor(Math.random() * icons.length);
				let icon = icons[iconIndex];
				this.placeIconOnDartboard(seg, icon, 0);
				icons.splice(iconIndex, 1);
			}
		});
	}

	////////////////////////////////
	resetDartboardIcons() {
		let iconCont = document.querySelector('.icon_vis');
		if (iconCont) iconCont.innerHTML = '';
		this.iconsOnSegments = [];
	}

	////////////////////////////////
	/*createGridPreanimation(instr) {
		let preSequence = [];
		let presnt = instr.presentation || instr.presenation;
		this.animationSequence.sequence.forEach(seq => {
			let shadeSquare = seq.animation == "Step_Left" ? seq.footLeft : seq.footRight;
			if (presnt == "Path all at once") {
				preSequence.push({ type: "grid", animation: "Shade", durationInSecs: 0, square: shadeSquare });
			} else {
				preSequence.push({ type: "grid", animation: "Shade", durationInSecs: 0, square: shadeSquare });
				preSequence.push({ type: "grid", animation: "Unshade", durationInSecs: 0.5 });
			}
		});
		if (presnt == "Path all at once") preSequence.push({ type: "grid", animation: "Unshade", durationInSecs: this.animationSequence.sequence.length * 0.5 });
		this.animationSequence.sequence = preSequence.concat(this.animationSequence.sequence);
		return Promise.resolve(this.animationSequence);
	}*/

	////////////////////////////////
	private createGridSequence(instr) {
		console.log('%c >>>>>>>>>>>>> CREATING NEW GRID SEQUENCE', 'color:red');
		// All grid go left then right
		// Both feet means both feet in a grid, one foot means only one foot per grid
		// Arrow show both feet and arrow
		// Two tempos means randomly select the tempo (retroactively fit this to all other exercises)
		// Cue of array (e.g. arrows and compass) means randomise which to show
		// When stepping "Long", can only step sideway
		// When I see the property "dual", I randomly pick an exercise from it to run (for the entire exercise, even through the 3 repeats)
		// Target cue is for the user to work out themselves completely, no animation
		// For dual tasks:
		// Never move feet, use different cues
		// Play number of steps as beeps
		// Arrows -> Different colour.  2 Arrows, one green, one purple.  User should follow green
		// Arrows -> Change colour.  1 Arrow, randomise which colour
		// Arrows -> Opposite. Green and purple as above.  User does opposite.
		// Shades -> Path all at once.  Shows path the user should take, then fade.  I've determined the path.  Forward span & Backward ignore
		// Shades -> Path chrono.    Shows path the user should take, then fade.  I've determined the path.  Forward span & Backward span, play forward and backward.
		// Target -> 1, 2 and 3.  User must hit within "x" amount of steps, but no animation form meeeeeee.

		// NEW UPDATES
		// \\ Arrows -> Different colour.  1 arrow, randomise which colour, user doesn't step on purple, and it is considered a beat -> Always 25%, rounded down
		// \\ Arrows -> Change colour.  1 Arrow, is green, but then quickly changes to purple, and is considered a beat -> Always 25%, rounded down
		// \\ Arrows -> Opposite. Green as normal, but green points in the opposite direction
		// \\ Shades -> Light up for 0.5 second.  If all at once, leave for the combined time.  If path, each for that time.

		// NEWER UPDATES (See below in switch statement)

		let instructions;
		this.animationSequence.sequence = [];
		let beat;
		let reps = parseInt(instr.reps);
		let dirs = (Array.isArray(instr.direction) && instr.direction[0].split('/')) || instr.direction.split('/');
		let leftFootPos;
		let rightFootPos;
		this.gridMoveLeftFootThisTime = true;
		let numFakeouts = 0;

		// Switch up instructions if the dual property exists (we'll choose a random one to use)
		if (instr.dual && Array.isArray(instr.dual)) {
			let chosenOne;
			let dualIndex = this.sExercise.getPrechosenDualIndex();
			if (dualIndex || dualIndex == 0) chosenOne = instr.dual[dualIndex];
			else chosenOne = instr.dual[Math.floor(Math.random() * instr.dual.length)];

			instructions = chosenOne;
			instructions.action = instr.action;
			instructions.step = instr.step;
			reps = parseInt(instructions.reps);
			instructions.hasDualProperty = true;
			instructions.dual = true;
			if (chosenOne.feet) instructions.action = chosenOne.feet; // Required, but commented for testing other thing
		} else {
			instructions = instr;
		}

		// Set starting foot
		if (instructions.action == 'One foot') {
			if (dirs.indexOf('B') == -1) {
				leftFootPos = { x: 1, y: 3 };
				rightFootPos = { x: 2, y: 3 };
			} else {
				leftFootPos = { x: 1, y: 2 };
				rightFootPos = { x: 2, y: 2 };
			}
		} else {
			if (dirs.indexOf('B') == -1) {
				leftFootPos = { x: 1, y: 3 };
				rightFootPos = { x: 1, y: 3 };
			} else {
				leftFootPos = { x: 1, y: 2 };
				rightFootPos = { x: 1, y: 2 };
			}
		}

		// Create an initialState
		this.animationSequence.initialState.push({
			type: 'grid',
			animation: 'Step_Left',
			durationInSecs: 0,
			rotation: 0,
			footLeft: this.createFootGridPosition(leftFootPos),
			footRight: this.createFootGridPosition(rightFootPos),
			highlight: false,
		});
		this.animationSequence.initialState.push({
			type: 'grid',
			animation: 'Step_Right',
			durationInSecs: 0,
			rotation: 0,
			footLeft: this.createFootGridPosition(leftFootPos),
			footRight: this.createFootGridPosition(rightFootPos),
			highlight: false,
		});

		// When peforming dual, we need to add new rules and effects. NOTE this is lots of duplicated code, as dual was not considered when grid below was built
		if (instructions.dual) {
			let path;
			let fakeout;
			let cardinalDir;
			beat = this.getBeatFromTempo(this.animationSequence.tempo);
			switch (instructions.cog_mode.toLowerCase()) {
				// Forward Span && Backwards Span
				// Path all at once: Show path made up of n_cues squares, can't step on existing squares.  Then beep n_steps times at tempo.  Keep squares lit for 5x tempo
				// Path chronological: Show path made up of n_cues, one after the other, CAN step on existing squares.  Then beep n_steps times at tempo.  Light each square for (5x tempo / n_cues)
				case 'forward span':
				case 'backward span':
					path = this.createGridPathBothFeet(leftFootPos, dirs, instructions.step, instructions.n_cues, true);
					if (instructions.presenation.toLowerCase() == 'path all at once') {
						path.forEach((pathI, index) => {
							this.animationSequence.sequence.push({
								durationInSecs: 0,
								type: 'grid',
								animation: 'Shade',
								number: index + 1,
								dual: true,
								square: this.createFootGridPosition(pathI),
							});
						});
						this.animationSequence.sequence.push({
							durationInSecs: beat * 5,
							type: 'grid',
							animation: 'Unshade',
						});
						for (let x = 0; x < instructions.n_steps; x++) {
							this.animationSequence.sequence.push({
								durationInSecs: beat,
								type: 'general',
								animation: 'beep',
							});
						}
					} else if (instructions.presenation.toLowerCase() == 'path chronological') {
						path.forEach((pathI) => {
							this.animationSequence.sequence.push({
								durationInSecs: (beat * 5) / path.length,
								type: 'grid',
								animation: 'Shade',
								dual: true,
								square: this.createFootGridPosition(pathI),
							});
						});
						this.animationSequence.sequence.push({
							durationInSecs: beat * 5,
							type: 'grid',
							animation: 'Unshade',
						});
						for (let x = 0; x < instructions.n_steps; x++) {
							this.animationSequence.sequence.push({
								durationInSecs: beat,
								type: 'general',
								animation: 'beep',
							});
						}
					}
					break;
				// Visual Search
				// Show "presenation" targets, for 5x tempo.  Then Beep n_cues times @ tempo.
				case 'visual search':
					let numTargets;
					switch (instructions.presenation.toLowerCase()) {
						case 'one target':
							numTargets = 1;
							break;
						case 'two targets':
							numTargets = 2;
							break;
						case 'three targets':
							numTargets = 3;
							break;
					}
					path = this.createGridPathBothFeet(leftFootPos, dirs, 'Random', numTargets, true);
					path.forEach((pathI, index) => {
						this.animationSequence.sequence.push({
							durationInSecs: 0,
							type: 'grid',
							icon: 'target' + (index + 1),
							dual: true,
							square: this.createFootGridPosition(pathI),
						});
					});
					this.animationSequence.sequence.push({
						durationInSecs: beat * 5,
						type: 'grid',
						animation: 'Untarget',
					});
					for (let x = 0; x < instructions.n_steps; x++) {
						this.animationSequence.sequence.push({
							durationInSecs: beat,
							type: 'general',
							animation: 'beep',
						});
					}
					break;
				// Selective
				// Beep and show cue and for first step.  Beep for second step.  Show next cue and beep after tempo.  Don't show feet.  Repeat for n_steps
				case 'selective':
					path = this.createGridPathBothFeet(leftFootPos, dirs, instructions.step, instructions.n_steps / 2, true, true); // Emma confirmed that for selective, a step is both feet, so we need to halve steps
					path.forEach((pathI, index) => {
						if (index == 0) cardinalDir = this.determineCardinalDifference(leftFootPos, pathI);
						else cardinalDir = this.determineCardinalDifference(path[index - 1], pathI);
						this.animationSequence.sequence.push({
							durationInSecs: beat,
							type: 'grid',
							icon: (pathI.fakeout && 'ArrowPurple') || 'ArrowGreen',
							fakeout: false,
							direction: cardinalDir,
							dual: true,
							beep: true,
						});
						this.animationSequence.sequence.push({
							durationInSecs: beat,
							type: 'general',
							animation: 'beep',
						});
					});
					break;
				// Inhibition
				// As selective, but the cue changes colour midway
				case 'inhibition':
					path = this.createGridPathBothFeet(leftFootPos, dirs, instructions.step, instructions.n_steps / 2, true, true); // Emma confirmed that for selective, a step is both feet, so we need to halve steps
					path.forEach((pathI, index) => {
						if (index == 0) cardinalDir = this.determineCardinalDifference(leftFootPos, pathI);
						else cardinalDir = this.determineCardinalDifference(path[index - 1], pathI);
						if (!pathI.fakeout) {
							this.animationSequence.sequence.push({
								durationInSecs: beat,
								type: 'grid',
								icon: (pathI.fakeout && 'ArrowPurple') || 'ArrowGreen',
								fakeout: false,
								direction: cardinalDir,
								dual: true,
								beep: true,
							});
							this.animationSequence.sequence.push({
								durationInSecs: beat,
								type: 'general',
								animation: 'beep',
							});
						} else {
							this.animationSequence.sequence.push({
								durationInSecs: beat,
								type: 'grid',
								icon: 'ArrowGreen',
								fakeout: false,
								direction: cardinalDir,
								dual: true,
								beep: true,
							});
							this.animationSequence.sequence.push({
								durationInSecs: beat / 2,
								type: 'grid',
								icon: 'ArrowPurple',
								fakeout: false,
								direction: cardinalDir,
								dual: true,
								beep: false,
							});
							this.animationSequence.sequence.push({
								durationInSecs: beat / 2,
								type: 'general',
								animation: 'beep',
							});
						}
					});
					break;
				// Attention
				// As Selective, but the user goes the opposite direction, so flip the arrow to the opposite when displaying (but not when calculating)
				case 'attention':
					path = this.createGridPathBothFeet(leftFootPos, dirs, instructions.step, instructions.n_steps / 2, true, true); // Emma confirmed that for selective, a step is both feet, so we need to halve steps
					path.forEach((pathI, index) => {
						if (index == 0) cardinalDir = this.determineCardinalDifference(leftFootPos, pathI);
						else cardinalDir = this.determineCardinalDifference(path[index - 1], pathI);
						this.animationSequence.sequence.push({
							durationInSecs: beat,
							type: 'grid',
							icon: 'ArrowGreen',
							fakeout: false,
							direction: cardinalDir,
							dual: true,
							reverseArrow: true,
							beep: true,
						});
						this.animationSequence.sequence.push({
							durationInSecs: beat,
							type: 'general',
							animation: 'beep',
						});
					});
					break;
			}
		}

		// Otherwise, we don't have dual.  This uses older legacy code to plot the path also.  However "newer" code might not have all the required features.
		else {
			// Create a sequence identifier for each rep
			for (let x = 0; x < reps; x++) {
				beat = this.getBeatFromTempo(this.animationSequence.tempo);
				this.limiter = 0;
				let nextMove;
				let tempLeft: any;
				let tempRight: any;

				// Get the next move
				nextMove = this.suggestGridMove(leftFootPos, rightFootPos, this.shuffleArray(dirs), instructions.action, instructions.step);
				if (!nextMove) {
					this.limiter2++;
					if (this.limiter2 > 50) throw "Couldn't create Grid animation - randomised nature provided no successful animation";
					return this.createGridSequence(instructions);
				}

				// Prepare an icon draw
				let icon = Array.isArray(instructions.cue) ? instructions.cue[Math.floor(Math.random() * instructions.cue.length)] : instructions.cue;

				// Do we need to create a fakeout?  Arrows -> Different Colour / Change Colour
				let presnt = instructions.presenation || instructions.presentation;
				if (instructions.cue == 'Arrows' && (presnt == 'Different colour' || presnt == 'Change colour')) {
					let fakeout: boolean;
					let fakeoutPerc = numFakeouts / reps;
					if (fakeoutPerc >= 0.25) fakeout = false;
					else {
						fakeout = [true, false][Math.floor(Math.random() * 2)];
						if (fakeout) {
							numFakeouts += 1;
							nextMove.fakeout = fakeout;
							if (presnt == 'Change colour') nextMove.fakeoutDuration = 0.5;
							nextMove.durationInSecs = beat;
							nextMove.type = 'grid';
							nextMove.icon = icon;
							nextMove.highlight = false;
							nextMove.dual = instructions.hasDualProperty;
							nextMove.footLeft = this.createFootGridPosition(leftFootPos);
							nextMove.footRight = this.createFootGridPosition(rightFootPos);
							nextMove.presentation = instructions.presenation || instructions.presentation || null;
							this.animationSequence.sequence.push(nextMove);
							continue;
						}
					}
				}

				// If the arrow should be opposite...
				if (instructions.cue == 'Arrows' && presnt == 'Opposite') {
					nextMove.arrowOpposite = true;
				}

				// Remember our new position for the next step
				leftFootPos = JSON.parse(JSON.stringify(nextMove.footLeft));
				tempLeft = leftFootPos;
				rightFootPos = JSON.parse(JSON.stringify(nextMove.footRight));
				tempRight = rightFootPos;

				// Store grid coords for animating
				nextMove.footLeft = this.createFootGridPosition(nextMove.footLeft);
				nextMove.footRight = this.createFootGridPosition(nextMove.footRight);

				nextMove.durationInSecs = beat;
				nextMove.type = 'grid';
				nextMove.icon = icon;
				nextMove.highlight = true;
				nextMove.dual = instructions.hasDualProperty;
				nextMove.presentation = instructions.presenation || instructions.presentation || null;
				this.animationSequence.sequence.push(nextMove);

				// Create a duplicate for the other foot, if we're running "Both feet"
				if (instructions.action.toLowerCase() == 'both feet') {
					let mirrorMove = JSON.parse(JSON.stringify(nextMove));
					if (this.gridMoveLeftFootThisTime) {
						mirrorMove.footRight = mirrorMove.footLeft;
						mirrorMove.animation = 'Step_Right';
						rightFootPos = tempLeft;
					} else {
						mirrorMove.footLeft = mirrorMove.footRight;
						mirrorMove.animation = 'Step_Left';
						leftFootPos = tempRight;
					}
					mirrorMove.durationInSecs = beat;
					this.animationSequence.sequence.push(mirrorMove);
				}

				// Not strictly the best, but I swap feet regardless of Both feet or One foot.  This means that Both feet alternate, but works better with my code
				this.gridMoveLeftFootThisTime = !this.gridMoveLeftFootThisTime;
			}
		}

		// Create a shades preanimation if needs be
		//if (instructions.cue == "Shades") this.createGridPreanimation(instructions);

		return Promise.resolve(this.animationSequence);
	}

	////////////////////////////////////
	private createGridPathBothFeet(footPos, dirs, stepLength, numSteps, uniqueSquares = false, canFakeout = false) {
		let limiter = 0;
		let limit = 1000;
		let path = [];
		let stepLengthNum;
		let fakeoutPercentage = 0.8;
		let fakeout = false;

		// Continue until we have a path
		while (path.length < numSteps && limiter < limit) {
			let validMove = false;
			let move;
			limiter++;
			dirs = this.shuffleArray(dirs);
			stepLengthNum = this.getStepLength(stepLength);

			// We'll fakeout if we can
			if (canFakeout && Math.random() > fakeoutPercentage) fakeout = true;
			else fakeout = false;

			dirs.forEach((dir) => {
				move = this.gridApplyFootDirectionNew(footPos, dir, dirs, stepLengthNum);

				if (this.gridIsValidPosition(move, move, 'both feet') && !validMove && uniqueSquares && this.gridIsUniquePosition(move, path)) {
					let newMove = JSON.parse(JSON.stringify(move));
					newMove.fakeout = fakeout;
					path.push(newMove);

					if (!fakeout) footPos = newMove; // Let's only move from the next tile if it's not a fakeout
					validMove = true;
				}
			});
		}

		// If we've encountered an error, otherwise return
		if (path.length < numSteps) {
			return this.createGridPathBothFeet(footPos, dirs, stepLength, numSteps, uniqueSquares);
		} else {
			return path;
		}
	}

	////////////////////////////////////
	private createBoxSequence(instructions) {
		console.log('%c >>>>>>>>>>>>> CREATING NEW BOX SEQUENCE', 'color:red');
		// 1 Rep = movement with one foot
		// Mixed means randomise the distance, but always left / right / left / right
		// Tap is front-of-foot, "Simple steps" = regular step flat foot, High knees = just show flat foot

		// NEW UPDATES
		// Step up and Over, make the turn action 2x a beat, not 1x
		// High knee, the high knee foot isn't shown on the box.  So we need to account for High knee.  Consider a blurry foot
		// 1 Rep = both feet on box, or two individual taps on box.
		// jD Need to change Step Over, so that up on box is 1 rep.

		// NEWER UPDATES
		// Step up and Over, make the turn action 4x a beat, not 1x

		// Instantiation
		let beat = 60 / this.animationSequence.tempo;
		let reps = parseInt(instructions.reps);
		let tempSequence: any[] = [];

		// If the action property is not an array, convert it to one so the rest of the code doesn't break, as content is not provided in a standard manner
		if (!Array.isArray(instructions.action)) instructions.action = [instructions.action];

		// If we only have 1 action
		if (instructions.action && instructions.action.length == 1) {
			// Instantiation
			let isLeft = true;
			let leftNeutral;
			let rightNeutral;
			let isSidewaysLeft = false;
			let cueIndex = 0;

			// Set the starting position based on the action or cue
			if (instructions.cue[0] == 'Step down' || instructions.cue[0] == 'Tap down') {
				leftNeutral = 'box-middle';
				rightNeutral = 'box-middle';
			} else if (instructions.animation_name == 'step_over') {
				if (instructions.action[0] == 'Forwards') leftNeutral = rightNeutral = 'box-bottom-outer';
				else if (instructions.action[0] == 'Sideways')
					leftNeutral = rightNeutral = ['box-left-outer', 'box-right-outer'][Math.floor(Math.random() * 2)];
				else if (instructions.action[0] == 'Combination' || instructions.action[0] == 'combination')
					leftNeutral = rightNeutral = 'box-bottom-outer';
			} else if (instructions.action[0] == 'Forwards') leftNeutral = rightNeutral = 'box-bottom-outer';
			else if (instructions.action[0] == 'Backards') leftNeutral = rightNeutral = 'box-top-outer';
			else if (instructions.action[0] == 'Sideways') {
				leftNeutral = rightNeutral = ['box-left-outer', 'box-right-outer'][Math.floor(Math.random() * 2)];
				if (leftNeutral == 'box-left-outer') {
					isSidewaysLeft = true;
					isLeft = false;
				}
			}

			// Starting position
			let position = leftNeutral;
			this.animationSequence.initialState.push({
				type: 'box',
				animation: 'Step_Left',
				durationInSecs: 0,
				rotation: 0,
				footLeft: leftNeutral,
				stance: 'flat',
				beep: false,
			});
			this.animationSequence.initialState.push({
				type: 'box',
				animation: 'Step_Right',
				durationInSecs: 0,
				rotation: 0,
				footRight: rightNeutral,
				stance: 'flat',
				beep: false,
			});

			// Repeat for reps
			for (let x = 0; x < reps; x++) {
				let lastPosition = position;
				position = this.getBoxFootPosition(instructions.cue[cueIndex], instructions.action[0], isLeft, isSidewaysLeft).pos;
				if (instructions.animation_name == 'tap') {
					if (isLeft) {
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Left',
							durationInSecs: beat,
							rotation: 0,
							footLeft: position,
							stance: 'forward',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Left',
							durationInSecs: beat,
							rotation: 0,
							footLeft: leftNeutral,
							stance: 'flat',
							beep: true,
						});
					} else {
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Right',
							durationInSecs: beat,
							rotation: 0,
							footRight: position,
							stance: 'forward',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Right',
							durationInSecs: beat,
							rotation: 0,
							footRight: rightNeutral,
							stance: 'flat',
							beep: true,
						});
					}
				} else if (['step_up', 'step_down'].indexOf(instructions.animation_name) != -1) {
					let leftFootFirst = this.shouldLeftFootMoveFirst(lastPosition, position, 0);
					let positionR;

					// Hardcode the tap down version because it behaves differently
					if (instructions.cue[0].toLowerCase() == 'tap down' && instructions.animation_name == 'step_down') {
						let action = instructions.action[Math.floor(Math.random() * instructions.action.length)];
						if (action == 'Backwards' || action == 'Backward') {
							position = positionR = 'box-bottom-outer';
						}
						if (action == 'Forwards' || action == 'Forward') {
							position = positionR = 'box-top-outer';
						}
						if (action == 'Sideways' || action == 'Sideway') {
							position = 'box-left-outer';
							positionR = 'box-right-outer';
							//if (side == 'left') leftFootFirst = true
							//else leftFootFirst = false;
						}
						if (action == 'Mixed_Backwards_Sideways') {
							let side = ['bottom', 'side'][Math.floor(Math.random() * 2)];
							if (side == 'side') {
								position = 'box-left-outer';
								positionR = 'box-right-outer';
							} else {
								position = positionR = 'box-' + side + '-outer';
							}
						}
						//if (leftFootFirst) {
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Left',
							durationInSecs: beat,
							rotation: 0,
							footLeft: position,
							stance: 'forward',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Left',
							durationInSecs: beat,
							rotation: 0,
							footLeft: leftNeutral,
							stance: 'flat',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Right',
							durationInSecs: beat,
							rotation: 0,
							footRight: positionR,
							stance: 'forward',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Right',
							durationInSecs: beat,
							rotation: 0,
							footRight: leftNeutral,
							stance: 'flat',
							beep: true,
						});
						position = leftNeutral; // Sets position to neutral for tricking the leftFootFirst property
						//}

						// Otherwise dynamically generate the rest
					} else {
						if (leftFootFirst) {
							this.animationSequence.sequence.push({
								type: 'box',
								animation: 'Step_Left',
								durationInSecs: beat,
								rotation: 0,
								footLeft: position,
								stance: 'flat',
								beep: true,
							});
							this.animationSequence.sequence.push({
								type: 'box',
								animation: 'Step_Right',
								durationInSecs: beat,
								rotation: 0,
								footRight: position,
								stance: 'flat',
								beep: true,
							});
							this.animationSequence.sequence.push({
								type: 'box',
								animation: 'Step_Right',
								durationInSecs: beat,
								rotation: 0,
								footRight: rightNeutral,
								stance: 'flat',
								beep: true,
							});
							this.animationSequence.sequence.push({
								type: 'box',
								animation: 'Step_Left',
								durationInSecs: beat,
								rotation: 0,
								footLeft: leftNeutral,
								stance: 'flat',
								beep: true,
							});
							position = leftNeutral; // Sets position to neutral for tricking the leftFootFirst property
						} else {
							this.animationSequence.sequence.push({
								type: 'box',
								animation: 'Step_Right',
								durationInSecs: beat,
								rotation: 0,
								footRight: position,
								stance: 'flat',
								beep: true,
							});
							this.animationSequence.sequence.push({
								type: 'box',
								animation: 'Step_Left',
								durationInSecs: beat,
								rotation: 0,
								footLeft: position,
								stance: 'flat',
								beep: true,
							});
							this.animationSequence.sequence.push({
								type: 'box',
								animation: 'Step_Left',
								durationInSecs: beat,
								rotation: 0,
								footLeft: leftNeutral,
								stance: 'flat',
								beep: true,
							});
							this.animationSequence.sequence.push({
								type: 'box',
								animation: 'Step_Right',
								durationInSecs: beat,
								rotation: 0,
								footRight: rightNeutral,
								stance: 'flat',
								beep: true,
							});
							position = leftNeutral; // Sets position to neutral for tricking the leftFootFirst property
						}
					}

					// STEP UP AND OVER
				} else if (instructions.animation_name == 'step_over') {
					let act = instructions.action[0];
					if (act == 'Forward' || act == 'Forwards') {
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Left',
							durationInSecs: beat,
							rotation: 0,
							footLeft: 'box-middle',
							stance: 'flat',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Right',
							durationInSecs: beat,
							rotation: 0,
							footRight: 'box-middle',
							stance: 'flat',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Left',
							durationInSecs: beat,
							rotation: 0,
							footLeft: 'box-top-outer',
							stance: 'flat',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Right',
							durationInSecs: beat,
							rotation: 0,
							footRight: 'box-top-outer',
							stance: 'flat',
							beep: true,
						});

						// Rotate
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Left',
							durationInSecs: beat,
							rotation: 45,
							footLeft: 'box-top-outer',
							stance: 'flat',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Right',
							durationInSecs: beat / 2,
							rotation: 45,
							footRight: 'box-top-outer',
							stance: 'flat',
							beep: false,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Left',
							durationInSecs: beat / 2,
							rotation: 90,
							footLeft: 'box-top-outer',
							stance: 'flat',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Right',
							durationInSecs: beat / 2,
							rotation: 90,
							footRight: 'box-top-outer',
							stance: 'flat',
							beep: false,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Left',
							durationInSecs: beat / 2,
							rotation: 135,
							footLeft: 'box-top-outer',
							stance: 'flat',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Right',
							durationInSecs: beat / 2,
							rotation: 135,
							footRight: 'box-top-outer',
							stance: 'flat',
							beep: false,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Left',
							durationInSecs: beat / 2,
							rotation: 180,
							footLeft: 'box-top-outer',
							stance: 'flat',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Right',
							durationInSecs: beat / 2,
							rotation: 180,
							footRight: 'box-top-outer',
							stance: 'flat',
							beep: false,
						});

						// Up and down
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Left',
							durationInSecs: beat,
							rotation: 180,
							footLeft: 'box-middle',
							stance: 'flat',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Right',
							durationInSecs: beat,
							rotation: 180,
							footRight: 'box-middle',
							stance: 'flat',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Left',
							durationInSecs: beat,
							rotation: 180,
							footLeft: 'box-bottom-outer',
							stance: 'flat',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Right',
							durationInSecs: beat,
							rotation: 180,
							footRight: 'box-bottom-outer',
							stance: 'flat',
							beep: true,
						});

						// Rotate back
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Left',
							durationInSecs: beat / 2,
							rotation: 135,
							footLeft: 'box-bottom-outer',
							stance: 'flat',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Right',
							durationInSecs: beat / 2,
							rotation: 135,
							footRight: 'box-bottom-outer',
							stance: 'flat',
							beep: false,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Left',
							durationInSecs: beat / 2,
							rotation: 90,
							footLeft: 'box-bottom-outer',
							stance: 'flat',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Right',
							durationInSecs: beat / 2,
							rotation: 90,
							footRight: 'box-bottom-outer',
							stance: 'flat',
							beep: false,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Left',
							durationInSecs: beat / 2,
							rotation: 45,
							footLeft: 'box-bottom-outer',
							stance: 'flat',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Right',
							durationInSecs: beat / 2,
							rotation: 45,
							footRight: 'box-bottom-outer',
							stance: 'flat',
							beep: false,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Left',
							durationInSecs: beat / 2,
							rotation: 0,
							footLeft: 'box-bottom-outer',
							stance: 'flat',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Right',
							durationInSecs: beat / 2,
							rotation: 0,
							footRight: 'box-bottom-outer',
							stance: 'flat',
							beep: false,
						});
					} else if (act == 'Sideways') {
						if (leftNeutral.indexOf('left') != -1) {
							this.animationSequence.sequence.push({
								type: 'box',
								animation: 'Step_Right',
								durationInSecs: beat,
								rotation: 0,
								footRight: 'box-middle',
								stance: 'flat',
								beep: true,
							});
							this.animationSequence.sequence.push({
								type: 'box',
								animation: 'Step_Left',
								durationInSecs: beat,
								rotation: 0,
								footLeft: 'box-middle',
								stance: 'flat',
								beep: true,
							});
							this.animationSequence.sequence.push({
								type: 'box',
								animation: 'Step_Right',
								durationInSecs: beat,
								rotation: 0,
								footRight: 'box-right-outer',
								stance: 'flat',
								beep: true,
							});
							this.animationSequence.sequence.push({
								type: 'box',
								animation: 'Step_Left',
								durationInSecs: beat,
								rotation: 0,
								footLeft: 'box-right-outer',
								stance: 'flat',
								beep: true,
							});
							this.animationSequence.sequence.push({
								type: 'box',
								animation: 'Step_Left',
								durationInSecs: beat,
								rotation: 0,
								footLeft: 'box-middle',
								stance: 'flat',
								beep: true,
							});
							this.animationSequence.sequence.push({
								type: 'box',
								animation: 'Step_Right',
								durationInSecs: beat,
								rotation: 0,
								footRight: 'box-middle',
								stance: 'flat',
								beep: true,
							});
							this.animationSequence.sequence.push({
								type: 'box',
								animation: 'Step_Left',
								durationInSecs: beat,
								rotation: 0,
								footLeft: 'box-left-outer',
								stance: 'flat',
								beep: true,
							});
							this.animationSequence.sequence.push({
								type: 'box',
								animation: 'Step_Right',
								durationInSecs: beat,
								rotation: 0,
								footRight: 'box-left-outer',
								stance: 'flat',
								beep: true,
							});
						} else {
							this.animationSequence.sequence.push({
								type: 'box',
								animation: 'Step_Left',
								durationInSecs: beat,
								rotation: 0,
								footLeft: 'box-middle',
								stance: 'flat',
								beep: true,
							});
							this.animationSequence.sequence.push({
								type: 'box',
								animation: 'Step_Right',
								durationInSecs: beat,
								rotation: 0,
								footRight: 'box-middle',
								stance: 'flat',
								beep: true,
							});
							this.animationSequence.sequence.push({
								type: 'box',
								animation: 'Step_Left',
								durationInSecs: beat,
								rotation: 0,
								footLeft: 'box-left-outer',
								stance: 'flat',
								beep: true,
							});
							this.animationSequence.sequence.push({
								type: 'box',
								animation: 'Step_Right',
								durationInSecs: beat,
								rotation: 0,
								footRight: 'box-left-outer',
								stance: 'flat',
								beep: true,
							});
							this.animationSequence.sequence.push({
								type: 'box',
								animation: 'Step_Right',
								durationInSecs: beat,
								rotation: 0,
								footRight: 'box-middle',
								stance: 'flat',
								beep: true,
							});
							this.animationSequence.sequence.push({
								type: 'box',
								animation: 'Step_Left',
								durationInSecs: beat,
								rotation: 0,
								footLeft: 'box-middle',
								stance: 'flat',
								beep: true,
							});
							this.animationSequence.sequence.push({
								type: 'box',
								animation: 'Step_Right',
								durationInSecs: beat,
								rotation: 0,
								footRight: 'box-right-outer',
								stance: 'flat',
								beep: true,
							});
							this.animationSequence.sequence.push({
								type: 'box',
								animation: 'Step_Left',
								durationInSecs: beat,
								rotation: 0,
								footLeft: 'box-right-outer',
								stance: 'flat',
								beep: true,
							});
						}
					} else if (act == 'Combination' || act == 'combination') {
						// nee up_left_up_right_up_forward_turn_up_forward_turn
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Left',
							durationInSecs: beat,
							rotation: 0,
							footLeft: 'box-middle',
							stance: 'flat',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Right',
							durationInSecs: beat,
							rotation: 0,
							footRight: 'box-middle',
							stance: 'flat',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Left',
							durationInSecs: beat,
							rotation: 0,
							footLeft: 'box-left-outer',
							stance: 'flat',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Right',
							durationInSecs: beat,
							rotation: 0,
							footRight: 'box-left-outer',
							stance: 'flat',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Right',
							durationInSecs: beat,
							rotation: 0,
							footRight: 'box-middle',
							stance: 'flat',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Left',
							durationInSecs: beat,
							rotation: 0,
							footLeft: 'box-middle',
							stance: 'flat',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Right',
							durationInSecs: beat,
							rotation: 0,
							footRight: 'box-right-outer',
							stance: 'flat',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Left',
							durationInSecs: beat,
							rotation: 0,
							footLeft: 'box-right-outer',
							stance: 'flat',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Left',
							durationInSecs: beat,
							rotation: 0,
							footLeft: 'box-middle',
							stance: 'flat',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Right',
							durationInSecs: beat,
							rotation: 0,
							footRight: 'box-middle',
							stance: 'flat',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Left',
							durationInSecs: beat,
							rotation: 0,
							footLeft: 'box-top-outer',
							stance: 'flat',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Right',
							durationInSecs: beat,
							rotation: 0,
							footRight: 'box-top-outer',
							stance: 'flat',
							beep: true,
						});
						// Turn
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Left',
							durationInSecs: beat,
							rotation: 45,
							footLeft: 'box-top-outer',
							stance: 'flat',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Right',
							durationInSecs: beat / 2,
							rotation: 45,
							footRight: 'box-top-outer',
							stance: 'flat',
							beep: false,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Left',
							durationInSecs: beat / 2,
							rotation: 90,
							footLeft: 'box-top-outer',
							stance: 'flat',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Right',
							durationInSecs: beat / 2,
							rotation: 90,
							footRight: 'box-top-outer',
							stance: 'flat',
							beep: false,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Left',
							durationInSecs: beat / 2,
							rotation: 135,
							footLeft: 'box-top-outer',
							stance: 'flat',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Right',
							durationInSecs: beat / 2,
							rotation: 135,
							footRight: 'box-top-outer',
							stance: 'flat',
							beep: false,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Left',
							durationInSecs: beat / 2,
							rotation: 180,
							footLeft: 'box-top-outer',
							stance: 'flat',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Right',
							durationInSecs: beat / 2,
							rotation: 180,
							footRight: 'box-top-outer',
							stance: 'flat',
							beep: false,
						});
						// Up
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Left',
							durationInSecs: beat,
							rotation: 180,
							footLeft: 'box-middle',
							stance: 'flat',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Right',
							durationInSecs: beat,
							rotation: 180,
							footRight: 'box-middle',
							stance: 'flat',
							beep: true,
						});
						// Forward / bottom
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Left',
							durationInSecs: beat,
							rotation: 180,
							footLeft: 'box-bottom-outer',
							stance: 'flat',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Right',
							durationInSecs: beat,
							rotation: 180,
							footRight: 'box-bottom-outer',
							stance: 'flat',
							beep: true,
						});
						// Turn
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Left',
							durationInSecs: beat / 2,
							rotation: 135,
							footLeft: 'box-bottom-outer',
							stance: 'flat',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Right',
							durationInSecs: beat / 2,
							rotation: 135,
							footRight: 'box-bottom-outer',
							stance: 'flat',
							beep: false,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Left',
							durationInSecs: beat / 2,
							rotation: 90,
							footLeft: 'box-bottom-outer',
							stance: 'flat',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Right',
							durationInSecs: beat / 2,
							rotation: 90,
							footRight: 'box-bottom-outer',
							stance: 'flat',
							beep: false,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Left',
							durationInSecs: beat / 2,
							rotation: 45,
							footLeft: 'box-bottom-outer',
							stance: 'flat',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Right',
							durationInSecs: beat / 2,
							rotation: 45,
							footRight: 'box-bottom-outer',
							stance: 'flat',
							beep: false,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Left',
							durationInSecs: beat / 2,
							rotation: 0,
							footLeft: 'box-bottom-outer',
							stance: 'flat',
							beep: true,
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Right',
							durationInSecs: beat / 2,
							rotation: 0,
							footRight: 'box-bottom-outer',
							stance: 'flat',
							beep: false,
						});
					}
				}
				if (instructions.action[0] != 'Sideways') isLeft = !isLeft;
				if (instructions.cue.length > 1) cueIndex++;
				if (cueIndex > instructions.cue.length - 1) cueIndex = 0;

				// Add a rep counter item
				this.animationSequence.sequence.push(new RepEvent());
			}

			// Action is an array of additional actions
		} else {
			// Instantiation
			let leftNeutral;
			let rightNeutral;
			leftNeutral = rightNeutral = 'box-bottom-outer';
			let position = leftNeutral;
			let rotate = 0;

			// Starting position
			this.animationSequence.sequence.push({ type: 'box', animation: 'Step_Left', durationInSecs: 0, rotation: 0, footLeft: leftNeutral });
			this.animationSequence.sequence.push({ type: 'box', animation: 'Step_Right', durationInSecs: 0, rotation: 0, footRight: rightNeutral });

			// Repeat for reps, then for each given action
			for (let x = 0; x < reps; x++) {
				for (let action = 0; action < instructions.action[0].length; action++) {
					let lastPosition = position;
					if (instructions.action[0][action] == 'turn') {
						rotate = rotate == 180 ? 0 : 180;
					} else {
						position = this.getBoxFootPosition(instructions.cue[0], instructions.action[0][action], null, null).pos;
					}
					let leftFootFirst = this.shouldLeftFootMoveFirst(lastPosition, position, rotate);
					if (leftFootFirst) {
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Left',
							durationInSecs: beat,
							rotation: rotate,
							footLeft: position,
							stance: 'flat',
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Right',
							durationInSecs: beat,
							rotation: rotate,
							footRight: position,
							stance: 'flat',
						});
					} else {
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Right',
							durationInSecs: beat,
							rotation: rotate,
							footRight: position,
							stance: 'flat',
						});
						this.animationSequence.sequence.push({
							type: 'box',
							animation: 'Step_Left',
							durationInSecs: beat,
							rotation: rotate,
							footLeft: position,
							stance: 'flat',
						});
					}
				}

				this.animationSequence.sequence.push(new RepEvent());
			}
		}

		return Promise.resolve(this.animationSequence);
	}

	////////////////////////////////////
	private createBoxNewTimingSequence(instructions) {
		let sequenceStartingFrames;
		let sequenceGenerator;
		let sequenceAnimation = [];

		// Define the starting sequence
		switch (instructions.animation_name) {
			// Tap & Step Up: Starting Sequence
			case 'tap_on':
			case 'step_up':
				sequenceStartingFrames = [
					{
						type: 'box',
						animation: 'Step_Left',
						durationInSecs: 0,
						rotation: 0,
						footLeft: 'box-bottom-outer',
						stance: 'flat',
						beep: false,
					},
					{
						type: 'box',
						animation: 'Step_Right',
						durationInSecs: 0,
						rotation: 0,
						footRight: 'box-bottom-outer',
						stance: 'flat',
						beep: false,
					},
				];
				break;

			case 'tap_down':
				sequenceStartingFrames = [
					{
						type: 'box',
						animation: 'Step_Left',
						durationInSecs: 0,
						rotation: 0,
						footLeft: 'box-left-inner-bot',
						stance: 'flat',
						beep: false,
					},
					{
						type: 'box',
						animation: 'Step_Right',
						durationInSecs: 0,
						rotation: 0,
						footRight: 'box-right-inner-bot',
						stance: 'flat',
						beep: false,
					},
				];
				break;
		}

		// Define the animation sequence
		switch (instructions.animation_name) {
			// Tap: Sequence
			case 'tap_on':
				sequenceGenerator = Box_Sequences.CardioTappingUp;
				break;

			// Tap Down: Sequence
			case 'tap_down':
				sequenceGenerator = Box_Sequences.CardioTappingDown;
				break;

			// Step Up: Sequence
			case 'step_up':
				sequenceGenerator = Box_Sequences.CardioSteppingUp;
				break;
		}

		// Generate the sequence using the timing method
		if (instructions.timing == 'tempo+duration') {
			let beat = 60 / this.animationSequence.tempo;
			let remainingDuration = instructions.duration;

			// Temporary information
			let sequenceSteps = sequenceGenerator(0).length;

			// Sequence Generator
			while (remainingDuration >= sequenceSteps * beat) {
				let sequence = sequenceGenerator(beat);
				sequenceAnimation = sequenceAnimation.concat(sequence);
				sequenceAnimation.push(new RepEvent() as never);
				remainingDuration = instructions.duration - sequenceAnimation.length * beat;
			}
		}

		// Tempo+reps
		else if (instructions.timing == 'tempo+reps') {
			let tempSequence = sequenceGenerator(0);
			let beat = 60 / this.animationSequence.tempo / tempSequence.length;
			for (let x = 0; x < instructions.reps; x++) {
				let sequence = sequenceGenerator(beat);
				sequenceAnimation = sequenceAnimation.concat(sequence);
				sequenceAnimation.push(new RepEvent() as never);
			}
		}

		// New / unknown timing method
		else {
			console.warn('Do not know that timing method', instructions.timing);
		}

		// Finally write the sequence
		this.animationSequence.sequence = this.animationSequence.sequence.concat(sequenceStartingFrames);
		this.animationSequence.sequence = this.animationSequence.sequence.concat(sequenceAnimation);

		return Promise.resolve(this.animationSequence);
	}

	////////////////////////////////////
	createBalanceAssessementSequence(instructions) {
		let animationFrame: string = 'St_FT';
		switch (instructions.exerciseName) {
			case 'Standing Feet Together - Floor':
			case 'Standing Feet Together - Foam':
				animationFrame = 'St_FT';
				break;
			case 'Standing Feet Near Tandem - Floor':
			case 'Standing Feet Near Tandem - Foam':
				animationFrame = 'St_NT';
				break;
			case 'Standing Feet Tandem - Floor':
			case 'Standing Feet Tandem - Foam':
				animationFrame = 'St_T';
				break;
			case 'Standing on Left Leg - Floor':
			case 'Standing on Left Leg - Foam':
				animationFrame = 'St_LLeg';
				break;
			case 'Standing on Right Leg - Floor':
			case 'Standing on Right Leg - Foam':
				animationFrame = 'St_RLeg';
				break;
		}

		if (instructions.exerciseName.indexOf('Foam') == -1) this.removeClass(this.charFoam.nativeElement, 'on');
		//this.charFoam.nativeElement.classList.remove('on');
		else this.addClass(this.charFoam.nativeElement, 'on'); //this.charFoam.nativeElement.classList.add('on');

		this.animationSequence.initialState.push({ type: 'floor_balance', animation: animationFrame, durationInSecs: 0 });
		return Promise.resolve(this.animationSequence);
	}

	////////////////////////////////////
	getAnimationDetailsFromAnimationName(seq) {
		let detailsObj;
		switch (seq.animation) {
			case 'Steps_Left':
				detailsObj = { distance: 15 };
				break;
			case 'Steps_Right':
				detailsObj = { distance: 15 };
				break;
			default:
				//console.log("Exercise Animation Details cannot find type: " + seq.type);
				detailsObj = {};
				break;
		}

		return detailsObj;
	}

	////////////////////////////////
	isFloorCumulativeAdjustments(instructions) {
		let cumulativeAdjusted = ['S_KL_Mar_Si', 'HKs_Mar_Si'];
		if (cumulativeAdjusted.indexOf(instructions.animation_name) == -1) return false;
		return true;
	}

	////////////////////////////////
	suggestGridMove(leftFootPos, rightFootPos, dirs, actionName, stepSizes) {
		// Don't allow the random script to work more than 500 times
		this.limiter = this.limiter + 1;
		if (this.limiter > 500) return false;

		// Instantiation
		let validPosition = false;
		let chosenDir = '';
		let newLeftFootPos;
		let newRightFootPos;

		for (let feet = 0; feet < 2; feet++) {
			for (let x = 0; x < dirs.length; x++) {
				// Reset foot positions
				newLeftFootPos = JSON.parse(JSON.stringify(leftFootPos));
				newRightFootPos = JSON.parse(JSON.stringify(rightFootPos));

				// Check steo size
				let stepSize =
					stepSizes[0] == 'Mixed' ? ['Short', 'Long'][Math.floor(Math.random() * 2)] : stepSizes[Math.floor(Math.random() * stepSizes.length)];
				stepSize = stepSize == 'Short' ? 1 : 2;

				// Adjust a foot according to the dir
				if (this.gridMoveLeftFootThisTime) newLeftFootPos = this.gridApplyFootDirection(newLeftFootPos, dirs[x], dirs, stepSize);
				else newRightFootPos = this.gridApplyFootDirection(newRightFootPos, dirs[x], dirs, stepSize);

				// Check the foot is completely valid
				validPosition = this.gridIsValidPosition(newLeftFootPos, newRightFootPos, actionName);
				chosenDir = dirs[x];
				if (validPosition) break;
			}

			if (validPosition) break;
			else {
				newLeftFootPos = JSON.parse(JSON.stringify(leftFootPos));
				newRightFootPos = JSON.parse(JSON.stringify(rightFootPos));
				this.gridMoveLeftFootThisTime = !this.gridMoveLeftFootThisTime;
			}
		}

		let sequenceItem = {
			animation: 'Step_' + ((this.gridMoveLeftFootThisTime && 'Left') || 'Right'),
			durationInSecs: 0,
			rotation: 0,
			footLeft: newLeftFootPos,
			footRight: newRightFootPos,
			direction: chosenDir,
		};

		return sequenceItem;
	}

	////////////////////////////////
	gridApplyFootDirection(footPos, dir, allDirs, stepSize) {
		function getStepSize(dir, stepSize) {
			if (dir == 'F' && allDirs.indexOf('B') == -1) return 1;
			if (dir == 'F' || dir == 'B') return stepSize;
			if (['L', 'R'].indexOf(dir) != -1) return stepSize;
			return 1;
		}
		switch (dir) {
			case 'L':
				footPos.x -= getStepSize(dir, stepSize);
				break;
			case 'R':
				footPos.x += getStepSize(dir, stepSize);
				break;
			case 'B':
				footPos.y += getStepSize(dir, stepSize);
				break;
			case 'F':
				footPos.y -= getStepSize(dir, stepSize);
				break;
			case 'FL':
				footPos.x -= 1;
				footPos.y -= 1;
				break;
			case 'FR':
				footPos.x += 1;
				footPos.y -= 1;
				break;
			case 'BL':
				footPos.x -= 1;
				footPos.y += 1;
				break;
			case 'BR':
				footPos.x += 1;
				footPos.y += 1;
				break;
			default:
				footPos.x -= 10;
				footPos.y -= 10;
				break;
		}
		return footPos;
	}

	////////////////////////////////////
	gridApplyFootDirectionNew(footPos, dir, allDirs, stepSize) {
		let newFootPos = JSON.parse(JSON.stringify(footPos));
		function getStepSize(dir, stepSize) {
			if (dir == 'F' && allDirs.indexOf('B') == -1) return 1;
			if (dir == 'F' || dir == 'B') return stepSize;
			if (['L', 'R'].indexOf(dir) != -1) return stepSize;
			return 1;
		}
		switch (dir) {
			case 'L':
				newFootPos.x -= getStepSize(dir, stepSize);
				break;
			case 'R':
				newFootPos.x += getStepSize(dir, stepSize);
				break;
			case 'B':
				newFootPos.y += getStepSize(dir, stepSize);
				break;
			case 'F':
				newFootPos.y -= getStepSize(dir, stepSize);
				break;
			case 'FL':
				newFootPos.x -= 1;
				newFootPos.y -= 1;
				break;
			case 'FR':
				newFootPos.x += 1;
				newFootPos.y -= 1;
				break;
			case 'BL':
				newFootPos.x -= 1;
				newFootPos.y += 1;
				break;
			case 'BR':
				newFootPos.x += 1;
				newFootPos.y += 1;
				break;
			default:
				newFootPos.x -= 10;
				newFootPos.y -= 10;
				break;
		}
		return newFootPos;
	}

	////////////////////////////////
	gridIsValidPosition(leftFoot, rightFoot, actionName) {
		let xDistance = rightFoot.x - leftFoot.x;
		let yDistance = Math.max(leftFoot.y, rightFoot.y) - Math.min(leftFoot.y, rightFoot.y);
		if (xDistance > 2) {
			return false;
		}
		if (yDistance > 2) {
			return false;
		}
		if (xDistance + yDistance >= 4) {
			return false;
		}
		if (actionName.toLowerCase() == 'one foot' && leftFoot.x == rightFoot.x && leftFoot.y == rightFoot.y) {
			return false;
		}
		if (
			[leftFoot.x, leftFoot.y, rightFoot.x, rightFoot.y].filter((it) => {
				return it < 0 || it > 3;
			}).length
		) {
			return false;
		}
		if (leftFoot.x > rightFoot.x) return false;
		return true;
	}

	////////////////////////////////////
	gridIsUniquePosition(move, allMoves) {
		let noDupe = true;
		allMoves.forEach((mv) => {
			if (move.x == mv.x && move.y == mv.y) {
				noDupe = false;
			}
		});
		return noDupe;
	}

	////////////////////////////////
	gridSwapFeet(nextMove, oldLeftFootPos, oldRightFootPos) {
		let tempLeft = JSON.parse(JSON.stringify(nextMove.footLeft));
		//let tempRight = JSON.parse(JSON.stringify(nextMove.footRight));
		nextMove.footLeft = oldLeftFootPos;
		nextMove.footRight = tempLeft;
		nextMove.direction = this.gridCalculateDirection(nextMove.footRight, oldRightFootPos);
		return nextMove;
	}

	////////////////////////////////
	createFootGridPosition(pos) {
		let gridX = [1, 2, 3, 4];
		let gridY = ['A', 'B', 'C', 'D'];
		let posX = (pos && typeof pos.x == 'number' && pos.x) || 0;
		let posY = (pos && typeof pos.y == 'number' && pos.y) || 0;
		let x = gridX[posX];
		let y = gridY[posY];
		return '' + x + y;
	}

	////////////////////////////////
	/*undoFootGridPosition(pos: string) {
		let ix = parseInt(pos[0]); let iy = pos[1];
		let gridX = [1, 2, 3, 4];
		let gridY = ["A", "B", "C", "D"];
		return { x: gridX.indexOf(ix), y: gridY.indexOf(iy) };
	}*/

	////////////////////////////////
	gridCalculateDirection(newPos, oldPos) {
		let Y = '';
		let X = '';
		if (newPos.y < oldPos.y) Y = 'F';
		else if (newPos.y > oldPos.y) Y = 'B';
		if (newPos.x < oldPos.x) X = 'L';
		else if (newPos.x > oldPos.x) X = 'R';
		return Y + X;
	}

	////////////////////////////////////
	getStepLength(stepLength) {
		let newStepLength;
		if (Array.isArray(stepLength)) {
			newStepLength = JSON.parse(JSON.stringify(stepLength[Math.floor(Math.random() * stepLength.length)]));
		} else {
			newStepLength = stepLength;
		}
		let stepLengthString = (newStepLength == 'Mixed' && ['Short', 'Long'][Math.floor(Math.random() * 2)]) || newStepLength;

		let stepLengthNum = 0;
		switch (stepLengthString.toLowerCase()) {
			case 'long':
				stepLengthNum = 2;
				break;
			case 'short':
				stepLengthNum = 1;
				break;
			case 'random':
				stepLengthNum = Math.ceil(Math.random() * 3);
				break;
			default:
				stepLengthNum = 1;
		}
		return stepLengthNum;
	}

	////////////////////////////////
	getDir(dirs, lastDir = '') {
		let dir = dirs[Math.floor(Math.random() * dirs.length)];
		if (dir == lastDir) return this.getDir(dirs, lastDir);
		else return dir;
	}

	////////////////////////////////
	getDirAndFootForDartboard(dirs, lastDir, dirsAndFoots) {
		let limiter = 0;
		let lefties = dirsAndFoots.filter((dAndF) => {
			return this.isLeftFootForDartboard(dAndF.dir);
		});
		let righties = dirsAndFoots.filter((dAndF) => {
			return !this.isLeftFootForDartboard(dAndF.dir);
		});

		let retDir;
		let retIsLeft;
		while (!retDir && !retIsLeft && limiter < 1000) {
			limiter++;
			retDir = this.getDir(dirs, lastDir);
			retIsLeft = this.isLeftFootForDartboard(retDir);

			const mostRecentStep = (righties.length > lefties.length && lefties[lefties.length - 1]) || righties[righties.length - 1];

			// If the suggested foot is left, and this isn't the first left
			if (retIsLeft & lefties[lefties.length - 1]) {
				// If the suggested step is the same as the exact same direction, reset and try again
				if (retDir == mostRecentStep.dir) {
					retDir = null;
					retIsLeft = null;
				}
				// Else if the suggested foot is right, and this isn't the first right
			} else if (!retIsLeft && righties[righties.length - 1]) {
				// If the suggested step is the same as the exact same direction, reset and try again
				if (retDir == mostRecentStep.dir) {
					retDir = null;
					retIsLeft = null;
				}
			}
		}

		if (retDir == null || retIsLeft == null) throw 'Unable to determine a randomised order for Dartboard placement';
		return { dir: retDir, isLeftFoot: retIsLeft };
	}

	////////////////////////////////
	isLeftFootForDartboard(dir) {
		if (['SW', 'W', 'NW'].indexOf(dir) > -1) return true;
		else if (['SE', 'E', 'NE'].indexOf(dir) > -1) return false;
		else return [true, false][Math.floor(Math.random() * 2)];
	}

	////////////////////////////////
	getBoxFootPosition(cue, action, isLeftFoot, isSidewaysLeft): any {
		let pos: string = '';
		let stance: string = 'flat';
		switch (cue) {
			case 'Closest side of box':
				if (action == 'Forwards' && isLeftFoot) pos = 'box-left-inner-bot';
				else if (action == 'Forwards' && !isLeftFoot) pos = 'box-right-inner-bot';
				else if (action == 'Backwards' && isLeftFoot) pos = 'box-left-inner-top';
				else if (action == 'Backwards' && !isLeftFoot) pos = 'box-right-inner-top';
				else if (action == 'Sideways' && isSidewaysLeft) pos = 'box-left-inner';
				else if (action == 'Sideways' && !isSidewaysLeft) pos = 'box-right-inner';
				else throw "Can't choose box position - Closest";
				break;
			case 'Furthest side of box':
				if (action == 'Forwards' && isLeftFoot) pos = 'box-left-inner-top';
				else if (action == 'Forwards' && !isLeftFoot) pos = 'box-right-inner-top';
				else if (action == 'Backwards' && isLeftFoot) pos = 'box-left-inner-bot';
				else if (action == 'Backwards' && !isLeftFoot) pos = 'box-right-inner-bot';
				else if (action == 'Sideways' && isSidewaysLeft) pos = 'box-right-inner';
				else if (action == 'Sideways' && !isSidewaysLeft) pos = 'box-left-inner';
				else throw "Can't choose box position - Furthest";
				break;
			case 'Mixed':
				if ((action == 'Forwards' || action == 'Backwards') && isLeftFoot)
					pos = ['box-left-inner-top', 'box-left-inner-bot'][Math.floor(Math.random() * 2)];
				else if ((action == 'Forwards' || action == 'Backwards') && !isLeftFoot)
					pos = ['box-right-inner-top', 'box-right-inner-bot'][Math.floor(Math.random() * 2)];
				else if (action == 'Sideways') pos = ['box-left-inner', 'box-right-inner'][Math.floor(Math.random() * 2)];
				else throw "Can't choose box position - Mixed";
				break;
			case 'High knees':
				pos = 'box-middle'; // Ticket (resolved 6 march) specified that all high knees go into the middle
				/*if 		((action == "Forwards" || action == "Backwards") && isLeftFoot) pos = 'box-left-inner';
				else if ((action == "Forwards" || action == "Backwards") && !isLeftFoot) pos = 'box-right-inner';
				else if (action == "Sideways") pos = ['box-left-inner', 'box-right-inner'][Math.floor(Math.random() * 2)];
				else throw "Can't choose box position - High Knees";*/
				break;
			case 'Simple steps':
				if (action == 'up' || action == 'centre' || action == 'Forwards' || action == 'Forward') pos = 'box-middle';
				else if (action == 'left') pos = 'box-left-outer';
				else if (action == 'right') pos = 'box-right-outer';
				else if (action == 'forward' || action == 'forwards') pos = 'box-top-outer';
				else if (action == 'backward' || action == 'backwards') pos = 'box-bottom-outer';
				else if (action == 'Sideways') pos = 'box-middle';
				else if (action == 'Combination' || action == 'combination') pos = 'box-middle';
				else throw "Can't choose box position - Simple steps";
				break;
			case 'Step down':
			case 'Tap down':
				if (cue == 'Tap down') stance = 'forward';
				if (action == 'Forwards' || action == 'Forward') pos = 'box-top-outer';
				else if (action == 'Backwards' || action == 'Backward') pos = 'box-bottom-outer';
				else if (action == 'Sideways') pos = ['box-left-outer', 'box-right-outer'][Math.floor(Math.random() * 2)];
				//else if (action == "Mixed_Backwards_Sideways" && isLeftFoot) pos = "box-left-outer";
				//else if (action == "Mixed_Backwards_Sideways" && !isLeftFoot) pos = "box-right-outer";
				else if (action == 'Mixed_Backwards_Sideways') {
					let side = ['bottom', 'side'][Math.floor(Math.random() * 2)];
					if (side == 'side') {
						if (isLeftFoot) pos = 'box-left-outer';
						else pos = 'box-right-outer';
					} else {
						pos = 'box-bottom-outer';
					}
				} else throw "Can't choose box position - Step Down";
				break;
			default:
				throw 'Unable to get box foot position.  Unknown cue: ' + cue;
		}
		return { pos: pos, stance: stance };
	}

	////////////////////////////////
	shouldLeftFootMoveFirst(lastPosition, newPosition, rotate) {
		let isMiddle = lastPosition.indexOf('middle') > -1;
		let isLeft = lastPosition.indexOf('left') > -1;
		let isRight = lastPosition.indexOf('right') > -1;
		let willBeLeft = newPosition.indexOf('left') > -1;
		let willBeRight = newPosition.indexOf('right') > -1;

		if (isMiddle && willBeLeft && rotate < 180) return true;
		else if (isMiddle && willBeLeft && rotate >= 180) return false;
		else if (isMiddle && willBeRight && rotate < 180) return false;
		else if (isMiddle && willBeRight && rotate >= 180) return true;
		else if (isLeft && rotate < 180) return false;
		else if (isLeft && rotate >= 180) return true;
		else if (isRight && rotate < 180) return true;
		else if (isRight && rotate >= 180) return false;
		else return true;
	}

	////////////////////////////////
	getBeatFromTempo(tempos) {
		if (Array.isArray(tempos)) {
			let tempo = tempos[Math.floor(Math.random() * tempos.length)];
			return 60 / tempo;
		} else {
			return 60 / tempos;
		}
	}

	////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////// ANIMATION PLAYBACK METHODS

	////////////////////////////////////
	private playbackAnimationOnBeat(event) {
		console.log('%c ------ Playback animation on beat', 'color:green', event);
		if (this.debugLastFireTime)
			console.log('%c ---- Time since last playback:', 'color:grey', (new Date().getTime() - this.debugLastFireTime) / 1000);
		this.debugLastFireTime = new Date().getTime();

		// Add and remove 'noTransition' class if 'initialState' animation
		if (event.params.noTransition) {
			/*this.footLeft.nativeElement.classList.add('noTransition');
			this.footRight.nativeElement.classList.add('noTransition');
			this.floorFootLeft.nativeElement.classList.add('noTransition');
			this.floorFootRight.nativeElement.classList.add('noTransition');*/
			this.addClass(this.footLeft.nativeElement, 'noTransition');
			this.addClass(this.footRight.nativeElement, 'noTransition');
			this.addClass(this.floorFootLeft.nativeElement, 'noTransition');
			this.addClass(this.floorFootRight.nativeElement, 'noTransition');
			setTimeout(() => {
				this.removeClass(this.footLeft.nativeElement, 'noTransition');
				this.removeClass(this.footRight.nativeElement, 'noTransition');
				this.removeClass(this.floorFootLeft.nativeElement, 'noTransition');
				this.removeClass(this.floorFootRight.nativeElement, 'noTransition');
			}, 500);
		}

		try {
			switch (event.params.type) {
				//////////////////////////////////////////////////////
				////////////////// FLOOR ANIMATIONS
				case 'floor':
				case 'floor_balance':
					this.characterSetPose(event.params.animation, event.params.cumulative || false);
					if (event.params.beep !== false && event.params.beep !== undefined && event.params.beep !== null) this.playBeep();
					break;

				//////////////////////////////////////////////////////
				////////////////// FLOOT (FLOOR FEET) ANIMATIONS
				case 'floor_foot':
					this.floorFootMoveFoot(event.params);
					if (event.params.beep !== false && event.params.beep !== undefined && event.params.beep !== null) this.playBeep();
					break;

				//////////////////////////////////////////////////////
				////////////////// BOX ANIMATIONS
				case 'box':
					switch (event.params.animation) {
						case 'Step_Left':
							this.boxMoveFoot('left', event.params.stance, event.params.footLeft, event.params.rotation, true, event.params.beep);
							break;
						case 'Step_Right':
							this.boxMoveFoot('right', event.params.stance, event.params.footRight, event.params.rotation, true, event.params.beep);
							break;
					}
					break;

				//////////////////////////////////////////////////////
				////////////////// DARTBOARD ANIMATIONS
				case 'dart':
					switch (event.params.animation) {
						case 'Step':
							let shouldBeep: boolean;
							if (event.params.hasOwnProperty('beep')) shouldBeep = event.params.beep;
							else shouldBeep = true;
							this.dartboardMoveFoot(
								(event.params.isLeftFoot && 'left') || 'right',
								event.params.dir,
								event.params.cue == 'shade' || event.params.cue == 'Shade',
								shouldBeep
							);
							this.dartboardAdjustFoot(event.params);
							break;
						case 'AdjustFoot':
							this.dartboardAdjustFoot(event.params);
							break;
						case 'icon_middle':
							let icon =
								(event.params.iconFromDir &&
									document
										.querySelector('[data-pos="' + event.params.iconFromDir + '"]')
										.getAttribute('id')
										.replace('ico-', '')) ||
								event.params.icon;
							let theArrow = document.querySelector(".icon_templates .icon_vis [data-pos='inner']");
							if (theArrow && theArrow.getAttribute('id') != 'ico-' + icon) {
								theArrow.parentElement.removeChild(theArrow);
								theArrow = null;
							}
							if (!theArrow) {
								this.placeIconOnDartboard('inner', icon, 0);
								theArrow = document.querySelector(".icon_templates .icon_vis [data-pos='inner']#ico-" + icon);
							}
							(theArrow as HTMLElement).style.opacity = event.params.opacity;
							(theArrow as HTMLElement).style.transform =
								'translate(-50%, -50%) rotate(' + this.translateCardinalToRotation(event.params.dir) + 'deg)';

							this.removeClass(theArrow, 'green');
							this.removeClass(theArrow, 'purple');
							if (event.params.colour) {
								//theArrow.classList.add(event.params.colour);
								this.addClass(theArrow, event.params.colour);
							}
							break;
					}
					break;

				//////////////////////////////////////////////////////
				////////////////// GRID ANIMATIONS
				case 'grid':
					let shouldBeep: boolean;
					let gridSquareLeft;
					let gridSquareTop;
					let gridSquare;
					if (event.params.hasOwnProperty('beep')) shouldBeep = event.params.beep;
					else shouldBeep = true;
					// When dual, we never move the feet
					if (!event.params.dual) {
						switch (event.params.animation) {
							case 'Step_Left':
								this.gridMoveFoot('left', event.params.footLeft, event.params.highlight, shouldBeep);
								break;
							case 'Step_Right':
								this.gridMoveFoot('right', event.params.footRight, event.params.highlight, shouldBeep);
								break;
						}
					} else {
						if (shouldBeep) this.playBeep();
						/*this.footLeft.nativeElement.classList.remove('on');
						this.footRight.nativeElement.classList.remove('on');*/
						this.removeClass(this.footLeft.nativeElement, 'on');
						this.removeClass(this.footRight.nativeElement, 'on');
					}
					switch (event.params.icon) {
						case 'Arrows':
						case 'Arrow':
							// Reset
							this.removeClass(this.gridIcoArrow.nativeElement, 'green');
							this.removeClass(this.gridIcoArrow.nativeElement, 'purple');
							this.gridIcoCompass.nativeElement.setAttribute('class', 'grid-icon-compass');
							this.addClass(this.gridIcoArrow.nativeElement, 'on');

							if (event.params.fakeout && event.params.fakeoutDuration) {
								this.addClass(this.gridIcoArrow.nativeElement, 'green');
								this.gridIcoArrow.nativeElement.style.transform = 'translateX(-50%) rotate(' + 45 * Math.floor(Math.random() * 8) + 'deg)';
								setTimeout(() => {
									this.removeClass(this.gridIcoArrow.nativeElement, 'green');
									/*this.gridIcoArrow.nativeElement.classList.remove('green'); */ this.addClass(
										this.gridIcoArrow.nativeElement,
										'green'
									); /*this.gridIcoArrow.nativeElement.classList.add('purple'); */
								}, event.params.fakeoutDuration * 1000);
							} else if (event.params.fakeout) {
								this.addClass(this.gridIcoArrow.nativeElement, 'purple');
								this.gridIcoArrow.nativeElement.style.transform = 'translateX(-50%) rotate(' + 45 * Math.floor(Math.random() * 8) + 'deg)';
							} else if (event.params.arrowOpposite) {
								this.addClass(this.gridIcoArrow.nativeElement, 'green');
								this.gridIcoArrow.nativeElement.style.transform =
									'translateX(-50%) rotate(' + this.translateCardinalToRotation(event.params.direction, true) + 'deg)';
							} else {
								this.addClass(this.gridIcoArrow.nativeElement, 'green');
								this.gridIcoArrow.nativeElement.style.transform =
									'translateX(-50%) rotate(' + this.translateCardinalToRotation(event.params.direction) + 'deg)';
							}
							break;
						case 'ArrowGreen':
							this.addClass(this.gridIcoArrow.nativeElement, 'green');
							this.removeClass(this.gridIcoArrow.nativeElement, 'purple');
							this.gridIcoArrow.nativeElement.style.transform =
								'translateX(-50%) rotate(' +
								this.translateCardinalToRotation(event.params.direction, event.params.reverseArrow || false) +
								'deg)';
							this.addClass(this.gridIcoArrow.nativeElement, 'on');
							break;
						case 'ArrowPurple':
							this.addClass(this.gridIcoArrow.nativeElement, 'purple');
							this.removeClass(this.gridIcoArrow.nativeElement, 'green');
							this.gridIcoArrow.nativeElement.style.transform =
								'translateX(-50%) rotate(' +
								this.translateCardinalToRotation(event.params.direction, event.params.reverseArrow || false) +
								'deg)';
							this.addClass(this.gridIcoArrow.nativeElement, 'on');
							break;
						case 'Unarrow':
							this.removeClass(this.gridIcoArrow.nativeElement, 'on');
							break;
						case 'Compass':
							this.removeClass(this.gridIcoArrow.nativeElement, 'on');
							this.gridIcoCompass.nativeElement.setAttribute('class', 'grid-icon-compass on');
							this.gridIcoCompass.nativeElement.style.transform =
								'translateX(-50%) rotate(' + this.translateCardinalToRotation(event.params.direction) + 'deg)';
							break;
						case 'target1':
							gridSquare = document.querySelector('#grid_' + event.params.square) as any;
							gridSquareLeft = gridSquare.offsetLeft;
							gridSquareTop = gridSquare.offsetTop;
							this.addClass(this.gridIcoTargets.nativeElement, 'on');
							this.gridIcoTarget1.nativeElement.setAttribute('class', 'grid-ico-target on');
							this.gridIcoTarget1.nativeElement.style.width = this.gridIcoTarget1.nativeElement.style.height =
								gridSquare.offsetHeight * 0.8 + 'px';
							this.gridIcoTarget1.nativeElement.style.left = gridSquareLeft + gridSquare.offsetHeight * 0.1 + 'px';
							this.gridIcoTarget1.nativeElement.style.top = gridSquareTop + gridSquare.offsetHeight * 0.1 + 'px';

							this.highlightGrid(event.params.square, -1);
							break;
						case 'target2':
							gridSquare = document.querySelector('#grid_' + event.params.square) as any;
							gridSquareLeft = gridSquare.offsetLeft;
							gridSquareTop = gridSquare.offsetTop;
							this.addClass(this.gridIcoTargets.nativeElement, 'on');
							this.gridIcoTarget2.nativeElement.setAttribute('class', 'grid-ico-target on');
							this.gridIcoTarget2.nativeElement.style.width = this.gridIcoTarget2.nativeElement.style.height =
								gridSquare.offsetHeight * 0.8 + 'px';
							this.gridIcoTarget2.nativeElement.style.left = gridSquareLeft + gridSquare.offsetHeight * 0.1 + 'px';
							this.gridIcoTarget2.nativeElement.style.top = gridSquareTop + gridSquare.offsetHeight * 0.1 + 'px';

							this.highlightGrid(event.params.square, -1);
							break;
						case 'target3':
							gridSquare = document.querySelector('#grid_' + event.params.square) as any;
							gridSquareLeft = gridSquare.offsetLeft;
							gridSquareTop = gridSquare.offsetTop;
							this.addClass(this.gridIcoTargets.nativeElement, 'on');
							this.gridIcoTarget3.nativeElement.setAttribute('class', 'grid-ico-target on');
							this.gridIcoTarget3.nativeElement.style.width = this.gridIcoTarget3.nativeElement.style.height =
								gridSquare.offsetHeight * 0.8 + 'px';
							this.gridIcoTarget3.nativeElement.style.left = gridSquareLeft + gridSquare.offsetHeight * 0.1 + 'px';
							this.gridIcoTarget3.nativeElement.style.top = gridSquareTop + gridSquare.offsetHeight * 0.1 + 'px';

							this.highlightGrid(event.params.square, -1);
							break;
					}
					if (event.params.animation == 'Shade') {
						this.highlightGrid(event.params.square, event.params.number || 0);
					}
					if (event.params.animation == 'Unshade') {
						this.unhighlightAll();
					}
					if (event.params.animation == 'Untarget') {
						this.removeClass(this.gridIcoTargets.nativeElement, 'on');
						this.gridIcoTarget1.nativeElement.setAttribute('class', 'grid-ico-target');
						this.gridIcoTarget2.nativeElement.setAttribute('class', 'grid-ico-target');
						this.gridIcoTarget3.nativeElement.setAttribute('class', 'grid-ico-target');
						this.unhighlightAll();
					}
					break;

				//////////////////////////////////////////////////////
				////////////////// GRID ANIMATIONS
				case 'general':
					switch (event.params.animation) {
						case 'beep':
							this.playBeep();
							break;
						case 'nothing':
							break;
					}
					break;
			}
		} catch (e) {
			console.log('ERROR DURING PLAYBACK:', e);
			this.animSchedule.stop();
		}
	}

	////////////////////////////////////
	playbackCognitiveOnBeat(event) {
		console.log('%c ------ Playback cognitive', 'color:green', event);
		if (event.params.type == 'see') {
			this.showCognitiveImage('assets/animations/svg/' + event.params.what + '.svg');
		}
		if (event.params.type == 'see_additive') {
			this.addCognitiveImage('assets/animations/svg/' + event.params.what + '.svg');
		}
		if (event.params.type == 'hear') {
			this.playCognitiveAudio(event.params.what);
		}
	}

	////////////////////////////////////
	showCognitiveImage(which) {
		console.log('%c >> Showed Cognitive Image: ' + which, 'color:purple;');

		// Preload the image
		let img = new Image();

		img.onload = () => {
			this.cogimage.nativeElement.src = which;
			setTimeout(() => this.addClass(this.cogimage.nativeElement, 'on') /*this.cogimage.nativeElement.classList.add('on')*/, 50);
			setTimeout(() => this.removeClass(this.cogimage.nativeElement, 'on'), this.cognitiveImageOnScreen + 50);
		};

		img.src = which;

		/* Old version which relied totally on preloadCognitiveImageIfRequired()
		this.cogimage.nativeElement.src = which;
		setTimeout(() => this.cogimage.nativeElement.classList.add('on'), 50);
		setTimeout(() => this.cogimage.nativeElement.classList.remove('on'), this.cognitiveImageOnScreen + 50);*/
	}

	////////////////////////////////////
	addCognitiveImage(which) {
		let image = new Image();
		image.src = which;
		image.style.height = '100%';
		image.style.marginRight = '7px';
		this.cogpatterncont.nativeElement.appendChild(image);
		this.addClass(this.cogpatterncont.nativeElement, 'on');
	}

	////////////////////////////////////
	playCognitiveAudio(which) {
		this.audioController.playAudio(which);
	}

	////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////// ANIMATION SCHEDULER METHODS

	////////////////////////////////////
	public createScheduledAnims(instr: any, cognitive: any = null): ShortSchedule {
		this.animSchedule = new ShortSchedule();

		let delay = 0;
		instr.sequence.forEach((seq) => {
			delay = delay + seq.durationInSecs * 1000;
			this.animSchedule.add(delay, false, this.playbackAnimationOnBeat.bind(this), seq);
		});
		instr.initialState.forEach((initSt) => {
			this.animSchedule.add(0, false, this.playbackAnimationOnBeat.bind(this), initSt, true);
		});

		if (cognitive) {
			delay = 0;
			cognitive.forEach((cogSeq) => {
				delay = delay + cogSeq.durationInSecs * 1000;
				this.animSchedule.addCognitive(delay, false, this.playbackCognitiveOnBeat.bind(this), cogSeq);
			});
		}

		// Store the sequence for further iterations if necessary
		this.backupScheduled = [];
		this.animSchedule.scheduleds.forEach((sch) => {
			this.backupScheduled.push({ delay: sch.delay, repeat: sch.repeat, callback: sch.callback, params: sch.params });
		});

		return this.animSchedule;
	}

	////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////// HANDY FUNCTIONS

	////////////////////////////////////
	preloadCharacterPoseImagesIfNecessary() {
		if (!this.preloadableImages.length) return;

		// Disable the button
		this.addClass(document.querySelector('.start_overlay button.bt_start'), 'loading');
		this.preloadCharacterPoseTimeout = setTimeout(
			() => this.removeClass(document.querySelector('.start_overlay button.bt_start'), 'loading'),
			30000
		);

		// Preload images
		let imsToPreload = 0;
		let imsPreloaded = 0;
		this.preloadableImages.forEach((im) => {
			imsToPreload++;
			let image = new Image();
			image.onload = completedAnImage.bind(this);
			image.src = this.getImageSourceFromCharacterPose(im);
		});

		// Image preloaded
		function completedAnImage() {
			imsPreloaded++;
			if (imsPreloaded >= imsToPreload) {
				this.removeClass(document.querySelector('.start_overlay button.bt_start'), 'loading');
				clearTimeout(this.preloadCharacterPoseTimeout);
			}
		}
	}

	////////////////////////////////////
	private isBrowser() {
		return !window['cordova_custom'] || window['cordova_custom'].device() == 'browser';
	}

	////////////////////////////////////
	private addClass(nativeElement, classAdd) {
		//Escapers
		if (nativeElement == null) return;

		if (!nativeElement.getAttribute('class')) nativeElement.setAttribute('class', classAdd);
		else {
			let classes = nativeElement.getAttribute('class').split(' ');
			if (classes.indexOf(classAdd) == -1) classes.push(classAdd);
			nativeElement.setAttribute('class', classes.join(' '));
		}
	}

	////////////////////////////////////
	private removeClass(nativeElement, classRem) {
		//Escapers
		if (nativeElement == null) return;

		if (!nativeElement.getAttribute('class')) return; // If getting the class attribute yields nothing
		/*let regex = new RegExp(classRem, "g");
		nativeElement.setAttribute('class', nativeElement.getAttribute('class').replace(regex, ""));
		nativeElement.setAttribute('class', nativeElement.getAttribute('class').trim());*/
		let classes = nativeElement.getAttribute('class').split(' ');
		let removeClassIndex = classes.indexOf(classRem);
		if (removeClassIndex != -1) {
			classes.splice(removeClassIndex, 1);
		}
		nativeElement.setAttribute('class', classes.join(' '));
	}
}
