///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS

////////////////////////////////
////////// IMPORTS
import { Injectable } from '@angular/core';
import { Exercise, ExerciseCategory, ExerciseLevel, ExerciseAction } from '../st-commons/models';
import { STExerciseLogic } from '../st-commons/exercise_logic';
import { DataService } from './data.service';
import { Vasat } from 'vasat';
import { UserService } from './user.service';
import { AbsExerciseService } from '../st-commons/service';
import { BehaviorSubject } from 'rxjs';
import { GlobalPubSub } from '../services/global-pub-sub.service';
import { DataStream } from '../objects/datastream';
import { StorageService } from '../services/storage.service';
import { DebugLoggerService } from '../services/debugLogger.service';
import { environment } from '../../environments/environment';

declare var require;
const M = require('moment');

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE CLASS
@Injectable({ providedIn: 'root' })
export class ExerciseService extends AbsExerciseService {
	////////////////////////////////
	exercises: Exercise[] = [];
	exerciseCategories: ExerciseCategory[] = [];
	exerciseLevels: ExerciseLevel[] = [];
	public presetDualIndex: number = null;
	presetDualInstructions: any = null;

	////////////////////////////////
	maxPauseTime: number = 1000 * 60 * 30; // 30 minutes
	default_session: any = { created: null, exercise_actions: [], user: null };
	exercise_session: any = null;
	exercise_session_action_names: any[] = [];
	selectedTime: number;
	sessionStarted: number;
	savingState: string = 'idle';

	////////////////////////////////
	balanceAssessmentRules: any;
	balanceAssessmentAction: ExerciseAction;
	balance_assessment_session: any;
	balanceAssessmentTotalDuration: number = 0;
	inactivityTimeoutLengthMs: number = 1000 * 60 * 120; // 120 minutes

	////////////////////////////////
	sessionScenarioOptions: any[] = [];
	selectedSessionScenario: string = 'balance';

	////////////////////////////////
	trial_session: any;

	////////////////////////////////
	stExerciseLogic: STExerciseLogic;

	////////////////////////////////
	constructor(
		private sData: DataService,
		private V: Vasat,
		private sUser: UserService,
		private sGlobalPubSub: GlobalPubSub,
		private sStorage: StorageService,
		private sDebugLogger: DebugLoggerService
	) {
		super();
		this.exerciseTimeoutWatch();
		if (!this.stExerciseLogic) this.stExerciseLogic = new STExerciseLogic(this.V);
	}

	////////////////////////////////
	determineSessionScenarioCapabilities() {
		let shouldDoCog = this.stExerciseLogic.userShouldDoCog(this.sUser.user);
		let hasCardio = this.sUser.user.levelProgression.some((lp) => ['cardio'].includes(lp.category.uid) && !lp.disabled);
		let hasStrength = this.sUser.user.levelProgression.some((lp) => ['upbodystr', 'lowbodystr'].includes(lp.category.uid) && !lp.disabled);

		if (!shouldDoCog && hasCardio && hasStrength) {
			this.sessionScenarioOptions = [
				{ name: 'Balance', type: 'balance', icon: 'ico_exercise_white', selected: true },
				{ name: 'Balance + Cardio & Strength', type: 'balance_cardio_strength', icon: 'ico_exercise_balance_white', selected: false },
				{ name: 'Cardio & Strength', type: 'cardio_strength', icon: 'ico_exercise_strength_white', selected: false },
			];
		} else if (shouldDoCog) {
			this.sessionScenarioOptions = [{ name: 'Balance + Cognitive', type: 'balance_cognitive', icon: 'ico_exercise_white', selected: true }];
		} else {
			this.sessionScenarioOptions = [{ name: 'Balance', type: 'balance', icon: 'ico_exercise_white', selected: true }];
		}
	}

	////////////////////////////////
	getSessionActionsFromLogic(stUser, sessionScenario) {
		let resolver = function (resolve, reject) {
			let actions;
			try {
				actions = this.stExerciseLogic.createExerciseSession({
					user: stUser,
					sessionScenario: sessionScenario,
					duration: this.selectedTime.val,
					debug: environment.debug,
				});
				if (!actions.length) reject('No actions were generated');
				else {
					// Used to test specific exercises in site / DEBUG
					/*let actionOptions = [	{"mode":"static_duration","animation_name":"St_NT","start_state_animation":"St_NT","feet_icon":"Near Tandem","floorSequence":[{"beats":1,"tempo":0.1,"animation":"St_NT","multiplier":1}],"tempo":[12],"reps":[1],"modifier":null,"cognitive":{"mode":"Audio","cue_to_count":"Specific word","cue_to_ignore":"Distractor words","tempos":["1.8"],"feedback":"Number"}},
											{"mode":"static_duration","animation_name":"St_FT_Arms","start_state_animation":"St_FT","feet_icon":"Feet Together","floorSequence":[{"beats":1,"tempo":30,"animation":"St_FT_LArm_F_RArm_Si","multiplier":1},{"beats":1,"tempo":30,"animation":"St_FT_LArm_L_RArm_Si","multiplier":1},{"beats":1,"tempo":30,"animation":"St_FT_LArm_R_RArm_Si","multiplier":1},{"beats":1,"tempo":30,"animation":"St_FT_RArm_F_LArm_Si","multiplier":1},{"beats":1,"tempo":30,"animation":"St_FT_RArm_R_LArm_Si","multiplier":1},{"beats":1,"tempo":30,"animation":"St_FT_RArm_L_LArm_Si","multiplier":1}],"tempo":[6],"reps":[1],"cognitive":{"mode":"Audio","cue_to_count":"Specific word","cue_to_ignore":"Word category","tempos":["1.5,3.0"],"feedback":"Number"}},
											{"mode":"static_duration","animation_name":"St_NT","start_state_animation":"St_NT","feet_icon":"Near Tandem","floorSequence":[{"beats":1,"tempo":0.1,"animation":"St_NT","multiplier":1}],"tempo":[12],"reps":[1],"modifier":null,"cognitive":{"mode":"Audio","cue_to_count":"Specific word","cue_to_ignore":"Distractor words","tempos":["1.8"],"feedback":"Number"}}
										]
										  actions.forEach(act => act.instructions = actionOptions[Math.floor(Math.random()*actionOptions.length)]);*/

					this.exercise_session.exercise_actions = actions;
					this.sDebugLogger.saveExerciseSessionLog(this.exercise_session);
					resolve(actions);
				}
			} catch (e) {
				reject(e);
			}
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////
	selectExerciseType(type) {
		this.selectedSessionScenario = type;
		this.sessionScenarioOptions.map((scen) => (scen.selected = false));
		this.sessionScenarioOptions.find((scen) => scen.type == type).selected = true;
	}

	////////////////////////////////
	getDoseageForThisWeek() {
		let resolver = function (resolve, reject) {
			/*this.sData
				.getTypedDataFromStorage(ExerciseAction)
				.then((res) => {
					if (!this.sUser.userLoaded) return reject('No user to calculate dosage');

					let startOfWeek = M().startOf('isoWeek').toDate().getTime();
					let endOfWeek = M().endOf('isoWeek').toDate().getTime();
					let dosage: number = parseInt(this.stExerciseLogic.doseageForThisWeek(this.sUser.user));

					if (this.sUser.isUserCog()) {
						dosage += this.stExerciseLogic.cogDoseageForThisWeek(this.sUser.user);
					}

					let completage: number = 0;
					//res.data.ExerciseAction.forEach( it => { if (it.name != "Balance Assessment" && it.name != "Balance Assesment" && it.owner.id == this.sUser.user.id && it.complete) completage += it.duration; });
					res.data.ExerciseAction.forEach((it) => {
						let timeOfEx = it.dateMade || it.exerciseTime;
						if (
							it.owner.id == this.sUser.user.id &&
							timeOfEx >= startOfWeek &&
							timeOfEx < endOfWeek &&
							it.name != 'Balance Assesment' &&
							it.name != 'Balance Assessment'
						) {
							completage += it.duration;
						}
					});
					let remainage: number = Math.max(0, dosage - completage);
					let minutes: any;
					let hours: any;
					let timeString: string;
					minutes = Math.min(59, Math.round(remainage % 60));
					hours = Math.floor(remainage / 60);
					if (hours > 0) {
						if (hours == 1) hours = hours + ' hour';
						if (hours > 1) hours = hours + ' hours';
						if (minutes > 0) hours = hours + ', and ';
					} else hours = '';
					if (minutes > 0) {
						if (minutes == 1) minutes = '1 minute';
						if (minutes > 1) minutes = minutes + ' minutes';
					} else minutes = '';
					if (hours == '' && minutes == '') minutes = '0 minutes';

					timeString = hours + minutes;

					resolve({
						dosage: dosage,
						completage: completage,
						remainage: remainage,
						percentage: Math.ceil((completage / dosage) * 100),
						hourage: hours,
						minuteage: minutes,
						stringage: timeString,
					});
				})
				.catch(reject);
		}.bind(this);*/

			// Switching to using usre data profile
			this.sData.getPrimeUser().then(theUser => {
				const user = theUser.data && theUser.data.primeUser && theUser.data.primeUser[0];
				if (!user) return reject();

				let dosage: number = parseInt(this.stExerciseLogic.doseageForThisWeek(this.sUser.user));
				if (this.sUser.isUserCog()) {
					dosage += this.stExerciseLogic.cogDoseageForThisWeek(this.sUser.user);
				}

				const year_month = `${M().year()}_${M().week()}`;
				const completage = (user.completedHours && user.completedHours[year_month] || 0) + (user.completedCognitiveHours && user.completedCognitiveHours[year_month] || 0);
				const remainage = dosage - completage;

				let minutes: any;
				let hours: any;
				minutes = Math.min(59, Math.round(remainage % 60));
				hours = Math.floor(remainage / 60);
				if (hours > 0) {
					if (hours == 1) hours = hours + ' hour';
					if (hours > 1) hours = hours + ' hours';
					if (minutes > 0) hours = hours + ', and ';
				} else hours = '';
				if (minutes > 0) {
					if (minutes == 1) minutes = '1 minute';
					if (minutes > 1) minutes = minutes + ' minutes';
				} else minutes = '';
				if (hours == '' && minutes == '') minutes = '0 minutes';

				resolve({
					dosage: dosage,
					completage: completage,
					remainage: remainage,
					percentage: Math.ceil((completage / dosage) * 100),
					hourage: hours,
					minuteage: minutes,
					stringage: hours + minutes
				});
			}).catch(reject);
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////
	getCogDosageForThisWeek(): number {
		return this.stExerciseLogic.cogDoseageForThisWeek(this.sUser.user);
	}

	////////////////////////////////
	checkDataRequirements(): boolean {
		return this.exercises.length > 0 && this.exerciseCategories.length > 0 && this.exerciseLevels.length > 0;
	}

	////////////////////////////////
	hasExistingSession() {
		let numberIncompleteExercises = this.exercise_session.exercise_actions.filter((act) => {
			if (act.complete) return false;
			else return true;
		}).length;

		//Check if current time taken is less than max pause time (30 mins)
		let createdWithinTime = new Date().getTime() - this.exercise_session.created < this.maxPauseTime; // here
		if (createdWithinTime && numberIncompleteExercises > 0) return true;
		else return true;
	}

	////////////////////////////////
	hasExistingBalanceAssessment() {
		if (this.getNextBalanceAssessmentExercise() == null) return false;
		else return true;
	}

	////////////////////////////////
	getNextBalanceAssessmentExercise() {
		let next = null;
		if (!this.balance_assessment_session) return null;

		let nextItem = this.balance_assessment_session.exercise_actions.find((ex) => ex.score === null);
		if (nextItem) return nextItem;
		else return false;
	}

	////////////////////////////////
	saveBalanceResult(name, typeName, score) {
		let currentItem = this.balance_assessment_session.exercise_actions.find((ex) => ex.score === null);
		console.groupCollapsed(`%c+ BA Result Information`, 'color:lightblue');
		console.log('Score:', score);
		console.log('Current item', currentItem);
		console.log('Balance Assessment Session (critical):', this.balance_assessment_session);
		console.log('Argument - Name:', name);
		console.log('Argument - typeName:', typeName);
		console.groupEnd();
		currentItem.score = parseInt(score);
		let upgradeScore = currentItem.upgradeScore;
		let willLockID = currentItem.locksOutID;

		if (upgradeScore) {
			if (score < upgradeScore) {
				this.balance_assessment_session.exercise_actions.find((ex) => ex.id == willLockID).score = 0;
			}
		}
	}

	////////////////////////////////
	needsBalanceAssessment() {
		let resolver = function (resolve, reject) {
			this.sData.getAndCacheExerciseData().then((res) => {
				this.sUser.user.updateRelations();
				resolve(this.stExerciseLogic.needsBalanceAssesment(this.sUser.user));
			});
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////
	createNewSession(time, sessionScenario) {
		////////////////////////////////
		this.selectedTime = time;
		this.sessionStarted = new Date().getTime();

		////////////////////////////////
		let resolver = function (resolve, reject) {
			this.sData
				.getAndCacheExerciseData()
				.then((res) => {
					this.sUser.user.updateRelations();
					this.exercise_session = JSON.parse(JSON.stringify(this.default_session));
					this.exercise_session.user = this.sUser.user.id;
					this.exercise_session.created = new Date().getTime();
				})
				.then((res) => {
					return this.getSessionActionsFromLogic(this.sUser.user, sessionScenario);
				})
				.then(resolve)
				.catch(reject);
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////
	createNewBalanceAssessment() {
		////////////////////////////////////
		this.balanceAssessmentTotalDuration = 0;
		this.sessionStarted = new Date().getTime();

		let resolver = function (resolve, reject) {
			this.sData
				.getAndCacheExerciseData()
				.then((res) => {
					this.sUser.user.updateRelations();
					this.balanceAssessmentAction = this.stExerciseLogic.makeBalanceAssesment(this.sUser.user);
					if (!this.balanceAssessmentRules) this.balanceAssessmentRules = require('../../assets/json/balanceAssessment.json');
					this.balance_assessment_session = JSON.parse(JSON.stringify(this.default_session));
					this.balance_assessment_session.exercise_actions = JSON.parse(JSON.stringify(this.balanceAssessmentRules));
					this.balance_assessment_session.created = new Date().getTime();
					this.sDebugLogger.saveExerciseSessionLog(this.exercise_session);
					resolve(this.balanceAssessmentAction);
				})
				.catch((err) => console.log(`%cError during Create New Balance Assessment: ${err}`, 'color:red;'));
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////
	createNewTrialSession() {
		////////////////////////////////
		// 481 - 486
		let exerciseIDList = [
			{ exerciseID: 41, levelID: 184 },
			{ exerciseID: 159, levelID: 646 },
			{ exerciseID: 103, levelID: 444 },
			{ exerciseID: 17, levelID: 74 },
			{ exerciseID: 65, levelID: 476 },
			{ exerciseID: 107, levelID: 691 },
			{ exerciseID: 110, levelID: 497 },
		];

		////////////////////////////////
		this.selectedTime = 300;
		this.sessionStarted = new Date().getTime();

		////////////////////////////////
		let resolver = function (resolve, reject) {
			this.sData
				.getAndCacheExerciseData()
				.then((res) => {
					this.sUser.user.updateRelations();
					this.exercise_session = JSON.parse(JSON.stringify(this.default_session));
					this.exercise_session.user = this.sUser.user.id;
					this.exercise_session.created = new Date().getTime();
					this.exercise_session.exercise_actions = this.stExerciseLogic.makeTrialSession(this.sUser.user, exerciseIDList);
				})
				.then((res) => {
					return this.exercise_session.exercise_actions;
				})
				.then(resolve)
				.catch(reject);
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////
	clearSession() {
		this.exercise_session = null;
		this.balance_assessment_session = null;
		this.balanceAssessmentAction = null;
		this.sessionStarted = null;
		return Promise.resolve('complete');
	}

	////////////////////////////////
	getNextTrialExercise() {
		let next = null;
		if (!this.trial_session) return null;

		//let nextItem = this.trial_session.exercise_actions.find(ex => ex.score === null);
		let nextItem = this.trial_session.exercise_actions[0];
		if (nextItem) return nextItem;
		else return false;
	}

	////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////// SAVE AND GETTING METHODS
	////////////////////////////////
	getCurrentExerciseAction() {
		let action = this.exercise_session.exercise_actions.filter((act) => {
			return act.complete == false;
		})[0];
		if (action) {
			action.usedChair = null;
			action.usedWeights = null;
			return action;
		} else return null;
	}

	////////////////////////////////////
	getCurrentExerciseActionIndex() {
		let total = this.exercise_session.exercise_actions.length;
		let remaining = this.exercise_session.exercise_actions.filter((act) => {
			return act.complete == false;
		}).length;
		return total - remaining + 1;
	}

	////////////////////////////////
	saveSession() {
		console.log('%cSave Session', 'color: orange;');

		let resolver = function (resolve, reject) {
			// 2020-11-14: New strategy to count all completed in all exercise sessions, even if the session itself was not complete
			// Mark exercises as complete, and set their feedback as lowly
			this.setFeedbackForIncompleteActions(0, true, true);
			this.convertIncompleteToComplete();

			// 2020-11-14: This is now redundant
			// If for some reason there aren't enough completed exerceses
			if (
				this.exercise_session.exercise_actions.filter((it) => {
					return it.complete == false;
				}).length > 0
			)
				return reject('Session is incomplete');
			// Otherwise run saveExercise on each exercise_action
			else {
				// Mark as saving
				this.savingState = 'saving';

				// Save with Vasat
				let savedExercises = [];
				this.exercise_session.exercise_actions.forEach((action) => {
					action.owner = { id: this.sUser.user.id };
					delete action.instructions; //2023-05-31: Storage Review - remove instructions due to heavy data usage
					savedExercises.push(this.stExerciseLogic.saveExercise(this.sUser.user, action).toPromise());
				});

				// Ensure that all exercises save, then send data off
				Promise.all(savedExercises)
					.then(() => {
						this.savingState = 'saving';

						this.sData
							.saveCompleteSessionActions(this.exercise_session.exercise_actions)
							.then((res) => {
								resolve();
								this.savingState = 'idle';

								// Now we need to save the user
								this.sData
									.updateUserProfile(this.sUser.user, true, [
										'completedHours',
										'completedCognitiveHours',
										'levelProgression',
										'lastWorkoutWasCog',
										'cognitiveHoursSinceLastAssess',
										'cognitiveRatings',
									])
									.then((res) => {
										this.sData.sendAllServerUpdates();
									});
							})
							.catch((err) => {
								setTimeout(() => (this.savingState = 'error'), 1000);
								console.log('Unable to save session', err);
								reject(err);
							});
					})
					.catch((err) => {
						setTimeout(() => (this.savingState = 'error'), 1000);
						console.log('Unable to save all exercise_actions', err);
						reject(err);
					});
			}
		}.bind(this);
		return new Promise(resolver);
	}

	////////////////////////////////
	saveBalanceAssessment(individualExercises) {
		let resolver = function (resolve, reject) {
			this.balanceAssessmentAction.owner = this.sUser.user;
			this.balanceAssessmentAction.complete = true;
			this.balanceAssessmentAction.exerciseEndTime = new Date().getTime();
			this.balanceAssessmentAction.duration = this.balanceAssessmentTotalDuration;
			this.balanceAssessmentAction.individualExercises = individualExercises;
			this.stExerciseLogic.saveExercise(this.sUser.user, this.balanceAssessmentAction);

			// Save to localStorage
			this.sData
				.saveCompleteSessionActions([this.balanceAssessmentAction])
				.then((res) => {
					this.sData.updateUserProfile(this.sUser.user, true, ['balancePerMonth', 'lastBalanceAssesment', 'levelProgression']).then((res) => {
						this.sData.sendAllServerUpdates();
					});
					resolve(res);
				})
				.catch(reject);
		}.bind(this);

		return new Promise(resolver);
	}

	////////////////////////////////////
	getExerciseNameFromSession(id) {
		let name = this.exercise_session_action_names.find((it) => it.id == id);
		return name.name;
	}

	////////////////////////////////////
	getHelp(eAction) {
		if (this.presetDualInstructions) {
			let eHelp = eAction.getHelp(this.presetDualInstructions);
			return eHelp;
		} else {
			return eAction.getHelp();
		}
	}

	////////////////////////////////////
	prechooseDualIndex(instructions) {
		if (instructions.dual) {
			this.presetDualIndex = Math.floor(Math.random() * instructions.dual.length);
			this.presetDualInstructions = instructions.dual[this.presetDualIndex];
		} else {
			this.presetDualIndex = null;
			this.presetDualInstructions = null;
		}
	}

	////////////////////////////////////
	getPrechosenDualIndex() {
		return this.presetDualIndex;
	}

	////////////////////////////////////
	prechooseDualIndexMethod(): any {
		return this.prechooseDualIndex;
	}

	////////////////////////////////////
	setFeedbackForIncompleteActions(rating: number = 1, usedChair: boolean = false, floorDuration: boolean = false) {
		let exerciseEndTime = new Date().getTime();

		this.exercise_session.exercise_actions.forEach((action) => {
			if (!action.complete) {
				action.rating = rating;
				action.usedChair = usedChair;
				action.exerciseEndTime = exerciseEndTime;

				if (floorDuration) action.duration = 0;
			}
		});
	}

	////////////////////////////////////
	convertIncompleteToComplete() {
		this.exercise_session.exercise_actions.forEach((action) => {
			action.complete = true;
		});
	}

	////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////// EXERCISE TIMEOUT METHODS
	////////////////////////////////

	////////////////////////////////
	exerciseTimeoutWatch() {
		this.sGlobalPubSub.subscribe('cordovaresume', this.exerciseHasTimedOut.bind(this));
	}

	////////////////////////////////
	exerciseTimeoutTime() {
		if (this.exercise_session || this.balance_assessment_session) {
			var now = new Date().getTime();
			var then = this.sessionStarted;
			var total_ms = this.inactivityTimeoutLengthMs;
			var total_secs = this.inactivityTimeoutLengthMs / 1000;
			var total_mins = this.inactivityTimeoutLengthMs / 1000 / 60;
			var elapsed_ms = now - then;
			var elapsed_secs = Math.round(elapsed_ms / 1000);
			var elapsed_mins = Math.round(elapsed_secs / 60);
			var rem_ms = total_ms - elapsed_ms;
			var rem_secs = total_secs - elapsed_secs;
			var rem_mins = total_mins - elapsed_mins;

			var returnData = {
				started: then,
				elapsed: { ms: elapsed_ms, secs: elapsed_secs, mins: elapsed_mins },
				total: { ms: total_ms, secs: total_secs, mins: total_mins },
				remaining: { ms: rem_ms, secs: rem_secs, mins: rem_mins },
			};

			return returnData;
		} else {
			return {
				elapsed: { ms: 0, secs: 0, mins: 0 },
				total: { ms: 0, secs: 0, mins: 0 },
				remaining: { ms: 0, secs: 0, mins: 0 },
			};
		}
	}

	////////////////////////////////
	exerciseHasTimedOut(): boolean {
		if (this.exercise_session || this.balance_assessment_session) {
			var time = this.exerciseTimeoutTime();

			if (time.remaining.secs <= 0) {
				this.sGlobalPubSub.fireEvent('sessionInvalidated', []);
				return true;
			}
		}

		return false;
	}

	////////////////////////////////////
	generateGridAndDartboardName(exerciseAction) {
		const instructionsSource = exerciseAction.json || exerciseAction.instructions;
		if (!instructionsSource) return "";
		const json = (typeof instructionsSource == "object") ? instructionsSource : JSON.parse(instructionsSource);
		if (!json.mode || ['box', 'grid', 'dart'].indexOf(json.mode) == -1) return "";

		const labels = [];

		if (['grid'].indexOf(json.mode) !== -1) {
			labels.push(json.action.charAt(0).toUpperCase() + json.action.slice(1));
		}

		if (['grid', 'dart'].indexOf(json.mode) !== -1) {
			switch (json.cue[0]) {
				case "Arrow":
					labels.push("✥ Arrows");
				break;
				case "Compass":
					labels.push("🧭 Compass");
				break;
				default:
					labels.push(json.cue[0]);
				break;
			}
		}

		json.direction && labels.push(json.direction);

		(json.tempo[0] >= 65 && labels.push('Fast Tempo')) || (json.tempo[0] >= 50 && labels.push('Lively Tempo')) || (json.tempo[0] > 35 && labels.push('Standard Tempo')) || labels.push('Slow Tempo');

		return `${labels.join(", ")}`;
	}
}
