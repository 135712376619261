///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// IMPORTS
import { Component, OnInit, Input, ViewChild } from '@angular/core';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// DEFINE COMPONENT
@Component({
  selector: 'subpage-termsandconditions',
  templateUrl: './subpage-termsandconditions.component.html',
  styleUrls: ['./subpage-termsandconditions.component.scss']
})


///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CLASS
export class SubpageTermsandconditionsComponent implements OnInit {

	////////////////////////////////////
	@Input() colourClass:string = "brown";
	@ViewChild('main') mainEl;

	////////////////////////////////////
	constructor() { }
	ngOnInit() {
		this.mainEl.nativeElement.className = "copyContainer "+this.colourClass;
	}
}